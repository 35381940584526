import React, { useCallback, useEffect, useState } from "react";
import { resizeFile } from "./utils"; // Create a separate utility file for image resizing
import Button from "@mui/material/Button";

const LogoUploader = React.forwardRef((props, ref) => {
  const [sizeWrong, setSizeWrong] = useState(false);
  const [imageUrl, setImageUrl] = useState(
    `${process.env.REACT_APP_IMAGE_URL}/logo/${
      props.merchantName
    }_Logo.png?timestamp=${Date.now().toString()}`
  );

  const [currentImage, setCurrentImage] = useState(props.image);
  useEffect(() => {
    setCurrentImage(props.image);
  }, [props.image]);

  const handleUpload = async (e) => {
    if (e.target.files !== undefined && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();

      var img: HTMLImageElement;
      img = document.createElement("img");

      img.onload = function () {
        // if (
        //   props.desiredHeight > img.height ||
        //   props.desiredWidth > img.width
        // ) {
        //   props.setErrorMessage(
        //     `Upload Image with dimensions of at least ${props.desiredWidth} x ${props.desiredHeight}`
        //   );
        //   props.setShowErrorAlert(true);
        //   setSizeWrong(true);
        // } else {
        setSizeWrong(false);
        props.setErrorMessage("");
        props.setShowErrorAlert(false);
        // }
      };
      console.log(sizeWrong);

      img.src = URL.createObjectURL(file);

      // const resizeImage = await resizeFile(
      //   file,
      //   props.desiredWidth,
      //   props.desiredHeight,
      //   `${props.merchantName}_logo_image`
      // );
      const resizeImage = file;
      var imgs = URL.createObjectURL(resizeImage);
      props.setImage(imgs);
      setCurrentImage(imgs);

      if (resizeImage) {
        reader.readAsDataURL(resizeImage);
      }

      reader.onloadend = () => {
        // `reader.result` contains the Base64 representation of the image
        const base64String = reader.result;
        props.setResizedImage(base64String);
      };

      setImageUrl(imgs);
    }
  };

  return (
    <div className="mx-auto mt-8 w-full max-w-md">
      <div className="mb-4 flex h-64 w-full flex-col items-center justify-center rounded-lg border-2 border-dashed">
        {currentImage == null || sizeWrong ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
        ) : (
          <img
            src={`${currentImage}`}
            onError={() => {
              setCurrentImage(null);
            }}
            alt="Preview"
            className="mt-2 max-h-40 w-full rounded-lg object-contain"
          />
        )}
        <div className="mt-10">
          <Button variant="outlined" component="label">
            Upload File
            <input
              type="file"
              hidden
              onChange={handleUpload}
              accept=".png, .jpg, .jpeg"
            />
          </Button>
        </div>
      </div>
    </div>
  );
});

export default LogoUploader;
