import React, { Component, useRef } from 'react';
import LineChart from '../../charts/LineChart02';
import BarChart from '../../charts/BarChart01'
// Import utilities
import { tailwindConfig } from '../../utils/Utils';
import { Auth } from "aws-amplify";

import resolveConfig from 'tailwindcss/resolveConfig'
import Button from "@material-tailwind/react/Button";
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";

const {theme} = resolveConfig(tailwindConfig)

export class DashboardCard18 extends Component {

  constructor(props) {
    super(props)

    this.state = {
      jwtToken: '',
      total: 0,
      accountNames: props.accountNames,
      currentBalances: props.currentBalances,
      chartData: {
        labels: [],
        datasets: [
          // Indigo line
          {
            label: 'Current',
            data: [],
            borderColor: tailwindConfig().theme.colors.red[500],
            fill: false,
            borderWidth: 2,
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBackgroundColor: tailwindConfig().theme.colors.red[500],
          },
        ],
      }
    }
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.value !== props.value) {
      this.updateData()
    }
    return null
  }

  componentDidUpdate() {
    if (this.state.accountNames !== this.props.accountNames) {
      console.log('updated data')
      this.updateData()
    }
  }

  async componentDidMount() {
    const data = await Auth.currentSession()
    this.setState({
      jwtToken: data['idToken']['jwtToken']
    }, () => this.updateData())
  }

  convertTimestamp(timestamp) {
    const sections = timestamp.split('-')
    return sections[1] + '-' + sections[2] + '-' + sections[0]
  }

  updateData() {
    const requestOptions = {
      method: 'POST',
      headers: { 'accept': '*/*', 'content-type': 'application/json', 'Authorization': this.state.jwtToken },
      body: JSON.stringify({
        accountNames: this.props.accountNames,
        merchantID: global.merchantID
      }),
    };
    const url = 'https://qvggsbg2r9.execute-api.us-east-2.amazonaws.com/prod/donations'
    let paymentInstrument = fetch(url, requestOptions)
      .then(async (response) => {
        const res = await response.json()
        let timestamps = [];
        let data = [];
        let uniqueDonors = {};
        let totalDonations = 0;
        let accountBalances = 0;

        res.map(row => {
          if (row.state === 'SUCCEEDED') {
            accountBalances++;

            const unformattedTimestamp = row.timestamp.split(' ')[0];
            const timestamp = this.convertTimestamp(unformattedTimestamp);
            const timestampIndex = timestamps.indexOf(timestamp);

            let totalAmount = (row.amount - row.fees).toFixed(2)
            if (row.coverFees === 'true') {
              totalAmount = row.amount;
            }

            totalDonations += Number(totalAmount);

            if (!uniqueDonors.hasOwnProperty(row.email)) {
              uniqueDonors[row.email.toLowerCase()] = 'exists';
            }

            if (timestampIndex == -1) {
              timestamps.push(timestamp);
              data.push(Number(totalAmount));
            }
            else {
              data[timestampIndex] += Number(totalAmount);
            }
          }
        })
        console.log('Unique donations ' + accountBalances)
        console.log(timestamps)
        console.log(data)
        this.setState({
          total: totalDonations,
          accountNames: this.props.accountNames,
          accountNames: Object.keys(uniqueDonors).length,
          accountBalances: accountBalances,
          chartData: {
            labels: timestamps.reverse(),
            datasets: [
              // Indigo line
              {
                label: 'Current',
                data: data.reverse(),
                borderColor: '#69995E',
                fill: false,
                borderWidth: 2,
                tension: 0,
                pointRadius: 0,
                pointHoverRadius: 3,
                pointBackgroundColor: '#324234',
              },
            ],
          }
        })
      });
  }

  render() {

    const { chartData, total, accountNames, accountBalances } = this.state;
    return (
      <div className="col-span-full xl:col-span-12 bg-white shadow-lg rounded-lg border border-gray-200">
  
        {/* Chart built with Chart.js 3 */}
        {/* Change the height attribute to adjust the chart height */}
        <BarChart data={chartData} total={total} accountNames={accountNames} accountBalances={accountBalances} height={200} />
      </div>
    );
  }
}

export default DashboardCard18;
