import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./phone.css";
import parsePhoneNumber from "libphonenumber-js";
import S3Upload from "../../uploads/S3Upload";
import { Storage, Amplify } from "aws-amplify";
import awsconfig from "../../../aws-exports";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import LogoUploader from "../../uploads/LogoUploader";
import ErrorAlert from "../../alerts/ErrorAlert";
import PayPalLogo from "../../../assets/paypal.png";
import DoubleTheDonationIntegration from "../../integrations/DoubleTheDonation/DoubleTheDonationIntegration";
import SuccessAlert from "../../alerts/SuccessAlert";
import { paragon } from "@useparagon/connect";
import ParagonSalesforce from "../../integrations/Paragon/ParagonSalesforce";

function CRM(props) {
  const { merchant, grabBaseMerchant } = props;

  const [merchantName, setMerchantName] = useState("");
  const [salesforceConnectionEnabled, setSalesforceConnectionEnabled] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [jwtToken, setJwtToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [paragonUser, setParagonUser] = useState(null);

  const initParagon = async () => {
    const data = await Auth.currentSession();
    const charityStackJWT = data["idToken"]["jwtToken"];
    const requestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: charityStackJWT,
      },
    };

    const response = await fetch(
      `${process.env.REACT_APP_PARAGON_URL}/get-jwt`,
      requestOptions
    );

    const responseJSON = await response.json();
    const paragonJWT = responseJSON["jwt"];
    const paragonProjectID = responseJSON["projectID"];
    console.log("paragonJWT", paragonJWT);
    console.log("paragonProjectID", paragonProjectID);
    const paragonAuth = await paragon.authenticate(
      // You can find your project ID in the Overview tab of any Integration
      paragonProjectID, // TODO: make into env variable

      // See Setup for how to encode your user token
      paragonJWT
    );
    const paragonUser = await paragon.getUser();
    setParagonUser(paragonUser);
  };

  useEffect(() => {
    if (props.merchant && props.merchant["merchantName"]) {
      let merchantName = props.merchant["merchantName"];
      if (merchant.merchantName) {
        setMerchantName(merchant.merchantName);
        console.log("Setting Salesforce value to: ", merchant.salesforceConnectionEnabled)
        setSalesforceConnectionEnabled(merchant.salesforceConnectionEnabled)
      }

    }
  }, [props.merchant]);

  useEffect(() => {
    Amplify.configure(awsconfig);

    if (props.merchant && props.merchant["merchantName"]) {
      let merchantName = props.merchant["merchantName"];
    }

    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
  }, [merchant]);

  useEffect(() => {
    initParagon();
  }, []);

  console.log("salesforceConnectionEnabled:", salesforceConnectionEnabled)
  return (
    <div>
      <div className="pb-6 text-2xl text-black">CRM</div>
      <div className="flex">
        {" "}
        {/* This flex container will hold all button boxes, left-aligned */}
        <div className="flex w-52 justify-center overflow-hidden rounded-lg border p-4 transition-shadow duration-200 hover:shadow-lg">
          {" "}
          {/* Adjust w-52 to control the width */}
          <DoubleTheDonationIntegration
            merchant={props.merchant}
            setSuccessMessage={setSuccessMessage} // Assuming you will define these functions
            setShowSuccessAlert={setShowSuccessAlert} // Assuming you will define these functions
          />
        </div>
        <div
          className={`flex w-52 justify-center overflow-hidden rounded-lg border p-4 transition-shadow duration-200 hover:shadow-lg ${
            salesforceConnectionEnabled === true
              ? ""
              : "hidden"
          }`}
        >
          {" "}
          {/* Adjust w-52 to control the width */}
          <ParagonSalesforce
            paragonUser={paragonUser}
            merchant={props.merchant}
            setSuccessMessage={setSuccessMessage} // Assuming you will define these functions
            setShowSuccessAlert={setShowSuccessAlert} // Assuming you will define these functions
          />
        </div>
      </div>
      <div></div>
      {showSuccessAlert && (
        <SuccessAlert
          message={
            successMessage != "" ? successMessage : "Changes Saved Successfully"
          }
          setShowSuccessAlert={this.setShowSuccessAlert}
        />
      )}
    </div>
  );
}

export default CRM;
