import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Select, { components } from 'react-select'; // Import components from the main react-select package

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '1px solid #324234' : '1px solid #cccccc',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #324234',
      boxShadow: 'none',
    },
    cursor: 'default',
  }),
};

function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const SortableMultiValue = SortableElement((props) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableSelect = SortableContainer(Select);

export default function SingleSelectDropdown(props) {
  const handleChange = (selectedOption, actionMeta) => {
    if (selectedOption) {
      const newValue = [selectedOption.value];
      if (props.dataIndex !== undefined) {
        props.setItems(newValue, props.dataIndex);
      } else {
        props.setItems(newValue);
      }
    } else {
      // Handle case when an existing option is cleared (selectedOption is null or undefined)
      // For example, reset the value or do nothing based on your requirements.
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(props.items, oldIndex, newIndex);
    if (props.dataIndex !== undefined) {
      props.setItems(newValue, props.dataIndex);
    } else {
      props.setItems(newValue);
    }
  };

  const selectedValue = props.items && props.items[0] ? { value: props.items[0], label: props.items[0] } : null;

  return (
    <SortableSelect
      helperClass="z-40 visible"
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      isMulti={false} // Set to true to make it single-select with the ability to create new options
      placeholder={props.placeholder}
      onChange={handleChange}
      options={props.options}
      styles={customStyles}
      value={selectedValue}
      components={{
        MultiValue: SortableMultiValue,
      }}
      closeMenuOnSelect={true} // Set to true to allow closing the menu after selecting an option
    />
  );
}
