/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, React, useEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XMarkIcon, ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import DonorSubscriptions from "../lists/DonorSubscriptions";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import UpdatePayment from "../UpdatePayment";
import SuccessAlert from "../alerts/SuccessAlert";
import CancelDonorSubscriptionAlert from "../alerts/CancelDonorSubscriptionAlert";
import { useSelector } from "react-redux";
import RefundModal from "../modals/RefundModal";
import ConfirmationRefund from "../payment-methods/ConfirmationRefund";
import { Auth } from "aws-amplify";
import dollarFormat from "../../utils/DollarFormat";
import { setRef } from "@mui/material";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function capitalizeFirstLetter(string) {
  const words = string.split(" ");

  for (let i = 0; i < words.length; i++) {
    if (words[i].length > 1) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    } else if (words[i].length == 1) {
      words[i] = words[i].charAt(0).toUpperCase();
    }
  }

  return words.join(" ");
}

// splits array into an array of chunks
function chunkArray(inputArray, chunkSize) {
  const resultArray = [];
  for (let i = 0; i < inputArray.length; i += chunkSize) {
    resultArray.push(inputArray.slice(i, i + chunkSize));
  }
  return resultArray;
}

const paymentMethodMap = {
  GOOGLE_PAY: "Google Pay",
  APPLE_PAY: "Apple Pay",
  PAYPAL: "PayPal",
  VENMO: "Venmo",
  PAY_BY_BANK: "Pay By Bank",
  CARD: "Card",
};

export default function TransactionSlideover(props) {
  const [jwtToken, setJWTToken] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [refundData, setRefundData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [showUpdatePayment, setShowUpdatePayment] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [refundsEnabled, setRefundsEnabled] = useState(false);
  const [refundConfirmationIDs, setRefundConfirmationIDs] = useState([]);

  const [refundReason, setRefundReason] = useState("Duplicate"); // used in refund modal and passed to backend

  const { readOnly } = useSelector((state) => state.readOnly); // this contains the variable which youll use to check
  {
    /*
            If the readOnly variable is truthy (evaluates to true), it will return null. In other words, nothing will be rendered in this case.
            If the readOnly variable is falsy (evaluates to false), it will execute the code block enclosed in the parentheses ( and ). This code block can contain JSX elements or other JavaScript code that will be rendered or executed when readOnly is falsy.
            */
  }

  let transformedTickets = [];
  let tickets = [];
  let funds = [];
  let fund = "";
  let coverFees = "No";
  let fees = "0";
  let name = "";
  let email = "";
  let phone = "N/A";
  let address1 = "N/A";
  let address2 = "N/A";
  let address3 = "N/A";
  let showAddress2 = { display: "none" };
  let showAddress3 = { display: "none" };
  let stateColor =
    "px-3 text-center text-green-800 bg-green-100 rounded-full ml-3";
  let donorStatus = "Recurring";
  let showRefundButtons = true;

  let monthlyDonation = 0;
  let grossAmountVar = "";
  let netAmount = 0;
  let formName = "";
  let refunded = [];
  let frequency = "";
  let totalCharged = 0;
  let refundedTotal = 0;
  let fileID = "";
  let paymentMethod = "N/A";
  let paymentProcessor = "";
  let transactionID = "";
  let fundKeyToSubscriptionID = {};
  console.log(props);

  if (props.selectedTransaction != null) {
    fundKeyToSubscriptionID =
      props.selectedTransaction.fundKeyToSubscriptionID ?? {};
    transactionID = props.selectedTransaction.transactionID;
    totalCharged = props.selectedTransaction.totalCharged;
    console.log("totalCharged", totalCharged);
    frequency = props.selectedTransaction.donationFrequency;
    refunded = props.selectedTransaction.refunded ?? [];
    let state = props.selectedTransaction.state;
    fileID = props.selectedTransaction.fileID ?? "";
    paymentMethod = props.selectedTransaction.paymentMethod;
    paymentProcessor = props.selectedTransaction.paymentProcessor ?? "";
    console.log("paymentProcessor for transaction: ", paymentProcessor);

    // sum refundAmount in refunded array rounded to 2 decimal places
    refundedTotal = parseFloat(
      refunded
        .reduce((acc, refundedItem) => {
          return acc + parseFloat(refundedItem.refundAmount);
        }, 0)
        .toFixed(2)
    );

    const full_refund = refundedTotal === totalCharged;
    console.log("full_refund", full_refund);
    console.log("refundedTotal", refundedTotal);
    console.log("totalCharged", totalCharged);

    showRefundButtons =
      !full_refund &&
      refundsEnabled &&
      showRefundButtons &&
      readOnly == false &&
      (state.includes("Success") ||
        (state.includes("Refund") && !state.includes("Full Refund"))) &&
      fileID === ""; // no file ID should exist

    console.log("showRefundButtons", showRefundButtons);
    console.log(state);
    formName = props.selectedTransaction.formName;

    //check if fund, funds, or tickets exist
    fund = props.selectedTransaction.fund ?? "";
    funds = props.selectedTransaction.funds ?? [];
    tickets = props.selectedTransaction.tickets ?? [];

    if (tickets.length > 0) {
      // create a map of ticketID to seats
      const ticketIDToSeats = {};

      // group tickets by ticketID
      const groupedTickets = {};

      tickets.forEach((ticket) => {
        const { ticketID } = ticket;

        if (!groupedTickets[ticketID]) {
          groupedTickets[ticketID] = [];
        }

        ticketIDToSeats[ticketID] = ticket.seats;
        groupedTickets[ticketID].push(ticket);
      });

      // group tickets by ticketID
      // but if there are more tickets with the same ticketID than seats, then create a group for those extra tickets
      // by group i mean a new ticket with an added field called name which is the firstName + lastName of each ticket
      // merged into it comma separated
      Object.keys(groupedTickets).forEach((ticketID) => {
        const tickets = groupedTickets[ticketID];
        const seats = ticketIDToSeats[ticketID];

        let ticketChunks = chunkArray(tickets, seats);
        // iterate through ticketChunks
        // push each chunk into transformedTickets with a new field called name
        // that name field will be the firstName + lastName of each ticket in the chunk
        // merged into it comma separated
        ticketChunks.forEach((chunk) => {
          const name = chunk
            .map((ticket) => ticket.firstName + " " + ticket.lastName)
            .join(", ");
          // construct a list of confirmationIDs
          const confirmationIDs = chunk.map((ticket) => ticket.confirmationID);

          transformedTickets.push({
            ...chunk[0],
            name, // used for displaying
            confirmationIDs, // used to send to backend
          });
        });
      });
    }

    coverFees = props.selectedTransaction.coverFees;
    fees = parseFloat(props.selectedTransaction.fees);
    netAmount =
      props.selectedTransaction.amount - props.selectedTransaction.fees;
    if (coverFees == "Yes") {
      netAmount = props.selectedTransaction.grossAmount;
    } else {
      netAmount =
        props.selectedTransaction.grossAmount - props.selectedTransaction.fees;
    }
    name = capitalizeFirstLetter(props.selectedTransaction.name);
    email = props.selectedTransaction.email;

    donorStatus = props.selectedTransaction.donorType;
    grossAmountVar = parseFloat(props.selectedTransaction.grossAmount);

    if (
      props.selectedTransaction.address != "" &&
      props.selectedTransaction.address != null
    ) {
      address1 = props.selectedTransaction.address;
      address2 = props.selectedTransaction.address2;
      address3 =
        props.selectedTransaction.city +
        ", " +
        props.selectedTransaction.region +
        " " +
        props.selectedTransaction.zip;
      showAddress3 = {};
      if (
        props.selectedTransaction.address2 != "" &&
        props.selectedTransaction.address2 != null
      ) {
        showAddress2 = {};
      }
    }
    if (
      (props.selectedTransaction.phonenumber != "") &
      (props.selectedTransaction.phonenumber != null)
    ) {
      phone = props.selectedTransaction.phonenumber;
    }
    //set color for monthly donor (green), repeat? (blue), one-time (red)
    if (props.selectedTransaction.donorType == "One-Time") {
      stateColor = "px-3 text-center text-red-800 bg-red-200 rounded-full ml-3";
    }
    if (props.selectedTransaction.donorType == "Repeat") {
      stateColor =
        "px-3 text-center text-blue-800 bg-blue-100 rounded-full ml-3";
    }
    if (props.selectedTransaction.donorType == "Canceled") {
      stateColor =
        "px-3 text-center text-yellow-800 bg-yellow-100 rounded-full ml-3";
    }
  }

  const initiateRefund = async () => {
    if (refundData.refundAmount == 0) {
      setShowErrorAlert(true);
      setErrorMessage("Refund amount must be greater than $0.00");
      setInProgress(false);
      return;
    }
    setInProgress(true);
    const requestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        refundAmount: String(refundData.refundAmount),
        refundType: refundData.refundType,
        transactionID: refundData.transactionID,
        // refundItem: refundData.refundItem,
        refundTicketConfirmationIDs: refundConfirmationIDs,
        refundReason: refundReason,
        nonprofitName: props.selectedTransaction.merchantName,
        charityStackID: props.selectedTransaction.transactionID,
        // fundraiser: refundData.fundraiser,
        // frequency: refundData.frequency,
        // paymentMethod: refundData.paymentMethod,
        paymentProcessor: refundData.paymentProcessor,
      }),
    };
    console.log("props", props);
    console.log("refundData", refundData);
    console.log("requestOptions", requestOptions);
    console.log("paymentMethod", paymentMethod);
    // submitting transaction to donors-data lambda

    if (paymentProcessor === "PAYPAL") {
      try {
        let response = await fetch(
          `${process.env.REACT_APP_PAYPAL_REFUNDS_URL}`,
          requestOptions
        );
        let responseBody = await response.json();
        console.log("responseBody from paypal", responseBody);
        if (!response.ok) {
          console.log("response not ok");
          setShowErrorAlert(true);
          setErrorMessage(responseBody.error ?? responseBody.message);
          setInProgress(false);
        } else {
          setRefundConfirmationIDs([]);
          setShowErrorAlert(false);
          setErrorMessage("");
          // props.updateData();
          // props.setOpen(false);
          setOpenModal(false);
          setShowConfirmation(true);
          let selectedTransaction = props.selectedTransaction;
          selectedTransaction.refunded = responseBody;
          console.log(selectedTransaction);
          props.setSelectedTransaction(selectedTransaction);
          console.log("updating data now");
          await new Promise((r) => setTimeout(r, 2000));
          props.updateData(props.limit);
          setInProgress(false);
          // clearStates();
        }
      } catch (error) {
        setShowErrorAlert(true);
        setErrorMessage("Something went wrong. Please try again later.");
        setInProgress(false);
      }
    }
    if (paymentProcessor === "STRIPE") {
      try {
        console.log("Stripe Refund");
        console.log(`${process.env.REACT_APP_REFUND_URL}/stripe`);
        let response = await fetch(
          `${process.env.REACT_APP_REFUND_URL}/stripe`,
          requestOptions
        );
        console.log("response", response);

        let responseBody = await response.json();
        console.log("responseBody", responseBody);
        if (!response.ok) {
          console.log("response not ok");
          setShowErrorAlert(true);
          setErrorMessage(responseBody.error ?? responseBody.message);
          setInProgress(false);
        } else {
          setRefundConfirmationIDs([]);
          setShowErrorAlert(false);
          setErrorMessage("");
          // props.updateData();
          // props.setOpen(false);
          setOpenModal(false);
          setShowConfirmation(true);
          let selectedTransaction = props.selectedTransaction;
          selectedTransaction.refunded = responseBody;
          console.log(selectedTransaction);
          props.setSelectedTransaction(selectedTransaction);
          console.log("updating data now");
          // sleep for 2 seconds
          await new Promise((r) => setTimeout(r, 2000));
          props.updateData(props.limit);
          setInProgress(false);
          // clearStates();
        }
      } catch (error) {
        setShowErrorAlert(true);
        console.log("error: ", error);
        setErrorMessage("Something went wrong. Please try again later.");
        setInProgress(false);
      }
    }
    if (
      paymentProcessor === "FINIX" ||
      paymentProcessor === "" ||
      paymentProcessor === undefined ||
      paymentProcessor === null ||
      paymentProcessor === "null"
    ) {
      try {
        let response = await fetch(
          `${process.env.REACT_APP_REFUND_URL}/finix`,
          requestOptions
        );
        let responseBody = await response.json();
        console.log("responseBody", responseBody);
        if (!response.ok) {
          console.log("response not ok");
          setShowErrorAlert(true);
          setErrorMessage(responseBody.error ?? responseBody.message);
          setInProgress(false);
        } else {
          setRefundConfirmationIDs([]);
          setShowErrorAlert(false);
          setErrorMessage("");
          // props.updateData();
          // props.setOpen(false);
          setOpenModal(false);
          setShowConfirmation(true);
          let selectedTransaction = props.selectedTransaction;
          selectedTransaction.refunded = responseBody;
          console.log(selectedTransaction);
          props.setSelectedTransaction(selectedTransaction);
          console.log("updating data now");
          // sleep for 2 seconds
          await new Promise((r) => setTimeout(r, 2000));
          props.updateData(props.limit);
          setInProgress(false);
          // clearStates();
        }
      } catch (error) {
        setShowErrorAlert(true);
        setErrorMessage("Something went wrong. Please try again later.");
        setInProgress(false);
      }
    }
  };

  const checkIfRefunded = (refunded, ticketConfirmationIDs) => {
    console.log("checking if refunded", ticketConfirmationIDs, refunded);
    // console.log(refunded.filter((refundedItem) => refundedItem.name));

    // console.log(
    //   refunded.filter((refundedItem) => refundedItem.name === item).length
    // );
    // check if the item is in the refunded array for ticket refunds
    if (ticketConfirmationIDs != null) {
      console.log("ticketConfirmationIDs != null");
      console.log("refundedItem", refunded);

      // find the refunded item that matches the item we are checking
      if (
        refunded.filter((refundedItem) => {
          console.log("refundedItem", refundedItem);

          return (
            // refundedItem.name === item &&
            ticketConfirmationIDs.every((val, index) => {
              return (
                refundedItem.ticketConfirmationIDs &&
                refundedItem.ticketConfirmationIDs.includes(val)
              );
            })
          );
        }).length > 0
      ) {
        return true;
      }
      return false;
    }
    // // fund or fee refund. check if the item is in the refunded array
    // else if (
    //   refunded.filter((refundedItem) => refundedItem.name === item).length > 0
    // ) {
    //   console.log("true");
    //   return true;
    // }
    console.log("false");

    return false;
  };

  useEffect(() => {
    console.log("changed open", props.open);
  }, [props.open]);

  useEffect(() => {
    console.log("props.selectedTransaction changed", props.selectedTransaction);
    let paymentProcessor = props.selectedTransaction?.paymentProcessor ?? "";
    if (
      paymentProcessor === "" ||
      paymentProcessor === "FINIX" ||
      paymentProcessor === undefined ||
      paymentProcessor === null ||
      paymentProcessor === "null" ||
      paymentProcessor === "undefined"
    ) {
      console.log("paymentProcessor for transaction: ", paymentProcessor);
      setRefundsEnabled(true);
    }
    if (paymentProcessor === "STRIPE") {
      console.log("paymentProcessor for transaction: ", paymentProcessor);
      setRefundsEnabled(true);
    }
  }, [props.selectedTransaction]);

  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      setJWTToken(data["idToken"]["jwtToken"]);
    };
    myFunction();
  }, []);

  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
          {showConfirmation === true && (
            <ConfirmationRefund
              openConfirmation={showConfirmation}
              setOpenConfirmation={setShowConfirmation}
            />
          )}
          <RefundModal
            refundReason={refundReason}
            setRefundReason={setRefundReason}
            open={openModal}
            setOpen={setOpenModal}
            slideoverOpen={props.open}
            refundData={refundData}
            setRefundData={setRefundData}
            initiateRefund={initiateRefund}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            showErrorAlert={showErrorAlert}
            setShowErrorAlert={setShowErrorAlert}
            paymentMethod={paymentMethod}
            inProgress={inProgress}
            tickets={transformedTickets.filter((ticket) => {
              // check if ticket.confirmationIDs array values are present anywhere
              // in refunded array, where refund.ticketConfirmationIDs contains all the confirmationIDs
              // per refund. do not include transformedTicket if it is already refunded
              return !checkIfRefunded(refunded, ticket.confirmationIDs);
            })}
            refundConfirmationIDs={refundConfirmationIDs}
            setRefundConfirmationIDs={setRefundConfirmationIDs}
          />
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    {showSuccessAlert && (
                      <div className="fixed z-20">
                        <SuccessAlert
                          message={successMessage}
                          setShowSuccessAlert={setShowSuccessAlert}
                        />
                      </div>
                    )}
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <div className="space-y-1">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              Transaction Details
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              Below is information pertaining to the transaction
                              you selected.
                            </p>
                          </div>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => props.setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Transaction Info */}
                      <div>
                        <div className="pb-1 sm:pb-6">
                          <div>
                            {/* <div className="relative h-40 sm:h-56">
                                                        <img
                                                            className="absolute h-full w-full object-cover"
                                                            src="https://images.unsplash.com/photo-1501031170107-cfd33f0cbdcc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&h=600&q=80"
                                                            alt=""
                                                        />
                                                    </div> */}
                            <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                              <div className="sm:flex-1">
                                <div className="flex grid grid-cols-1 sm:grid-cols-2">
                                  <div className="col-span-2">
                                    <div className="flex items-center">
                                      <h3 className="text-xl font-bold text-gray-900">
                                        {name}
                                      </h3>

                                      <button className={stateColor}>
                                        {donorStatus}
                                      </button>
                                    </div>
                                    <div className="flex">
                                      <EnvelopeIcon
                                        className="mr-1.5 mt-1 h-4 w-4 flex-shrink-0 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      <p className="text-sm text-gray-500">
                                        {email}
                                      </p>
                                    </div>
                                    <div className="text-sm text-gray-500">
                                      Transaction ID: {transactionID}
                                    </div>
                                  </div>
                                  <div className="ml-10 flex justify-end sm:col-start-2">
                                    {/* only show this button if the transaction has not been refunded at all yet (partial or full) */}
                                    {showRefundButtons && totalCharged != 0 && (
                                      <button
                                        onClick={() => {
                                          console.log(
                                            "grossAmountVar",
                                            grossAmountVar
                                          );
                                          console.log("fees", fees);
                                          // setRefundAmount(
                                          //   parseFloat(grossAmountVar) +
                                          //     parseFloat(fees)
                                          // );
                                          setRefundData({
                                            refundAmount: (
                                              totalCharged - refundedTotal
                                            ).toFixed(2),
                                            transactionID:
                                              props.selectedTransaction
                                                .transactionID,
                                            // fundraiser: "",
                                            // frequency: "",
                                            paymentProcessor: paymentProcessor,
                                            refundConfirmationIDs:
                                              refundConfirmationIDs,
                                            setRefundConfirmationIDs:
                                              setRefundConfirmationIDs,
                                          });
                                          setOpenModal(true);
                                        }}
                                        className="focus:outline-none flex h-12
                                          items-center justify-center rounded-md
                                          border border-gray-200 border-transparent
                                          bg-white px-4 py-2 text-lg font-medium
                                          text-gray-500 shadow-sm hover:bg-gray-50
                                          focus:ring-2 focus:ring-offset-2"
                                      >
                                        <ArrowUturnLeftIcon className="mr-1 h-5 w-5" />
                                        <div>Refund</div>
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <dl className="grid h-36 grid-cols-1 px-4 sm:grid-cols-2 sm:px-6">
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                                Total Amount
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-1">
                                ${dollarFormat(netAmount)}
                              </dd>
                              <br />
                              <br />
                            </div>
                            <div className="ml-10 justify-self-end text-right sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                                Gross Amount
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-1">
                                ${dollarFormat(grossAmountVar)}
                              </dd>
                            </div>

                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                                Fee Amount
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-1">
                                ${dollarFormat(fees)}
                              </dd>
                              <br />
                              <br />
                            </div>
                            <div className="ml-10 justify-self-end text-right sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                                Fee Covered
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-1">
                                {coverFees}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                      {/* Refundable Items */}

                      {/* fund column transaction */}
                      {fund !== "" && transformedTickets.length == 0 && (
                        <>
                          <div
                            className="min-h-52 mt-4 h-52 rounded-md border-2 border-gray-200 bg-gray-50 px-6 pt-8"
                            style={{ minHeight: "208px" }}
                            // style={{
                            //   boxShadow: "inset 0px 0px 0px 2px #d1d5db",
                            // }}
                          >
                            <div className="flex justify-between">
                              <div className="text-gray-500">
                                <div>Fundraiser: {formName}</div>
                                <div>Fund: {fund}</div>
                                <div>
                                  Amount: $
                                  {dollarFormat(
                                    props.selectedTransaction.grossAmount
                                  )}
                                </div>
                              </div>
                              {/* {showRefundButtons &&
                                checkIfRefunded(refunded, fund) === false && (
                                  <button
                                    onClick={() => {
                                      setRefundData({
                                        refundAmount:
                                          props.selectedTransaction.grossAmount,
                                        transactionID:
                                          props.selectedTransaction
                                            .transactionID,
                                        refundType: "FUND_REFUND",
                                        refundItem: fund,
                                        fundraiser: formName,
                                        frequency: frequency,
                                        paymentProcessor: paymentProcessor,

                                      });
                                      setOpenModal(true);
                                    }}
                                    className="focus:outline-none flex h-12 items-center justify-center rounded-md border border-gray-200 border-transparent bg-white px-4 py-2 text-lg font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-offset-2"
                                  >
                                    <ArrowUturnLeftIcon className="mr-1 h-5 w-5" />
                                    <div>Refund</div>
                                  </button>
                                )} */}
                            </div>
                          </div>
                        </>
                      )}
                      {/* multi fund transaction */}
                      {funds.length > 0 && (
                        <div>
                          {/* iterate through every fund in funds array */}
                          {funds.map((item, i) => {
                            const refundedCopy = [...refunded]; // We will use this to remove refunded funds
                            // grab fund quantity from refunded if it exists. otherwise set it to 0
                            let refundedQuantity = refunded.reduce(
                              (count, refundedItem) => {
                                if (
                                  refundedItem.name === item.fundName &&
                                  refundedItem.fundraiser === item.fundraiser &&
                                  parseFloat(refundedItem.refundAmount) ===
                                    parseFloat(item.amount) &&
                                  refundedItem.frequency === item.frequency
                                ) {
                                  return count + 1;
                                } else {
                                  return count;
                                }
                              },
                              0
                            );
                            console.log("item", item);
                            console.log("refundedQuantity", refundedQuantity);
                            console.log("refunded", refundedCopy);
                            return (
                              <>
                                {/* render every fund "quantity" number of times */}

                                {Array.from(
                                  { length: item.quantity },
                                  (_, index) => {
                                    // dont show button for refunded quantities
                                    let alreadyRefunded = false;

                                    if (refundedQuantity !== 0) {
                                      refundedQuantity = refundedQuantity - 1;
                                      alreadyRefunded = true;
                                    }
                                    return (
                                      <div
                                        className={`min-h-52 mt-4 h-52 rounded-md border-2 border-gray-200 bg-gray-50 px-6 pt-8`}
                                        style={{ minHeight: "208px" }}
                                        // style={{
                                        //   boxShadow: "inset 0px 0px 0px 2px #d1d5db",
                                        // }}
                                      >
                                        <div className="flex justify-between">
                                          <div className="text-gray-500">
                                            {fundKeyToSubscriptionID[
                                              item.cartItemKey
                                            ] && (
                                              <div>
                                                Subscription ID:{" "}
                                                {
                                                  fundKeyToSubscriptionID[
                                                    item.cartItemKey
                                                  ]
                                                }
                                              </div>
                                            )}

                                            <div>
                                              Fundraiser: {item.fundraiser}
                                            </div>
                                            <div>Fund: {item.fundName}</div>
                                            <div>
                                              Amount: $
                                              {parseFloat(item.amount).toFixed(
                                                2
                                              )}
                                            </div>
                                          </div>
                                          {/* {showRefundButtons &&
                                            !alreadyRefunded && (
                                              <button
                                                onClick={() => {
                                                  setRefundData({
                                                    refundAmount: parseFloat(
                                                      item.amount
                                                    ),
                                                    transactionID:
                                                      props.selectedTransaction
                                                        .transactionID,
                                                    refundType: "FUND_REFUND",
                                                    refundItem: item.fundName,
                                                    fundraiser: item.fundraiser,
                                                    frequency: item.frequency,
                                                    paymentProcessor: paymentProcessor,

                                                  });
                                                  setOpenModal(true);
                                                }}
                                                className="focus:outline-none flex h-12 items-center justify-center rounded-md border border-gray-200 border-transparent bg-white px-4 py-2 text-lg font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-offset-2"
                                              >
                                                <ArrowUturnLeftIcon className="mr-1 h-5 w-5" />
                                                <div>Refund</div>
                                              </button>
                                            )} */}
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            );
                          })}
                        </div>
                      )}
                      {/* tickets/event transaction */}
                      {transformedTickets.length > 0 && (
                        // iterate through tickets. create a name attribute that is firstName + lastName with a space in between

                        <>
                          {/* iterate through every fund in funds array */}
                          {transformedTickets.map((item, i) => {
                            return (
                              <>
                                {/* render every fund "quantity" number of times */}

                                <div
                                  className={`min-h-52 mt-4 h-52 rounded-md border-2 border-gray-200 bg-gray-50 px-6 pt-8`}
                                  style={{ minHeight: "208px" }}
                                  // style={{
                                  //   boxShadow: "inset 0px 0px 0px 2px #d1d5db",
                                  // }}
                                >
                                  <div className="flex justify-between">
                                    <div className="text-gray-500">
                                      <div>Event: {formName}</div>
                                      <div>Ticket: {item.ticketName}</div>
                                      <div>Attendees: {item.name}</div>
                                      <div>
                                        Amount: $
                                        {dollarFormat(parseFloat(item.price))}
                                      </div>
                                      {item.promoCode && (
                                        <div>Promo Code: {item.promoCode}</div>
                                      )}
                                      {item.discount && (
                                        <div>
                                          Discount: $
                                          {dollarFormat(
                                            parseFloat(item.discount)
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    {/* {showRefundButtons &&
                                      item.price - (item.discount ?? 0) > 0 &&
                                      checkIfRefunded(
                                        refunded,
                                        item.ticketName,
                                        item.confirmationIDs
                                      ) === false && (
                                        <button
                                          onClick={() => {
                                            setRefundData({
                                              refundAmount:
                                                item.discount !== undefined &&
                                                item.discount !== null
                                                  ? parseFloat(
                                                      (
                                                        item.price -
                                                        item.discount
                                                      ).toFixed(2)
                                                    )
                                                  : parseFloat(item.price),
                                              transactionID:
                                                props.selectedTransaction
                                                  .transactionID,
                                              refundType: "TICKET_REFUND",
                                              refundItem: item.ticketName,
                                              refundConfirmationIDs:
                                                item.confirmationIDs,
                                              fundraiser: formName,
                                              frequency: frequency,
                                              paymentProcessor: paymentProcessor,

                                            });
                                            setOpenModal(true);
                                          }}
                                          className="focus:outline-none flex h-12 items-center justify-center rounded-md border border-gray-200 border-transparent bg-white px-4 py-2 text-lg font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-offset-2"
                                        >
                                          <ArrowUturnLeftIcon className="mr-1 h-5 w-5" />
                                          <div>Refund</div>
                                        </button>
                                      )} */}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      )}
                      {coverFees == "Yes" && grossAmountVar != 0 && (
                        <div
                          className="min-h-52 mt-4 h-52 rounded-md border-2 border-gray-200 bg-gray-50 px-6 pt-8"
                          style={{ minHeight: "208px" }}
                          // style={{
                          //   boxShadow: "inset 0px 0px 0px 2px #d1d5db",
                          // }}
                        >
                          <div className="flex justify-between">
                            <div className="text-gray-500">
                              <div>Fee</div>
                              <div>Amount: ${dollarFormat(fees)}</div>
                            </div>
                            {/* {showRefundButtons &&
                              checkIfRefunded(refunded, "Fee") === false && (
                                <button
                                  onClick={() => {
                                    setRefundData({
                                      refundAmount: parseFloat(fees),
                                      transactionID:
                                        props.selectedTransaction.transactionID,
                                      refundType: "FEE_REFUND",
                                      refundItem: "Fee",
                                      fundraiser: "",
                                      frequency: "",
                                      paymentProcessor: paymentProcessor,
                                    });
                                    setOpenModal(true);
                                  }}
                                  className="focus:outline-none flex h-12 items-center justify-center rounded-md border border-gray-200 border-transparent bg-white px-4 py-2 text-lg font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-offset-2"
                                >
                                  <ArrowUturnLeftIcon className="mr-1 h-5 w-5" />
                                  <div>Refund</div>
                                </button>
                              )} */}
                          </div>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
