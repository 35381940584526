import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import { TableFlowImporter } from "@tableflow/react";
import Papa from "papaparse";
import {
  ArrowUpTrayIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

const ImportTable = (props) => {
  const [jwtToken, setJWTToken] = useState(null);
  const [merchantName, setMerchantName] = useState("");
  const [dataList, setDataList] = useState([]);
  const [errored, setErrored] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [total, setTotal] = useState(0);
  const [fileName, setFileName] = useState("file.csv");
  const [presigned, setPresigned] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [numRows, setNumRows] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [uniqueFormNames, setUniqueFormName] = useState([]);

  const convertTimestamp = (timestamp) => {
    const sections = timestamp.split("-");
    return sections[1] + "-" + sections[2] + "-" + sections[0];
  };

  useEffect(() => {
    if (merchantName !== "") {
      const interval = setInterval(getImportFileData, 120000); // 120k ms = 2 min
      return () => clearInterval(interval);
    }
    console.log("merchantName is 47", merchantName);
  }, [merchantName]);

  useEffect(() => {
    if (props.selectableForms !== null && props.selectableForms !== undefined) {
      getUniqueFormNames();
    }
  }, [props.selectableForms]);

  useEffect(() => {
    if (props.merchant != null && props.jwtToken != null) {
      setMerchantName(props.merchant.merchantName);
      setJWTToken(props.jwtToken);
      getInitialImportFileData(props.merchant.merchantName);
      console.log("butter");
    }
  }, [props.merchant, props.jwtToken]);

  const onImportFlatFile = () => {
    setIsOpen(true);
  };

  const getPresignedUrl = async (fileName) => {
    setLoading(true);

    // grab file data
    const formsRequestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: props.jwtToken,
      },
    };

    const presignedURL = `${
      process.env.REACT_APP_IMPORT_URL
    }/presigned-url?nonprofitName=${encodeURIComponent(
      props.merchant.merchantName
    )}&&fileName=${encodeURIComponent(fileName)}`;

    let response = await fetch(presignedURL, formsRequestOptions);
    let data = await response.json();
    console.log(data);

    setLoading(false);
    setPresigned(data.presigned_url);

    return data.presigned_url;
  };

  const onCompleteUploadTableFlow = async (data) => {
    console.log("data import table", data);
    const fileName = data.file_name;
    console.log("props", props);

    const rows = data.rows;
    const cols = data.columns;
    setFileName(fileName);
    setNumRows(data.num_rows);

    // const options = {
    //   mode: "no-cors", // This will cause the request to be made with no CORS request headers
    //   method: "GET",
    //   headers: { Authorization: "Bearer tf_b46933a8a04146679d6a0989d87e822e" },
    // };
    //const targetUrl = `https://api.tableflow.com/v1/import/${data.id}/download`;
    try {
      // let response = await fetch(targetUrl, options);
      // console.log("options", options);
      const processDataToCSV = () => {
        const processedData = rows.map((row) => {
          return cols.reduce((acc, column) => {
            acc[column.key] = row.values[column.key];
            return acc;
          }, {});
        });

        return Papa.unparse(processedData);
      };
      const csvData = processDataToCSV();
      const dataBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

      const s3File = new File([dataBlob], fileName, { type: "text/csv" });

      // check if file is less than 1GB
      if (s3File.size > 1073741824) {
        // 1GB in bytes
        setError("File size exceeds 1GB");
        setErrored(true);
      } else if (data.num_rows > 200000) {
        setError("File has too many rows, max 200K");
        setErrored(true);
      } else {
        try {
          const presignedUrl = await getPresignedUrl(s3File.name);

          console.log(presignedUrl);
          // Use the pre-signed URL to upload the file directly to S3

          const result = await fetch(presignedUrl, {
            method: "PUT", // Use the same method as specified when generating the pre-signed URL
            body: s3File, // The file to be uploaded
            headers: {
              "Content-Type": s3File.type, // Ensure this matches the type expected by your pre-signed UR
            },
          });

          console.log("result", result);

          if (result.status === 200) {
            setIsOpen(false);
            // send data upload for api
            postImportFileData(data.num_rows, fileName);
          } else {
            setError("Failed to upload file.");
            setErrored(true);
            setIsOpen(false);
          }
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const postImportFileData = async (num_row, file) => {
    setLoading(true);
    // grab file data
    const formsRequestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: props.jwtToken,
      },
      body: JSON.stringify({
        fileName: file,
        nonprofitName: props.merchant.merchantName,
        numRows: num_row,
      }),
    };

    const uploadUrl = `${process.env.REACT_APP_IMPORT_URL}/import-file-dashboard`;

    let response = await fetch(uploadUrl, formsRequestOptions);
    let data = await response.json();

    if (data.error) {
      setError(data.error + " post");
      setErrored(true);
      setIsOpen(false);
      return;
    } else {
      getImportFileData();
    }
  };

  const getImportFileData = async () => {
    setLoading(true);
    // grab file data
    console.log("hello", merchantName);
    const formsRequestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: props.jwtToken,
      },
    };

    const importURL = `${
      process.env.REACT_APP_IMPORT_URL
    }/import-file-dashboard?nonprofitName=${encodeURIComponent(
      props.merchant.merchantName
    )}`;

    let response = await fetch(importURL, formsRequestOptions);
    let data = await response.json();

    let end = 20;
    if (data.length < 20) {
      end = data.length;
    }

    const sorted = data.sort((a, b) => {
      return new Date(b.dateLastUpdated) - new Date(a.dateLastUpdated);
    });
    setDataList(sorted);

    setLoading(false);
    setStart(0);
    setEnd(end);
    setTotal(data.length);
  };

  const getUniqueFormNames = () => {
    // Filter out forms where formType is not 'EVENT'
    const filteredForms = props.selectableForms.filter(
      (form) => form.formType !== "EVENT"
    );

    // Extract the formName from the filtered forms
    const formNames = filteredForms.map((form) => form.formName);

    // To ensure uniqueness, you can use a Set
    const uniqueFormName = [...new Set(formNames)];
    setUniqueFormName(uniqueFormName);
  };

  const getInitialImportFileData = async (merchantNm) => {
    setLoading(true);
    // grab file data

    let jwt = null;
    if (props.jwtToken != null && props.jwtToken != undefined) {
      jwt = props.jwtToken;
    } else {
      const data = await Auth.currentSession();
      jwt = data["idToken"]["jwtToken"];
    }

    const formsRequestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwt,
      },
    };

    const importURL = `${
      process.env.REACT_APP_IMPORT_URL
    }/import-file-dashboard?nonprofitName=${encodeURIComponent(merchantNm)}`;

    let response = await fetch(importURL, formsRequestOptions);
    let data = await response.json();

    let end = 20;
    if (data.length < 20) {
      end = data.length;
    }

    const sorted = data.sort((a, b) => {
      return new Date(b.dateLastUpdated) - new Date(a.dateLastUpdated);
    });
    setDataList(sorted);

    setLoading(false);
    setStart(0);
    setEnd(end);
    setTotal(data.length);
  };

  const goNext = (e) => {
    e.preventDefault();
    if (end !== dataList.length) {
      setStart(start + 20);
      setEnd(Math.min(end + 20, dataList.length));
    }
  };

  const goPrev = (e) => {
    e.preventDefault();
    if (start !== 0) {
      setStart(Math.max(start - 20, 0));
      setEnd(start);
    }
  };

  let headers = [
    { label: "Date", key: "dateLastUpdated" },
    { label: "Name", key: "fileName" },
    { label: "Records", key: "records" },
    { label: "Status", key: "status" },
  ];

  let next =
    "btn focus:outline-none  bg-white border-gray-100 hover:border-gray-300 text-light";
  let prev =
    "btn focus:outline-none bg-white border-gray-100 hover:border-gray-300 text-light";
  if (end === total) {
    next =
      "btn focus:outline-none bg-white border-gray-100 text-gray-300 cursor-not-allowed";
  }
  if (start === 0) {
    prev =
      "btn focus:outline-none bg-white border-gray-100 text-gray-300 cursor-not-allowed";
  }

  return (
    <div className="col-span-full xl:col-span-12">
      <div className="space-y-2">
        {errored && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-red-800">{error}</p>
              </div>
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    onClick={() => setErrored(false)}
                    className="focus:outline-none inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                  >
                    <span className="sr-only">Dismiss</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {!loading &&
          props.merchant !== null &&
          props.merchant !== undefined && (
            <div className="flex flex-row justify-end py-3 ">
              <div className="flex">
                <button
                  type="button"
                  onClick={onImportFlatFile}
                  className="focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 inline-flex items-center gap-x-2 rounded-md bg-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-400"
                >
                  <ArrowUpTrayIcon
                    className="-ml-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  Import
                </button>
              </div>
            </div>
          )}
        <div>
          {props.merchant !== null && props.merchant !== undefined && (
            <TableFlowImporter
              importerId={process.env.REACT_APP_TABLE_FLOW_IMPORTER_ID} // Use your importer ID
              modalIsOpen={isOpen}
              modalOnCloseTriggered={() => setIsOpen(false)}
              waitOnComplete={true}
              darkMode={false}
              primaryColor="#22C55E"
              metadata={{ user: props.merchant }} // Pass through user or import info
              onComplete={onCompleteUploadTableFlow}
              template={{
                columns: [
                  {
                    name: "First Name",
                    key: "first_name",
                    required: true,
                    data_type: "string",
                    description: "The first name of the user",
                    suggested_mappings: ["First Name", "Name", "First"],
                  },
                  {
                    name: "Last Name",
                    key: "last_name",
                    required: true,
                    data_type: "string",
                    description: "The last name of the user",
                    suggested_mappings: ["Last Name", "Last"],
                  },
                  {
                    name: "Transaction Amount",
                    key: "transaction_amount",
                    required: true,
                    data_type: "number",
                    description: "The transaction amount",
                    suggested_mappings: ["amount"],
                  },
                  {
                    name: "Date",
                    key: "date",
                    required: true,
                    data_type: "Date",
                    description: "The date of transaction",
                    suggested_mappings: ["date"],
                  },
                  {
                    name: "Fee",
                    key: "fee",
                    required: true,
                    data_type: "Number",
                    description: "The fee for transaction",
                    suggested_mappings: ["fee"],
                  },
                  {
                    name: "Fee Covered",
                    key: "fee_covered",
                    required: true,
                    data_type: "Boolean",
                  },
                  {
                    name: "Address Line 1",
                    key: "address_line",
                    required: false,
                    data_type: "string",
                  },
                  {
                    name: "Address Line 2",
                    key: "address_line_2",
                    required: false,
                    data_type: "string",
                  },
                  {
                    name: "City",
                    key: "city",
                    data_type: "string",
                    suggested_mappings: ["city"],
                  },
                  {
                    name: "Country",
                    key: "country",
                    data_type: "string",
                    suggested_mappings: ["country"],
                  },
                  {
                    name: "Region",
                    key: "region",
                    data_type: "string",
                    description: "Region or State",
                    suggested_mappings: ["region", "state"],
                  },
                  {
                    name: "Zip Code",
                    key: "zip_code",
                    data_type: "string",
                    suggested_mappings: ["zip cpde", "zip", "postal code"],
                  },
                  {
                    name: "Email",
                    key: "email",
                    required: true,
                    data_type: "string",
                    validations: [
                      {
                        validate: "email",
                      },
                      {
                        validate: "not_blank",
                      },
                    ],
                    suggested_mappings: ["Email"],
                  },
                  {
                    name: "Phone Number",
                    key: "phone_number",
                    required: true,
                    data_type: "string",
                    validations: [
                      {
                        validate: "phone",
                      },
                      {
                        validate: "not_blank",
                      },
                    ],
                    suggested_mappings: ["Phone Number", "Phone"],
                  },
                  {
                    name: "Fund",
                    key: "fund",
                    required: true,
                    data_type: "string",
                    suggested_mappings: ["Fund"],
                  },
                  {
                    name: "Payment Method",
                    key: "payment_method",
                    required: true,
                    data_type: "string",
                    validations: [
                      {
                        validate: "list",
                        options: [
                          "Card",
                          "Apple Pay",
                          "Google Pay",
                          "Paypal",
                          "Venmo",
                          "Cash",
                          "Check",
                          "Donor Advised Fund",
                          "Stock Transfer",
                          "Bank Transfer",
                          "Zelle",
                        ],
                      },
                      {
                        validate: "not_blank",
                      },
                    ],
                  },
                  {
                    name: "Fundraiser",
                    key: "fundraiser",
                    required: true,
                    data_type: "string",
                    description: "form name / fundraiser name",
                    suggested_mappings: [
                      "form name",
                      "form",
                      "fundraiser",
                      "fundraiser name",
                    ],
                    validations: [
                      {
                        validate: "list",
                        options: uniqueFormNames,
                      },
                      {
                        validate: "not_blank",
                      },
                    ],
                  },
                ],
              }}
            />
          )}
        </div>

        {/* flatfile object Copied */}
        <div className="border-gray-2000 col-span-full rounded-lg border bg-white shadow-sm xl:col-span-12">
          {/* Table */}
          {loading &&
            (props.merchant == null || props.merchant === undefined) && (
              <div class="min-w-screen flex min-h-screen justify-center bg-gray-100 p-5 pt-32">
                <div class="flex animate-pulse space-x-2">
                  <div class="h-3 w-3 rounded-full bg-gray-500"></div>
                  <div class="h-3 w-3 rounded-full bg-gray-500"></div>
                  <div class="h-3 w-3 rounded-full bg-gray-500"></div>
                </div>
              </div>
            )}
          <div className="p-3">
            <div>
              {dataList.length == 0 &&
                !loading &&
                props.merchant !== null &&
                props.merchant !== undefined && (
                  <div
                    class="rounded-2xl border border-blue-100 bg-white p-8 shadow-lg"
                    role="alert"
                    id="noMatchFound"
                  >
                    <div class="items-center sm:flex">
                      <span class="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#a1afc2"
                          class="h-6 w-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                          />
                        </svg>
                      </span>

                      <p class="mt-3 text-lg font-medium sm:ml-3 sm:mt-0">
                        No Imports Found
                      </p>
                    </div>

                    <p class="mt-4 text-gray-500">
                      You haven't imported any transactions into our platform.
                      <br></br>To get started click the "Import" button above.{" "}
                    </p>
                  </div>
                )}
              <div className="overflow-x-auto">
                {dataList.length > 0 && (
                  <div className="overflow-x-auto">
                    <table className="w-full table-auto">
                      {/* Table header */}
                      <thead className="rounded-sm bg-gray-50 text-xs uppercase text-gray-400">
                        <tr>
                          <th className="p-2">
                            <div className="text-left font-semibold">Date</div>
                          </th>
                          <th className="p-2">
                            <div className="text-left font-semibold">Name</div>
                          </th>
                          <th className="p-2">
                            <div className="text-center font-semibold">
                              Records
                            </div>
                          </th>
                          <th className="p-2">
                            <div className="text-center font-semibold">
                              Status
                            </div>
                          </th>
                        </tr>
                      </thead>
                      {/* Table body */}
                      <tbody className="divide-y divide-gray-100 text-sm font-medium">
                        {dataList.slice(start, end).map((imported, index) => {
                          // donor.grossAmount = (donor.grossAmount).toLocaleString(undefined, { maximumFractionDigits: 2 })

                          const unformattedTimestamp =
                            imported.dateLastUpdated.split(" ")[0];
                          const timestamp =
                            convertTimestamp(unformattedTimestamp);

                          let status = "Success";
                          if (imported.status == "IN_PROGRESS") {
                            status = "In Progress";
                          } else if (imported.status == "FAILED") {
                            status = "Failed";
                          } else {
                            status = "Success";
                          }

                          let statusColor = "";
                          if (imported.status == "IN_PROGRESS") {
                            statusColor =
                              "px-3 text-center text-yellow-700 bg-yellow-100 rounded-full hover:text-yellow-900 focus:outline-none";
                          } else if (imported.status == "FAILED") {
                            statusColor =
                              "px-3 text-center text-red-700 bg-red-100 rounded-full hover:text-red-900 focus:outline-none";
                          } else {
                            statusColor =
                              "px-3 text-center text-green-700 bg-green-100 rounded-full hover:text-green-900 focus:outline-none";
                          }

                          return (
                            <tr>
                              <td className="p-2">
                                <div className="items-center">
                                  <div className="text-s text-left">
                                    {timestamp}
                                  </div>
                                </div>
                              </td>

                              <td className="p-2">
                                <div className="items-center text-center">
                                  <div className="text-left">
                                    {imported.fileName}
                                  </div>
                                </div>
                              </td>
                              <td className="p-2">
                                <div className="items-center text-center">
                                  <div className="text-center">
                                    {imported.records}
                                  </div>
                                </div>
                              </td>
                              <td className="p-2">
                                <div className="items-center text-center">
                                  <button className={statusColor}>
                                    {status}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                        {}
                      </tbody>
                    </table>
                    <div className="mt-8">
                      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                        <nav
                          className="mb-4 sm:order-1"
                          role="navigation"
                          aria-label="Navigation"
                        >
                          <ul className="flex justify-center">
                            <li className="ml-3 first:ml-0">
                              <a className={prev} href="#0" onClick={goPrev}>
                                &lt;- Previous
                              </a>
                            </li>
                            <li className="ml-3 first:ml-0">
                              <a className={next} href="#0" onClick={goNext}>
                                Next -&gt;
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportTable;
