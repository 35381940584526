/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function Checkbox(props) {
  console.log("checkbox", props);
  return (
    <fieldset className={props.removeSpacing ? "" : "space-y-2"}>
      <legend className="sr-only">Checkbox</legend>
      <div className={props.removeSpacing ? "" : "relative flex items-end"}>
        <div className="flex h-5 items-center">
          <input
            id={props.label}
            name={props.label}
            type="checkbox"
            className="text-primary focus:ring-secondary h-4 w-4 rounded border-gray-300"
            checked={props.checked}
            onChange={() => props.onChange(!props.checked)}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={props.label} className="font-medium text-gray-700">
            {props.label}
          </label>
        </div>
      </div>
    </fieldset>
  );
}
