import React, { Component } from 'react'
import { StrictMode } from 'react/cjs/react.production.min';
import PaginationClassic from '../PaginationClassic';
import moment from 'moment';
import {Auth} from "aws-amplify";
import BarChart01 from '../../charts/BarChart01';

export class BankingAccountTable extends Component {
  constructor(props) {
    super(props)
    let end = 6
    
    if (this.props.chapters.length < 3) {
        end = this.props.chapters.length
    }
    console.log('hello im inside banking')
    this.state = {
      start: 0,
      end: end,
      total: this.props.chapters.length
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.chapters[0] !== this.props.chapters[0]) {
        let end = 6
        if (this.props.chapters.length < 3) {
            end = this.props.chapters.length
        }
        console.log('hello im inside banking')
        this.setState({
        start: 0,
        end: end,
        total: this.props.chapters.length
        })
    }
  }


  render() {
    const { transactionList, start, end, total } = this.state;
    let next = "btn bg-white border-gray-200 hover:border-gray-300 text-light"
    let prev = "btn bg-white border-gray-200 hover:border-gray-300 text-light"
    if (end === total) {
      next = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed"
    }
    if (start === 0) {
      prev = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed"
    }

   

    return (
      <div className="">

        {/* <header className="px-5 py-4 border-b border-gray-100">
         <h2 className="font-semibold text-gray-800">Transactions</h2> 
        </header> */}
      
          {/* Table */}
              {/* Table header */}
       
              {/* Table body */}
                  <div>
                <p class="text-4xl sm:text-7xl hover:text-blue-800">${(this.props.totalBalance).toLocaleString()}</p> 
                         </div>
                
    
      </div>
    );
  }
}

export default BankingAccountTable