import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  BanknotesIcon,
} from "@heroicons/react/20/solid";
import CancelDonorSubscriptionAlert from "../alerts/CancelDonorSubscriptionAlert";
import { useEffect, useState } from "react";
import { ReactComponent as Visa } from "../../assets/visa.svg";
import { ReactComponent as Discover } from "../../assets/discover.svg";
import { ReactComponent as Mastercard } from "../../assets/mastercard.svg";
import { ReactComponent as AmericanExpress } from "../../assets/american-express.svg";

function capitalizeFirstLetter(string) {
  const words = string.split(" ");

  for (let i = 0; i < words.length; i++) {
    if (words[i].length > 1) {
      words[i] =
        words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    } else if (words[i].length == 1) {
      words[i] = words[i].charAt(0).toUpperCase();
    }
  }

  return words.join(" ");
}

export default function DonorSubscriptions(props) {
  const [showCancelSubscriptionAlert, setShowCancelSubscriptionAlert] =
    useState(false);
  const [fund, setFund] = useState("");
  const [netAmount, setNetAmount] = useState("");
  const [subscriptionID, setSubscriptionID] = useState("");

  const cancelSubscription = async () => {
    const requestOptions = {
      method: "PATCH",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: props.jwtToken,
      },
      body: JSON.stringify({
        paymentMethod: "CANCEL",
        subscriptionID: subscriptionID,
      }),
    };

    // submitting transaction to donors-data lambda
    try {
      let response = await fetch(
        process.env.REACT_APP_DONORS_URL,
        requestOptions
      );
      let responseBody = await response.json();
      if (!response.ok) {
        // setShowErrorAlert(true)
        // setErrorMessage(responseBody)
        // setSendingData(false)
      } else {
        props.setShowSuccessAlert(true);
        props.setSuccessMessage("Your subscription has been canceled.");
        setShowCancelSubscriptionAlert(false);
        props.updateData();
        // setShowErrorAlert(false)
        // setErrorMessage("")
        // props.setOpen(false)
        // setSendingData(false)
      }
    } catch (error) {
      console.log("error");
      // setShowErrorAlert(true)
      // setErrorMessage(error.message)
      // setSendingData(false)
    }
  };

  return (
    <>
      {/* CancelDonorSubscriptionAlert is only shown when Cancel is selected for a donor subscription */}
      <CancelDonorSubscriptionAlert
        open={showCancelSubscriptionAlert}
        setOpen={setShowCancelSubscriptionAlert}
        fund={fund}
        netAmount={netAmount}
        cancelSubscription={cancelSubscription}
      />

      <div className="overflow-hidden border border-gray-300 bg-white shadow-lg sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {props.selectedDonorSubscriptions.map((subscription) => {
            let state = "ACTIVE";
            let stateColor =
              "px-3 text-center text-green-800 bg-green-100 rounded-full text-xs font-medium uppercase tracking-wide";
            let frequencyColor = "";
            let frequencyIndicator = "";
            let donationFrequency = subscription.donationFrequency;
            let displayedFund = subscription.fund;
            let fundLabel = "Fund:";
            if (
              subscription.funds !== null &&
              subscription.funds !== undefined &&
              subscription.funds.length > 0
            ) {
              if (subscription.funds.length > 1) {
                fundLabel = "Funds:";
              }
              // make fund equal to a nicely formatted stringified array of funds
              displayedFund = subscription.funds
                .map((item) => item.fundName)
                .join(", ");
            }

            if (donationFrequency === "MONTHLY") {
              frequencyColor =
                "px-3 text-center text-cyan-800 bg-cyan-400 rounded-full text-xs font-medium uppercase tracking-wide";
              frequencyIndicator = "per month";
            }
            if (donationFrequency === "WEEKLY") {
              frequencyColor =
                "px-3 text-center text-cyan-800 bg-cyan-400 rounded-full text-xs font-medium uppercase tracking-wide";
              frequencyIndicator = "per week";
            }
            if (
              donationFrequency === "YEARLY" ||
              donationFrequency === "ANNUALLY"
            ) {
              frequencyColor =
                "px-3 text-center text-cyan-800 bg-cyan-400 rounded-full text-xs font-medium uppercase tracking-wide";
              frequencyIndicator = "per year";
            }
            if (donationFrequency === "DAILY") {
              frequencyColor =
                "px-3 text-center text-cyan-800 bg-cyan-400 rounded-full text-xs font-medium uppercase tracking-wide";
              frequencyIndicator = "per day";
            }
            if (subscription.subscriptionStatus === "DEACTIVATED") {
              state = "CANCELED";
              stateColor =
                "px-3 text-center text-yellow-800 bg-yellow-100 rounded-full text-xs font-medium uppercase tracking-wide";
            }
            if (subscription.subscriptionStatus === "ERROR") {
              state = "FAILED";
              stateColor =
                "px-3 text-center text-yellow-800 bg-red-100 rounded-full text-xs font-medium uppercase tracking-wide";
            }
            if (subscription.subscriptionStatus === "FINISHED") {
              state = "COMPLETED";
            }

            let output = "";
            if (subscription.paymentMethod == "PAY_BY_BANK") {
              output =
                subscription.bankAccountType === null ||
                subscription.bankAccountType === undefined
                  ? ""
                  : `${capitalizeFirstLetter(
                      subscription.bankAccountType.toLowerCase()
                    )} ****${subscription.bankAccountLastFourDigits}`;
            } else {
              output =
                subscription.cardBrand === null ||
                subscription.cardBrand === undefined
                  ? ""
                  : `${capitalizeFirstLetter(
                      subscription.cardBrand.toLowerCase()
                    )} ****${subscription.cardLastFourDigits} - Expires ${
                      subscription.cardExpirationDate
                    }`;
            }

            let cardLogo = <></>;
            if (
              subscription.paymentMethod !== "PAY_BY_BANK" &&
              subscription.cardBrand !== null
            ) {
              if (subscription.cardBrand === "VISA") {
                cardLogo = <Visa className="-mt-1 mr-2 h-7 w-7" />;
              }
              if (subscription.cardBrand === "AMERICANEXPRESS") {
                cardLogo = <AmericanExpress className="-mt-1 mr-2 h-7 w-7" />;
              }
              if (subscription.cardBrand === "MASTERCARD") {
                cardLogo = <Mastercard className="-mt-1 mr-2 h-7 w-7" />;
              }
              if (subscription.cardBrand === "DISCOVER") {
                cardLogo = <Discover className="-mt-1 mr-2 h-7 w-7" />;
              }
            }

            // format subscription.dateNextCharged to something similar to September 01, 2021
            let next_date = new Date(subscription.dateNextCharge);
            let next_month = next_date.toLocaleString("default", {
              month: "long",
            });
            let next_day = next_date.getDate();
            let next_year = next_date.getFullYear();
            let nextChargeDate = next_month + " " + next_day + ", " + next_year;

            // format subscription.dateEndCharge to something similar to September 01, 2021
            let endChargeDate = "INFINITY";
            if (subscription.dateEndCharge !== "INFINITY") {
              let end_date = new Date(subscription.dateEndCharge);
              let end_month = end_date.toLocaleString("default", {
                month: "long",
              });
              let end_day = end_date.getDate();
              let end_year = end_date.getFullYear();
              endChargeDate = end_month + " " + end_day + ", " + end_year;
            }

            return (
              <li key={subscription.email + " | " + subscription.name}>
                {/* <a href={application.href} className="block hover:bg-gray-50"> */}
                <div className="flex items-center py-4 ">
                  <div className="flex min-w-0 flex-1 items-center">
                    <div className="flex-shrink-0">
                      {/* <img className="h-12 w-12 rounded-full" src={application.applicant.imageUrl} alt="" /> */}
                    </div>
                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
                      <div className="col-span-2">
                        <div className="flex grid grid-cols-2">
                          <p className="text-primary col-span-1 truncate text-sm font-medium">
                            $
                            {(
                              ((subscription.amount - subscription.fees) *
                                Math.pow(10, 2)) /
                              Math.pow(10, 2)
                            )
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            {frequencyIndicator}
                          </p>
                          <p className="col-span-1 text-sm text-gray-900">
                            {/* Applied on <time dateTime={application.date}>{application.dateFull}</time> */}
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "left",
                              }}
                            >
                              <button className={stateColor}>{state}</button>
                              {/*  &nbsp;
                                                            <button className={frequencyColor}>{capitalizeFirstLetter(donationFrequency)}</button> */}
                            </span>
                          </p>
                        </div>
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          {/* <BanknotesIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
                          <span className="truncate">
                            {fundLabel} {displayedFund}
                          </span>
                        </p>
                        {subscription.subscriptionStatus === "ACTIVE" && (
                          <div className="flex text-sm text-gray-500">
                            The next donation will occur on
                            <div className="font-medium">
                              &nbsp;{nextChargeDate}
                            </div>
                          </div>
                        )}
                        {subscription.subscriptionStatus === "ACTIVE" &&
                          endChargeDate !== "INFINITY" && (
                            <div className="flex text-sm text-gray-500">
                              The recurring donation will end on
                              <div className="font-medium">
                                &nbsp;{endChargeDate}
                              </div>
                            </div>
                          )}
                        <br />
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          <div>
                            <div className="flex">
                              <div className="inline-block flex text-sm text-gray-500">
                                {subscription.paymentMethod !==
                                  "PAY_BY_BANK" && <div>{cardLogo}</div>}
                                <div>{output}</div>
                              </div>
                            </div>
                          </div>
                        </p>
                      </div>
                      <div className="ml-8 hidden md:block">
                        <div>
                          {/* <p className="text-sm text-gray-900">
                                                        Applied on <time dateTime={application.date}>{application.dateFull}</time> 
                                                    <button className={stateColor}>{state}</button>
                                                </p> */}
                          {state == "ACTIVE" && (
                            <p className="flex justify-end space-x-4 text-sm text-gray-500">
                              <button
                                type="button"
                                name="Update"
                                className="bg-primary hover:bg-secondary focus:outline-none focus:ring-secondary rounded-md border border-transparent px-2 py-1 text-xs font-medium text-white shadow-sm focus:ring-2 focus:ring-offset-2"
                                onClick={() => {
                                  props.setSelectedSubscription(subscription);
                                  props.setShowUpdatePayment(true);
                                }}
                              >
                                Update
                              </button>
                              <button
                                type="button"
                                name="Cancel"
                                className="focus:outline-none rounded-md border border-transparent bg-red-600 px-2 py-1 text-xs font-medium text-white shadow-sm hover:bg-red-800 focus:ring-2 focus:ring-red-700 focus:ring-offset-2"
                                onClick={() => {
                                  setFund(displayedFund);
                                  console.log(
                                    (
                                      (Math.round(
                                        subscription.amount - subscription.fees
                                      ) *
                                        Math.pow(10, 2)) /
                                      Math.pow(10, 2)
                                    )
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  );
                                  setNetAmount(
                                    (
                                      (Math.round(
                                        subscription.amount - subscription.fees
                                      ) *
                                        Math.pow(10, 2)) /
                                      Math.pow(10, 2)
                                    )
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  );
                                  setSubscriptionID(subscription.id);
                                  setShowCancelSubscriptionAlert(true);
                                }}
                              >
                                Cancel
                              </button>
                              {/* <CheckCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400" aria-hidden="true" /> */}
                              {/* {application.stage} */}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div> */}
                </div>
                {/* </a> */}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
