import React, { useEffect, useState } from "react";
import CRM from "./CRM";
import Processors from "./Processors";
import { Auth } from "aws-amplify"; // remove later when we release paypal

function Integrations(props) {
  const [tab, setTab] = useState("CRM");
  const [user, setUser] = useState(null); // remove later when we release paypal

  useEffect(() => {
    console.log("tab", tab);
    // remove this below later when we release paypal
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user.username);
      })
      .catch((err) => console.log(err));
    // remove the above later when we release paypal
  }, [tab]);

  return (
    <div
      className="flex grid grid-cols-7 rounded-md border-2 border-gray-300"
      style={{ minHeight: "calc(100vh - 300px)" }}
    >
      <div className="col-span-2 row-span-full border-r-2 border-gray-200">
        <div
          onClick={() => setTab("CRM")}
          className="cursor-pointer border-b-2 border-gray-200 p-4 hover:bg-gray-200"
        >
          <div className="text-base font-medium text-black">CRM</div>
          <div className="text-sm leading-4 text-gray-400">
            Import your donor data from your existing CRM or database
          </div>
        </div>
        {/* remove the checks below later when we release paypal */}
        {
          <div
            onClick={() => setTab("Processors")}
            className="cursor-pointer border-b-2 border-gray-200 p-4 hover:bg-gray-200"
          >
            <div className="text-base font-medium text-black">Processors</div>
            <div className="text-sm leading-4 text-gray-400">
              Connect to a processor to accept payments or expand payment
              options
            </div>
          </div>
        }
        {/* <div
          onClick={() => setTab("THEME")}
          className="cursor-pointer border-b-2 border-gray-200 p-4 hover:bg-gray-200"
        >
          <div className="text-base font-medium text-black">Theme</div>
          <div className="text-sm leading-4 text-gray-400">
            Select theme of system
          </div>
        </div> */}
      </div>
      <div className="relative col-span-5 p-4">
        <div className={`${tab === "CRM" ? "" : "hidden"}`}>
          <CRM
            merchant={props.merchant}
            grabBaseMerchant={props.grabBaseMerchant}
            jwtToken={props.jwtToken}
          />
        </div>
        {tab === "Processors" && (
          <Processors
            merchant={props.merchant}
            grabBaseMerchant={props.grabBaseMerchant}
            jwtToken={props.jwtToken}
          />
        )}
      </div>
    </div>
  );
}

export default Integrations;
