import React, { useState, useEffect } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setReadOnly } from "./redux/slices/readOnly";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";

import "./css/style.scss";

import { focusHandling } from "cruip-js-toolkit";
import "./charts/ChartjsConfig";

//amplify
import Amplify, { Auth, Hub } from "aws-amplify";
import awsconfig from "./aws-exports";
import {
  AmplifySignOut,
  AmplifySignIn,
  withAuthenticator,
} from "@aws-amplify/ui-react";

// Import pages
import Dashboard from "./pages/Dashboard";
import Donations from "./pages/Donations";
import Donors from "./pages/Donors";
import Home from "./pages/Home";
import Banking from "./pages/Banking";
import ComingSoon from "./pages/ComingSoon";
import Customers from "./pages/Customers";
import Orders from "./pages/Orders";
import Campaigns from "./pages/Campaigns";
import TeamTabs from "./pages/TeamTabs";
import TeamTiles from "./pages/TeamTiles";
import Messages from "./pages/Messages";
import Tasks from "./pages/Tasks";
import Applications from "./pages/Applications";
import Settings from "./pages/Settings";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword";
import Funds from "./pages/Funds";
//import Forms from './pages/Forms';
import Fundraisers from "./pages/Fundraisers";
import Events from "./pages/Events";
import Tickets from "./pages/Tickets";

import Payouts from "./pages/Payouts";

// Configure
const awsConfig = {
  aws_project_region: "us-east-2",
  aws_cognito_identity_pool_id:
    "us-east-2:3f22d0ba-ec02-497e-80b5-4f8427ba280e",
  Auth: {
    region: "us-east-2",
    userPoolId: "us-east-2_rgQU9hI0d",
    userPoolWebClientId: "23jtveguege5k7f0f05abtjugo",
  },
};

Amplify.configure(awsConfig);

function App() {
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null);
  const location = useLocation();
  const [selectableFunds, setSelectableFunds] = useState([]);
  const [selectableForms, setSelectableForms] = useState([]);
  // const [readOnly, setReadOnly] = useState(null);

  const [jwtToken, setJWTToken] = useState(null);
  const [merchant, setMerchant] = useState(null);
  const [user, setUser] = useState(undefined); // State to hold user information
  const [groups, setGroups] = useState(null);

  const { readOnly, readOnlySet } = useSelector((state) => state.readOnly);
  const dispatch = useDispatch();

  useEffect(() => {
    // Check the authentication status when the component mounts
    checkAuthStatus();

    // Listen for changes in authentication status
    const authListener = Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        const userGroups =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        const formattedGroups = userGroups.map((group) =>
          group.replace(/-/g, " ")
        );
        setGroups(formattedGroups);
      })
      .catch(() => {
        setUser(null);
      });

    // Clean up the listener when the component unmounts
    return () => {
      authListener();
    };
  }, []);

  const checkAuthStatus = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user); // Set the user state if authenticated
    } catch (error) {
      setUser(null); // Set user state to null if not authenticated
    }
  };

  // update read_only state based on user
  useEffect(() => {
    if (user === undefined || readOnlySet === true) {
      // We can't determine the current authentication state yet or it has already been set
      // console.log("user is undefined");
    } else if (user === null) {
      // User is not logged in
      // console.log("user is not logged in");
      dispatch(setReadOnly(false));
    } else {
      // User is logged in
      // console.log("user is logged in");
      dispatch(setReadOnly(user.attributes["custom:read_only"] === "true"));
      // set stripe connect instance
      setStripeConnectInstance(
        loadConnectAndInitialize({
          // This is your test publishable API key.
          publishableKey: process.env.REACT_APP_STRIPE_PK,
          fetchClientSecret: getStripeAccountSession,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#69995E",
              fontFamily: "Inter, sans-serif",
            },
          },
        })
      );
    }
  }, [user]);

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, [location.pathname]); // triggered on route change

  // console.log("readOnly", readOnly);
  // console.log("readOnlySet", readOnlySet);

  const getStripeAccountSession = async () => {
    const data = await Auth.currentSession();
    const jwtToken = data.idToken.jwtToken;
    const response = await fetch(
      `${process.env.REACT_APP_STRIPE_BACKEND}/account_session`,
      {
        method: "POST",
        headers: {
          accept: "*/*",
          "content-type": "application/json",
          Authorization: jwtToken,
        },
      }
    );
    const session = await response.json();
    console.log("session", session);
    return session.client_secret;
  };
  if (groups !== null) {
    const routes = (
      <Switch>
        {readOnly === true && (
          <>
            <Route exact path="/">
              <Home
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
                groups={groups}
              />
            </Route>

            <Route exact path="/transactions">
              <Donations
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
                groups={groups}
              />
            </Route>

            {/* <Route exact path="/dashboard">
            <Dashboard 
              jwtToken={jwtToken}
              setJWTToken={setJwtToken}
              selectableFunds={selectableFunds}
              setSelectableFunds={setSelectableFunds}
            />
          </Route> */}

            <Route exact path="/funds">
              <Funds
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
                groups={groups}
              />
            </Route>

            <Route exact path="/comingsoon">
              <ComingSoon />
            </Route>
            {/*
            <Route exact path="/settings">
              <Settings
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
              />
            </Route>
        */}
            <Route exact path="/contacts">
              <Donors
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
                groups={groups}
              />
            </Route>
            <Route exact path="/payouts">
              <Payouts
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
                groups={groups}
              />
            </Route>
            {/*
            <Route exact path="/events">
              <Events
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
              />
            </Route>
            */}

            <Route exact path="/tickets">
              <Tickets
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
                groups={groups}
              />
            </Route>
          </>
        )}

        {readOnly === false && (
          <>
            <Route exact path="/">
              <Home
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
                groups={groups}
              />
            </Route>

            <Route exact path="/transactions">
              <Donations
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
                groups={groups}
              />
            </Route>

            <Route exact path="/dashboard">
              <Dashboard
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                groups={groups}
              />
            </Route>
            <Route exact path="/funds">
              <Funds
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
                groups={groups}
              />
            </Route>

            <Route exact path="/fundraisers">
              <Fundraisers
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
                groups={groups}
              />
            </Route>

            <Route exact path="/settings">
              <Settings
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
                groups={groups}
              />
            </Route>

            <Route exact path="/contacts">
              <Donors
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
                groups={groups}
              />
            </Route>

            <Route exact path="/payouts">
              <Payouts
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
                groups={groups}
              />
            </Route>

            <Route exact path="/events">
              <Events
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
                groups={groups}
              />
            </Route>
            <Route exact path="/tickets">
              <Tickets
                jwtToken={jwtToken}
                setJWTToken={setJWTToken}
                selectableFunds={selectableFunds}
                setSelectableFunds={setSelectableFunds}
                merchant={merchant}
                setMerchant={setMerchant}
                selectableForms={selectableForms}
                setSelectableForms={setSelectableForms}
                groups={groups}
              />
            </Route>
          </>
        )}

        <AmplifySignIn headerText="CharityStack" hideSignUp></AmplifySignIn>
      </Switch>
    );

    return (
      <>
        {stripeConnectInstance ? (
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            {routes}
          </ConnectComponentsProvider>
        ) : (
          { routes }
        )}
      </>
    );
  }
}

export default withAuthenticator(App, false);
