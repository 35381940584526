import React, { Component } from "react";
import { StrictMode } from "react/cjs/react.production.min";
import PaginationClassic from "../PaginationClassic";
import moment from "moment";
import { Auth } from "aws-amplify";
import DonationDatePicker from "./DonationDatePicker";
import { CSVLink } from "react-csv";
import EditFormSlideover from "../slideovers/EditFormSlideover";
import EditDonationFlagSlideover from "../slideovers/EditDonationFlagSlideover";
import EditFundraisingBarSlideover from "../slideovers/EditFundraisingBarSlideover";
import EditQRCodeSlideover from "../slideovers/EditQRCodeSlideover";
import EditDonorList from "../slideovers/EditDonorList";
export class FormsTable extends Component {
  constructor(props) {
    super(props);

    const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
    let today = new Date();
    // increment today forward by 1 day
    today.setDate(today.getDate() + 1);
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() - 6000);
    const startTimerange =
      tomorrow.toLocaleDateString("en-US", DATE_OPTIONS) +
      " - " +
      today.toLocaleDateString("en-US", DATE_OPTIONS);
    this.state = {
      jwtToken: "",
      donorsList: [],
      start: 0,
      end: 0,
      total: 0,
      totalElements: 0,
      timerange: startTimerange,
      showFilter: false,
      openDonationFlag: false,
      openFundraisingBar: false,
      openQRCode: false,
      openDonorList: false,
      editElementData: null,
      formsData: null,
    };
  }

  setOpenDonorList = (toggle) => {
    this.setState({ openDonorList: toggle });
  };

  setOpenQRCode = (toggle) => {
    this.setState({ openQRCode: toggle });
  };

  setOpenDonationFlag = (toggle) => {
    this.setState({ openDonationFlag: toggle });
  };

  setOpenFundraisingBar = (toggle) => {
    this.setState({ openFundraisingBar: toggle });
  };

  onFilterClickHandler = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  async componentDidMount() {
    const data = await Auth.currentSession();
    let user = await Auth.currentAuthenticatedUser();
    this.setState(
      {
        jwtToken: data["idToken"]["jwtToken"],
        whoIsLoggedIn: user.username,
      },
      () => this.updateData()
    );
  }

  componentDidUpdate() {
    const { totalElements } = this.state;
    this.props.getElementMetrics(totalElements); //, totalDonationSumTwoDecimals, totalDonations, totalDonors)
  }

  convertTimestamp(timestamp) {
    const sections = timestamp.split("-");
    return sections[1] + "-" + sections[2] + "-" + sections[0];
  }

  setDateTimeRange = (timerange) => {
    this.setState({ timerange: timerange }, () => this.updateData());
  };

  onEditHandler = (e) => {
    console.log("in one edit handler", e);
    const { formsData } = this.state;
    console.log(e.target.id);
    const nonprofitName = e.target.id.split(" | ")[0];
    const elementName = e.target.id.split(" | ")[1];
    //  console.log("nonprofitname", nonprofitName)
    //  console.log("elementName", elementName)
    // console.log("formsdata", formsData)
    const elementData = formsData.find(
      (form) =>
        form.nonprofitName === nonprofitName && form.elementName === elementName
    );
    //   console.log("elementData", elementData)
    if (elementData.elementType === "Donation Flag") {
      this.setState({
        openDonationFlag: true,
        openFundraisingBar: false, // Close the other slideover if it was open
        openQRCode: false,
        openDonorList: false,
        editElementData: elementData,
      });
    } else if (elementData.elementType === "Fundraising Bar") {
      this.setState({
        openDonationFlag: false, // Close the other slideover if it was open
        openFundraisingBar: true,
        openQRCode: false,
        openDonorList: false,
        editElementData: elementData,
      });
    } else if (elementData.elementType === "QR Code") {
      this.setState({
        openDonationFlag: false, // Close the other slideover if it was open
        openFundraisingBar: false,
        openQRCode: true,
        openDonorList: false,
        editElementData: elementData,
      });
    } else if (elementData.elementType === "Donor List") {
      this.setState({
        openDonationFlag: false, // Close the other slideover if it was open
        openFundraisingBar: false,
        openQRCode: false,
        openDonorList: true,
        editElementData: elementData,
      });
    }
  };

  updateData = async () => {
    // grab forms data
    const formsRequestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: this.state.jwtToken,
      },
    };

    const elementsURL = `${process.env.REACT_APP_ELEMENTS_URL}/elements`;

    let formsResponse = await fetch(elementsURL, formsRequestOptions);
    let formsData = await formsResponse.json();

    // Sort the formsData array based on the elementName key
    formsData.sort((a, b) => {
      const elementNameA = a.elementName.toLowerCase();
      const elementNameB = b.elementName.toLowerCase();
      if (elementNameA < elementNameB) {
        return -1;
      } else if (elementNameA > elementNameB) {
        return 1;
      } else {
        return 0;
      }
    });

    // Create a map of formname string to states from formsdata
    const elementsActive = new Map();
    //console.log("hello ")

    // Create a map of formName string to set of active funds
    formsData = formsData.filter((element) => {
      console.log("hello ", element.elementType);
      if (
        element.elementType === "EMBED_FORM" ||
        element.elementType === "CROWDFUNDING_FORM" ||
        element.elementType == "QUICK_DONATE"
      ) {
        return false; // Exclude the element from the filtered array
      } else {
        const key = element.nonprofitName + " | " + element.elementName;
        elementsActive.set(key, element.active);
        return true; // Include the element in the filtered array
      }
    });

    const totalActiveForms = formsData.length;

    this.setState(
      {
        formsData: formsData,
        donorsList: formsData,
        start: 0,
        end: 20,
        totalElements: totalActiveForms,
      },
      () => {
        // Call the remaining code that depends on the sorted donorsList array
        // For example, you can call this.props.getElementMetrics(totalActiveForms) here
        // Or any other code that relies on the updated donorsList array
      }
    );

    // ...remaining code for fetching transactions data...
  };

  goNext = (e) => {
    e.preventDefault();
    const { start, end, donorsList } = this.state;
    if (end !== donorsList.length) {
      this.setState({
        start: start + 20,
        end: Math.min(end + 20, donorsList.length),
      });
    }
  };

  goPrev = (e) => {
    e.preventDefault();
    const { start, end, total } = this.state;
    if (start !== 0) {
      this.setState({
        start: Math.max(start - 20, 0),
        end: start,
      });
    }
  };

  headers1 = [
    { label: "Form Name", key: "formName" },
    { label: "Fund", key: "fund" },
    { label: "Total Raised", key: "grossAmountVar" },
    { label: "Total Donations", key: "totalDonationNumberCounter" },
  ];

  render() {
    const {
      openDonationFlag,
      openFundraisingBar,
      openQRCode,
      donorsList,
      start,
      end,
      total,
      showFilter,
      editElementData,
    } = this.state;
    const headers1 = this.headers1;
    let donationList1 = donorsList;

    let next = "btn bg-white border-gray-200 hover:border-gray-300 text-light";
    let prev = "btn bg-white border-gray-200 hover:border-gray-300 text-light";

    if (end === total) {
      next = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed";
    }
    if (start === 0) {
      prev = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed";
    }

    return (
      <div className="col-span-full xl:col-span-12">
        <EditDonationFlagSlideover
          grabSelectableFunds={this.props.grabSelectableFunds}
          open={openDonationFlag}
          setOpen={this.setOpenDonationFlag}
          selectableFunds={this.props.selectableFunds}
          editFormData={editElementData}
          updateData={this.updateData}
          merchant={this.props.merchant}
        />
        <EditFundraisingBarSlideover
          grabSelectableFunds={this.props.grabSelectableFunds}
          open={openFundraisingBar}
          setOpen={this.setOpenFundraisingBar}
          selectableFunds={this.props.selectableFunds}
          selectableForms={this.props.selectableForms}
          editFormData={editElementData}
          updateData={this.updateData}
          merchant={this.props.merchant}
        />
        <EditQRCodeSlideover
          grabSelectableFunds={this.props.grabSelectableFunds}
          open={openQRCode}
          setOpen={this.setOpenQRCode}
          selectableFunds={this.props.selectableFunds}
          editFormData={editElementData}
          updateData={this.updateData}
          merchant={this.props.merchant}
        />
        <EditDonorList
          open={this.state.openDonorList}
          setOpen={this.setOpenDonorList}
          editFormData={this.state.editElementData}
          updateData={this.updateData}
          merchant={this.props.merchant}
          selectableForms={this.props.selectableForms}
        />
        <div className="flex flex-row justify-end py-3 ">
          <div className="flex ">
            <button
              type="button "
              className="focus:outline-none inline-block flex focus:ring-2 focus:ring-offset-2 "
            >
              <CSVLink
                filename="CharityStack Forms Export.csv"
                data={donationList1}
                headers={headers1}
              >
                <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 17"
                    stroke="#a1afc2"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                  &nbsp;
                  <div className="text-gray-500 hover:text-gray-900 ">
                    {" "}
                    Export &nbsp;{" "}
                  </div>
                </div>
              </CSVLink>
            </button>
          </div>
        </div>

        {showFilter && (
          <div className="flex flex-row p-3">
            <DonationDatePicker setTimerange={this.setDateTimeRange} />
          </div>
        )}

        <div className="rounded-lg border border-[#D1D9E0] bg-white p-3">
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="w-full table-auto ">
              {/* Table header */}
              <thead className="rounded-sm bg-gray-50 text-xs uppercase text-gray-400">
                <tr>
                  <th className="p-2">
                    <div className="text-left font-semibold">Element</div>
                  </th>
                  <th className="p-2">
                    <div className="text-left font-semibold">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type
                    </div>
                  </th>
                  <th className="p-2">
                    <div className="text-center font-semibold">Status</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="divide-y divide-gray-100 text-sm font-medium">
                {donorsList.slice(start, end).map((donor, index) => {
                  // donor.grossAmount = (donor.grossAmount).toLocaleString(undefined, { maximumFractionDigits: 2 })
                  const id = donor.nonprofitName + " | " + donor.elementName;

                  donor.elementType = donor.elementType
                    .replace(/_/g, " ") // Replace underscores with spaces
                    .toLowerCase() // Convert to lowercase
                    .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word

                  if (donor.elementType === "Qr Code") {
                    // convert QR_CODE into QR Code
                    donor.elementType = "QR Code";
                  }

                  let status = donor.active == true ? "Active" : "Deactivated";
                  let statusColor =
                    donor.active == true
                      ? "px-3 text-center text-green-800 bg-green-100 rounded-full"
                      : "px-3 text-center text-red-800 bg-red-200 rounded-full";

                  return (
                    <tr>
                      <td className="p-2">
                        <div className="items-center">
                          <div className="text-s text-left">
                            <a
                              className="cursor-pointer font-medium text-gray-600 hover:text-gray-400"
                              onClick={this.onEditHandler}
                              id={id}
                            >
                              {donor.elementName}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="items-center">
                          <div className="text-s text-left">
                            {donor.elementType}
                          </div>
                        </div>
                      </td>

                      <td className="p-2">
                        <div className="items-center text-center">
                          <button className={statusColor}>{status}</button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
                {}
              </tbody>
            </table>
            <div className="mt-8">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <nav
                  className="mb-4 sm:order-1"
                  role="navigation"
                  aria-label="Navigation"
                >
                  <ul className="flex justify-center">
                    <li className="ml-3 first:ml-0">
                      <a className={prev} href="#0" onClick={this.goPrev}>
                        &lt;- Previous
                      </a>
                    </li>
                    <li className="ml-3 first:ml-0">
                      <a className={next} href="#0" onClick={this.goNext}>
                        Next -&gt;
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="text-center text-sm text-gray-500 sm:text-left">
                  Showing{" "}
                  <span className="font-medium text-gray-600">{start}</span> to{" "}
                  <span className="font-medium text-gray-600">{end}</span> of{" "}
                  <span className="font-medium text-gray-600">{total}</span>{" "}
                  results
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormsTable;
