/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { LinkIcon } from "@heroicons/react/20/solid";
import Toggle from "../Toggle";
import Dropdown from "../dropdowns/Dropdown";
import MultiSelectDropdown from "../dropdowns/MultiSelectDropdown";
import { Auth } from "aws-amplify";
import ReactTooltip from "react-tooltip";
import SuccessAlert from "../alerts/SuccessAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import Checkbox from "../Checkbox";
import { useHistory } from "react-router-dom";
import CustomInputsTab from "./tabs/CustomInputsTab";
import SingleSelectDropdown from "../dropdowns/SingleSelectDropdown";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

const RECURRING_FREQUENCY_ORDER_MAP = {
  Annually: 0,
  Monthly: 1,
  Weekly: 2,
  Daily: 3,
  "One-Time": 4,
};

const tabs = [
  { name: "Form Options", href: "#formOptions", current: true },
  // { name: 'Custom Fields', href: '#tax', current: false },
  { name: "Tax Receipts", href: "#tax", current: false },
];

export default function CreateFormSlideover(props) {
  const [jwtToken, setJWTToken] = useState("");
  const [elementName, setelementName] = useState("");
  const [elementID, setElementID] = useState("");

  const [activeTab, setActiveTab] = useState({
    name: "Form Options",
    current: true,
  });

  const [defaultDonationType, setDefaultDonationType] = useState("Monthly");
  const [dataForQRCode, setDataForQRCode] = useState("");

  const [urlInput, setUrlInput] = useState("");
  const [form, setForm] = useState("");
  const [color, setColor] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [copiedState, setCopiedState] = useState("Copied!");

  const [checkedValues, setCheckedValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  const [dataSubmitted, setDataSubmitted] = useState(false);

  const [createdForm, setCreatedForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // internal functions
  const checkFormErrors = () => {
    // console.log("checkedValues: ", checkedValues, "selectedValue: ", selectedValue)
    if (elementName === "") {
      return "A value for name is required";
    }
    if (urlInput === "") {
      return "A value for url is required";
    }
    if (color.length != 7) {
      return "Color is required";
    }
    return "";
  };

  const generateQRCodeData = () => {
    // You can set any data you want to encode in the QR code based on form input values
    const baseURL =
      process.env.REACT_APP_ENV === "development"
        ? "https://dev-link.charitystack.com"
        : "https://link.charitystack.com";
    const qrCodeURL = `${baseURL}/elements-config?elementID=${elementID}`;
    setDataForQRCode(qrCodeURL);
  };

  const downloadQRCodeImage = () => {
    const container = document.getElementById("qr-code-container");
    html2canvas(container).then((canvas) => {
      canvas.toBlob((blob) => {
        saveAs(blob, "CharityStack_QR_Code.png");
      });
    });
  };

  const sendToServer = async () => {
    let returnedError = checkFormErrors();
    console.log(returnedError);
    if (returnedError != "") {
      setErrorMessage(returnedError);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    }
    setIsDisabled(true);

    const payload = {
      elementName: elementName,
      destinationURL: urlInput,
      nonprofitName: props.merchant.merchantName,
      color: color,
      elementType: "QR_CODE",
      elementID: elementID,
    };

    console.log("payload is as follows: ", payload);
    const requestOptions = {
      //only for create
      method: dataSubmitted ? "PATCH" : "POST", // choose the method based on if it is an updated or a new form
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_ELEMENTS_URL}/elements`;

    let response = await fetch(url, requestOptions);
    let data = await response.json();
    console.log({ data });
    if (data.error) {
      setIsDisabled(false);
      setErrorMessage(data.error);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);

      return;
    } else {
      //   changeEmailSettings()
      if (elementID === "") {
        setElementID(data.elementID);
      }
      setIsDisabled(false);
      setShowSuccessAlert(true);
      setSuccessMessage(
        dataSubmitted
          ? "Element Saved Successfully"
          : "Element Created Successfully"
      );
      setShowErrorAlert(false);
      setDataSubmitted(true);
      //    generateQRCodeData();
    }
  };
  useEffect(() => {
    // When dataSubmitted changes to true, generate the QR code data
    if (dataSubmitted) {
      generateQRCodeData();
    }
  }, [dataSubmitted]);

  // Clear states when modal is closed
  const clearStates = () => {
    // close slideover
    props.setOpen(false);

    // return to default states
    setelementName("");
    setElementID("");
    setColor("");
    setDataForQRCode("");
    setDataSubmitted(false);
  };

  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      const formattedMerchants = data["idToken"]["payload"][
        "cognito:groups"
      ].map((merchant) => merchant.replace(/-/g, " "));

      setJWTToken(data["idToken"]["jwtToken"]);
      console.log("forms", props.selectableForms);
    };
    myFunction();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
  }, [props.open]);

  let submitButtonClass =
    "justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2";
  if (isDisabled) {
    submitButtonClass =
      "justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm cursor-not-allowed focus:outline-none";
  }
  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                New QR Code
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Get started by filling in the information below
                                to create your new QR code.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={clearStates}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        {activeTab.name === "Form Options" && (
                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:py-0">
                            {/* Form name */}
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              {!dataSubmitted && (
                                <>
                                  <div>
                                    <label
                                      htmlFor="form-name"
                                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                    >
                                      Element name*
                                      <svg
                                        data-tip
                                        data-for="elementNameTooltip"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="text-primary ml-1 inline-block h-5 w-5"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                          clip-rule="evenodd"
                                        />
                                      </svg>
                                      <ReactTooltip
                                        id="elementNameTooltip"
                                        effect="solid"
                                        place="right"
                                      >
                                        <p className="text-sm text-white">
                                          This is the name of your QR Code.{" "}
                                        </p>
                                      </ReactTooltip>
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <input
                                      value={elementName}
                                      onChange={(e) =>
                                        setelementName(
                                          e.target.value.replace("|", "")
                                        )
                                      }
                                      type="text"
                                      name="form-name"
                                      id="form-name"
                                      className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                    />
                                  </div>
                                </>
                              )}
                              {dataSubmitted && (
                                <>
                                  <div>
                                    <label
                                      htmlFor="form-name"
                                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                    >
                                      Element name*
                                      <svg
                                        data-tip
                                        data-for="elementNameTooltip"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="text-primary ml-1 inline-block h-5 w-5"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                          clip-rule="evenodd"
                                        />
                                      </svg>
                                      <ReactTooltip
                                        id="elementNameTooltip"
                                        effect="solid"
                                        place="right"
                                      >
                                        <p className="text-sm text-white">
                                          This is the name of the QR code you
                                          created.
                                        </p>
                                      </ReactTooltip>
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <div className="py-2 text-sm text-gray-700">
                                      {elementName}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            {/* Color */}
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="color"
                                  className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Color*
                                  <svg
                                    data-tip
                                    data-for="colorTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="colorTooltip"
                                    effect="solid"
                                    place="right"
                                    delayHide={500}
                                    className="react-tooltip-clickable-link"
                                  >
                                    <p className="text-sm text-white">
                                      Provide a six character Hex color preceded
                                      by a '#'.
                                    </p>
                                    <p className="text-sm text-white">
                                      For help getting this value, visit the
                                      link below.
                                    </p>
                                    <a
                                      target="_blank"
                                      className="focus:outline-none text-sm text-white underline"
                                      href="https://htmlcolorcodes.com/color-picker/"
                                    >
                                      https://htmlcolorcodes.com/color-picker/
                                    </a>
                                  </ReactTooltip>
                                </label>
                              </div>
                              <div className="inline-flex">
                                <input
                                  value={color}
                                  placeholder="#000000"
                                  onChange={(e) => {
                                    let color = e.target.value;
                                    color = color.toUpperCase();
                                    color = color.replace(/^([^#])/g, "#$1");
                                    color = color.replace(/[^0-9a-fA-F#]/g, "");
                                    if (color.length > 7) {
                                      color = color.substring(0, 7);
                                    }
                                    setColor(color);
                                  }}
                                  type="text"
                                  name="color"
                                  id="color"
                                  className="focus:border-secondary focus:ring-secondary flex w-32 rounded-md border-gray-300 shadow-sm sm:text-sm"
                                />
                              </div>
                            </div>

                            {/* URL */}
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="color"
                                  className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  URL*
                                  <svg
                                    data-tip
                                    data-for="urlTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="urlTooltip"
                                    effect="solid"
                                    place="right"
                                  >
                                    <p className="text-sm text-white">
                                      This is where you can customize the url of
                                      the{" "}
                                    </p>
                                    <p className="text-sm text-white">
                                      QR Code. Scanning the QR Code will take
                                      you here.
                                    </p>
                                  </ReactTooltip>
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <input
                                  type="text"
                                  name="urlOfQRCode"
                                  id="urlOfQRCode"
                                  className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                  placeholder="www.charitystack.com"
                                  value={urlInput}
                                  onChange={(e) => setUrlInput(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Download Image */}
                        {dataSubmitted && urlInput && (
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="color"
                                className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Download Image*
                                <svg
                                  data-tip
                                  data-for="downloadTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="downloadTooltip"
                                  effect="solid"
                                  place="right"
                                >
                                  <p className="text-sm text-white">
                                    This is where you can download the{" "}
                                  </p>
                                  <p className="text-sm text-white">QR Code.</p>
                                </ReactTooltip>
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <button
                                type="button"
                                onClick={downloadQRCodeImage}
                              >
                                Click here to download QR code
                              </button>
                            </div>
                          </div>
                        )}
                        {/* Display the container with QR code and text */}
                        {dataSubmitted && (
                          <div className="flex flex-col items-center">
                            <div
                              id="qr-code-container"
                              style={{ backgroundColor: "transparent" }}
                            >
                              <div className="flex flex-col items-center p-1">
                                <QRCode
                                  value={dataForQRCode}
                                  size={189.5}
                                  bgColor={"white"}
                                />
                                <div
                                  className="mx-9 bg-black px-7 py-2 pt-2 text-white"
                                  style={{
                                    backgroundColor: color,
                                    textAlign: "center",
                                    borderBottomLeftRadius: "10px", // Adjust the value as needed
                                    borderBottomRightRadius: "10px", // Adjust the value as needed
                                    width: 189.5,
                                    height: "auto", // Set height to 'auto' to adjust based on content
                                  }}
                                >
                                  Scan to Donate
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex h-12 items-center justify-between space-x-3">
                          {showSuccessAlert && (
                            <SuccessAlert
                              message={successMessage}
                              setShowSuccessAlert={setShowSuccessAlert}
                            />
                          )}
                          {showErrorAlert && (
                            <ErrorAlert
                              message={errorMessage}
                              setShowErrorAlert={setShowErrorAlert}
                            />
                          )}
                          {!showSuccessAlert && !showErrorAlert && <div />}

                          <button
                            type="button"
                            className={submitButtonClass}
                            onClick={sendToServer}
                          >
                            {dataSubmitted
                              ? isDisabled
                                ? "Saving"
                                : "Save"
                              : isDisabled
                              ? "Creating"
                              : "Create"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
