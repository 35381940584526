import React, { useEffect, useState } from "react";
import ImportTable from "./ImportTable";

function Import(props) {
  return (
    <div>
      <section>
        <div>
          <div className="col-span-2 row-span-1">
            <div className="mb-4 text-2xl font-semibold">Imports</div>
            <div className="text-sm text-gray-700">
              Import any data from your previous processor or fundraising
              solution on this page.
            </div>

            <div className="space-y-2">
              <div>
                <a
                  className="text-primary hover:text-secondary group flex cursor-pointer text-sm font-medium hover:underline"
                  data-tip
                  data-for="copyIframe"
                  data-event="click"
                  onClick={() => {
                    console.log("hi");
                  }}
                >
                  {/* Copy code JSX */}
                </a>
              </div>
              <div className="grid grid-cols-12 gap-6">
                <ImportTable
                  jwtToken={props.jwtToken}
                  merchant={props.merchant}
                  grabBaseMerchant={props.grabBaseMerchant}
                  selectableForms={props.selectableForms}
                ></ImportTable>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 border-t border-gray-200 py-2">
          <div className="flex h-12 items-center justify-between space-x-3">
            {/* Rest of the JSX */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Import;
