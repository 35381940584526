import React from "react";
import CustomInputsTabContent_Events from "./CustomInputsTabContent_Events";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { PlusIcon } from "@heroicons/react/20/solid";

const REVERSE_INPUT_TYPE_MAPPING = {
  "Short Text Input": "SHORT_TEXT_INPUT",
  "Long Text Input": "LONG_TEXT_INPUT",
  "Single Selection Dropdown": "SINGLE_SELECTION_DROPDOWN",
  "Multi Selection Dropdown": "MULTI_SELECTION_DROPDOWN",
};

function CustomInputsTab_Events(props) {
  const { customInputs, setCustomInputs, originalCustomInputs } = props;

  const handleTitleChange = (event) => {
    let index = parseInt(event.target.dataset.index);
    let value = event.target.value;

    let newCustomInputs = [...customInputs];
    let newCustomInput = newCustomInputs[index];
    newCustomInput["title"] = value;
    setCustomInputs(newCustomInputs);
  };

  const handleTypeChange = (event) => {
    let index = parseInt(event.target.dataset.index);
    let value = event.target.dataset.value;

    value = REVERSE_INPUT_TYPE_MAPPING[value];

    let newCustomInputs = [...customInputs];
    let newCustomInput = newCustomInputs[index];
    newCustomInput["type"] = value;
    // clear options if type is not a dropdown
    if (
      value !== "SINGLE_SELECTION_DROPDOWN" &&
      value !== "MULTI_SELECTION_DROPDOWN"
    ) {
      newCustomInput["options"] = [];
    }

    setCustomInputs(newCustomInputs);
  };

  const handleOptionsChange = (options, index) => {
    let newCustomInputs = [...customInputs];
    let newCustomInput = newCustomInputs[index];
    newCustomInput["options"] = options;
    setCustomInputs(newCustomInputs);
  };

  const handleRequiredChange = (value, index) => {
    let newCustomInputs = [...customInputs];
    let newCustomInput = newCustomInputs[index];
    newCustomInput["required"] = value;

    setCustomInputs(newCustomInputs);
  };

  // Type
  const handleQuestionTypeChange = (event) => {
    let index = parseInt(event.target.dataset.index);
    let value = event.target.defaultValue;

    let newCustomInputs = [...customInputs];
    let newCustomInput = newCustomInputs[index];
    newCustomInput["questionType"] = value;
    setCustomInputs(newCustomInputs);
  };

  const createNewCustomInput = () => {
    let newCustomInputs = [...customInputs];
    newCustomInputs.push({
      title: "",
      type: "Select an Input Type",
      options: [],
      required: false,
      questionType: "PURCHASER",
      id: Math.random().toString(36).substring(2, 9), // generates a random id
    });
    setCustomInputs(newCustomInputs);
  };

  const removeCustomInput = (index) => {
    let newCustomInputs = [...customInputs];
    newCustomInputs.splice(index, 1);
    setCustomInputs(newCustomInputs);
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let newCustomInputs = [...customInputs];
    let newCustomInput = newCustomInputs[source.index];
    newCustomInputs.splice(source.index, 1);
    newCustomInputs.splice(destination.index, 0, newCustomInput);
    console.log(newCustomInputs);
    setCustomInputs(newCustomInputs);
  };

  return (
    <>
      <div className="mb-16 space-y-6 py-6 sm:space-y-0 sm:py-0">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {customInputs.map((customInput, index) => (
                  <CustomInputsTabContent_Events
                    key={customInput.id}
                    id={customInput.id}
                    customInput={customInput}
                    index={index}
                    originalCustomInputs={originalCustomInputs}
                    handleTitleChange={handleTitleChange}
                    handleTypeChange={handleTypeChange}
                    handleOptionsChange={handleOptionsChange}
                    handleRequiredChange={handleRequiredChange}
                    handleQuestionTypeChange={handleQuestionTypeChange}
                    removeCustomInput={removeCustomInput}
                  />
                ))}
                {provided.placeholder}
                <div className="mr-4 mt-2 flex justify-end">
                  <div
                    className="flex cursor-pointer"
                    onClick={createNewCustomInput}
                  >
                    <PlusIcon
                      className="mr-2 mt-0.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <div className="text-gray-400"> add custom field </div>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
}

export default CustomInputsTab_Events;
