import React, { useState, useEffect, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import CustomFAQsTabContent from './CustomFAQsTabContent';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Bars3Icon, TrashIcon } from '@heroicons/react/outline';
import Dropdown from '../../dropdowns/Dropdown';
import MultiSelectDropdown from '../../dropdowns/MultiSelectDropdown';

function CustomFAQsTab(props) {

  const { customFAQs, setCustomFAQs, originalCustomFAQs } = props;
  const [faqLimitReached, setFaqLimitReached] = useState(false);

  const handleQuestionChange = (event) => {
    let index = parseInt(event.target.dataset.index);
    let value = event.target.value;

    let newcustomFAQs = [...customFAQs];
    let newCustomInput = newcustomFAQs[index];
    newCustomInput['question'] = value;
    setCustomFAQs(newcustomFAQs);
  }



  const handleAnswerChange = (options, index) => {
    let newcustomFAQs = [...customFAQs];
    let newCustomInput = newcustomFAQs[index];
    newCustomInput['answer'] = options;
    setCustomFAQs(newcustomFAQs);
  }


  const createNewCustomInput = () => {
    if (customFAQs.length < 4) {
      let newcustomFAQs = [...customFAQs];
      newcustomFAQs.push({
        question: '',
        answer: '',
        id: Math.random().toString(36).substring(2, 9) // generates a random id
      });
      setCustomFAQs(newcustomFAQs);
    } else {
      setFaqLimitReached(true); // Set the state variable to indicate the limit has been reached
    }
  };

  const removeCustomFAQs = (index) => {
    let newcustomFAQs = [...customFAQs];
    newcustomFAQs.splice(index, 1);
    setCustomFAQs(newcustomFAQs);
    setFaqLimitReached(false); // Reset to false when an FAQ is removed
  };


  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    let newcustomFAQs = [...customFAQs];
    let newCustomInput = newcustomFAQs[source.index];
    newcustomFAQs.splice(source.index, 1);
    newcustomFAQs.splice(destination.index, 0, newCustomInput);
    console.log(newcustomFAQs)
    setCustomFAQs(newcustomFAQs);
  }

  return (
    <>
      <div className="space-y-6 py-6 sm:space-y-0 sm:py-0 mb-16">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {customFAQs.map((customInput, index) => (
                  <CustomFAQsTabContent
                    key={customInput.id}
                    id={customInput.id}
                    customFAQs={customInput} // Pass the correct object
                    index={index}
                    originalCustomFAQs={originalCustomFAQs}
                    handleQuestionChange={handleQuestionChange}
                    //      handleTypeChange={handleTypeChange}
                    handleAnswerChange={handleAnswerChange}
                    //    handleRequiredChange={handleRequiredChange}
                    removeCustomFAQs={removeCustomFAQs} // Correct the prop name
                  />
                ))}
                {provided.placeholder}
                <div className="flex justify-end mr-4 mt-2">
                  {faqLimitReached ? (
                    <div className="text-red-500">You can only create up to four FAQs.</div>
                  ) : (
                    <div className="flex cursor-pointer" onClick={createNewCustomInput}>
                      <PlusIcon className="h-5 w-5 mr-2 mt-0.5 text-gray-400" aria-hidden="true" />
                      <div className="text-gray-400"> add custom FAQ </div>
                    </div>
                  )}
                </div>

              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
}

export default CustomFAQsTab;