import React, { useRef, useState, useEffect, useCallback } from "react";
import MultiSelectDropdown from "../../dropdowns/MultiSelectDropdown";
import ReactTooltip from "react-tooltip";
import { Draggable } from "react-beautiful-dnd";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";

function CustomPromoTabContent(props) {
  const {
    CustomPromo,
    id,
    index,
    tickets,
    handleNameChange,
    handleTypeChange,
    handleTicketsChange,
    handleAmountChange,
    removeCustomPromo,
    setShowErrorAlert,
    setErrorMessage,
  } = props;
  const [dollarAmount, setDollarAmount] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState([]);

  useEffect(() => {
    if (CustomPromo.codeType === "AMOUNT") {
      setDollarAmount(true);
    } else {
      setDollarAmount(false);
    }

    if (CustomPromo.tickets2 != undefined) {
      const namesList = CustomPromo.tickets2.map((item) => item.name);
      console.log("namesList here", namesList);
      setSelectedTickets(namesList);
    }
  }, []);

  //update names anytime customPromo.tickets2 changes
  useEffect(() => {
    console.log("updating multi-select", CustomPromo);
    if (CustomPromo.tickets2 != undefined) {
      const namesList = CustomPromo.tickets2.map((item) => item.name);
      console.log("namesList", namesList);
      setSelectedTickets(namesList);
    }
  }, [CustomPromo]);

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className="border-b border-gray-300"
        >
          <div {...provided.dragHandleProps}>
            <Bars3Icon
              className="ml-auto mr-4 mt-2 h-6 w-6"
              aria-hidden="true"
            />
          </div>

          {/* Name */}
          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6">
            <label
              htmlFor="form-name"
              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
              Promo Code Name
              <svg
                data-tip
                data-for="nameTip"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="text-primary ml-1 inline-block h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
              <ReactTooltip id="nameTip" effect="solid" place="right">
                <p className="text-sm text-white">
                  The name used for the promo code will be
                </p>
                <p className="text-sm text-white">
                  what the ticket purchaser will input at checkout.
                </p>
              </ReactTooltip>
            </label>
            <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
              <input
                data-type="title"
                data-index={index}
                value={CustomPromo.codeName}
                type="text"
                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                onChange={(e) => handleNameChange(e)}
                maxLength={50}
              />
            </div>
          </div>

          {/* Type */}
          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-300 ">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 ">
              <label
                htmlFor="form-name"
                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
              >
                Promo Code Type
                <svg
                  data-tip
                  data-for="typeTooltip"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="text-primary ml-1 inline-block h-5 w-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  />
                </svg>
                <ReactTooltip id="typeTooltip" effect="solid" place="right">
                  <p className="text-sm text-white">
                    Choosing between Percentage or Dollar amount
                  </p>
                  <p className="text-sm text-white">
                    affects the promo amount below.
                  </p>
                </ReactTooltip>
              </label>
              <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
                <input
                  type="radio"
                  value="PERCENTAGE"
                  name="typeChoose"
                  data-type="title"
                  data-index={index}
                  onClick={(e) => {
                    setDollarAmount(false);
                    handleTypeChange(e);
                  }}
                  style={{
                    backgroundColor: dollarAmount === false ? "green" : "white",
                    marginTop: "4px",
                    marginRight: "5px",
                  }}
                />
                Percentage
                <input
                  type="radio"
                  value="AMOUNT"
                  name="typeChoose"
                  data-type="title"
                  data-index={index}
                  onClick={(e) => {
                    setDollarAmount(true);
                    handleTypeChange(e);
                  }}
                  style={{
                    backgroundColor: dollarAmount === true ? "green" : "white",
                    marginTop: "4px",
                    marginRight: "5px",
                  }}
                />
                Dollar Amount
              </div>
            </div>
          </div>

          {/* Amount */}
          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6">
            <label
              htmlFor="form-name"
              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
              Promo Amount
              <svg
                data-tip
                data-for="amtTooltip"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="text-primary ml-1 inline-block h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
              <ReactTooltip id="amtTooltip" effect="solid" place="right">
                <p className="text-sm text-white">
                  Do not enter a dollar sign or percent sign.
                </p>
                <p className="text-sm text-white">
                  The type of promo discount that is applied
                </p>
                <p className="text-sm text-white">
                  will be determined by the Promo Code Type above.
                </p>
              </ReactTooltip>
            </label>
            <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
              <input
                data-type="price"
                data-index={index}
                value={CustomPromo.codeAmount}
                type="number"
                step="any"
                pattern="d\+\.\d\d$"
                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                onChange={(e) => handleAmountChange(e)}
              />
            </div>
          </div>

          {/* Tickets */}

          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6">
            <label
              htmlFor="form-name"
              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
              Tickets
              <svg
                data-tip
                data-for="ticketTooltip"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="text-primary ml-1 inline-block h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
              <ReactTooltip id="ticketTooltip" effect="solid" place="right">
                <p className="text-sm text-white">
                  Select any of the tickets for this event
                </p>
                <p className="text-sm text-white">
                  that the Promo Code can be applied to.
                </p>
              </ReactTooltip>
            </label>
            <div className="flex w-full space-x-10 pt-2 text-sm sm:col-span-2">
              <div className="w-full pb-5">
                <MultiSelectDropdown
                  options={tickets.map((ticket) => {
                    return { value: ticket, label: ticket, color: "#69995E" };
                  })}
                  setItems={(items) => {
                    handleTicketsChange(items, index);
                  }}
                  items={
                    CustomPromo.tickets2
                      ? CustomPromo.tickets2.map((item) => item.name)
                      : []
                  }
                  placeholder="Select ticket(s)"
                />
              </div>
            </div>
          </div>

          <div className="mb-2 mr-4 flex justify-end">
            <div
              className="flex cursor-pointer"
              data-index={index}
              onClick={(e) => removeCustomPromo(index)}
            >
              <TrashIcon
                data-index={index}
                className="mr-2 mt-0.5 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
              <div data-index={index} className="text-red-500">
                {" "}
                remove Promo Code{" "}
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

export default CustomPromoTabContent;
