import React, { Component, useRef } from "react";
import LineChart from "../../charts/LineChart02";
import decompressData from "../../utils/decompressData";
import pako from "pako";

// Import utilities
import { tailwindConfig } from "../../utils/Utils";
import { Auth } from "aws-amplify";

import resolveConfig from "tailwindcss/resolveConfig";
import Button from "@material-tailwind/react/Button";
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";

const { theme } = resolveConfig(tailwindConfig);

export class DashboardCard08 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jwtToken: "",
      total: 0,
      timerange: props.timerange,
      numOfUniqueDonors: 0,
      numOfDonations: 0,
      chartData: {
        labels: [],
        datasets: [
          // Indigo line
          {
            label: "Current",
            data: [],
            borderColor: tailwindConfig().theme.colors.red[500],
            fill: false,
            borderWidth: 2,
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBackgroundColor: tailwindConfig().theme.colors.red[500],
          },
        ],
      },
    };
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.value !== props.value) {
      this.updateData();
    }
    return null;
  }

  componentDidUpdate() {
    if (this.state.timerange !== this.props.timerange) {
      console.log("updated data");
      this.updateData();
    }
  }

  async componentDidMount() {
    const data = await Auth.currentSession();
    this.setState(
      {
        jwtToken: data["idToken"]["jwtToken"],
      },
      () => this.updateData()
    );
  }

  convertTimestamp(timestamp) {
    const sections = timestamp.split("-");
    return sections[1] + "-" + sections[2] + "-" + sections[0];
  }

  async updateData() {
    const requestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: this.state.jwtToken,
      },
      body: JSON.stringify({
        timerange: this.props.timerange,
        merchantID: global.merchantID,
      }),
    };
    const url = process.env.REACT_APP_DONATIONS_URL;
    let res = [];
    let lastEvaluatedKeys = {};
    while (true) {
      // fetch data
      let response = await fetch(url, requestOptions);
      // get response in json
      let response_json = await response.json();
      // decompress data
      let decompressed_data = decompressData(response_json);
      console.log("DECOMPRESSED DATA", decompressed_data);
      // extend res to include decompressed_data['transactions']
      res = res.concat(decompressed_data["transactions"]);
      lastEvaluatedKeys = decompressed_data["lastEvaluatedKeys"];

      // break if lastEvaluatedKeys is an object of size 0
      if (
        lastEvaluatedKeys === undefined ||
        lastEvaluatedKeys === null ||
        Object.keys(lastEvaluatedKeys).length === 0
      ) {
        console.log("no more last evaluated key");
        break;
      }

      console.log("has lastEvaluatedKeys", lastEvaluatedKeys);
      // update body to include lastEvaluatedKey
      requestOptions.body = JSON.stringify({
        timerange: this.props.timerange,
        lastEvaluatedKeys: lastEvaluatedKeys,
      });
    }

    let timestamps = [];
    let data = [];
    let uniqueDonors = {};
    let totalDonations = 0;
    let numOfDonations = 0;

    res.map((row) => {
      if (row.state === "SUCCEEDED") {
        numOfDonations++;

        const unformattedTimestamp = row.timestamp.split(" ")[0];
        const timestamp = this.convertTimestamp(unformattedTimestamp);
        const timestampIndex = timestamps.indexOf(timestamp);

        let totalAmount = (row.amount - row.fees).toFixed(2);
        if (row.coverFees === "true") {
          totalAmount = row.amount;
        }

        totalDonations += Number(totalAmount);

        if (!uniqueDonors.hasOwnProperty(row.email)) {
          uniqueDonors[row.email.toLowerCase()] = "exists";
        }

        if (timestampIndex == -1) {
          timestamps.push(timestamp);
          data.push(Number(totalAmount));
        } else {
          data[timestampIndex] += Number(totalAmount);
        }
      }
    });
    console.log("Unique donations " + numOfDonations);
    console.log(timestamps);
    console.log(data);
    this.setState({
      total: totalDonations,
      timerange: this.props.timerange,
      numOfUniqueDonors: Object.keys(uniqueDonors).length,
      numOfDonations: numOfDonations,
      chartData: {
        labels: timestamps.reverse(),
        datasets: [
          // Indigo line
          {
            label: "Current",
            data: data.reverse(),
            borderColor: "#69995E",
            fill: false,
            borderWidth: 2,
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBackgroundColor: "#324234",
          },
        ],
      },
    });
  }

  render() {
    const { chartData, total, numOfUniqueDonors, numOfDonations } = this.state;
    return (
      <div className="col-span-full rounded-lg border border-gray-200 bg-white shadow-lg xl:col-span-12">
        {/* Chart built with Chart.js 3 */}
        {/* Change the height attribute to adjust the chart height */}
        <LineChart
          data={chartData}
          total={(Math.round(total * Math.pow(10, 2)) / Math.pow(10, 2))
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          numOfUniqueDonors={numOfUniqueDonors}
          numOfDonations={numOfDonations}
          height={300}
        />
      </div>
    );
  }
}

export default DashboardCard08;
