import React, { Component, useState, useEffect, useMemo } from "react";
import { StrictMode } from "react/cjs/react.production.min";
import PaginationClassic from "../PaginationClassic";
import moment from "moment";
import { Auth } from "aws-amplify";
import DonationDatePicker from "./DonationDatePicker";
import { CSVLink } from "react-csv";
import EditEventSlideover from "../slideovers/EditEventSlideover";
import {
  CheckIcon,
  SelectorIcon,
  ChevronDownIcon,
} from "@heroicons/react/solid";
import { Popover, Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import decompressData from "../../utils/decompressData";
import pako from "pako";
import {
  formatTimestamp,
  formatTimestampToDate,
} from "../../utils/formatTimestamp";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
let today = new Date();
// increment today forward by 1 day
today.setDate(today.getDate() + 1);
today.setDate(today.getDate() + 1);
let tomorrow = new Date();
tomorrow.setDate(today.getDate() - 6000);
const startTimerange =
  tomorrow.toLocaleDateString("en-US", DATE_OPTIONS) +
  " - " +
  today.toLocaleDateString("en-US", DATE_OPTIONS);

const formTypeMap = {
  CROWDFUNDING_FORM: "Crowdfunding",
  EMBED_FORM: "Standard",
  QUICK_DONATE: "Quick Donate",
  EVENT: "Event",
  undefined: "Standard",
};

export class TicketsTable extends Component {
  constructor(props) {
    super(props);

    const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
    let today = new Date();
    // increment today forward by 1 day
    today.setDate(today.getDate() + 1);
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() - 6000);
    const startTimerange =
      tomorrow.toLocaleDateString("en-US", DATE_OPTIONS) +
      " - " +
      today.toLocaleDateString("en-US", DATE_OPTIONS);
    this.state = {
      jwtToken: "",
      ticketList: [],

      start: 0,
      end: 0,
      total: 0,
      totalActiveEvents: 0,
      totalAmount: 0,
      totalAttendees: 0,
      totalAttendeeValue: 0,
      totalCheckedIn: 0,

      timerange: startTimerange,
      open: false,
      formsData: null,
      donorsNextList: [],
      allTickets: [],
      ticketUpdated: false,
      loading: false,
      recentAttendeesText: "Attendees",
      recentEventsText: "Events",

      duplicate: 0,
      showFilter: false,
      filteredAttendee: "",
      filteredAttendeeList: [],
      filteredEvent: "",
      filteredEventList: [],
      filteredState: [],
      filteredKeywordSet: false, // This is to determine if the user ever set the value initially
      listOfAllPossibleAttendees: [],
      showKeywordFilter: true,
      showStatusFilter: false,
      resetFilter: false,
      keywordSearchTerm: "",
      filteredList: [],
      originalList: [],

      savedFilterIndicatorKeywordAttendee: false,
      savedFilterIndicatorKeywordEvent: false,
      savedFilterIndicatorKeywordSearch: false,
      showIndicators: false,
      savedFilterIndicatorStatus: false,
    };
  }

  setOpen = (toggle) => {
    this.setState({ open: toggle });
  };

  onResetFilter = () => {
    const { originalList } = this.state;
    this.setState(
      {
        filteredKeywordSet: true,
        resetFilter: true,
        showKeywordFilter: false,
        showStatusFilter: false,
        filteredAttendee: "",
        filteredAttendeeList: [],
        filteredEvent: "",
        filteredEventList: [],
        filteredState: [],
        keywordSearchTerm: "",

        showFilter: false,
        savedFilterIndicatorKeywordAttendee: false,
        savedFilterIndicatorKeywordEvent: false,
        savedFilterIndicatorKeywordSearch: false,
        showIndicators: false,

        savedFilterIndicatorStatus: false,
      },
      () => {
        this.updateData();
      }
    );
  };

  onSavedIndicatorKeywordClick = () => {
    const { originalList } = this.state;
    this.setState(
      {
        filteredAttendee: "",
        filteredAttendeeList: [],
        filteredEvent: "",
        filteredEventList: [],
        keywordSearchTerm: "",
        loading: true,

        savedFilterIndicatorKeywordAttendee: false,
        savedFilterIndicatorKeywordEvent: false,
        savedFilterIndicatorKeywordSearch: false,
      },
      () => {
        this.updateData();
      }
    );
  };

  onSavedIndicatorStatusClick = () => {
    const { originalList } = this.state;
    this.setState(
      {
        filteredState: [],
        loading: true,
        savedFilterIndicatorStatus: false,
      },
      () => {
        this.updateData();
      }
    );
  };

  onFilterKeywordFilter = () => {
    this.setState({
      showKeywordFilter: true,
      showStatusFilter: false,
    });
  };

  onFilterStatusFilter = () => {
    this.setState({
      showStatusFilter: true,
      showKeywordFilter: false,
    });
  };

  onFilterClickHandler = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  onKeywordSearchFilter = (e) => {
    e.preventDefault();
    //(e.target.value)
    if (e.target.value.length === 0) {
      this.setState(
        {
          recentAttendeesText: "Attendees",
          recentEventsText: "Events",
          savedFilterIndicatorKeywordSearch: false,
          keywordSearchTerm: "",
        },
        () => this.updateFilter()
      );
    }
    if (e.target.value.length === null) {
    }
    if (e.target.value.length > 0) {
      this.setState(
        {
          keywordSearchTerm: e.target.value,
          recentAttendeesText: "Attendees",
          recentEventsText: "Events",
          savedFilterIndicatorKeywordSearch: true,
        },
        () => this.updateFilter()
      );
    }
  };

  onFilteredAttendeeClickHandler = (e) => {
    //    e.preventDefault();
    let newAttendeeValue = this.state.filteredAttendeeList;

    if (newAttendeeValue.includes(e.target.value)) {
      newAttendeeValue.splice(newAttendeeValue.indexOf(e.target.value), 1);
      this.setState(
        {
          filteredAttendeeList: newAttendeeValue,
          savedFilterIndicatorKeywordAttendee: false,
        },
        () => this.updateFilter()
      );
    } else {
      newAttendeeValue[newAttendeeValue.length] = e.target.value;
      this.setState(
        {
          filteredAttendeeList: newAttendeeValue,
          savedFilterIndicatorKeywordAttendee: true,
        },
        () => this.updateFilter()
      );
    }
  };

  onFilteredEventClickHandler = (e) => {
    //    e.preventDefault();
    let newValue = this.state.filteredEventList;

    if (newValue.includes(e.target.value)) {
      newValue.splice(newValue.indexOf(e.target.value), 1);
      this.setState(
        {
          filteredEventList: newValue,
          savedFilterIndicatorKeywordEvent: false,
        },
        () => this.updateFilter()
      );
    } else {
      newValue[newValue.length] = e.target.value;
      this.setState(
        {
          filteredEventList: newValue,
          savedFilterIndicatorKeywordEvent: true,
        },
        () => this.updateFilter()
      );
    }
  };

  onFilteredStateClickHandler = (e) => {
    //   e.preventDefault();
    const { filteredState } = this.state;
    let newAttendeeValue = this.state.filteredState;
    if (newAttendeeValue.includes(e.target.value)) {
      newAttendeeValue.splice(newAttendeeValue.indexOf(e.target.value), 1);
      let filterIndicatorStatus = true;
      // only set to false when all status filters are cleared
      if (filteredState.length == 0) {
        filterIndicatorStatus = false;
      }
      this.setState(
        {
          filteredState: newAttendeeValue,
          savedFilterIndicatorStatus: filterIndicatorStatus,
        },
        () => this.updateFilter()
      );
    } else {
      newAttendeeValue[newAttendeeValue.length] = e.target.value;
      this.setState(
        {
          filteredState: newAttendeeValue,
          savedFilterIndicatorStatus: true,
        },
        () => this.updateFilter()
      );
    }
  };

  updateFilter = () => {
    const {
      filteredState,
      resetFilter,
      filteredAttendeeList,
      filteredEventList,
      keywordSearchTerm,
      savedFilterIndicatorKeyword,
      ticketList,
      loading,
    } = this.state;

    let setOfAttendees = new Set();
    let setOfEvents = new Set();
    let listOfAllPossibleAttendees = [];
    let listOfAllPossibleEvents = [];

    let filteredList = ticketList;

    if (filteredState.length !== 0) {
      filteredList = filteredList.filter((t) => {
        let status = t.checkInStatus == true ? "Checked-In" : "Not Checked-In";
        return filteredState.includes(status);
      });
    }

    for (let index = 0; index < filteredList.length; index++) {
      if (
        !setOfAttendees.has(
          filteredList[index].firstName +
            " " +
            filteredList[index].lastName +
            " " +
            filteredList[index].email
        ) &&
        listOfAllPossibleAttendees.length < 5
      ) {
        if (
          keywordSearchTerm === "" ||
          (keywordSearchTerm !== "" &&
            (filteredList[index].firstName
              .toLowerCase()
              .includes(keywordSearchTerm.toLowerCase()) ||
              filteredList[index].lastName
                .toLowerCase()
                .includes(keywordSearchTerm.toLowerCase()) ||
              filteredList[index].email
                .toLowerCase()
                .includes(keywordSearchTerm.toLowerCase()) ||
              filteredList[index].confirmationId.includes(keywordSearchTerm)))
        ) {
          listOfAllPossibleAttendees.push(
            filteredList[index].firstName + " " + filteredList[index].lastName
          );
          setOfAttendees.add(
            filteredList[index].firstName +
              " " +
              filteredList[index].lastName +
              " " +
              filteredList[index].email
          );
        }
      }
      if (
        !setOfEvents.has(filteredList[index].formName) &&
        listOfAllPossibleEvents.length < 5
      ) {
        if (
          keywordSearchTerm === "" ||
          (keywordSearchTerm !== "" &&
            filteredList[index].formName
              .toLowerCase()
              .includes(keywordSearchTerm.toLowerCase()))
        ) {
          listOfAllPossibleEvents.push(filteredList[index].formName);
          setOfEvents.add(filteredList[index].formName);
        }
      }
    }
    // Get the size of the Set, which gives the count of unique formNames
    this.setState({
      totalAttendeeValue: filteredList.length,
      filteredList: filteredList,
      listOfAllPossibleAttendees: listOfAllPossibleAttendees,
      listOfAllPossibleEvents: listOfAllPossibleEvents,
      loading: false,
    });
  };

  async componentDidMount() {
    const data = await Auth.currentSession();
    let user = await Auth.currentAuthenticatedUser();
    this.setState(
      {
        jwtToken: data["idToken"]["jwtToken"],
        whoIsLoggedIn: user.username,
      },
      () => this.updateData()
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      totalActiveEvents,
      totalAmount,
      totalAttendees,
      totalAttendeeValue,
      totalCheckedIn,
      ticketUpdated,
    } = this.state;
    // this.props.getMetrics(
    //   totalActiveEvents,
    //   totalAmount,
    //   totalAttendees,
    //   totalAttendeeValue,
    //   totalCheckedIn
    // );
    if (ticketUpdated) {
      this.setState({ ticketUpdated: false });
    }
  }

  updateTicket = async (ticket) => {
    const { totalCheckedIn, ticketUpdated } = this.state;

    const payload = {
      transactionID: ticket.transactionID,
      confirmationId: ticket.confirmationId,
      checkInStatus: ticket.checkInStatus,
      merchantName: ticket.merchantName,
    };

    const requestOptions = {
      method: "PATCH",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: this.state.jwtToken,
      },
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_ELEMENTS_URL}/tickets`;

    let response = await fetch(url, requestOptions);
    let data = await response.json();

    if (data.error) {
      console.error(data.error);
    } else {
      this.setState({ ticketUpdated: true });
      if (ticket.checkInStatus) {
        this.setState({ totalCheckedIn: totalCheckedIn + 1 });
      } else {
        this.setState({ totalCheckedIn: totalCheckedIn - 1 });
      }
    }
  };

  updateData = async () => {
    this.setState({ loading: true });
    // grab forms data
    const formsRequestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: this.state.jwtToken,
      },
    };

    const formsURL = process.env.REACT_APP_FORMS_URL;

    let formsResponse = await fetch(formsURL, formsRequestOptions);
    let formsData = await formsResponse.json();

    formsData = formsData.filter((formData) => formData.formType === "EVENT");
    // create a map of formname string to states from formsdata
    const formsActive = new Map();
    // create a map of formname string to formType from formsdata
    const formsType = new Map();

    const formsTickets = new Map();

    const formsTicketMappings = new Map();

    // create map of formName string to set of active funds
    formsData.forEach((form) => {
      const key = form.nonprofitName + " | " + form.formName;
      formsActive.set(key, form.active);
      formsType.set(key, form.formType);
      formsTickets.set(key, form.tickets);
    });

    // grab transactions data
    const requestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: this.state.jwtToken,
      },

      body: JSON.stringify({
        timerange: this.state.timerange,
      }),
    };

    const url = process.env.REACT_APP_DONATIONS_URL;
    let res = [];
    let lastEvaluatedKeys = {};
    while (true) {
      // fetch data
      let response = await fetch(url, requestOptions);
      // get response in json
      let response_json = await response.json();
      // decompress data
      let decompressed_data = decompressData(response_json);
      console.log("DECOMPRESSED DATA", decompressed_data);
      // extend res to include decompressed_data['transactions']
      res = res.concat(decompressed_data["transactions"]);
      lastEvaluatedKeys = decompressed_data["lastEvaluatedKeys"];

      // break if lastEvaluatedKeys is an object of size 0
      if (
        lastEvaluatedKeys === undefined ||
        lastEvaluatedKeys === null ||
        Object.keys(lastEvaluatedKeys).length === 0
      ) {
        console.log("no more last evaluated key");
        break;
      }

      console.log("has lastEvaluatedKeys", lastEvaluatedKeys);
      // update body to include lastEvaluatedKey
      requestOptions.body = JSON.stringify({
        timerange: this.state.timerange,
        lastEvaluatedKeys: lastEvaluatedKeys,
      });
    }

    res = res.filter(
      (formData) => formData.tickets !== undefined && formData.tickets !== null
    );

    let end = 20;
    let grossAmountVariable = 0;
    let totalAttendees = new Set();
    let allTicketList = [];
    let formToTicket = new Map();
    let rows = [];
    const ticketMap = new Map();

    // console.log(res.length, "res.length");
    // if (res.length < 20) {
    //   end = res.length;
    // }
    for (let index = 0; index < res.length; index++) {
      res[index].grossAmountVar = (res[index].amount - res[index].fees).toFixed(
        2
      );
    }

    res.map((transaction) => {
      // console.log(transaction.tickets);

      // required vars outside for loop
      let eventName = transaction.formName;
      const amount = parseFloat(transaction.amount).toFixed(2);
      const name = transaction.name.toLowerCase();
      const email = transaction.email.toLowerCase();
      let key = transaction.merchantName + " | " + transaction.formName;

      if (
        transaction.overrideMerchant !== null &&
        transaction.overrideMerchant !== undefined &&
        transaction.overrideMerchant === true
      ) {
        key = transaction.originalMerchantName + " | " + transaction.formName;
      }

      if (
        transaction.state == "SUCCEEDED" ||
        transaction.state == "PARTIAL_REFUND" ||
        transaction.state == "FULL_REFUND"
      ) {
        totalAttendees.add(name + " | " + email);

        let refunded = transaction.refunded ?? [];
        // remove Fee from refunded
        refunded = refunded.filter((refund) => refund.name !== "Fee");

        let tickets = transaction.tickets ?? [];

        // iterate through refunded
        for (let i = 0; i < refunded.length; i++) {
          // grab ticketConfirmationIDs
          const ticketConfirmationIDs = refunded[i].ticketConfirmationIDs ?? [];
          // remove any ticket that has a confirmationID in ticketConfirmationIDs
          tickets = tickets.filter(
            (ticket) => !ticketConfirmationIDs.includes(ticket.confirmationID)
          );
        }

        if (tickets != null && tickets != undefined && tickets.length > 0) {
          const ticketListEdited = tickets.map((ticket) => ({
            ...ticket,
            merchantName: transaction.merchantName,
          }));
          for (let i = 0; i < ticketListEdited.length; i++) {
            ticketListEdited[i].eventName = eventName;
            ticketListEdited[i].eventActive = formsActive.get(key);
            ticketListEdited[i].transactionID = transaction.transactionID;
            ticketListEdited[i].merchantName = transaction.merchantName;
            ticketListEdited[i].timestamp = transaction.timestamp;
            ticketListEdited[i].state = transaction.state;
            allTicketList.push(ticketListEdited[i]);
          }
        }

        let nonprofitNameM = transaction.merchantName;
        if (
          transaction.overrideMerchant !== null &&
          transaction.overrideMerchant !== undefined &&
          transaction.overrideMerchant === true
        ) {
          nonprofitNameM = transaction.originalMerchantName;
        }
      } else if (transaction.state == "PENDING") {
        console.log(key);
      }
    });

    for (const attendee of allTicketList) {
      rows.push({
        formName: attendee.eventName,
        checkInStatus: attendee.checkInStatus,
        email: attendee.email,
        firstName: attendee.firstName,
        lastName: attendee.lastName,
        ticketPrice: attendee.price,
        confirmationId: attendee.confirmationID,
        ticketId: attendee.ticketID,
        ticketName: attendee.ticketName,
        ticketType: attendee.type,
        eventActive: attendee.eventActive,
        transactionID: attendee.transactionID,
        merchantName: attendee.merchantName,
        inputs: attendee.customInputAttendee,
        timestamp: attendee.timestamp,
        state: attendee.state,
      });
    }

    rows.sort((a, b) => {
      if (a.eventActive == b.eventActive) {
        return a.formName.toLowerCase() < b.formName.toLowerCase() ? 1 : -1;
      }
    });
    rows.sort((a, b) => (a.eventActive < b.eventActive ? 1 : -1));

    if (rows.length < 20) {
      console.log("end is at ", end);
      end = rows.length;
    }

    let totalAttendeeValue = 0;
    let totalCheckedIn = 0;

    if (rows !== undefined) {
      totalAttendeeValue = rows.length;
      for (let i = 0; i < rows.length; i++) {
        if (
          rows[i].checkInStatus != undefined &&
          rows[i].checkInStatus != null &&
          rows[i].checkInStatus == true
        ) {
          totalCheckedIn += 1;
        }
      }
    }

    // number of active forms
    // const totalActiveEvents = new Set(rows.map((item) => item.formName))
    //   .size;
    const totalActiveEvents = new Set(formsData).size;

    // Get the size of the Set, which gives the count of unique formNames
    let listN = [];

    rows.map((attendee, index) => {
      let attendeesTicket = 0;
      let fullName = attendee.firstName + " " + attendee.lastName;
      let customInput = "";
      if (attendee.inputs !== undefined) {
        attendee.inputs.map((input, index) => {
          const requiredMessage = input.required ? "" : " (not required)";

          if (input.answers[0] !== undefined) {
            customInput +=
              input.title + " " + input.answers[0] + requiredMessage + ";";
          } else {
            customInput += input.title + "No Input" + requiredMessage + ";";
          }
        });
      }

      let mapB = {
        name: fullName,
        email: attendee.email,
        event: attendee.formName,
        type: attendee.ticketType,
        ticketName: attendee.ticketName,
        code: attendee.confirmationId,
        status: attendee.checkInStatus,
        inputs: customInput,
        state: attendee.state,
      };

      listN.push(mapB);
    });

    this.setState(
      {
        ticketList: rows,
        start: 0,
        end: end,
        totalActiveEvents: totalActiveEvents,
        total: rows.length,
        totalAttendees: totalAttendees,
        totalAttendeeValue: totalAttendeeValue,
        totalCheckedIn: totalCheckedIn,
        formsData: formsData,
        donorsNextList: listN,
        allTickets: allTicketList,
        loading: false,
      },
      () => this.updateFilter()
    );
  };

  goNext = (e) => {
    e.preventDefault();
    const { start, end, ticketList, filteredList } = this.state;
    if (end !== filteredList.length) {
      this.setState({
        start: start + 20,
        end: Math.min(end + 20, filteredList.length),
      });
    }
  };

  goPrev = (e) => {
    e.preventDefault();
    const { start, end, total } = this.state;
    if (start !== 0) {
      this.setState({
        start: Math.max(start - 20, 0),
        end: start,
      });
    }
  };

  headersExport = [
    { label: "Full Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Event", key: "event" },
    { label: "Ticket", key: "ticketName" },
    { label: "Type", key: "type" },
    { label: "Code", key: "code" },
    { label: "Checked-In Status", key: "status" },
    { label: "Inputs", key: "inputs" },
    { label: "Date Purchased", key: "timestamp" },
    { label: "Date Purchased Short", key: "date" },
  ];

  render() {
    const {
      open,
      ticketList,
      start,
      end,
      total,
      showFilter,
      timerange,
      showKeywordFilter,
      showStatusFilter,
      filteredAttendee,
      loading,
      totalDonation,
      avgDonation,
      totalEvents,
      duplicates,
      savedFilterIndicatorKeywordEvent,
      savedFilterIndicatorKeywordAttendee,
      savedFilterIndicatorKeywordSearch,
      savedFilterIndicatorStatus,
      showIndicators,
      listOfAllPossibleAttendees,
      listOfAllPossibleEvents,
      filteredState,
      resetFilter,
      filteredEvent,
      filteredEventList,
      filteredAttendeeList,
      filteredKeywordSet,
      keywordSearchTerm,
      filteredList,
      donorsNextList,
    } = this.state;

    //this.showIndicators = false
    let filterResultsAtBottom = 0;
    let filterResultsAtBottomBool = false;
    let keys = new Set();
    const headersExport = this.headersExport;
    let noFilteredResults = false;
    let savedFilterDivStatus = "";
    let savedFilterDivKeyword = "";

    const stateOptions = [
      {
        option: "Checked-In",
        descriptor: "Tickets with attendees that have checked-in.",
        val: true,
      },
      {
        option: "Not Checked-In",
        descriptor: "Tickets with attendees that have not checked-in.",
        val: false,
      },
    ];

    let filteredListCopy = filteredList;
    let filteredListCopyHolder = filteredList;
    //criteria for saved indicators
    if (
      savedFilterIndicatorKeywordEvent === true ||
      savedFilterIndicatorKeywordAttendee === true ||
      savedFilterIndicatorKeywordSearch === true ||
      savedFilterIndicatorStatus === true ||
      //only for filtering on keyword
      (keywordSearchTerm.length !== 0 && filteredEventList.length !== 0) ||
      (keywordSearchTerm.length !== 0 && filteredAttendeeList.length !== 0) ||
      (filteredEventList.length >= 1 && filteredEventList.length >= 1) ||
      filteredAttendeeList.length >= 1 ||
      filteredEventList.length >= 1 ||
      (keywordSearchTerm.length !== 0 &&
        filteredEventList.length !== 0 &&
        filteredAttendeeList.length !== 0)
    ) {
      this.showIndicators = true;
    }

    if (resetFilter === true) {
      filteredListCopy = filteredListCopyHolder;
    }
    if (resetFilter === false) {
      if (filteredEventList.length !== 0) {
        filteredListCopy = filteredListCopy.filter((t) =>
          filteredEventList.includes(t.formName)
        );
      }
      if (filteredAttendeeList.length !== 0) {
        filteredListCopy = filteredListCopy.filter((t) =>
          filteredAttendeeList.includes(t.firstName + " " + t.lastName)
        );
      }
      if (filteredListCopy.length === 0) {
        noFilteredResults = true;
      }
    }

    let tempKeywordFilter = "";
    if (
      savedFilterIndicatorKeywordEvent === true ||
      savedFilterIndicatorKeywordAttendee === true ||
      savedFilterIndicatorKeywordSearch === true ||
      filteredAttendeeList.length >= 1 ||
      filteredEventList.length >= 1
    ) {
      if (
        (keywordSearchTerm.length !== 0 && filteredEventList.length !== 0) ||
        (keywordSearchTerm.length !== 0 && filteredAttendeeList.length !== 0) ||
        filteredAttendeeList.length >= 1 ||
        filteredEventList.length >= 1 ||
        (keywordSearchTerm.length !== 0 &&
          filteredEventList.length !== 0 &&
          filteredAttendeeList.length !== 0)
      ) {
        let keywordIndicator = `Keyword (${
          filteredEventList.length + filteredAttendeeList.length
        })`;
        if (filteredAttendeeList.length + filteredEventList.length == 1) {
          // show non empty values in filteredAttendeeList or filteredEventList
          keywordIndicator =
            "Keyword: " +
            filteredAttendeeList.filter((s) => s != "") +
            filteredEventList.filter((s) => s != "");
        }

        savedFilterDivKeyword = (
          <button
            type="button "
            className="focus:outline-none inline-block flex px-1 focus:ring-2 focus:ring-offset-2"
            onClick={this.onSavedIndicatorKeywordClick}
            onMouse
          >
            <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
              &nbsp;
              <div className="text-gray-500 hover:text-gray-900 ">
                {" "}
                {keywordIndicator}&nbsp;{" "}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 22 22"
                stroke-width="1"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
          </button>
        );
      }
    }

    if (savedFilterIndicatorStatus === true) {
      let statusIndicator = `Status (${filteredState.length})`;
      if (filteredState.length == 1) {
        // show non empty values in filteredAttendeeList or filteredEventList
        statusIndicator = "Status: " + filteredState.filter((s) => s != "");
      }

      savedFilterDivStatus = (
        <button
          type="button "
          className="focus:outline-none inline-block flex px-1 focus:ring-2 focus:ring-offset-2"
          onClick={this.onSavedIndicatorStatusClick}
          onMouse
        >
          <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
            {" "}
            &nbsp;
            <div className="text-gray-500 hover:text-gray-900 ">
              {" "}
              {statusIndicator}&nbsp;{" "}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 22 22"
              stroke-width="1"
              stroke="currentColor"
              class="h-5 w-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </button>
      );
    }

    let listN = [];

    filteredListCopy.map((attendee, index) => {
      let attendeesTicket = 0;
      let fullName = attendee.firstName + " " + attendee.lastName;
      let customInput = "";
      if (attendee.inputs !== undefined) {
        attendee.inputs.map((input, index) => {
          const requiredMessage = input.required ? "" : " (not required)";

          if (input.answers[0] !== undefined) {
            customInput +=
              input.title + " " + input.answers[0] + requiredMessage + ";";
          } else {
            customInput += input.title + "No Input" + requiredMessage + ";";
          }
        });
      }

      let mapB = {
        name: fullName,
        email: attendee.email,
        event: attendee.formName,
        ticketName: attendee.ticketName,
        type: attendee.ticketType === "SINGLE" ? "Individual" : "Group",
        code: attendee.confirmationId,
        status: attendee.checkInStatus,
        inputs: customInput,
        timestamp: attendee.timestamp,
      };

      listN.push(mapB);
    });

    // update metrics using filtersListCopy
    let totalAttendeeValue = filteredListCopy.length;
    let totalCheckedIn = 0;
    let eventsSet = new Set();
    for (let i = 0; i < filteredListCopy.length; i++) {
      if (
        filteredListCopy[i].checkInStatus != undefined &&
        filteredListCopy[i].checkInStatus != null &&
        filteredListCopy[i].checkInStatus == true
      ) {
        totalCheckedIn += 1;
      }
      let formName = filteredListCopy[i].formName;

      eventsSet.add(formName);
    }
    console.log("updatingggg");
    this.props.getMetrics(
      eventsSet.size,
      0, // not used
      0, // not used
      totalAttendeeValue,
      totalCheckedIn
    );

    let ticketTableContent = filteredListCopy
      .slice(start, end)
      .map((ticket, index) => {
        const id =
          ticket.firstName + " " + ticket.lastName + " | " + ticket.formName;
        let attendeesTicket = 0;
        let status = ticket.checkInStatus == true ? "Checked-In" : "Check-In";

        let statusColor =
          ticket.checkInStatus == true
            ? "px-3 text-center text-green-700 bg-green-100 rounded-full hover:text-green-900 focus:outline-none"
            : "px-3 text-center text-yellow-700 bg-yellow-100 rounded-full hover:text-yellow-900 focus:outline-none"; // focus:outline-none (removes the blue outline thing)

        while (
          ticket.eventActive !== null &&
          ticket.eventActive !== undefined &&
          ticket.eventActive === false
        ) {
          return (
            <tr key={index} className="position-relative">
              <td className="p-2">
                <div className="items-left flex">
                  <p className="text-base text-gray-600">
                    {ticket.firstName + " " + ticket.lastName}
                  </p>
                  <p className="text-sm text-gray-400">{ticket.email}</p>
                </div>
              </td>
              <td className="p-2">
                <div className="text-left text-xs">{ticket.formName}</div>
              </td>
              <td className="p-2">
                <div className="text-left text-xs">{ticket.ticketName}</div>
              </td>
              <td className="p-2">
                <div className="items-center text-center">
                  <button className="focus:outline-none	rounded-full bg-gray-300 px-3 text-center text-gray-700">
                    {ticket.confirmationId}
                  </button>
                </div>
              </td>
              <td className="p-2">
                <div className="items-center text-center">
                  <button
                    className={statusColor}
                    onClick={() => {
                      ticket.checkInStatus = !ticket.checkInStatus;
                      this.updateTicket(ticket);
                      // this.updateTicket(attendee);
                    }}
                  >
                    {status}
                  </button>
                </div>
              </td>
            </tr>
          );
        }
      });
    let next =
      "btn focus:outline-none  bg-white border-gray-100 hover:border-gray-300 text-light";
    let prev =
      "btn focus:outline-none bg-white border-gray-100 hover:border-gray-300 text-light";
    if (end === total) {
      next =
        "btn focus:outline-none bg-white border-gray-100 text-gray-300 cursor-not-allowed";
    }
    if (start === 0) {
      prev =
        "btn focus:outline-none bg-white border-gray-100 text-gray-300 cursor-not-allowed";
    }

    return (
      <div className="col-span-full xl:col-span-12">
        {!loading && !this.showIndicators && (
          <div className="flex flex-row justify-end py-3 ">
            <div className="flex">
              <button
                type="button "
                className="focus:outline-none  inline-block flex focus:ring-2 focus:ring-offset-2 "
                onClick={this.onFilterClickHandler}
                onMouse
              >
                <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 17"
                    stroke="#a1afc2"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                    />
                  </svg>
                  &nbsp;
                  <div className="text-gray-500 hover:text-gray-900 ">
                    {" "}
                    Filter &nbsp;&nbsp;&nbsp;{" "}
                  </div>
                </div>
              </button>
            </div>
            <div> &nbsp;</div>
            <div className="flex ">
              <button
                type="button "
                className="focus:outline-none inline-block flex focus:ring-2 focus:ring-offset-2"
              >
                <CSVLink
                  filename="CharityStack Tickets Export.csv"
                  data={listN.map((item) => ({
                    ...item,
                    timestamp: formatTimestamp(item.timestamp),
                    date: formatTimestampToDate(item.timestamp),
                  }))}
                  headers={headersExport}
                >
                  <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 17"
                      stroke="#a1afc2"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                      />
                    </svg>
                    &nbsp;
                    <div className="text-gray-500 hover:text-gray-900 ">
                      {" "}
                      Export &nbsp;{" "}
                    </div>
                  </div>
                </CSVLink>
              </button>
            </div>
          </div>
        )}
        {!loading && this.showIndicators && (
          //saved filter indicators
          <div>
            <div>
              <div className="flex flex-row justify-between py-3 ">
                <div className="z-10 flex flex-row">
                  {savedFilterDivKeyword}
                  {savedFilterDivStatus}
                </div>
                <div className="flex flex-row justify-end">
                  <div className="inline">
                    <button
                      type="button "
                      className="focus:outline-none  inline-block flex focus:ring-2 focus:ring-offset-2 "
                      onClick={this.onFilterClickHandler}
                      onMouse
                    >
                      <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 17"
                          stroke="#a1afc2"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                          />
                        </svg>
                        &nbsp;
                        <div className="text-gray-500 hover:text-gray-900 ">
                          {" "}
                          Filter &nbsp;&nbsp;&nbsp;{" "}
                        </div>
                      </div>
                    </button>
                  </div>
                  <div> &nbsp;</div>
                  <div className="inline ">
                    <button
                      type="button "
                      className="focus:outline-none inline-block flex focus:ring-2 focus:ring-offset-2"
                    >
                      <CSVLink
                        filename="CharityStack Tickets Export.csv"
                        data={listN.map((item) => ({
                          ...item,
                          timestamp: formatTimestamp(item.timestamp),
                          date: formatTimestampToDate(item.timestamp),
                        }))}
                        headers={headersExport}
                      >
                        <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 17"
                            stroke="#a1afc2"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                          </svg>
                          &nbsp;
                          <div className="text-gray-500 hover:text-gray-900 ">
                            {" "}
                            Export &nbsp;{" "}
                          </div>
                        </div>
                      </CSVLink>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showFilter && (
          <div class="container relative bg-gray-500">
            <div
              class="
              border-sky-500 absolute right-20 items-stretch justify-around gap-4 divide-x rounded-lg  border-2 border-solid bg-white p-4 p-4 text-sm drop-shadow-xl"
              style={{ minWidth: "500px" }}
            >
              <div class="flow-root divide-x">
                <div class="py-30 float-left grid grid-rows-6 gap-8 px-1 pt-7">
                  <div>
                    <button type="button " onClick={this.onFilterKeywordFilter}>
                      <div className="inline-block flex justify-center align-baseline">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 20"
                          stroke-width="1.5"
                          stroke="#a1afc2"
                          class="h-5 w-5"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
                            clipRule="evenodd"
                          />
                        </svg>{" "}
                        &nbsp;
                        <div className="text-gray-500 hover:text-gray-900 ">
                          {" "}
                          Keyword{" "}
                        </div>
                      </div>
                    </button>
                  </div>

                  <div>
                    <button type="button" onClick={this.onFilterStatusFilter}>
                      <div className="inline-block flex justify-center align-baseline">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 20"
                          stroke-width="1.5"
                          stroke="#a1afc2"
                          class="h-5 w-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 6h.008v.008H6V6z"
                          />
                        </svg>
                        &nbsp;
                        <div className="text-gray-500 hover:text-gray-900 ">
                          {" "}
                          Status{" "}
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className="py-2"></div>
                  <div className="py-2"></div>
                  <div className="py-2"></div>
                  <div>
                    <button type="button" onClick={this.onResetFilter}>
                      <div className="inline-block justify-center align-bottom">
                        <div className="text-red-700 hover:text-red-300 ">
                          {" "}
                          Clear Filters{" "}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="flex content-start justify-start border-none">
                  {showKeywordFilter && (
                    <div className="justify-start pl-7 pr-6">
                      <div className="rounded-md border-gray-300 pl-8 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"></div>
                      <div class="relative mt-1 rounded-md shadow-sm">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span class="text-gray-500 sm:text-sm">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 22 22"
                              stroke-width="1.5"
                              stroke="#a1afc2"
                              class="h-4 w-4"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </div>
                        <label htmlFor="keywordsearch" className="sr-only">
                          Search
                        </label>
                        <input
                          type="search"
                          name="keywordsearch"
                          id="keywordsearch"
                          className="w-80 rounded-md border-gray-300 pl-8 pt-3 text-xs shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          placeholder="Search for Attendee, Email, Code, or Event"
                          onChange={(e) => this.onKeywordSearchFilter(e)}
                        />
                      </div>

                      <div className="pt-4 text-base underline">
                        {"Attendees"}
                      </div>

                      {listOfAllPossibleAttendees.map((attendee) => {
                        return (
                          <div className="justify-begin justify-left flex items-start justify-start">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 py-1 text-indigo-600 focus:ring-0"
                              onChange={(e) =>
                                this.onFilteredAttendeeClickHandler(e)
                              }
                              value={attendee.toString()}
                              checked={filteredAttendeeList.includes(attendee)}
                            />{" "}
                            &nbsp;
                            {attendee.toString()}
                          </div>
                        );
                      })}
                      <div className="pt-4 text-base underline">{"Events"}</div>
                      {listOfAllPossibleEvents.map((event) => {
                        return (
                          <div className="justify-begin justify-left flex items-center justify-start">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0"
                              onChange={(e) =>
                                this.onFilteredEventClickHandler(e)
                              }
                              value={event.toString()}
                              checked={filteredEventList.includes(event)}
                            />{" "}
                            &nbsp;
                            {event.toString()}
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {showStatusFilter && (
                    <div className="justify-start pl-7 pr-6">
                      <div className="w-85 rounded-md border-gray-300 pl-8 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"></div>
                      <div className="pt-6 text-base underline">Status</div>
                      {stateOptions.map((ticket, index) => {
                        return (
                          <div className="justify-begin justify-left flex items-center justify-start">
                            <label>
                              <input
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 py-1 text-indigo-600 focus:ring-0"
                                onChange={(e) =>
                                  this.onFilteredStateClickHandler(e)
                                }
                                value={ticket.option.toString()}
                                checked={filteredState.includes(
                                  ticket.option.toString()
                                )}
                              />{" "}
                              &nbsp;
                              {ticket.option.toString()}
                              <div className="text-xxs w-85 text-grey-500 pb-3 pl-6  text-gray-500">
                                {stateOptions[index].descriptor.toString()}
                              </div>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="border-gray-2000 col-span-full rounded-lg border bg-white shadow-sm xl:col-span-12">
          {/* Table */}
          {loading && (
            <div class="min-w-screen flex min-h-screen justify-center bg-gray-100 p-5 pt-32">
              <div class="flex animate-pulse space-x-2">
                <div class="h-3 w-3 rounded-full bg-gray-500"></div>
                <div class="h-3 w-3 rounded-full bg-gray-500"></div>
                <div class="h-3 w-3 rounded-full bg-gray-500"></div>
              </div>
            </div>
          )}
          <div className="p-3">
            <div>
              {noFilteredResults && !loading && (
                <div
                  class="rounded-2xl border border-blue-100 bg-white p-8 shadow-lg"
                  role="alert"
                  id="noMatchFound"
                >
                  <div class="items-center sm:flex">
                    <span class="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#a1afc2"
                        class="h-6 w-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                        />
                      </svg>
                    </span>

                    <p class="mt-3 text-lg font-medium sm:ml-3 sm:mt-0">
                      No matches found
                    </p>
                  </div>

                  <p class="mt-4 text-gray-500">
                    Unfortunately, no results match the search criteria you
                    entered. Try making a change to the filters you selected.
                    <br></br> If this issue persists, please reach out to the{" "}
                    <a href="mailto:support@charitystack.org">
                      <span className="text-blue-400 hover:animate-spin">
                        {" "}
                        CharityStack team!
                      </span>{" "}
                    </a>
                  </p>
                  {/*}
             <div class="mt-6 sm:flex">
               <a
                 class="inline-block w-full px-5 py-3 text-sm font-semibold text-center text-white bg-blue-500 rounded-lg sm:w-auto"
                 href=""
               >
                 Take a Look
               </a>

               <a
                 class="inline-block w-full px-5 py-3 mt-3 text-sm font-semibold text-center text-gray-500 rounded-lg bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto"
                 href=""
               >
                 Mark as Read
               </a>
             </div> */}
                </div>
              )}
              <div className="overflow-x-auto">
                {!noFilteredResults && (
                  <div className="overflow-x-auto">
                    <table className="w-full table-auto">
                      {/* Table header */}
                      <thead className="rounded-sm bg-gray-50 text-xs uppercase text-gray-400">
                        <tr>
                          <th className="p-2">
                            <div className="text-left font-semibold">
                              Attendee
                            </div>
                          </th>
                          <th className="p-2">
                            <div className="text-left font-semibold">Event</div>
                          </th>
                          <th className="p-2">
                            <div className="text-center font-semibold">
                              Ticket
                            </div>
                          </th>
                          <th className="p-2">
                            <div className="text-center font-semibold">
                              Type
                            </div>
                          </th>
                          <th className="p-2">
                            <div className="text-center font-semibold">
                              Code
                            </div>
                          </th>
                          <th className="p-2">
                            <div className="text-center font-semibold">
                              Status
                            </div>
                          </th>
                        </tr>
                      </thead>
                      {/* Table body */}
                      <tbody className="divide-y divide-gray-100 text-sm font-medium">
                        {filteredListCopy
                          .slice(start, end)
                          .map((attendee, index) => {
                            // donor.grossAmount = (donor.grossAmount).toLocaleString(undefined, { maximumFractionDigits: 2 })
                            const id =
                              attendee.firstName +
                              " " +
                              attendee.lastName +
                              " | " +
                              attendee.formName;
                            let attendeesTicket = 0;

                            // console.log(attendee)

                            let status =
                              attendee.checkInStatus == true
                                ? "Checked-In"
                                : "Check-In";

                            let statusColor =
                              attendee.checkInStatus == true
                                ? "px-3 text-center text-green-700 bg-green-100 rounded-full hover:text-green-900 focus:outline-none"
                                : "px-3 text-center text-yellow-700 bg-yellow-100 rounded-full hover:text-yellow-900 focus:outline-none"; // focus:outline-none (removes the blue outline thing)

                            return (
                              <tr>
                                <td className="p-2">
                                  <div className="items-center">
                                    <div className="text-s flex flex-col text-left">
                                      <p className="text-base text-gray-600">
                                        {attendee.firstName +
                                          " " +
                                          attendee.lastName}
                                      </p>
                                      <p className="text-sm text-gray-400">
                                        {attendee.email}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td className="p-2">
                                  <div className="items-center">
                                    <div className="text-s text-left">
                                      {attendee.formName}
                                    </div>
                                  </div>
                                </td>

                                <td className="p-2">
                                  <div className="items-center text-center">
                                    <div className="text-center">
                                      {attendee.ticketName}
                                    </div>
                                  </div>
                                </td>
                                <td className="p-2">
                                  <div className="items-center text-center">
                                    <div className="text-center">
                                      {attendee.ticketType === "SINGLE"
                                        ? "Individual"
                                        : "Group"}
                                    </div>
                                  </div>
                                </td>
                                <td className="p-2">
                                  <div className="items-center text-center">
                                    <button className="focus:outline-none	rounded-full bg-gray-300 px-3 text-center text-gray-700">
                                      {attendee.confirmationId}
                                    </button>
                                  </div>
                                </td>
                                <td className="p-2">
                                  <div className="items-center text-center">
                                    <button
                                      className={statusColor}
                                      onClick={() => {
                                        attendee.checkInStatus =
                                          !attendee.checkInStatus;
                                        this.updateTicket(attendee);
                                        // this.updateTicket(attendee);
                                      }}
                                    >
                                      {status}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        {}
                      </tbody>
                    </table>
                    <div className="mt-8">
                      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                        <nav
                          className="mb-4 sm:order-1"
                          role="navigation"
                          aria-label="Navigation"
                        >
                          <ul className="flex justify-center">
                            <li className="ml-3 first:ml-0">
                              <a
                                className={prev}
                                href="#0"
                                onClick={this.goPrev}
                              >
                                &lt;- Previous
                              </a>
                            </li>
                            <li className="ml-3 first:ml-0">
                              <a
                                className={next}
                                href="#0"
                                onClick={this.goNext}
                              >
                                Next -&gt;
                              </a>
                            </li>
                          </ul>
                        </nav>
                        {filterResultsAtBottomBool && (
                          <div className="text-center text-sm text-gray-500 sm:text-left">
                            Showing{" "}
                            <span className="font-medium text-gray-600">
                              {start}
                            </span>{" "}
                            to{" "}
                            <span className="font-medium text-gray-600">
                              {end}
                            </span>{" "}
                            of{" "}
                            <span className="font-medium text-gray-600">
                              {filterResultsAtBottom}
                            </span>{" "}
                            results
                            <div></div>
                          </div>
                        )}
                        {!filterResultsAtBottomBool && (
                          <div className="text-center text-sm text-gray-500 sm:text-left">
                            Showing{" "}
                            <span className="font-medium text-gray-600">
                              {start}
                            </span>{" "}
                            to{" "}
                            <span className="font-medium text-gray-600">
                              {end}
                            </span>{" "}
                            of{" "}
                            <span className="font-medium text-gray-600">
                              {total}
                            </span>{" "}
                            results
                            <div></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TicketsTable;
