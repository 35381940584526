import React, { useRef, useState, useEffect, useCallback } from 'react'
import Dropdown from '../../dropdowns/Dropdown';
import SingleSelectNoCreateDropdown from '../../dropdowns/SingleSelectNoCreateDropdown';
import Toggle from '../../Toggle'
import ReactTooltip from "react-tooltip";
import { Draggable } from 'react-beautiful-dnd';
import { Bars3Icon } from '@heroicons/react/20/solid'
import { TrashIcon } from '@heroicons/react/24/outline'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';


function CustomTicketCreationContent(props) {
    const { key, id, CustomTicket, setCustomTicket, index, handleEmailChange, handleFirstNameChange, handleLastNameChange, handleTypeChange, tickets, removeCustomTicket, typeChange, setTypeChange, setInputtedTickets, inputtedTickets, setIndex } = props


    return (
        <Draggable draggableId={id} index={index}>
            {
                (provided) => (
                    <div
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        className="border-b border-gray-300"
                    >
                        <div {...provided.dragHandleProps}>

                            <Bars3Icon className="h-6 w-6 ml-auto mr-4 mt-2" aria-hidden="true" />
                        </div>


                        <div className="space-y-1 sm:space-y-0 mb-5 sm:divide-y sm:divide-gray-300 ">
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 ">
                                <label
                                    htmlFor="form-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                    Ticket Type

                                </label>
                                <div className="flex space-x-10 sm:col-span-2 pt-2 text-sm w-full">
                                    {tickets != undefined && tickets.length > 0 && tickets != null && (
                                        <div className='w-full'>
                                            <SingleSelectNoCreateDropdown
                                                options={tickets.map((ticket) => {
                                                    return { value: ticket.name, label: ticket.name, color: '#69995E', seats: ticket.seats, price: ticket.price }
                                                })}
                                                setItems={handleTypeChange}
                                                items={[inputtedTickets[index].type]}
                                                placeholder="Select Ticket"
                                                dataIndex={index}
                                            />

                                        </div>
                                    )}
                                </div>
                            </div>


                        </div>

                        <div className="space-y-1 sm:space-y-0 mb-5 sm:divide-y sm:divide-gray-300 ">
                            
                     

                        {inputtedTickets.length > 0 ? (
                            inputtedTickets[index].attendees.map((attendee, i) => (
                                <div>
                                    <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 pt-3 sm:gap-4 sm:space-y-0 sm:px-6">
                                        <label
                                            htmlFor="form-name"
                                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                        >
                                            First Name

                                        </label>
                                        <div className="flex space-x-10 sm:col-span-2 pt-2 text-sm ">
                                            <input
                                                data-type="title"
                                                data-index={i}
                                                value={attendee.firstName}
                                                type="text"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                onChange={(e) => handleFirstNameChange(e, i, index)}
                                                maxLength={50}
                                            />
                                        </div>
                                    </div>


                                    <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 pt-3 sm:gap-4 sm:space-y-0 sm:px-6">
                                        <label
                                            htmlFor="form-name"
                                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                        >
                                            Last Name

                                        </label>
                                        <div className="flex space-x-10 sm:col-span-2 pt-2 text-sm ">
                                            <input
                                                data-type="title"
                                                data-index={i}
                                                value={attendee.lastName}
                                                type="text"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                onChange={(e) => handleLastNameChange(e, i, index)}
                                                maxLength={50}
                                            />
                                        </div>
                                    </div>


                                    <div className="space-y-1 px-4 mb-5 sm:grid sm:grid-cols-3 sm:gap-4 pt-3 sm:space-y-0 sm:px-6">
                                        <label
                                            htmlFor="form-name"
                                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                        >
                                            Email

                                        </label>
                                        <div className="flex space-x-10 sm:col-span-2 pt-2 text-sm ">
                                            <input
                                                data-type="title"
                                                data-index={i}
                                                value={attendee.email}
                                                type="text"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                onChange={(e) => handleEmailChange(e, i, index)}
                                                maxLength={50}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <></>
                        )}
                           </div>









                        <div className='flex justify-end mr-4 mb-2 pt-5'>
                            <div className='flex cursor-pointer' data-index={index} onClick={(e) => removeCustomTicket(index)}>
                                <TrashIcon data-index={index} className="h-5 w-5 mr-2 mt-0.5 text-red-500" aria-hidden="true" />
                                <div data-index={index} className='text-red-500'> remove ticket </div>
                            </div>
                        </div>


                    </div >
                )
            }
        </Draggable >
    )
}

export default CustomTicketCreationContent