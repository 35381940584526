import React, { useRef, useState, useEffect, useCallback } from "react";
import Dropdown from "../../dropdowns/Dropdown";
import MultiSelectDropdown from "../../dropdowns/MultiSelectDropdown";
import Toggle from "../../Toggle";
import ReactTooltip from "react-tooltip";
import { Draggable } from "react-beautiful-dnd";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";

const INPUT_TYPE_MAPPING = {
  SHORT_TEXT_INPUT: "Short Text Input",
  LONG_TEXT_INPUT: "Long Text Input",
  SINGLE_SELECTION_DROPDOWN: "Single Selection Dropdown",
  MULTI_SELECTION_DROPDOWN: "Multi Selection Dropdown",
};

function CustomInputsTabContent_Events(props) {
  const {
    moveCard,
    customInput,
    id,
    index,
    originalCustomInputs,
    handleTitleChange,
    handleTypeChange,
    handleOptionsChange,
    handleRequiredChange,
    handleQuestionTypeChange,
    removeCustomInput,
  } = props;
  console.log(originalCustomInputs);
  const [purchaser, setPurchaser] = useState(true);

  useEffect(() => {
    if (customInput.questionType === "PURCHASER") {
      setPurchaser(true);
    } else {
      setPurchaser(false);
    }
  }, []);

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className="border-b border-gray-300"
        >
          <div {...provided.dragHandleProps}>
            <Bars3Icon
              className="ml-auto mr-4 mt-2 h-6 w-6"
              aria-hidden="true"
            />
          </div>
          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <label
              htmlFor="form-name"
              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
              Title/Question*
              <svg
                data-tip
                data-for="titleTooltip"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="text-primary ml-1 inline-block h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
              <ReactTooltip id="titleTooltip" effect="solid" place="right">
                <p className="text-sm text-white">
                  This is the question/title you will show to{" "}
                </p>
                <p className="text-sm text-white">your donors.</p>
              </ReactTooltip>
            </label>
            <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
              <input
                data-type="title"
                data-index={index}
                value={customInput.title}
                placeholder={"e.g. How did you find our website?"}
                type="text"
                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                onChange={(e) => handleTitleChange(e)}
                // maxLength={50}
              />
            </div>
          </div>

          {/* Form name */}
          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <label
              htmlFor="form-name"
              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
              Input Type*
              <svg
                data-tip
                data-for="inputTypeToolTip"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="text-primary ml-1 inline-block h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
              <ReactTooltip id="inputTypeToolTip" effect="solid" place="right">
                <p className="text-sm text-white">
                  The input type can be short text, long text, or
                </p>
                <p className="text-sm text-white">
                  a dropdown of choices. Please select the type
                </p>
                <p className="text-sm text-white">
                  that best fulfills your needs.
                </p>
              </ReactTooltip>
            </label>
            <div className="sm:col-span-2">
              {/* TODO: Add Multi Selection Dropdown to list of values when implemented on donation form */}
              <Dropdown
                dataIndex={index}
                values={[
                  "Short Text Input",
                  "Long Text Input",
                  "Single Selection Dropdown",
                ]}
                selectedValue={
                  customInput.type == "Select an Input Type"
                    ? customInput.type
                    : INPUT_TYPE_MAPPING[customInput.type]
                }
                setSelectedValue={handleTypeChange}
                width={"w-96"}
              />
            </div>
          </div>
          {(customInput.type === "SINGLE_SELECTION_DROPDOWN" ||
            customInput.type === "MULTI_SELECTION_DROPDOWN") && (
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
              <label
                htmlFor="form-name"
                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
              >
                Input Options*
                <svg
                  data-tip
                  data-for="choicesTooltip"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="text-primary ml-1 inline-block h-5 w-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  />
                </svg>
                <ReactTooltip id="choicesTooltip" effect="solid" place="right">
                  <p className="text-sm text-white">
                    These are choices that the donor will be able to select
                  </p>
                  <p className="text-sm text-white">
                    from when answering your custom input. You can type
                  </p>
                  <p className="text-sm text-white">
                    in new options or remove current ones.
                  </p>
                </ReactTooltip>
              </label>
              <div className="sm:col-span-2">
                <MultiSelectDropdown
                  options={
                    // only map old options if the index is less than the length of the original custom inputs, b/c otherwise it doesn't exist
                    index < originalCustomInputs.length
                      ? // map the options to the format that the dropdown expects
                        originalCustomInputs[index].options.map((option) => {
                          return {
                            label: option,
                            value: option,
                          };
                        })
                      : []
                  }
                  setItems={handleOptionsChange}
                  items={customInput.options}
                  placeholder="Select/Type an option"
                  dataIndex={index}
                />
              </div>
            </div>
          )}
          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <div>
              <h3 className="inline-block text-sm font-medium text-gray-900">
                Required
              </h3>
              <svg
                data-tip
                data-for="requiredTooltip"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="text-primary ml-1 inline-block h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
              <ReactTooltip id="requiredTooltip" effect="solid" place="right">
                <p className="text-sm text-white">
                  You can set this custom input to be required
                </p>
                <p className="text-sm text-white">
                  by donors. By enabling this option, donors must
                </p>
                <p className="text-sm text-white">
                  answer your custom input before submitting their
                </p>
                <p className="text-sm text-white">donation.</p>
              </ReactTooltip>
            </div>
            <div className="sm:col-span-2">
              <div className="">
                <div className="mt-2 block">
                  <Toggle
                    dataIndex={index}
                    name={"Required"}
                    enabled={customInput.required}
                    setEnabled={handleRequiredChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <div>
              <h3 className="inline-block text-sm font-medium text-gray-900">
                Question Type
              </h3>
              <svg
                data-tip
                data-for="qTypeTooltip"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="text-primary ml-1 inline-block h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
              <ReactTooltip id="qTypeTooltip" effect="solid" place="right">
                <p className="text-sm text-white">
                  Purchaser: Allows the standard custom inputs per purchase.
                </p>
                <p className="text-sm text-white">
                  Attendee: This adds a new custom input per attendee.
                </p>
              </ReactTooltip>
            </div>
            <div className="sm:col-span-2">
              <div className="">
                <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
                  <input
                    type="radio"
                    value="PURCHASER"
                    name="typeChoose"
                    data-type="title"
                    data-index={index}
                    onClick={(e) => {
                      setPurchaser(true);
                      handleQuestionTypeChange(e);
                    }}
                    style={{
                      backgroundColor: purchaser === true ? "green" : "white",
                      marginTop: "4px",
                      marginRight: "5px",
                    }}
                  />
                  Purchaser
                  <input
                    type="radio"
                    value="ATTENDEE"
                    name="typeChoose"
                    data-type="title"
                    data-index={index}
                    onClick={(e) => {
                      setPurchaser(false);
                      handleQuestionTypeChange(e);
                    }}
                    style={{
                      backgroundColor: purchaser === false ? "green" : "white",
                      marginTop: "4px",
                      marginRight: "5px",
                    }}
                  />
                  Attendee
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-300 ">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 "></div>
          </div>

          <div className="mb-2 mr-4 flex justify-end">
            <div
              className="flex cursor-pointer"
              data-index={index}
              onClick={(e) => removeCustomInput(index)}
            >
              <TrashIcon
                data-index={index}
                className="mr-2 mt-0.5 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
              <div data-index={index} className="text-red-500">
                {" "}
                remove custom field{" "}
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

export default CustomInputsTabContent_Events;
