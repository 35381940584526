import React, { useState, useEffect, useCallback } from "react";
import ReactTooltip from "react-tooltip";
import ElementsTabContent from "./ElementsTabContent";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { PlusIcon } from "@heroicons/react/20/solid";

const INPUT_TYPE_MAPPING = {
  FUNDRAISING_BAR: "Fundraising Bar",
  QR_CODE: "QR Code",
  DONOR_LIST: "Donor List",
};

const REVERSE_INPUT_TYPE_MAPPING = {
  "Fundraising Bar": "FUNDRAISING_BAR",
  "QR Code": "QR_CODE",
  "Donor List": "DONOR_LIST",
};

function ElementsTab(props) {
  const {
    elements,
    setElements,
    originalElements,
    elementID,
    elementsSaved,
    merchantName,
    formName,
    submit,
    color,
  } = props;

  const [selectedValueSoFar, setSelectedValueSoFar] = useState([]);

  useEffect(() => {
    if (elements.length > 0 && elements[0].selectedValuesSoFar !== null) {
      setSelectedValueSoFar(elements[0].selectedValuesSoFar);
    }
  }, []);

  useEffect(() => {
    if (elementsSaved) {
      // Map the existing elements and set isSaved to true
      const updatedElements = elements.map((element) => ({
        ...element,
        isSaved: true,
      }));
      console.log("saving elements", updatedElements);
      // Update the elements state with the updatedElements
      setElements(updatedElements);
    }
  }, [elementsSaved, setElements]);

  const setDataIndexValue = (index) => {
    const newElements = [...elements];

    // First, find all unique indices
    const allIndices = new Set(
      newElements.flatMap((element) => element.savedIndicies || [])
    );

    // Add the current index to the set of all indices
    allIndices.add(index);

    // Convert the set back to an array
    const updatedIndices = Array.from(allIndices);

    // Update the savedIndicies for all elements with the updated indices
    newElements.forEach((element) => {
      element.savedIndicies = updatedIndices;
    });
    console.log("fhe", newElements);
    setElements(newElements);
  };

  const setElementListType = (index, value) => {
    const newElements = [...elements];
    newElements[index]["listType"] = value;
    setElements(newElements);
  };

  const setElementSizeOfBar = (index, value) => {
    const newElements = [...elements];
    newElements[index]["embedSize"] = value;
    setElements(newElements);
  };

  const setElementGoal = (index, value) => {
    const newElements = [...elements];
    newElements[index]["goal"] = value;
    setElements(newElements);
  };

  const setElementDonationOffset = (index, value) => {
    const newElements = [...elements];
    newElements[index]["donationsOffset"] = value;
    setElements(newElements);
  };

  const setElementNumberOfDonationOffset = (index, value) => {
    const newElements = [...elements];
    newElements[index]["raisedOffset"] = value;
    setElements(newElements);
  };

  const setElementQRCodeType = (index, value) => {
    const newElements = [...elements];
    newElements[index]["qrCodeType"] = value;
    setElements(newElements);
  };

  const setElementURL = (index, value) => {
    const newElements = [...elements];
    newElements[index]["url"] = value;
    setElements(newElements);
  };

  const handleElementChange = (event) => {
    console.log("event", event);
    let index = parseInt(event.target.dataset.index);
    let value = event.target.dataset.value;
  
    // Ensure selectedValueSoFar is an array or initialize it as an empty array
    const updatedSelectedValueSoFar = Array.isArray(selectedValueSoFar) ? [...selectedValueSoFar] : [];
  
    updatedSelectedValueSoFar[index] = value;
  
    // Update the selectedValueSoFar state variable
    setSelectedValueSoFar(updatedSelectedValueSoFar);
    console.log("Event coming in hot", updatedSelectedValueSoFar);
  
    value = REVERSE_INPUT_TYPE_MAPPING[value];
  
    console.log("event coming in hot", value);
    let newElements = [...elements];
    let newElement = newElements[index];
    // Set default values based on the type
  
    if (value === "FUNDRAISING_BAR") {
      newElement["type"] = value;
      newElement.goal = "";
      newElement.donationsOffset = "";
      newElement.raisedOffset = "";
      newElement.embedSize = "";
      newElement.qrCodeType = ""; // Clear qrCodeType
      newElement.url = ""; // Clear url
      newElement.listType = ""; // Clear listType
    } else if (value === "QR_CODE") {
      newElement["type"] = value;
      newElement.qrCodeType = "";
      newElement.url = "";
      newElement.listType = ""; // Clear listType
      newElement.goal = ""; // Clear goal
      newElement.embedSize = ""; // Clear embedSize
    } else if (value === "DONOR_LIST") {
      newElement["type"] = value;
      newElement.listType = "";
      newElement.goal = ""; // Clear goal
      newElement.embedSize = ""; // Clear embedSize
      newElement.qrCodeType = ""; // Clear qrCodeType
      newElement.url = ""; // Clear url
    }
    newElement["selectedValuesSoFar"] = updatedSelectedValueSoFar;
  
    setElements(newElements);
  };
  

  const createNewElement = () => {
    console.log("elements", elements);

    // Use a random string as the id
    const newId = Math.random().toString(36).substring(2, 9);

    const newElement = {
      type: "Select an Element",
      id: newId,
      isSaved: false, // Add this flag for new elements
      savedIndicies: elements.map((_, i) => i), // Update savedIndicies for all elements
    };

    // Set default values based on the type
    if (newElement.type === "FUNDRAISING_BAR") {
      newElement.goal = "";
      newElement.embedSize = "";
      newElement.donationsOffset = "";
      newElement.raisedOffset = "";
    } else if (newElement.type === "QR_CODE") {
      newElement.qrCodeType = "";
      newElement.url = "";
    } else if (newElement.type === "DONOR_LIST") {
      newElement.listType = "";
    }

    const newElements = [...elements, newElement];
    setElements(newElements);
  };

  const removeElementsTab = (indexToRemove) => {
    console.log("remove element event", selectedValueSoFar);

    // Check if selectedValueSoFar is undefined
    if (selectedValueSoFar === undefined) {
      console.log("selectedValueSoFar is undefined");
      setElements([])
      // setElements() to an empty object
    } else {
      // Create a new array with the updated selected values, removing the value at the specified index
      const updatedSelectedValueSoFar = [...selectedValueSoFar];
      updatedSelectedValueSoFar.splice(indexToRemove, 1);

      // Update the selectedValueSoFar state variable
      setSelectedValueSoFar(updatedSelectedValueSoFar);
      console.log("Event coming in hot", updatedSelectedValueSoFar);

      // Create a new array with the updated elements, removing the element at the specified index
      const newElements = elements.filter((_, index) => index !== indexToRemove);

      // Update the savedIndicies for all elements
      newElements.forEach((element, index) => {
        element.selectedValuesSoFar = updatedSelectedValueSoFar; // Update selectedValuesSoFar for each element

        if (element.savedIndicies) {
          // Update the indices in savedIndicies
          element.savedIndicies = element.savedIndicies.map((idx) =>
            idx > indexToRemove ? idx - 1 : idx
          );
        }
      });

      // Update the elements state variable
      setElements(newElements);
    }
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let newElements = [...elements];
    let newElement = newElements[source.index];
    newElements.splice(source.index, 1);
    newElements.splice(destination.index, 0, newElement);
    console.log(newElements);
    setElements(newElements);
  };

  return (
    <>
      <div className="mb-16 space-y-6 py-6 sm:space-y-0 sm:py-0">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {elements.map((elements, index) => (
                  <ElementsTabContent
                    key={elements.id}
                    id={elements.id}
                    elements={elements}
                    index={index}
                    originalElements={originalElements}
                    handleElementChange={handleElementChange}
                    removeElementsTab={removeElementsTab}
                    setElementListType={setElementListType}
                    setElementSizeOfBar={setElementSizeOfBar}
                    setElementGoal={setElementGoal}
                    setElementNumberOfDonationOffset={setElementNumberOfDonationOffset}
                    setElementDonationOffset={setElementDonationOffset}
                    setElementQRCodeType={setElementQRCodeType}
                    setElementURL={setElementURL}
                    elementID={elementID}
                    selectedValueSoFar={selectedValueSoFar}
                    elementsSaved={elementsSaved}
                    setDataIndexValue={setDataIndexValue}
                    merchantName={merchantName}
                    formName={formName}
                    submit={submit}
                    color={color}
                  />
                ))}
                {provided.placeholder}
                {elements.length < 3 && ( // Check if elements.length is less than maxElements
                  <div className="mr-4 mt-2 flex justify-end">
                    <div
                      className="flex cursor-pointer"
                      onClick={createNewElement}
                    >
                      <PlusIcon
                        className="mr-2 mt-0.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <div className="text-gray-400"> add Element </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
}

export default ElementsTab;
