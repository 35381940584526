import React, { Component } from "react";
import { StrictMode } from "react/cjs/react.production.min";
import PaginationClassic from "../PaginationClassic";
import moment from "moment";
import { Auth } from "aws-amplify";
import DonationDatePicker from "./DonationDatePicker";
import { CSVLink } from "react-csv";
import decompressData from "../../utils/decompressData";
import pako from "pako";

export class FundsTable extends Component {
  constructor(props) {
    super(props);

    const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
    let today = new Date();
    // increment today forward by 1 day
    today.setDate(today.getDate() + 1);
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() - 6000);
    const startTimerange =
      tomorrow.toLocaleDateString("en-US", DATE_OPTIONS) +
      " - " +
      today.toLocaleDateString("en-US", DATE_OPTIONS);
    this.state = {
      jwtToken: "",
      donorsList: [],
      start: 0,
      end: 0,
      total: 0,
      totalDonors: 0,
      grossAmountVariable: 0,
      totalDonationSum: 0,
      totalDonations: 0,
      totalDonorValue: 0,
      timerange: startTimerange,
      showFilter: false,
    };
  }

  onFilterClickHandler = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  async componentDidMount() {
    const data = await Auth.currentSession();
    let user = await Auth.currentAuthenticatedUser();
    this.setState(
      {
        jwtToken: data["idToken"]["jwtToken"],
        whoIsLoggedIn: user.username,
      },
      () => this.updateData()
    );
  }

  convertTimestamp(timestamp) {
    const sections = timestamp.split("-");
    return sections[1] + "-" + sections[2] + "-" + sections[0];
  }

  setDateTimeRange = (timerange) => {
    this.setState({ timerange: timerange }, () => this.updateData());
  };

  updateData = async () => {
    // grab forms data
    const formsRequestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: this.state.jwtToken,
      },
    };

    const formsURL = process.env.REACT_APP_FORMS_URL;

    let formsResponse = await fetch(formsURL, formsRequestOptions);
    let formsData = await formsResponse.json();

    formsData = formsData.filter((formData) => formData.formType !== "EVENT");

    console.log(formsData);

    // create a map of formname string to states from formsdata
    const formsActive = new Map();
    // create map of formName string to set of active funds
    const activeFunds = new Map();
    formsData.forEach((form) => {
      const key = form.nonprofitName + " | " + form.formName;
      formsActive.set(key, form.active);
      if (!activeFunds.has(key)) {
        activeFunds.set(key, new Set());
      }
      form["funds"].forEach((fund) => {
        activeFunds.get(key).add(fund);
      });
    });

    console.log(activeFunds);

    // grab transaction data
    const requestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: this.state.jwtToken,
      },

      body: JSON.stringify({
        timerange: this.state.timerange,
        merchantID: global.merchantID,
      }),
    };

    const url = process.env.REACT_APP_DONATIONS_URL;
    let res = [];
    let lastEvaluatedKeys = {};
    while (true) {
      // fetch data
      let response = await fetch(url, requestOptions);
      // get response in json
      let response_json = await response.json();
      // decompress data
      let decompressed_data = decompressData(response_json);
      console.log("DECOMPRESSED DATA", decompressed_data);
      // extend res to include decompressed_data['transactions']
      res = res.concat(decompressed_data["transactions"]);
      lastEvaluatedKeys = decompressed_data["lastEvaluatedKeys"];

      // break if lastEvaluatedKeys is an object of size 0
      if (
        lastEvaluatedKeys === undefined ||
        lastEvaluatedKeys === null ||
        Object.keys(lastEvaluatedKeys).length === 0
      ) {
        console.log("no more last evaluated key");
        break;
      }

      console.log("has lastEvaluatedKeys", lastEvaluatedKeys);
      // update body to include lastEvaluatedKey
      requestOptions.body = JSON.stringify({
        timerange: this.state.timerange,
        lastEvaluatedKeys: lastEvaluatedKeys,
      });
    }
    // transacitons
    res = res.filter(
      (row) => row.tickets === undefined || row.tickets === null
    );

    let end = 20;
    if (res.length < 20) {
      end = res.length;
    }
    let totalDonors = {};
    let grossAmountVariable = 0;
    let totalDonationSum = 0; // = 0.0;
    let totalDonations = 0;
    let totalDonorValue = 0;

    for (let index = 0; index < res.length; index++) {
      /*    if (res[index].state === "SUCCEEDED") {
               res[index].grossAmountVar = (
                 res[index].amount - res[index].fees
               ).toFixed(2);
             } */
    }
    //Get all unique donors by email and sponsee
    let keys = [];
    //Table rows with calculated gross donations from each unique email
    let rows = [];
    res.map((donation) => {
      // skip internal donation
      if (donation.formName == "Internal Donation") {
        return;
      }
      let grossAmountVariable = parseFloat(donation.grossAmount).toFixed(2);
      let key = donation.fund ? donation.fund.trim() : ""; // Handle possible null fund
      let donorKey =
        donation.name.toLowerCase() + " - " + donation.email.toLowerCase();
      const monthlyDonation = donation.donationFrequency === "monthly"; // Assumes donationFrequency is a string that can be "monthly"

      if (donation.state === "SUCCEEDED" || donation.state === "Success") {
        // Check for unique donor once per donation
        if (!totalDonors.hasOwnProperty(donorKey)) {
          totalDonors[donorKey] = true; // Mark this donor as counted.
          totalDonorValue += 1; // Increment your unique donors count.
        }
        // Handle multi-fund donations
        if (
          donation.funds !== null &&
          donation.funds !== undefined &&
          donation.funds.length > 0
        ) {
          donation.funds.forEach((fund) => {
            const fundAmount = parseFloat(fund.amount);
            const fundQuantity = parseInt(fund.quantity);
            const totalFundAmount = fundAmount * fundQuantity;

            totalDonations += fundQuantity;
            totalDonationSum += totalFundAmount;

            let fundKey = fund.fundName.trim(); // Use fundName as key

            let fundKeyIndex = keys.indexOf(fundKey);

            if (fundKeyIndex !== -1) {
              // Update existing fund data
              rows[fundKeyIndex].grossAmountVar = (
                parseFloat(rows[fundKeyIndex].grossAmountVar) + totalFundAmount
              ).toFixed(2);
              rows[fundKeyIndex].totalDonationNumberCounter += fundQuantity;
            } else {
              keys.push(fundKey);
              rows.push({
                name: donation.name,
                email: donation.email,
                fund: fund.fundName,
                grossAmount: totalFundAmount.toFixed(2), // Convert to string with two decimal places here
                monthlyDonor: monthlyDonation,
                grossAmountVar: totalFundAmount.toFixed(2), // Convert to string with two decimal places here
                totalDonors: Object.keys(totalDonors).length,
                totalDonationNumberCounter: fundQuantity,
                totalDonorValue: totalDonorValue,
                nonprofitname: donation.merchantName,
              });
            }
          });
        } else {
          // Handle single-fund donations...
          // Update the totalDonationSum safely by adding the number directly
          // totalDonationSum += parseFloat(grossAmountVariable);
          // Rest of your single-fund donation handling code...
          let keyIndex = keys.indexOf(key);
          totalDonationSum += parseFloat(grossAmountVariable);
          totalDonations += 1;
          if (!totalDonors.hasOwnProperty(donorKey)) {
            totalDonors[donorKey] = "exists";
          }

          if (keyIndex !== -1) {
            totalDonorValue += 1;
            rows[keyIndex].totalDonationNumberCounter += 1;
            rows[keyIndex].grossAmountVar = (
              parseFloat(rows[keyIndex].grossAmountVar) +
              parseFloat(grossAmountVariable)
            ).toFixed(2);
          } else {
            keys.push(key);
            rows.push({
              name: donation.name,
              email: donation.email,
              fund: key,
              grossAmount: grossAmountVariable,
              monthlyDonor: monthlyDonation === "monthly",
              grossAmountVar: grossAmountVariable,
              totalDonors: Object.keys(totalDonors).length,
              totalDonationNumberCounter: 1,
              totalDonorValue: totalDonorValue,
              nonprofitname: donation.merchantName,
            });
          }
        }
      }

      if (
        donation.state === "PARTIAL_REFUND" ||
        donation.state === "Partial Refund"
      ) {
        console.log("found a partial refund", donation);

        // Check for unique donor once per donation
        if (!totalDonors.hasOwnProperty(donorKey)) {
          totalDonors[donorKey] = true; // Mark this donor as counted.
          totalDonorValue += 1; // Increment your unique donors count.
        }
        // Handle multi-fund donations
        if (
          donation.funds !== null &&
          donation.funds !== undefined &&
          donation.funds.length > 0
        ) {
          donation.funds.forEach((fund) => {
            console.log("in for each loop for partial refund");
            console.log("fund", fund);
            const fundAmount = parseFloat(fund.amount);
            const fundQuantity = parseInt(fund.quantity);
            const totalFundAmount = fundAmount * fundQuantity;

            const refunded = donation.refunded.filter((item) => {
              return (
                item.name === fund.fundName &&
                item.refundAmount == fund.amount &&
                item.fundraiser == fund.fundraiser &&
                item.frequency == fund.frequency
              );
            });

            let totalRefunded = refunded.reduce((sum, item) => {
              sum += parseFloat(item.refundAmount) || 0;
              return sum;
            }, 0);

            console.log("totalRefunded", totalRefunded);

            let totalFundQuantityRefunded = refunded.length;
            totalDonations += fundQuantity - totalFundQuantityRefunded;
            totalDonationSum += totalFundAmount - totalRefunded;

            let fundKey = fund.fundName.trim(); // Use fundName as key

            let fundKeyIndex = keys.indexOf(fundKey);

            if (fundKeyIndex !== -1) {
              // Update existing fund data
              rows[fundKeyIndex].grossAmountVar = (
                parseFloat(rows[fundKeyIndex].grossAmountVar) +
                totalFundAmount -
                totalRefunded
              ).toFixed(2);
              rows[fundKeyIndex].totalDonationNumberCounter +=
                fundQuantity - totalFundQuantityRefunded;
            } else {
              console.log("in else");
              keys.push(fundKey);
              rows.push({
                name: donation.name,
                email: donation.email,
                fund: fund.fundName,
                grossAmount: (totalFundAmount - totalRefunded).toFixed(2), // Convert to string with two decimal places here
                monthlyDonor: monthlyDonation,
                grossAmountVar: (totalFundAmount - totalRefunded).toFixed(2), // Convert to string with two decimal places here
                totalDonors: Object.keys(totalDonors).length,
                totalDonationNumberCounter:
                  fundQuantity - totalFundQuantityRefunded,
                totalDonorValue: totalDonorValue,
                nonprofitname: donation.merchantName,
              });
            }
          });
        } else {
          console.log("in single fund else");

          // Handle single-fund donations...
          // Update the totalDonationSum safely by adding the number directly
          // totalDonationSum += parseFloat(grossAmountVariable);
          // Rest of your single-fund donation handling code...
          let totalRefunded = donation.refunded.reduce((sum, item) => {
            if (item.name !== "Fee") {
              sum += parseFloat(item.refundAmount) || 0;
            }
            return sum;
          }, 0);

          let keyIndex = keys.indexOf(key);
          totalDonationSum += parseFloat(grossAmountVariable) - totalRefunded;
          totalDonations += 1;
          if (!totalDonors.hasOwnProperty(donorKey)) {
            totalDonors[donorKey] = "exists";
          }

          if (keyIndex !== -1) {
            console.log("312");
            totalDonorValue += 1;
            //   rows[keyIndex].totalDonationNumberCounter += 1;
            rows[keyIndex].grossAmountVar = (
              parseFloat(rows[keyIndex].grossAmountVar) +
              parseFloat(grossAmountVariable) -
              totalRefunded
            ).toFixed(2);
          } else {
            console.log("320");

            keys.push(key);
            rows.push({
              name: donation.name,
              email: donation.email,
              fund: key,
              grossAmount: grossAmountVariable - totalRefunded,
              monthlyDonor: monthlyDonation === "monthly",
              grossAmountVar: grossAmountVariable - totalRefunded,
              totalDonors: Object.keys(totalDonors).length,
              totalDonationNumberCounter: 1,
              totalDonorValue: totalDonorValue,
              nonprofitname: donation.merchantName,
            });
          }
        }
      }
      if (
        donation.state === "FULL_REFUND" ||
        donation.state === "Full Refund"
      ) {
        console.log("found a full refund", donation);
        // Check for unique donor once per donation
        if (!totalDonors.hasOwnProperty(donorKey)) {
          totalDonors[donorKey] = true; // Mark this donor as counted.
          totalDonorValue += 1; // Increment your unique donors count.
        }
        // Handle multi-fund donations
        if (
          donation.funds !== null &&
          donation.funds !== undefined &&
          donation.funds.length > 0
        ) {
          donation.funds.forEach((fund) => {
            console.log("in for each loop for full refund");
            /*    const fundAmount = parseFloat(fund.amount);
                   const fundQuantity = parseInt(fund.quantity);
                   const totalFundAmount = fundAmount * fundQuantity;
   
                   totalDonations += fundQuantity;
                   totalDonationSum += totalFundAmount;
   
                 */
            let fundKey = fund.fundName.trim(); // Use fundName as key

            let fundKeyIndex = keys.indexOf(fundKey);

            if (fundKeyIndex !== -1) {
              // Update existing fund data
              /*   rows[fundKeyIndex].grossAmountVar = (
            parseFloat(rows[fundKeyIndex].grossAmountVar) +
                 totalFundAmount
               ).toFixed(2);
               rows[fundKeyIndex].totalDonationNumberCounter += fundQuantity; */
            } else {
              console.log("in else");
              keys.push(fundKey);
              rows.push({
                name: donation.name,
                email: donation.email,
                fund: fund.fundName,
                grossAmount: 0, // Convert to string with two decimal places here
                monthlyDonor: monthlyDonation,
                grossAmountVar: 0, // Convert to string with two decimal places here
                totalDonors: Object.keys(totalDonors).length,
                totalDonationNumberCounter: 0,
                totalDonorValue: totalDonorValue,
                nonprofitname: donation.merchantName,
              });
            }
          });
        } else {
          console.log("in single fund else");

          // Handle single-fund donations...
          // Update the totalDonationSum safely by adding the number directly
          // totalDonationSum += parseFloat(grossAmountVariable);
          // Rest of your single-fund donation handling code...
          let keyIndex = keys.indexOf(key);
          //   totalDonationSum += parseFloat(grossAmountVariable);
          //  totalDonations += 1;
          if (!totalDonors.hasOwnProperty(donorKey)) {
            totalDonors[donorKey] = "exists";
          }

          if (keyIndex !== -1) {
            console.log("312");
            totalDonorValue += 1;
            //   rows[keyIndex].totalDonationNumberCounter += 1;
            /*       rows[keyIndex].grossAmountVar = (
                    parseFloat(rows[keyIndex].grossAmountVar) +
                    parseFloat(grossAmountVariable)
                  ).toFixed(2); */
          } else {
            console.log("320");

            keys.push(key);
            rows.push({
              name: donation.name,
              email: donation.email,
              fund: key,
              grossAmount: 0,
              monthlyDonor: monthlyDonation === "monthly",
              grossAmountVar: 0,
              totalDonors: Object.keys(totalDonors).length,
              totalDonationNumberCounter: 0,
              totalDonorValue: totalDonorValue,
              nonprofitname: donation.merchantName,
            });
          }
        }
      }
    });
    // add in empty rows for funds that have no donations
    activeFunds.forEach((funds, formName) => {
      funds.forEach((fund) => {
        const key = fund;
        if (!keys.includes(key)) {
          rows.push({
            name: "",
            email: "",
            fund: fund,
            grossAmount: 0,
            monthlyDonor: "",
            grossAmountVar: 0,
            totalDonors: Object.keys(totalDonors).length,
            totalDonationSum: 0,
            totalDonationNumberCounter: 0,
            totalDonorValue: totalDonorValue,
            nonprofitname: formName.split(" | ")[0],
          });
          keys.push(key);
        }
      });
    });

    if (rows.length < 20) {
      end = rows.length;
    }

    // sort rows alphabetically by fund name
    rows.sort((a, b) => {
      return a.fund.localeCompare(b.fund);
    });

    // calculate scorecard for Total Donations
    let totalDonationCount = res.filter(
      (donation) =>
        (donation.state === "SUCCEEDED" ||
          donation.state === "PARTIAL_REFUND") &&
        donation.formName !== "Internal Donation" &&
        (donation.tickets === undefined ||
          donation.tickets === null ||
          donation.tickets.length === 0)
    ).length;

    console.log("hihi", grossAmountVariable);
    this.setState({
      donorsList: rows,
      start: 0,
      end: end,
      total: rows.length,
      totalDonors: Object.keys(totalDonors).length,
      grossAmountVar: grossAmountVariable,
      totalDonationSum: totalDonationSum,
      totalDonationNumberCounter: 1,
      totalDonations: totalDonationCount,
    });
  };

  goNext = (e) => {
    e.preventDefault();
    const { start, end, total } = this.state;
    if (end !== total) {
      this.setState({
        start: start + 20,
        end: Math.min(end + 20, total),
      });
    }
  };

  goPrev = (e) => {
    e.preventDefault();
    const { start, end, total } = this.state;
    if (start !== 0) {
      this.setState({
        start: Math.max(start - 20, 0),
        end: start,
      });
    }
  };

  headers1 = [
    { label: "Fund", key: "fund" },
    { label: "Nonprofit Name", key: "nonprofitname" },
    { label: "Total Raised", key: "grossAmountVar" },
    { label: "Total Donations", key: "totalDonationNumberCounter" },
  ];

  render() {
    const {
      donorsList,
      start,
      end,
      total,
      grossAmountList,
      totalDonors,
      showFilter,
      totalDonationSum,
      totalDonations,
    } = this.state;
    const headers1 = this.headers1;
    let donationList1 = donorsList;

    let next = "btn bg-white border-gray-200 hover:border-gray-300 text-light";
    let prev = "btn bg-white border-gray-200 hover:border-gray-300 text-light";
    let totalDonationSumTwoDecimals = totalDonationSum.toFixed(2);
    if (end === total) {
      next = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed";
    }
    if (start === 0) {
      prev = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed";
    }

    this.props.getMetrics(
      total,
      totalDonationSumTwoDecimals,
      totalDonations,
      totalDonors
    );

    return (
      <div className="col-span-full xl:col-span-12">
        <div className="flex flex-row justify-end py-3 ">
          <div className="flex ">
            <button
              type="button "
              className="focus:outline-none inline-block flex focus:ring-2 focus:ring-offset-2 "
            >
              <CSVLink
                filename="CharityStack Funds Export.csv"
                data={donationList1}
                headers={headers1}
              >
                <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 17"
                    stroke="#a1afc2"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                  &nbsp;
                  <div className="text-gray-500 hover:text-gray-900 ">
                    {" "}
                    Export &nbsp;{" "}
                  </div>
                </div>
              </CSVLink>
            </button>
          </div>
        </div>
        {showFilter && (
          <div className="flex flex-row p-3">
            <DonationDatePicker setTimerange={this.setDateTimeRange} />
          </div>
        )}

        <div className="rounded-lg border border-[#D1D9E0] bg-white p-3">
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="w-full table-auto ">
              {/* Table header */}
              <thead className="rounded-sm bg-gray-50 text-xs uppercase text-gray-400">
                <tr>
                  <th className="p-2">
                    <div className="text-left font-semibold">Funds</div>
                  </th>
                  <th className="p-2">
                    <div className="text-center font-semibold">Donations</div>
                  </th>
                  <th className="p-2">
                    <div className="text-right font-semibold">Amount</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="divide-y divide-gray-100 text-sm font-medium">
                {donorsList.slice(start, end).map((donor, index) => {
                  donor.grossAmount = donor.grossAmount.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2 }
                  );

                  return (
                    <tr>
                      <td className="p-2">
                        <div className="items-center">
                          <div className="text-s text-left">{donor.fund}</div>
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="items-center text-center">
                          <button className="text-center">
                            {donor.totalDonationNumberCounter
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </button>
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="items-center text-right">
                          <div className="text-right">
                            $
                            {(
                              Math.round(
                                donor.grossAmountVar * Math.pow(10, 2)
                              ) / Math.pow(10, 2)
                            )
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
                {}
              </tbody>
            </table>
            <div className="mt-8">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <nav
                  className="mb-4 sm:order-1"
                  role="navigation"
                  aria-label="Navigation"
                >
                  <ul className="flex justify-center">
                    <li className="ml-3 first:ml-0">
                      <a className={prev} href="#0" onClick={this.goPrev}>
                        &lt;- Previous
                      </a>
                    </li>
                    <li className="ml-3 first:ml-0">
                      <a className={next} href="#0" onClick={this.goNext}>
                        Next -&gt;
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="text-center text-sm text-gray-500 sm:text-left">
                  Showing{" "}
                  <span className="font-medium text-gray-600">{start}</span> to{" "}
                  <span className="font-medium text-gray-600">{end}</span> of{" "}
                  <span className="font-medium text-gray-600">{total}</span>{" "}
                  results
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FundsTable;
