import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { LinkIcon } from "@heroicons/react/20/solid";
import Toggle from "../Toggle";
import Dropdown from "../dropdowns/Dropdown";
import SingleSelectDropdown from "../dropdowns/SingleSelectDropdown";
import { Auth } from "aws-amplify";
import ReactTooltip from "react-tooltip";
import SuccessAlert from "../alerts/SuccessAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import Checkbox from "../Checkbox";
import UpdatePaymentCreateDonation from "../UpdatePaymentCreateDonation";
import uuid from "react-uuid";
import Confirmation from "../payment-methods/Confirmation";
import tailwindConfig from "../../css/tailwind.config.js";
// import '../../css/donation-form-styles.css'
import RecurringDatePicker from "../datepickers/RecurringDatePicker";
import dayjs from "dayjs";
import { AddressAutofill } from "@mapbox/search-js-react";
import CountryList from "react-select-country-list";

const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`.toUpperCase();
  } else {
    return hexColor.toUpperCase();
  }
};

const lightColor = tailwindConfig.theme.extend.colors.primary;
const darkColor = tailwindConfig.theme.extend.colors.secondary;
const handleMapboxError = (error) => {
  console.error("Mapbox Autofill Error:", error);
  //setMapBoxAccess(false); // Disables Mapbox functionality gracefully
};

const countries = [
  "United States",
  "Canada",
  "United Kingdom",
  "Australia",
  "France",
  "Lebanon",
  "Kuwait",
];

const countryOptions = CountryList().getData();

const recurringFrequencies = ["Daily", "Weekly", "Monthly", "Annually"];
const recurringDatePickerTransform = {
  Daily: "day",
  Weekly: "week",
  Monthly: "month",
  Annually: "year",
  "Please select an option": "month",
};

const countrieDefinition = {
  "United States": {
    cityText: "City",
    regionText: "State",
    postalCodeText: "Zip",
  },
  Canada: {
    cityText: "City",
    regionText: "Province",
    postalCodeText: "Postal Code",
  },
  "United Kingdom": {
    cityText: "Town/City",
    regionText: "Region",
    postalCodeText: "Postal Code",
  },
  Australia: {
    cityText: "Suburb/City",
    regionText: "State",
    postalCodeText: "Postal Code",
  },
  France: {
    cityText: "City",
    regionText: "Region",
    postalCodeText: "Postal Code",
  },
  Lebanon: {
    cityText: "City/Locality",
    regionText: "District",
    postalCodeText: "Postal Code",
  },
  Kuwait: {
    cityText: "City",
    regionText: "Governorate",
    postalCodeText: "Postal Code",
  },
};

export default function CreateFormSlideover(props) {
  const [showUpdatePaymentCreateDonation, setShowUpdatePaymentCreateDonation] =
    useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const [jwtToken, setJWTToken] = useState("");
  const [donationAmount, setDonationAmount] = useState("0");
  const [donorFirstName, setDonorFirstName] = useState("");
  const [donorLastName, setDonorLastName] = useState("");
  const [donorEmail, setDonorEmail] = useState("");
  const [donorPhoneNumber, setDonorPhoneNumber] = useState("");

  const [donorCountry, setDonorCountry] = useState("");
  const [donorAddress1, setDonorAddress1] = useState("");
  const [donorAddress2, setDonorAddress2] = useState("");
  const [donorCity, setDonorCity] = useState("");
  const [donorState, setDonorState] = useState("");
  const [donorZip, setDonorZip] = useState("");
  const [transactionID, setTransactionID] = useState("");

  const [defaultDonationType, setDefaultDonationType] = useState("Monthly");
  const [oneTimeAmount1, setOneTimeAmount1] = useState("");
  const [oneTimeAmount2, setOneTimeAmount2] = useState("");
  const [oneTimeAmount3, setOneTimeAmount3] = useState("");
  const [oneTimeAmount4, setOneTimeAmount4] = useState("");
  const [monthlyAmount1, setMonthlyAmount1] = useState("");
  const [monthlyAmount2, setMonthlyAmount2] = useState("");
  const [monthlyAmount3, setMonthlyAmount3] = useState("");
  const [monthlyAmount4, setMonthlyAmount4] = useState("");
  const [showOneTimeDefault, setShowOneTimeDefault] = useState(false);
  const [showMonthlyDefault, setShowMonthlyDefault] = useState(false);
  const [oneTimeDefault, setOneTimeDefault] = useState("");
  const [monthlyDefault, setMonthlyDefault] = useState("");
  const [separateAmounts, setSeparateAmounts] = useState(false);
  const [requirePhoneNumber, setRequirePhoneNumber] = useState(false);
  const [requireBillingAddress, setRequireBillingAddress] = useState(false);
  const [requireCountryDropdown, setRequireCountryDropdown] = useState(false);
  const [mapBoxAccess, setMapBoxAccess] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [labelPostalCodeText, setLabelPostalCodeText] = useState(
    countrieDefinition["United States"].postalCodeText
  );
  const [labelRegionText, setLabelRegionText] = useState(
    countrieDefinition["United States"].regionText
  );
  const [labelCityText, setLabelCityText] = useState(
    countrieDefinition["United States"].cityText
  );

  const [countryOptions, setCountryOptions] = useState([]);

  const [funds, setFunds] = useState([]);
  const [color, setColor] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [copiedState, setCopiedState] = useState("Copied!");
  const [monthlyChecked, setMonthlyChecked] = useState(true);
  const [frequencyChecked, setFrequencyChecked] = useState("");
  const [frequencyCheckedValue, setFrequencyCheckedValue] = useState("");
  const [feeCovered, setFeeCovered] = useState(false);
  const [fees, setFees] = useState(0);
  const [oneTimeChecked, setOneTimeChecked] = useState(true);
  const [perTimeIndicator, setPerTimeIndicator] = useState("");

  const [selectedRecurringFrequency, setSelectedRecurringFrequency] = useState(
    "Please select an option"
  );
  const [showScheduleDonation, setShowScheduleDonation] = useState(false);
  const [showDatePickerInput, setShowDatePickerInput] = useState(false);
  const [startValue, setStartValue] = useState(dayjs(new Date()));
  const [endValue, setEndValue] = useState(undefined);

  useEffect(() => {
    if (props.merchant) {
      let value = donationAmount;
      let r = /^\$?[0-9]+\.?[0-9]?[0-9]?$/;
      if (
        r.test(value) === true &&
        value[0] !== "0" &&
        parseFloat(value) <= 19000 &&
        parseFloat(value) >= 1
      ) {
        if (feeCovered === true) {
          setFees(
            (parseFloat(value) + parseFloat(props.merchant["fixedFee"])) /
              (1 - parseFloat(props.merchant["percentFee"])) -
              parseFloat(value)
          );
        } else {
          // use normal calculation with percent and fixed fee
          setFees(
            parseFloat(value) * parseFloat(props.merchant["percentFee"]) +
              parseFloat(props.merchant["fixedFee"])
          );
        }
      }
      //if 0 is entered, then the next time a user types, that zero should be automatically deleted
      if (r.test(value) === true && value[0] === "0") {
        value = value.substring(1);
        if (feeCovered === true) {
          setFees(
            (parseFloat(value) + parseFloat(props.merchant["fixedFee"])) /
              (1 - parseFloat(props.merchant["percentFee"])) -
              parseFloat(value)
          );
        } else {
          // use normal calculation with percent and fixed fee
          setFees(
            parseFloat(value) * parseFloat(props.merchant["percentFee"]) +
              parseFloat(props.merchant["fixedFee"])
          );
        }
      }
      if (r.test(value) === false) {
        setFees(0);
      }
    }
  }, [feeCovered]);

  useEffect(() => {
    const countries = CountryList().getData();
    setCountryOptions(countries);
  }, []);

  function handleCoverFeesChange(e) {
    if (e === true) {
      setFeeCovered(true);
    }
    if (e === false) {
      setFeeCovered(false);
    }
  }

  function handleEmailChange(e) {
    setDonorEmail(e);
    if (e.indexOf("@") === -1 || e.indexOf(".") === -1) {
      return "Incorrect Email Address";
    }
  }

  function handleCustomAmountChange(e) {
    let value = e.target.value;
    let r = /^\$?[0-9]+\.?[0-9]?[0-9]?$/;
    value = value.replace(/(\.\d{2})\d{1,}/g, "$1");
    if (
      r.test(value) === true &&
      value[0] !== "0" &&
      parseFloat(value) <= 19000 &&
      parseFloat(value) >= 1
    ) {
      setDonationAmount(value);
      if (feeCovered === true) {
        setFees(
          (parseFloat(value) + parseFloat(props.merchant["fixedFee"])) /
            (1 - parseFloat(props.merchant["percentFee"])) -
            parseFloat(value)
        );
      } else {
        // use normal calculation with percent and fixed fee
        setFees(
          parseFloat(value) * parseFloat(props.merchant["percentFee"]) +
            parseFloat(props.merchant["fixedFee"])
        );
      }
    }
    //if 0 is entered, then the next time a user types, that zero should be automatically deleted
    if (r.test(value) === true && value[0] === "0") {
      value = value.substring(1);
      setDonationAmount(value);
      if (feeCovered === true) {
        setFees(
          (parseFloat(value) + parseFloat(props.merchant["fixedFee"])) /
            (1 - parseFloat(props.merchant["percentFee"])) -
            parseFloat(value)
        );
      } else {
        // use normal calculation with percent and fixed fee
        setFees(
          parseFloat(value) * parseFloat(props.merchant["percentFee"]) +
            parseFloat(props.merchant["fixedFee"])
        );
      }
    }
    if (r.test(value) === false) {
      setDonationAmount("");
      setFees(0);
    }
  }

  function onChangeLocation(event) {
    setSelectedCountry(event);
    setLabelPostalCodeText(countrieDefinition[event].postalCodeText);
    setLabelCityText(countrieDefinition[event].cityText);
    setLabelRegionText(countrieDefinition[event].regionText);
  }

  const handleSelectedCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setSelectedCountry(selectedCountry);
    setLabelPostalCodeText(countrieDefinition[selectedCountry].postalCodeText);
    setLabelCityText(countrieDefinition[selectedCountry].cityText);
    setLabelRegionText(countrieDefinition[selectedCountry].regionText);
  };

  function onChangeLocation(event) {
    setSelectedCountry(event);
    setLabelPostalCodeText(countrieDefinition[event].postalCodeText);
    setLabelCityText(countrieDefinition[event].cityText);
    setLabelRegionText(countrieDefinition[event].regionText);
  }

  function onChangeRecurringFrequency(event) {
    console.log("in 293!");
    setSelectedRecurringFrequency(event);

    if (event === "Please select an option") {
      setPerTimeIndicator("");
      setEndValue(undefined); // Ensure endValue is reset if no option is selected
    } else {
      // Set per time indicator based on the selected option
      const perTimeIndicators = {
        Daily: "/day",
        Weekly: "/wk",
        Monthly: "/mo",
        Annually: "/yr",
      };
      setPerTimeIndicator(perTimeIndicators[event]);
    }
  }

  function onChangeFrequency(event) {
    setFrequencyChecked(event.target.value);
    if (event.target.value === "Recurring") {
      if (selectedRecurringFrequency === "Please select an option") {
        setPerTimeIndicator("");
      }
      if (selectedRecurringFrequency === "Daily") {
        setPerTimeIndicator("/day");
      }
      if (selectedRecurringFrequency === "Weekly") {
        setPerTimeIndicator("/wk");
      }
      if (selectedRecurringFrequency === "Monthly") {
        setPerTimeIndicator("/mo");
      }
      if (selectedRecurringFrequency === "Annually") {
        setPerTimeIndicator("/yr");
      }
      setEndValue(undefined);
    }
    if (event.target.value === "One-Time") {
      setPerTimeIndicator("");
    }
  }

  // internal functions
  const checkFormErrors = () => {
    if (
      donationAmount === "" ||
      donationAmount === "0" ||
      donationAmount === 0
    ) {
      return "Donation Amount is required";
    }
    if (donorFirstName === "" || donorLastName === "") {
      return "Donor Name is required";
    }
    if (donorEmail === "") {
      return "Donor Email required";
    }
    if (frequencyChecked === "") {
      return "Donation frequency is required";
    }
    if (
      frequencyChecked === "Recurring" &&
      selectedRecurringFrequency === "Please select an option"
    ) {
      return "Recurring frequency is required";
    }
    if (showDatePickerInput === true && endValue === undefined) {
      return "End Date is required for Scheduled Donations";
    }
    if (funds.length == 0) {
      return "A fund designation is required";
    }
    return "";
  };

  const sendToPaymentScreen = async () => {
    let returnedError = checkFormErrors();
    if (returnedError != "") {
      setErrorMessage(returnedError);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    }
    if (returnedError === "") {
      setTransactionID(uuid());
      console.log("selectedRecurringFrequency", selectedRecurringFrequency);
      if (frequencyChecked === "One-Time") {
        setFrequencyCheckedValue("ONE_TIME");
      }
      if (frequencyChecked === "Recurring") {
        if (selectedRecurringFrequency === "Daily") {
          setFrequencyCheckedValue("DAILY");
        } else if (selectedRecurringFrequency === "Weekly") {
          setFrequencyCheckedValue("WEEKLY");
        } else if (selectedRecurringFrequency === "Monthly") {
          setFrequencyCheckedValue("MONTHLY");
        } else if (selectedRecurringFrequency === "Annually") {
          setFrequencyCheckedValue("ANNUALLY");
        } else {
          alert("Please select a recurring frequency");
        }
      }
      if (selectedCountry === "Choose a Country" || selectedCountry === "") {
        setSelectedCountry("United States");
      }
      setShowUpdatePaymentCreateDonation(true);
    }
  };
  const createDonationCard = async (tokenID, stripePaymentMethod) => {
    let returnedError = checkFormErrors();

    if (returnedError != "" && transactionID === "") {
      setErrorMessage(returnedError);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    }
    if (returnedError === "") {
      //     setShowUpdatePaymentCreateDonation(true)
      const requestOptions = {
        method: "POST",
        headers: {
          accept: "*/*",
          "content-type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify({
          paymentMethod: "CARD",
          stripePaymentMethod: stripePaymentMethod,
          name: donorFirstName + " " + donorLastName,
          fund: funds[0],
          address: donorAddress1,
          address2: donorAddress2,
          city: donorCity,
          amount: donationAmount,
          region: donorState,
          uuid: transactionID,
          tip: 0,
          zip: donorZip,
          donationFrequency: frequencyCheckedValue,
          dateEndCharge: endValue,
          coverFees: feeCovered,
          email: donorEmail,
          formName: "Internal Donation",
          merchantName: props.merchant.merchantName,
          country: selectedCountry,
          token: tokenID,
          phone: donorPhoneNumber,
        }),
      };

      // submitting transaction to donors-data lambda
      try {
        let response = await fetch(
          `${process.env.REACT_APP_DASHBOARD_TRANSACTIONS_URL}/checkout/create-donation`,
          requestOptions
        );
        let responseBody = await response.json();
        if (!response.ok) {
          setTransactionID(uuid());
          setShowErrorAlert(true);
          setErrorMessage(responseBody);
        } else {
          props.grabSelectableFunds(jwtToken);
          setShowErrorAlert(false);
          setErrorMessage("");
          props.setOpen(false);
          setShowConfirmation(true);
          clearStates();
        }
      } catch (error) {
        setTransactionID(uuid());
        setShowErrorAlert(true);
        setErrorMessage(error.message);
      }
    }
  };

  const createDonationBank = async (
    accountNumber,
    routingNumber,
    accountType
  ) => {
    let returnedError = checkFormErrors();
    if (returnedError != "" && transactionID === "") {
      setErrorMessage(returnedError);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    }
    if (returnedError === "") {
      // setShowUpdatePaymentCreateDonation(true)
      const requestOptions = {
        method: "POST",
        headers: {
          accept: "*/*",
          "content-type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify({
          accountNumber: accountNumber,
          routingNumber: routingNumber,
          accountType: accountType,
          paymentMethod: "PAY_BY_BANK",
          name: donorFirstName + " " + donorLastName,
          fund: funds[0],
          address: donorAddress1,
          address2: donorAddress2,
          city: donorCity,
          amount: donationAmount,
          region: donorState,
          uuid: transactionID,
          tip: 0,
          zip: donorZip,
          donationFrequency: frequencyCheckedValue,
          dateEndCharge: endValue,
          coverFees: feeCovered,
          email: donorEmail,
          formName: "Internal Donation",
          merchantName: props.merchant.merchantName,
          country: selectedCountry,
          phone: donorPhoneNumber,
        }),
      };

      // submitting transaction to donors-data lambda
      try {
        let response = await fetch(
          `${process.env.REACT_APP_DASHBOARD_TRANSACTIONS_URL}/checkout/create-donation`,
          requestOptions
        );
        let responseBody = await response.json();
        if (!response.ok) {
          setShowErrorAlert(true);
          setErrorMessage(responseBody);
        } else {
          props.grabSelectableFunds(jwtToken);
          setShowErrorAlert(false);
          setErrorMessage("");
          props.setOpen(false);
          setShowConfirmation(true);
          clearStates();
        }
      } catch (error) {
        setShowErrorAlert(true);
        setErrorMessage(error.message);
      }
    }
  };

  // Clear states when modal is closed
  const clearStates = () => {
    // close slideover

    // return to default states

    setTransactionID("");
    //    setShowConfirmation(false)
    setShowUpdatePaymentCreateDonation(false);
    setDonationAmount("0");
    setDonorFirstName("");
    setDonorLastName("");
    setDonorEmail("");
    setDonorPhoneNumber("");
    setDonorAddress1("");
    setDonorAddress2("");
    setDonorCity("");
    setDonorCountry("");
    setDonorState("");
    setDonorZip("");
    setDefaultDonationType("Monthly");
    setSeparateAmounts(false);
    setShowOneTimeDefault(false);
    setShowMonthlyDefault(false);
    setOneTimeDefault("");
    setMonthlyDefault("");
    setRequirePhoneNumber(false);
    setRequireBillingAddress(false);
    setRequireCountryDropdown(false);
    setSelectedCountry("Choose a Country");
    setFunds([]);
    setColor("");
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
    setIsDisabled(false);
    setMonthlyChecked(true);
    setFrequencyChecked("One-Time");
    setFeeCovered(false);
    setFees(0);
    setOneTimeChecked(true);
    setPerTimeIndicator("");
    props.setOpen(false);
  };

  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      const formattedMerchants = data["idToken"]["payload"][
        "cognito:groups"
      ].map((merchant) => merchant.replace(/-/g, " "));

      setJWTToken(data["idToken"]["jwtToken"]);
      // setMerchants(formattedMerchants)
    };
    myFunction();
  }, []);

  useEffect(() => {
    if (props.merchant != null) {
      console.log("merchantName", props.merchant["merchantName"]);
    }
  }, [props.merchant]);

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
  }, [props.open]);

  let submitButtonClass =
    "justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2";
  if (isDisabled) {
    submitButtonClass =
      "justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm cursor-not-allowed focus:outline-none";
  }

  let scheduleDonationButtonParentStyle = { display: "none" };
  let scheduleDonationButtonStyle = { display: "none" };
  let dateRangePickerStyle = { display: "none" };

  if (frequencyChecked === "Recurring" && showDatePickerInput) {
    scheduleDonationButtonStyle = { display: "none" };
    scheduleDonationButtonParentStyle = { display: "none" };
    dateRangePickerStyle = { paddingBottom: "8px" };
  }
  if (frequencyChecked === "Recurring" && !showDatePickerInput) {
    console.log("im in");
    scheduleDonationButtonParentStyle = { paddingBottom: "8px" };
    scheduleDonationButtonStyle = {
      height: "42px",
      top: "0px",
      backgroundColor: "white",
      inset: "white",
      color: lightColor,
      "border-width": "1px",
      "border-color": lightColor,
      "border-style": "solid",
      marginTop: "0px",
    };
    dateRangePickerStyle = { display: "none" };
  }

  return (
    <>
      {showConfirmation === true && (
        <Confirmation
          slideoverOpen={props.openConfirmation}
          openConfirmation={showConfirmation}
          setOpenConfirmation={setShowConfirmation}
        />
      )}
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
          <UpdatePaymentCreateDonation
            merchant={props.merchant}
            slideoverOpen={props.open}
            open={showUpdatePaymentCreateDonation}
            setOpen={setShowUpdatePaymentCreateDonation}
            createDonationBank={createDonationBank}
            createDonationCard={createDonationCard}
            donationAmount={donationAmount}
            funds={funds}
            frequency={frequencyChecked}
            errorMessageFromParent={errorMessage}
            perTimeIndicator={perTimeIndicator}
            fees={fees}
            feeCovered={feeCovered}
            achMaxFee={
              props.merchant && props.merchant["achMaxFee"]
                ? props.merchant["achMaxFee"]
                : 25
            }
            //     address={donorAddress1} address2={donorAddress2} city={donorCity} region={donorState} country={selectedCountry} phone={donorPhoneNumber} email={donorEmail}
            //      coverFees={feeCovered}
            //        //check with Abe and MC if this is needed.
            //      formName="Dashboard Manual Donation"
          />

          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                Create Internal Donation
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Get started by filling in the information below
                                to create your new internal donation.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={clearStates}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-start justify-center space-x-3 pt-2">
                          <div> </div>
                          <div className="flex items-start justify-center space-x-3 py-2">
                            <span className="font-6xl block text-6xl font-semibold slashed-zero lining-nums text-gray-900 ">
                              ${donationAmount}
                            </span>

                            <span className="font-xl block pt-8 text-xl text-gray-900">
                              {perTimeIndicator}{" "}
                            </span>
                          </div>
                        </div>
                        {/* Divider container */}
                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:py-0">
                          {/* Form name */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="donation-amount-block"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Donation Amount*
                                <svg
                                  data-tip
                                  data-for="donationAmountTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="donationAmountTooltip"
                                  effect="solid"
                                  place="right"
                                >
                                  <p className="text-sm text-white">
                                    This is the amount of the donation.{" "}
                                  </p>
                                </ReactTooltip>
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                value={donationAmount}
                                onChange={(e) => handleCustomAmountChange(e)}
                                type="text"
                                placeholder="Enter Amount"
                                name="donation-amount"
                                id="donation-amount"
                                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="form-description"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Fees
                              </label>
                            </div>
                            <div className="flex space-x-10 text-gray-400 sm:col-span-2">
                              <Checkbox
                                label={
                                  "Yes, the donor will cover the transaction fee."
                                }
                                onChange={handleCoverFeesChange}
                                checked={feeCovered}
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="form-description"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Frequency*
                                <svg
                                  data-tip
                                  data-for="donationTypeTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="donationTypeTooltip"
                                  effect="solid"
                                  place="right"
                                >
                                  <p className="text-sm text-white">
                                    Choose the donation frequency for the
                                    donation.
                                  </p>
                                </ReactTooltip>
                              </label>
                            </div>
                            <div className="block pt-2 text-sm sm:col-span-2">
                              <div onChange={onChangeFrequency}>
                                <input
                                  type="radio"
                                  value="One-Time"
                                  name="frequencyChecked"
                                  checked={frequencyChecked === "One-Time"}
                                  style={{
                                    backgroundColor:
                                      frequencyChecked === "One-Time"
                                        ? "green"
                                        : "white",
                                  }}
                                />{" "}
                                One-Time &nbsp;&nbsp;
                                <input
                                  type="radio"
                                  value="Recurring"
                                  name="frequencyChecked"
                                  checked={frequencyChecked === "Recurring"}
                                  style={{
                                    backgroundColor:
                                      frequencyChecked === "Recurring"
                                        ? "green"
                                        : "white",
                                  }}
                                />{" "}
                                Recurring
                              </div>
                              {frequencyChecked === "Recurring" && (
                                <div className="flex w-full pt-4">
                                  <Dropdown
                                    selectedValue={selectedRecurringFrequency}
                                    setSelectedValue={
                                      onChangeRecurringFrequency
                                    }
                                    values={recurringFrequencies}
                                    width={"w-full"}
                                  />
                                </div>
                              )}
                            </div>
                            {frequencyChecked === "Recurring" && (
                              <>
                                <div>
                                  <h3 className="inline-block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2 ">
                                    Schedule Donation
                                  </h3>
                                  <svg
                                    data-tip
                                    data-for="scheduleDonationTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="scheduleDonationTooltip"
                                    effect="solid"
                                    place="right"
                                  >
                                    <p className="text-sm text-white">
                                      You can choose to end a recurring donation
                                    </p>
                                    <p className="text-sm text-white">
                                      at a specific date with this option.
                                    </p>
                                  </ReactTooltip>
                                </div>
                                <div className="sm:col-span-2">
                                  {!showDatePickerInput && (
                                    <div style={{}}>
                                      <button
                                        type="button"
                                        className="bg-primary hover:bg-secondary focus:outline-none focus:ring-secondary w-full justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:ring-2 focus:ring-offset-2"
                                        name="scheduleDonation"
                                        id="scheduleDonation"
                                        onClick={() => {
                                          setShowDatePickerInput(true);
                                          setEndValue(undefined);
                                        }}
                                      >
                                        {" "}
                                        Schedule Donation{" "}
                                      </button>
                                    </div>
                                  )}
                                  {showDatePickerInput && (
                                    <div style={{}}>
                                      <RecurringDatePicker
                                        startValue={startValue}
                                        setStartValue={setStartValue}
                                        endValue={endValue}
                                        setEndValue={setEndValue}
                                        lightColor={lightColor}
                                        darkColor={darkColor}
                                        timePicker={
                                          recurringDatePickerTransform[
                                            selectedRecurringFrequency
                                          ]
                                        }
                                        setShowComponent={
                                          setShowDatePickerInput
                                        }
                                        showComponent={showDatePickerInput}
                                      />
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>

                          {/* Funds */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-4 sm:pb-4 ">
                            <div>
                              <h3 className="inline-block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2 ">
                                Fund Designation*
                              </h3>
                              <svg
                                data-tip
                                data-for="fundOptionsTooltip"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="text-primary ml-1 inline-block h-5 w-5"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              <ReactTooltip
                                id="fundOptionsTooltip"
                                effect="solid"
                                place="right"
                              >
                                <p className="text-sm text-white">
                                  Please select 1 fund. You may select
                                </p>
                                <p className="text-sm text-white">
                                  from the dropdown, or type in a new fund to
                                  add.
                                </p>
                              </ReactTooltip>
                            </div>
                            <div className="sm:col-span-2">
                              <SingleSelectDropdown
                                options={props.selectableFunds}
                                setItems={setFunds}
                                items={funds}
                                placeholder="Select/Type a fund"
                              />
                            </div>
                          </div>

                          {/* Donor name */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-2 sm:pb-2">
                            <div className="py-0">
                              <h3 className="inline-block text-sm font-medium text-gray-900">
                                Donor Name*
                              </h3>
                              <svg
                                data-tip
                                data-for="donorNameTooltip"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="text-primary ml-1 inline-block h-5 w-5"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              <ReactTooltip
                                id="donorNameTooltip"
                                effect="solid"
                                place="right"
                              >
                                <p className="text-sm text-white">
                                  Please fill out the donor's name.
                                </p>
                              </ReactTooltip>
                              <br></br>

                              <h3 className="font-small inline-block pb-6 pt-2 text-sm text-gray-700">
                                First Name
                              </h3>
                              <br></br>

                              <h3 className="font-small inline-block pb-6 text-sm text-gray-700">
                                Last Name
                              </h3>
                            </div>

                            <div className="sm:col-span-2">
                              <div className="inline-block text-sm font-medium text-gray-900">
                                <br></br>
                              </div>
                              <input
                                value={donorFirstName}
                                onChange={(e) =>
                                  setDonorFirstName(e.target.value)
                                }
                                type="text"
                                name="donor-first-name"
                                id="donor-first-name"
                                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                              />
                              <div className="pb-2"></div>
                              <input
                                value={donorLastName}
                                onChange={(e) =>
                                  setDonorLastName(e.target.value)
                                }
                                type="text"
                                name="donor-last-name"
                                id="donor-last-name"
                                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* Contact information name */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-2 sm:pb-2">
                            <div className="py-0">
                              <h3 className="inline-block text-sm font-medium text-gray-900">
                                Contact Information*
                              </h3>
                              <svg
                                data-tip
                                data-for="donorContactInfoTooltip"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="text-primary ml-1 inline-block h-5 w-5"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              <ReactTooltip
                                id="donorContactInfoTooltip"
                                effect="solid"
                                place="right"
                              >
                                <p className="text-sm text-white">
                                  Please fill out the donor's contact
                                  information.
                                </p>
                                <p className="text-sm text-white">
                                  Only the donor's email is required below.
                                </p>
                              </ReactTooltip>
                              <br></br>

                              <h3 className="font-small inline-block pb-6 pt-2 text-sm text-gray-700">
                                Email*
                              </h3>
                              <br></br>

                              <h3 className="font-small inline-block pb-6 text-sm text-gray-700">
                                Phone Number
                              </h3>
                            </div>

                            <div className="sm:col-span-2">
                              <div className="inline-block text-sm font-medium text-gray-900">
                                <br></br>
                              </div>
                              <input
                                value={donorEmail}
                                onChange={(e) =>
                                  handleEmailChange(e.target.value)
                                }
                                type="text"
                                name="donor-email"
                                id="donor-email"
                                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                              />
                              <div className="pb-2"></div>
                              <input
                                value={donorPhoneNumber}
                                onChange={(e) =>
                                  setDonorPhoneNumber(
                                    e.target.value.replace(/[^0-9]/g, "")
                                  )
                                }
                                type="text"
                                name="donor-phone"
                                id="donor-phone"
                                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* Donor Address name */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-2 sm:pb-2">
                            <div className="py-0">
                              <h3 className="inline-block text-sm font-medium text-gray-900">
                                Donor Address
                              </h3>
                              <svg
                                data-tip
                                data-for="donorAddressTooltip"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="text-primary ml-1 inline-block h-5 w-5"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              <ReactTooltip
                                id="donorAddressTooltip"
                                effect="solid"
                                place="right"
                              >
                                <p className="text-sm text-white">
                                  Optionally, fill out the donor's full address
                                  information.
                                </p>
                              </ReactTooltip>
                              <br></br>

                              <h3 className="font-small inline-block pb-6 text-sm text-gray-700">
                                Address 1
                              </h3>
                              <br></br>
                              <h3 className="font-small inline-block pb-6 text-sm text-gray-700">
                                Address 2
                              </h3>
                              <br></br>
                              <h3 className="font-small inline-block pb-6 text-sm text-gray-700">
                                {labelCityText}
                              </h3>
                              <br></br>
                              <h3 className="font-small inline-block pb-6 text-sm text-gray-700">
                                {labelRegionText}
                              </h3>
                              <br></br>
                              <h3 className="font-small inline-block pb-6 text-sm text-gray-700">
                                {labelPostalCodeText}
                              </h3>
                              <br></br>
                              <h3 className="font-small inline-block pb-6 pt-2 text-sm text-gray-700">
                                Country
                              </h3>
                            </div>

                            <div className="sm:col-span-2">
                              <div className="pt-5">
                                {mapBoxAccess ? (
                                  <AddressAutofill
                                    accessToken={
                                      process.env.REACT_APP_SCOPED_MAPBOX_TOKEN
                                    }
                                    onError={(error) => {
                                      console.error(
                                        "Mapbox Autofill Error:",
                                        error
                                      );
                                      // setMapBoxAccess(false); // Disables Mapbox functionality gracefully
                                    }}
                                  >
                                    <input
                                      value={donorAddress1}
                                      onChange={(e) =>
                                        setDonorAddress1(e.target.value)
                                      }
                                      type="text"
                                      name="donor-address1"
                                      id="donor-address1"
                                      autoComplete="address-line1"
                                      className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                    />
                                  </AddressAutofill>
                                ) : (
                                  <input
                                    value={donorAddress1}
                                    onChange={(e) =>
                                      setDonorAddress1(e.target.value)
                                    }
                                    type="text"
                                    name="donor-address1"
                                    id="donor-address1"
                                    autoComplete="address-line1"
                                    className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                  />
                                )}
                              </div>

                              <div className="pb-2"></div>
                              <input
                                value={donorAddress2}
                                onChange={(e) =>
                                  setDonorAddress2(e.target.value)
                                }
                                type="text"
                                name="donor-address2"
                                id="donor-address2"
                                autoComplete="address-line2"
                                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                              />
                              <div className="pb-2"></div>
                              <input
                                value={donorCity}
                                onChange={(e) => setDonorCity(e.target.value)}
                                type="text"
                                name="donor-city"
                                id="donor-city"
                                autoComplete="address-level2"
                                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                              />
                              <div className="pb-2"></div>
                              <input
                                value={donorState}
                                onChange={(e) => setDonorState(e.target.value)}
                                type="text"
                                name="donor-state"
                                id="donor-state"
                                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                autoComplete="address-level1"
                              />
                              <div className="pb-2"></div>
                              <input
                                value={donorZip}
                                onChange={(e) => setDonorZip(e.target.value)}
                                type="text"
                                name="donor-zip"
                                id="donor-zip"
                                autoComplete="postal-code"
                                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                              />
                              <div className="sm:col-span-2">
                                <div className="inline-block pb-2 pt-3 text-sm font-medium text-gray-900">
                                  <select
                                    onChange={handleSelectedCountryChange}
                                    autoComplete="country-name"
                                    value={selectedCountry}
                                    className="focus:border-secondary focus:ring-secondary rounded-md border-gray-300 shadow-sm "
                                  >
                                    <option key="" value="" disabled>
                                      Choose a Country
                                    </option>
                                    {countryOptions.map((country) => (
                                      <option
                                        key={country.label}
                                        value={country.label}
                                      >
                                        {country.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex h-12 items-center justify-between space-x-3">
                          {showSuccessAlert && (
                            <SuccessAlert
                              message="Form Created Successfully"
                              setShowSuccessAlert={setShowSuccessAlert}
                            />
                          )}
                          {showErrorAlert && (
                            <ErrorAlert
                              message={errorMessage}
                              setShowErrorAlert={setShowErrorAlert}
                            />
                          )}
                          {!showSuccessAlert && !showErrorAlert && <div />}
                          <button
                            type="button"
                            className={submitButtonClass}
                            onClick={sendToPaymentScreen}
                          >
                            {!isDisabled && "Next"}
                            {isDisabled && "Completed"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
