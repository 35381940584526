import React, { useState, useEffect } from 'react';
import PayPalLogo from '../../../assets/paypal.png'
import PaypalIntegration from '../Paypal/PaypalIntegration'

function PaypalOnboarding(props) {
    const [showModal, setShowModal] = useState(false);
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [loading, setLoading] = useState(false); // Ensure this state is defined
    const [modalOpen, setModalOpen] = useState(false); // Ensure this state is defined
    const [connectionStatus, setConnectionStatus] = useState(""); // Moved this to state
    const [connectedEmail, setConnectedEmail] = useState(""); // Moved this to state
    const [emailVisability, setEmailVisability] = useState("hidden"); // Moved this to state
    const [isContentVisible, setIsContentVisible] = useState(false);
    const [paypalErrorMessage, setPaypalErrorMessage] = useState(""); // Moved this to state
    const [errorVisability, setErrorVisability] = useState("hidden"); // Moved this to state


    const jwtToken = props.jwtToken; // Ensure jwtToken is available in props or state
    const merchant = props.merchant;
    //let connectionStatus = "Connect"
    //let connectedEmail = ""

    // New function to update connectionStatus
    const updateConnectionStatus = (status) => {
        setConnectionStatus(status);
    };

    const updateConnectedEmail = (email) => {
        setConnectedEmail(email);
    };


    const updatePaypalErrorMessage = (error) => {
        setPaypalErrorMessage(error);
    };

    useEffect(() => {
        if (props.merchant && props.merchant.merchantName) {
            checkPaypalConnectionStatus();
        }
    }, [props.merchant]); // This effect depends on props.merchant


    useEffect(() => {
        // Call the function when the component is mounted
        checkPaypalConnectionStatus();
    }, []); // The empty array ensures this effect runs only once when the component mounts

    const handleEmailReceived = (email) => {
        if (email !== "" && email !== null) {
            updateConnectedEmail(email);
            setEmailVisability("transition-opacity duration-500 ease-in-out opacity-100 text-xxs pb-2 text-center");
            setConnectionStatus("Disconnect");
        }
    };

    const handlePaypalErrorReceived = (error) => {
        if (error !== "" && error !== null) {
            updatePaypalErrorMessage(error);
            setErrorVisability("transition-opacity duration-500 ease-in-out opacity-100 text-xxs pb-2 text-center");
          //  setConnectionStatus("Disconnect");
        }
    };
    const hideEmail = () => {
        setEmailVisability("transition-opacity duration-500 ease-in-out opacity-0");
    };

    useEffect(() => {
        if (connectionStatus === "Connect" || connectionStatus === "Pending" || connectionStatus === "Disconnect") {
            // Start with the content invisible for a smooth transition
            setIsContentVisible(false);
            // Simulate a loading time (adjust according to your needs or actual loading time)
            setTimeout(() => {
                setIsContentVisible(true);
            }, 500); // Adjust the delay as needed
        }
    }, [connectionStatus]);

    useEffect(() => {
    }, [connectionStatus]); // Dependency array, this effect runs when connectionStatus changes


    const checkPaypalConnectionStatus = async () => {
        const payload = {
            merchantName: props.merchant.merchantName
        };
        console.log(payload)
        try {
            console.log("jwt value ", jwtToken)
            const requestOptions = {
                method: "POST",
                headers: {
                    accept: "*/*",
                    "content-type": "application/json",
                    Authorization: jwtToken,
                },
                body: JSON.stringify(payload),
            };

            const url = process.env.REACT_APP_PAYPAL_STATUS_URL;
            console.log("url", url, jwtToken)
            let response = await fetch(url, requestOptions);

            console.log("response", response)
            let data = await response.json();
            console.log("response", { data });
            if (data.error || data.email === null) {
                setConnectionStatus("Connect")
      //          throw new Error(data.error);
            }

            if (data) {
                console.log(data)
                handleEmailReceived(data.email);
                handlePaypalErrorReceived(data.error_message);
            } else {
                throw new Error('No link received');
            }
        } catch (error) {
            console.error('Error during the API call:', error);
            setConnectionStatus("Connect")
            //            alert(error.message);
            console.log(error.message)
        } finally {
            setLoading(false);
            setModalOpen(false);
        }
    };

    let tailwindSpinner =
        <div className="flex justify-center items-center">
            <div className="w-5 h-5 border-3 border-gray-500 border-dashed rounded-lg animate-spin"></div>
        </div>
    
    return (
        <div>
            <button
                onClick={() => connectionStatus === "Connect" ? setShowModal(true) : setShowDisconnectModal(true)}
                className="">
                <img src={PayPalLogo} alt="PayPal" className="mb-3 h-12" />
                <div className="pt-1 pb-3"> PayPal </div>
                {((connectionStatus === "Connect") || (connectionStatus === "Pending")) ? (
                    isContentVisible ? (
                        <div className="transition-opacity duration-700 ease-in opacity-100">
                            <button
                                type="button"
                                className={`text-white ${connectionStatus === "Disconnect" ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'} focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center transition-opacity duration-500 ease-in-out`}>
                                {connectionStatus}
                            </button>
                        </div>
                    ) : (
                        tailwindSpinner
                    )
                ) : null}
                {((connectionStatus === "Disconnect")) ? (
                    isContentVisible ? (
                        <div className="transition-opacity duration-700 ease-in opacity-100">
                            <div className="w-44">
                                <div className="mb-2 text-xs">Account:</div>
                                <div className="break-words text-xxs">
                                    {connectedEmail}
                                </div>
                                {paypalErrorMessage !== "" &&
                                    <div className="mb-2 text-xs pt-2 font-semibold">
                                    {paypalErrorMessage}
                                </div>
                                }
                            </div>
                            <button
                                type="button"
                                className={`mt-4 text-white ${connectionStatus === "Disconnect" ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'} focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center transition-opacity duration-500 ease-in-out`}>
                                {connectionStatus}
                            </button>
                        </div>
                    ) : (
                        tailwindSpinner
                    )
                ) : null}
            </button>
            <PaypalIntegration merchant={props.merchant} updateConnectionStatus={updateConnectionStatus}
                connectionStatus={connectionStatus} showModal={showModal} setShowModal={setShowModal}
                setShowDisconnectModal={setShowDisconnectModal} showDisconnectModal={showDisconnectModal}
                jwtToken={props.jwtToken} />

        </div>
    )
}

export default PaypalOnboarding