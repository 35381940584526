/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { LinkIcon } from "@heroicons/react/20/solid";
import Toggle from "../Toggle";
import Dropdown from "../dropdowns/Dropdown";
import MultiSelectDropdown from "../dropdowns/MultiSelectDropdown";
import { Auth } from "aws-amplify";
import ReactTooltip from "react-tooltip";
import SuccessAlert from "../alerts/SuccessAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import Checkbox from "../Checkbox";
import { useHistory } from "react-router-dom";
import CustomInputsTab from "./tabs/CustomInputsTab";
import SingleSelectDropdown from "../dropdowns/SingleSelectDropdown";
const RECURRING_FREQUENCY_ORDER_MAP = {
  Annually: 0,
  Monthly: 1,
  Weekly: 2,
  Daily: 3,
  "One-Time": 4,
};

const frequencyOptions = ["One-Time", "Daily", "Weekly", "Monthly", "Annually"];

const tabs = [
  { name: "Form Options", href: "#formOptions", current: true },
  // { name: 'Custom Fields', href: '#tax', current: false },
  { name: "Tax Receipts", href: "#tax", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const defaultAmounts = [10, 25, 50, 100];
const countries = [
  "United States",
  "Canada",
  "United Kingdom",
  "Australia",
  "France",
  "Lebanon",
  "Kuwait",
];

export default function CreateDonorList(props) {
  const [jwtToken, setJWTToken] = useState("");
  const [elementName, setelementName] = useState("");
  const [elementID, setElementID] = useState("");

  const [activeTab, setActiveTab] = useState({
    name: "Form Options",
    current: true,
  });

  const [form, setForm] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [copiedState, setCopiedState] = useState("Copied!");

  const [checkedValues, setCheckedValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  const [listType, setListType] = useState("");

  const [createdElement, setCreatedElement] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // internal functions
  const checkFormErrors = () => {
    // console.log("checkedValues: ", checkedValues, "selectedValue: ", selectedValue)
    if (elementName === "") {
      return "A value for name is required";
    }
    if (listType === "") {
      return "A selection for list type is required";
    }
    if (form === "") {
      //crowdfunding is the selected form type and a required field is blank
      return "A Form is required";
    }
    return "";
  };

  const sendToServer = async () => {
    let returnedError = checkFormErrors();
    console.log(returnedError);
    if (returnedError != "") {
      setErrorMessage(returnedError);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    }

    setIsDisabled(true);
    const payload = {
      elementName: elementName,
      listType: listType,
      formName: form,
      nonprofitName: props.merchant.merchantName,
      elementType: "DONOR_LIST",
      elementID: elementID,
    };

    console.log("payload is as follows: ", payload);
    const requestOptions = {
      //only for create
      method: createdElement ? "PATCH" : "POST", // choose the method based on if it is an updated or a new form
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_ELEMENTS_URL}/elements`;

    let response = await fetch(url, requestOptions);
    let data = await response.json();
    console.log({ data });
    if (data.error) {
      setIsDisabled(false);
      setErrorMessage(data.error);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    } else {
      //   changeEmailSettings()
      if (elementID === "") {
        setElementID(data.elementID);
      }
      setIsDisabled(false);
      setShowSuccessAlert(true);
      setShowErrorAlert(false);
      setSuccessMessage(
        createdElement
          ? "Element Saved Successfully"
          : "Element Created Successfully"
      );
      setCreatedElement(true);
    }
  };

  // Clear states when modal is closed
  const clearStates = () => {
    // close slideover
    props.setOpen(false);

    // return to default states
    setelementName("");
    setElementID("");
    setListType("");
  };

  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      const formattedMerchants = data["idToken"]["payload"][
        "cognito:groups"
      ].map((merchant) => merchant.replace(/-/g, " "));

      setJWTToken(data["idToken"]["jwtToken"]);
      console.log("forms", props.selectableForms);
    };
    myFunction();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
  }, [props.open]);

  useEffect(() => {
    if (checkedValues.length > 1 && selectedValue === "") {
      setSelectedValue("Select an option");
    } else if (
      checkedValues.length > 1 &&
      checkedValues.includes(selectedValue) === false
    ) {
      setSelectedValue("Select an option");
    } else if (checkedValues.length === 0) {
      setSelectedValue("Select an option");
    } else if (checkedValues.length === 1) {
      console.log("seeting checked value", checkedValues[0]);
      setSelectedValue(checkedValues[0]);
    }
  }, [checkedValues]);

  let submitButtonClass =
    "justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2";
  if (isDisabled) {
    submitButtonClass =
      "justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm cursor-not-allowed focus:outline-none";
  }
  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                New Donor List
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Get started by filling in the information below
                                to create your new donor list.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={clearStates}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        {activeTab.name === "Form Options" && (
                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:py-0">
                            {/* Form name */}
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              {!createdElement && (
                                <>
                                  <div>
                                    <label
                                      htmlFor="form-name"
                                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                    >
                                      Element name*
                                      <svg
                                        data-tip
                                        data-for="elementNameTooltip"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="text-primary ml-1 inline-block h-5 w-5"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                          clip-rule="evenodd"
                                        />
                                      </svg>
                                      <ReactTooltip
                                        id="elementNameTooltip"
                                        effect="solid"
                                        place="right"
                                      >
                                        <p className="text-sm text-white">
                                          This is the name of your donor list
                                          embed.{" "}
                                        </p>
                                      </ReactTooltip>
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <input
                                      value={elementName}
                                      onChange={(e) =>
                                        setelementName(
                                          e.target.value.replace("|", "")
                                        )
                                      }
                                      type="text"
                                      name="form-name"
                                      id="form-name"
                                      className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                    />
                                  </div>
                                </>
                              )}
                              {createdElement && (
                                <>
                                  <div>
                                    <label
                                      htmlFor="form-name"
                                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                    >
                                      Element name*
                                      <svg
                                        data-tip
                                        data-for="elementNameTooltip"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="text-primary ml-1 inline-block h-5 w-5"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                          clip-rule="evenodd"
                                        />
                                      </svg>
                                      <ReactTooltip
                                        id="elementNameTooltip"
                                        effect="solid"
                                        place="right"
                                      >
                                        <p className="text-sm text-white">
                                          This is the name of your donor list
                                          element.
                                        </p>
                                      </ReactTooltip>
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <div className="py-2 text-sm text-gray-700">
                                      {elementName}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                            {/* List Type */}
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="color"
                                  className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  {" "}
                                  List Type*
                                  <svg
                                    data-tip
                                    data-for="listTypeTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="listTypeTooltip"
                                    effect="solid"
                                    place="right"
                                  >
                                    <p className="text-sm text-white">
                                      This is where you can choose what kind of
                                      Donation List{" "}
                                    </p>
                                    <p className="text-sm text-white">
                                      you want. Data will be aggregated in the
                                      method you pick.
                                    </p>
                                  </ReactTooltip>
                                </label>
                              </div>
                              <div className="col-span-2 inline-flex">
                                <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
                                  <input
                                    type="radio"
                                    value="false"
                                    name="listType"
                                    onClick={() => setListType("TOP_DONORS")}
                                    style={{
                                      backgroundColor:
                                        listType === "TOP_DONORS"
                                          ? "green"
                                          : "white",
                                      marginTop: "4px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  Top Donors&nbsp;&nbsp;
                                  <input
                                    type="radio"
                                    value="false"
                                    name="listType"
                                    onClick={() => setListType("RECENT_DONORS")}
                                    style={{
                                      backgroundColor:
                                        listType === "RECENT_DONORS"
                                          ? "green"
                                          : "white",
                                      marginTop: "4px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  Recent Donors
                                </div>
                              </div>
                            </div>

                            {/* Forms */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <h3 className="inline-block text-sm font-medium text-gray-900">
                                  Forms*
                                </h3>
                                <svg
                                  data-tip
                                  data-for="formsTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="formsTooltip"
                                  effect="solid"
                                  place="right"
                                >
                                  <p className="text-sm text-white">
                                    Please select one form.
                                  </p>
                                </ReactTooltip>
                              </div>
                              <div className="sm:col-span-2">
                                <select
                                  value={form}
                                  className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                  onChange={(e) => setForm(e.target.value)}
                                >
                                  <option value="" disabled>
                                    Select a form
                                  </option>
                                  {props.selectableForms.map((form) => (
                                    <option
                                      key={form.formName}
                                      value={form.formName}
                                    >
                                      {form.formName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            {/* Embed Link */}
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="project-description"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Embed link
                                  <svg
                                    data-tip
                                    data-for="embedTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="embedTooltip"
                                    effect="solid"
                                    place="right"
                                  >
                                    <p className="text-sm text-white">
                                      Copy and paste the code on the right into
                                      your
                                    </p>
                                    <p className="text-sm text-white">
                                      website after clicking "Create". If
                                      nothing appears,
                                    </p>
                                    <p className="text-sm text-white">
                                      you may have forgotten to embed the
                                      CharityStack script
                                    </p>
                                    <p className="text-sm text-white">
                                      file. You can find this on the Settings
                                      Page.
                                    </p>
                                  </ReactTooltip>
                                </label>

                                <p className="text-sm text-gray-500">
                                  Embed this code on your website to collect
                                  donations.
                                </p>
                                <br />

                                <a
                                  className="text-primary hover:text-secondary group flex cursor-pointer items-center space-x-2.5 text-sm font-medium"
                                  data-tip
                                  data-for="copyIframe"
                                  data-event="click"
                                ></a>

                                <ReactTooltip
                                  id="copyIframe"
                                  clickable={true}
                                  effect="solid"
                                  place="bottom"
                                  afterShow={async () => {
                                    try {
                                      await navigator.clipboard.writeText(
                                        elementID !== ""
                                          ? `<div data-entity="DONOR_LIST" data-source="CharityStack" id="${elementID}"></div>`
                                          : ""
                                      );
                                      setCopiedState("Copied!");
                                    } catch (err) {
                                      console.error("Failed to copy: ", err);
                                      setCopiedState("Failed to copy");
                                    } finally {
                                      setTimeout(ReactTooltip.hide, 1000);
                                    }
                                  }}
                                  delayHide={500}
                                  delayUpdate={500}
                                >
                                  <span className="text-sm text-white">
                                    {copiedState}
                                  </span>
                                </ReactTooltip>
                              </div>
                              <div className="sm:col-span-2">
                                <textarea
                                  value={
                                    elementID !== ""
                                      ? `<div data-entity="DONOR_LIST" data-source="CharityStack" id="${elementID}"></div>`
                                      : ""
                                  }
                                  id="project-description"
                                  name="project-description"
                                  rows={5}
                                  className={
                                    "focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm " +
                                    (elementID !== ""
                                      ? "bg-white"
                                      : "bg-gray-100")
                                  }
                                  defaultValue={""}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex h-12 items-center justify-between space-x-3">
                          {showSuccessAlert && (
                            <SuccessAlert
                              message={successMessage}
                              setShowSuccessAlert={setShowSuccessAlert}
                            />
                          )}
                          {showErrorAlert && (
                            <ErrorAlert
                              message={errorMessage}
                              setShowErrorAlert={setShowErrorAlert}
                            />
                          )}
                          {!showSuccessAlert && !showErrorAlert && <div />}

                          <button
                            type="button"
                            className={submitButtonClass}
                            onClick={sendToServer}
                          >
                            {createdElement
                              ? isDisabled
                                ? "Saving"
                                : "Save"
                              : isDisabled
                              ? "Creating"
                              : "Create"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
