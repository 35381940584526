import React, { Component } from "react";
import { StrictMode } from "react/cjs/react.production.min";
import PaginationClassic from "../PaginationClassic";
import moment from "moment";
import { Auth } from "aws-amplify";
import DonationDatePicker from "./DonationDatePicker";
import { CSVLink } from "react-csv";
import EditEventSlideover from "../slideovers/EditEventSlideover";
import decompressData from "../../utils/decompressData";
import pako from "pako";

const formTypeMap = {
  CROWDFUNDING_FORM: "Crowdfunding",
  EMBED_FORM: "Standard",
  QUICK_DONATE: "Quick Donate",
  EVENT: "Event",
  undefined: "Standard",
};

export class EventsTable extends Component {
  constructor(props) {
    super(props);

    const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
    let today = new Date();
    // increment today forward by 1 day
    today.setDate(today.getDate() + 1);
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() - 6000);
    const startTimerange =
      tomorrow.toLocaleDateString("en-US", DATE_OPTIONS) +
      " - " +
      today.toLocaleDateString("en-US", DATE_OPTIONS);
    this.state = {
      jwtToken: "",
      donorsList: [],

      start: 0,
      end: 0,
      total: 0,
      totalActiveEvents: 0,
      totalAmount: 0,
      totalAttendees: 0,
      grossAmountVariable: 0,
      totalTransactionSum: 0,
      totalTransactions: 0,
      totalAttendeeValue: 0,
      timerange: startTimerange,
      open: false,
      editFormData: null,
      formsData: null,
      donorsNextList: [],
      transactionsData: null,
    };
  }

  setOpen = (toggle) => {
    this.setState({ open: toggle });
    // console.log('toggle', toggle)
    // if (toggle == false) {
    //   console.log("close")
    //   this.updateData()
    // }
  };

  async componentDidMount() {
    const data = await Auth.currentSession();
    let user = await Auth.currentAuthenticatedUser();
    console.log("joit");
    console.log("jwtToken", data["idToken"]["jwtToken"]);

    this.setState(
      {
        jwtToken: data["idToken"]["jwtToken"],
        whoIsLoggedIn: user.username,
      }
      // () => this.updateData()
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      totalActiveEvents,
      totalTransactionSum,
      totalTransactions,
      totalAttendees,
      totalAttendeeValue,
      totalAmount,
    } = this.state;
    let totalTicketSumTwoDecimals = totalTransactionSum.toFixed(2);
    this.props.getMetrics(
      totalActiveEvents,
      totalTicketSumTwoDecimals,
      totalTransactions,
      totalAttendees,
      totalAttendeeValue,
      totalAmount
    );
    // check if props.selectableForms is different from its prev state
    if (this.props.selectableForms !== prevProps.selectableForms) {
      // call updateData
      console.log("calling update data");
      this.updateData();
    }
  }

  convertTimestamp(timestamp) {
    const sections = timestamp.split("-");
    return sections[1] + "-" + sections[2] + "-" + sections[0];
  }

  setDateTimeRange = (timerange) => {
    this.setState({ timerange: timerange }, () => this.updateData());
  };

  onEditHandler = (key) => {
    const { formsData } = this.state;
    const nonprofitName = key.split(" | ")[0];

    console.log("nonprofit", nonprofitName);

    const formName = key.split(" | ")[1];
    const formData = formsData.find(
      (form) =>
        form.nonprofitName === nonprofitName && form.formName === formName
    );
    if (formData.formType === "EVENT" || formData.formType !== undefined) {
      this.setState({
        open: true,
        editFormData: formData,
      });
    }
  };

  updateData = async () => {
    // grab forms data
    // const formsRequestOptions = {
    //   method: "GET",
    //   headers: {
    //     accept: "*/*",
    //     "content-type": "application/json",
    //     Authorization: this.state.jwtToken,
    //   },
    // };

    // const formsURL = process.env.REACT_APP_FORMS_URL;

    // let formsResponse = await fetch(formsURL, formsRequestOptions);
    // let formsData = await formsResponse.json();
    if (
      this.props.selectableForms === undefined ||
      this.props.selectableForms === null
    ) {
      return;
    }
    let formsData = JSON.parse(JSON.stringify(this.props.selectableForms));

    formsData = formsData.filter((formData) => formData.formType === "EVENT");
    // create a map of formname string to states from formsdata
    const formsActive = new Map();
    // create a map of formname string to formType from formsdata
    const formsType = new Map();

    const formsTickets = new Map();

    const formsTicketMappings = new Map();

    // create map of formName string to set of active funds
    formsData.forEach((form) => {
      const key = form.nonprofitName + " | " + form.formName;
      formsActive.set(key, form.active);
      formsType.set(key, form.formType);
      formsTickets.set(key, form.tickets);
    });

    let res = this.state.transactionsData;
    console.log("hello");
    if (res === null) {
      // grab transactions data
      const requestOptions = {
        method: "POST",
        headers: {
          accept: "*/*",
          "content-type": "application/json",
          Authorization: this.state.jwtToken,
        },

        body: JSON.stringify({
          timerange: this.state.timerange,
        }),
      };

      const url = process.env.REACT_APP_DONATIONS_URL;
      res = [];
      let lastEvaluatedKeys = {};
      while (true) {
        // fetch data
        let response = await fetch(url, requestOptions);
        // get response in json
        let response_json = await response.json();
        // decompress data
        let decompressed_data = decompressData(response_json);
        console.log("DECOMPRESSED DATA", decompressed_data);
        // extend res to include decompressed_data['transactions']
        res = res.concat(decompressed_data["transactions"]);
        lastEvaluatedKeys = decompressed_data["lastEvaluatedKeys"];

        // break if lastEvaluatedKeys is an object of size 0
        if (
          lastEvaluatedKeys === undefined ||
          lastEvaluatedKeys === null ||
          Object.keys(lastEvaluatedKeys).length === 0
        ) {
          console.log("no more last evaluated key");
          break;
        }

        console.log("has lastEvaluatedKeys", lastEvaluatedKeys);
        // update body to include lastEvaluatedKey
        requestOptions.body = JSON.stringify({
          timerange: this.state.timerange,
          lastEvaluatedKeys: lastEvaluatedKeys,
        });
      }

      this.setState({
        transactionsData: res,
      });
      console.log("res befoer", res);
    }

    console.log("res", res);

    res = res.filter(
      (formData) =>
        formData.tickets !== undefined &&
        formData.tickets !== null &&
        formData.tickets.length !== 0
    );

    let end = 20;
    if (formsData.length < 20) {
      end = formsData.length;
    }
    let totalAttendees = new Set();
    let grossAmountVariable = 0;
    // let totalTransactionsum = 0 // = 0.0;
    // let totalTransactions = 0

    for (let index = 0; index < res.length; index++) {
      res[index].grossAmountVar = (res[index].amount - res[index].fees).toFixed(
        2
      );
    }

    //Table rows with calculated gross donations from each unique email
    let rows = [];
    const eventMap = new Map();
    res.map((transaction) => {
      let grossAmountVariable = parseFloat(transaction.grossAmountVar);

      const refunded = transaction.refunded ?? [];
      const tickets = transaction.tickets ?? [];
      const amount = parseFloat(transaction.amount).toFixed(2);
      const name = transaction.name.toLowerCase();
      const email = transaction.email.toLowerCase();
      let key = transaction.merchantName + " | " + transaction.formName;

      // subtract every refunded amount from the gross amount
      refunded.forEach((refund) => {
        if (refund.name !== "Fee") {
          grossAmountVariable -= parseFloat(refund.refundAmount);
        }
      });

      if (
        transaction.overrideMerchant !== null &&
        transaction.overrideMerchant !== undefined &&
        transaction.overrideMerchant === true
      ) {
        key = transaction.originalMerchantName + " | " + transaction.formName;
      }

      if (
        transaction.state == "SUCCEEDED" ||
        transaction.state == "PARTIAL_REFUND"
      ) {
        totalAttendees.add(name + " | " + email);

        let attendeeCount = tickets.length;
        // iterate through refunded and sum the length of ticketConfirmationIDs
        refunded.forEach((refund) => {
          if (refund.name !== "Fee" && refund.ticketConfirmationIDs) {
            attendeeCount -= refund.ticketConfirmationIDs.length;
          }
        });

        if (eventMap.has(key)) {
          console.log("transaction", transaction);
          eventMap.get(key)["totalDonationNumberCounter"] += 1;
          eventMap.get(key)["grossAmount"] += grossAmountVariable;
          eventMap.get(key)["attendeeCount"] += attendeeCount;
        } else {
          //    console.log('new key', donation)
          let nonprofitNameM = transaction.merchantName;
          if (
            transaction.overrideMerchant !== null &&
            transaction.overrideMerchant !== undefined &&
            transaction.overrideMerchant === true
          ) {
            nonprofitNameM = transaction.originalMerchantName;
          }
          console.log("transaction else", transaction);
          eventMap.set(key, {
            formName: transaction.formName,
            grossAmount: grossAmountVariable,
            totalDonationNumberCounter: 1,
            nonprofitName: nonprofitNameM,
            active: formsActive.get(key),
            formType: formsType.get(key),
            tickets: formsTickets.get(key),
            ticketMapping: transaction.ticketMapping,
            attendeeCount: attendeeCount,
          });
          console.log("eventMap 195", eventMap);
        }
      } else if (transaction.state == "PENDING") {
        console.log(key);
        //     console.log(donation)
      }
    });
    console.log("eventMap 202", eventMap);
    let totalTransactions = 0;
    let totalTransactionsum = 0;
    for (const [key, value] of eventMap.entries()) {
      rows.push({
        formName: value["formName"],
        grossAmountVar: value["grossAmount"],
        totalDonationNumberCounter: value["totalDonationNumberCounter"],
        nonprofitName: value["nonprofitName"],
        active: value["active"],
        formType: value["formType"],
        tickets: value["tickets"],
        ticketMapping: value["ticketMapping"],
        attendeeCount: value["attendeeCount"],
      });
      totalTransactions += value["totalDonationNumberCounter"];
      totalTransactionsum += value["grossAmount"];
    }

    // Create empty entries for forms without donations
    for (let i = 0; i < formsData.length; i++) {
      let key = formsData[i].nonprofitName + " | " + formsData[i].formName;
      if (!eventMap.has(key)) {
        rows.push({
          formName: formsData[i].formName,
          grossAmountVar: 0,
          totalDonationNumberCounter: 0,
          nonprofitName: formsData[i].nonprofitName,
          active: formsData[i].active,
          formType: formsData[i].formType,
          tickets: formsData[i].tickets,
          ticketMapping: formsData[i].ticketMapping,
          attendeeCount: 0,
        });
      }
    }

    rows.sort((a, b) => {
      if (a.active == b.active) {
        return a.formName.toLowerCase() < b.formName.toLowerCase() ? 1 : -1;
      }
    });
    rows.sort((a, b) => (a.active < b.active ? 1 : -1));

    if (rows.length < 20) {
      end = rows.length;
    }

    let totalAttendeeValue = 0;
    let totalAmounts = 0;

    if (rows !== undefined) {
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].tickets !== undefined && rows[i].tickets !== null) {
          totalAttendeeValue += rows[i].attendeeCount;
          totalAmounts += rows[i].grossAmountVar;
        }
      }
    }
    // number of active forms
    const totalActiveEvents = rows.length; // made it include inactive too

    let listN = [];

    rows.map((donor, index) => {
      let attendeesTicket = 0;

      let ticketList = [];
      if (donor.tickets != undefined) {
        if (donor.tickets.length > 0) {
          for (let x = 0; x < donor.tickets.length; x++) {
            let attendeesSoFar =
              parseInt(donor.tickets[x].sold) *
              parseInt(donor.tickets[x].seats);
            attendeesTicket += attendeesSoFar;
            ticketList.push(donor.tickets[x].name);
          }
          console.log("attendeesSoFar", donor.formName, attendeesTicket);
        }
      }
      let commaSeparatedTickets = ticketList.join(", ");
      let status = donor.active == true ? "Active" : "Deactivated";

      console.log(
        'eventMap.get(donor.nonprofitName + " | " + donor.formName)',
        eventMap.get(donor.nonprofitName + " | " + donor.formName)
      );
      let mapB = {
        formName: donor.formName,
        tickets: commaSeparatedTickets,
        grossAmountVar: (
          Math.round(donor.grossAmountVar * Math.pow(10, 2)) / Math.pow(10, 2)
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        transactions: donor.totalDonationNumberCounter
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        attendees: eventMap.get(donor.nonprofitName + " | " + donor.formName),
        status: status,
      };

      listN.push(mapB);
    });

    console.log("updated donorsList", rows);
    this.setState({
      donorsList: rows,
      start: 0,
      end: end,
      totalActiveEvents: totalActiveEvents,
      total: rows.length,
      totalAttendees: totalAttendees,
      totalAttendeeValue: totalAttendeeValue,
      totalAmount: totalAmounts,
      grossAmountVar: grossAmountVariable,
      totalTransactionsum: totalTransactionsum,
      totalDonationNumberCounter: 1,
      totalTransactions: totalTransactions,
      formsData: formsData,
      donorsNextList: listN,
    });
  };

  goNext = (e) => {
    e.preventDefault();
    const { start, end, donorsList } = this.state;
    if (end !== donorsList.length) {
      this.setState({
        start: start + 20,
        end: Math.min(end + 20, donorsList.length),
      });
    }
  };

  goPrev = (e) => {
    e.preventDefault();
    const { start, end, total } = this.state;
    if (start !== 0) {
      this.setState({
        start: Math.max(start - 20, 0),
        end: start,
      });
    }
  };

  headers1 = [
    { label: "Events", key: "formName" },
    { label: "Tickets", key: "tickets" },
    { label: "Amount", key: "grossAmountVar" },
    { label: "Transactions", key: "transactions" },
    { label: "Attendees", key: "attendees" },
    { label: "Status", key: "status" },
  ];

  render() {
    const {
      open,
      donorsList,
      start,
      end,
      total,
      showFilter,
      editFormData,
      openQuickDonation,
      donorsNextList,
    } = this.state;
    const headers1 = this.headers1;
    let donationList1 = donorsList;

    let next = "btn bg-white border-gray-200 hover:border-gray-300 text-light";
    let prev = "btn bg-white border-gray-200 hover:border-gray-300 text-light";

    if (end === total) {
      next = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed";
    }
    if (start === 0) {
      prev = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed";
    }

    return (
      <div className="col-span-full xl:col-span-12">
        <EditEventSlideover
          grabSelectableFunds={this.props.grabSelectableFunds}
          open={open}
          setOpen={this.setOpen}
          selectableFunds={this.props.selectableFunds}
          editFormData={editFormData}
          updateData={this.updateData}
          merchant={this.props.merchant}
        />
        <div className="flex flex-row justify-end py-3 ">
          <div className="flex ">
            <button
              type="button "
              className="focus:outline-none inline-block flex focus:ring-2 focus:ring-offset-2 "
            >
              <CSVLink
                filename="CharityStack Events Export.csv"
                data={donorsNextList}
                headers={headers1}
              >
                <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 17"
                    stroke="#a1afc2"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                  &nbsp;
                  <div className="text-gray-500 hover:text-gray-900 ">
                    {" "}
                    Export &nbsp;{" "}
                  </div>
                </div>
              </CSVLink>
            </button>
          </div>
        </div>

        {showFilter && (
          <div className="flex flex-row p-3">
            <DonationDatePicker setTimerange={this.setDateTimeRange} />
          </div>
        )}

        <div className="rounded-lg border border-[#D1D9E0] bg-white p-3">
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="w-full table-auto ">
              {/* Table header */}
              <thead className="rounded-sm bg-gray-50 text-xs uppercase text-gray-400">
                <tr>
                  <th className="p-2">
                    <div className="text-left font-semibold">Events</div>
                  </th>
                  <th className="p-2">
                    <div className="text-left font-semibold">Tickets</div>
                  </th>
                  <th className="p-2">
                    <div className="text-center font-semibold">Amount</div>
                  </th>
                  <th className="p-2">
                    <div className="text-center font-semibold">
                      Transactions
                    </div>
                  </th>
                  <th className="p-2">
                    <div className="text-center font-semibold">Attendees</div>
                  </th>
                  <th className="p-2">
                    <div className="text-center font-semibold">Status</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="divide-y divide-gray-100 text-sm font-medium">
                {donorsList.slice(start, end).map((donor, index) => {
                  // donor.grossAmount = (donor.grossAmount).toLocaleString(undefined, { maximumFractionDigits: 2 })
                  const id = donor.nonprofitName + " | " + donor.formName;
                  let attendeesTicket = donor.attendeeCount;

                  let ticketList = [];
                  if (donor.tickets != undefined) {
                    if (donor.tickets.length > 0) {
                      for (let x = 0; x < donor.tickets.length; x++) {
                        ticketList.push(donor.tickets[x].name);
                      }
                    }
                  }
                  let commaSeparatedTickets = ticketList.join(", ");

                  let elementTypeColumn = formTypeMap[donor.formType];
                  elementTypeColumn = elementTypeColumn
                    .replace(/_/g, " ") // Replace underscores with spaces
                    .toLowerCase() // Convert to lowercase
                    .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word

                  let status = donor.active == true ? "Active" : "Deactivated";
                  let statusColor =
                    donor.active == true
                      ? "px-3 text-center text-green-800 bg-green-100 rounded-full"
                      : "px-3 text-center text-red-800 bg-red-200 rounded-full";

                  return (
                    <tr
                      className="cursor-pointer hover:bg-gray-50"
                      key={id}
                      onClick={() => this.onEditHandler(id)}
                    >
                      <td className="p-2">
                        <div className="items-center">
                          <div className="text-left text-sm">
                            <a className="font-medium text-gray-600">
                              {donor.formName}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="items-center">
                          <div className="text-s text-left">
                            {commaSeparatedTickets}
                          </div>
                        </div>
                      </td>

                      <td className="p-2">
                        <div className="items-center text-center">
                          <div className="text-center">
                            $
                            {(
                              Math.round(
                                donor.grossAmountVar * Math.pow(10, 2)
                              ) / Math.pow(10, 2)
                            )
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </div>
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="items-center text-center">
                          <div className="text-center">
                            {donor.totalDonationNumberCounter
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </div>
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="items-center text-center">
                          <div className="text-center">{attendeesTicket}</div>
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="items-center text-center">
                          <button className={statusColor}>{status}</button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
                {}
              </tbody>
            </table>
            <div className="mt-8">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <nav
                  className="mb-4 sm:order-1"
                  role="navigation"
                  aria-label="Navigation"
                >
                  <ul className="flex justify-center">
                    <li className="ml-3 first:ml-0">
                      <a className={prev} href="#0" onClick={this.goPrev}>
                        &lt;- Previous
                      </a>
                    </li>
                    <li className="ml-3 first:ml-0">
                      <a className={next} href="#0" onClick={this.goNext}>
                        Next -&gt;
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="text-center text-sm text-gray-500 sm:text-left">
                  Showing{" "}
                  <span className="font-medium text-gray-600">{start}</span> to{" "}
                  <span className="font-medium text-gray-600">{end}</span> of{" "}
                  <span className="font-medium text-gray-600">{total}</span>{" "}
                  results
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventsTable;
