import React, { Component } from "react";
import { StrictMode } from "react/cjs/react.production.min";
import PaginationClassic from "../PaginationClassic";
import moment from "moment";
import { Auth } from "aws-amplify";
import mainLogo from "./logo.png";
import { maxHeight } from "@mui/system";

export class PayoutsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jwtToken: "",
      donorsList: [],
      start: 0,
      end: 0,
      total: 0,
      grossAmountVariable: 0,
      totalDonationSum: 0,
      totalDonations: 0,
      totalDonorValue: 0,
      fiscalSponsor: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.timerange !== this.props.timerange) {
      // console.log("time range " + this.props.timerange)
      // console.log("previous time range " + prevProps.timerange)

      this.updateData();
    }
  }

  async componentDidMount() {
    const data = await Auth.currentSession();
    this.setState(
      {
        jwtToken: data["idToken"]["jwtToken"],
      },
      () => this.updateData()
    );
  }

  convertTimestamp(timestamp) {
    // Attempt to handle ISO 8601 and other formats by first trying to parse the timestamp directly.
    const date = new Date(timestamp);
    // Check if the date is valid.
    if (!isNaN(date.getTime())) {
      // Construct a string in the desired format (MM-DD-YYYY).
      let month = "" + (date.getMonth() + 1), // getMonth() is zero-based.
        day = "" + date.getDate(),
        year = date.getFullYear();

      // Ensure month and day are 2 digits.
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [month, day, year].join("-");
    } else {
      // Fallback for non-standard formats not recognized by the Date constructor.
      // Attempt to manually parse assuming a structure and then rearrange.
      const sections = timestamp.split("-");
      if (sections.length >= 3) {
        return sections[1] + "-" + sections[2] + "-" + sections[0];
      } else {
        // Return original timestamp or a placeholder if it cannot be parsed.
        return timestamp; // Or return something like 'Invalid Date'.
      }
    }
  }

  getNextBusinessDay(dateString) {
    const date = new Date(dateString);
    let nextDay = new Date(date.getTime() + 1000 * 60 * 60 * 24); // Add 1 day

    // Check if the next day is a weekend (Saturday or Sunday)
    while (nextDay.getDay() === 6 || nextDay.getDay() === 0) {
      nextDay = new Date(nextDay.getTime() + 1000 * 60 * 60 * 24); // Add 1 more day
    }

    // Format the next business day as "YYYY-MM-DD"
    return nextDay.toISOString().slice(0, 10);
  }

  async updateData() {
    const requestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: this.state.jwtToken,
      },
      body: JSON.stringify({
        timerange: this.props.timerange,
        merchantID: global.merchantID,
      }),
    };

    const stripeRequestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: this.state.jwtToken,
      },
    };
    // REACT_APP_STRIPE_PAYOUTS_URL
    const stripePayoutsURL =
      process.env.REACT_APP_STRIPE_PAYOUTS_URL + "/payouts";
    const url = process.env.REACT_APP_SETTLEMENTS_URL;
    let response = await fetch(url, requestOptions);

    // change method to GET
    requestOptions.method = "GET";
    let stripeResponse = await fetch(stripePayoutsURL, stripeRequestOptions);

    let resStripe = await stripeResponse.json();
    console.log("res_stripe", resStripe);
    const res = await response.json();
    // add resStripe to res
    res.push(...resStripe);
    let rows = [];
    res.map((payouts) => {
      let processorValue = "CharityStack";
      // if (payouts.paymentProcessor !== undefined) {
      //   processorValue = payouts.paymentProcessor;
      //   if (processorValue === "STRIPE") {
      //     processorValue = "Stripe";
      //   }
      // }

      if (payouts.paymentProcessor === "STRIPE") {
        const amount = (parseFloat(payouts.amount) / 100).toFixed(2);

        // convert epoch timestamp to MM-DD-YYYY
        const unformattedCreatedTimestamp =
          payouts.createdDateReadable.split(" ")[0];
        const createdTimestamp = this.convertTimestamp(
          unformattedCreatedTimestamp
        );
        const unformattedArrivalTimestamp =
          payouts.arrivalDateReadable.split(" ")[0];
        const arrivalTimestamp = this.convertTimestamp(
          unformattedArrivalTimestamp
        );

        if (payouts.status == "PENDING" || payouts.status == "IN_TRANSIT") {
          payouts.status = "Pending";
        }
        if (payouts.status == "PAID") {
          if (parseFloat(payouts.amount) > 0) {
            payouts.status = "Paid";
          } else {
            payouts.status = "Withdrawn";
          }
        }
        if (payouts.status == "FAILED" || payouts.status == "CANCELED") {
          payouts.status = "Failed";
          arrivalTimestamp = "N/A";
        }
        rows.push({
          created: createdTimestamp,
          arrivalDate: arrivalTimestamp,
          grossAmount: amount,
          status: payouts.status,
          payoutID: payouts.charityStackID,
          processor: processorValue,
        });
      }

      if (
        payouts.status === "TRANSFER_SUCCEEDED" ||
        payouts.status === "TRANSFER_PENDING" ||
        payouts.status === "TRANSFER_FAILED"
      ) {
        const amount = (parseFloat(payouts.payoutAmount) / 100).toFixed(2);
        const unformattedCreatedTimestamp = payouts.created.split(" ")[0];
        const createdTimestamp = this.convertTimestamp(
          unformattedCreatedTimestamp
        );
        const unformattedArrivalTimestamp = this.getNextBusinessDay(
          unformattedCreatedTimestamp
        );
        const arrivalTimestamp = this.convertTimestamp(
          unformattedArrivalTimestamp
        );

        if (payouts.status == "SETTLEMENT_APPROVED") {
          payouts.status = "Pending";
        }
        if (payouts.status == "TRANSFER_SUCCEEDED") {
          if (parseFloat(payouts.payoutAmount) > 0) {
            payouts.status = "Paid";
          } else {
            payouts.status = "Withdrawn";
          }
        }

        rows.push({
          created: createdTimestamp,
          arrivalDate: arrivalTimestamp,
          grossAmount: amount,
          status: payouts.status,
          payoutID: payouts.settlementID,
          processor: processorValue,
        });
        console.log(rows);
      }
    });
    // sort rows by created date
    rows.sort((a, b) => {
      return new Date(b.created) - new Date(a.created);
    });
    console.log("payment rows", rows);
    let end = 20;
    if (rows.length < 20) {
      end = rows.length;
    }
    this.setState(
      {
        donorsList: rows,
        start: 0,
        end: end,
        total: rows.length,
      },
      () => {
        // Call the readiness callback after state is updated
        if (this.props.onDataReady) {
          this.props.onDataReady();
        }
      }
    );
  }

  render() {
    const { donorsList, start, end, total, fiscalSponsor } = this.state;

    let next = "btn bg-white border-gray-200 hover:border-gray-300 text-light";
    let prev = "btn bg-white border-gray-200 hover:border-gray-300 text-light";
    if (end === total) {
      next = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed";
    }
    if (start === 0) {
      prev = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed";
    }
    // console.log("in the code")
    // console.log(fiscalSponsor)
    if (fiscalSponsor == true) {
      return (
        <div className="col-span-full rounded-lg border border-gray-200 bg-white shadow-lg xl:col-span-12">
          <div className="lg:text-center">
            <img className="mx-auto h-60" src={mainLogo} alt=".NGO" />

            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              This feature is not available for Fiscal Sponsors.
            </p>
          </div>

          <div className="mt-10"></div>
        </div>
      );
    }
    if (fiscalSponsor == false) {
      return (
        <div className="col-span-full rounded-lg border border-gray-200 bg-white shadow-lg xl:col-span-12">
          <div className="p-3">
            <div className="font-lg px-2 py-2 text-left text-lg font-semibold text-black ">
              Payouts
            </div>
            {/* Table */}
            <div className="overflow-x-auto">
              <table className="w-full table-auto ">
                {/* Table header */}
                <thead className="rounded-sm bg-gray-50 text-xs uppercase text-gray-400">
                  <tr>
                    <th className="p-2">
                      <div className="text-left font-semibold">
                        Created Date
                      </div>
                    </th>
                    {/*
                    <th className="p-2">
                      <div className="font-semibold text-left">Fund</div>
                    </th>
      */}
                    <th className="p-2">
                      <div className="text-center font-semibold">Amount</div>
                    </th>
                    <th className="p-2">
                      <div className="text-center font-semibold">State</div>
                    </th>
                    <th className="p-2">
                      <div className="text-center font-semibold">
                        Arrival Date
                      </div>
                    </th>
                    <th className="p-2">
                      <div className="text-center font-semibold">Processor</div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="divide-y divide-gray-100 text-sm font-medium">
                  {donorsList.slice(start, 5).map((donor, index) => {
                    const amount = parseFloat(
                      donor.grossAmount.replace(/,/g, "")
                    ); // Remove all commas from the string
                    const formattedAmount = amount
                      .toFixed(2)
                      .toLocaleString("en-US"); // Convert to 2 decimal places and add commas back

                    donor.grossAmount = formattedAmount; // Update donor.grossAmount with the formatted value

                    //       donor.grossAmount = (Math.round(donor.grossAmount * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    let stateColor1 =
                      "px-3 text-center text-xs text-green-800 bg-green-100 rounded-full";
                    if (donor.status == "TRANSFER_SUCCEEDED") {
                      if (parseFloat(donor.grossAmount) > 0) {
                        donor.status = "Paid";
                      } else {
                        donor.status = "Withdrawn";
                        stateColor1 =
                          "px-3 text-center text-gray-800 bg-gray-200 text-xs rounded-full";
                      }
                    }
                    if (donor.status == "Pending") {
                      stateColor1 = stateColor1 =
                        "px-3 text-cente text-xs text-blue-800 bg-blue-100 rounded-full";
                    }
                    if (donor.status == "TRANSFER_PENDING") {
                      stateColor1 =
                        "px-3 text-center text-xs text-blue-800 bg-blue-100 rounded-full";
                      donor.status = "Pending";
                    }
                    if (
                      donor.status == "TRANSFER_FAILED" ||
                      donor.status == "SETTLEMENT_FAILED"
                    ) {
                      stateColor1 =
                        "px-3 text-center text-xs text-red-800 bg-red-200 rounded-full";
                      donor.status = "Failed";
                    }
                    return (
                      <tr className="text-gray-800">
                        <td className="p-2">
                          <div className="items-center">
                            <div className="text-left text-xs">
                              {donor.created}
                            </div>
                          </div>
                        </td>
                        {/*
                        <td className="p-2">
                          <div className="items-center text-left">
                            <button className="text-left text-xs">{donor.merchantName}</button>
                          </div>
                        </td>
                    */}
                        <td className="">
                          <div className="items-center text-center">
                            <button className="text-center text-xs">
                              {donor.grossAmount.startsWith("-") ? (
                                <>
                                  ($
                                  {Number(
                                    donor.grossAmount.replace(/-/g, "")
                                  ).toFixed(2)}
                                  )
                                </>
                              ) : (
                                <>${Number(donor.grossAmount).toFixed(2)}</>
                              )}
                            </button>
                          </div>
                        </td>
                        <div className="flex flex-col items-center text-lg">
                          <button className="align-center content-center text-center">
                            <div className="content-center p-2 text-center">
                              <div className={stateColor1}> {donor.status}</div>
                            </div>
                          </button>
                        </div>
                        <td className="p-2">
                          <div className="items-center">
                            <div className="text-center text-xs">
                              {donor.arrivalDate ?? ""}
                            </div>
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="content-center text-center text-xs">
                            {donor.processor}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PayoutsTable;
