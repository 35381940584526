/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Dialog, Transition } from "@headlessui/react";
import {
  CreditCardIcon,
  ArrowUturnLeftIcon,
} from "@heroicons/react/24/outline";
import { BanknotesIcon, BuildingLibraryIcon } from "@heroicons/react/20/solid";
import Card from "../payment-methods/Card";
import BankTransfer from "../payment-methods/BankTransfer";
import ErrorAlert from "../alerts/ErrorAlert";
import ClipLoader from "react-spinners/ClipLoader";
import Dropdown from "../dropdowns/Dropdown";
import dollarFormat from "../../utils/DollarFormat";
import Checkbox from "../Checkbox";

export default function RefundModal(props) {
  const { selectedSubscription } = props;
  const cancelButtonRef = useRef(null);

  const [jwtToken, setJWTToken] = useState(null);
  // const [sendingData, setSendingData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Card Related
  const [cardSelected, setCardSelected] = useState(false);
  const [iframeStates, setIframeStates] = useState(null);
  const [form, setForm] = useState(null);

  // Bank Transfer Related
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountType, setAccountType] = useState("CHECKING");

  function formatTicketAttendees(names) {
    const nameList = names.split(",").map((name) => name.trim());
    if (nameList.length <= 2) {
      return names;
    } else if (nameList.length == 3) {
      const remainingAttendees = nameList.length - 2;
      return `${nameList[0]}, ${nameList[1]},  +${remainingAttendees} attendee`;
    } else {
      const remainingAttendees = nameList.length - 2;
      return `${nameList[0]}, ${nameList[1]},  +${remainingAttendees} attendees`;
    }
  }

  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      setJWTToken(data["idToken"]["jwtToken"]);

      setForm(
        window.PaymentForm.card((state, binInformation) => {
          if (state === undefined) {
            // document.getElementById('brand').innerHTML = binInformation.cardBrand;
            //document.getElementById('bin').innerHTML = JSON.stringify(binInformation, null, '  ');
          } else {
            setIframeStates(state);
          }
        })
      );

      // store every confirmationID in confirmationRefundIDs
      let newRefundConfirmationIDs = [];
      props.tickets.forEach((ticket) => {
        ticket.confirmationIDs.forEach((id) => {
          newRefundConfirmationIDs.push(id);
        });
      });
      props.setRefundConfirmationIDs(newRefundConfirmationIDs);
      console.log("newRefundConfirmationIDs", newRefundConfirmationIDs);
      console.log("props.tickets", props.tickets);
    };
    myFunction();
  }, []);

  useEffect(() => {
    const myFunction = async () => {
      // if (props.open == true) {
      //     setErrorMessage("")
      //     setShowErrorAlert(false)
      // }
      // if (props.open == false) {
      setForm(
        window.PaymentForm.card((state, binInformation) => {
          if (state === undefined) {
            // document.getElementById('brand').innerHTML = binInformation.cardBrand;
            //document.getElementById('bin').innerHTML = JSON.stringify(binInformation, null, '  ');
          } else {
            setIframeStates(state);
          }
        })
      );
      props.setErrorMessage("");
      props.setShowErrorAlert(false);
      // }
    };
    myFunction();
  }, [props.open]);

  let netAmount = 0;
  let displayedFund = "";
  if (selectedSubscription != null) {
    netAmount = (
      (Math.round(selectedSubscription.amount - selectedSubscription.fees) *
        Math.pow(10, 2)) /
      Math.pow(10, 2)
    )
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Determine whether to display fund or funds
    displayedFund = selectedSubscription.fund;
    if (
      selectedSubscription.funds !== null &&
      selectedSubscription.funds !== undefined &&
      selectedSubscription.funds.length > 0
    ) {
      // make fund equal to a nicely formatted stringified array of funds
      displayedFund = selectedSubscription.funds
        .map((item) => item.fundName)
        .join(", ");
    }
  }
  let cardVisibility = {};
  let bankTransferVisibility = { display: "none" };

  let extraCardButtonClass =
    "bg-primary text-white hover:bg-secondary focus:ring-secondary border-transparent";
  let extraBankTransferButtonClass =
    "bg-white text-secondary hover:bg-gray059 focus:ring-gray-400 border-gray-300";

  if (!cardSelected) {
    cardVisibility = { display: "none" };
    bankTransferVisibility = {};

    extraCardButtonClass =
      "bg-white text-secondary hover:bg-gray059 focus:ring-gray-400 border-gray-300";
    extraBankTransferButtonClass =
      "bg-primary text-white hover:bg-secondary focus:ring-secondary border-transparent";
  }

  const message = cardSelected
    ? "Enter the credit card details, and then click the update button below to update the payment method"
    : "Enter the bank transfer details, and then click the update button below to update the payment method";

  return (
    <Transition.Root
      show={props.open}
      as={Fragment}
      unmount={props.slideoverOpen}
    >
      <Dialog
        as="div"
        className="relative z-20 overflow-y-visible"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
        unmount={props.slideoverOpen}
        classes={{
          paperFullWidth: {
            overflowY: "visible",
          },
          dialogContentRoot: {
            overflowY: "visible",
          },
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          unmount={props.slideoverOpen}
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              unmount={props.slideoverOpen}
            >
              <Dialog.Panel className="relative transform overflow-y-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {props.showErrorAlert && (
                  <div className="mb-2">
                    <ErrorAlert
                      message={props.errorMessage}
                      setShowErrorAlert={props.setShowErrorAlert}
                    />
                  </div>
                )}
                <div className="sm:flex sm:items-start">
                  {/* {
                                        showErrorAlert
                                        &&
                                        <ErrorAlert message={errorMessage} setShowErrorAlert={setShowErrorAlert} />
                                    } */}
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ArrowUturnLeftIcon className="h-6 w-6 text-gray-500" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-2 sm:text-left">
                    <div className="">
                      <Dialog.Title
                        as="h3"
                        className="text-2xl leading-6 text-gray-500"
                      >
                        Confirm Refund
                      </Dialog.Title>
                      <div className="mt-2 text-base leading-tight text-gray-500">
                        <div>
                          Refunds take 5-10 days to appear on a customer's
                          statement. CharityStack's fees for the original
                          payment won't be returned, but there are no additional
                          fees for refund.
                        </div>
                        <div className="mt-2">
                          Once a refund is initiated and processed through our
                          system, it cannot be reversed.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* using negative margin on x to bypass parent margin for this one child */}
                <div className="-mx-6 mt-4 border-b border-t border-gray-300">
                  <div className="mx-12 pb-4 pt-4 text-gray-500">
                    <div className="w-full">
                      <div className="">Refund Amount</div>
                      <div className="mt-1 flex h-12 w-full rounded-md border border-gray-300 bg-white shadow-sm sm:w-full">
                        <span className="inline-flex items-center border-r border-gray-300 px-4 text-gray-500 sm:text-sm">
                          USD
                        </span>
                        <span
                          className={`${
                            props.refundData.refundAmount === ""
                              ? "text-gray-500"
                              : "text-black"
                          } inline-flex items-center pl-3 sm:text-sm`}
                        >
                          $
                        </span>
                        <input
                          type="tel"
                          className="outline-none focus:outline-none ml-0 mr-1 w-full border-none pl-0 pr-1 text-black focus:ring-0 focus:ring-offset-0 sm:text-base"
                          placeholder="0.00"
                          value={props.refundData.refundAmount}
                          onChange={(e) => {
                            const { value } = e.target;
                            const numericValue = value.replace(/[^0-9.]/g, "");
                            const parts = numericValue.split(".");

                            if (parts.length > 2) {
                              return;
                            }

                            if (parts.length === 2 && parts[1].length > 2) {
                              return;
                            }
                            props.setRefundData({
                              ...props.refundData,
                              refundAmount: numericValue,
                            });
                            // setInputtedFundAmount(numericValue);
                          }}
                        />

                        {/* <div
                          className="text-highlight-blue flex cursor-pointer items-center justify-center px-2 text-sm font-normal "
                          onClick={() => {
                            if (props.refundData.refundAmount === "") {
                              setShowErrorAlert(true);
                              setErrorMessage("Amount cannot be empty");
                              return;
                            }
                            if (parseFloat(props.refundData.refundAmount) < 1) {
                              setShowErrorAlert(true);
                              setErrorMessage(
                                "Each line item amount must be greater than $1"
                              );
                              return;
                            }

                            setShowErrorAlert(false);
                            setErrorMessage("");
                            updateFundAmount(
                              fund,
                              parseFloat(props.refundData.refundAmount)
                            );
                            setUpdating(false);
                          }}
                        >
                          <div className="text-center">Apply</div>
                        </div> */}
                      </div>
                    </div>
                    <div className="mb-1 mt-2">Reason </div>
                    <div className="z-60 block grow">
                      <Dropdown
                        selectedValue={props.refundReason}
                        setSelectedValue={props.setRefundReason}
                        values={[
                          "Duplicate",
                          "Fraud",
                          "Requested by Customer",
                          "Other",
                        ]}
                        width={"w-full"}
                        additionalMenuButtonClasses={"items-center h-12"}
                      />
                    </div>

                    {props.tickets.length > 0 && (
                      <>
                        <div className="mb-1 mt-2">
                          Reopen the following seats for purchase{" "}
                        </div>
                        <div>
                          <div className="text-gray-500">
                            {props.tickets.map((ticket, i) => {
                              // if (ticket.numSold > 0) {
                              return (
                                // <div
                                //   key={ticket.name}
                                //   className="mb-2 flex h-10 items-center rounded-md border border-gray-300 px-2"
                                // >
                                //   <div className="w-11/12 border-r border-gray-300 pl-2 font-semibold">
                                //     Name: {ticket.name}
                                //   </div>
                                //   <input
                                //     type="tel"
                                //     value={ticket.numSold}
                                //     className="outline-none focus:outline-none h-full w-1/12 border-none text-black focus:ring-0 focus:ring-offset-0 sm:text-base "
                                //     placeholder="0"
                                //   />
                                // </div>
                                <div
                                  className={`isolate h-12 w-full items-center justify-between rounded-md border border-gray-200 bg-gray-100 px-4 py-2 sm:flex ${
                                    i === props.tickets.length - 1 ? "" : "mb-2"
                                  }`}
                                >
                                  <div className={`justify-start pb-1 sm:pb-0`}>
                                    <div className="text-left text-sm font-medium leading-tight text-black">
                                      {ticket.ticketName}
                                    </div>
                                    <div className="text-left text-xs font-medium leading-tight text-gray-500">
                                      {formatTicketAttendees(ticket.name)}
                                    </div>
                                  </div>

                                  <Checkbox
                                    // label="One-Time &nbsp;&nbsp;"
                                    removeSpacing={true}
                                    checked={ticket.confirmationIDs.every(
                                      (id) =>
                                        props.refundConfirmationIDs.includes(id)
                                    )}
                                    onChange={(isChecked) => {
                                      if (isChecked) {
                                        // add all confirmation IDs to refundConfirmationIDs
                                        props.setRefundConfirmationIDs([
                                          ...props.refundConfirmationIDs,
                                          ...ticket.confirmationIDs,
                                        ]);
                                      }
                                      if (!isChecked) {
                                        // remove all confirmation IDs from refundConfirmationIDs
                                        props.setRefundConfirmationIDs(
                                          props.refundConfirmationIDs.filter(
                                            (id) =>
                                              !ticket.confirmationIDs.includes(
                                                id
                                              )
                                          )
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              );
                              // }
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {props.inProgress && (
                    <button
                      type="button"
                      name="deactivate"
                      className="bg-secondary hover:bg-secondary focus:outline-none focus:ring-secondary pointer-events-none inline-flex w-1/5 justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:text-sm"
                      onClick={(e) => {
                        // updateWithCard(props);
                      }}
                    >
                      {/* <div className="invisible">
                                                Update
                                            </div> */}
                      <ClipLoader
                        color={"#FFFFFF"}
                        loading={props.inProgress}
                        // cssOverride={override}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </button>
                  )}
                  {!props.inProgress && (
                    <button
                      type="button"
                      name="deactivate"
                      className="bg-primary hover:bg-secondary focus:outline-none focus:ring-secondary inline-flex w-1/5 justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:text-sm"
                      onClick={(e) => {
                        props.initiateRefund();
                      }}
                    >
                      Refund
                    </button>
                  )}

                  <button
                    type="button"
                    className="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => props.setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
