import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Storage, Amplify } from "aws-amplify";
import { store } from "./redux/store";
import { Provider } from "react-redux";

const awsConfig = {
  aws_project_region: "us-east-2", // Replace with your actual AWS region, e.g., 'us-east-1'
  // Add other required AWS configurations if needed
  aws_cognito_identity_pool_id:
    "us-east-2:3f22d0ba-ec02-497e-80b5-4f8427ba280e",
  Auth: {
    region: "us-east-2",
    userPoolId: "us-east-2_rgQU9hI0d",
    userPoolWebClientId: "23jtveguege5k7f0f05abtjugo",
  },
};

Amplify.configure(awsConfig);

ReactDOM.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <Router>
      <App />
    </Router>
    {/* </React.StrictMode> */}
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
