import { formatDateRockset } from "../formatTimestamp";

const apikey = process.env.REACT_APP_ROCKSET_API_KEY;

const reactENV = process.env.REACT_APP_ENV;
let env = "dev";
if (reactENV == "production") {
  env = "prod";
}

const rockset_transactions_query_url = `https://api.usw2a1.rockset.com/v1/orgs/self/ws/${env}/lambdas/transactions-query/tags/latest`;

const rockset_transactions_top_forms_query_url = `https://api.usw2a1.rockset.com/v1/orgs/self/ws/${env}/lambdas/transactions-top-forms-query/tags/latest`;

const rockset_transactions_top_funds_query_url = `https://api.usw2a1.rockset.com/v1/orgs/self/ws/${env}/lambdas/transactions-top-funds-query/tags/latest`;

const rockset_transactions_top_names_query_url = `https://api.usw2a1.rockset.com/v1/orgs/self/ws/${env}/lambdas/transactions-top-names-query/tags/latest`;

const rockset_transactions_top_emails_query_url = `https://api.usw2a1.rockset.com/v1/orgs/self/ws/${env}/lambdas/transactions-top-emails-query/tags/latest`;

const rockset_transactions_chart_query_url = `https://api.usw2a1.rockset.com/v1/orgs/self/ws/${env}/lambdas/transactions-chart-query/tags/latest`;

export async function queryTransactionsChart(merchants) {
  const response = await fetch(rockset_transactions_chart_query_url, {
    method: "POST",
    headers: {
      Authorization: `ApiKey ${apikey}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      parameters: [
        {
          name: "merchantNames",
          type: "array",
          value: JSON.stringify(merchants),
        },
      ],
    }),
  });

  const queryResponse = await response.json();
  console.log("queryResponse Transactions Chart", queryResponse);
  return queryResponse.results;
}

export async function queryTransactions(
  merchants,
  timerange,
  amountAtLeast,
  amountNoMoreThan,
  amountSpecific,
  filteredPaymentType,
  filteredState,
  filteredDonorList,
  filteredEmailList,
  filteredFormList,
  filteredFundList,
  limit
) {
  const paymentMethods = filteredPaymentType
    ? filteredPaymentType.map((paymentType) =>
        paymentType.toUpperCase().replace(/ /g, "_")
      )
    : "";
  const states = filteredState.flatMap((state) => {
    if (state === "Refunded") {
      // add FULL_REFUND, FUND_REFUND, TICKET_REFUND, FEE_REFUND
      return ["FULL_REFUND", "PARTIAL_REFUND"];
    }
    if (state === "Failed") {
      return ["FAILED"];
    }
    if (state === "Pending") {
      return ["PENDING"];
    }
    if (state === "Success") {
      return ["SUCCEEDED"];
    }
    if (state === "Disputed") {
      return ["DISPUTED_OPEN", "DISPUTED_CLOSED"];
    }
  });
  const { startDate, endDate } = timerange
    ? formatDateRockset(timerange)
    : { startDate: "", endDate: "" };
  const response = await fetch(rockset_transactions_query_url, {
    method: "POST",
    headers: {
      Authorization: `ApiKey ${apikey}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      parameters: [
        {
          name: "amountEquals",
          type: "float",
          value: amountSpecific === "" ? "-1" : amountSpecific,
        },
        {
          name: "amountGreaterThan",
          type: "float",
          value: amountAtLeast === "" ? "-1" : amountAtLeast,
        },
        {
          name: "amountLessThan",
          type: "float",
          value: amountNoMoreThan === "" ? "-1" : amountNoMoreThan,
        },
        { name: "endDate", type: "string", value: endDate },
        {
          name: "paymentMethods",
          type: "array",
          value: JSON.stringify(paymentMethods),
        },
        { name: "startDate", type: "string", value: startDate },
        {
          name: "states",
          type: "array",
          value: JSON.stringify(states),
        },
        {
          name: "merchantNames",
          type: "array",
          value: JSON.stringify(merchants),
        },
        {
          name: "formNames",
          type: "array",
          value: JSON.stringify(filteredFormList),
        },
        {
          name: "fundNames",
          type: "array",
          value: JSON.stringify(filteredFundList),
        },
        {
          name: "names",
          type: "array",
          value: JSON.stringify(filteredDonorList),
        },
        {
          name: "emails",
          type: "array",
          value: JSON.stringify(filteredEmailList),
        },
        { name: "limit", type: "int", value: limit },
      ],
    }),
  });

  const queryResponse = await response.json();
  console.log("queryResponse", queryResponse);
  return queryResponse.results;
}

export async function queryTransactionsTopForms(
  merchants,
  timerange,
  amountAtLeast,
  amountNoMoreThan,
  amountSpecific,
  filteredPaymentType,
  filteredState,
  keywordSearchTerm
) {
  const paymentMethods = filteredPaymentType
    ? filteredPaymentType.map((paymentType) =>
        paymentType.toUpperCase().replace(/ /g, "_")
      )
    : "";
  console.log("paymentMethods", paymentMethods);
  const states = filteredState.flatMap((state) => {
    if (state === "Refunded") {
      // add FULL_REFUND, FUND_REFUND, TICKET_REFUND, FEE_REFUND
      return ["FULL_REFUND", "PARTIAL_REFUND"];
    }
    if (state === "Failed") {
      return ["FAILED"];
    }
    if (state === "Pending") {
      return ["PENDING"];
    }
    if (state === "Success") {
      return ["SUCCEEDED"];
    }
    if (state === "Disputed") {
      return ["DISPUTED_OPEN", "DISPUTED_CLOSED"];
    }
  });
  const { startDate, endDate } = timerange
    ? formatDateRockset(timerange)
    : { startDate: "", endDate: "" };
  const response = await fetch(rockset_transactions_top_forms_query_url, {
    method: "POST",
    headers: {
      Authorization: `ApiKey ${apikey}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      parameters: [
        {
          name: "amountEquals",
          type: "float",
          value: amountSpecific === "" ? "-1" : amountSpecific,
        },
        {
          name: "amountGreaterThan",
          type: "float",
          value: amountAtLeast === "" ? "-1" : amountAtLeast,
        },
        {
          name: "amountLessThan",
          type: "float",
          value: amountNoMoreThan === "" ? "-1" : amountNoMoreThan,
        },
        { name: "endDate", type: "string", value: endDate },
        { name: "frequency", type: "string", value: "" },
        // { name: "formNames", type: "array", value: "" },
        // { name: "fundNames", type: "array", value: "" },
        {
          name: "keyword",
          type: "string",
          value: "%" + keywordSearchTerm + "%",
        },
        { name: "name", type: "string", value: "" },
        {
          name: "paymentMethods",
          type: "array",
          value: JSON.stringify(paymentMethods),
        },
        { name: "startDate", type: "string", value: startDate },
        {
          name: "states",
          type: "array",
          value: JSON.stringify(states),
        },
        {
          name: "merchantNames",
          type: "array",
          value: JSON.stringify(merchants),
        },
      ],
    }),
  });

  const queryResponse = await response.json();
  console.log("queryResponse forms", queryResponse.results);
  return queryResponse.results.map((result) => result.formName);
}

export async function queryTransactionsTopFunds(
  merchants,
  timerange,
  amountAtLeast,
  amountNoMoreThan,
  amountSpecific,
  filteredPaymentType,
  filteredState,
  keywordSearchTerm,
  limit
) {
  const paymentMethods = filteredPaymentType
    ? filteredPaymentType.map((paymentType) =>
        paymentType.toUpperCase().replace(/ /g, "_")
      )
    : "";
  const states = filteredState.flatMap((state) => {
    if (state === "Refunded") {
      // add FULL_REFUND, FUND_REFUND, TICKET_REFUND, FEE_REFUND
      return ["FULL_REFUND", "PARTIAL_REFUND"];
    }
    if (state === "Failed") {
      return ["FAILED"];
    }
    if (state === "Pending") {
      return ["PENDING"];
    }
    if (state === "Success") {
      return ["SUCCEEDED"];
    }
    if (state === "Disputed") {
      return ["DISPUTED_OPEN", "DISPUTED_CLOSED"];
    }
  });
  const { startDate, endDate } = timerange
    ? formatDateRockset(timerange)
    : { startDate: "", endDate: "" };
  const response = await fetch(rockset_transactions_top_funds_query_url, {
    method: "POST",
    headers: {
      Authorization: `ApiKey ${apikey}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      parameters: [
        {
          name: "amountEquals",
          type: "float",
          value: amountSpecific === "" ? "-1" : amountSpecific,
        },
        {
          name: "amountGreaterThan",
          type: "float",
          value: amountAtLeast === "" ? "-1" : amountAtLeast,
        },
        {
          name: "amountLessThan",
          type: "float",
          value: amountNoMoreThan === "" ? "-1" : amountNoMoreThan,
        },
        { name: "endDate", type: "string", value: endDate },
        { name: "frequency", type: "string", value: "" },
        // { name: "formNames", type: "array", value: "" },
        // { name: "fundNames", type: "array", value: "" },
        {
          name: "keyword",
          type: "string",
          value: "%" + keywordSearchTerm + "%",
        },
        { name: "name", type: "string", value: "" },
        {
          name: "paymentMethods",
          type: "array",
          value: JSON.stringify(paymentMethods),
        },
        { name: "startDate", type: "string", value: startDate },
        {
          name: "states",
          type: "array",
          value: JSON.stringify(states),
        },
        {
          name: "merchantNames",
          type: "array",
          value: JSON.stringify(merchants),
        },
        {
          name: "limit",
          type: "int",
          value: limit ? limit : 5,
        },
      ],
    }),
  });

  const queryResponse = await response.json();
  console.log("queryResponse funds", queryResponse.results);
  return queryResponse.results.map((result) => result.fundName);
}

export async function queryTransactionsTopNames(
  merchants,
  timerange,
  amountAtLeast,
  amountNoMoreThan,
  amountSpecific,
  filteredPaymentType,
  filteredState,
  keywordSearchTerm
) {
  const paymentMethods = filteredPaymentType
    ? filteredPaymentType.map((paymentType) =>
        paymentType.toUpperCase().replace(/ /g, "_")
      )
    : "";
  const states = filteredState.flatMap((state) => {
    if (state === "Refunded") {
      // add FULL_REFUND, FUND_REFUND, TICKET_REFUND, FEE_REFUND
      return ["FULL_REFUND", "PARTIAL_REFUND"];
    }
    if (state === "Failed") {
      return ["FAILED"];
    }
    if (state === "Pending") {
      return ["PENDING"];
    }
    if (state === "Success") {
      return ["SUCCEEDED"];
    }
    if (state === "Disputed") {
      return ["DISPUTED_OPEN", "DISPUTED_CLOSED"];
    }
  });
  const { startDate, endDate } = timerange
    ? formatDateRockset(timerange)
    : { startDate: "", endDate: "" };
  const response = await fetch(rockset_transactions_top_names_query_url, {
    method: "POST",
    headers: {
      Authorization: `ApiKey ${apikey}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      parameters: [
        {
          name: "amountEquals",
          type: "float",
          value: amountSpecific === "" ? "-1" : amountSpecific,
        },
        {
          name: "amountGreaterThan",
          type: "float",
          value: amountAtLeast === "" ? "-1" : amountAtLeast,
        },
        {
          name: "amountLessThan",
          type: "float",
          value: amountNoMoreThan === "" ? "-1" : amountNoMoreThan,
        },
        { name: "endDate", type: "string", value: endDate },
        { name: "frequency", type: "string", value: "" },
        // { name: "formNames", type: "array", value: "" },
        // { name: "fundNames", type: "array", value: "" },
        {
          name: "keyword",
          type: "string",
          value: "%" + keywordSearchTerm + "%",
        },
        { name: "name", type: "string", value: "" },
        {
          name: "paymentMethods",
          type: "array",
          value: JSON.stringify(paymentMethods),
        },
        { name: "startDate", type: "string", value: startDate },
        {
          name: "states",
          type: "array",
          value: JSON.stringify(states),
        },
        {
          name: "merchantNames",
          type: "array",
          value: JSON.stringify(merchants),
        },
      ],
    }),
  });

  const queryResponse = await response.json();
  console.log("queryResponse forms", queryResponse.results);
  return queryResponse.results.map((result) => result.name);
}

export async function queryTransactionsTopEmails(
  merchants,
  timerange,
  amountAtLeast,
  amountNoMoreThan,
  amountSpecific,
  filteredPaymentType,
  filteredState,
  keywordSearchTerm
) {
  const paymentMethods = filteredPaymentType
    ? filteredPaymentType.map((paymentType) =>
        paymentType.toUpperCase().replace(/ /g, "_")
      )
    : "";
  const states = filteredState.flatMap((state) => {
    if (state === "Refunded") {
      // add FULL_REFUND, FUND_REFUND, TICKET_REFUND, FEE_REFUND
      return ["FULL_REFUND", "PARTIAL_REFUND"];
    }
    if (state === "Failed") {
      return ["FAILED"];
    }
    if (state === "Pending") {
      return ["PENDING"];
    }
    if (state === "Success") {
      return ["SUCCEEDED"];
    }
    if (state === "Disputed") {
      return ["DISPUTED_OPEN", "DISPUTED_CLOSED"];
    }
  });
  const { startDate, endDate } = timerange
    ? formatDateRockset(timerange)
    : { startDate: "", endDate: "" };
  const response = await fetch(rockset_transactions_top_emails_query_url, {
    method: "POST",
    headers: {
      Authorization: `ApiKey ${apikey}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      parameters: [
        {
          name: "amountEquals",
          type: "float",
          value: amountSpecific === "" ? "-1" : amountSpecific,
        },
        {
          name: "amountGreaterThan",
          type: "float",
          value: amountAtLeast === "" ? "-1" : amountAtLeast,
        },
        {
          name: "amountLessThan",
          type: "float",
          value: amountNoMoreThan === "" ? "-1" : amountNoMoreThan,
        },
        { name: "endDate", type: "string", value: endDate },
        { name: "frequency", type: "string", value: "" },
        // { name: "formNames", type: "array", value: "" },
        // { name: "fundNames", type: "array", value: "" },
        {
          name: "keyword",
          type: "string",
          value: "%" + keywordSearchTerm + "%",
        },
        { name: "name", type: "string", value: "" },
        {
          name: "paymentMethods",
          type: "array",
          value: JSON.stringify(paymentMethods),
        },
        { name: "startDate", type: "string", value: startDate },
        {
          name: "states",
          type: "array",
          value: JSON.stringify(states),
        },
        {
          name: "merchantNames",
          type: "array",
          value: JSON.stringify(merchants),
        },
      ],
    }),
  });

  const queryResponse = await response.json();
  console.log("queryResponse forms", queryResponse.results);
  return queryResponse.results.map((result) => result.email);
}
