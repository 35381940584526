import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  readOnly: true, // the actual value of the read only flag
  readOnlySet: false, // used to determine if the value has been set already before
};

export const readOnly = createSlice({
  name: "readOnly",
  initialState,
  reducers: {
    setReadOnly: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.readOnly = action.payload;
      state.readOnlySet = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setReadOnly } = readOnly.actions;

export default readOnly.reducer;
