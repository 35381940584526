/* This example requires Tailwind CSS v2.0+ */
import { useState } from "react";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Toggle(props) {
  const onChangeHandlerWithIndex = (value) => {
    props.setEnabled(value, props.dataIndex);
  };

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={props.enabled}
        // only use onChangeHandlerWithIndex if it's needed, like for a list of toggles in custom inputs for forms
        onChange={
          props.dataIndex !== undefined
            ? onChangeHandlerWithIndex
            : props.setEnabled
        }
        className={classNames(
          props.enabled ? "bg-primary" : "bg-gray-200",
          "focus:outline-none focus:ring-secondary relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-offset-2"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            props.enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3">
        <span className="text-sm font-medium text-gray-900">{props.name}</span>
        {/* <span className="text-sm text-gray-500">(Save 10%)</span> */}
      </Switch.Label>
    </Switch.Group>
  );
}
