import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./phone.css";
import parsePhoneNumber from "libphonenumber-js";
import S3Upload from "../../uploads/S3Upload";
import { Storage, Amplify } from "aws-amplify";
import awsconfig from "../../../aws-exports";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import LogoUploader from "../../uploads/LogoUploader";
import ErrorAlert from "../../alerts/ErrorAlert";
import PayPalLogo from '../../../assets/paypal.png';
import PaypalOnboarding from "../../integrations/Paypal/PaypalOnboarding";

function Processors(props) {
  const { merchant, grabBaseMerchant } = props;

  const [merchantName, setMerchantName] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [jwtToken, setJwtToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  useEffect(() => {
    if (props.merchant && props.merchant["merchantName"]) {
      let merchantName = props.merchant["merchantName"];
      if (merchant.merchantName) {
        setMerchantName(merchant.merchantName);
      }
    }
  }, [merchantName]);

  useEffect(() => {
    Amplify.configure(awsconfig);

    if (props.merchant && props.merchant["merchantName"]) {
      let merchantName = props.merchant["merchantName"];
    }

    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
  }, [merchant]);




  return (
    <div>
      <div className="pb-6 text-2xl text-black">Processors</div>
      <div className="flex"> {/* This flex container will hold all button boxes, left-aligned */}
        <div className="border hover:shadow-lg transition-shadow duration-200 rounded-lg overflow-hidden flex flex-col items-center p-4 w-52"> {/* Adjust w-52 to control the width */}
          <PaypalOnboarding
            merchant={props.merchant}
            jwtToken={props.jwtToken}

          />
        </div>
      </div>
    </div>
  );
}

export default Processors;
