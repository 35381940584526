import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import mainLogo from "./header/logo.png";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Header from "../partials/Header";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const refreshPage = async () => {
  try {
    await Auth.signOut();
    window.location.reload();
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

const eventOrgs = [
  "Dev",
  "Thabaat",
  "Muslim Coogs Alumni Network",
  "Nona Muslim Community Association Inc",
  "Muslim Longhorns Alumni Association",
  "Guided Hearts Institute",
  "Nueces Mosque Operations",
  ".NGO Charities",
  "Amaly",
  "Hamd Institute",
  "Texas Muslimahs",
  "Detroit Muslim Running Club",
  "Ihya Foundation",
  "Alpha Lambda Mu Foundation",
  "An Nisa Hope Center",
  "Khalil Islamic Community",
  "Pillars by Hamd",
  "Salaam Foundation",
  "Aghosh AlKhidmat USA",
  "Al Noor Muslim Community Center",
  "Bayan",
  "Guardians Foundation International",
  "Middle Eastern Immigrant and Refugee Alliance (MIRA)",
  "Mihraab Foundation",
  "Rahma Worldwide Aid & Development",
  "The Giving Games Foundation",
  "The Preservers",
  "The SunBundle",
  "Two Point Five Fund",
  "Najam Institute Foundation Inc",
  "Yameen ATX",
  "Iswarah",
  "Kidneys Beyond Borders Inc",
  "Voices of Virtue",
];

function Sidebar({ sidebarOpen, setSidebarOpen, groups }) {
  {
    useEffect(() => {
      Auth.currentAuthenticatedUser({
        bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then((user) => setUser(user))
        .catch((err) => console.log(err));
    }, []);
    const [user, setUser] = useState("");

    const location = useLocation();
    const { pathname } = location;
    const page = pathname.split("/")[1];

    const trigger = useRef(null);
    const sidebar = useRef(null);

    // close on click outside
    useEffect(() => {
      const clickHandler = ({ target }) => {
        if (!sidebar.current || !trigger.current) return;
        if (
          !sidebarOpen ||
          sidebar.current.contains(target) ||
          trigger.current.contains(target)
        )
          return;
        setSidebarOpen(false);
      };
      document.addEventListener("click", clickHandler);
      return () => document.removeEventListener("click", clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
      const keyHandler = ({ keyCode }) => {
        if (!sidebarOpen || keyCode !== 27) return;
        setSidebarOpen(false);
      };
      document.addEventListener("keydown", keyHandler);
      return () => document.removeEventListener("keydown", keyHandler);
    });

    {
      /*
            If the readOnly variable is truthy (evaluates to true), it will return null. In other words, nothing will be rendered in this case.
            If the readOnly variable is falsy (evaluates to false), it will execute the code block enclosed in the parentheses ( and ). This code block can contain JSX elements or other JavaScript code that will be rendered or executed when readOnly is falsy.
            */
    }
    const { readOnly } = useSelector((state) => state.readOnly); // this contains the variable which youll use to check

    return (
      <div className="lg:w-64">
        {/* Sidebar backdrop (mobile only) */}
        <div
          className={`fixed inset-0 z-40 bg-transparent bg-opacity-30 transition-opacity duration-200 lg:z-auto lg:hidden ${
            sidebarOpen ? "opacity-100" : "pointer-events-none opacity-0"
          }`}
          aria-hidden="true"
        ></div>

        {/* Sidebar */}
        <div
          id="sidebar"
          ref={sidebar}
          className={`no-scrollbar absolute left-0 top-0  z-40 h-screen w-64 flex-shrink-0 transform overflow-y-scroll bg-gradient-to-r from-white via-white p-4 transition-transform duration-200 ease-in-out lg:static lg:left-auto lg:top-auto lg:translate-x-0 lg:overflow-y-auto ${
            sidebarOpen ? "translate-x-0" : "-translate-x-64"
          }`}
        >
          {/* Sidebar header */}
          <div className="mb-0 flex w-3/5 content-center justify-between  border-b border-gray-200 pr-3 sm:px-2">
            {/* Close button */}
            <button
              ref={trigger}
              className="text-gray-500 hover:text-gray-400 lg:hidden"
              onClick={() => setSidebarOpen(!sidebarOpen)}
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
            >
              <span className="sr-only">Close sidebar</span>
              <svg
                className="h-6 w-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
              </svg>
            </button>
            {/* Logo */}

            <NavLink
              exact
              to="/"
              className={` ${
                page === "" && "items-center hover:text-gray-200"
              }`}
            >
              <img
                className="z-0 h-full w-full pb-3 pr-0 pt-5"
                src={mainLogo}
                alt="CharityStack"
                style={{ width: "105%", height: "105%" }}
              />
            </NavLink>
          </div>

          {/* Links */}
          <div>
            {/* Main */}
            <div className="mt-3">
              <p className="text-sm text-gray-400">Main</p>
              <ul className="mt-1">
                {/* Home */}
                <li
                  className={`mb-0.5 rounded-sm px-3 py-1 last:mb-0 ${
                    page === "home" && "bg-transparent"
                  }`}
                >
                  <NavLink
                    exact
                    to="/"
                    activeClassName={`text-green-500`}
                    className={`text-green hover:text-green block transition duration-150 ${
                      page === "home" && "hover:text-gray-200"
                    }`}
                  >
                    <div className="flex flex-grow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                        />
                      </svg>
                      &nbsp;
                      <span className="text-sm font-medium">Home</span>
                    </div>
                  </NavLink>
                </li>

                {/* Donations */}
                <li
                  className={`mb-0.5 rounded-sm px-3 py-1 last:mb-0 ${
                    page === "donations" && "bg-transparent"
                  }`}
                >
                  <NavLink
                    exact
                    to="/transactions"
                    activeClassName={`text-green-500`}
                    className={`text-green hover:text-green block transition duration-150 ${
                      page === "donations" && "hover:text-gray-200"
                    }`}
                  >
                    <div className="flex flex-grow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      &nbsp;
                      <span className="text-sm font-medium">Transactions</span>
                    </div>
                  </NavLink>
                </li>

                {/* Contacts */}
                <li
                  className={`mb-0.5 rounded-sm px-3 py-1 last:mb-0 ${
                    page === "contacts" && "bg-transparent"
                  }`}
                >
                  <NavLink
                    exact
                    to="/contacts"
                    activeClassName={`text-green-500`}
                    className={`text-black-200 hover:text-green block transition duration-150 ${
                      page === "donations" && "hover:text-gray-200"
                    }`}
                  >
                    <div className="flex flex-grow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                      </svg>
                      &nbsp;
                      <span className="text-sm font-medium">Contacts</span>
                    </div>
                  </NavLink>
                </li>

                {/* Payouts */}

                <li
                  className={`mb-0.5 w-3/5 rounded-sm px-3 py-1 last:mb-0 ${
                    page === "events" && "bg-transparent"
                  }`}
                >
                  <NavLink
                    exact
                    to="/payouts"
                    activeClassName={`text-green-500`}
                    className={`text-black-200 hover:text-green block transition duration-150 ${
                      page === "donations" && "hover:text-gray-200"
                    }`}
                  >
                    <div className="flex flex-grow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                      </svg>
                      &nbsp;
                      <span className="text-sm font-medium">Payouts</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>

            {/* Raise */}
            <div className="mt-3">
              <p className="text-sm text-gray-400">Raise</p>
              <ul className="mt-1">
                {/* Fundraisers */}
                {!readOnly && (
                  <li
                    className={`mb-0.5 rounded-sm px-3 py-1 last:mb-0 ${
                      page === "funds" && "bg-transparent"
                    }`}
                  >
                    <NavLink
                      exact
                      to="/fundraisers"
                      activeClassName={`text-green-500`}
                      className={`text-black-200 hover:text-green block transition duration-150 ${
                        page === "donations" && "hover:text-gray-200"
                      }`}
                    >
                      <div className="flex flex-grow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                          />
                        </svg>
                        &nbsp;
                        <span className="text-sm font-medium">Fundraisers</span>
                      </div>
                    </NavLink>
                  </li>
                )}

                {/* Funds */}
                <li
                  className={`mb-0.5 rounded-sm px-3 py-1 last:mb-0 ${
                    page === "funds" && "bg-transparent"
                  }`}
                >
                  <NavLink
                    exact
                    to="/funds"
                    activeClassName={`text-green-500`}
                    className={`text-black-200 hover:text-green block transition duration-150 ${
                      page === "donations" && "hover:text-gray-200"
                    }`}
                  >
                    <div className="flex flex-grow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                        />
                      </svg>
                      &nbsp;
                      <span className="text-sm font-medium">Funds</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>

            {/* Connect */}
            {
              // only render if groups doesn't contain any match in eventOrgs
              groups.some((group) => eventOrgs.includes(group)) && (
                <div className="mt-3">
                  <p className="text-sm text-gray-400">Connect</p>
                  <ul className="mt-1">
                    {/* Events */}
                    {readOnly ? null : (
                      <li
                        className={`mb-0.5 w-3/5 rounded-sm px-3 py-1 last:mb-0 ${
                          page === "events" && "bg-transparent"
                        }`}
                      >
                        <NavLink
                          exact
                          to="/events"
                          activeClassName={`text-green-500`}
                          className={`text-black-200 hover:text-green block transition duration-150 ${
                            page === "donations" && "hover:text-gray-200"
                          }`}
                        >
                          <div className="flex flex-grow">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2Z"
                              />
                            </svg>
                            &nbsp;
                            <span className="text-sm font-medium">Events</span>
                          </div>
                        </NavLink>
                      </li>
                    )}

                    <li
                      className={`mb-0.5 w-3/5 rounded-sm px-3 py-1 last:mb-0 ${
                        page === "tickets" && "bg-transparent"
                      }`}
                    >
                      <NavLink
                        exact
                        to="/tickets"
                        activeClassName={`text-green-500`}
                        className={`text-black-200 hover:text-green block transition duration-150 ${
                          page === "donations" && "hover:text-gray-200"
                        }`}
                      >
                        <div className="flex flex-grow">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 0 0-2 2v3a2 2 0 1 1 0 4v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3a2 2 0 1 1 0-4V7a2 2 0 0 0-2-2H5Z"
                            />
                          </svg>
                          &nbsp;
                          <span className="text-sm font-medium">Tickets</span>
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              )
            }

            <div className="mb-1  mt-5 flex   w-3/5 border-t border-gray-200 pr-2 ">
              <ul className="mt-1">
                <li className={`  mb-0.5 rounded-sm py-1 last:mb-0`}>
                  <div className="flex flex-grow ">
                    <hr></hr>
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex w-full justify-center bg-transparent text-sm font-medium text-gray-700 ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          &nbsp;
                          <span className="text-sm font-medium">Account</span>
                          <ChevronDownIcon
                            className="-mr-1 ml-0 h-6 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="focus:outline-none absolute left-0 mt-2 w-56 origin-top-left rounded-md bg-transparent  shadow-lg">
                          <div className="py-1">
                            {!readOnly && (
                              <Menu.Item>
                                <NavLink
                                  exact
                                  to="/settings"
                                  className={`text-black-200 hover:text-green block transition duration-150 ${
                                    page === "donations" &&
                                    "hover:text-gray-200"
                                  }`}
                                >
                                  <div className="flex flex-grow">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="h-5 w-5"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                                      />
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                      />
                                    </svg>
                                    &nbsp;
                                    <span className="text-sm font-medium">
                                      Settings
                                    </span>
                                  </div>
                                </NavLink>
                              </Menu.Item>
                            )}
                          </div>
                          <div className="px-4 py-3">
                            <p className="text-sm">Signed in as</p>
                            <p className="truncate text-sm font-medium text-gray-900">
                              {user.username}
                            </p>
                          </div>
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={refreshPage}
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block w-full px-4 py-1 text-left text-sm"
                                  )}
                                >
                                  Sign Out
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </li>
              </ul>
            </div>

            <br></br>
            {/* Cards 
            <li className={`px-3 py-1 rounded-sm mb-0.5 last:mb-0 ${page === 'cards' && 'bg-transparent'}`}>
              <NavLink exact to="/comingsoon" className={`block text-black-200 hover:text-green transition duration-150 ${page === 'donations' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                    <path fill-rule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clip-rule="evenodd" />
                  </svg>
                  &nbsp;
                  <span className="text-sm font-medium">Cards</span>
                </div>
              </NavLink>
            </li>

*/}
            {/* Dashboard 
            <li className={`px-3 py-1 rounded-sm mb-0.5 last:mb-0 ${page === 'dashboard' && 'bg-transparent'}`}>
              <NavLink exact to="/" className={`block text-black-200 hover:text-green transition duration-150 ${page === 'dashboard' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="black">
                    <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                  </svg>
                  &nbsp;
                  <span className="text-sm font-medium">Dashboard</span>
                </div>
            </NavLink> 
            </li>
            */}
            {/*
            <div class="grid grid-cols-1 divide-y divide-grey-500">
              Banking 
              <li className={`px-3 py-1 rounded-sm mb-0.5 last:mb-0 ${page === 'banking' && 'bg-transparent'}`}>
                <NavLink exact to="/banking" className={`block text-black-200 hover:text-green transition duration-150 ${page === 'banking' && 'hover:text-gray-200'}`}>
                  <div className="flex flex-grow">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M10.496 2.132a1 1 0 00-.992 0l-7 4A1 1 0 003 8v7a1 1 0 100 2h14a1 1 0 100-2V8a1 1 0 00.496-1.868l-7-4zM6 9a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1zm3 1a1 1 0 012 0v3a1 1 0 11-2 0v-3zm5-1a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1z" clip-rule="evenodd" />
                    </svg>
                    &nbsp;
                    <span className="text-sm font-medium"> Banking</span>
                  </div>

                </NavLink>
              </li>
*/}
            {/* API 
              <li className={`px-3 py-1 rounded-sm mb-0.5 last:mb-0 ${page === 'api' && 'bg-transparent'}`}>
                <NavLink exact to="/comingsoon" className={`block text-black-200 hover:text-green transition duration-150 ${page === 'banking' && 'hover:text-gray-200'}`}>
                  <div className="flex flex-grow">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
                    </svg>
                    &nbsp;
                    <span className="text-sm font-medium"> API</span>
                  </div>

                </NavLink>
              </li>
            </div>
*/}
            {/* Settings 
            <li className={`px-3 py-1 rounded-sm mb-0.5 last:mb-0 ${page === 'settings' && 'bg-transparent'}`}>
              <NavLink exact to="/comingsoon" className={`block text-black-200 hover:text-green transition duration-150 ${page === 'banking' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                  </svg>
                  &nbsp;
                  <span className="text-sm font-medium"> Settings</span>
                </div>

              </NavLink>
            </li>
*/}
          </div>
        </div>
      </div>
    );
  }
}
export default Sidebar;
