import React, { useRef, useState, useEffect, useCallback } from "react";
import Dropdown from "../../dropdowns/Dropdown";
import MultiSelectDropdown from "../../dropdowns/MultiSelectDropdown";
import Toggle from "../../Toggle";
import ReactTooltip from "react-tooltip";
import { Draggable } from "react-beautiful-dnd";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

const TICKET_TYPE = {
  Single: "SINGLE",
  Group: "GROUP",
};

function CustomTicketsTabContent(props) {
  const {
    moveCard,
    CustomTicket,
    id,
    index,
    handleNameChange,
    handleTypeChange,
    handleSeatsChange,
    handlePriceChange,
    handleQuantityChange,
    removeCustomTicket,
  } = props;
  const [groups, setGroups] = useState(false);

  useEffect(() => {
    if (CustomTicket.type.toLowerCase() === "single") {
      setGroups(false);
    } else {
      setGroups(true);
    }
  }, []);

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className="border-b border-gray-300"
        >
          <div {...provided.dragHandleProps}>
            <Bars3Icon
              className="ml-auto mr-4 mt-2 h-6 w-6"
              aria-hidden="true"
            />
          </div>
          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6">
            <label
              htmlFor="form-name"
              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
              Ticket Name
            </label>
            <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
              <input
                data-type="title"
                data-index={index}
                value={CustomTicket.name}
                type="text"
                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                onChange={(e) => handleNameChange(e)}
                maxLength={50}
              />
            </div>
          </div>
          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-300 ">
            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 ">
              <label
                htmlFor="form-name"
                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
              >
                Ticket Type
                <svg
                  data-tip
                  data-for="typeTooltip"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="text-primary ml-1 inline-block h-5 w-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  />
                </svg>
                <ReactTooltip id="typeTooltip" effect="solid" place="right">
                  <p className="text-sm text-white">Select ticket type</p>
                </ReactTooltip>
              </label>
              <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
                <input
                  type="radio"
                  value="Single"
                  name="typeChoose"
                  data-type="title"
                  data-index={index}
                  onClick={(e) => {
                    setGroups(false);
                    handleTypeChange(e);
                  }}
                  style={{
                    backgroundColor: groups === false ? "green" : "white",
                    marginTop: "4px",
                    marginRight: "5px",
                  }}
                />
                Individual
                <input
                  type="radio"
                  value="Group"
                  name="typeChoose"
                  data-type="title"
                  data-index={index}
                  onClick={(e) => {
                    setGroups(true);
                    handleTypeChange(e);
                  }}
                  style={{
                    backgroundColor: groups === true ? "green" : "white",
                    marginTop: "4px",
                    marginRight: "5px",
                  }}
                />
                Group
              </div>
            </div>
          </div>

          <div className="sm:col-span-2">
            <Box sx={{ flexGrow: 1 }} className="px-7 pb-4">
              <Stack
                container
                direction="row"
                alignItems="flex-start"
                spacing={12}
              >
                <div>
                  <label
                    htmlFor="form-name"
                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Price
                  </label>
                  <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
                    <input
                      data-type="price"
                      data-index={index}
                      value={CustomTicket.price}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      step="any"
                      pattern="d\+\.\d\d$"
                      className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                      onChange={(e) => handlePriceChange(e)}
                    />
                  </div>
                </div>
                <div className="">
                  <label
                    htmlFor="form-name"
                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Quantity
                  </label>
                  <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
                    <input
                      data-type="title"
                      data-index={index}
                      value={CustomTicket.quantity}
                      type="number"
                      pattern="[0-9]*"
                      onWheel={(e) => e.target.blur()}
                      className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                      onChange={(e) => handleQuantityChange(e)}
                    />
                  </div>
                </div>
              </Stack>
            </Box>
          </div>
          {groups === true && (
            <>
              <div className=" px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6">
                <label
                  htmlFor="form-name"
                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                >
                  Group Seats
                  <svg
                    data-tip
                    data-for="seatsTooltip"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="text-primary ml-1 inline-block h-5 w-5"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <ReactTooltip id="seatsTooltip" effect="solid" place="right">
                    <p className="text-sm text-white">
                      Select how many seats per group ticket.
                    </p>
                  </ReactTooltip>
                </label>
                <div className="sm:col-span-3">
                  <input
                    data-type="seats"
                    data-index={index}
                    value={CustomTicket.seats}
                    onWheel={(e) => e.target.blur()}
                    type="number"
                    pattern="[0-9]*"
                    className="focus:border-secondary focus:ring-secondary block w-20 rounded-md border-gray-300 shadow-sm sm:text-sm"
                    onChange={(e) => handleSeatsChange(e)}
                  />
                </div>
              </div>
            </>
          )}

          <div className="mb-2 mr-4 flex justify-end">
            <div
              className="flex cursor-pointer"
              data-index={index}
              onClick={(e) => removeCustomTicket(index)}
            >
              <TrashIcon
                data-index={index}
                className="mr-2 mt-0.5 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
              <div data-index={index} className="text-red-500">
                {" "}
                remove ticket{" "}
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

export default CustomTicketsTabContent;
