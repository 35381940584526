/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dropdown(props) {
  const menuButtonClass =
    `inline-flex content-center rounded-md justify-start border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 focus:ring-offset-gray-100 ${
      props.additionalMenuButtonClasses
        ? props.additionalMenuButtonClasses + " "
        : ""
    }` + props.width;
  const menuItemsClass =
    "absolute content-center right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none " +
    props.width;
  return (
    <Menu
      as="div"
      className={"relative inline-block text-center " + props.width}
    >
      <div>
        <Menu.Button className={menuButtonClass}>
          {props.selectedValue}
          <ChevronDownIcon
            className="absolute right-2	 ml-2 mr-1 h-5 w-5 content-center"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={menuItemsClass}>
          <div className="py-1 text-left">
            {props.values.map((value, index) => {
              return (
                <Menu.Item>
                  {({ active }) => (
                    <a
                      // **********
                      // These data attributes are used to identify the selected value for list items like in custom inputs for forms
                      data-value={value}
                      data-index={
                        props.dataIndex !== undefined
                          ? props.dataIndex
                          : undefined
                      }
                      // **********
                      onClick={(e) => {
                        // using regular dropdown
                        if (props.dataIndex == undefined) {
                          props.setSelectedValue(value);
                        }
                        // using data attributes
                        else {
                          props.setSelectedValue(e);
                        }
                      }}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      {value}
                    </a>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
