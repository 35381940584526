import React, { useState } from 'react'
import Dropdown from '../dropdowns/Dropdown'

function BankTransfer(props) {
    const { accountNumber, setAccountNumber, routingNumber,
        setRoutingNumber, accountType, setAccountType } = props

    return (
        <div className="space-y-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5 items-center">
            <div>
                <label
                    htmlFor="account-number"
                    className="flex text-sm font-medium text-gray-900 col-span-1"
                >
                    Account Number
                </label>
            </div>
            <div className="sm:col-span-2">
                <input
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value.replace(/\D/g, ''))}
                    type="text"
                    name="account-number"
                    id="account-number"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                />
            </div>
            <div>
                <label
                    htmlFor="routing-number"
                    className="flex text-sm font-medium text-gray-900 col-span-1"
                >
                    Routing Number
                </label>
            </div>
            <div className="sm:col-span-2">
                <input
                    value={routingNumber}
                    onChange={(e) => setRoutingNumber(e.target.value.replace(/\D/g, ''))}
                    type="text"
                    name="routing-number"
                    id="routing-number"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                />
            </div>
            <div>
                <label
                    htmlFor="routing-number"
                    className="flex text-sm font-medium text-gray-900 col-span-1"
                >
                    Account Type
                </label>
            </div>
            <div className='sm:col-span-2'>
                <div className="block grow">
                    <Dropdown selectedValue={accountType} setSelectedValue={setAccountType} values={['CHECKING', 'SAVINGS']} width={'w-64'}/>
                </div>
            </div>
        </div>
    )
}

export default BankTransfer