import React from "react";
import image from "../../../assets/double-the-donation-1.png";
import DoubleTheDonationApiKeyModal from "./DoubleTheDonationApiKeyModal";

function DoubleTheDonationIntegration(props) {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <div>
      <div className="grid h-full grid-rows-3 items-center">
        <img
          src={image}
          alt="DtD"
          className="row-span-1 h-12 justify-self-center"
        />
        <div className="row-span-1 justify-self-center ">
          Double The Donation
        </div>
        <button
          onClick={() => setShowModal(true)}
          type="button"
          className="row-span-1 w-24 justify-self-center rounded-lg bg-green-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-600 focus:ring-4 focus:ring-green-300"
        >
          Connect
        </button>
      </div>
      <DoubleTheDonationApiKeyModal
        open={showModal}
        setOpen={setShowModal}
        merchant={props.merchant}
        setSuccessMessage={props.setSuccessMessage}
        setShowSuccessAlert={props.setShowSuccessAlert}
      />
    </div>
  );
}

export default DoubleTheDonationIntegration;
