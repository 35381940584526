// master version
import React, { Component, useState, useEffect, useMemo } from "react";
import decompressData from "../../utils/decompressData";
import pako from "pako";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import axios from "axios";
import {
  CheckIcon,
  SelectorIcon,
  ChevronDownIcon,
} from "@heroicons/react/solid";
import DonationDatePicker from "./DonationDatePicker";
import { Popover, Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import fetchTransactionData from "../../utils/FetchTransactionData";
import DonorSlideover from "../slideovers/DonorSlideover";
import { useSelector, connect } from "react-redux";
import readOnly from "../../redux/slices/readOnly";
import ContentLoader from "react-content-loader";

const mapStateToProps = (state) => {
  return {
    readOnly: state.readOnly, // Assuming `readOnly` is a property in your Redux store
  };
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Define your columns and their respective widths as a percentage of the total table width
const columns = [
  { width: "40%" }, // Adjust the widths based on your design
  { width: "22%" },
  { width: "20%" },
];

const numberOfRows = 50;
const rowHeight = 22;
const spaceBetweenRows = 15;
const totalHeight = numberOfRows * (rowHeight + spaceBetweenRows);
const numberOfGaps = columns.length + 1; // One less gap than the number of columns
const totalSpacingPercent = 70; // Total percentage of width allocated for spacing
const singleSpacingPercent = totalSpacingPercent / numberOfGaps; // Percentage of each gap

// Adjusted column widths to account for the spacing
const adjustedColumns = columns.map((col, index) => {
  const originalWidth = parseFloat(col.width);
  const adjustedWidth = (
    originalWidth -
    (singleSpacingPercent * (columns.length - 1)) / columns.length
  ).toFixed(2);
  return { ...col, width: `${adjustedWidth}%` };
});

const calculateXPosition = (colIndex, spacingPercent) => {
  // No offset for the first column
  if (colIndex === 0) return 1;
  // Calculate the sum of all previous column widths
  const widthSum = adjustedColumns
    .slice(0, colIndex)
    .reduce((acc, col) => acc + parseFloat(col.width), 0);

  // Calculate the total spacing to add based on the column index
  const totalSpacing = (colIndex - 1) * spacingPercent + 32; // -1 because no space before first column

  // Sum the width and spacing to get the starting x position
  return widthSum + totalSpacing;
};

const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
let today = new Date();
// increment today forward by 1 day
today.setDate(today.getDate() + 1);
let tomorrow = new Date();
tomorrow.setDate(today.getDate() - 6000);
const startTimerange =
  tomorrow.toLocaleDateString("en-US", DATE_OPTIONS) +
  " - " +
  today.toLocaleDateString("en-US", DATE_OPTIONS);

export class DonationsTable extends Component {
  constructor(props) {
    super(props);

    const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
    let today = new Date();
    // increment today forward by 1 day
    today.setDate(today.getDate() + 1);
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() - 6000);
    const startTimerange =
      tomorrow.toLocaleDateString("en-US", DATE_OPTIONS) +
      " - " +
      today.toLocaleDateString("en-US", DATE_OPTIONS);
    this.state = {
      jwtToken: "",
      donationList: [],
      start: 0,
      end: 0,
      total: 0,
      totalDonation: 0,
      avgDonation: 0,
      totalDonors: 0,
      duplicate: 0,
      grossAmountList: [],
      totalAmountList: [],
      showFilter: false,
      filteredFund: "",
      filteredFundList: [],
      filteredDonor: "",
      filteredDonorList: [],
      filteredPaymentType: [],
      filteredState: [],
      filteredFeeCovered: "",
      filteredOperator: "=",
      filteredAmount: 0, // This is the actual "set" value that we filter on. We only set this when the box becomes out of focus
      filteredAmountDisplayed: "", // this value is needed because we need to populate text box
      filteredAmountSet: false, // This is to determine if the user ever set the value initially
      filteredKeywordSet: false, // This is to determine if the user ever set the value initially
      timerange: startTimerange,
      whoIsLoggedIn: "",
      listOfAllPossibleFunds: [],
      whoIsLoggedIn: "",
      showDateFilter: false,
      showKeywordFilter: true,
      showAmountFilter: false,
      showMethodFilter: false,
      showFrequencyFilter: false,
      resetFilter: false,
      keywordSearchTerm: "",
      amountSpecific: "",
      amountNoMoreThan: "",
      amountAtLeast: "",
      filteredList: [],
      loading: true,
      originalList: [],
      recentFundsText: "Recent Funds",
      recentDonorsText: "Recent Donors",
      allO: 0,
      allD: 0,
      allM: 0,
      allR: 0,
      timerange: startTimerange,
      showFilter: false,
      open: false,
      selectedDonor: null,
      selectionCriteria: "",
      selectionCriteriaRender: [],
      selectedDonorSubscriptions: [],
      donorSubscriptions: [],

      savedFilterIndicatorStatus: false,
      savedFilterIndicatorAmount: false,
      savedFilterIndicatorKeywordFund: false,
      savedFilterIndicatorKeywordDonor: false,
    };
  }

  capitalizeFirstLetter(string) {
    const words = string.split(" ");

    for (let i = 0; i < words.length; i++) {
      if (words[i].length > 1) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      } else if (words[i].length == 1) {
        words[i] = words[i].charAt(0).toUpperCase();
      }
    }

    return words.join(" ");
  }

  setOpen = (toggle) => {
    this.setState({ open: toggle });
  };

  openSlideOver = (key) => {
    const { donorsList, donorSubscriptions } = this.state;
    const email = key.split(" | ")[0];
    const name = key.split(" | ")[1];
    console.log("hello the value of key is ", key);
    this.setOpen(true);
    const donorInfo = donorsList.filter(
      (donor) => donor.email === email && donor.name === name
    )[0];
    console.log("d", donorInfo);
    let selectedDonorSubscriptions = null;
    if (donorInfo.donorType === "Recurring") {
      selectedDonorSubscriptions = donorSubscriptions.get(key);
      //     if (donorInfo.donationFrequency === 'MONTHLY')
      //   console.log("hello the value of selectedDonorSubsciropton is ", selectedDonorSubscriptions)
    }

    this.setState({
      selectedDonor: donorInfo,
      selectedDonorSubscriptions: selectedDonorSubscriptions,
    });
  };

  onResetFilter = () => {
    const { originalList } = this.state;
    this.setState(
      {
        filteredKeywordSet: true,
        resetFilter: true,
        showDateFilter: false,
        showKeywordFilter: true,
        showAmountFilter: false,
        showMethodFilter: false,
        showFrequencyFilter: false,
        filteredFund: "",
        filteredFundList: [],
        filteredDonor: "",
        filteredDonorList: [],
        filteredPaymentType: [],
        filteredState: [],
        keywordSearchTerm: "",
        filteredFeeCovered: "",
        filteredOperator: "=",
        amountSpecific: "",
        amountNoMoreThan: "",
        recentFundsText: "Recent Funds",
        recentDonorsText: "Recent Donors",
        amountAtLeast: "",
        showFilter: false,
        timerange: startTimerange,
        loading: true,

        savedFilterIndicatorStatus: false,
        savedFilterIndicatorAmount: false,
        savedFilterIndicatorKeywordFund: false,
        savedFilterIndicatorKeywordDonor: false,
      },
      () => {
        this.updateData();
      }
    );
  };

  onSavedIndicatorKeywordClick = () => {
    const { originalList } = this.state;
    this.setState(
      {
        filteredFund: "",
        filteredFundList: [],
        filteredDonor: "",
        filteredDonorList: [],
        keywordSearchTerm: "",
        loading: true,

        savedFilterIndicatorKeywordFund: false,
        savedFilterIndicatorKeywordDonor: false,
      },
      () => {
        this.updateData();
      }
    );
  };

  onSavedIndicatorStatusClick = () => {
    this.setState(
      {
        filteredState: [],
        loading: true,
        savedFilterIndicatorStatus: false,
      },
      () => {
        this.updateData();
      }
    );
  };
  onSavedIndicatorAmountClick = () => {
    const { originalList } = this.state;
    this.setState(
      {
        amountSpecific: "",
        amountNoMoreThan: "",
        amountAtLeast: "",
        loading: true,
        savedFilterIndicatorAmount: false,
      },
      () => {
        this.updateData();
      }
    );
  };

  onFilterDateFilter = () => {
    if (this.state.showDateFilter === true) {
    } else {
      this.setState({
        showDateFilter: false,
        showKeywordFilter: true,
        showAmountFilter: false,
        showMethodFilter: false,
        showFrequencyFilter: false,
      });
    }
  };
  onFilterKeywordFilter = () => {
    this.setState({
      showKeywordFilter: true,
      showAmountFilter: false,
      showMethodFilter: false,
      showFrequencyFilter: false,
      showDateFilter: false,
    });
  };
  onFilterAmountFilter = () => {
    this.setState({
      showAmountFilter: true,
      showKeywordFilter: false,
      showMethodFilter: false,
      showFrequencyFilter: false,
      showDateFilter: false,
    });
  };
  onFilterMethodFilter = () => {
    this.setState({
      showMethodFilter: true,
      showAmountFilter: false,
      showKeywordFilter: false,
      showFrequencyFilter: false,
      showDateFilter: false,
    });
  };

  onFilterFrequencyFilter = () => {
    this.setState({
      showFrequencyFilter: true,
      showAmountFilter: false,
      showMethodFilter: false,
      showKeywordFilter: false,
      showDateFilter: false,
    });
  };

  onFilterClickHandler = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  onFilteredAmountDisplayedClickHandler = (e) => {
    e.preventDefault();
    this.setState(
      {
        filteredAmountDisplayed: e.target.value,
      },
      () => this.updateFilter()
    );
  };

  onFilteredAmountClickHandler = (e) => {
    e.preventDefault();
    this.setState(
      {
        filteredAmount: +e.target.value,
        filteredAmountSet: true,
      },
      () => this.updateFilter()
    );
  };

  onFilteredOperatorClickHandler = (e) => {
    e.preventDefault();
    this.setState(
      {
        filteredOperator: e.target.value,
      },
      () => this.updateFilter()
    );
  };

  onKeywordSearchFilter = (e) => {
    e.preventDefault();
    if (e.target.value.length === 0 || e.target.value == "") {
      this.setState({
        keywordSearchTerm: "",
        recentFundsText: "Recent Funds",
        recentDonorsText: "Recent Donors",
      });
    } else {
      this.setState(
        {
          keywordSearchTerm: e.target.value,
          recentFundsText: "Funds",
          recentDonorsText: "Donors",
        },
        () => this.updateFilter()
      );
    }
  };

  onFilteredFundClickHandler = (e) => {
    //    e.preventDefault();
    let newFundValue = this.state.filteredFundList;

    if (newFundValue.includes(e.target.value)) {
      newFundValue.splice(newFundValue.indexOf(e.target.value), 1);
      let tempSavedFilterIndicatorKeywordFund =
        newFundValue.length == 0 ? false : true;
      this.setState(
        {
          filteredFundList: newFundValue,
          savedFilterIndicatorKeywordFund: tempSavedFilterIndicatorKeywordFund,
        },
        () => this.updateFilter()
      );
    } else {
      newFundValue[newFundValue.length] = e.target.value;
      this.setState(
        {
          filteredFundList: newFundValue,
          savedFilterIndicatorKeywordFund: true,
        },
        () => this.updateFilter()
      );
    }
  };

  onFilteredFundDonorClickHandler = (e) => {
    //    e.preventDefault();
    let newFundValue = this.state.filteredDonorList;
    if (newFundValue.includes(e.target.value.toLowerCase())) {
      newFundValue.splice(
        newFundValue.indexOf(e.target.value.toLowerCase()),
        1
      );
      let tempSavedFilterIndicatorKeywordDonor =
        newFundValue.length == 0 ? false : true;
      this.setState(
        {
          filteredDonorList: newFundValue,
          savedFilterIndicatorKeywordDonor:
            tempSavedFilterIndicatorKeywordDonor,
        },
        () => this.updateFilter()
      );
    } else {
      newFundValue[newFundValue.length] = e.target.value.toLowerCase();
      this.setState(
        {
          filteredDonorList: newFundValue,
          savedFilterIndicatorKeywordDonor: true,
        },
        () => this.updateFilter()
      );
    }
  };

  onFilteredPaymentTypeClickHandler = (e) => {
    //   e.preventDefault();
    let newFundValue = this.state.filteredPaymentType;
    if (newFundValue.includes(e.target.value)) {
      newFundValue.splice(newFundValue.indexOf(e.target.value), 1);
      this.setState(
        {
          filteredPaymentType: newFundValue,
        },
        () => this.updateFilter()
      );
    } else {
      newFundValue[newFundValue.length] = e.target.value;
      this.setState(
        {
          filteredPaymentType: newFundValue,
        },
        () => this.updateFilter()
      );
    }
  };

  onFilteredStateClickHandler = (e) => {
    const { selectionCriteria } = this.state;
    let tempSelectionCriteria = selectionCriteria + " Frequency ";
    //   e.preventDefault();
    let newFundValue = this.state.filteredState;
    if (newFundValue.includes(e.target.value)) {
      newFundValue.splice(newFundValue.indexOf(e.target.value), 1);
      const tempSavedFilterIndicatorStatus =
        newFundValue.length == 0 ? false : true;
      this.setState(
        {
          filteredState: newFundValue,
          selectionCriteria: tempSelectionCriteria,
          savedFilterIndicatorStatus: tempSavedFilterIndicatorStatus,
        },
        () => this.updateFilter()
      );
    } else {
      newFundValue[newFundValue.length] = e.target.value;
      this.setState(
        {
          filteredState: newFundValue,
          selectionCriteria: tempSelectionCriteria,
          savedFilterIndicatorStatus: true,
        },
        () => this.updateFilter()
      );
    }
  };

  amountFilterChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    value = value.replace(/[^0-9.].*/g, "");

    //allows only 1 decimal
    let decimalCount = (value.match(/\./g) || []).length;
    if (decimalCount == 2) {
      let index = value.lastIndexOf(".");
      value = value.substr(0, index);
    }
    value = value.replace(/(\.\d{2})\d{1,}/g, "$1");

    if (value === ".") {
      value = "0.";
    }
    this.setState(
      {
        [e.target.name]: value,
      },
      () => this.updateFilter()
    );
  };

  onFilteredFeeCoveredClickHandler = (e) => {
    e.preventDefault();
    this.setState({
      filteredFeeCovered: e.target.text,
    });
  };

  async componentDidMount() {
    const data = await Auth.currentSession();
    this.setState(
      {
        jwtToken: data["idToken"]["jwtToken"],
      },
      () => this.updateData()
    );
  }

  convertTimestamp(timestamp) {
    const sections = timestamp.split("-");
    return sections[1] + "-" + sections[2] + "-" + sections[0];
  }

  setDateTimeRange = (timerange) => {
    this.setState(
      {
        timerange: timerange,
      },
      () => this.updateData()
    );
  };

  updateFilter = () => {
    const {
      filteredState,
      filteredFeeCovered,
      resetFilter,
      filteredFundList,
      filteredDonorList,
      keywordSearchTerm,
      savedFilterIndicatorAmount,
      filteredPaymentType,
      amountAtLeast,
      amountSpecific,
      amountNoMoreThan,
      donationList,
      loading,
      donorsList,
      selectionCriteria,
      selectionCriteriaRender,
    } = this.state;

    let grossAmountList = [];
    let totalAmountList = [];
    let setOfFunds = new Set();
    let setOfDonors = new Set();
    let listOfAllPossibleFunds = [];
    let listOfAllPossibleDonors = [];
    let listOfAllRenders = [];
    //let filteredList = res
    let filteredList = donorsList;

    // sort in backwards order
    filteredList.sort((a, b) => {
      return new Date(b.timestamp) - new Date(a.timestamp);
    });

    if (selectionCriteria !== "") {
      if (selectionCriteria.includes("Frequency")) {
        selectionCriteriaRender.push("Frequency");
        listOfAllRenders.push("Frequency");
      }
      if (selectionCriteria.includes("Amount")) {
        selectionCriteriaRender.push("Amount");
        listOfAllRenders.push("Amount");
      }
      if (selectionCriteria.includes("Keyword")) {
        selectionCriteriaRender.push("Keyword");
        listOfAllRenders.push("Keyword");
      }
    }
    //   console.log(selectionCriteriaRender)
    if (filteredPaymentType.length !== 0) {
      let filteredPaymentTypeHolder = [];
      for (var i = 0; i < filteredPaymentType.length; i++) {
        filteredPaymentTypeHolder[i] = filteredPaymentType[i]
          .replaceAll(" ", "_")
          .toUpperCase();
      }
      filteredList = filteredList.filter((t) =>
        filteredPaymentTypeHolder.includes(t.paymentMethod)
      );
      //      filteredList = filteredList.filter(t => t.paymentMethod === filteredPaymentTypeHolder.replace(' ', '_').toUpperCase());
    }
    if (filteredFeeCovered !== "") {
      filteredList = filteredList.filter(
        (t) => t.coverFees === filteredFeeCovered
      );
    }
    if (filteredState.length !== 0) {
      filteredList = filteredList.filter((t) =>
        filteredState.includes(t.donorType)
      );
    }
    if (keywordSearchTerm !== "") {
      filteredList = filteredList.filter((t) => {
        return (
          t.name.toLowerCase().includes(keywordSearchTerm.toLowerCase()) ||
          t.email.toLowerCase().includes(keywordSearchTerm.toLowerCase())
          // t.fund.toLowerCase().includes(keywordSearchTerm.toLowerCase())
        );
      });
    }
    let atLeastOneAmountPresent = false;
    if (amountAtLeast !== "") {
      atLeastOneAmountPresent = true;
      let parsedAmountFilter = parseFloat(amountAtLeast);
      filteredList = filteredList.filter(
        (t) => t.grossAmountVar >= parsedAmountFilter
      );
      this.setState({
        savedFilterIndicatorAmount: true,
      });
    }
    if (amountNoMoreThan !== "") {
      atLeastOneAmountPresent = true;
      let parsedAmountFilter = parseFloat(amountNoMoreThan);
      filteredList = filteredList.filter(
        (t) => t.grossAmountVar <= parsedAmountFilter
      );
      this.setState({
        savedFilterIndicatorAmount: true,
      });
    }
    if (amountSpecific !== "") {
      atLeastOneAmountPresent = true;
      let parsedAmountFilter = parseFloat(amountSpecific);
      filteredList = filteredList.filter(
        (t) => t.grossAmountVar == parsedAmountFilter
      );
      this.setState({
        savedFilterIndicatorAmount: true,
      });
    }
    if (!atLeastOneAmountPresent) {
      this.setState({
        savedFilterIndicatorAmount: false,
      });
    }

    for (let index = 0; index < filteredList.length; index++) {
      // if (
      //   !setOfFunds.has(filteredList[index].fund) &&
      //   listOfAllPossibleFunds.length < 5
      // ) {
      //   if (
      //     keywordSearchTerm === "" ||
      //     (keywordSearchTerm !== "" &&
      //       filteredList[index].fund
      //         .toLowerCase()
      //         .includes(keywordSearchTerm.toLowerCase()))
      //   ) {
      //     listOfAllPossibleFunds.push(filteredList[index].fund);
      //     setOfFunds.add(filteredList[index].fund);
      //   }
      // }
      const tickets = filteredList[index].tickets ?? [];
      const funds = filteredList[index].funds ?? [];
      if (tickets.length === 0) {
        if (funds.length == 0) {
          if (
            !setOfFunds.has(filteredList[index].fund) &&
            listOfAllPossibleFunds.length < 5
          ) {
            if (
              keywordSearchTerm === "" ||
              (keywordSearchTerm !== "" &&
                filteredList[index].fund &&
                filteredList[index].fund
                  .toLowerCase()
                  .includes(keywordSearchTerm.toLowerCase()))
            ) {
              listOfAllPossibleFunds.push(filteredList[index].fund);
              setOfFunds.add(filteredList[index].fund);
            }
          }
        } else {
          for (
            let fundIndex = 0;
            fundIndex < filteredList[index].funds.length;
            fundIndex++
          ) {
            const fund = filteredList[index].funds[fundIndex].fundName;
            if (!setOfFunds.has(fund) && listOfAllPossibleFunds.length < 5) {
              if (
                keywordSearchTerm === "" ||
                (keywordSearchTerm !== "" &&
                  fund.toLowerCase().includes(keywordSearchTerm.toLowerCase()))
              ) {
                listOfAllPossibleFunds.push(fund);
                setOfFunds.add(fund);
              }
            }
          }
        }
      }
      if (
        !setOfDonors.has(filteredList[index].name) &&
        listOfAllPossibleDonors.length < 5
      ) {
        if (
          keywordSearchTerm === "" ||
          (keywordSearchTerm !== "" &&
            filteredList[index].name
              .toLowerCase()
              .includes(keywordSearchTerm.toLowerCase()))
        ) {
          listOfAllPossibleDonors.push(filteredList[index].name);
          setOfDonors.add(filteredList[index].name);
        }
      }
    }
    console.log("listOfAllPossibleFunds", listOfAllPossibleFunds);
    this.setState({
      filteredList: filteredList,
      totalAmountList: totalAmountList,
      grossAmountList: grossAmountList,
      listOfAllPossibleFunds: listOfAllPossibleFunds,
      listOfAllPossibleDonors: listOfAllPossibleDonors,
      loading: false,
      timerange: startTimerange,
    });
  };

  checkMonthlyCanceled = (donation, donorSubscriptions, frequency) => {
    let subscriptionKey =
      donation.email.toLowerCase() + " | " + donation.name.toLowerCase();
    let subscriptions = donorSubscriptions.get(subscriptionKey);
    if (subscriptions) {
      let allCanceled = true;
      subscriptions.forEach((subscription) => {
        if (subscription.subscriptionStatus !== "DEACTIVATED") {
          allCanceled = false;
        }
      });
      if (allCanceled) {
        frequency = "Canceled";
      }
    }
    return frequency;
  };

  getMultiFundDonationFreq = (donation) => {
    let donorStatus = "One-Time";
    let funds = donation.funds;
    if (funds !== undefined && funds !== null && funds.length > 0) {
      // iterate through funds and asses the frequency. if the frequency is not one-time, then the donor is a repeat donor
      for (let i = 0; i < funds.length; i++) {
        if (funds[i].frequency !== "ONE_TIME") {
          return "Recurring";
        }
      }
    }
    return "One-Time";
  };

  updateData = async () => {
    const { timerange, jwtToken } = this.state;

    // Grab donor data below

    const donorRequestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
    };

    const donorURL = `${
      process.env.REACT_APP_DONORS_URL
    }/?timerange=${encodeURIComponent(timerange)}`;

    const donorURLResponse = await fetch(donorURL, donorRequestOptions);
    let decompressed_data = await donorURLResponse.json();
    //console.log("DECOMPRESSED DATA", decompressed_data);
    let donorData = decompressData(decompressed_data);

    const donorSubscriptions = new Map();
    donorData.forEach((donor) => {
      let key = donor.email.toLowerCase() + " | " + donor.name.toLowerCase();
      if (donorSubscriptions.has(key)) {
        donorSubscriptions.get(key).push(donor);
      } else {
        donorSubscriptions.set(key, [donor]);
      }
    });

    // Grab transaction data below

    const requestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },

      body: JSON.stringify({
        timerange: timerange,
      }),
    };
    const url = process.env.REACT_APP_DONATIONS_URL;
    let res = [];
    let lastEvaluatedKeys = {};
    while (true) {
      // fetch data
      let response = await fetch(url, requestOptions);
      // get response in json
      let response_json = await response.json();
      // decompress data
      let decompressed_data = decompressData(response_json);
      //console.log("DECOMPRESSED DATA", decompressed_data);
      // extend res to include decompressed_data['transactions']
      res = res.concat(decompressed_data["transactions"]);
      lastEvaluatedKeys = decompressed_data["lastEvaluatedKeys"];

      // break if lastEvaluatedKeys is an object of size 0
      if (
        lastEvaluatedKeys === undefined ||
        lastEvaluatedKeys === null ||
        Object.keys(lastEvaluatedKeys).length === 0
      ) {
        console.log("no more last evaluated key");
        break;
      }

      console.log("has lastEvaluatedKeys", lastEvaluatedKeys);
      // update body to include lastEvaluatedKey
      requestOptions.body = JSON.stringify({
        timerange: timerange,
        lastEvaluatedKeys: lastEvaluatedKeys,
      });
    }

    let end = 20;
    if (res.length < 20) {
      end = res.length;
    }
    let grossAmountList = [];
    let totalAmountList = [];

    for (let index = 0; index < res.length; index++) {
      if (
        res[index].donationFrequency === "ONE_TIME" &&
        this.getMultiFundDonationFreq(res[index]) !== "Recurring"
      ) {
        res[index].donationFrequency = "One-Time";
      } else if (
        res[index].donationFrequency == "MONTHLY" ||
        res[index].donationFrequency == "WEEKLY" ||
        res[index].donationFrequency == "ANNUALLY" ||
        res[index].donationFrequency == "DAILY" ||
        this.getMultiFundDonationFreq(res[index]) === "Recurring"
      ) {
        res[index].donationFrequency = "Recurring";
      } else if (res[index].donationFrequency == "DEACTIVATED") {
        res[index].donationFrequency = "Canceled";
      }
      if (res[index].coverFees === true && res[index].state === "SUCCEEDED") {
        res[index].state = "Success";
        res[index].grossAmountVar = (
          res[index].amount - res[index].fees
        ).toFixed(2);
      } else if (
        res[index].coverFees === false &&
        res[index].state === "SUCCEEDED"
      ) {
        res[index].state = "Success";
        res[index].grossAmountVar = (
          res[index].amount - res[index].fees
        ).toFixed(2);
      }
      res[index].grossAmountVar = (
        Math.round(res[index].grossAmountVar * Math.pow(10, 2)) /
        Math.pow(10, 2)
      ).toFixed(2);
    }
    console.log("RES", res);
    //Get all unique donors by email and sponsee
    let keys = [];
    //Table rows with calculated gross donations from each unique email
    let rows = [];
    // let holder = []

    // make copy of res and order by increasing Date of timestamp
    let resCopy = res.slice();
    resCopy.sort((a, b) => {
      return new Date(a.timestamp) - new Date(b.timestamp);
    });
    resCopy.map((donation) => {
      let paymentMethodHolder = "";
      let grossAmountVariable = parseFloat(donation.grossAmountVar);
      const amount = parseFloat(donation.amount).toFixed(2);
      const donationEmail = donation.email.toLowerCase();
      if (donation.fund === undefined) {
        donation.fund = donation.merchantName;
      }
      let key =
        donation.email.toString().toLowerCase() +
        " | " +
        donation.name.toString().toLowerCase();
      const keyIndex = keys.indexOf(key);
      if (donation.state === "Success") {
        //when it repeats, not unique
        if (keyIndex !== -1) {
          rows[keyIndex].grossAmountVar += grossAmountVariable;
          rows[keyIndex].latestTimetamp = donation.timestamp;

          if (
            donation.donationFrequency == "One-Time" &&
            rows[keyIndex].donorType == "One-Time"
          ) {
            rows[keyIndex].donorType = "Repeat";
            rows[keyIndex].Frequency = "Repeat";
            // if (!holder.includes(keyIndex)) {
            //   holder.push(keyIndex)
            // }
          }
          if (
            donation.donationFrequency == "One-Time" &&
            rows[keyIndex].donorType == "Canceled"
          ) {
            rows[keyIndex].donorType = "One-Time";
            rows[keyIndex].Frequency = "One-Time";
          }

          if (donation.donationFrequency == "Recurring") {
            let frequency = "Recurring";

            frequency = this.checkMonthlyCanceled(
              donation,
              donorSubscriptions,
              frequency
            );

            rows[keyIndex].donorType = frequency;
            rows[keyIndex].Frequency = frequency;

            donation.monthlyDonation += grossAmountVariable;
          }

          if (!paymentMethodHolder.includes(donation.paymentMethod)) {
            paymentMethodHolder =
              paymentMethodHolder + ", " + donation.paymentMethod;
          }
        }
        if (keyIndex === -1) {
          if (!paymentMethodHolder.includes(donation.paymentMethod)) {
            paymentMethodHolder =
              paymentMethodHolder + ", " + donation.paymentMethod;
          }

          let frequency = donation.donationFrequency;

          let monthlyDonation = 0;
          if (donation.donationFrequency == "Recurring") {
            monthlyDonation = grossAmountVariable;
            frequency = this.checkMonthlyCanceled(
              donation,
              donorSubscriptions,
              frequency
            );
          }

          keys.push(key);
          rows.push({
            name: donation.name.toLowerCase(),
            email: donation.email.toLowerCase(),
            phonenumber: donation.phone,
            city: donation.city,
            region: donation.region,
            paymentMethod: paymentMethodHolder,
            zip: donation.zip,
            funds: donation.funds,
            address: donation.address,
            address2: donation.address2,
            state: donation.state,
            grossAmount: amount,
            donorType: frequency,
            Frequency: frequency,
            country: donation.country,
            grossAmountVar: grossAmountVariable,
            monthlyDonation: monthlyDonation,
            latestTimetamp: donation.timestamp,
          });
        }
      }
    });

    // Update rows with Subscription data if not already there
    donorSubscriptions.forEach((subscriptions, key) => {
      if (!keys.includes(key)) {
        // Assuming subscriptions is an array and we take the first subscription to represent the donor
        let firstSubscription = subscriptions[0];
        let recurring = "Canceled";
        for (let i = 0; i < subscriptions.length; i++) {
          if (subscriptions[i].subscriptionStatus === "ACTIVE") {
            recurring = "Recurring";
          }
        }
        rows.push({
          name: firstSubscription.name.toLowerCase(),
          email: firstSubscription.email.toLowerCase(),
          phonenumber: firstSubscription.phone,
          city: firstSubscription.city,
          region: firstSubscription.region,
          paymentMethod: firstSubscription.paymentMethod,
          zip: firstSubscription.zip,
          funds: firstSubscription.funds,
          address: firstSubscription.address,
          address2: firstSubscription.address2,
          state: firstSubscription.state,
          country: firstSubscription.country,
          grossAmount: "0.00", // No transactions, so gross amount is 0
          donorType: recurring,
          Frequency: recurring,
          grossAmountVar: 0,
          monthlyDonation: 0,
          latestTimetamp: firstSubscription.timestamp,
        });
      }
    });

    // print length of rows that is Recurring and Success
    // print length of subscriptions that is Recurring and Success
    let recurringRows = rows.filter(
      (row) => row.donorType === "Recurring" && row.state == "Success"
    );
    let recurringSubscriptions = [];
    donorSubscriptions.forEach((subscriptions, key) => {
      let recurring = "Canceled";
      for (let i = 0; i < subscriptions.length; i++) {
        if (subscriptions[i].subscriptionStatus === "ACTIVE") {
          recurring = "Recurring";
        }
      }
      if (recurring === "Recurring") {
        recurringSubscriptions.push(key);
      }
    });
    //console.log(recurringRows.length, "recurringRows.length");
    //console.log(recurringSubscriptions.length, "recurringSubscriptions.length");

    // print subscription Key where more than one susbcription is active
    donorSubscriptions.forEach((subscriptions, key) => {
      let recurring = "Canceled";
      let activeSubscriptions = 0;
      for (let i = 0; i < subscriptions.length; i++) {
        if (subscriptions[i].subscriptionStatus === "ACTIVE") {
          activeSubscriptions += 1;
        }
      }
      if (activeSubscriptions > 1) {
        // console.log("More than one active subscription for: ", key);
      }
    });

    // update rows with Canceled donorType if there is no matching subscription
    rows.forEach((row) => {
      if (row.donorType === "Recurring" && row.state == "Success") {
        let subscriptionKey = row.email + " | " + row.name;
        if (!recurringSubscriptions.includes(subscriptionKey)) {
          // set the donorType to Canceled
          row.donorType = "Canceled";
          row.Frequency = "Canceled";
        }
      }
    });

    // // print out any rows without a matching donorSubscription
    // let count = 0;
    // rows.forEach((row) => {
    //   console.log(row.donorType, "row.donorType");
    //   if (row.donorType === "Recurring" && row.state == "Success") {
    //     let subscriptionKey = row.email + " | " + row.name;
    //     if (!donorSubscriptions.has(subscriptionKey)) {
    //       console.log("No subscription for: ", subscriptionKey);
    //       // print out a subscription with the same email if it exists
    //       donorSubscriptions.forEach((subscriptions, key) => {
    //         if (key.includes(row.email)) {
    //           console.log("No subscription for subscription: ", key);
    //         }
    //       });
    //     } else {
    //       count += 1;
    //     }
    //   }
    // });
    //console.log(count, "count");

    if (rows.length < 20) {
      end = rows.length;
    }

    // replace rows donor addresses with the address of first subscription in the donorSubscriptions map
    rows.forEach((row) => {
      let subscriptionKey = row.email + " | " + row.name;
      let subscriptions = donorSubscriptions.get(subscriptionKey);
      if (subscriptions) {
        let firstSubscription = subscriptions[0];
        row.address = firstSubscription.address;
        row.address2 = firstSubscription.address2;
        row.city = firstSubscription.city;
        row.region = firstSubscription.region;
        row.zip = firstSubscription.zip;
      }
    });

    // order rows by latest timestamp
    rows.sort((a, b) => {
      return new Date(b.latestTimetamp) - new Date(a.latestTimetamp);
    });

    this.setState(
      {
        donorsList: rows,
        donorList2: res,
        start: 0,
        end: end,
        total: rows.length,
        totalAmountList: totalAmountList,
        grossAmountList: grossAmountList,
        // allO: allO,
        // allM: allM,
        // allR: allR,
        donorSubscriptions: donorSubscriptions,
      },
      () => {
        // This callback updates selectedDonorSubscriptions
        // so that if updateData is called while DonorSlideover
        // is open, DonorSlideover will be rerendered with the new data.
        const { open, selectedDonor } = this.state;
        if (open) {
          let key =
            selectedDonor.email.toLowerCase() +
            " | " +
            selectedDonor.name.toLowerCase();
          let selectedDonorSubscriptions = null;

          if (selectedDonor.donorType === "Recurring") {
            selectedDonorSubscriptions = donorSubscriptions.get(key);
          }

          this.setState({
            selectedDonorSubscriptions: selectedDonorSubscriptions,
          });
        }
        this.updateFilter();
      }
    );
  };

  goNext = (e) => {
    e.preventDefault();
    const { start, end, total } = this.state;
    if (end !== total) {
      this.setState({
        start: start + 20,
        end: Math.min(end + 20, total),
      });
    }
  };

  goPrev = (e) => {
    e.preventDefault();
    const { start, end, total } = this.state;
    if (start !== 0) {
      this.setState({
        start: Math.max(start - 20, 0),
        end: start,
      });
    }
  };
  headers1 = [
    { label: "Donor", key: "name" },
    { label: "Email", key: "email" },
    { label: "Address", key: "address" },
    { label: "Address 2", key: "address2" },
    { label: "City", key: "city" },
    { label: "State", key: "region" },
    { label: "Zip", key: "zip" },
    { label: "Country", key: "country" },
    { label: "Phone", key: "phonenumber" },

    { label: "Total Amount Donated", key: "grossAmountVar" },
    { label: "Frequency", key: "Frequency" },
  ];

  render() {
    const { readOnly } = this.props;
    const {
      donationList,
      start,
      end,
      total,
      showFilter,
      timerange,
      showDateFilter,
      showKeywordFilter,
      showAmountFilter,
      showMethodFilter,
      showFrequencyFilter,
      whoIsLoggedIn,
      filteredFund,
      loading,
      totalDonation,
      avgDonation,
      totalDonors,
      duplicates,
      recentFundsText,
      recentDonorsText,
      allO,
      allD,
      allM,
      allR,
      selectedDonor,
      open,
      listOfAllPossibleFunds,
      filteredState,
      filteredFeeCovered,
      resetFilter,
      listOfAllPossibleDonors,
      filteredFundList,
      filteredDonor,
      filteredDonorList,
      filteredKeywordSet,
      keywordSearchTerm,
      filteredOperator,
      filteredAmount,
      filteredAmountDisplayed,
      filteredAmountSet,
      filteredPaymentType,
      amountAtLeast,
      amountSpecific,
      amountNoMoreThan,
      filteredList,
      savedFilterIndicatorAmount,
      savedFilterIndicatorStatus,
      savedFilterIndicatorKeywordDonor,
      savedFilterIndicatorKeywordFund,
      grossAmountList,
      selectedDonorSubscriptions,
      jwtToken,
    } = this.state;
    this.allM = 0;
    this.allD = 0;
    this.allR = 0;
    this.allO = 0;
    this.allC = 0;
    let filterResultsAtBottom = 0;
    let filterResultsAtBottomBool = false;
    let keys = new Set();
    const headers1 = this.headers1;
    let masterAdmin = false;
    let noFilteredResults = false;
    let savedFilterDivAmount = "";
    let savedFilterDivStatus = "";
    let savedFilterDivKeyword = "";

    const topSpacing = loading ? "mt-14" : "mt-0"; // Adjust 'mt-0' to remove the space when loading

    const stateOptions = [
      { option: "One-Time", descriptor: "Donors that have only donated once." },
      {
        option: "Repeat",
        descriptor:
          "Donors that have donated more than once, but are not on a reoccuring subscription",
      },
      {
        option: "Recurring",
        descriptor: "Donors that are on a recurring subscription.",
      },
      {
        option: "Canceled",
        descriptor: "Donors that have canceled their recurring subscription.",
      },
    ];
    //    { option: 'Cancelled', descriptor: "Donor that were previously on a reoccuring subscription that has now been canceled." },]
    const paymentTypes = ["Card", "Apple Pay", "Google Pay", "Pay By Bank"];
    let filteredListCopy = filteredList;
    let filteredListCopyHolder = filteredList;
    if (resetFilter === true) {
      filteredListCopy = filteredListCopyHolder;
    }
    if (resetFilter === false) {
      if (filteredDonorList.length !== 0) {
        filteredListCopy = filteredListCopy.filter((t) =>
          filteredDonorList.includes(t.name)
        );
      }
      if (filteredFundList.length !== 0) {
        filteredListCopy = filteredListCopy.filter((t) => {
          let Tfunds = t.funds ?? [];
          if (Tfunds.length > 0) {
            return Tfunds.some((fund) =>
              filteredFundList.includes(fund.fundName)
            );
          }
          return filteredFundList.includes(t.fund);
        });
      }

      if (filteredListCopy.length === 0) {
        noFilteredResults = true;
      }
    }

    // // sort filteredListCopy in alphabetical order by name in lowercase
    // filteredListCopy.sort((a, b) => {
    //   if (a.name.toLowerCase() < b.name.toLowerCase()) {
    //     return -1;
    //   }
    //   if (a.name.toLowerCase() > b.name.toLowerCase()) {
    //     return 1;
    //   }
    //   return 0;
    // })

    // make an a deep copy of filteredListCopy that is sorted alphabetically by name in lowercase
    const alphabeticallySortedList = JSON.parse(
      JSON.stringify(filteredListCopy)
    );
    alphabeticallySortedList.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    // giant code block for indicators
    if (filteredFundList.length >= 1 || filteredDonorList.length >= 1) {
      let keywordIndicator = `Keyword (${
        filteredDonorList.length + filteredFundList.length
      })`;
      if (filteredFundList.length + filteredDonorList.length == 1) {
        // show non empty values in filteredFundList or filteredDonorList
        keywordIndicator =
          "Keyword: " +
          filteredFundList.filter((s) => s != "") +
          filteredDonorList.filter((s) => s != "");
        keywordIndicator =
          filteredDonorList.length == 1
            ? this.capitalizeFirstLetter(keywordIndicator)
            : keywordIndicator;
      }

      savedFilterDivKeyword = (
        <button
          type="button "
          className="focus:outline-none inline-block flex px-1 focus:ring-2 focus:ring-offset-2"
          onClick={this.onSavedIndicatorKeywordClick}
          onMouse
        >
          <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
            &nbsp;
            <div className="text-gray-500 hover:text-gray-900 ">
              {" "}
              {keywordIndicator}&nbsp;{" "}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 22 22"
              stroke-width="1"
              stroke="currentColor"
              class="h-5 w-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </button>
      );
    }
    if (savedFilterIndicatorAmount === true) {
      let amountIndicatorValue = "";
      if (amountAtLeast !== "") {
        amountIndicatorValue +=
          amountIndicatorValue == ""
            ? `>$${amountAtLeast}`
            : `, >$${amountAtLeast}`;
      }
      if (amountNoMoreThan !== "") {
        amountIndicatorValue +=
          amountIndicatorValue == ""
            ? `<$${amountNoMoreThan}`
            : `, <$${amountNoMoreThan}`;
      }
      if (amountSpecific !== "") {
        amountIndicatorValue +=
          amountIndicatorValue == ""
            ? `=$${amountSpecific}`
            : `, =$${amountSpecific}`;
      }

      savedFilterDivAmount = (
        <button
          type="button "
          className="focus:outline-none inline-block flex px-1 focus:ring-2 focus:ring-offset-2"
          onClick={this.onSavedIndicatorAmountClick}
          onMouse
        >
          <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
            {" "}
            &nbsp;
            <div className="text-gray-500 hover:text-gray-900 ">
              {" "}
              Amount: {amountIndicatorValue}&nbsp;{" "}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 22 22"
              stroke-width="1"
              stroke="currentColor"
              class="h-5 w-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </button>
      );
    }
    if (savedFilterIndicatorStatus === true) {
      let statusIndicator = `Frequency (${filteredState.length})`;
      if (filteredState.length == 1) {
        // show non empty values in filteredFundList or filteredDonorList
        statusIndicator = "Frequency: " + filteredState.filter((s) => s != "");
      }

      savedFilterDivStatus = (
        <button
          type="button "
          className="focus:outline-none inline-block flex px-1 focus:ring-2 focus:ring-offset-2"
          onClick={this.onSavedIndicatorStatusClick}
          onMouse
        >
          <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
            {" "}
            &nbsp;
            <div className="text-gray-500 hover:text-gray-900 ">
              {" "}
              {statusIndicator}&nbsp;{" "}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 22 22"
              stroke-width="1"
              stroke="currentColor"
              class="h-5 w-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </button>
      );
    }

    let donationTableExport = alphabeticallySortedList
      .slice(0, total)
      .map((donation, index) => {
        donation.email = this.capitalizeFirstLetter(donation.email);
        donation.name = this.capitalizeFirstLetter(donation.name);
        let key =
          donation.email.toString().toLowerCase() +
          " | " +
          donation.name.toString().toLowerCase();
        let keys = [];
        const keyIndex = keys.indexOf(key);
        if (donation.state === "Success") {
          this.allD = this.allD + 1;
          if (keyIndex === -1) {
            if (donation.Frequency == "One-Time") {
              this.allO = this.allO + 1;
            }
            if (donation.Frequency == "Recurring") {
              this.allM = this.allM + 1;
            }
            if (donation.Frequency == "Repeat") {
              this.allR = this.allR + 1;
            }
            if (donation.Frequency == "Canceled") {
              this.allC = this.allC + 1;
            }
          }
        }
      });

    if (
      this.end > filterResultsAtBottom &&
      filterResultsAtBottomBool === true
    ) {
      this.end = filterResultsAtBottom;
    }
    let donationTableContent = alphabeticallySortedList
      .slice(start, end)
      .map((donation, index) => {
        //fix data formatting
        //      const unformattedTimestamp = donation.timestamp.split(' ')[0];
        //      const timestamp = this.convertTimestamp(unformattedTimestamp);

        //convert amount and coverFees to correct types
        donation.amount = donation.grossAmount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        });
        //set color of true/false and the Total amount

        let stateColor1 =
          "px-3 text-center text-green-800 bg-green-100 rounded-full";
        //set color for monthly donor (green), repeat? (blue), one-time (red)
        if (donation.donorType == "One-Time") {
          stateColor1 = "px-3 text-center text-red-800 bg-red-200 rounded-full";
        }
        if (donation.donorType == "Repeat") {
          stateColor1 =
            "px-3 text-center text-blue-800 bg-blue-100 rounded-full";
        }
        if (donation.donorType == "Canceled") {
          stateColor1 =
            "px-3 text-center text-yellow-800 bg-yellow-100 rounded-full";
        }

        while (donation.state !== null) {
          const key =
            donation.email.toLowerCase() + " | " + donation.name.toLowerCase();
          return (
            <tr
              className="cursor-pointer hover:bg-gray-50"
              key={key}
              onClick={() => this.openSlideOver(key)}
            >
              <td className="p-2">
                <div className="items-center">
                  <a className="font-medium text-gray-600">
                    <div className="hover:none text-left text-sm">
                      {this.capitalizeFirstLetter(donation.name)}
                    </div>
                    <div className="hover:none text-left text-xs">
                      {donation.email}
                    </div>
                  </a>
                </div>
              </td>
              {/* <td className="p-2">
                        <div className="items-center">
                          <div className="text-center text-s">{donor.fund}</div>
                        </div>
                      </td> */}
              <td className="p-2">
                <div className="items-center text-center">
                  <button className={stateColor1}>{donation.donorType}</button>
                </div>
              </td>
              <td className="p-2">
                <div className="items-center text-center">
                  <div className="text-center">
                    $
                    {(
                      Math.round(donation.grossAmountVar * Math.pow(10, 2)) /
                      Math.pow(10, 2)
                    ).toFixed(2)}
                  </div>
                </div>
              </td>
            </tr>
          );
        }
      });

    this.props.getMetrics(
      this.allD,
      this.allO,
      this.allR,
      this.allM,
      this.allC
    );
    let next = "btn bg-white border-gray-100 hover:border-gray-300 text-light";
    let prev = "btn bg-white border-gray-100 hover:border-gray-300 text-light";
    if (end === total) {
      next = "btn bg-white border-gray-100 text-gray-300 cursor-not-allowed";
    }
    if (start === 0) {
      prev = "btn bg-white border-gray-100 text-gray-300 cursor-not-allowed";
    }

    let showIndicators = false;
    if (
      savedFilterIndicatorKeywordDonor ||
      savedFilterIndicatorKeywordFund ||
      savedFilterIndicatorAmount ||
      savedFilterIndicatorStatus
    ) {
      showIndicators = true;
    }
    return (
      <div className="col-span-full xl:col-span-12">
        {readOnly.readOnly ? null : (
          <DonorSlideover
            open={open}
            setOpen={this.setOpen}
            updateData={this.updateData}
            selectedDonor={selectedDonor}
            selectedDonorSubscriptions={selectedDonorSubscriptions}
            jwtToken={jwtToken}
            merchant={this.props.merchant}
          />
        )}

        {!loading && !showIndicators && (
          <div className="flex flex-row justify-end py-3 ">
            <div className="flex">
              <button
                type="button "
                className="focus:outline-none  inline-block flex focus:ring-2 focus:ring-offset-2 "
                onClick={this.onFilterClickHandler}
                onMouse
              >
                <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 17"
                    stroke="#a1afc2"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                    />
                  </svg>
                  &nbsp;
                  <div className="text-gray-500 hover:text-gray-900 ">
                    {" "}
                    Filter &nbsp;&nbsp;&nbsp;{" "}
                  </div>
                </div>
              </button>
            </div>

            <div> &nbsp;</div>

            <div className="flex ">
              <button
                type="button "
                className="focus:outline-none inline-block flex focus:ring-2 focus:ring-offset-2 "
              >
                <CSVLink
                  filename="CharityStack Donors Export.csv"
                  data={alphabeticallySortedList}
                  headers={headers1}
                >
                  <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 17"
                      stroke="#a1afc2"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                      />
                    </svg>
                    &nbsp;
                    <div className="text-gray-500 hover:text-gray-900 ">
                      {" "}
                      Export &nbsp;{" "}
                    </div>
                  </div>
                </CSVLink>
              </button>
            </div>
          </div>
        )}

        {!loading && showIndicators && (
          //saved filter indicators
          <div>
            <div>
              <div className="flex flex-row justify-between py-3 ">
                <div className="z-10 flex flex-row">
                  {savedFilterDivKeyword}
                  {savedFilterDivAmount}
                  {savedFilterDivStatus}
                </div>
                <div className="flex flex-row justify-end">
                  <div className="inline">
                    <button
                      type="button "
                      className="focus:outline-none  inline-block flex focus:ring-2 focus:ring-offset-2 "
                      onClick={this.onFilterClickHandler}
                      onMouse
                    >
                      <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 17"
                          stroke="#a1afc2"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                          />
                        </svg>
                        &nbsp;
                        <div className="text-gray-500 hover:text-gray-900 ">
                          {" "}
                          Filter &nbsp;&nbsp;&nbsp;{" "}
                        </div>
                      </div>
                    </button>
                  </div>
                  <div> &nbsp;</div>
                  <div className="inline ">
                    <button
                      type="button "
                      className="focus:outline-none inline-block flex focus:ring-2 focus:ring-offset-2 "
                    >
                      <CSVLink
                        filename="CharityStack Donors Export.csv"
                        data={alphabeticallySortedList}
                        headers={headers1}
                      >
                        <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 17"
                            stroke="#a1afc2"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                          </svg>
                          &nbsp;
                          <div className="text-gray-500 hover:text-gray-900 ">
                            {" "}
                            Export &nbsp;{" "}
                          </div>
                        </div>
                      </CSVLink>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {showFilter && (
          <div class="container relative mx-auto bg-gray-500">
            <div
              class="
              border-sky-500 absolute right-28 w-[600px] items-stretch justify-around gap-4 divide-x rounded-lg border-2 border-solid bg-white p-4 p-4 text-sm drop-shadow-xl"
            >
              <div class="flow-root divide-x">
                <div class="py-15 float-left grid grid-rows-6 gap-4 px-1  pt-7">
                  <div>
                    <button type="button " onClick={this.onFilterKeywordFilter}>
                      <div className="inline-block flex justify-center align-baseline">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 20"
                          stroke-width="1.5"
                          stroke="#a1afc2"
                          class="h-5 w-5"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
                            clipRule="evenodd"
                          />
                        </svg>{" "}
                        &nbsp;
                        <div className="text-gray-500 hover:text-gray-900 ">
                          {" "}
                          Keyword{" "}
                        </div>
                      </div>
                    </button>
                  </div>

                  <div>
                    <button type="button " onClick={this.onFilterAmountFilter}>
                      <div className="inline-block flex justify-center align-baseline">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 20"
                          stroke-width="1.5"
                          stroke="#a1afc2"
                          class="h-5 w-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>{" "}
                        &nbsp;
                        <div className="text-gray-500 hover:text-gray-900 ">
                          {" "}
                          Amount{" "}
                        </div>
                      </div>
                    </button>
                  </div>
                  {/*}
                    <div>
                      <button
                        type="button "
                        onClick={this.onFilterMethodFilter}                  >
                        <div className='flex justify-center inline-block align-baseline'>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 20" stroke-width="1.5" stroke="#a1afc2" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                          </svg>
                          &nbsp;
                          <div className="text-gray-500 hover:text-gray-900 "> Method </div>
                        </div>
                      </button>
                    </div>
                    */}
                  <div>
                    <button
                      type="button "
                      onClick={this.onFilterFrequencyFilter}
                    >
                      <div className="inline-block flex justify-center align-baseline">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 20"
                          stroke-width="1.5"
                          stroke="#a1afc2"
                          class="h-5 w-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 6h.008v.008H6V6z"
                          />
                        </svg>
                        &nbsp;
                        <div className="text-gray-500 hover:text-gray-900 ">
                          {" "}
                          Frequency{" "}
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className="py-2"></div>
                  <div className="py-2"></div>
                  <div className="py-2"></div>
                  <div className="py-2"></div>
                  <div>
                    <button type="button " onClick={this.onResetFilter}>
                      <div className="inline-block flex justify-center align-baseline">
                        <div className="text-red-700 hover:text-red-300 ">
                          {" "}
                          Clear Filters{" "}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="flex content-start justify-start border-none">
                  {/* <DonationDatePicker setTimerange={this.setDateTimeRange} /> 
                    <div>
                      {showDateFilter &&
                        <div className="px-5">
                          <DonationDatePicker setTimerange={this.setDateTimeRange} />

                        </div>
                      }
                    </div>
                    */}
                  {showKeywordFilter && (
                    <div className="justify-start pl-7 pr-6">
                      <div className="w-72 rounded-md border-gray-300 pl-8 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"></div>
                      <div class="relative mt-1 rounded-md shadow-sm">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span class="text-gray-500 sm:text-sm">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 22 22"
                              stroke-width="1.5"
                              stroke="#a1afc2"
                              class="h-4 w-4"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </div>
                        <label htmlFor="keywordsearch" className="sr-only">
                          Search
                        </label>
                        <input
                          type="search"
                          name="keywordsearch"
                          id="keywordsearch"
                          className="w-72 rounded-md border-gray-300 pl-8 pt-3 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          placeholder="Search for Donors, Emails, or Funds"
                          onChange={(e) => this.onKeywordSearchFilter(e)}
                        />
                      </div>

                      <div className="pt-4 text-base underline">
                        {recentDonorsText}
                      </div>

                      {listOfAllPossibleDonors.map((donor) => {
                        return (
                          <div className="justify-begin justify-left flex items-start justify-start">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 py-1 text-indigo-600 focus:ring-0"
                              onChange={(e) =>
                                this.onFilteredFundDonorClickHandler(e)
                              }
                              value={this.capitalizeFirstLetter(
                                donor.toString()
                              )}
                              checked={filteredDonorList.includes(donor)}
                            />{" "}
                            &nbsp;
                            {this.capitalizeFirstLetter(donor.toString())}
                          </div>
                        );
                      })}
                      <div className="pt-4 text-base underline">
                        {recentFundsText}
                      </div>
                      {listOfAllPossibleFunds.map((newFund, index) => {
                        if (newFund === undefined)
                          return console.log(
                            "WHAT HAPPENED?? ",
                            newFund,
                            listOfAllPossibleFunds
                          );
                        return (
                          <div
                            key={index}
                            className="justify-begin justify-left flex items-center justify-start"
                          >
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0"
                              onChange={(e) =>
                                this.onFilteredFundClickHandler(e)
                              }
                              value={newFund.toString()}
                              checked={filteredFundList.includes(newFund)}
                            />{" "}
                            &nbsp;
                            {newFund.toString()}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {showAmountFilter && (
                    <div className="justify-start pl-7 pr-6">
                      <div className="w-72 rounded-md border-gray-300 pl-8 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"></div>

                      <div className="py-3">
                        Specific Amount
                        <input
                          maxLength={6}
                          type="search"
                          name="amountSpecific"
                          id="amountSpecific"
                          className="block w-full rounded-md border-gray-300 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          placeholder="=$0.00"
                          onChange={(e) => this.amountFilterChange(e)}
                          value={amountSpecific}
                        />
                      </div>
                      <div className="py-3">
                        At Least...
                        <input
                          maxLength={6}
                          type="search"
                          name="amountAtLeast"
                          id="amountAtLeast"
                          className="block w-full rounded-md border-gray-300 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          placeholder=">=$0.00"
                          onChange={(e) => this.amountFilterChange(e)}
                          value={amountAtLeast}
                        />
                      </div>
                      <div className="py-3">
                        No More Than...
                        <input
                          maxLength={6}
                          type="search"
                          name="amountNoMoreThan"
                          id="amountNoMoreThan"
                          className="block w-full rounded-md border-gray-300 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          placeholder="<=$0.00"
                          onChange={(e) => this.amountFilterChange(e)}
                          value={amountNoMoreThan}
                        />
                      </div>
                    </div>
                  )}
                  {showMethodFilter && (
                    <div className="justify-start pl-7 pr-6">
                      <div className="w-72 rounded-md border-gray-300 pl-8 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"></div>
                      <div className="pt-6 text-base underline">Method</div>
                      {paymentTypes.map((donor) => {
                        return (
                          <div className="justify-begin justify-left flex items-center justify-start py-1">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 py-1 text-indigo-600 focus:ring-0"
                              onChange={(e) =>
                                this.onFilteredPaymentTypeClickHandler(e)
                              }
                              value={donor.toString()}
                              checked={filteredPaymentType.includes(donor)}
                            />{" "}
                            &nbsp;
                            {donor.toString()}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {showFrequencyFilter && (
                    <div className="justify-start pl-7 pr-6">
                      <div className="w-72 rounded-md border-gray-300 pl-8 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"></div>
                      <div className="pt-6 text-base underline">Frequency</div>
                      {stateOptions.map((donor, index) => {
                        return (
                          <div className="justify-begin justify-left flex items-center justify-start">
                            <label>
                              <input
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 py-1 text-indigo-600 focus:ring-0"
                                onChange={(e) =>
                                  this.onFilteredStateClickHandler(e)
                                }
                                value={donor.option.toString()}
                                checked={filteredState.includes(donor.option)}
                              />{" "}
                              &nbsp;
                              {donor.option.toString()}
                              <div className="text-xxs text-grey-500 w-72 pb-3 pl-6  text-gray-500">
                                {stateOptions[index].descriptor.toString()}
                              </div>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={`col-span-full rounded-lg border border-gray-200 bg-white shadow-sm xl:col-span-12 ${topSpacing}`}
        >
          {/* Table 
          naumaan */}
          {loading && (
            <div className="flex">
              <ContentLoader
                speed={2}
                width="100%"
                height={totalHeight}
                preserveAspectRatio="xMidYMid meet"
                backgroundColor="#f0f0f0"
                foregroundColor="#dedede"
              >
                {Array.from({ length: numberOfRows }).map((_, rowIndex) => {
                  return (
                    <React.Fragment key={`row-${rowIndex}`}>
                      {adjustedColumns.map((col, colIndex) => {
                        const isHeader = rowIndex === 0;
                        const bgColor = isHeader ? "#b1b1b1" : "#f0f0f0";
                        const currentX = calculateXPosition(
                          colIndex,
                          singleSpacingPercent
                        );
                        //  console.log("some values: ", rowIndex, colIndex)
                        // if colIndex is 1, height needs to be half
                        let yValue =
                          rowIndex * (rowHeight + spaceBetweenRows) + 20;
                        if (colIndex !== 0) {
                          //     console.log("yvalue : ", yValue);
                          yValue =
                            (rowIndex * (rowHeight + spaceBetweenRows) + 20) *
                            1.75;
                          //    console.log("yvalue after: ", yValue);
                        }
                        return (
                          <rect
                            key={`row-${rowIndex}-col-${colIndex}`}
                            x={`${currentX}%`} // Use the function to calculate x position
                            y={yValue}
                            rx="5"
                            ry="5"
                            width={col.width} // Use the adjusted width percentage
                            height={rowHeight}
                            fill={bgColor}
                          />
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </ContentLoader>
            </div>
          )}
          <div className="p-3">
            <div>
              {noFilteredResults && !loading && (
                <div
                  class="rounded-2xl border border-blue-100 bg-white p-8 shadow-lg"
                  role="alert"
                  id="noMatchFound"
                >
                  <div class="items-center sm:flex">
                    <span class="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#a1afc2"
                        class="h-6 w-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                        />
                      </svg>
                    </span>

                    <p class="mt-3 text-lg font-medium sm:ml-3 sm:mt-0">
                      No matches found
                    </p>
                  </div>

                  <p class="mt-4 text-gray-500">
                    Unfortunately, no results match the search criteria you
                    entered. Try making a change to the filters you selected.
                    <br></br> If this issue persists, please reach out to the{" "}
                    <a href="mailto:support@charitystack.org">
                      <span className="text-blue-400 hover:animate-spin">
                        {" "}
                        CharityStack team!
                      </span>{" "}
                    </a>
                  </p>
                  {/*}
             <div class="mt-6 sm:flex">
               <a
                 class="inline-block w-full px-5 py-3 text-sm font-semibold text-center text-white bg-blue-500 rounded-lg sm:w-auto"
                 href=""
               >
                 Take a Look
               </a>
           
               <a
                 class="inline-block w-full px-5 py-3 mt-3 text-sm font-semibold text-center text-gray-500 rounded-lg bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto"
                 href=""
               >
                 Mark as Read
               </a>
             </div> */}
                </div>
              )}
              <div className="overflow-x-auto">
                {!noFilteredResults && (
                  <div className="overflow-x-auto">
                    <table className="w-full table-auto">
                      {/* Table header */}
                      <thead className="rounded-sm bg-gray-50 text-xs uppercase text-gray-400">
                        <tr>
                          <th className="p-2">
                            <div className="text-left font-semibold">Donor</div>
                          </th>
                          {/* <th className="p-2">
                    <div className="font-semibold text-center">Fund</div>
                  </th> */}

                          <th className="p-2">
                            <div className="text-center font-semibold">
                              Type{" "}
                            </div>
                          </th>
                          <th className="p-2">
                            <div className="text-center font-semibold">
                              Amount
                            </div>
                          </th>
                        </tr>
                      </thead>
                      {/* Table body */}
                      <tbody className="divide-y divide-gray-100 text-sm font-medium">
                        {donationTableContent}
                        {donationTableExport}
                      </tbody>
                    </table>
                    <div className="mt-8">
                      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                        <nav
                          className="mb-4 sm:order-1"
                          role="navigation"
                          aria-label="Navigation"
                        >
                          <ul className="flex justify-center">
                            <li className="ml-3 first:ml-0">
                              <a
                                className={prev}
                                href="#0"
                                onClick={this.goPrev}
                              >
                                &lt;- Previous
                              </a>
                            </li>
                            <li className="ml-3 first:ml-0">
                              <a
                                className={next}
                                href="#0"
                                onClick={this.goNext}
                              >
                                Next -&gt;
                              </a>
                            </li>
                          </ul>
                        </nav>
                        {filterResultsAtBottomBool && (
                          <div className="text-center text-sm text-gray-500 sm:text-left">
                            Showing{" "}
                            <span className="font-medium text-gray-600">
                              {start}
                            </span>{" "}
                            to{" "}
                            <span className="font-medium text-gray-600">
                              {end}
                            </span>{" "}
                            of{" "}
                            <span className="font-medium text-gray-600">
                              {filterResultsAtBottom}
                            </span>{" "}
                            results
                            <div></div>
                          </div>
                        )}
                        {!filterResultsAtBottomBool && (
                          <div className="text-center text-sm text-gray-500 sm:text-left">
                            Showing{" "}
                            <span className="font-medium text-gray-600">
                              {start}
                            </span>{" "}
                            to{" "}
                            <span className="font-medium text-gray-600">
                              {end}
                            </span>{" "}
                            of{" "}
                            <span className="font-medium text-gray-600">
                              {this.totalDonors}
                            </span>{" "}
                            results
                            <div></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(DonationsTable);
