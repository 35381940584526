import React, { useState, useEffect, useCallback } from "react";
import ReactTooltip from "react-tooltip";
import ProductGroupsContent from "./ProductGroupsContent";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { PlusIcon } from "@heroicons/react/20/solid";

const INPUT_TYPE_MAPPING = {
  SHORT_TEXT_INPUT: "Short Text Input",
  LONG_TEXT_INPUT: "Long Text Input",
  SINGLE_SELECTION_DROPDOWN: "Single Selection Dropdown",
  MULTI_SELECTION_DROPDOWN: "Multi Selection Dropdown",
};
const REVERSE_INPUT_TYPE_MAPPING = {
  "Short Text Input": "SHORT_TEXT_INPUT",
  "Long Text Input": "LONG_TEXT_INPUT",
  "Single Selection Dropdown": "SINGLE_SELECTION_DROPDOWN",
  "Multi Selection Dropdown": "MULTI_SELECTION_DROPDOWN",
};

function ProductGroups(props) {
  const { productGroups, setProductGroups } = props;

  useEffect(() => {
    console.log("productGroups changed", productGroups);
  }, [productGroups]);

  const handleProductNameChange = (event) => {
    console.log(event.target.dataset);
    let index = parseInt(event.target.dataset.index);
    let productIndex = parseInt(event.target.dataset.productindex);
    let value = event.target.value;

    let newProducts = JSON.parse(JSON.stringify(productGroups));
    console.log(newProducts);
    console.log(index);
    console.log(productIndex);
    let newProduct = newProducts[index].products[productIndex];
    newProduct["productName"] = value;
    setProductGroups(newProducts);
  };

  const handleProductPriceChange = (event) => {
    let index = parseInt(event.target.dataset.index);
    let productIndex = parseInt(event.target.dataset.productindex);
    let value = event.target.value;

    // Remove non-numeric characters except for the last period
    value = value.replace(/[^\d\.]/g, "").replace(/(\..*)\./g, "$1");
    // Ensure no more than two decimal places
    value = value.replace(/(\.\d{2})\d+$/, "$1");

    let newProducts = JSON.parse(JSON.stringify(productGroups));
    let newProduct = newProducts[index].products[productIndex];
    newProduct["productPrice"] = value;
    setProductGroups(newProducts);
  };

  const handleProductGroupNameChange = (event) => {
    let index = parseInt(event.target.dataset.index);
    let value = event.target.value;

    let newProducts = JSON.parse(JSON.stringify(productGroups));
    let newProductGroup = newProducts[index];
    newProductGroup["productGroupName"] = value;
    setProductGroups(newProducts);
  };

  const handleProductGroupDescriptionChange = (event) => {
    let index = parseInt(event.target.dataset.index);
    let value = event.target.value;

    let newProducts = JSON.parse(JSON.stringify(productGroups));
    let newProductGroup = newProducts[index];
    newProductGroup["productGroupDescription"] = value;
    setProductGroups(newProducts);
  };

  const createNewProductGroup = () => {
    let newProducts = [...productGroups];
    newProducts.push({
      productGroupName: "",
      productGroupDescription: "",
      products: [
        {
          productID: Math.random().toString(36).substring(2, 9),
          productName: "",
          productPrice: "",
        },
      ],
      id: Math.random().toString(36).substring(2, 9), // generates a random id
    });
    setProductGroups(newProducts);
  };

  const createNewProduct = (event) => {
    let index = parseInt(event.target.dataset.index);

    let newProducts = JSON.parse(JSON.stringify(productGroups));
    console.log(newProducts);
    console.log(index);
    let newProductGroup = newProducts[index];
    newProductGroup.products.push({
      productID: Math.random().toString(36).substring(2, 9),
      productName: "",
      productPrice: "",
    });
    setProductGroups(newProducts);
  };

  const removeProduct = (event) => {
    console.log({ event });
    console.log(event.target.dataset);
    let index = parseInt(event.target.dataset.index);
    let productIndex = parseInt(event.target.dataset.productindex);

    let newProducts = JSON.parse(JSON.stringify(productGroups));
    console.log(newProducts);
    console.log(index);
    let newProductGroup = newProducts[index];
    newProductGroup.products.splice(productIndex, 1);
    setProductGroups(newProducts);
  };

  const removeProductGroup = (index) => {
    let newProducts = [...productGroups];
    newProducts.splice(index, 1);
    setProductGroups(newProducts);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let newProducts = [...productGroups];
    let newCustomInput = newProducts[source.index];
    newProducts.splice(source.index, 1);
    newProducts.splice(destination.index, 0, newCustomInput);
    console.log(newProducts);
    setProductGroups(newProducts);
  };

  return (
    <>
      <div className="w-full">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {productGroups.map((productGroup, index) => (
                  <ProductGroupsContent
                    key={productGroup.id}
                    id={productGroup.id}
                    productGroup={productGroup}
                    index={index}
                    handleProductGroupNameChange={handleProductGroupNameChange}
                    handleProductGroupDescriptionChange={
                      handleProductGroupDescriptionChange
                    }
                    handleProductNameChange={handleProductNameChange}
                    handleProductPriceChange={handleProductPriceChange}
                    createNewProduct={createNewProduct}
                    removeProduct={removeProduct}
                    removeProductGroup={removeProductGroup}
                  />
                ))}
                {provided.placeholder}
                <div
                  className={`mr-4 flex ${
                    productGroups.length === 0
                      ? "justify-start"
                      : "mt-1 justify-end"
                  }`}
                >
                  <div
                    className="flex cursor-pointer items-center"
                    onClick={createNewProductGroup}
                  >
                    <PlusIcon
                      className="mr-2 mt-0.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <div className="text-gray-400"> add product group </div>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
}

export default ProductGroups;
