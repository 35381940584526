import React, { useRef, useState, useEffect, useCallback } from "react";
import Dropdown from "../../dropdowns/Dropdown";
import MultiSelectDropdown from "../../dropdowns/MultiSelectDropdown";
import Toggle from "../../Toggle";
import ReactTooltip from "react-tooltip";
import { Draggable } from "react-beautiful-dnd";
import { Bars3Icon, XMarkIcon, PlusIcon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";

const INPUT_TYPE_MAPPING = {
  SHORT_TEXT_INPUT: "Short Text Input",
  LONG_TEXT_INPUT: "Long Text Input",
  SINGLE_SELECTION_DROPDOWN: "Single Selection Dropdown",
  MULTI_SELECTION_DROPDOWN: "Multi Selection Dropdown",
};

function ProductGroupsContent(props) {
  const {
    moveCard,
    productGroup,
    id,
    index,
    handleProductGroupNameChange,
    handleProductGroupDescriptionChange,
    handleProductNameChange,
    handleProductPriceChange,
    createNewProduct,
    removeProduct,
    removeProductGroup,
  } = props;

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className="w-full border-b border-gray-300"
        >
          <div {...provided.dragHandleProps}>
            <Bars3Icon className="ml-auto h-6 w-6" aria-hidden="true" />
          </div>
          <div className="pb-2 sm:grid sm:grid-cols-3">
            <label
              htmlFor="form-name"
              className="col-span-5 block text-sm font-medium text-gray-900 sm:col-span-3 sm:mt-px sm:pt-2"
            >
              Product Group Name*
              {/* <svg
                data-tip
                data-for="titleTooltip"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="text-primary ml-1 inline-block h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
              <ReactTooltip id="titleTooltip" effect="solid" place="right">
                <p className="text-sm text-white">
                  The group name of your products.
                </p>
              </ReactTooltip> */}
            </label>
            <div className="flex space-x-10 text-sm sm:col-span-3 ">
              <input
                data-type="title"
                data-index={index}
                value={productGroup.productGroupName}
                placeholder={"Large"}
                type="text"
                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                onChange={(e) => handleProductGroupNameChange(e)}
                // maxLength={50}
              />
            </div>
          </div>

          {/* Form name */}
          <div className="sm:grid sm:grid-cols-3">
            <label
              htmlFor="form-name"
              className="block text-sm font-medium text-gray-900 sm:col-span-3 sm:pt-2"
            >
              Product Group Description
              {/* <svg
                data-tip
                data-for="inputTypeToolTip"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="text-primary ml-1 inline-block h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
              <ReactTooltip id="inputTypeToolTip" effect="solid" place="right">
                <p className="text-sm text-white">
                  The description of your product group.
                </p>
              </ReactTooltip> */}
            </label>
            <div className="sm:col-span-3">
              <div className="space-x-10 text-sm">
                <input
                  data-type="title"
                  data-index={index}
                  value={productGroup.productGroupDescription}
                  placeholder={"Large Sized Apparel"}
                  type="text"
                  className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  onChange={(e) => handleProductGroupDescriptionChange(e)}
                  // maxLength={50}
                />
              </div>
            </div>
          </div>

          {productGroup.products.map((product, productIndex) => (
            <>
              <div className="w-full items-center sm:pt-2">
                <div className="grid grid-cols-12">
                  <label
                    htmlFor="form-name"
                    className="col-span-5 block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Product Name*
                    {/* <svg
                  data-tip
                  data-for="titleTooltip"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="text-primary ml-1 inline-block h-5 w-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  />
                </svg>
                <ReactTooltip id="titleTooltip" effect="solid" place="right">
                  <p className="text-sm text-white">
                    The name of your product.
                  </p>
                </ReactTooltip> */}
                  </label>
                  <label
                    htmlFor="form-name"
                    className="col-span-5 col-start-7 block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Product Price*
                    {/* <svg
                  data-tip
                  data-for="titleTooltip"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="text-primary ml-1 inline-block h-5 w-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  />
                </svg>
                <ReactTooltip id="titleTooltip" effect="solid" place="right">
                  <p className="text-sm text-white">
                    The price of your product.
                  </p>
                </ReactTooltip> */}
                  </label>
                </div>

                <div className="grid grid-cols-12 items-center text-sm">
                  <input
                    data-type="title"
                    data-index={index}
                    data-productindex={productIndex}
                    value={product.productName}
                    placeholder={"T-Shirt"}
                    type="text"
                    className="focus:border-secondary focus:ring-secondary col-span-5 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                    onChange={(e) => handleProductNameChange(e)}
                    // maxLength={50}
                  />
                  <input
                    data-type="title"
                    data-index={index}
                    data-productindex={productIndex}
                    value={product.productPrice}
                    placeholder={"$10"}
                    type="text"
                    pattern="^\d+(\.\d{0,2})?$"
                    className="focus:border-secondary focus:ring-secondary col-span-5 col-start-7 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                    onChange={(e) => handleProductPriceChange(e)}
                    // maxLength={50}
                  />
                  <div
                    className="h-fit w-fit col-start-12 ml-[1px] cursor-pointer justify-self-end"
                    data-index={index}
                    data-productindex={productIndex}
                    onClick={(e) => removeProduct(e)}
                    id={`removeProduct${productIndex}`}
                  >
                    <XMarkIcon
                      className="h-6 w-6 text-gray-500"
                      aria-hidden="true"
                      data-index={index}
                      data-productindex={productIndex}
                      id={`removeProductX${productIndex}`}
                      // aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </>
          ))}
          <div className="mr-4 flex justify-start pt-2">
            <div
              className="flex cursor-pointer"
              data-index={index}
              onClick={(e) => createNewProduct(e)}
            >
              <PlusIcon
                className="mr-2 mt-0.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
                data-index={index}
              />
              <div className="text-gray-400" data-index={index}>
                {" "}
                add product{" "}
              </div>
            </div>
          </div>

          <div className="mb-2 mr-4 flex justify-end">
            <div
              className="flex cursor-pointer"
              data-index={index}
              onClick={(e) => removeProductGroup(index)}
            >
              <TrashIcon
                data-index={index}
                className="mr-2 mt-0.5 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
              <div data-index={index} className="text-red-500">
                {" "}
                remove product group{" "}
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

export default ProductGroupsContent;
