import React, { useState, useEffect } from 'react';
import paypalBig from '../../../assets/paypal-big.png'
import PaypalDisconnectModal from '../../modals/PaypalDisconnect'
import ConfirmationPaypalDisconnect from '../../payment-methods/ConfirmationPaypalDisconnect'

const PaypalIntegration = ({ showModal, setShowModal, merchant, jwtToken, updateConnectionStatus,
    connectionStatus, showDisconnectModal, setShowDisconnectModal }) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleLinkReceived = (link) => {
        setModalOpen(false);
        setLoading(false);
        updateConnectionStatus("Pending"); // Use the passed function to update status
        // Get the screen width and height
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;

        // Calculate window size and position to be a fraction of the screen size
        const windowWidth = screenWidth * 0.8; // 80% of the screen width
        const windowHeight = screenHeight * 0.8; // 80% of the screen height
        const windowLeft = (screenWidth - windowWidth) / 2; // Centered horizontally
        const windowTop = (screenHeight - windowHeight) / 2; // Centered vertically

        // Create the window features string based on calculated dimensions
        const windowFeatures = `width=${windowWidth},height=${windowHeight},left=${windowLeft},top=${windowTop}`;

        // Open the new window with the dynamic features
        window.open(link, '_blank', windowFeatures);
    };


    const disconnectFromPaypal = async () => {
        setLoading(true);
        setModalOpen(true);
        console.log("merchantName value ", merchant)
        const payload = {
            merchantName: merchant.merchantName
        };

        try {
            console.log("disconnecting from paypal")
            console.log("jwt value ", jwtToken)
            const requestOptions = {
                method: "POST",
                headers: {
                    accept: "*/*",
                    "content-type": "application/json",
                    Authorization: jwtToken,
                },
                body: JSON.stringify(payload),
            };

            const url = process.env.REACT_APP_PAYPAL_DISCONNECT_URL;
            console.log("url", url, jwtToken)
            let response = await fetch(url, requestOptions);

            console.log("response", response)
            let data = await response.json();
            console.log({ data });
            if (data.error) {
                throw new Error(data.error);
            }

            if (data === true) {
                setShowDisconnectModal(false)
                setShowConfirmation(true)
            } else {
                throw new Error('No confirmation received');
            }
        } catch (error) {
            console.error('Error during the API call:', error);
            alert(error.message);
        } finally {
            setLoading(false);
            setModalOpen(false);
        }
    };

    const connectToPayPal = async () => {
        setLoading(true);
        setModalOpen(true);
        console.log("merchantName value ", merchant)
        const payload = {
            merchantName: merchant.merchantName
        };

        try {
            console.log("jwt value ", jwtToken)
            const requestOptions = {
                method: "POST",
                headers: {
                    accept: "*/*",
                    "content-type": "application/json",
                    Authorization: jwtToken,
                },
                body: JSON.stringify(payload),
            };

            const url = process.env.REACT_APP_PAYPAL_ONBOARDING_URL;
            console.log("url", url, jwtToken)
            let response = await fetch(url, requestOptions);

            console.log("response", response)
            let data = await response.json();
            console.log({ data });
            if (data.error) {
                throw new Error(data.error);
            }

            // Assuming the response contains a link to be opened in a new tab
            if (data.link) {
                handleLinkReceived(data);
            } else {
                throw new Error('No link received');
            }
        } catch (error) {
            console.error('Error during the API call:', error);
            alert(error.message);
        } finally {
            setLoading(false);
            setModalOpen(false);
        }
    };


    // Automatically trigger connectToPayPal when showModal becomes true
    useEffect(() => {
        if (showModal) {
            connectToPayPal();
        }
    }, [showModal]);

    console.log("showDisconnectModal value", showDisconnectModal)
    // useEffect(() => {
    //    if (showDisconnectModal) {
    //        disconnectFromPaypal();
    //    }
    //}, [showDisconnectModal]);

    // useEffect(() => {
    //     if (!showModal) {
    //         updateConnectionStatus("Connect");
    //     }
    // }, [showModal, updateConnectionStatus]);


    // Tailwind CSS classes for showing and hiding the modal with transition
    const modalClasses = showModal
        ? "fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 transition-opacity opacity-100"
        : "fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 transition-opacity opacity-0 pointer-events-none";

    const modalDisconnectClasses = showDisconnectModal
        ? "fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 transition-opacity opacity-100"
        : "fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 transition-opacity opacity-0 pointer-events-none";

    return (
        <>
            {connectionStatus === "Disconnect" &&
                <div className={`${modalDisconnectClasses}`} style={{ transition: "opacity 400ms ease-in-out" }}>

                    <PaypalDisconnectModal
                        open={showDisconnectModal}
                        disconnectFromPaypal={disconnectFromPaypal}
                        showErrorAlert={showErrorAlert}
                        errorMessage={errorMessage}
                        setShowErrorAlert={setShowErrorAlert}
                        setOpen={setShowDisconnectModal}
                    />

                    {showConfirmation === true && (
                        <ConfirmationPaypalDisconnect
                            openConfirmation={showConfirmation}
                            setOpenConfirmation={setShowConfirmation}
                        />
                    )}

                </div>
            }

            {connectionStatus === "Connect" &&

                <div className={`${modalClasses}`} style={{ transition: "opacity 400ms ease-in-out" }}>
                    <div className="flex flex-col items-center">
                        <img src={paypalBig} alt="PayPal" className="mb-4 w-40 h-auto" />
                        <p className="text-white text-sm mb-4 text-center">A new window has opened. Sign into your PayPal
                            <br />
                            account to complete the onboarding.</p>
                        <button
                            onClick={() => setShowModal(false)}
                            className="bg-white text-black font-medium rounded-lg text-sm px-4 py-2 text-center min-w-[100px]"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            }

            {/* Ensure that the modal is not unmounted immediately when `showModal` is false to allow the exit transition to complete */}
            {showModal && connectionStatus === "Connect" && (
                <div onClick={() => setShowModal(false)} className="fixed inset-0 z-40 bg-black opacity-50"></div>
            )}
            {/* Ensure that the modal is not unmounted immediately when `showModal` is false to allow the exit transition to complete */}
            {showDisconnectModal && connectionStatus === "Disconnect" && (
                <div onClick={() => setShowDisconnectModal(false)} className="fixed inset-0 z-40 bg-black opacity-50"></div>
            )}
        </>
    );
};

export default PaypalIntegration;