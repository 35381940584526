import React, { useState, useEffect } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import FilterButton from "../partials/actions/FilterButton";
import DonationDatePicker from "../partials/actions/DonationDatePicker";
import DashboardCard01 from "../partials/dashboard/DashboardCard01";
import DashboardCard02 from "../partials/dashboard/DashboardCard02";
import DashboardCard03 from "../partials/dashboard/DashboardCard03";
import DashboardCard04 from "../partials/dashboard/DashboardCard04";
import DashboardCard05 from "../partials/dashboard/DashboardCard05";
import DashboardCard06 from "../partials/dashboard/DashboardCard06";
import DashboardCard07 from "../partials/dashboard/DashboardCard07";
import DashboardCard09 from "../partials/dashboard/DashboardCard09";
import DashboardCard10 from "../partials/dashboard/DashboardCard10";
import DashboardCard11 from "../partials/dashboard/DashboardCard11";
import DonationsTable from "../partials/dashboard/DonationsTable";
import DonorsTable from "../partials/dashboard/DonorsTable";
import DonationsChart from "../partials/dashboard/DonationsChart";

function Dashboard(props) {
  //logic for switching tables. Used in onClick below
  function switchTables() {
    setTableIndex((tableIndex + 1) % 2);
  }

  //constants
  const tables = [DonationsTable, DonorsTable];
  const tableNames = ["Donors Table", "Donations Table"];

  //Define default timerange
  const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
  let today = new Date();
  // increment today forward by 1 day
  today.setDate(today.getDate() + 1);
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() - 6);
  const startTimerange =
    tomorrow.toLocaleDateString("en-US", DATE_OPTIONS) +
    " - " +
    today.toLocaleDateString("en-US", DATE_OPTIONS);

  //react hooks
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [timerange, setTimerange] = useState(startTimerange);
  const [tableIndex, setTableIndex] = useState(0);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        groups={props.groups}
      />

      {/* Content area */}
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          groups={props.groups}
        />

        <main>
          <div className="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
            {/* Welcome banner */}
            <WelcomeBanner />

            {/* Dashboard actions */}
            <div className="mb-8 sm:flex sm:items-center sm:justify-between md:items-end md:justify-end">
              {/* Left: Avatars */}
              {/* <DashboardAvatars /> */}

              {/* Right: Actions */}
              <div className="grid grid-flow-col gap-2 sm:auto-cols-max sm:justify-end">
                {/* Filter button */}
                {/* <FilterButton /> */}
                {/* DonationDatePicker built with flatpickr */}
                <DonationDatePicker setTimerange={setTimerange} />
                {/* Add view button */}
                <button
                  onClick={switchTables}
                  className="btn bg-light hover:bg-dark text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 flex-shrink-0 fill-current opacity-50"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                    />
                  </svg>
                  <span className="xs:block ml-2 hidden">
                    Switch to {tableNames[tableIndex]}
                  </span>
                </button>
              </div>
            </div>

            <div className="mb-4 grid grid-cols-12 gap-6">
              <DonationsChart timerange={timerange} />
            </div>

            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              {React.createElement(tables[tableIndex], {
                timerange: timerange,
              })}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
