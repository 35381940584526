import React, { useRef, useState, useEffect, useCallback } from "react";
import Dropdown from "../../dropdowns/ElementDropdown";
import MultiSelectDropdown from "../../dropdowns/MultiSelectDropdown";
import Toggle from "../../Toggle";
import ReactTooltip from "react-tooltip";
import { Draggable } from "react-beautiful-dnd";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import { QRCodeSVG } from "qrcode.react";
import ReactDOMServer from 'react-dom/server';
import QRCode from 'qrcode.react';
import ReactDOM from 'react-dom';
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { createRoot } from 'react-dom/client';

const INPUT_TYPE_MAPPING = {
  FUNDRAISING_BAR: "Fundraising Bar",
  QR_CODE: "QR Code",
  DONOR_LIST: "Donor List",
};

function ElementsTabContent(props) {
  const {
    moveCard,
    elements,
    id,
    index,
    originalElements,
    handleElementChange,
    removeElementsTab,
    selectedValueSoFar,
    setElementListType,
    setElementSizeOfBar,
    setElementGoal,
    setElementDonationOffset,
    setElementNumberOfDonationOffset,
    setElementQRCodeType,
    setElementURL,
    elementID,
    elementsSaved,
    setDataIndexValue,
    merchantName,
    formName,
    submit,
    color,
  } = props;

  const [copiedState, setCopiedState] = useState("Copied!");
  const [dataForQRCode, setDataForQRCode] = useState("");

  const [resizedImage, setResizedImage] = useState(null);
  const [image, setImage] = useState(
    `${process.env.REACT_APP_IMAGE_URL
    }/logo/${merchantName}_Logo.png?timestamp=${Date.now().toString()}`
  );

  const allValues = ["Donor List", "Fundraising Bar", "QR Code"];

  // Convert selectedValueSoFar using the mapping
  console.log("selectedValueSoFar", selectedValueSoFar);
  console.log("elements.type value ", elements.type);
  console.log("ORIGINAL ELEMENT", originalElements);
  // Now, filter based on human-readable values
  let differenceArray = allValues.filter(
    (element) => !selectedValueSoFar || !selectedValueSoFar.includes(element)
  );
  console.log("diff ", differenceArray);
  //  console.log("elements", elements);
  // console.log(index, "index value")
  console.log(
    "disable expression for value of ",
    index,
    "now next part: ",
    elements.isSaved === true &&
    elements.savedIndicies &&
    elements.savedIndicies.includes(index)
  );
  // console.log("testing expresison", elements.savedIndicies.includes(index))
  let disableExpression =
    elements.isSaved === true &&
    elements.savedIndicies &&
    elements.savedIndicies.includes(index);

  const iframe = `<iframe src="${process.env.REACT_APP_DONATION_FORM_BASE_URL
    }/?nonprofitName=${encodeURIComponent(
      merchantName
    )}&elementName=${encodeURIComponent(
      formName
    )}" height="800" width="100%" allowtransparency="true" frameborder="0" allow="payment"></iframe>`;

  useEffect(() => {
    // When dataSubmitted changes to true, generate the QR code data
    if (id.length === 36) {
      generateQRCodeData();
    }
  }, [id]);

  const generateQRCodeData = () => {
    // You can set any data you want to encode in the QR code based on form input values
    const baseURL =
      process.env.REACT_APP_ENV === "development"
        ? "https://dev-link.charitystack.com"
        : "https://link.charitystack.com";
    const qrCodeURL = `${baseURL}/elements-config?elementID=${id}`;
    setDataForQRCode(qrCodeURL);
  };
  const convertImageToDataUri = (url, callback) => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous'); // Attempt to address CORS issues
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      const dataUri = canvas.toDataURL('image/png');
      callback(dataUri, img.width, img.height); // Pass the Data URI and dimensions to the callback
    };
    img.src = url;
  };


  function removeWhitespaceAndGenerateQRCode(src, callback) {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      // Get image data for the whole image
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      // Function to determine if a pixel is white or nearly-white.
      const isNonWhitePixel = (r, g, b, alpha, tolerance = 150) => {
        return !(r > tolerance && g > tolerance && b > tolerance) || alpha === 0;
      };

      let minX = canvas.width, minY = canvas.height, maxX = 0, maxY = 0;

      // Scan each pixel and find the bounds of the logo
      for (let x = 0; x < canvas.width; x++) {
        for (let y = 0; y < canvas.height; y++) {
          const index = (y * canvas.width + x) * 4;
          const r = data[index];
          const g = data[index + 1];
          const b = data[index + 2];
          const alpha = data[index + 3];

          if (isNonWhitePixel(r, g, b, alpha)) {
            if (x < minX) minX = x;
            if (y < minY) minY = y;
            if (x > maxX) maxX = x;
            if (y > maxY) maxY = y;
          }
        }
      }

      // Create a new canvas to draw the cropped image
      const croppedWidth = maxX - minX;
      const croppedHeight = maxY - minY;
      const croppedCanvas = document.createElement('canvas');
      const croppedCtx = croppedCanvas.getContext('2d');
      croppedCanvas.width = croppedWidth;
      croppedCanvas.height = croppedHeight;

      // Draw the cropped image onto the new canvas
      croppedCtx.drawImage(
        canvas,
        minX,
        minY,
        croppedWidth,
        croppedHeight,
        0,
        0,
        croppedWidth,
        croppedHeight
      );

      // Export to Data URI and generate QR code
      const croppedDataUri = croppedCanvas.toDataURL();
      callback(croppedDataUri);
    };
    img.src = src;
  }


  const downloadQRCodeImageDetailed = () => {

    const imageUrl = image;


    convertImageToDataUri(imageUrl, (dataUri, imgWidth, imgHeight) => {

      // Calculate dimensions based on your needs. Here, as an example,
      // we maintain the original dimensions. Adjust as necessary.
      let adjustedWidth = imgWidth;
      let adjustedHeight = imgHeight;
      // You might want to scale the dimensions down if they are too large
      // or set maximum limits.

      const qrCodeHTMLString = ReactDOMServer.renderToStaticMarkup(
        <div className="flex flex-col items-center">
          <div style={{ backgroundColor: "transparent", visibility: "visible" }}>
            <div className="flex flex-col items-center p-1">
              <QRCodeSVG
                value={dataForQRCode}
                size={568.5} // Size of the QR Code itself
                level={"L"}
                includeMargin={false}
                imageSettings={{
                  src: dataUri, // Use the Data URI here
                  x: undefined, // Positioning inside the QR code, adjust if needed
                  y: undefined, // Positioning inside the QR code, adjust if needed
                  height: adjustedHeight, // Set dynamically
                  width: adjustedWidth, // Set dynamically
                  excavate: true,
                }}
              />
              <div
                className="mx-9 bg-black px-5 py-4 pt-4 text-white"
                style={{
                  backgroundColor: color,
                  textAlign: "center",
                  borderBottomLeftRadius: "30px",
                  borderBottomRightRadius: "30px",
                  width: 568.5,
                  fontSize: 40,
                  height: "auto",
                }}
              >
                Scan to Donate
              </div>
            </div>
          </div>
        </div>
      );



      const tempContainer = document.createElement('div');
      tempContainer.innerHTML = qrCodeHTMLString;
      document.body.appendChild(tempContainer); // Append it to the body to ensure styles are applied

      // Use html2canvas to capture the QR code from the temporary container
      html2canvas(tempContainer.firstChild, { useCORS: true }).then(canvas => {
        canvas.toBlob(blob => {
          saveAs(blob, `CharityStack QR Code - ${formName}.png`);
          document.body.removeChild(tempContainer); // Clean up by removing the container
        });
      });
    });
  };

  {/* <div className="flex flex-col items-center hidden">
                <div
                  id="qr-code-container-hidden"
                  style={{
                    backgroundColor: "transparent",
                    visibility: "hidden"
                  }}
                />
                <div className="flex flex-col items-center p-1">
                  <QRCode
                    value={dataForQRCode}
                    size={568.5}
                    bgColor={"white"}
                  />
                  <div
                    className="mx-9 bg-black px-5 py-4 pt-4 text-white"
                    style={{
                      backgroundColor: color,
                      textAlign: "center",
                      borderBottomLeftRadius: "30px",
                      borderBottomRightRadius: "30px",
                      width: 568.5,
                      fontSize: 40,
                      height: "auto",
                    }}
                  >
                    Scan to Donate
                  </div>
                </div>
              </div> */}


  const downloadQRCodeImage = () => {
 
  // Create a container for the QR code
  const tempDiv = document.createElement('div');
  tempDiv.style.position = 'absolute';
  tempDiv.style.left = '-9999px'; // Hide the div off-screen
  document.body.appendChild(tempDiv);

  // Use the new createRoot API to render the QR code into our div
  const root = createRoot(tempDiv);
  root.render(
    <div className="flex flex-col items-center">
      <div style={{ backgroundColor: "transparent", visibility: "visible" }}>
        <div className="flex flex-col items-center p-1">
          <QRCode
            value={dataForQRCode}
            size={568.5} // Size of the QR Code itself
            bgColor="white"
          />
          <div
            className="mx-9 bg-black px-5 py-4 pt-4 text-white"
            style={{
              backgroundColor: color,
              textAlign: "center",
              borderBottomLeftRadius: "30px",
              borderBottomRightRadius: "30px",
              width: 568.5,
              fontSize: 40,
              height: "auto",
            }}
          >
            Scan to Donate
          </div>
        </div>
      </div>
    </div>
  );

  // Capture the QR code after it has been rendered
  setTimeout(() => {
    html2canvas(tempDiv, { useCORS: true }).then(canvas => {
      canvas.toBlob(blob => {
        saveAs(blob, `CharityStack QR Code - ${formName}.png`);
        // Clean up by removing the div
        document.body.removeChild(tempDiv);
      });
    });
  }, 500); // A timeout is used to allow the QR code to render
};



  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className="border-b border-gray-300"
        >
          <div {...provided.dragHandleProps}>
            <Bars3Icon
              className="ml-auto mr-4 mt-2 h-6 w-6"
              aria-hidden="true"
            />
          </div>

          {/* Form name */}
          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
            <label
              htmlFor="form-name"
              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
              Element Type*
              <svg
                data-tip
                data-for="inputTypeToolTip"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="text-primary ml-1 inline-block h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
              <ReactTooltip id="inputTypeToolTip" effect="solid" place="right">
                <p className="text-sm text-white">
                  Donor List: Track and showcase either recent donors or top
                  donors for a specific Fundraiser
                </p>
                <p className="text-sm text-white">
                  Fundraising Bar: Track and showcase a fundraising goal for a
                  specific Fundraiser.{" "}
                </p>
                <p className="text-sm text-white">
                  QR Code: Create an image of a QR code that redirects a donor
                  to the donation page or any other website URL.{" "}
                </p>
              </ReactTooltip>
            </label>
            <div className="sm:col-span-2">
              {/* TODO: Add Multi Selection Dropdown to list of values when implemented on donation form */}
              {disableExpression === false && (
                <div>
                  <Dropdown
                    dataIndex={index}
                    values={differenceArray}
                    selectedValue={
                      elements.type == "Select an Element"
                        ? elements.type
                        : INPUT_TYPE_MAPPING[elements.type]
                    }
                    setSelectedValue={(event) => {
                      handleElementChange(event);
                      setDataIndexValue(index);
                    }}
                    width={"w-96"}
                    style={{ display: "inline-block", marginRight: "8px" }}
                  // disabled={elements.isSaved === true && elements.savedIndicies && elements.savedIndicies.includes(index)}
                  />
                </div>
              )}
              {disableExpression === true && (
                <div>
                  <Dropdown
                    dataIndex={index}
                    values={differenceArray}
                    selectedValue={
                      elements.type == "Select an Element"
                        ? elements.type
                        : INPUT_TYPE_MAPPING[elements.type]
                    }
                    setSelectedValue={(event) => {
                      handleElementChange(event);
                      setDataIndexValue(index);
                    }}
                    width={"w-96"}
                    style={{ display: "inline-block", marginRight: "8px" }}
                    disabled={true}
                  />
                </div>
              )}
            </div>
          </div>

          {/*DONOR LIST */}
          {elements.type === "DONOR_LIST" && (
            <div>
              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <label
                  htmlFor="form-name"
                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                >
                  List Type*
                  <svg
                    data-tip
                    data-for="listTypeTooltip"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="text-primary ml-1 inline-block h-5 w-5"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <ReactTooltip
                    id="listTypeTooltip"
                    effect="solid"
                    place="right"
                  >
                    <p className="text-sm text-white">
                      This is where you can choose what kind of Donation List{" "}
                    </p>
                    <p className="text-sm text-white">
                      you want. Data will be aggregated in the method you pick.
                    </p>
                  </ReactTooltip>
                </label>

                <div className="sm:col-span-2">
                  <div className="flex space-x-10 pt-2 text-sm sm:col-span-2">
                    <input
                      type="radio"
                      value="TOP_DONORS"
                      name={`listType-${id}`} // Add an id to the name attribute
                      onClick={() => setElementListType(index, "TOP_DONORS")} // Use the function to set listType
                      style={{
                        backgroundColor:
                          elements.listType === "TOP_DONORS"
                            ? "green"
                            : "white",
                        marginTop: "4px",
                        marginRight: "5px",
                      }}
                    />
                    Top Donors&nbsp;&nbsp;
                    <input
                      type="radio"
                      value="RECENT_DONORS"
                      name={`listType-${id}`} // Add an id to the name attribute
                      onClick={() => setElementListType(index, "RECENT_DONORS")} // Use the function to set listType
                      style={{
                        backgroundColor:
                          elements.listType === "RECENT_DONORS"
                            ? "green"
                            : "white",
                        marginTop: "4px",
                        marginRight: "5px",
                      }}
                    />
                    Recent Donors
                  </div>
                </div>
              </div>

              {/* Embed Link */}
              {
                //disableExpression === true &&
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label
                      htmlFor="project-description"
                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      Embed link
                      <svg
                        data-tip
                        data-for="embedTooltip"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="text-primary ml-1 inline-block h-5 w-5"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <ReactTooltip
                        id="embedTooltip"
                        effect="solid"
                        place="right"
                      >
                        <p className="text-sm text-white">
                          Copy and paste the code on the right into your
                        </p>
                        <p className="text-sm text-white">
                          website after clicking "Create". If nothing appears,
                        </p>
                        <p className="text-sm text-white">
                          you may have forgotten to embed the CharityStack
                          script
                        </p>
                        <p className="text-sm text-white">
                          file. You can find this on the Settings Page.
                        </p>
                      </ReactTooltip>
                    </label>

                    <p className="text-sm text-gray-500">
                      Embed this code on your website to collect donations.
                    </p>
                    <br />

                    <a
                      className="text-primary hover:text-secondary group flex cursor-pointer items-center space-x-2.5 text-sm font-medium"
                      data-tip
                      data-for="copyIframe"
                      data-event="click"
                    ></a>

                    <ReactTooltip
                      id="copyIframe"
                      clickable={true}
                      effect="solid"
                      place="bottom"
                      afterShow={async () => {
                        try {
                          await navigator.clipboard.writeText(iframe);
                          setCopiedState("Copied!");
                        } catch (err) {
                          console.error("Failed to copy: ", err);
                          setCopiedState("Failed to copy");
                        } finally {
                          setTimeout(ReactTooltip.hide, 1000);
                        }
                      }}
                      delayHide={500}
                      delayUpdate={500}
                    >
                      <span className="text-sm text-white">{copiedState}</span>
                    </ReactTooltip>
                  </div>
                  <div className="sm:col-span-2">
                    <textarea
                      value={
                        id.length === 36
                          ? `<div data-entity="DONOR_LIST" data-source="CharityStack" id="${id}"></div>`
                          : ""
                      }
                      id="project-description"
                      name="project-description"
                      rows={5}
                      className={
                        "focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm " +
                        (id.length === 36 ? "bg-white" : "bg-gray-100")
                      }
                      defaultValue={""}
                      disabled
                    />
                  </div>
                </div>
              }
            </div>
          )}

          {/*FUNRAISING BAR*/}
          {elements.type === "FUNDRAISING_BAR" && (
            <div>
              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <div>
                  <label
                    htmlFor="color"
                    className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    {" "}
                    Size*
                    <svg
                      data-tip
                      data-for="sizeTooltip"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="text-primary ml-1 inline-block h-5 w-5"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <ReactTooltip id="sizeTooltip" effect="solid" place="right">
                      <p className="text-sm text-white">
                        Regular: The fundraising bar will match the width of the
                        donation form.{" "}
                      </p>
                      <p className="text-sm text-white">
                        Fullscreen: The fundraising bar will expand in width
                        across the website page.
                      </p>
                    </ReactTooltip>
                  </label>
                </div>
                <div className="inline-flex">
                  <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
                    <input
                      type="radio"
                      value="false"
                      name="sizeOfBar"
                      onClick={() => setElementSizeOfBar(index, "REGULAR")}
                      style={{
                        backgroundColor:
                          elements.embedSize === "REGULAR" ? "green" : "white",
                        marginTop: "4px",
                        marginRight: "5px",
                      }}
                    />
                    Regular&nbsp;&nbsp;
                    <input
                      type="radio"
                      value="false"
                      name="sizeOfBar"
                      onClick={() => setElementSizeOfBar(index, "FULLSCREEN")}
                      style={{
                        backgroundColor:
                          elements.embedSize === "FULLSCREEN"
                            ? "green"
                            : "white",
                        marginTop: "4px",
                        marginRight: "5px",
                      }}
                    />
                    Fullscreen
                  </div>
                </div>
              </div>
              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <div>
                  <label
                    htmlFor="color"
                    className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Goal*
                    <svg
                      data-tip
                      data-for="goalTooltip"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="text-primary ml-1 inline-block h-5 w-5"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <ReactTooltip id="goalTooltip" effect="solid" place="right">
                      <p className="text-sm text-white">
                        Enter a number for the goal shown{" "}
                      </p>
                      <p className="text-sm text-white">
                        on the fundraising bar.
                      </p>
                    </ReactTooltip>
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <input
                    type="tel"
                    name="goalOfFundraisingBar"
                    id="oneTimeDefault"
                    className="focus:border-secondary focus:ring-secondary col-start-1 block w-96 rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                    placeholder="$10"
                    value={elements.goal}
                    onChange={(e) =>
                      setElementGoal(
                        index,
                        e.target.value.replace(/[^0-9]/g, "")
                      )
                    }
                  />
                </div>
              </div>
              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <div>
                  <label
                    htmlFor="color"
                    className="text-xs font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Donation Amount Offset
                    <svg
                      data-tip
                      data-for="donationAmountOffsetTooltip"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="text-primary ml-1 inline-block h-5 w-5"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <ReactTooltip id="donationAmountOffsetTooltip" effect="solid" place="right">
                      <p className="text-sm text-white">
                        Enter a number to offset the total donation amount{" "}
                      </p>
                      <p className="text-sm text-white">
                        on the corresponding fundraising bar.
                      </p>
                    </ReactTooltip>
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <input
                    type="tel"
                    name="raisedOffset"
                    id="oneTimeDefault"
                    className="focus:border-secondary focus:ring-secondary col-start-1 block w-96 rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                    placeholder="$220"
                    value={elements.raisedOffset}
                    onChange={(e) =>
                      setElementNumberOfDonationOffset(
                        index,
                        e.target.value.replace(/[^0-9]/g, "")
                      )
                    }
                  />
                </div>
              </div>
              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <div>
                  <label
                    htmlFor="color"
                    className="text-xs font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Number of Donations Offset
                    <svg
                      data-tip
                      data-for="numberOfDonationsTooltip"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="text-primary ml-1 inline-block h-5 w-5"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <ReactTooltip id="numberOfDonationsTooltip" effect="solid" place="right">
                      <p className="text-sm text-white">
                      Enter a number to offset the total number of donations{" "}
                      </p>
                      <p className="text-sm text-white">
                      on the corresponding fundraising bar.
                      </p>
                    </ReactTooltip>
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <input
                    type="tel"
                    name="donationsOffset"
                    id="oneTimeDefault"
                    className="focus:border-secondary focus:ring-secondary col-start-1 block w-96 rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                    placeholder="1,995"
                    value={elements.donationsOffset}
                    onChange={(e) =>
                      setElementDonationOffset(
                        index,
                        e.target.value.replace(/[^0-9]/g, "")
                      )
                    }
                  />
                </div>
              </div>
              {/* Embed Link */}
              {
                //disableExpression === true &&
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label
                      htmlFor="project-description"
                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      Embed link
                      <svg
                        data-tip
                        data-for="embedTooltip"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="text-primary ml-1 inline-block h-5 w-5"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <ReactTooltip
                        id="embedTooltip"
                        effect="solid"
                        place="right"
                      >
                        <p className="text-sm text-white">
                          Copy and paste the code on the right into your
                        </p>
                        <p className="text-sm text-white">
                          website after clicking "Create". If nothing appears,
                        </p>
                        <p className="text-sm text-white">
                          you may have forgotten to embed the CharityStack
                          script
                        </p>
                        <p className="text-sm text-white">
                          file. You can find this on the Settings Page.
                        </p>
                      </ReactTooltip>
                    </label>

                    <p className="text-sm text-gray-500">
                      Embed this code on your website to collect donations.
                    </p>
                    <br />

                    <a
                      className="text-primary hover:text-secondary group flex cursor-pointer items-center space-x-2.5 text-sm font-medium"
                      data-tip
                      data-for="copyIframe"
                      data-event="click"
                    ></a>

                    <ReactTooltip
                      id="copyIframe"
                      clickable={true}
                      effect="solid"
                      place="bottom"
                      afterShow={async () => {
                        try {
                          await navigator.clipboard.writeText(iframe);
                          setCopiedState("Copied!");
                        } catch (err) {
                          console.error("Failed to copy: ", err);
                          setCopiedState("Failed to copy");
                        } finally {
                          setTimeout(ReactTooltip.hide, 1000);
                        }
                      }}
                      delayHide={500}
                      delayUpdate={500}
                    >
                      <span className="text-sm text-white">{copiedState}</span>
                    </ReactTooltip>
                  </div>
                  <div className="sm:col-span-2">
                    <textarea
                      value={
                        id.length === 36
                          ? `<div data-entity="FUNDRAISING_BAR" data-source="CharityStack" id="${id}"></div>`
                          : ""
                      }
                      id="project-description"
                      name="project-description"
                      rows={5}
                      className={
                        "focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm " +
                        (id.length === 36 ? "bg-white" : "bg-gray-100")
                      }
                      defaultValue={""}
                      disabled
                    />
                  </div>
                </div>
              }
            </div>
          )}

          {/*QR CODE*/}
          {elements.type === "QR_CODE" && (
            <div>
              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <div>
                  <label
                    htmlFor="color"
                    className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    {" "}
                    Type*
                    <svg
                      data-tip
                      data-for="sizeTooltip"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="text-primary ml-1 inline-block h-5 w-5"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <ReactTooltip id="sizeTooltip" effect="solid" place="right">
                      <p className="text-sm text-white">
                        Hosted: The QR Code will navigate to the Hosted Page for
                        this fundraiser.{" "}
                      </p>
                      <p className="text-sm text-white">
                        Custom: The QR Code will navigate to a custom URL.
                      </p>
                    </ReactTooltip>
                  </label>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex space-x-10 pt-2 text-sm sm:col-span-2">
                    <input
                      type="radio"
                      value="false"
                      name="qrCodeType" //onClick={() => setElementQRCodeType(index, 'HOSTED_PAGE')}
                      onClick={() => {
                        // Set the URL to the hosted page URL
                        setElementQRCodeType(index, "HOSTED_PAGE");
                        setElementURL(
                          index,

                          process.env.REACT_APP_ENV === "production"
                            ? `https://donate.charitystack.com/${elementID}`
                            : `https://dev.d3qaovrr6ze9o9.amplifyapp.com/${elementID}`
                        );
                      }}
                      style={{
                        backgroundColor:
                          elements.qrCodeType === "HOSTED_PAGE"
                            ? "green"
                            : "white",
                        marginTop: "4px",
                        marginRight: "5px",
                      }}
                    />
                    Hosted Page &nbsp;&nbsp;
                    <input
                      type="radio"
                      value="false"
                      name="qrCodeType"
                      onClick={() => {
                        setElementQRCodeType(index, "CUSTOM_PAGE");
                        setElementURL(index, "");
                      }}
                      style={{
                        backgroundColor:
                          elements.qrCodeType === "CUSTOM_PAGE"
                            ? "green"
                            : "white",
                        marginTop: "4px",
                        marginRight: "5px",
                      }}
                    />
                    Custom Page
                  </div>
                </div>
              </div>

              {elements.qrCodeType === "CUSTOM_PAGE" && (
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label
                      htmlFor="color"
                      className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      URL*
                      <svg
                        data-tip
                        data-for="urlTooltip"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="text-primary ml-1 inline-block h-5 w-5"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <ReactTooltip
                        id="urlTooltip"
                        effect="solid"
                        place="right"
                      >
                        <p className="text-sm text-white">
                          This is where you can customize the url of the{" "}
                        </p>
                        <p className="text-sm text-white">
                          QR Code. Scanning the QR Code will take you here.
                        </p>
                      </ReactTooltip>
                    </label>
                  </div>

                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      name="urlOfQRCode"
                      id="urlOfQRCode"
                      className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                      placeholder=""
                      value={elements.url}
                      onChange={(e) => setElementURL(index, e.target.value)}
                    />
                  </div>
                </div>
              )}

              {/* Download Image */}
              {id.length === 36 && elements.url && (
                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label
                      htmlFor="color"
                      className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      Download Image*
                      <svg
                        data-tip
                        data-for="downloadTooltip"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="text-primary ml-1 inline-block h-5 w-5"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <ReactTooltip
                        id="downloadTooltip"
                        effect="solid"
                        place="right"
                      >
                        <p className="text-sm text-white">
                          This is where you can download a{" "}
                        </p>
                        <p className="text-sm text-white">High Quality QR Code image.</p>
                      </ReactTooltip>
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <button type="button" onClick={downloadQRCodeImage}>
                      Click here to download QR code
                    </button>
                  </div>
                </div>
              )}
              {/* Display the container with QR code and text */}
              {id.length === 36 && (
                <div className="flex flex-col items-center">
                  <div
                    id="qr-code-container"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="flex flex-col items-center p-1">
                      <QRCode
                        value={dataForQRCode}
                        size={189.5}
                        bgColor={"white"}
                      />
                      <div
                        className="mx-9 bg-black px-7 py-2 pt-2 text-white"
                        style={{
                          backgroundColor: color,
                          textAlign: "center",
                          borderBottomLeftRadius: "10px", // Adjust the value as needed
                          borderBottomRightRadius: "10px", // Adjust the value as needed
                          width: 189.5,
                          height: "auto", // Set height to 'auto' to adjust based on content
                        }}
                      >
                        Scan to Donate
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="mb-2 mr-4 flex justify-end">
            <div
              className="flex cursor-pointer"
              data-index={index}
              onClick={(e) => removeElementsTab(index)}
            >
              <TrashIcon
                data-index={index}
                className="mr-2 mt-0.5 h-5 w-5 text-red-500"
                aria-hidden="true"
              />
              <div data-index={index} className="text-red-500">
                {" "}
                remove Element{" "}
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

export default ElementsTabContent;
