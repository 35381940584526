import React, { useEffect, useState } from "react";
import StripeManagement from "./StripeManagement";
import PCISelfAssessment from "./PCISelfAssessment";

function Compliance(props) {
  const [tab, setTab] = useState("PCI");

  useEffect(() => {
    console.log("tab", tab);
    // setEditMode(false);
  }, [tab]);

  return (
    <div
      className="flex grid grid-cols-7 rounded-md border-2 border-gray-300"
      style={{ minHeight: "calc(100vh - 300px)" }}
    >
      <div className="col-span-2 row-span-full border-r-2 border-gray-200">
        <div
          onClick={() => setTab("PCI")}
          className="cursor-pointer border-b-2 border-gray-200 p-4 hover:bg-gray-200"
        >
          <div className="text-base font-medium text-black">
            PCI Self Assessment
          </div>
          <div className="text-sm leading-4 text-gray-400">
            Complete your PCI Self Assessment
          </div>
        </div>
        {props.merchant?.defaultPaymentProcessor === "STRIPE" && (
          <div
            onClick={() => setTab("STRIPE")}
            className="cursor-pointer border-b-2 border-gray-200 p-4 hover:bg-gray-200"
          >
            <div className="text-base font-medium text-black">
              Payments Account Management
            </div>
            <div className="text-sm leading-4 text-gray-400">
              Manage your Stripe account details
            </div>
          </div>
        )}
        {/* <div
          onClick={() => setTab("THEME")}
          className="cursor-pointer border-b-2 border-gray-200 p-4 hover:bg-gray-200"
        >
          <div className="text-base font-medium text-black">Theme</div>
          <div className="text-sm leading-4 text-gray-400">
            Select theme of system
          </div>
        </div> */}
      </div>
      <div className="relative col-span-5 p-4">
        {tab === "PCI" && (
          <PCISelfAssessment
            merchant={props.merchant}
            setSuccessMessage={props.setSuccessMessage}
            setShowSuccessAlert={props.setShowSuccessAlert}
          />
        )}

        <div style={tab === "STRIPE" ? {} : { display: "none" }}>
          <StripeManagement />
        </div>
      </div>
    </div>
  );
}

export default Compliance;
