/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Dialog, Transition } from "@headlessui/react";
import { CreditCardIcon } from "@heroicons/react/24/outline";
import {
  BanknotesIcon,
  BuildingLibraryIcon,
  CheckBadgeIcon,
} from "@heroicons/react/20/solid";
import Card from "./payment-methods/Card";
import BankTransfer from "./payment-methods/BankTransfer";
import ErrorAlert from "./alerts/ErrorAlert";
import ClipLoader from "react-spinners/ClipLoader";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import StripeCheckout from "./StripeCheckout";

export default function CreateTicket(props) {
  const {
    selectedSubscription,
    donationAmount,
    frequency,
    CreatePaymentBank,
    CreatePaymentCard,
    handleFreeTicketPurchase,
    tickets,
    ticketMapping,
    setInputtedTickets,
    inputtedTickets,
    event,
    errorMessageFromParent,
    perTimeIndicator,
    feeCovered,
    setFeeCovered,
    merchant,
  } = props;

  const cancelButtonRef = useRef(null);

  const [jwtToken, setJWTToken] = useState(null);
  const [sendingData, setSendingData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [eventName, setEventName] = useState();
  const [netAmounts, setAmount] = useState(0.0);

  const [calcFee, setCalcFee] = useState(0.0);
  const [netAmount, setNetAmount] = useState("0.00");

  console.log("tickets", tickets, ticketMapping);

  let fund = "";

  const handleChange = (event) => {
    setFeeCovered(event.target.checked);
  };

  const UpdateFees = () => {
    let finalFee = 0;
    if (donationAmount > 0) {
      const calculatedFees =
        (parseFloat(donationAmount) + parseFloat(props.merchant["fixedFee"])) /
          (1 - parseFloat(props.merchant["percentFee"])) -
        parseFloat(donationAmount);

      if (!cardSelected) {
        setCalcFee(Math.min(calculatedFees, 25));
        finalFee = Math.min(calculatedFees, 25);
      } else {
        setCalcFee(calculatedFees);
        finalFee = calculatedFees;
      }
    } else {
      setCalcFee(0);
      finalFee = 0;
    }
    console.log("finalFee", finalFee, "donationAmount", donationAmount);
    if (feeCovered) {
      setNetAmount((parseFloat(donationAmount) + finalFee).toFixed(2));
    } else {
      setNetAmount(parseFloat(donationAmount).toFixed(2));
    }
  };

  useEffect(() => {
    UpdateFees();
  }, [donationAmount, feeCovered]);

  useEffect(() => {
    if (selectedSubscription != null) {
      fund = selectedSubscription;
    }
  }, [donationAmount, selectedSubscription]);

  useEffect(() => {
    if (event != null) {
      setEventName(event[0]);
    }
  }, [event]);

  const [showErrorAlert, setShowErrorAlert] = useState(false);

  // Card Related
  const [cardSelected, setCardSelected] = useState(true);
  const [iframeStates, setIframeStates] = useState(null);
  const [form, setForm] = useState(null);

  // Bank Transfer Related
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountType, setAccountType] = useState("CHECKING");

  const [stripePromise, setStripePromise] = useState(null);
  const stripeCardRef = useRef(null);

  const callStripeCardPaymentFunction = () => {
    stripeCardRef.current.handleSubmit();
  };

  useEffect(() => {
    console.log("stripeAccountID", props.merchant);
    if (props.merchant && props.merchant.stripeAccountID) {
      console.log("stripePromise");
      setStripePromise(
        loadStripe(process.env.REACT_APP_STRIPE_PK, {
          stripeAccount: props.merchant.stripeAccountID,
        })
      );
    }
  }, [props.merchant]);

  useEffect(() => {
    const myFunction = async () => {
      if (errorMessageFromParent !== "") {
        setShowErrorAlert(true);
        setErrorMessage(errorMessageFromParent);
        setSendingData(false);
      }
    };
    myFunction();
  }, [errorMessageFromParent]);

  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      setJWTToken(data["idToken"]["jwtToken"]);

      setForm(
        window.PaymentForm.card((state, binInformation) => {
          if (state === undefined) {
          } else {
            setIframeStates(state);
          }
        })
      );
    };
    myFunction();
  }, []);

  useEffect(() => {
    const myFunction = async () => {
      // if (props.open == false) {
      setForm(
        window.PaymentForm.card((state, binInformation) => {
          if (state === undefined) {
          } else {
            setIframeStates(state);
          }
        })
      );
      setErrorMessage("");
      setShowErrorAlert(false);
      setCardSelected(true);
      setAccountNumber("");
      setRoutingNumber("");
      setAccountType("CHECKING");

      if (props.merchant.defaultPaymentProcessor === "STRIPE") {
        setCardSelected(true);
      }
      // }
    };
    myFunction();
  }, [props.open]);

  useEffect(() => {
    console.log("merchant is", merchant);
    if (merchant?.defaultPaymentProcessor === "STRIPE") {
      setCardSelected(true);
      // setStripeAccountID(merchant.stripeAccountID);
      console.log("stripe account id", merchant.stripeAccountID);
    }
  }, [merchant]);

  let cardVisibility = {};
  let bankTransferVisibility = { display: "none" };

  let extraCardButtonClass =
    "bg-primary text-white hover:bg-secondary focus:ring-secondary border-transparent";
  let extraBankTransferButtonClass =
    "bg-white text-secondary hover:bg-gray059 focus:ring-gray-400 border-gray-300";

  if (!cardSelected) {
    cardVisibility = { display: "none" };
    bankTransferVisibility = {};

    extraCardButtonClass =
      "bg-white text-secondary hover:bg-gray059 focus:ring-gray-400 border-gray-300";
    extraBankTransferButtonClass =
      "bg-primary text-white hover:bg-secondary focus:ring-secondary border-transparent";
  }

  const message =
    parseFloat(donationAmount) == 0
      ? "Press Checkout button below to confirm the free ticket purchase"
      : cardSelected
      ? "Enter the credit card details, and then click the submit button below to process the payment method"
      : "Enter the bank transfer details, and then click the submit button below to process the payment method";

  // console.log(inputtedTickets);
  // Finix Error Checking
  const checkIframeErrors = () => {
    return (
      iframeStates.number.errorMessages.length == 0 &&
      iframeStates.expiration_date.errorMessages.length == 0 &&
      iframeStates.security_code.errorMessages.length == 0 &&
      iframeStates["address.postal_code"].errorMessages.length == 0
    );
  };

  const stripePayCard = (paymentMethod) => {
    try {
      CreatePaymentCard(null, paymentMethod);
    } catch {
      setShowErrorAlert(true);
      setErrorMessage("Something went wrong when submitting your request");
      setSendingData(false);
    }
  };

  const updateWithBankTransfer = async () => {
    if (accountNumber === "" || routingNumber === "") {
      setShowErrorAlert(true);
      setErrorMessage("Your banking's information is incomplete");
      setSendingData(false);
    }
    if (accountNumber != "" || routingNumber != "") {
      // console.log("sending bank data")
      setSendingData(true);
      CreatePaymentBank(accountNumber, routingNumber, accountType);
    }
  };

  const updateWithCard = () => {
    setSendingData(true);
    const iframesOkay = checkIframeErrors();
    if (iframesOkay === false) {
      setShowErrorAlert(true);
      setErrorMessage("Your card's information is incomplete");
      console.log("iframes not okay");
      setSendingData(false);
    } else {
      setShowErrorAlert(false);
      setErrorMessage("");
      try {
        form.submit(
          process.env.REACT_APP_FINIX_ENVIRONMENT,
          process.env.REACT_APP_FINIX_APPLICATION_ID,
          async function (err, res) {
            if (err) {
              alert("There was an error", err);
              setShowErrorAlert(true);
              setErrorMessage(err);
              setSendingData(false);
              return;
            }

            const tokenID = res.data.id;
            //iframe data = tokenID
            //                    console.log('tokenID', tokenID)
            CreatePaymentCard(tokenID, null);
          }
        );
      } catch (err) {
        setShowErrorAlert(true);
        setErrorMessage("Something went wrong when submitting your request");
        setSendingData(false);
      }
    }
  };

  let indicator = " due";
  if (frequency === "Recurring") {
    indicator = perTimeIndicator;
  }

  return (
    <Transition.Root
      show={props.open}
      as={Fragment}
      unmount={props.slideoverOpen}
    >
      <Dialog
        as="div"
        className="relative z-20"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
        unmount={props.slideoverOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          unmount={props.slideoverOpen}
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              unmount={props.slideoverOpen}
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {showErrorAlert && (
                  <div className="mb-2">
                    <ErrorAlert
                      message={errorMessage}
                      setShowErrorAlert={setShowErrorAlert}
                    />
                  </div>
                )}

                {parseFloat(donationAmount) == 0 || netAmount == 0 ? (
                  <div>
                    <div className="sm:flex sm:items-start">
                      {/* {
                                        showErrorAlert
                                        &&
                                        <ErrorAlert message={errorMessage} setShowErrorAlert={setShowErrorAlert} />
                                    } */}
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        <CheckBadgeIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <div className="">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Free Ticket Purchase
                          </Dialog.Title>
                          <div className="grid grid-cols-2">
                            <p className="text-primary col-span-1 mt-2 truncate text-sm font-medium">
                              $0
                            </p>
                            <p className="col-span-1 mt-2 flex items-center text-sm text-gray-500">
                              {/* <BanknotesIcon className="ml-1 mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
                              <span className="truncate">
                                Event: {eventName}
                              </span>
                            </p>
                          </div>
                        </div>

                        <div className="mt-2">
                          <p className="text-sm text-gray-500">{message}</p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      {!sendingData && (
                        <button
                          type="button"
                          name="deactivate"
                          className="bg-primary hover:bg-secondary focus:outline-none focus:ring-secondary inline-flex w-1/5 justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:text-sm"
                          onClick={(e) => {
                            handleFreeTicketPurchase();
                          }}
                        >
                          Checkout
                        </button>
                      )}

                      <button
                        type="button"
                        className="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => props.setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="sm:flex sm:items-start">
                      {/* {
                                        showErrorAlert
                                        &&
                                        <ErrorAlert message={errorMessage} setShowErrorAlert={setShowErrorAlert} />
                                    } */}
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        {cardSelected && (
                          <CreditCardIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        )}
                        {!cardSelected && (
                          <BuildingLibraryIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        )}
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <div className="">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Enter Payment Details
                          </Dialog.Title>
                          <div className="grid grid-cols-2">
                            <p className="text-primary col-span-1 mt-2 truncate text-sm font-medium">
                              ${netAmount}
                            </p>
                            <p className="col-span-1 mt-2 flex items-center text-sm text-gray-500">
                              {/* <BanknotesIcon className="ml-1 mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
                              <span className="truncate">
                                Event: {eventName}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div>
                          <FormControl component="fieldset">
                            <FormControlLabel
                              value="Yes, the purchaser will cover the processing fee"
                              control={
                                <Checkbox
                                  checked={feeCovered}
                                  onChange={handleChange}
                                />
                              }
                              label="Yes, the purchaser will cover the processing fee"
                              labelPlacement="end"
                            />
                          </FormControl>
                        </div>
                        {/* {merchant?.defaultPaymentProcessor !== "STRIPE" && (
                          <div className="mt-2 flex justify-between">
                            <button
                              type="button"
                              name="Cancel"
                              className={
                                "focus:outline-none w-1/2 rounded-md border px-2 py-1 text-xs font-medium shadow-sm focus:ring-2 focus:ring-offset-2 " +
                                extraBankTransferButtonClass
                              }
                              onClick={() => setCardSelected(false)}
                            >
                              Bank Transfer
                            </button>
                            <button
                              type="button"
                              name="Update"
                              className={
                                "focus:outline-none ml-1 w-1/2 rounded-md border px-2 py-1 text-xs font-medium shadow-sm focus:ring-2 focus:ring-offset-2 " +
                                extraCardButtonClass
                              }
                              onClick={() => setCardSelected(true)}
                            >
                              Card
                            </button>
                          </div>
                        )} */}
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">{message}</p>
                        </div>
                        {merchant?.defaultPaymentProcessor !== "STRIPE" && (
                          <div className="mt-2" style={bankTransferVisibility}>
                            <BankTransfer
                              accountNumber={accountNumber}
                              setAccountNumber={setAccountNumber}
                              routingNumber={routingNumber}
                              setRoutingNumber={setRoutingNumber}
                              accountType={accountType}
                              setAccountType={setAccountType}
                            />
                          </div>
                        )}
                        <div className="mt-2" style={cardVisibility}>
                          {merchant?.defaultPaymentProcessor === "STRIPE" &&
                          stripePromise ? (
                            <Elements stripe={stripePromise}>
                              <StripeCheckout
                                ref={stripeCardRef}
                                setErrorMessage={setErrorMessage}
                                setShowErrorAlert={setShowErrorAlert}
                                setSendingData={setSendingData}
                                defaultPaymentProcessor={"STRIPE"}
                                // processStripeCard={}
                                processStripeCard={stripePayCard}
                                stripeAccountID={merchant.stripeAccountID}
                              />
                            </Elements>
                          ) : (
                            form !== null && (
                              <Card
                                updateWithCard={updateWithCard}
                                form={form}
                              />
                            )
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      {sendingData && (
                        <button
                          type="button"
                          name="deactivate"
                          className="bg-secondary hover:bg-secondary focus:outline-none focus:ring-secondary pointer-events-none inline-flex w-1/5 justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:text-sm"
                          onClick={(e) => {
                            updateWithCard(props);
                          }}
                        >
                          {/* <div className="invisible">
                                                    Update
                                                </div> */}
                          <ClipLoader
                            color={"#FFFFFF"}
                            loading={sendingData}
                            // cssOverride={override}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </button>
                      )}
                      {!sendingData && (
                        <button
                          type="button"
                          name="deactivate"
                          className="bg-primary hover:bg-secondary focus:outline-none focus:ring-secondary inline-flex w-1/5 justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:text-sm"
                          onClick={(e) => {
                            if (
                              merchant?.defaultPaymentProcessor === "STRIPE"
                            ) {
                              callStripeCardPaymentFunction();
                            } else if (cardSelected) {
                              updateWithCard();
                            } else {
                              updateWithBankTransfer();
                            }
                          }}
                        >
                          Submit
                        </button>
                      )}

                      <button
                        type="button"
                        className="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => props.setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
