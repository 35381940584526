import { ConnectAccountManagement } from "@stripe/react-connect-js";
import React from "react";

function StripeManagement() {
  return (
    <div className="my-10 h-full">
      <ConnectAccountManagement />
    </div>
  );
}

export default StripeManagement;
