import React, { Component, useState, useEffect, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import { Auth } from "aws-amplify";
import Image from "../../images/user-avatar-80.png";
import SuccessAlert from "../alerts/SuccessAlert";
import ErrorAlert from "../alerts/ErrorAlert";

import { VesselConnectButton, api } from "@vesselapi/react-vessel-link";
import KindfulIntegration from "../integrations/kindful/AuthorizationCodeExample";
import DoubleTheDonationIntegration from "../integrations/DoubleTheDonation/DoubleTheDonationIntegration";
import PDFViewer from "../../utils/PDFViewer";
import Compliance from "./compliance/Compliance";
import CustomFAQsTab from "../slideovers/tabs/CustomFAQsTab";
import S3Upload from "../uploads/S3Upload";
import Account from "./account/Account";
import Integerations from "./integrations/Integrations";
import Import from "./import/Import";
import SettingsCustomInputs from "./custom-inputs/SettingsCustomInputs";

const tabs = [
  { name: "Account", href: "#account", current: true },
  { name: "Embed", href: "#embed", current: false },
  { name: "FAQs", href: "#faqs", current: false },
  { name: "Custom Fields", href: "#customfields", current: false },
  { name: "Tax Receipts", href: "#email", current: false },
  { name: "Compliance", href: "#Compliance", current: false },
  { name: "Integrations", href: "#integrations", current: false },
  // { name: "Import", href: "#import", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export class SettingsContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: { name: "Account", current: true },
      customFAQs: [],
      originalCustomFAQs: [],
      replyToEmailAddress: "",
      complianceFullName: "",
      complianceTitle: "",
      complianceCheck: false,
      complianceDueDate: "",
      complianceRequired: false,
      compliancePDFLink: "",
      subject: "",
      emailMessage: "",
      jwtToken: "",
      isDisabled: false,
      errorMessage: "",
      showErrorAlert: false,
      showSuccessAlert: false,
      user: "",
      successMessage: "",
      copiedState: "",
    };
  }
  async componentDidMount() {
    const { jwtToken, user } = this.state;

    const data = await Auth.currentSession();
    const dashboardUser = await Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });

    this.setState({
      jwtToken: data["idToken"]["jwtToken"],
      user: dashboardUser.username,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: data["idToken"]["jwtToken"],
      },
    };
    const params = new URLSearchParams();
    params.append("requestType", "SETTINGS_PAGE");
    const url = process.env.REACT_APP_EMAIL_CONFIG_URL;

    const response = await fetch(url + `?${params.toString()}`, requestOptions);

    let data1 = await response.json();
    if (data1 === null || data1.message === "Unauthorized") {
      this.setState({
        replyToEmailAddress: "",
        subject: "",
        emailMessage: "",
      });
    }

    if (data1 !== null) {
      if (
        data1.complianceRequired === null ||
        data1.complianceRequired === undefined
      ) {
        data1.complianceRequired = true;
      }
      this.setState({
        replyToEmailAddress: data1.replyToEmail,
        subject: data1.emailSubject,
        emailMessage: data1.emailMessage,

        //uncheck to get due date from field
        // complianceRequired: data1.complianceRequired
        // compliancePDFLink: data1.compliancePDFLink
        // complianceDueDate: data1.complianceDueDate,
      });
    }

    // load custom faqs on mount
    this.loadCustomFAQsFromProps();
  }

  componentDidUpdate(prevProps) {
    if (this.props.customFAQs !== prevProps.customFAQs) {
      this.loadCustomFAQsFromProps();
    }
  }
  async loadCustomFAQsFromProps() {
    if (this.props.merchant && this.props.merchant.faqs) {
      this.setState({
        customFAQs: this.props.merchant.faqs,
        originalCustomFAQs: this.props.merchant.faqs,
      });
    }
  }

  updateCopiedState(newState) {
    this.setState({ copiedState: newState });
  }

  handleCopyClick = async () => {
    const { iframe } = this.props;
    try {
      await navigator.clipboard.writeText(iframe);
      this.updateCopiedState("Copied!");
    } catch (err) {
      console.error("Failed to copy: ", err);
      this.updateCopiedState("Failed to copy");
    } finally {
      setTimeout(() => {
        this.updateCopiedState("");
      }, 1000);
    }
  };

  setIsDisabled = (value) => {
    this.setState({
      isDisabled: value,
    });
  };

  setErrorMessage = (value) => {
    this.setState({
      errorMessage: value,
    });
  };
  setShowErrorAlert = (value) => {
    this.setState({
      showErrorAlert: value,
    });
  };
  setShowSuccessAlert = (value) => {
    this.setState({
      showSuccessAlert: value,
    });
  };
  setSuccessMessage = (value) => {
    this.setState({
      successMessage: value,
    });
  };

  updateEmailValues = async () => {
    const {
      replyToEmailAddress,
      subject,
      emailMessage,
      jwtToken,
      isDisabled,
      showSuccessAlert,
    } = this.state;
    if (replyToEmailAddress === "" || subject === "" || emailMessage === "") {
      this.setErrorMessage("Incomplete Information");
      this.setShowErrorAlert(true);
      this.setShowSuccessAlert(false);
    }
    if (
      !(replyToEmailAddress === "" || subject === "" || emailMessage === "")
    ) {
      const payload = {
        replyToEmailAddress: replyToEmailAddress,
        subject: subject,
        emailMessage: emailMessage,
        requestType: "SETTINGS_PAGE",
      };
      this.setIsDisabled(true);

      const requestOptions = {
        method: "POST",
        headers: {
          accept: "*/*",
          "content-type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify(payload),
      };
      //needs to be updated
      const url = process.env.REACT_APP_EMAIL_CONFIG_URL;

      let response = await fetch(url, requestOptions);
      let data = await response.json();
      console.log({ data });
      if (data.error) {
        console.log(data.error);
        return;
      } else {
        this.setIsDisabled(false);

        this.setShowErrorAlert(false);
        this.setShowSuccessAlert(true);

        // this.grabSelectableFunds(jwtToken)
      }
    }
  };

  /*  updateFAQsValues = async () => {
     const { customFAQs, originalCustomFAQs, jwtToken, isDisabled, showSuccessAlert } = this.state;
     if ((customFAQs === "" || subject === "" || emailMessage === "")) {
       this.setErrorMessage("Incomplete Information")
       this.setShowErrorAlert(true)
       this.setShowSuccessAlert(false)
 
     }
     if (!(replyToEmailAddress === "" || subject === "" || emailMessage === "")) {
       const payload = {
         "replyToEmailAddress": replyToEmailAddress,
         "subject": subject,
         "emailMessage": emailMessage,
         "requestType": "SETTINGS_PAGE",
       }
       this.setIsDisabled(true)
 
       //faqs = an array 
    /*  
       [
         {
           question: "", 
           answer: "",
         },
         {
           question: "", 
           answer: "",
         },
       ]
        
     
       //needs to be updated
       const url = `${process.env.REACT_APP_ELEMENTS_URL}/merchant_faqs`
       
       let response = await fetch(url, requestOptions)
       let data = await response.json()
       console.log({ data })
       if (data.error) {
         console.log(data.error)
         return
       }
       else {
         this.setIsDisabled(false)
 
         this.setShowErrorAlert(false)
         this.setShowSuccessAlert(true)
 
         // this.grabSelectableFunds(jwtToken)
       }
     }
   } */

  updateFAQsValues = async () => {
    const {
      customFAQs,
      originalCustomFAQs,
      jwtToken,
      isDisabled,
      showSuccessAlert,
    } = this.state;

    // Error checking for the customFAQs array
    for (const faq of customFAQs) {
      if (faq.question.trim() === "" || faq.answer.trim() === "") {
        this.setErrorMessage("FAQ question or answer cannot be blank.");
        this.setShowErrorAlert(true);
        this.setShowSuccessAlert(false);
        //  return; // Exit the function if there's an error
      }
    }
    console.log("this.props.merchant", this.props.merchant.merchantName);
    // Check if any other fields are empty
    if (customFAQs.length === 0) {
      //     this.setErrorMessage("Incomplete Information");
      //     this.setShowErrorAlert(true);
      //     this.setShowSuccessAlert(false);
    } else {
      const payload = {
        nonprofitName: this.props.merchant.merchantName,
        faqs: customFAQs,
      };
      this.setIsDisabled(true);

      const requestOptions = {
        method: "POST",
        headers: {
          accept: "*/*",
          "content-type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify(payload),
      };
      const url = `${process.env.REACT_APP_ELEMENTS_URL}/merchant_faqs`;

      let response = await fetch(url, requestOptions);
      let data = await response.json();
      console.log({ data });
      if (data.error) {
        console.log(data.error);
        return;
      } else {
        this.setIsDisabled(false);
        this.setShowErrorAlert(false);
        this.setShowSuccessAlert(true);
        // this.grabSelectableFunds(jwtToken);
      }
    }
  };

  updateComplianceValues = async () => {
    const {
      complianceCheck,
      complianceFullName,
      complianceTitle,
      jwtToken,
      isDisabled,
      showSuccessAlert,
    } = this.state;
    if (
      complianceCheck === false ||
      complianceFullName === "" ||
      complianceTitle === ""
    ) {
      this.setErrorMessage("Incomplete Information");
      this.setShowErrorAlert(true);
      this.setShowSuccessAlert(false);
    }
    if (
      !(
        complianceCheck === false ||
        complianceFullName === "" ||
        complianceTitle === ""
      )
    ) {
      const payload = {
        complianceFullName: complianceFullName,
        complianceTitle: complianceTitle,
        requestType: "SETTINGS_PAGE",
      };
      this.setIsDisabled(true);

      const requestOptions = {
        method: "POST",
        headers: {
          accept: "*/*",
          "content-type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify(payload),
      };
      //needs to be updated
      const url = process.env.REACT_APP_COMPLIANCE_CONFIG_URL;

      let response = await fetch(url, requestOptions);
      let data = await response.json();
      console.log({ data });
      if (data.error) {
        console.log(data.error);
        return;
      } else {
        this.setIsDisabled(false);

        this.setShowErrorAlert(false);
        this.setShowSuccessAlert(true);

        // this.grabSelectableFunds(jwtToken)
      }
    }
  };

  replyToEmailAddressChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    this.setState({
      replyToEmailAddress: value,
    });
  };

  complianceFullNameChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    this.setState({
      complianceFullName: value,
    });
  };

  complianceCheckChange = (e) => {
    // console.log("im here", e)
    // e.preventDefault();
    let value;
    if (e === "true" || e === true) {
      value = true;
    }
    if (e === "false" || e === false) {
      value = false;
    }
    this.setState({
      complianceCheck: value,
    });
  };

  subjectChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    this.setState({
      subject: value,
    });
  };

  complianceTitleChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    this.setState({
      complianceTitle: value,
    });
  };

  variableClick = (e) => {
    e.preventDefault();
    let currentValueOfCustomMessage = this.state.emailMessage;
    //this adds a space charecter when the variable button list is clicked
    let newValueOfCustomMessage =
      currentValueOfCustomMessage + " " + e.target.value;

    this.setState({
      emailMessage: newValueOfCustomMessage,
    });
  };

  emailMessageChange = (e) => {
    e.preventDefault();
    let value = e.target.value;

    this.setState({
      emailMessage: value,
    });
  };

  setActiveTab = (e) => {
    const { activeTab } = this.state;
    let value = e;
    let activeTab2 = { name: e, current: true };

    this.setState({
      activeTab: activeTab2,
      showSuccessAlert: false,
    });
  };

  render() {
    const {
      jwtToken,
      activeTab,
      replyToEmailAddress,
      subject,
      emailMessage,
      isDisabled,
      showSuccessAlert,
      showErrorAlert,
      errorMessage,
      successMessage,
      user,
      complianceFullName,
      complianceTitle,
      complianceCheck,
      complianceDueDate,
      complianceRequired,
      compliancePDFLink,
      copiedState,
    } = this.state;
    let submitButtonClass =
      "justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2";
    if (isDisabled) {
      submitButtonClass =
        "justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm cursor-not-allowed focus:outline-none";
    }
    const iframe = process.env.REACT_APP_EMBED_CODE;
    //  console.log("hello naumaan, ", this.state.customFAQs)
    return (
      <div className="flex-grow justify-start">
        {/* Panel body */}
        <div className="space-y-2 p-2">
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8 px-5" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <a
                      key={tab.name}
                      href={tab.href}
                      onClick={() => this.setActiveTab(tab.name)}
                      className={classNames(
                        tab.name === activeTab.name
                          ? "border-green-500 text-green-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                      )}
                      aria-current={
                        tab.name === activeTab.name ? "page" : undefined
                      }
                    >
                      {tab.name}
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          {/* this will only show on the "test" user or naumaan and nabil */}
          <div
            className={`${activeTab.name === "Integrations" ? "" : "hidden"}`}
          >
            <Integerations
              merchant={this.props.merchant}
              grabBaseMerchant={this.props.grabBaseMerchant}
              jwtToken={this.state.jwtToken}
            />
          </div>
          {activeTab.name === "Compliance" && (
            <Compliance
              merchant={this.props.merchant}
              setSuccessMessage={this.setSuccessMessage}
              setShowSuccessAlert={this.setShowSuccessAlert}
            />
          )}
          {activeTab.name === "Embed" && (
            <div>
              <section>
                <div>
                  <div className="col-span-2 row-span-1">
                    <div className="mb-4 text-2xl font-semibold">Embeds</div>
                    <div className="text-sm text-gray-700">
                      CharityStack features are enabled by a simple piece of
                      code. Copy and paste the code below into your site's
                      &lt;head&gt;&lt;/head&gt; section.
                      <br></br> For more detailed installation instructions,
                      visit{" "}
                      <a
                        href="https://support.charitystack.com/en/articles/8072705-setting-up-the-checkout-form"
                        className="text-primary hover:text-secondary"
                      >
                        this guide
                      </a>
                      .
                    </div>

                    <br></br>
                    <div className="space-y-1">
                      <div>
                        <a
                          className="text-primary hover:text-secondary group flex cursor-pointer text-sm font-medium hover:underline"
                          data-tip
                          data-for="copyIframe"
                          data-event="click"
                          onClick={this.handleCopyClick}
                        >
                          {/* Copy code JSX */}
                        </a>
                      </div>
                      <div>
                        <textarea
                          value={iframe}
                          rows={5}
                          className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                          defaultValue={""}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-shrink-0 border-t border-gray-200 py-2">
                  <div className="flex h-12 items-center justify-between space-x-3">
                    {/* Rest of the JSX */}
                  </div>
                </div>
              </section>
            </div>
          )}

          {activeTab.name === "Custom Fields" && (
            <SettingsCustomInputs
              merchant={this.props.merchant}
              jwtToken={jwtToken}
            />
          )}

          {activeTab.name === "Tax Receipts" && (
            <div className="border-zinc-700 mb-8 rounded-md border-2 p-5 shadow-lg">
              <section>
                <div>
                  <div className="col-span-2 row-span-1 ">
                    <div>
                      <label
                        htmlFor="form-name"
                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        Reply-To Address*
                        <svg
                          data-tip
                          data-for="replytoTooltip"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="text-primary ml-1 inline-block h-5 w-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <ReactTooltip
                          id="replytoTooltip"
                          effect="solid"
                          place="right"
                        >
                          <p className="text-sm text-white">
                            This is the name of the email address that
                          </p>
                          <p className="text-sm text-white">
                            the donors will see on their donor receipts.
                          </p>
                          <p className="text-sm text-white">
                            It must be a full email address.
                          </p>
                        </ReactTooltip>
                      </label>
                      <div className="sm:col-span-2">
                        <input
                          value={replyToEmailAddress}
                          type="text"
                          className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                          onChange={(e) => this.replyToEmailAddressChange(e)}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="form-name"
                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        Subject*
                        <svg
                          data-tip
                          data-for="subjectTooltip"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="text-primary ml-1 inline-block h-5 w-5"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <ReactTooltip
                          id="subjectTooltip"
                          effect="solid"
                          place="right"
                        >
                          <p className="text-sm text-white">
                            This is the subject of the email that
                          </p>
                          <p className="text-sm text-white">
                            the donors will see on their donor receipts.
                          </p>
                        </ReactTooltip>
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        value={subject}
                        type="text"
                        className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                        onChange={(e) => this.subjectChange(e)}
                      />
                    </div>
                    <div class="flow-root ">
                      <p class="float-right py-2">
                        <select
                          id="variables"
                          class="rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                          onChange={(e) => this.variableClick(e)}
                        >
                          <option selected="true" disabled="disabled">
                            Variables
                          </option>
                          <option value="{{name}}">Donor Name</option>
                          <option value="{{total}}">Donated Amount</option>
                          <option value="{{fund}}">Donated Fund</option>
                          <option value="{{nonprofit}}">Nonprofit Name</option>
                          <option value="{{phone}}">Donor Phone Number</option>
                          <option value="{{address}}">Donor Address</option>
                          <option value="{{email}}">Donor Email</option>
                          <option value="{{paymentMethod}}">
                            Donor Payment Method
                          </option>
                          <option value="{{feeCovered}}">Covered Fee*</option>
                          <option value="{{ein}}">EIN</option>
                        </select>
                      </p>
                      <p class="float-left pt-7">
                        <label
                          htmlFor="form-name"
                          className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Custom Message*
                          <svg
                            data-tip
                            data-for="customMessageTooltip"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="text-primary ml-1 inline-block h-5 w-5"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <ReactTooltip
                            id="customMessageTooltip"
                            effect="solid"
                            place="right"
                          >
                            <p className="text-sm text-white">
                              This is the message that will be shown in the{" "}
                            </p>
                            <p className="text-sm text-white">
                              body of the email that donors will see on their
                              donor receipts.
                            </p>
                          </ReactTooltip>
                        </label>
                      </p>
                      <div className="sm:col-span-2">
                        <textarea
                          value={emailMessage}
                          type="text"
                          className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                          onChange={(e) => this.emailMessageChange(e)}
                          rows="4"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex-shrink-0 border-t border-gray-200 py-2">
                  <div className="flex h-12 items-center justify-between space-x-3">
                    {showSuccessAlert && (
                      <SuccessAlert
                        message="Changes Saved Successfully"
                        setShowSuccessAlert={this.setShowSuccessAlert}
                      />
                    )}
                    {showErrorAlert && (
                      <ErrorAlert
                        message={errorMessage}
                        setShowErrorAlert={this.setShowErrorAlert}
                      />
                    )}
                    {!showSuccessAlert && !showErrorAlert && <div />}
                    <button
                      type="button"
                      className={submitButtonClass}
                      onClick={this.updateEmailValues}
                    >
                      {!isDisabled && "Save"}
                      {isDisabled && "Saving"}
                    </button>
                  </div>
                </div>
              </section>
              {/* Smart Sync */}
            </div>
          )}
          {activeTab.name === "Account" && (
            <div className="h-full">
              <Account
                merchant={this.props.merchant}
                grabBaseMerchant={this.props.grabBaseMerchant}
              />
            </div>
          )}

          {activeTab.name === "FAQs" && (
            <div>
              <CustomFAQsTab
                customFAQs={this.state.customFAQs}
                setCustomFAQs={(customFAQs) =>
                  this.setState({ customFAQs: customFAQs })
                }
                originalCustomFAQs={this.state.originalCustomFAQs}
              />

              <div className="flex-shrink-0 border-t border-gray-200 py-2">
                <div className="flex h-12 items-center justify-between space-x-3">
                  {showSuccessAlert && (
                    <SuccessAlert
                      message="Changes Saved Successfully"
                      setShowSuccessAlert={this.setShowSuccessAlert}
                    />
                  )}
                  {showErrorAlert && (
                    <ErrorAlert
                      message={errorMessage}
                      setShowErrorAlert={this.setShowErrorAlert}
                    />
                  )}
                  {!showSuccessAlert && !showErrorAlert && <div />}
                  <button
                    type="button"
                    className={submitButtonClass}
                    onClick={this.updateFAQsValues}
                  >
                    {!isDisabled && "Save"}
                    {isDisabled && "Saving"}
                  </button>
                </div>
              </div>
            </div>
          )}

          {activeTab.name === "Import" && (
            <div className="h-full">
              <Import
                jwtToken={this.state.jwtToken}
                merchant={this.props.merchant}
                grabBaseMerchant={this.props.grabBaseMerchant}
                selectableForms={this.props.selectableForms}
              />
            </div>
          )}
        </div>

        {activeTab.name != "Tax Receipts" && activeTab.name != "FAQs" && (
          <div className="footer absolute bottom-0 left-1/2 -translate-x-1/2 transform text-center">
            {showSuccessAlert && (
              <SuccessAlert
                message={
                  successMessage != ""
                    ? successMessage
                    : "Changes Saved Successfully"
                }
                setShowSuccessAlert={this.setShowSuccessAlert}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default SettingsContent;
