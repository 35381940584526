import React, { Component, useState, useEffect, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import { Auth } from "aws-amplify";
import SuccessAlert from "../../alerts/SuccessAlert";
import ErrorAlert from "../../alerts/ErrorAlert";
import PDFViewer from "../../../utils/PDFViewer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export class PCISelfAssessment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      complianceFullName: "",
      complianceTitle: "",
      complianceCheck: false,
      complianceDueDate: "",
      complianceRequired: false,
      compliancePDFLink: "",

      jwtToken: "",
      isDisabled: false,
      errorMessage: "",
      showErrorAlert: false,
      showSuccessAlert: false,
      user: "",
      successMessage: "",
    };
  }
  async componentDidMount() {
    const { jwtToken, user } = this.state;
    console.log("pre - jwt", jwtToken);

    const data = await Auth.currentSession();
    const dashboardUser = await Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });

    this.setState({
      jwtToken: data["idToken"]["jwtToken"],
      user: dashboardUser.username,
    });
    console.log("jwt", data["idToken"]["jwtToken"]);
    const requestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: data["idToken"]["jwtToken"],
      },
    };

    const params = new URLSearchParams();
    params.append("nonprofit", this.props.merchant["merchantName"]);
    const url = process.env.REACT_APP_COMPLIANCE_CONFIG_URL;

    const response = await fetch(url + `?${params.toString()}`, requestOptions);
    let data1 = await response.json();

    console.log(data1);
    if (
      data1 === null ||
      data1.message === "Unauthorized" ||
      data1.message === "Internal Server Error"
    ) {
      this.setState({
        complianceRequired: false,
        compliancePDFLink: "",
        complianceDueDate: "",
      });
    }

    if (data1 !== null) {
      if (data1.complianceFormState === "INCOMPLETE") {
        data1.complianceRequired = true;
      }
      if (
        data1.complianceFormState === "COMPLETE" ||
        data1.complianceRequired === "INVALID" ||
        data1.complianceRequired === "PENDING"
      ) {
        data1.complianceRequired = false;
      }
      console.log("data1 value: ", data1);
      this.setState({
        complianceRequired: data1.complianceRequired,
        compliancePDFLink: data1.complianceFormURL,
        complianceDueDate: data1.complianceFormDueDate,
      });
    }
  }
  setIsDisabled = (value) => {
    this.setState({
      isDisabled: value,
    });
  };

  setErrorMessage = (value) => {
    this.setState({
      errorMessage: value,
    });
  };
  setShowErrorAlert = (value) => {
    this.setState({
      showErrorAlert: value,
    });
  };
  setShowSuccessAlert = (value) => {
    this.setState({
      showSuccessAlert: value,
    });
  };
  setSuccessMessage = (value) => {
    this.setState({
      successMessage: value,
    });
  };

  updateComplianceValues = async () => {
    const {
      complianceCheck,
      complianceFullName,
      complianceTitle,
      jwtToken,
      isDisabled,
      showSuccessAlert,
    } = this.state;
    if (
      complianceCheck === false ||
      complianceFullName === "" ||
      complianceTitle === ""
    ) {
      this.setErrorMessage("Incomplete Information");
      this.setShowErrorAlert(true);
      this.setShowSuccessAlert(false);
    }
    if (
      !(
        complianceCheck === false ||
        complianceFullName === "" ||
        complianceTitle === ""
      )
    ) {
      const payload = {
        name: complianceFullName,
        title: complianceTitle,
      };
      this.setIsDisabled(true);

      const requestOptions = {
        method: "POST",
        headers: {
          accept: "*/*",
          "content-type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify(payload),
      };
      //needs to be updated
      const url = process.env.REACT_APP_COMPLIANCE_CONFIG_URL;

      let response = await fetch(url, requestOptions);
      let data = await response.json();
      console.log("hello", { response });
      if (response.status >= 400) {
        console.log(data);
        this.setShowErrorAlert(true);
        this.setErrorMessage(data);
        this.setIsDisabled(false);
        this.setShowSuccessAlert(false);

        return;
      } else {
        this.setIsDisabled(false);

        this.setShowErrorAlert(false);
        this.setShowSuccessAlert(true);

        // this.grabSelectableFunds(jwtToken)
      }
    }
  };

  complianceFullNameChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    this.setState({
      complianceFullName: value,
    });
  };

  complianceCheckChange = (e) => {
    // e.preventDefault();
    let value;
    if (e === "true" || e === true) {
      value = true;
    }
    if (e === "false" || e === false) {
      value = false;
    }
    this.setState({
      complianceCheck: value,
    });
  };

  complianceTitleChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    this.setState({
      complianceTitle: value,
    });
  };

  render() {
    const {
      jwtToken,
      activeTab,
      replyToEmailAddress,
      subject,
      emailMessage,
      isDisabled,
      showSuccessAlert,
      showErrorAlert,
      errorMessage,
      successMessage,
      user,
      complianceFullName,
      complianceTitle,
      complianceCheck,
      complianceDueDate,
      complianceRequired,
      compliancePDFLink,
    } = this.state;
    let submitButtonClass =
      "justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2";
    if (isDisabled) {
      submitButtonClass =
        "justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm cursor-not-allowed focus:outline-none";
    }

    const date = new Date(complianceDueDate);
    const dateString = date.toDateString();

    return (
      <div className="flex-grow justify-start">
        {complianceRequired === false && (
          <div>
            You have completed your PCI Compliance Self-Assessment Questionnaire
            for this year. You are required to fill out a self-assessment
            questionnaire yearly. Please check back here again at a later date.
          </div>
        )}
        {complianceRequired === true && (
          <div>
            <div>
              Your PCI Compliance Self-Assessment Questionnaire is due on{" "}
              {dateString}. Please take a moment to review the document below
              and fill out your full name and title as it appears on the
              document.
              <PDFViewer
                pdf={compliancePDFLink}
                //pdf="https://charitystack-finix-compliance-forms.s3.us-east-2.amazonaws.com/Example+Marriage+Contract+Form.pdf"
              />
              <div className="grid grid-cols-2 gap-4 pt-3 ">
                <div className="col-span-1">
                  <label
                    htmlFor="compliance-fullname"
                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Full Name*
                    <svg
                      data-tip
                      data-for="compliancefullnameTooltip"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="text-primary ml-1 inline-block h-5 w-5"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <ReactTooltip
                      id="compliancefullnameTooltip"
                      effect="solid"
                      place="right"
                    >
                      <p className="text-sm text-white">
                        This name should match the PCI Compliance
                      </p>
                      <p className="text-sm text-white">
                        PDF that is showcased above.{" "}
                      </p>
                      <p className="text-sm text-white">
                        It must be your full name.
                      </p>
                    </ReactTooltip>
                  </label>
                  <div className="sm:col-span-2">
                    <input
                      value={complianceFullName}
                      type="text"
                      className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                      onChange={(e) => this.complianceFullNameChange(e)}
                    />
                  </div>
                </div>

                <div className="col-span-1">
                  <label
                    htmlFor="form-name"
                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                  >
                    Title*
                    <svg
                      data-tip
                      data-for="complianceTitleTooltip"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="text-primary ml-1 inline-block h-5 w-5"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <ReactTooltip
                      id="complianceTitleTooltip"
                      effect="solid"
                      place="right"
                    >
                      <p className="text-sm text-white">
                        This name should match the PCI Compliance
                      </p>
                      <p className="text-sm text-white">
                        PDF that is showcased above.{" "}
                      </p>
                      <p className="text-sm text-white">
                        It must be your correct title.
                      </p>
                    </ReactTooltip>
                  </label>
                  <div className="sm:col-span-2">
                    <input
                      value={complianceTitle}
                      type="text"
                      className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                      onChange={(e) => this.complianceTitleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-2 pt-3">
                <div className="flex items-center">
                  <input
                    id="compliance-certify"
                    name="compliance-certify"
                    type="checkbox"
                    className="text-secondary focus:ring-secondary h-4 w-4 rounded border-gray-300"
                    checked={complianceCheck}
                    onClick={() => this.complianceCheckChange(!complianceCheck)}
                  />
                  <label
                    htmlFor="compliance-certify"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    By submitting this Self-Assessment Questionnaire, I certify
                    that I am an authorized representative of the company and
                    that all the information submitted is true and correct.
                  </label>
                </div>
              </div>
            </div>

            <div className="flex-shrink-0 border-t border-gray-200 py-2">
              <div className="flex h-12 items-center justify-between space-x-3">
                {showSuccessAlert && (
                  <SuccessAlert
                    message="Changes Saved Successfully"
                    setShowSuccessAlert={this.setShowSuccessAlert}
                  />
                )}
                {showErrorAlert && (
                  <ErrorAlert
                    message={errorMessage}
                    setShowErrorAlert={this.setShowErrorAlert}
                  />
                )}
                {!showSuccessAlert && !showErrorAlert && <div />}
                <button
                  type="button"
                  className={submitButtonClass}
                  onClick={this.updateComplianceValues}
                >
                  {!isDisabled && "Save"}
                  {isDisabled && "Saving"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PCISelfAssessment;
