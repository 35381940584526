import { TypeAnimation } from 'react-type-animation';
import { useState } from 'react';

function Cursor(props) {
    const { setNeedsToBeTyped, text } = props;
    if (text === '') {
        return (
            <TypeAnimation
                sequence={[
                    500, // Waits 1s
                ]}
                wrapper="div"
                cursor={true}
                repeat={Infinity}
                style={{ fontSize: "16px", display: 'inline-block'}}
            // className='16px inline-block w-0'
            />
        );
    }
    else {
        return (
            <TypeAnimation
                sequence={[
                    text,
                    () => {
                        setNeedsToBeTyped(false);
                    }
                ]}
                speed={50}
                wrapper="div"
                cursor={true}
                repeat={0}
                style={{ fontSize: "16px", display: 'inline' }}
            // className='16px inline-block w-0'
            />
        );
    }
}

export default Cursor;