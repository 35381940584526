import { ConsoleLogger } from '@aws-amplify/core'
import React, { useState, useEffect } from 'react'
import { Auth } from "aws-amplify";
import './card.css'
import './shimmer.css'

function Card(props) {
    const [iframesReady, setIframesReady] = useState(false)

    const defineField = (elementId, type, placeholder) => {
        const { form } = props
        const f = form.field(type, {
            placeholder,

            // Required values for Finix Card Fields
            // Not mutatable due to lack of support from Finix
            styles: {
                placeholder: {
                    fontFamily: "sans-serif",
                    background: "transparent",
                    fontSize: "25px",
                },
                default: {
                    color: "black",
                },
                success: {
                    color: '#5cb85c',
                },
                error: {
                    color: '#d9534f',
                },
            }
        });

        const element = document.getElementById(elementId);
        // remove all children from element
        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }
        element.appendChild(f);
    }

    useEffect(() => {
        const myFunction = async () => {
            console.log('mounting card')

            if (props.form.fields.length === 0) {
                defineField("field-wrapper-number", "number", 'CARD NUMBER');
                defineField("field-wrapper-expiration_date", "expiration_date", 'MM/YY');
                defineField("field-wrapper-security_code", "security_code", 'CVV');
                defineField("field-wrapper-address_postal_code", "address.postal_code", 'ZIP/POSTAL CODE');
    
                setTimeout(() => {
                    setIframesReady(true)
                }, 2000)
                console.log(props.form)
            }
        }
        myFunction()



    }, [props.form]);

    let shimmerVisibility = { display: 'none' }
    let iframesVisibility = {}

    if (iframesReady === false) {
        console.log('inside', iframesReady)
        shimmerVisibility = {}
        iframesVisibility = { display: 'none' }
    }

    return (
        <>

            {/* <div style={shimmerVisibility}>
                <lines class="shine"></lines>
                <lines class="shine"></lines>
            </div > */}
            <div>
                <div class="fake-field">
                    <div id="field-wrapper-number" style={{ width: "100%" }} className="field-wrapper"></div>
                </div>
                <div class="fake-field">
                    <div id="field-wrapper-expiration_date" style={{ width: "50%" }} className="field-wrapper"></div>
                    <div id="field-wrapper-security_code" style={{ width: "50%" }} className="field-wrapper"></div>
                    <span id="field-wrapper-address_postal_code" className="field-wrapper"></span>
                </div>
            </div>

        </>

    )
}

export default Card