/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { LinkIcon } from "@heroicons/react/20/solid";
import Toggle from "../Toggle";
import Dropdown from "../dropdowns/Dropdown";
import MultiSelectDropdown from "../dropdowns/MultiSelectDropdown";
import { Auth } from "aws-amplify";
import ReactTooltip from "react-tooltip";
import SuccessAlert from "../alerts/SuccessAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import Checkbox from "../Checkbox";
import { useHistory } from "react-router-dom";
import CustomInputsTab from "./tabs/CustomInputsTab";
import SingleSelectDropdown from "../dropdowns/SingleSelectDropdown";
import CustomFAQsTab from "../slideovers/tabs/CustomFAQsTab";

const RECURRING_FREQUENCY_ORDER_MAP = {
  Annually: 0,
  Monthly: 1,
  Weekly: 2,
  Daily: 3,
  "One-Time": 4,
};

const frequencyOptions = ["One-Time", "Daily", "Weekly", "Monthly", "Annually"];

const tabs = [
  { name: "Form Options", href: "#formOptions", current: true },
  // { name: 'Custom Fields', href: '#tax', current: false },
  { name: "FAQs", href: "#faqs", current: false },
  { name: "Tax Receipts", href: "#tax", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`.toUpperCase();
  } else {
    return hexColor.toUpperCase();
  }
};

const defaultAmounts = [10, 25, 50, 100];
const countries = [
  "United States",
  "Canada",
  "United Kingdom",
  "Australia",
  "France",
  "Lebanon",
  "Kuwait",
];

export default function CreateFormSlideover(props) {
  const [jwtToken, setJWTToken] = useState("");
  const [elementName, setelementName] = useState("");
  const [elementID, setElementID] = useState("");

  const [activeTab, setActiveTab] = useState({
    name: "Form Options",
    current: true,
  });

  const [defaultDonationType, setDefaultDonationType] = useState("Monthly");

  const [oneTimeAmount1, setOneTimeAmount1] = useState("");
  const [oneTimeAmount2, setOneTimeAmount2] = useState("");
  const [oneTimeAmount3, setOneTimeAmount3] = useState("");
  const [oneTimeAmount4, setOneTimeAmount4] = useState("");
  const [monthlyAmount1, setMonthlyAmount1] = useState("");
  const [monthlyAmount2, setMonthlyAmount2] = useState("");
  const [monthlyAmount3, setMonthlyAmount3] = useState("");
  const [monthlyAmount4, setMonthlyAmount4] = useState("");

  const [dailyAmount1, setDailyAmount1] = useState("");
  const [dailyAmount2, setDailyAmount2] = useState("");
  const [dailyAmount3, setDailyAmount3] = useState("");
  const [dailyAmount4, setDailyAmount4] = useState("");
  const [weeklyAmount1, setWeeklyAmount1] = useState("");
  const [weeklyAmount2, setWeeklyAmount2] = useState("");
  const [weeklyAmount3, setWeeklyAmount3] = useState("");
  const [weeklyAmount4, setWeeklyAmount4] = useState("");
  const [annuallyAmount1, setAnuallyAmount1] = useState("");
  const [annuallyAmount2, setAnuallyAmount2] = useState("");
  const [annuallyAmount3, setAnuallyAmount3] = useState("");
  const [annuallyAmount4, setAnuallyAmount4] = useState("");

  const [crowdFundingFormType, setCrowdFundingFormType] = useState(false);

  const [option1Title, setOption1Title] = useState("");
  const [option1Amount, setOption1Amount] = useState("");
  const [option1Description, setOption1Description] = useState("");
  const [option2Title, setOption2Title] = useState("");
  const [option2Amount, setOption2Amount] = useState("");
  const [option2Description, setOption2Description] = useState("");
  const [option3Title, setOption3Title] = useState("");
  const [option3Amount, setOption3Amount] = useState("");
  const [option3Description, setOption3Description] = useState("");
  const [option4Title, setOption4Title] = useState("");
  const [option4Amount, setOption4Amount] = useState("");
  const [option4Description, setOption4Description] = useState("");

  const [frequency, setFrequency] = useState("One-Time");
  const [showFrequencyOptions, setShowFrequencyOptions] = useState(true);

  const [showOneTimeDefault, setShowOneTimeDefault] = useState(false);
  const [showMonthlyDefault, setShowMonthlyDefault] = useState(false);
  const [showDailyDefault, setShowDailyDefault] = useState(false);
  const [showWeeklyDefault, setShowWeeklyDefault] = useState(false);
  const [showAnnuallyDefault, setshowAnnuallyDefault] = useState(false);
  const [oneTimeDefault, setOneTimeDefault] = useState("");
  const [monthlyDefault, setMonthlyDefault] = useState("");
  const [dailyDefault, setDailyDefault] = useState("");
  const [weeklyDefault, setWeeklyDefault] = useState("");
  const [annuallyDefault, setAnnuallyDefault] = useState("");
  const [separateAmounts, setSeparateAmounts] = useState(true);
  const [requirePhoneNumber, setRequirePhoneNumber] = useState(false);
  const [requireScheduleDonations, setRequireScheduleDonations] =
    useState(false);
  const [requireBillingAddress, setRequireBillingAddress] = useState(false);
  const [requireCountryDropdown, setRequireCountryDropdown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    "Choose Default Country"
  );
  const [funds, setFunds] = useState("");
  const [color, setColor] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [copiedState, setCopiedState] = useState("Copied!");

  const [monthlyChecked, setMonthlyChecked] = useState(false);
  const [oneTimeChecked, setOneTimeChecked] = useState(false);
  const [weeklyChecked, setWeeklyChecked] = useState(false);
  const [dailyChecked, setDailyChecked] = useState(false);
  const [annuallyChecked, setAnnuallyChecked] = useState(false);

  const [checkedValues, setCheckedValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  const [emailCustomization, setEmailCustomization] = useState(false);

  const [replyToEmailAddress, replyToEmailAddressChange] = useState("");
  const [subject, subjectChange] = useState("");
  const [emailMessage, emailMessageChange] = useState("");

  const [dtdPublicApiKey, setDtdPublicApiKey] = useState(null);
  const [requireDoubleTheDonation, setRequireDoubleTheDonation] =
    useState(false);

  const [customInputs, setCustomInputs] = useState([]);
  const [originalCustomInputs, setOriginalCustomInputs] = useState([]);

  const [showAnonymousDonations, setShowAnonymousDonations] = useState(false);

  const [customFAQs, setCustomFAQs] = useState([]);
  const [originalCustomFAQs, setOriginalCustomFAQs] = useState([]);

  const [createdForm, setCreatedForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const variableClick = (e) => {
    let currentValueOfCustomMessage = emailMessage;
    //this adds a space charecter when the variable button list is clicked
    let newValueOfCustomMessage = currentValueOfCustomMessage + " " + e;

    emailMessageChange(newValueOfCustomMessage);
  };
  const recurringOptions = ["Annually", "Monthly", "Weekly", "Daily"];

  // const handleCheckboxChange = (value, isChecked) => {
  //   let newCheckedValues;

  //   if (isChecked) {
  //     newCheckedValues = [...checkedValues, value];
  //   } else {
  //     newCheckedValues = checkedValues.filter((v) => v !== value);

  //     // Uncheck recurring value
  //     const recurringOptions = ["Annually", "Monthly", "Weekly", "Daily"];
  //     if (recurringOptions.includes(value)) {
  //       newCheckedValues = newCheckedValues.filter((v) => !recurringOptions.includes(v));
  //     }
  //   }

  //   // Show all options if One-Time is selected
  //   if (newCheckedValues.includes("One-Time")) {
  //     setCheckedValues(newCheckedValues);
  //     return;
  //   }

  //   // Hide recurring options if a recurring value is selected and One-Time is not selected
  //   const hasRecurringValue = recurringOptions.some((v) => newCheckedValues.includes(v));
  //   if (hasRecurringValue) {
  //     newCheckedValues = newCheckedValues.filter((v) => recurringOptions.includes(v));

  //     // Only allow one recurring value if One-Time is not selected
  //     if (!newCheckedValues.includes("One-Time") && newCheckedValues.length > 1) {
  //       newCheckedValues = [value];
  //     }
  //   }

  //   setCheckedValues(newCheckedValues);
  // };

  const handleFrequencyRadioChange = (value) => {
    let newCheckedValues = [value];

    newCheckedValues.sort((a, b) => {
      const weightA = RECURRING_FREQUENCY_ORDER_MAP[a];
      const weightB = RECURRING_FREQUENCY_ORDER_MAP[b];

      if (weightA === weightB) {
        return a.localeCompare(b);
      } else {
        return weightB - weightA;
      }
    });

    console.log("newCheckedValues: ", newCheckedValues);
    setCheckedValues(newCheckedValues);
  };

  const handleFrequencyCheckboxChange = (value, isChecked) => {
    let newCheckedValues;
    if (value == "One-Time") {
      setOneTimeChecked(isChecked);
    }
    if (value == "Monthly") {
      setMonthlyChecked(isChecked);
    }
    if (value == "Weekly") {
      setWeeklyChecked(isChecked);
    }
    if (value == "Daily") {
      setDailyChecked(isChecked);
    }
    if (value == "Annually") {
      setAnnuallyChecked(isChecked);
    }
    console.log("value is: ", value, "isChecked is: ", isChecked);
    if (isChecked) {
      newCheckedValues = [...checkedValues, value];
    } else {
      newCheckedValues = checkedValues.filter((v) => v !== value);
      // newCheckedValues = [value];
    }

    newCheckedValues.sort((a, b) => {
      const weightA = RECURRING_FREQUENCY_ORDER_MAP[a];
      const weightB = RECURRING_FREQUENCY_ORDER_MAP[b];
      console.log("weighta", weightA);
      console.log("weightb", weightB);
      if (weightA === weightB) {
        return a.localeCompare(b);
      } else {
        return weightB - weightA;
      }
    });
    console.log("newCheckedValues: ", newCheckedValues);
    setCheckedValues(newCheckedValues);
  };

  // internal functions
  const checkFormErrors = () => {
    // console.log("checkedValues: ", checkedValues, "selectedValue: ", selectedValue)

    if (
      checkedValues.length > 1 &&
      (selectedValue === "" ||
        selectedValue === null ||
        selectedValue === "Select an option")
    ) {
      return "A default frequency is required";
    }
    if (elementName === "") {
      return "Element Name is required";
    }
    if (
      (oneTimeAmount1 == "" ||
        oneTimeAmount2 == "" ||
        oneTimeAmount3 == "" ||
        oneTimeAmount4 == "") &&
      oneTimeChecked
    ) {
      if (separateAmounts) {
        return "One-Time amounts are required";
      }
    }
    if (
      (weeklyAmount1 == "" ||
        weeklyAmount2 == "" ||
        weeklyAmount3 == "" ||
        weeklyAmount4 == "") &&
      weeklyChecked
    ) {
      if (separateAmounts) {
        return "Weekly amounts are required";
      }
    }
    if (
      (dailyAmount1 == "" ||
        dailyAmount2 == "" ||
        dailyAmount3 == "" ||
        dailyAmount4 == "") &&
      dailyChecked
    ) {
      if (separateAmounts) {
        return "Daily amounts are required";
      }
    }
    if (
      (annuallyAmount1 == "" ||
        annuallyAmount2 == "" ||
        annuallyAmount3 == "" ||
        annuallyAmount4 == "") &&
      annuallyChecked
    ) {
      if (separateAmounts) {
        return "Annual amounts are required";
      }
    }
    if (
      (monthlyAmount1 == "" ||
        monthlyAmount2 == "" ||
        monthlyAmount3 == "" ||
        monthlyAmount4 == "") &&
      monthlyChecked
    ) {
      if (separateAmounts) {
        return "Monthly amounts are required";
      }
    }
    if (oneTimeDefault == "" && showOneTimeDefault) {
      return 'Please provide a default One-Time amount, or deselect the "Set One-Time Default" toggle';
    }
    if (monthlyDefault == "" && showMonthlyDefault) {
      return 'Please provide a default Monthly amount, or deselect the "Set Monthly Default" toggle';
    }
    if (dailyDefault == "" && showDailyDefault) {
      return 'Please provide a default Daily amount, or deselect the "Set Daily Default" toggle';
    }
    if (weeklyDefault == "" && showWeeklyDefault) {
      return 'Please provide a default Weekly amount, or deselect the "Set Weekly Default" toggle';
    }
    if (annuallyDefault == "" && showAnnuallyDefault) {
      return 'Please provide a default Annually amount, or deselect the "Set Annually Default" toggle';
    }
    if (color.length != 7) {
      return "Color is required";
    }
    if (funds.length == 0) {
      return "At least one fund is required";
    }
    //iterate through customInputs and check for errors
    for (let i = 0; i < customInputs.length; i++) {
      if (customInputs[i].title == "") {
        return "Custom Input Label is required";
      }
      if (customInputs[i].type == "Select an Input Type") {
        return "Custom Input Type is required";
      }
      if (
        (customInputs[i].type == "SINGLE_SELECTION_DROPDOWN" ||
          customInputs[i].type == "SINGLE_SELECTION_DROPDOWN") &&
        customInputs[i].options.length < 2
      ) {
        return "2 or more Custom Input Options are required for Dropdowns";
      }
    }

    for (let i = 0; i < customFAQs.length; i++) {
      if (customFAQs[i].question == "" || customFAQs[i].answer === "") {
        return "FAQ details are required";
      }
    }

    return "";
  };

  const changeEmailSettings = async () => {
    if (
      emailCustomization === true &&
      (emailMessage === "" || subject === "" || replyToEmailAddress === "")
    ) {
      setErrorMessage("Tax Receipts is missing required fields");
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      setIsDisabled(false);
      return;
    }

    const payload = {
      replyToEmailAddress: replyToEmailAddress,
      subject: subject,
      emailMessage: emailMessage,
      requestType: "FORMS_PAGE",
      formName: elementName,
      merchantName: props.merchant.merchantName,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(payload),
    };

    const deletePayload = {
      replyToEmailAddress: replyToEmailAddress,
      subject: subject,
      emailMessage: emailMessage,
      requestType: "FORMS_PAGE",
      formName: elementName,
      merchantName: props.merchant.merchantName,
    };
    const queryParams = Object.entries(deletePayload)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    const requestOptionsDelete = {
      method: "DELETE",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(deletePayload),
    };

    const url = process.env.REACT_APP_EMAIL_CONFIG_URL;

    if (emailCustomization === false) {
      console.log("reverting to default template");
      let response = await fetch(url + "?" + queryParams, requestOptionsDelete);
      console.log(response);

      let data = await response.json();
      console.log({ data });
      if (data.error) {
        setIsDisabled(false);
        setErrorMessage(data.error);
        setShowErrorAlert(true);
        // unshowing success alert if it was never closed out
        setShowSuccessAlert(false);
        return;
      } else {
        props.grabSelectableFunds(jwtToken);
        setIsDisabled(false);
        setSuccessMessage(
          createdForm ? "Form Saved Successfully" : "Form Created Successfully"
        );
        setCreatedForm(true);
        // unshowing error alert if it was never closed out
        setShowErrorAlert(false);
        setShowSuccessAlert(true);
      }
    }

    if (emailCustomization === true) {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      console.log({ data });
      if (data.error) {
        setIsDisabled(false);
        setErrorMessage(data.error);
        setShowErrorAlert(true);
        // unshowing success alert if it was never closed out
        setShowSuccessAlert(false);
        return;
      } else {
        props.grabSelectableFunds(jwtToken);
        setIsDisabled(false);
        setSuccessMessage(
          createdForm ? "Form Saved Successfully" : "Form Created Successfully"
        );
        setCreatedForm(true);
        // unshowing error alert if it was never closed out
        setShowErrorAlert(false);
        setShowSuccessAlert(true);
      }
    }

    /*  let response = await fetch(url, requestOptions)
          let data = await response.json()
          console.log({ data })
          if (data.error) {
            setErrorMessage(data.error)
            setShowErrorAlert(true)
            // unshowing success alert if it was never closed out
            setShowSuccessAlert(false)
            return
          }
          else {
            props.grabSelectableFunds(jwtToken)
            setIsDisabled(false)
            // unshowing error alert if it was never closed out
            setShowErrorAlert(false)
            setShowSuccessAlert(true)
          }
          */
  };

  const sendToServer = async () => {
    let returnedError = checkFormErrors();
    console.log(returnedError);
    if (returnedError != "") {
      setErrorMessage(returnedError);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    }
    const darkColor = newShade(color, -50);

    let oneTimeAmounts = [
      oneTimeAmount1,
      oneTimeAmount2,
      oneTimeAmount3,
      oneTimeAmount4,
    ];
    let weeklyAmounts = [
      weeklyAmount1,
      weeklyAmount2,
      weeklyAmount3,
      weeklyAmount4,
    ];
    let dailyAmounts = [dailyAmount1, dailyAmount2, dailyAmount3, dailyAmount4];
    let annuallyAmounts = [
      annuallyAmount1,
      annuallyAmount2,
      annuallyAmount3,
      annuallyAmount4,
    ];
    let monthlyAmounts = [
      monthlyAmount1,
      monthlyAmount2,
      monthlyAmount3,
      monthlyAmount4,
    ];

    oneTimeAmounts = oneTimeAmounts.map((amount, index) => {
      if (amount === "") {
        amount = defaultAmounts[index];
      }
      return parseFloat(amount);
    });

    weeklyAmounts = weeklyAmounts.map((amount, index) => {
      if (amount === "") {
        amount = defaultAmounts[index];
      }
      return parseFloat(amount);
    });

    dailyAmounts = dailyAmounts.map((amount, index) => {
      if (amount === "") {
        amount = defaultAmounts[index];
      }
      return parseFloat(amount);
    });

    annuallyAmounts = annuallyAmounts.map((amount, index) => {
      if (amount === "") {
        amount = defaultAmounts[index];
      }
      return parseFloat(amount);
    });
    monthlyAmounts = monthlyAmounts.map((amount, index) => {
      if (amount === "") {
        amount = defaultAmounts[index];
      }
      return parseFloat(amount);
    });

    const mapping = {
      "One-Time": "ONE_TIME",
      Daily: "DAILY",
      Weekly: "WEEKLY",
      Monthly: "MONTHLY",
      Annually: "ANNUALLY",
      "": "",
    };

    let defaultFrequency = mapping[selectedValue];
    let frequencies1 = ["MONTHLY", "ONE_TIME", "ANNUALLY", "WEEKLY", "DAILY"];
    let frequencies = checkedValues.map((value) => {
      switch (value) {
        case "One-Time":
          return "ONE_TIME";
        case "Weekly":
          return "WEEKLY";
        case "Daily":
          return "DAILY";
        case "Monthly":
          return "MONTHLY";
        case "Annually":
          return "ANNUALLY";
        default:
          return null; // or throw an error if you want to handle invalid values
      }
    });
    let showScheduleDonations = requireScheduleDonations;
    if (
      !(
        checkedValues.includes("Daily") ||
        checkedValues.includes("Weekly") ||
        checkedValues.includes("Monthly") ||
        checkedValues.includes("Annually")
      )
    ) {
      showScheduleDonations = false;
    }
    let formType = "";
    let allOptionsDescriptionsAndAmounts = [];
    const stringValue = checkedValues.join(", ");

    setIsDisabled(true);
    const payload = {
      formName: elementName,
      //    "orientation": flagPosition,
      //   "destinationURL": flagPageUrl,
      //   "nonprofitName": merchantName,
      //    "color": color,
      //             "elementName": elementName,
      elementType: "QUICK_DONATE",
      //   "defaultMonthly": defaultMonthly,
      defaultFrequency: defaultFrequency,
      frequencies: stringValue,
      merchantName: props.merchant.merchantName,
      oneTimeDefaultAmount:
        showOneTimeDefault && checkedValues.includes("One-Time")
          ? parseFloat(oneTimeDefault)
          : 0,
      weeklyDefaultAmount:
        showWeeklyDefault && checkedValues.includes("Weekly")
          ? parseFloat(weeklyDefault)
          : 0,
      dailyDefaultAmount:
        showDailyDefault && checkedValues.includes("Daily")
          ? parseFloat(dailyDefault)
          : 0,
      annuallyDefaultAmount:
        showAnnuallyDefault && checkedValues.includes("Annually")
          ? parseFloat(annuallyDefault)
          : 0,
      monthlyDefaultAmount:
        showMonthlyDefault && checkedValues.includes("Monthly")
          ? parseFloat(monthlyDefault)
          : 0,
      // do something similar for option1title, option1amount, option1description
      // maybe convert option1amount to int
      requirePhoneNumber: requirePhoneNumber,
      requireScheduleDonations: showScheduleDonations,
      requireBillingAddress: requireBillingAddress,
      requireCountryDropdown: requireCountryDropdown,
      selectedCountry: selectedCountry,
      funds: funds,
      lightColor: color,
      darkColor: darkColor,
      requireDoubleTheDonation: requireDoubleTheDonation,
      dtdPublicApiKey: dtdPublicApiKey,
      customInputs: customInputs,
      formType: "QUICK_DONATE",
      elementID: elementID,
      faqs: customFAQs,
      showAnonymousDonations: showAnonymousDonations,
    };

    console.log("payload is as follows: ", payload);
    const requestOptions = {
      method: createdForm ? "PATCH" : "POST", // choose the method based on if it is an updated or a new form
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(payload),
    };

    const url = process.env.REACT_APP_FORMS_URL;

    let response = await fetch(url, requestOptions);
    let data = await response.json();
    console.log({ data });
    if (data.error) {
      setIsDisabled(false);
      setErrorMessage(data.error);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    } else {
      changeEmailSettings();
      if (elementID === "") {
        setElementID(data.elementID);
      }
      // props.grabSelectableFunds(jwtToken)
      // setIsDisabled(true)
      // // if previosly created form, then set success message to 'Form Saved Successfully'
      // setSuccessMessage(createdForm ? 'Form Saved Successfully' : 'Form Created Successfully')
      // // set createdForm to true so that next time it will be a PATCH request
      // setCreatedForm(true)
      // // unshowing error alert if it was never closed out
      // setShowErrorAlert(false)
      // setShowSuccessAlert(true)
    }
  };

  // Clear states when modal is closed
  const clearStates = () => {
    // close slideover
    props.setOpen(false);

    // return to default states
    setelementName("");
    setDefaultDonationType("Monthly");
    setSeparateAmounts(true);
    setOneTimeAmount1("");
    setOneTimeAmount2("");
    setOneTimeAmount3("");
    setOneTimeAmount4("");
    setMonthlyAmount1("");
    setMonthlyAmount2("");
    setMonthlyAmount3("");
    setMonthlyAmount4("");
    setShowOneTimeDefault(false);
    setShowMonthlyDefault(false);
    setShowDailyDefault(false);
    setShowWeeklyDefault(false);
    setshowAnnuallyDefault(false);
    setOneTimeDefault("");
    setMonthlyDefault("");
    setDailyDefault("");
    setWeeklyDefault("");
    setAnnuallyDefault("");
    setRequirePhoneNumber(false);
    setRequireScheduleDonations(false);
    setRequireBillingAddress(false);
    setRequireCountryDropdown(false);
    setSelectedCountry("Choose Default Country");
    setFunds([]);
    setColor("");
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
    setIsDisabled(false);
    setMonthlyChecked(false);
    setOneTimeChecked(false);
    setWeeklyChecked(false);
    setAnnuallyChecked(false);
    setDailyChecked(false);
    setCheckedValues([]);
    setRequireDoubleTheDonation(false);
    setCustomInputs([]);
    setActiveTab({ name: "Form Options", current: true });
    setCreatedForm(false);
    setElementID("");
    setCrowdFundingFormType(false);
    setOption1Amount("");
    setOption1Description("");
    setOption1Title("");
    setOption2Amount("");
    setOption2Description("");
    setOption2Title("");
    setOption3Amount("");
    setOption3Description("");
    setOption3Title("");
    setOption4Amount("");
    setOption4Description("");
    setOption4Title("");

    setCustomFAQs("");
    setOriginalCustomFAQs("");
    setShowAnonymousDonations(false);
  };

  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      const formattedMerchants = data["idToken"]["payload"][
        "cognito:groups"
      ].map((merchant) => merchant.replace(/-/g, " "));

      setJWTToken(data["idToken"]["jwtToken"]);
    };
    myFunction();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
  }, [props.open]);

  useEffect(() => {
    if (checkedValues.length > 1 && selectedValue === "") {
      setSelectedValue("Select an option");
    } else if (
      checkedValues.length > 1 &&
      checkedValues.includes(selectedValue) === false
    ) {
      setSelectedValue("Select an option");
    } else if (checkedValues.length === 0) {
      setSelectedValue("Select an option");
    } else if (checkedValues.length === 1) {
      console.log("seeting checked value", checkedValues[0]);
      setSelectedValue(checkedValues[0]);
    }
  }, [checkedValues]);

  useEffect(() => {
    const myFunction = async () => {
      let auth = jwtToken;
      if (props.merchant !== null) {
        if (auth === "") {
          const data = await Auth.currentSession();
          setJWTToken(data["idToken"]["jwtToken"]);
          auth = data["idToken"]["jwtToken"];
        }

        // grab double the donation api key if it exists
        const requestOptions = {
          method: "GET",
          headers: {
            accept: "*/*",
            "content-type": "application/json",
            Authorization: auth,
          },
        };

        const params = new URLSearchParams();
        params.append("nonprofit", props.merchant["merchantName"]);
        const url = process.env.REACT_APP_CS_DTD_SERVER_URL + "/api-keys";

        const response = await fetch(
          url + `?${params.toString()}`,
          requestOptions
        );

        let responseData = await response.json();
        // console.log('responseData is', responseData['publicApiKey'])
        setDtdPublicApiKey(responseData["publicApiKey"]);
      }
    };
    myFunction();
  }, [props.merchant]);

  let submitButtonClass =
    "justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2";
  if (isDisabled) {
    submitButtonClass =
      "justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm cursor-not-allowed focus:outline-none";
  }
  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                New Quick Donate Embed
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Get started by filling in the information below
                                to create your new quick donate embed.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={clearStates}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                              Select a tab
                            </label>
                            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                            <select
                              id="tabs"
                              name="tabs"
                              className="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              defaultValue={
                                tabs.find((tab) => tab.current).name
                              }
                            >
                              {tabs.map((tab) => (
                                <option key={tab.name}>{tab.name}</option>
                              ))}
                            </select>
                          </div>
                          <div className="hidden sm:block">
                            <div className="border-b border-gray-200">
                              <nav
                                className="-mb-px flex space-x-8 px-5"
                                aria-label="Tabs"
                              >
                                {tabs.map((tab) => (
                                  <a
                                    key={tab.name}
                                    href={tab.href}
                                    onClick={() =>
                                      setActiveTab({
                                        name: tab.name,
                                        current: true,
                                      })
                                    }
                                    className={classNames(
                                      tab.name === activeTab.name
                                        ? "border-green-500 text-green-600"
                                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                      "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                                    )}
                                    aria-current={
                                      tab.name === activeTab.name
                                        ? "page"
                                        : undefined
                                    }
                                  >
                                    {tab.name}
                                  </a>
                                ))}
                              </nav>
                            </div>
                          </div>
                        </div>
                        {/* Divider container */}
                        {/* {
                          activeTab.name === 'Custom Fields' &&
                          <CustomInputsTab customInputs={customInputs} setCustomInputs={setCustomInputs} originalCustomInputs={originalCustomInputs} />
                        } */}

                        {activeTab.name === "FAQs" && (
                          <CustomFAQsTab
                            customFAQs={customFAQs}
                            setCustomFAQs={setCustomFAQs}
                            originalCustomFAQs={originalCustomFAQs}
                          />
                        )}
                        {activeTab.name === "Tax Receipts" && (
                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:py-0">
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <label
                                htmlFor="form-name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Customization*
                                <svg
                                  data-tip
                                  data-for="emailCustomizationTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="emailCustomizationTooltip"
                                  effect="solid"
                                  place="right"
                                >
                                  <p className="text-sm text-white">
                                    This is where you can customize the details
                                    of the{" "}
                                  </p>
                                  <p className="text-sm text-white">
                                    email sent out to donors.
                                  </p>
                                </ReactTooltip>
                              </label>
                              <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
                                <input
                                  type="radio"
                                  value="false"
                                  name="emailCustomization"
                                  onClick={() => setEmailCustomization(false)}
                                  style={{
                                    backgroundColor:
                                      emailCustomization === false
                                        ? "green"
                                        : "white",
                                    marginTop: "4px",
                                    marginRight: "5px",
                                  }}
                                />
                                Default&nbsp;&nbsp;
                                <input
                                  type="radio"
                                  value="false"
                                  name="emailCustomization"
                                  onClick={() => setEmailCustomization(true)}
                                  style={{
                                    backgroundColor:
                                      emailCustomization === true
                                        ? "green"
                                        : "white",
                                    marginTop: "4px",
                                    marginRight: "5px",
                                  }}
                                />
                                Custom Message
                              </div>
                            </div>
                            {emailCustomization === true && (
                              <>
                                {/* Form name */}
                                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <label
                                    htmlFor="form-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Reply-To Address*
                                    <svg
                                      data-tip
                                      data-for="replytoTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="replytoTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        This is the name of the email address
                                        that
                                      </p>
                                      <p className="text-sm text-white">
                                        the donors will see on their donor
                                        receipts.
                                      </p>
                                      <p className="text-sm text-white">
                                        It must be a full email address.
                                      </p>
                                    </ReactTooltip>
                                  </label>
                                  <div className="sm:col-span-2">
                                    <input
                                      value={replyToEmailAddress}
                                      type="text"
                                      className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                      onChange={(e) =>
                                        replyToEmailAddressChange(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <div>
                                    <label
                                      htmlFor="form-name"
                                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                    >
                                      Subject*
                                      <svg
                                        data-tip
                                        data-for="subjectTooltip"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="text-primary ml-1 inline-block h-5 w-5"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                          clip-rule="evenodd"
                                        />
                                      </svg>
                                      <ReactTooltip
                                        id="subjectTooltip"
                                        effect="solid"
                                        place="right"
                                      >
                                        <p className="text-sm text-white">
                                          This is the subject of the email that
                                        </p>
                                        <p className="text-sm text-white">
                                          the donors will see on their donor
                                          receipts.
                                        </p>
                                      </ReactTooltip>
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <input
                                      value={subject}
                                      type="text"
                                      className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                      onChange={(e) =>
                                        subjectChange(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <label
                                    htmlFor="form-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Custom Message*
                                    <svg
                                      data-tip
                                      data-for="customMessageTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="customMessageTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        This is the message that will be shown
                                        in the{" "}
                                      </p>
                                      <p className="text-sm text-white">
                                        body of the email that donors will see
                                        on their donor receipts.
                                      </p>
                                    </ReactTooltip>
                                  </label>

                                  <div className="sm:col-span-2">
                                    <p class="py-2">
                                      <select
                                        id="variables"
                                        class="float-right mb-2 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        onChange={(e) =>
                                          variableClick(e.target.value)
                                        }
                                      >
                                        <option
                                          selected="true"
                                          disabled="disabled"
                                        >
                                          Variables
                                        </option>
                                        <option value="{{name}}">
                                          Donor Name
                                        </option>
                                        <option value="{{total}}">
                                          Donated Amount
                                        </option>
                                        <option value="{{fund}}">
                                          Donated Fund
                                        </option>
                                        <option value="{{nonprofit}}">
                                          Nonprofit Name
                                        </option>
                                        <option value="{{phone}}">
                                          Donor Phone Number
                                        </option>
                                        <option value="{{address}}">
                                          Donor Address
                                        </option>
                                        <option value="{{email}}">
                                          Donor Email
                                        </option>
                                        <option value="{{paymentMethod}}">
                                          Donor Payment Method
                                        </option>
                                        <option value="{{feeCovered}}">
                                          Covered Fee*
                                        </option>
                                        <option value="{{ein}}">EIN</option>
                                      </select>
                                      <textarea
                                        value={emailMessage}
                                        type="text"
                                        className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                        onChange={(e) =>
                                          emailMessageChange(e.target.value)
                                        }
                                        rows="4"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        {activeTab.name === "Form Options" && (
                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:py-0">
                            {/* Form name */}
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              {!createdForm && (
                                <>
                                  <div>
                                    <label
                                      htmlFor="form-name"
                                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                    >
                                      Element name*
                                      <svg
                                        data-tip
                                        data-for="elementNameTooltip"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="text-primary ml-1 inline-block h-5 w-5"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                          clip-rule="evenodd"
                                        />
                                      </svg>
                                      <ReactTooltip
                                        id="elementNameTooltip"
                                        effect="solid"
                                        place="right"
                                      >
                                        <p className="text-sm text-white">
                                          This is the name of your quick donate
                                          embed.{" "}
                                        </p>
                                      </ReactTooltip>
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <input
                                      value={elementName}
                                      onChange={(e) =>
                                        setelementName(
                                          e.target.value.replace("|", "")
                                        )
                                      }
                                      type="text"
                                      name="form-name"
                                      id="form-name"
                                      className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                    />
                                  </div>
                                </>
                              )}
                              {createdForm && (
                                <>
                                  <div>
                                    <label
                                      htmlFor="form-name"
                                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                    >
                                      Form name*
                                      <svg
                                        data-tip
                                        data-for="elementNameTooltip"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="text-primary ml-1 inline-block h-5 w-5"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                          clip-rule="evenodd"
                                        />
                                      </svg>
                                      <ReactTooltip
                                        id="elementNameTooltip"
                                        effect="solid"
                                        place="right"
                                      >
                                        <p className="text-sm text-white">
                                          This is the name of the donation form
                                          you created.
                                        </p>
                                      </ReactTooltip>
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <div className="py-2 text-sm text-gray-700">
                                      {elementName}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                            {/* Donation Frequencies */}
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="form-description"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Donation frequencies*
                                  <svg
                                    data-tip
                                    data-for="donationTypeTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="donationTypeTooltip"
                                    effect="solid"
                                    place="right"
                                  >
                                    <p className="text-sm text-white">
                                      Choose the donation frequencies
                                    </p>
                                    <p className="text-sm text-white">
                                      for your form. If you select more than
                                      one,
                                    </p>
                                    <p className="text-sm text-white">
                                      you will be asked to set a default option.
                                    </p>
                                  </ReactTooltip>
                                </label>
                              </div>
                              <div className="flex flex-wrap items-center sm:col-span-2">
                                <div className="p grid grid-cols-3 text-sm">
                                  <div className="pb-1">
                                    <input
                                      type="radio"
                                      id="oneTime"
                                      name="frequency"
                                      checked={checkedValues.includes(
                                        "One-Time"
                                      )}
                                      onChange={() =>
                                        handleFrequencyRadioChange("One-Time")
                                      }
                                      style={{
                                        backgroundColor: checkedValues.includes(
                                          "One-Time"
                                        )
                                          ? "green"
                                          : "white",
                                      }}
                                    />
                                    &nbsp;&nbsp;One-Time&nbsp;&nbsp;
                                  </div>
                                  <div className="pb-1">
                                    <input
                                      type="radio"
                                      id="daily"
                                      name="frequency"
                                      checked={checkedValues.includes("Daily")}
                                      onChange={() =>
                                        handleFrequencyRadioChange("Daily")
                                      }
                                      style={{
                                        backgroundColor: checkedValues.includes(
                                          "Daily"
                                        )
                                          ? "green"
                                          : "white",
                                      }}
                                    />
                                    &nbsp;&nbsp;Daily&nbsp;&nbsp;
                                  </div>
                                  <div className="pb-1">
                                    <input
                                      type="radio"
                                      id="weekly"
                                      name="frequency"
                                      checked={checkedValues.includes("Weekly")}
                                      onChange={() =>
                                        handleFrequencyRadioChange("Weekly")
                                      }
                                      style={{
                                        backgroundColor: checkedValues.includes(
                                          "Weekly"
                                        )
                                          ? "green"
                                          : "white",
                                      }}
                                    />
                                    &nbsp;&nbsp;Weekly&nbsp;&nbsp;
                                  </div>
                                  <div>
                                    <input
                                      type="radio"
                                      id="monthly"
                                      name="frequency"
                                      checked={checkedValues.includes(
                                        "Monthly"
                                      )}
                                      onChange={() =>
                                        handleFrequencyRadioChange("Monthly")
                                      }
                                      style={{
                                        backgroundColor: checkedValues.includes(
                                          "Monthly"
                                        )
                                          ? "green"
                                          : "white",
                                      }}
                                    />
                                    &nbsp;&nbsp;Monthly&nbsp;&nbsp;
                                  </div>
                                  <div>
                                    <input
                                      type="radio"
                                      id="annually"
                                      name="frequency"
                                      checked={checkedValues.includes(
                                        "Annually"
                                      )}
                                      onChange={() =>
                                        handleFrequencyRadioChange("Annually")
                                      }
                                      style={{
                                        backgroundColor: checkedValues.includes(
                                          "Annually"
                                        )
                                          ? "green"
                                          : "white",
                                      }}
                                    />
                                    &nbsp;&nbsp;Annually&nbsp;&nbsp;
                                  </div>
                                </div>
                              </div>
                              {checkedValues.length > 1 && (
                                <>
                                  <p className="py-2 text-sm text-gray-500">
                                    Default frequency
                                  </p>
                                  <div style={{ marginLeft: "auto" }}>
                                    <Dropdown
                                      values={checkedValues}
                                      selectedValue={selectedValue}
                                      setSelectedValue={setSelectedValue}
                                      width={"w-44"}
                                    />
                                  </div>
                                </>
                              )}
                            </div>

                            {/* Crowdfunding specific section*/}

                            {/* Default amount */}
                            {(checkedValues.includes("Daily") ||
                              checkedValues.includes("Weekly") ||
                              checkedValues.includes("Monthly") ||
                              checkedValues.includes("Annually") ||
                              checkedValues.includes("One-Time")) && (
                              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="form-description"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Default amount
                                    <svg
                                      data-tip
                                      data-for="defaultAmountTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="defaultAmountTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        You can choose to set a default selected
                                        amount
                                      </p>
                                      <p className="text-sm text-white">
                                        for your donation form. This will load
                                        your form
                                      </p>
                                      <p className="text-sm text-white">
                                        with the amount pre-selected.
                                      </p>
                                    </ReactTooltip>
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  {checkedValues.includes("One-Time") && (
                                    <div className="mt-4">
                                      <Toggle
                                        name={"Set One-Time Default"}
                                        enabled={showOneTimeDefault}
                                        setEnabled={setShowOneTimeDefault}
                                      />
                                    </div>
                                  )}
                                  {checkedValues.includes("Daily") && (
                                    <div className="mt-4">
                                      <Toggle
                                        name={"Set Daily Default"}
                                        enabled={showDailyDefault}
                                        setEnabled={setShowDailyDefault}
                                      />
                                    </div>
                                  )}
                                  {checkedValues.includes("Weekly") && (
                                    <div className="mt-4">
                                      <Toggle
                                        name={"Set Weekly Default"}
                                        enabled={showWeeklyDefault}
                                        setEnabled={setShowWeeklyDefault}
                                      />
                                    </div>
                                  )}
                                  {checkedValues.includes("Monthly") && (
                                    <div className="mt-4">
                                      <Toggle
                                        name={"Set Monthly Default"}
                                        enabled={showMonthlyDefault}
                                        setEnabled={setShowMonthlyDefault}
                                      />
                                    </div>
                                  )}
                                  {checkedValues.includes("Annually") && (
                                    <div className="mt-4">
                                      <Toggle
                                        name={"Set Annual Default"}
                                        enabled={showAnnuallyDefault}
                                        setEnabled={setshowAnnuallyDefault}
                                      />
                                    </div>
                                  )}
                                </div>

                                {showOneTimeDefault &&
                                  checkedValues.includes("One-Time") && (
                                    <>
                                      <p className="py-2 text-sm text-gray-500">
                                        One-Time
                                      </p>
                                      <div className="flex grid grid-cols-4 flex-wrap space-x-2 sm:col-span-2">
                                        <input
                                          type="number"
                                          name="oneTimeDefault"
                                          id="oneTimeDefault"
                                          className="focus:border-secondary focus:ring-secondary col-start-1 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                          placeholder="$10"
                                          value={oneTimeDefault}
                                          onChange={(e) =>
                                            setOneTimeDefault(
                                              e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                              )
                                            )
                                          }
                                        />
                                      </div>
                                    </>
                                  )}
                                {showDailyDefault &&
                                  checkedValues.includes("Daily") && (
                                    <>
                                      <p className="py-2 text-sm text-gray-500">
                                        Daily
                                      </p>
                                      <div className="flex grid grid-cols-4 flex-wrap space-x-2 sm:col-span-2">
                                        <input
                                          type="number"
                                          name="dailyDefault"
                                          id="dailyDefault"
                                          className="focus:border-secondary focus:ring-secondary col-start-1 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                          placeholder="$10"
                                          value={dailyDefault}
                                          onChange={(e) =>
                                            setDailyDefault(
                                              e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                              )
                                            )
                                          }
                                        />
                                      </div>
                                    </>
                                  )}
                                {showWeeklyDefault &&
                                  checkedValues.includes("Weekly") && (
                                    <>
                                      <p className="py-2 text-sm text-gray-500">
                                        Weekly
                                      </p>
                                      <div className="flex grid grid-cols-4 flex-wrap space-x-2 sm:col-span-2">
                                        <input
                                          type="number"
                                          name="weeklyDefault"
                                          id="weeklyDefault"
                                          className="focus:border-secondary focus:ring-secondary col-start-1 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                          placeholder="$10"
                                          value={weeklyDefault}
                                          onChange={(e) =>
                                            setWeeklyDefault(
                                              e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                              )
                                            )
                                          }
                                        />
                                      </div>
                                    </>
                                  )}
                                {showMonthlyDefault &&
                                  checkedValues.includes("Monthly") && (
                                    <>
                                      <p className="py-2 text-sm text-gray-500">
                                        Monthly
                                      </p>
                                      <div className="flex grid grid-cols-4 flex-wrap space-x-2 sm:col-span-2">
                                        <input
                                          type="number"
                                          name="monthlyDefault"
                                          id="monthlyDefault"
                                          className="focus:border-secondary focus:ring-secondary col-start-1 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                          placeholder="$10"
                                          value={monthlyDefault}
                                          onChange={(e) =>
                                            setMonthlyDefault(
                                              e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                              )
                                            )
                                          }
                                        />
                                      </div>
                                    </>
                                  )}
                                {showAnnuallyDefault &&
                                  checkedValues.includes("Annually") && (
                                    <>
                                      <p className="py-2 text-sm text-gray-500">
                                        Annually
                                      </p>
                                      <div className="flex grid grid-cols-4 flex-wrap space-x-2 sm:col-span-2">
                                        <input
                                          type="number"
                                          name="annuallyDefault"
                                          id="annuallyDefault"
                                          className="focus:border-secondary focus:ring-secondary col-start-1 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                          placeholder="$10"
                                          value={annuallyDefault}
                                          onChange={(e) =>
                                            setAnnuallyDefault(
                                              e.target.value.replace(
                                                /[^0-9]/g,
                                                ""
                                              )
                                            )
                                          }
                                        />
                                      </div>
                                    </>
                                  )}
                              </div>
                            )}

                            {/* amount Buttons 
                                                        {/* only one-time checked 
                                                        {
                                                            checkedValues.includes('One-Time') &&
                                                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 sm:items-center">
                                                                <div>
                                                                    <label
                                                                        htmlFor="form-description"
                                                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                                                    >
                                                                        One-Time buttons*
                                                                        <svg data-tip data-for='amountButtonsTooltipOneTime' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="inline-block w-5 h-5 ml-1 text-primary">
                                                                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                                                        </svg>
                                                                        <ReactTooltip id='amountButtonsTooltipOneTime' effect="solid" place='right'>
                                                                            <p className="text-sm text-white">These are the values for the</p>
                                                                            <p className="text-sm text-white">one-time amount buttons. </p>
                                                                        </ReactTooltip>
                                                                    </label>
                                                                </div>
                                                                <div className="sm:col-span-2 grid grid-cols-4 flex flex-wrap space-x-2">
                                                                    <input
                                                                        type="number"
                                                                        name="amount-1"
                                                                        id="amount-1"
                                                                        className="block placeholder-gray-400 col-start-1 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$10'
                                                                        value={oneTimeAmount1}
                                                                        onChange={(e) => setOneTimeAmount1(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="amount-2"
                                                                        id="amount-2"
                                                                        className="block placeholder-gray-400 col-start-2 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$25'
                                                                        value={oneTimeAmount2}
                                                                        onChange={(e) => setOneTimeAmount2(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="amount-3"
                                                                        id="amount-3"
                                                                        className="block placeholder-gray-400 col-start-3 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$50'
                                                                        value={oneTimeAmount3}
                                                                        onChange={(e) => setOneTimeAmount3(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="amount-4"
                                                                        id="amount-4"
                                                                        className="block placeholder-gray-400 col-start-4 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$100'
                                                                        value={oneTimeAmount4}
                                                                        onChange={(e) => setOneTimeAmount4(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }

                                                        {/* amount Buttons 
                                                        {/* only daily checked
                                                        {
                                                            checkedValues.includes('Daily') &&
                                                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                                <div>
                                                                    <label
                                                                        htmlFor="form-description"
                                                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                                                    >
                                                                        Daily buttons*
                                                                        <svg data-tip data-for='amountButtonsTooltipDaily' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="inline-block w-5 h-5 ml-1 text-primary">
                                                                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                                                        </svg>
                                                                        <ReactTooltip id='amountButtonsTooltipDaily' effect="solid" place='right'>
                                                                            <p className="text-sm text-white">These are the values for the</p>
                                                                            <p className="text-sm text-white">daily amount buttons. </p>
                                                                        </ReactTooltip>
                                                                    </label>
                                                                </div>
                                                                {/* <div className="py-2 sm:col-span-2"></div>
                              <p className="text-sm text-gray-500 py-2">
                                Monthly
                              </p> 
                                                                <div className="sm:col-span-2 grid grid-cols-4 flex flex-wrap space-x-2">
                                                                    <input
                                                                        type="number"
                                                                        name="amount-1"
                                                                        id="amount-1"
                                                                        className="block placeholder-gray-400 col-start-1 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$10'
                                                                        value={dailyAmount1}
                                                                        onChange={(e) => setDailyAmount1(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="amount-2"
                                                                        id="amount-2"
                                                                        className="block placeholder-gray-400 col-start-2 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$25'
                                                                        value={dailyAmount2}
                                                                        onChange={(e) => setDailyAmount2(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="amount-3"
                                                                        id="amount-3"
                                                                        className="block placeholder-gray-400 col-start-3 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$50'
                                                                        value={dailyAmount3}
                                                                        onChange={(e) => setDailyAmount3(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="amount-4"
                                                                        id="amount-4"
                                                                        className="block placeholder-gray-400 col-start-4 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$100'
                                                                        value={dailyAmount4}
                                                                        onChange={(e) => setDailyAmount4(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {/* weekly checked 
                                                        {
                                                            checkedValues.includes('Weekly') &&
                                                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 sm:items-center">
                                                                <div>
                                                                    <label
                                                                        htmlFor="form-description"
                                                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                                                    >
                                                                        Weekly buttons*
                                                                        <svg data-tip data-for='amountButtonsTooltipWeekly' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="inline-block w-5 h-5 ml-1 text-primary">
                                                                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                                                        </svg>
                                                                        <ReactTooltip id='amountButtonsTooltipWeekly' effect="solid" place='right'>
                                                                            <p className="text-sm text-white">These are the values for the</p>
                                                                            <p className="text-sm text-white">weekly amount buttons. </p>
                                                                        </ReactTooltip>
                                                                    </label>
                                                                </div>
                                                                <div className="sm:col-span-2 grid grid-cols-4 flex flex-wrap space-x-2">
                                                                    <input
                                                                        type="number"
                                                                        name="amount-1"
                                                                        id="amount-1"
                                                                        className="block placeholder-gray-400 col-start-1 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$10'
                                                                        value={weeklyAmount1}
                                                                        onChange={(e) => setWeeklyAmount1(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="amount-2"
                                                                        id="amount-2"
                                                                        className="block placeholder-gray-400 col-start-2 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$25'
                                                                        value={weeklyAmount2}
                                                                        onChange={(e) => setWeeklyAmount2(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="amount-3"
                                                                        id="amount-3"
                                                                        className="block placeholder-gray-400 col-start-3 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$50'
                                                                        value={weeklyAmount3}
                                                                        onChange={(e) => setWeeklyAmount3(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="amount-4"
                                                                        id="amount-4"
                                                                        className="block placeholder-gray-400 col-start-4 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$100'
                                                                        value={weeklyAmount4}
                                                                        onChange={(e) => setWeeklyAmount4(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {/* only monthly checked 
                                                        {
                                                            checkedValues.includes('Monthly') &&
                                                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                                <div>
                                                                    <label
                                                                        htmlFor="form-description"
                                                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                                                    >
                                                                        Monthly buttons*
                                                                        <svg data-tip data-for='amountButtonsTooltipMonthly' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="inline-block w-5 h-5 ml-1 text-primary">
                                                                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                                                        </svg>
                                                                        <ReactTooltip id='amountButtonsTooltipMonthly' effect="solid" place='right'>
                                                                            <p className="text-sm text-white">These are the values for the</p>
                                                                            <p className="text-sm text-white">monthly amount buttons. </p>
                                                                        </ReactTooltip>
                                                                    </label>
                                                                </div>
                                                                {/* <div className="py-2 sm:col-span-2"></div>
                              <p className="text-sm text-gray-500 py-2">
                                Monthly
                              </p> 
                                                                <div className="sm:col-span-2 grid grid-cols-4 flex flex-wrap space-x-2">
                                                                    <input
                                                                        type="number"
                                                                        name="amount-1"
                                                                        id="amount-1"
                                                                        className="block placeholder-gray-400 col-start-1 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$10'
                                                                        value={monthlyAmount1}
                                                                        onChange={(e) => setMonthlyAmount1(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="amount-2"
                                                                        id="amount-2"
                                                                        className="block placeholder-gray-400 col-start-2 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$25'
                                                                        value={monthlyAmount2}
                                                                        onChange={(e) => setMonthlyAmount2(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="amount-3"
                                                                        id="amount-3"
                                                                        className="block placeholder-gray-400 col-start-3 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$50'
                                                                        value={monthlyAmount3}
                                                                        onChange={(e) => setMonthlyAmount3(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="amount-4"
                                                                        id="amount-4"
                                                                        className="block placeholder-gray-400 col-start-4 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$100'
                                                                        value={monthlyAmount4}
                                                                        onChange={(e) => setMonthlyAmount4(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {/* only annually checked 
                                                        {
                                                            checkedValues.includes('Annually') &&
                                                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                                <div>
                                                                    <label
                                                                        htmlFor="form-description"
                                                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                                                    >
                                                                        Annual buttons*
                                                                        <svg data-tip data-for='amountButtonsTooltipAnnually' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="inline-block w-5 h-5 ml-1 text-primary">
                                                                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                                                        </svg>
                                                                        <ReactTooltip id='amountButtonsTooltipAnnually' effect="solid" place='right'>
                                                                            <p className="text-sm text-white">These are the values for the</p>
                                                                            <p className="text-sm text-white">annually amount buttons. </p>
                                                                        </ReactTooltip>
                                                                    </label>
                                                                </div>
                                                                {/* <div className="py-2 sm:col-span-2"></div>
                              <p className="text-sm text-gray-500 py-2">
                                Monthly
                              </p> 
                                                                <div className="sm:col-span-2 grid grid-cols-4 flex flex-wrap space-x-2">
                                                                    <input
                                                                        type="number"
                                                                        name="amount-1"
                                                                        id="amount-1"
                                                                        className="block placeholder-gray-400 col-start-1 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$10'
                                                                        value={annuallyAmount1}
                                                                        onChange={(e) => setAnuallyAmount1(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="amount-2"
                                                                        id="amount-2"
                                                                        className="block placeholder-gray-400 col-start-2 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$25'
                                                                        value={annuallyAmount2}
                                                                        onChange={(e) => setAnuallyAmount2(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="amount-3"
                                                                        id="amount-3"
                                                                        className="block placeholder-gray-400 col-start-3 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$50'
                                                                        value={annuallyAmount3}
                                                                        onChange={(e) => setAnuallyAmount3(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                    <input
                                                                        type="number"
                                                                        name="amount-4"
                                                                        id="amount-4"
                                                                        className="block placeholder-gray-400 col-start-4 rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                                                        placeholder='$100'
                                                                        value={annuallyAmount4}
                                                                        onChange={(e) => setAnuallyAmount4(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }





                                                        {/* Additonal Info to Collect */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-2 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <h3 className="inline-block text-sm font-medium text-gray-900">
                                  Required Fields
                                </h3>
                                <svg
                                  data-tip
                                  data-for="additionalInfoTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="additionalInfoTooltip"
                                  effect="solid"
                                  place="right"
                                >
                                  <p className="text-sm text-white">
                                    Optionally, include any additional required
                                    fields:
                                  </p>
                                  <p className="text-sm text-white">
                                    Phone Number - The donor's phone number.
                                  </p>
                                  <p className="text-sm text-white">
                                    Billing Address - The donor's full billing
                                    address.
                                  </p>
                                  <p className="text-sm text-white">
                                    Country Dropdown - Allow the donor to select
                                    from countries
                                  </p>
                                  <p className="text-sm text-white">
                                    outside of the US. If unselected, US is
                                    assumed.
                                  </p>
                                </ReactTooltip>
                              </div>
                              <div className="sm:col-span-2 sm:col-start-2">
                                <div className="">
                                  <div className="block">
                                    <Toggle
                                      name={"Require Phone Number"}
                                      enabled={requirePhoneNumber}
                                      setEnabled={setRequirePhoneNumber}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="sm:col-span-2 sm:col-start-2">
                                <div className="">
                                  <div className="block">
                                    <Toggle
                                      name={"Require Billing Address"}
                                      enabled={requireBillingAddress}
                                      setEnabled={setRequireBillingAddress}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="sm:col-span-2 sm:col-start-2">
                                <div className="">
                                  <div className="block">
                                    <Toggle
                                      name={"Require Country Dropdown"}
                                      enabled={requireCountryDropdown}
                                      setEnabled={setRequireCountryDropdown}
                                    />
                                  </div>
                                </div>
                              </div>
                              {requireCountryDropdown && (
                                <div className="sm:col-span-2 sm:col-start-2">
                                  <div className="">
                                    <div className="mt-2 block grow">
                                      <Dropdown
                                        selectedValue={selectedCountry}
                                        setSelectedValue={setSelectedCountry}
                                        values={countries}
                                        width={"w-56"}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>

                            {/* Show show Anonymous Donations */}
                            {
                              <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-2 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <h3 className="inline-block text-sm font-medium text-gray-900">
                                    Addtional Details
                                  </h3>
                                  <svg
                                    data-tip
                                    data-for="doubleTheDonationTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="doubleTheDonationTooltip"
                                    effect="solid"
                                    place="right"
                                  >
                                    <p className="text-sm text-white">
                                      When enabling the following options, they
                                      will appear on the
                                    </p>
                                    <p className="text-sm text-white">
                                      "Additional Details" section of the
                                      checkout page.
                                    </p>
                                  </ReactTooltip>
                                </div>
                                {dtdPublicApiKey != null && (
                                  <div className="sm:col-span-2 sm:col-start-2">
                                    <div className="">
                                      <div className="block">
                                        <Toggle
                                          name={"Enable Double the Donation"}
                                          enabled={requireDoubleTheDonation}
                                          setEnabled={
                                            setRequireDoubleTheDonation
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {(checkedValues.includes("Daily") ||
                                  checkedValues.includes("Weekly") ||
                                  checkedValues.includes("Monthly") ||
                                  checkedValues.includes("Annually")) && (
                                  <div className="sm:col-span-2 sm:col-start-2">
                                    <div className="">
                                      <div className="block">
                                        <Toggle
                                          name={"Enable Scheduled Donations"}
                                          enabled={requireScheduleDonations}
                                          setEnabled={
                                            setRequireScheduleDonations
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="sm:col-span-2 sm:col-start-2">
                                  <div className="">
                                    <div className="block">
                                      <Toggle
                                        name={"Enable Anonymous Donations"}
                                        enabled={showAnonymousDonations}
                                        setEnabled={setShowAnonymousDonations}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }

                            {/* Funds */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <h3 className="inline-block text-sm font-medium text-gray-900">
                                  Add fund options*
                                </h3>
                                <svg
                                  data-tip
                                  data-for="fundOptionsTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="fundOptionsTooltip"
                                  effect="solid"
                                  place="right"
                                >
                                  <p className="text-sm text-white">
                                    Please select at least 1 fund. You may
                                    select
                                  </p>
                                  <p className="text-sm text-white">
                                    from the dropdown, or type in a new fund to
                                    add.
                                  </p>
                                  <p className="text-sm text-white">
                                    You can reorder funds by dragging the cards.
                                  </p>
                                </ReactTooltip>
                              </div>
                              <div className="sm:col-span-2">
                                <SingleSelectDropdown
                                  options={props.selectableFunds}
                                  setItems={setFunds}
                                  items={funds}
                                  placeholder="Select/Type a fund"
                                />
                              </div>
                            </div>

                            {/* Color */}
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="color"
                                  className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Color*
                                  <svg
                                    data-tip
                                    data-for="colorTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="colorTooltip"
                                    effect="solid"
                                    place="right"
                                    delayHide={500}
                                    className="react-tooltip-clickable-link"
                                  >
                                    <p className="text-sm text-white">
                                      Provide a six character Hex color preceded
                                      by a '#'.
                                    </p>
                                    <p className="text-sm text-white">
                                      For help getting this value, visit the
                                      link below.
                                    </p>
                                    <a
                                      target="_blank"
                                      className="focus:outline-none text-sm text-white underline"
                                      href="https://htmlcolorcodes.com/color-picker/"
                                    >
                                      https://htmlcolorcodes.com/color-picker/
                                    </a>
                                  </ReactTooltip>
                                </label>
                              </div>
                              <div className="inline-flex">
                                <input
                                  value={color}
                                  placeholder="#000000"
                                  onChange={(e) => {
                                    let color = e.target.value;
                                    color = color.toUpperCase();
                                    color = color.replace(/^([^#])/g, "#$1");
                                    color = color.replace(/[^0-9a-fA-F#]/g, "");
                                    if (color.length > 7) {
                                      color = color.substring(0, 7);
                                    }
                                    setColor(color);
                                  }}
                                  type="text"
                                  name="color"
                                  id="color"
                                  className="focus:border-secondary focus:ring-secondary flex w-32 rounded-md border-gray-300 shadow-sm sm:text-sm"
                                />
                              </div>
                            </div>

                            {/* Multi-Merchant Select 
                                                        {
                                                            merchants.length > 1 &&
                                                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                                <div>
                                                                    <h3 className="inline-block text-sm font-medium text-gray-900">Selected Nonprofit</h3>
                                                                    <svg data-tip data-for='merchantsTooltip' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="inline-block w-5 h-5 ml-1 text-primary">
                                                                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                                                    </svg>
                                                                    <ReactTooltip id='merchantsTooltip' effect="solid" place='right'>
                                                                        <p className="text-sm text-white">Select the nonprofit you want this form to be</p>
                                                                        <p className="text-sm text-white">associated with.</p>
                                                                    </ReactTooltip>
                                                                </div>
                                                                <div>
                                                                    <div name="merchant" id="merchant">
                                                                        <Dropdown selectedValue={merchantName} setSelectedValue={setMerchantName} values={merchants} width={'w-64'} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }



                                                        {/* Embed Link */}
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="project-description"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Embed link
                                  <svg
                                    data-tip
                                    data-for="embedTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="embedTooltip"
                                    effect="solid"
                                    place="right"
                                  >
                                    <p className="text-sm text-white">
                                      Copy and paste the code on the right into
                                      your
                                    </p>
                                    <p className="text-sm text-white">
                                      website after clicking "Create". If
                                      nothing appears,
                                    </p>
                                    <p className="text-sm text-white">
                                      you may have forgotten to embed the
                                      CharityStack script
                                    </p>
                                    <p className="text-sm text-white">
                                      file. You can find this on the Settings
                                      page.
                                    </p>
                                  </ReactTooltip>
                                </label>

                                <p className="text-sm text-gray-500">
                                  Embed this code on your website to collect
                                  donations.
                                </p>
                                <br />

                                <a
                                  className="text-primary hover:text-secondary group flex cursor-pointer items-center space-x-2.5 text-sm font-medium"
                                  data-tip
                                  data-for="copyIframe"
                                  data-event="click"
                                >
                                  {/* <LinkIcon
                                  className="h-5 w-5 text-primary group-hover:text-secondary"
                                  aria-hidden="true"
                                />
                                <span>Copy code</span> */}
                                </a>

                                <ReactTooltip
                                  id="copyIframe"
                                  clickable={true}
                                  effect="solid"
                                  place="bottom"
                                  afterShow={async () => {
                                    try {
                                      await navigator.clipboard.writeText(
                                        elementID !== ""
                                          ? `<div data-entity="QUICK_DONATE" data-source="CharityStack" id="${elementID}"></div>`
                                          : ""
                                      );
                                      setCopiedState("Copied!");
                                    } catch (err) {
                                      console.error("Failed to copy: ", err);
                                      setCopiedState("Failed to copy");
                                    } finally {
                                      setTimeout(ReactTooltip.hide, 1000);
                                    }
                                  }}
                                  delayHide={500}
                                  delayUpdate={500}
                                >
                                  <span className="text-sm text-white">
                                    {copiedState}
                                  </span>
                                </ReactTooltip>
                              </div>
                              <div className="sm:col-span-2">
                                <textarea
                                  value={
                                    elementID !== ""
                                      ? `<div data-entity="QUICK_DONATE" data-source="CharityStack" id="${elementID}"></div>`
                                      : ""
                                  }
                                  id="project-description"
                                  name="project-description"
                                  rows={5}
                                  className={
                                    "focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm " +
                                    (elementID !== ""
                                      ? "bg-white"
                                      : "bg-gray-100")
                                  }
                                  defaultValue={""}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex h-12 items-center justify-between space-x-3">
                          {showSuccessAlert && (
                            <SuccessAlert
                              message={successMessage}
                              setShowSuccessAlert={setShowSuccessAlert}
                            />
                          )}
                          {showErrorAlert && (
                            <ErrorAlert
                              message={errorMessage}
                              setShowErrorAlert={setShowErrorAlert}
                            />
                          )}
                          {!showSuccessAlert && !showErrorAlert && <div />}

                          <button
                            type="button"
                            className={submitButtonClass}
                            onClick={sendToServer}
                          >
                            {createdForm
                              ? isDisabled
                                ? "Saving"
                                : "Save"
                              : isDisabled
                              ? "Creating"
                              : "Create"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
