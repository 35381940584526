import React, { Component } from "react";
import { StrictMode } from "react/cjs/react.production.min";
import PaginationClassic from "../PaginationClassic";
import moment from "moment";
import { Auth } from "aws-amplify";
import mainLogo from "./logo.png";
import DonationDatePicker from "./DonationDatePicker";
import { CSVLink } from "react-csv";
import {
  formatTimestamp,
  formatTimestampToDate,
} from "../../utils/formatTimestamp";

export class PayoutsTable extends Component {
  constructor(props) {
    super(props);

    const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
    let today = new Date();
    // increment today forward by 1 day
    today.setDate(today.getDate() + 1);
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() - 6000);
    const startTimerange =
      tomorrow.toLocaleDateString("en-US", DATE_OPTIONS) +
      " - " +
      today.toLocaleDateString("en-US", DATE_OPTIONS);

    this.state = {
      jwtToken: "",
      donorsList: [],
      start: 0,
      end: 0,
      total: 0,
      grossAmountVariable: 0,
      totalDonationSum: 0,
      totalDonations: 0,
      totalDonorValue: 0,
      fiscalSponsor: false,
      timerange: startTimerange,
      showFilter: false,
    };
  }

  onFilterClickHandler = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  async componentDidMount() {
    const data = await Auth.currentSession();
    let user = await Auth.currentAuthenticatedUser();
    this.setState(
      {
        jwtToken: data["idToken"]["jwtToken"],
        whoIsLoggedIn: user.username,
      },
      () => this.updateData()
    );
  }

  convertTimestamp(timestamp) {
    // Attempt to handle ISO 8601 and other formats by first trying to parse the timestamp directly.
    const date = new Date(timestamp);
    // Check if the date is valid.
    if (!isNaN(date.getTime())) {
      // Construct a string in the desired format (MM-DD-YYYY).
      let month = "" + (date.getMonth() + 1), // getMonth() is zero-based.
        day = "" + date.getDate(),
        year = date.getFullYear();

      // Ensure month and day are 2 digits.
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [month, day, year].join("-");
    } else {
      // Fallback for non-standard formats not recognized by the Date constructor.
      // Attempt to manually parse assuming a structure and then rearrange.
      const sections = timestamp.split("-");
      if (sections.length >= 3) {
        return sections[1] + "-" + sections[2] + "-" + sections[0];
      } else {
        // Return original timestamp or a placeholder if it cannot be parsed.
        return timestamp; // Or return something like 'Invalid Date'.
      }
    }
  }

  setDateTimeRange = (timerange) => {
    this.setState({ timerange: timerange }, () => this.updateData());
  };

  getNextBusinessDay(dateString) {
    const date = new Date(dateString);
    let nextDay = new Date(date.getTime() + 1000 * 60 * 60 * 24); // Add 1 day

    // Check if the next day is a weekend (Saturday or Sunday)
    while (nextDay.getDay() === 6 || nextDay.getDay() === 0) {
      nextDay = new Date(nextDay.getTime() + 1000 * 60 * 60 * 24); // Add 1 more day
    }

    // Format the next business day as "YYYY-MM-DD"
    return nextDay.toISOString().slice(0, 10);
  }

  async updateData() {
    const requestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: this.state.jwtToken,
      },
      body: JSON.stringify({
        timerange: this.props.timerange,
        merchantID: global.merchantID,
      }),
    };

    const stripeRequestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: this.state.jwtToken,
      },
    };
    // REACT_APP_STRIPE_PAYOUTS_URL
    const stripePayoutsURL =
      process.env.REACT_APP_STRIPE_PAYOUTS_URL + "/payouts";
    const url = process.env.REACT_APP_SETTLEMENTS_URL;
    let response = await fetch(url, requestOptions);

    // change method to GET
    requestOptions.method = "GET";
    let stripeResponse = await fetch(stripePayoutsURL, stripeRequestOptions);

    let resStripe = await stripeResponse.json();
    console.log("res_stripe", resStripe);
    const res = await response.json();
    // add resStripe to res
    res.push(...resStripe);
    let end = 20;
    if (res.length < 20) {
      end = res.length;
    }

    //Table rows with calculated gross donations from each unique email
    let rows = [];

    for (let i = 0; i < res.length; i++) {
      if (
        res[i].status == null &&
        res[i].payoutAmount == null &&
        res[i].merchantIdentity == null
      ) {
        this.setState({ fiscalSponsor: true });
        i = res.length;
        return;
      }
    }

    if (this.state.fiscalSponsor == false) {
      res.map((payouts) => {
        let processorValue = "CharityStack";
        // if (payouts.paymentProcessor !== undefined) {
        //   processorValue = payouts.paymentProcessor;
        //   if (processorValue === "STRIPE") {
        //     processorValue = "Stripe";
        //   }
        // }

        if (payouts.paymentProcessor === "STRIPE") {
          const amount = (parseFloat(payouts.amount) / 100).toFixed(2);

          // convert epoch timestamp to MM-DD-YYYY
          const unformattedCreatedTimestamp =
            payouts.createdDateReadable.split(" ")[0];
          const createdTimestamp = this.convertTimestamp(
            unformattedCreatedTimestamp
          );
          const unformattedArrivalTimestamp =
            payouts.arrivalDateReadable.split(" ")[0];
          const arrivalTimestamp = this.convertTimestamp(
            unformattedArrivalTimestamp
          );

          if (payouts.status == "PENDING" || payouts.status == "IN_TRANSIT") {
            payouts.status = "Pending";
          }
          if (payouts.status == "PAID") {
            if (parseFloat(payouts.amount) > 0) {
              payouts.status = "Paid";
            } else {
              payouts.status = "Withdrawn";
            }
          }
          if (payouts.status == "FAILED" || payouts.status == "CANCELED") {
            payouts.status = "Failed";
            arrivalTimestamp = "N/A";
          }
          rows.push({
            created: createdTimestamp,
            arrivalDate: arrivalTimestamp,
            grossAmount: amount,
            status: payouts.status,
            payoutID: payouts.charityStackID,
            processor: processorValue,
          });
        }

        if (
          payouts.status === "TRANSFER_SUCCEEDED" ||
          payouts.status === "TRANSFER_PENDING" ||
          payouts.status === "TRANSFER_FAILED"
        ) {
          const amount = (parseFloat(payouts.payoutAmount) / 100).toFixed(2);
          const unformattedCreatedTimestamp = payouts.created.split(" ")[0];
          const createdTimestamp = this.convertTimestamp(
            unformattedCreatedTimestamp
          );
          const unformattedArrivalTimestamp = this.getNextBusinessDay(
            unformattedCreatedTimestamp
          );
          const arrivalTimestamp = this.convertTimestamp(
            unformattedArrivalTimestamp
          );

          if (payouts.status == "SETTLEMENT_APPROVED") {
            payouts.status = "Pending";
          }
          if (payouts.status == "TRANSFER_SUCCEEDED") {
            if (parseFloat(payouts.payoutAmount) > 0) {
              payouts.status = "Paid";
            } else {
              payouts.status = "Withdrawn";
            }
          }

          rows.push({
            created: createdTimestamp,
            arrivalDate: arrivalTimestamp,
            grossAmount: amount,
            status: payouts.status,
            payoutID: payouts.settlementID,
            processor: processorValue,
          });
          console.log(rows);
        }
      });

      // sort rows by created date
      rows.sort((a, b) => {
        return new Date(b.created) - new Date(a.created);
      });
      console.log("payment rows", rows);
      if (rows.length < 20) {
        end = rows.length;
      }
      this.setState({
        donorsList: rows,
        start: 0,
        end: end,
        total: rows.length,
      });
    }
  }

  goNext = (e) => {
    e.preventDefault();
    const { start, end, total } = this.state;
    if (end !== total) {
      this.setState({
        start: start + 20,
        end: Math.min(end + 20, total),
      });
    }
  };

  goPrev = (e) => {
    e.preventDefault();
    const { start, end, total } = this.state;
    if (start !== 0) {
      this.setState({
        start: Math.max(start - 20, 0),
        end: start,
      });
    }
  };

  headers1 = [
    { label: "Created Date", key: "created" },
    { label: "Short Created Date", key: "createdShort" },
    { label: "Payout ID", key: "payoutID" },
    { label: "Total Payout", key: "grossAmount" },
    { label: "State", key: "status" },
    { label: "Processor", key: "processor" },
    { label: "Arrival Date", key: "arrivalDate" },
    { label: "Short Arrival Date", key: "arrivalDateShort" },
  ];

  render() {
    const { donorsList, showFilter, start, end, total, fiscalSponsor } =
      this.state;
    const headers1 = this.headers1;
    let payoutsList1 = JSON.parse(JSON.stringify(donorsList));

    // format all items in payoutsList1 with grossAmount < 0 to have the minus sign replaced with parantheses
    for (let i = 0; i < payoutsList1.length; i++) {
      if (payoutsList1[i].grossAmount < 0) {
        console.log(payoutsList1[i].grossAmount);
        console.log(typeof payoutsList1[i].grossAmount);
        payoutsList1[i].grossAmount = payoutsList1[i].grossAmount.replace(
          "-",
          ""
        );
        payoutsList1[i].grossAmount = "(" + payoutsList1[i].grossAmount + ")";
      }
    }
    console.log("payoutsList1", payoutsList1);

    let next = "btn bg-white border-gray-200 hover:border-gray-300 text-light";
    let prev = "btn bg-white border-gray-200 hover:border-gray-300 text-light";
    if (end === total) {
      next = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed";
    }
    if (start === 0) {
      prev = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed";
    }

    if (fiscalSponsor == true) {
      return (
        <div className="col-span-full rounded-lg border border-gray-200 bg-white shadow-lg xl:col-span-12">
          <div className="lg:text-center">
            <img class="mx-auto h-60" src={mainLogo} alt=".NGO" />

            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              This feature is not available for Fiscal Sponsors.
            </p>
          </div>

          <div className="mt-10"></div>
        </div>
      );
    }
    if (fiscalSponsor == false) {
      return (
        <div className="col-span-full xl:col-span-12">
          <div className="flex flex-row justify-end py-3 ">
            <div className="flex ">
              <button
                type="button "
                className="focus:outline-none inline-block flex focus:ring-2 focus:ring-offset-2 "
              >
                <CSVLink
                  filename="CharityStack Payouts Export.csv"
                  data={payoutsList1.map((payout) => {
                    try {
                      formatTimestamp(payout.created);
                    } catch (e) {
                      console.log(e);
                      console.log("payout", payout);
                    }
                    return {
                      ...payout,
                      created: formatTimestamp(payout.created),
                      createdShort: formatTimestampToDate(payout.created),
                      arrivalDate:
                        payout.arrivalDate && payout.arrivalDate != "N/A"
                          ? formatTimestamp(payout.arrivalDate)
                          : "",
                      arrivalDateShort:
                        payout.arrivalDate && payout.arrivalDate != "N/A"
                          ? formatTimestampToDate(payout.arrivalDate)
                          : "",
                    };
                  })}
                  headers={headers1}
                >
                  <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 17"
                      stroke="#a1afc2"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                      />
                    </svg>
                    &nbsp;
                    <div className="text-gray-500 hover:text-gray-900 ">
                      {" "}
                      Export &nbsp;{" "}
                    </div>
                  </div>
                </CSVLink>
              </button>
            </div>
          </div>
          {showFilter && (
            <div className="flex flex-row p-3">
              <DonationDatePicker setTimerange={this.setDateTimeRange} />
            </div>
          )}
          <div className="rounded-lg border border-[#D1D9E0] bg-white p-3">
            {/* Table */}
            <div className="overflow-x-auto">
              <table className="w-full table-auto ">
                {/* Table header */}
                <thead className="rounded-sm bg-gray-50 text-xs uppercase text-gray-400">
                  <tr>
                    <th className="p-2">
                      <div className="text-left font-semibold">
                        Created Date
                      </div>
                    </th>
                    <th className="p-2">
                      <div className="text-center font-semibold">Amount</div>
                    </th>
                    <th className="p-2">
                      <div className="text-center font-semibold">State</div>
                    </th>
                    <th className="p-2">
                      <div className="text-center font-semibold">
                        Arrival Date
                      </div>
                    </th>
                    <th className="p-2">
                      <div className="text-center font-semibold">Processor</div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="divide-y divide-gray-100 text-sm font-medium">
                  {donorsList.slice(start, end).map((payouts, index) => {
                    const amount = parseFloat(
                      payouts.grossAmount.replace(/,/g, "")
                    ); // Remove all commas from the string
                    const formattedAmount = amount
                      .toFixed(2)
                      .toLocaleString("en-US"); // Convert to 2 decimal places and add commas back

                    payouts.grossAmount = formattedAmount; // Update payouts.grossAmount with the formatted value

                    let stateColor1 =
                      "px-3 text-center text-green-800 bg-green-100 rounded-full";
                    if (payouts.status == "TRANSFER_SUCCEEDED") {
                      payouts.status = "Paid";
                    }
                    if (payouts.status == "Pending") {
                      stateColor1 = stateColor1 =
                        "px-3 text-center text-blue-800 bg-blue-100 rounded-full";
                    }
                    if (payouts.status == "TRANSFER_PENDING") {
                      stateColor1 =
                        "px-3 text-center text-blue-800 bg-blue-100 rounded-full";
                      payouts.status = "Pending";
                    }
                    if (
                      payouts.status == "TRANSFER_FAILED" ||
                      payouts.status == "SETTLEMENT_FAILED" ||
                      payouts.status == "Failed"
                    ) {
                      stateColor1 =
                        "px-3 text-center text-red-800 bg-red-200 rounded-full";
                      payouts.status = "Failed";
                    }
                    if (payouts.status == "Withdrawn") {
                      stateColor1 =
                        "px-3 text-center text-gray-800 bg-gray-200 rounded-full";
                    }
                    return (
                      <tr className="text-gray-800">
                        <td className="p-2">
                          <div className="items-center">
                            <div className="text-s text-left">
                              {payouts.created}
                            </div>
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="items-center text-center">
                            <div className="text-center">
                              {/* if status is withdrawn, put -${payouts.grossAmount}
                              else put ${payouts.grossAmount} */}
                              {payouts.grossAmount.startsWith("-") ? (
                                <>
                                  ($
                                  {Number(
                                    payouts.grossAmount.replace(/-/g, "")
                                  ).toFixed(2)}
                                  )
                                </>
                              ) : (
                                <>${Number(payouts.grossAmount).toFixed(2)}</>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="items-center text-center">
                            <button className={stateColor1}>
                              {payouts.status}
                            </button>
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="items-center">
                            <div className="text-s text-center">
                              {payouts.arrivalDate ?? ""}
                            </div>
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="items-center text-center">
                            {payouts.processor}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="mt-8">
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                  <nav
                    className="mb-4 sm:order-1"
                    role="navigation"
                    aria-label="Navigation"
                  >
                    <ul className="flex justify-center">
                      <li className="ml-3 first:ml-0">
                        <a className={prev} href="#0" onClick={this.goPrev}>
                          &lt;- Previous
                        </a>
                      </li>
                      <li className="ml-3 first:ml-0">
                        <a className={next} href="#0" onClick={this.goNext}>
                          Next -&gt;
                        </a>
                      </li>
                    </ul>
                  </nav>
                  <div className="text-center text-sm text-gray-500 sm:text-left">
                    Showing{" "}
                    <span className="font-medium text-gray-600">{start}</span>{" "}
                    to <span className="font-medium text-gray-600">{end}</span>{" "}
                    of{" "}
                    <span className="font-medium text-gray-600">{total}</span>{" "}
                    results
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PayoutsTable;
