import React, { useEffect, useState } from "react";
import Organization from "./Organization";
import Profile from "./Profile";
import ThemeSelection from "./ThemeSelection";


function Account(props) {
  const [tab, setTab] = useState("ORGANIZATION");

  useEffect(() => {
    console.log("tab", tab);
    // setEditMode(false);
  }, [tab]);

  return (
    <div
      className="flex grid grid-cols-7 rounded-md border-2 border-gray-300"
      style={{ minHeight: "calc(100vh - 300px)" }}
    >
      <div className="col-span-2 row-span-full border-r-2 border-gray-200">
        <div
          onClick={() => setTab("ORGANIZATION")}
          className="cursor-pointer border-b-2 border-gray-200 p-4 hover:bg-gray-200"
        >
          <div className="text-base font-medium text-black">Organization</div>
          <div className="text-sm leading-4 text-gray-400">
            Information about your nonprofit organizational information and
            branding
          </div>
        </div>
        <div
          onClick={() => setTab("PROFILE")}
          className="cursor-pointer border-b-2 border-gray-200 p-4 hover:bg-gray-200"
        >
          <div className="text-base font-medium text-black">Profile</div>
          <div className="text-sm leading-4 text-gray-400">
            Information about your account information and access
          </div>
        </div>
        {/* <div
          onClick={() => setTab("THEME")}
          className="cursor-pointer border-b-2 border-gray-200 p-4 hover:bg-gray-200"
        >
          <div className="text-base font-medium text-black">Theme</div>
          <div className="text-sm leading-4 text-gray-400">
            Select theme of system
          </div>
        </div> */}
      </div>
      <div className="relative col-span-5 p-4">
        {tab === "ORGANIZATION" && (
          <Organization
            merchant={props.merchant}
            grabBaseMerchant={props.grabBaseMerchant}
          />
        )}
        {tab === "PROFILE" && <Profile />}
        {tab === "THEME" && <ThemeSelection />}
      </div>
    </div>
  );
}

export default Account;
