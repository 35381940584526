import {
  ConnectPayments,
  ConnectNotificationBanner,
} from "@stripe/react-connect-js";
import { Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";

function WelcomeBanner(props) {
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => setUser(user))
      .catch((err) => console.log(err));
  }, []);
  const [user, setUser] = useState("");

  return (
    <div className="relative mb-2 overflow-hidden  rounded-lg border border-gray-200 bg-white p-2 shadow-lg sm:p-3">
      <div className="relative px-3">
        <div className="divide-y divide-gray-200 ">
          <h1 className="mb-1 text-xl font-bold text-gray-800">
            Hey there! 👋
          </h1>

          <p className="mb-1 py-1 text-sm font-semibold text-gray-800">
            Here's what's going on with your nonprofit today:
          </p>
        </div>
        {props.merchant?.defaultPaymentProcessor === "STRIPE" && (
          <ConnectNotificationBanner />
        )}
      </div>
    </div>
  );
}

export default WelcomeBanner;
