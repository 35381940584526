import React, { useEffect, useState } from "react";
import { paragon } from "@useparagon/connect";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import SalesforceIcon from "./SVGs/SalesforceIcon"; // Adjust the path according to your project structure

function ParagonSalesforce({ paragonUser }) {
  const [isConnecting, setIsConnecting] = useState(false);

  useEffect(() => {
    if (paragonUser && isConnecting) {
      paragon.connect("salesforce");
      setIsConnecting(false);
    }
  }, [paragonUser, isConnecting]);

  const initiateSalesforce = async () => {
    if (paragonUser) {
      paragon.connect("salesforce");
    } else {
      setIsConnecting(true);
    }
  };

  return (
    <div className="grid h-full grid-rows-3 items-center">
      <div className="row-span-1 justify-self-center">
        <SalesforceIcon width="75px" />
      </div>
      <div className="row-span-1 justify-self-center">Salesforce</div>
      {isConnecting ? (
        <button
          type="button"
          className="row-span-1 w-24 justify-self-center rounded-lg bg-green-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-600 focus:ring-4 focus:ring-green-300"
          disabled={true}
        >
          <div className="flex items-center justify-center space-x-2">
            <svg
              className="h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </button>
      ) : (
        <button
          type="button"
          className="row-span-1 w-24 justify-self-center rounded-lg bg-green-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-600 focus:ring-4 focus:ring-green-300"
          onClick={initiateSalesforce}
        >
          Connect
        </button>
      )}
    </div>
  );
}

export default ParagonSalesforce;
