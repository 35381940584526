import SearchModal from "./header/SearchModal";
import Notifications from "./header/Notifications";
import Help from "./header/Help";
import UserMenu from "./header/UserMenu";
import { Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import mainLogo from "./header/logo.png";
import {
  AmplifySignOut,
  withAuthenticator,
  Authenticator,
  SignOut,
} from "@aws-amplify/ui-react";
import CreateDropdown from "./dropdowns/CreateDropdown";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const refreshPage = async () => {
  try {
    await Auth.signOut();
    window.location.reload();
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

function Header({
  sidebarOpen,
  setSidebarOpen,
  selectableFunds,
  merchant,
  grabSelectableFunds,
  selectableForms,
  groups,
}) {
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => setUser(user))
      .catch((err) => console.log(err));
  }, []);
  const [user, setUser] = useState("");

  return (
    <header className="sticky top-0 z-10 bg-transparent ">
      <div className="sm:px-2.25 px-1.5 lg:px-3">
        <div className="-mb-px flex h-16 items-center justify-between">
          {/* Header: Left side */}
          <div className="flex">
            {/* Hamburger button */}
            <button
              className="text-gray-500 hover:text-gray-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="h-6 w-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className="-mt-8">
        <div className="float-right flex">
          <CreateDropdown
            selectableFunds={selectableFunds}
            merchant={merchant}
            grabSelectableFunds={grabSelectableFunds}
            selectableForms={selectableForms}
            groups={groups}
          />
        </div>
      </div>

      {/* <div className="col-start-11 row-start-2 ml-28">
            <QuestionMarkCircleIcon
            className="h-9 w-9 text-gray-400 hover:text-gray-500 font-bold"
            aria-hidden="true"
            />
          </div> */}
    </header>
  );
}

export default Header;
