import React, { useRef, useState, useEffect } from 'react';
import { focusHandling } from 'cruip-js-toolkit';

import {
  Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';

// Import utilities
import { tailwindConfig, formatValue } from '../utils/Utils';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

function LineChart02(props) {

  // let [chartData, setChartData] = useState({});
  const canvas = useRef(null);
  const legend = useRef(null);

  useEffect(() => {
    // setChartData(props.data);
    const ctx = canvas.current;
    // eslint-disable-next-line no-unused-vars
    const chart = new Chart(ctx, {
      type: 'line',
      data: props.data,
      options: {
        elements: {
            line: {
              //controls line curve
              lineTension: 0.3
            }
        },
        layout: {
          padding: 20,
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              beginAtZero: true,
            },
            ticks: {
              // maxTicksLimit: 5,
              beginAtZero : true,
              callback: (value) => formatValue(value),
            },
          },
          x: {
            type: 'time',
            time: {
              parser: 'MM-DD-YYYY',
              unit: 'day',
              displayFormats: {
                month: 'MMM DD',
              },
            },
            // grid: {
            //   display: false,
            //   drawBorder: false,
            // },
            ticks: {
              autoSkipPadding: 48,
              maxRotation: 0,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: () => false, // Disable tooltip title
              label: (context) => formatValue(context.parsed.y),
            },
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        maintainAspectRatio: false,
      },
      plugins: [{
        id: 'htmlLegend',
        afterUpdate(c, args, options) {
          const ul = legend.current;
          if (!ul) return;
          // Remove old legend items
          while (ul.firstChild) {
            ul.firstChild.remove();
          }
          // Reuse the built-in legendItems generator
          const items = c.options.plugins.legend.labels.generateLabels(c);
          items.slice(0, 2).forEach((item) => {
            const li = document.createElement('li');
            li.style.marginLeft = tailwindConfig().theme.margin[3];
            // Button element
            const button = document.createElement('button');
            button.style.display = 'inline-flex';
            button.style.alignItems = 'center';
            button.style.opacity = item.hidden ? '.3' : '';
            button.onclick = () => {
              c.setDatasetVisibility(item.datasetIndex, !c.isDatasetVisible(item.datasetIndex));
              c.update();
              focusHandling('outline');
            };
            // Color box
            const box = document.createElement('span');
            box.style.display = 'block';
            box.style.width = tailwindConfig().theme.width[3];
            box.style.height = tailwindConfig().theme.height[3];
            box.style.borderRadius = tailwindConfig().theme.borderRadius.full;
            box.style.marginRight = tailwindConfig().theme.margin[2];
            box.style.borderWidth = '3px';
            box.style.borderColor = c.data.datasets[item.datasetIndex].borderColor;
            box.style.pointerEvents = 'none';
            // Label
            const label = document.createElement('span');
            label.style.color = tailwindConfig().theme.colors.gray[500];
            label.style.fontSize = tailwindConfig().theme.fontSize.sm[0];
            label.style.lineHeight = tailwindConfig().theme.fontSize.sm[1].lineHeight;
            const labelText = document.createTextNode(item.text);
            label.appendChild(labelText);
            li.appendChild(button);
            button.appendChild(box);
            button.appendChild(label);
            ul.appendChild(li);
          });
        },
      }],
    });
    return () => {
      chart.destroy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  return (
    <React.Fragment>
      
        <div className="px-5 py-3">

          <div className="grid grid-cols-4 gap-12 text-center">
            <div class="block p-2 bg-white hover:bg-gray-100 shadow-md border border-gray-200 rounded-lg max-w-sm dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div class="text-gray-900 font-bold text-xl tracking-tight mb-2 dark:text-white">${props.total.toFixed(2)}</div>
              <p class="font-normal text-gray-700 text-sm dark:text-gray-400">Total Donations</p>
            </div>
            <div class="block p-2 bg-white hover:bg-gray-100 shadow-md border border-gray-200 rounded-lg max-w-sm dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div class="text-gray-900 font-bold text-xl tracking-tight mb-2 dark:text-white">${(+props.total / +props.numOfDonations).toFixed(2)}</div>
              <p class="font-normal text-gray-700 text-sm dark:text-gray-400">Avg Donations</p>
            </div>
            <div class="block p-2 bg-white hover:bg-gray-100 shadow-md border border-gray-200 rounded-lg max-w-sm dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div class="text-gray-900 font-bold text-xl tracking-tight mb-2 dark:text-white">{(props.numOfDonations).toLocaleString(undefined)}</div>
              <p class="font-normal text-gray-700 text-sm ark:text-gray-400">Total Donors</p>
            </div>
            <div class="block p-2 bg-white hover:bg-gray-100 shadow-md border border-gray-200 rounded-lg max-w-sm dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div class="text-gray-900 font-bold text-xl tracking-tight mb-2 dark:text-white">{(props.numOfUniqueDonors).toLocaleString(undefined)}</div>
              <p class="font-normal text-gray-700 text-sm dark:text-gray-400">Unique Donors</p>
            </div>

          </div>
      </div> 
      {/* Chart built with Chart.js 3 */}
      <div className="col-span-full flex-grow xl:row-span-6">
        <canvas ref={canvas} height={props.height}></canvas>
      </div>
    </React.Fragment>
  );
}

export default LineChart02;