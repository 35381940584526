import React, { Component, useRef } from "react";
import LineChart from "../../charts/LineChart02Home";
import decompressData from "../../utils/decompressData";
import pako from "pako";

// Import utilities
import { tailwindConfig } from "../../utils/Utils";
import { Auth } from "aws-amplify";

import resolveConfig from "tailwindcss/resolveConfig";
import Button from "@material-tailwind/react/Button";
import Tooltips from "@material-tailwind/react/Tooltips";
import TooltipsContent from "@material-tailwind/react/TooltipsContent";
import { queryTransactionsChart } from "../../utils/RocksetQueries/TransactionQueries";

const { theme } = resolveConfig(tailwindConfig);

export class DonationsChartHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jwtToken: "",
      total: 0,
      timerange: props.timerange,
      numOfUniqueDonors: 0,
      numOfDonations: 0,
      isLoading: true, // State to track if data is loading
      chartData: {
        labels: [],
        datasets: [
          // Indigo line
          {
            label: "Current",
            data: [],
            borderColor: tailwindConfig().theme.colors.red[500],
            fill: false,
            borderWidth: 2,
            tension: 0,
            pointRadius: 0,
            pointHoverRadius: 3,
            pointBackgroundColor: tailwindConfig().theme.colors.red[500],
          },
        ],
      },
    };
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.value !== props.value) {
      this.updateData();
    }
    return null;
  }

  componentDidUpdate() {
    if (this.state.timerange !== this.props.timerange) {
      // console.log('updated data')
      this.updateData();
    }
  }

  async componentDidMount() {
    const data = await Auth.currentSession();
    this.setState(
      {
        jwtToken: data["idToken"]["jwtToken"],
      },
      () => this.updateData()
    );
  }

  convertTimestamp(timestamp) {
    const sections = timestamp.split("-");
    return sections[1] + "-" + sections[2] + "-" + sections[0];
  }

  async updateData() {
    this.setState({ isLoading: true }); // Start loading
    const authData = await Auth.currentSession();
    // grab groups from cognito
    const groups = authData["idToken"]["payload"]["cognito:groups"];
    // replace the - with a space for every value in the array
    const groupsWithSpaces = groups.map((group) => group.replace(/-/g, " "));
    console.log("chart timerange", this.props.timerange);
    const res = await queryTransactionsChart(groupsWithSpaces);

    let timestamps = [];
    let data = [];
    let uniqueDonors = {};
    let totalDonations = 0;
    let numOfDonations = 0;

    console.log("res chart", res);
    res.map((row) => {
      if (row.state === "SUCCEEDED") {
        numOfDonations++;
        const unformattedTimestamp = row.timestamp.split(" ")[0];
        const timestamp = this.convertTimestamp(unformattedTimestamp);
        const timestampIndex = timestamps.indexOf(timestamp);

        let totalAmount = row.grossAmount.toFixed(2);

        totalDonations += Number(totalAmount);

        if (!uniqueDonors.hasOwnProperty(row.email)) {
          uniqueDonors[row.email.toLowerCase()] = "exists";
        }

        if (timestampIndex == -1) {
          timestamps.push(timestamp);
          data.push(Number(totalAmount));
        } else {
          data[timestampIndex] += Number(totalAmount);
        }
      }
    });
    // console.log('Unique donations ' + numOfDonations)
    // console.log(timestamps)
    // console.log(data)
    this.setState(
      {
        total: totalDonations,
        timerange: this.props.timerange,
        numOfUniqueDonors: Object.keys(uniqueDonors).length,
        numOfDonations: numOfDonations,
        chartData: {
          labels: timestamps.reverse(),
          datasets: [
            // Indigo line
            {
              label: "Current",
              data: data.reverse(),
              borderColor: "#69995E",
              fill: false,
              borderWidth: 2,
              tension: 0,
              pointRadius: 0,
              pointHoverRadius: 3,
              pointBackgroundColor: "#324234",
            },
          ],
        },
        isLoading: false, // End loading
      },
      () => {
        // Call the readiness callback here, after state is fully updated
        console.log("IM THINKING IM RTEADY");

        if (this.props.onDataReady) {
          console.log("IM SETTING RTEADY");
          this.props.onDataReady();
        }
      }
    );
  }

  render() {
    const { chartData, total, numOfUniqueDonors, numOfDonations } = this.state;
    return (
      <div className="col-span-full rounded-lg border border-gray-200 bg-white shadow-lg xl:col-span-12">
        {/* Chart built with Chart.js 3 */}
        {/* Change the height attribute to adjust the chart height */}
        <LineChart
          data={chartData}
          total={(Math.round(total * Math.pow(10, 2)) / Math.pow(10, 2))
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          numOfUniqueDonors={numOfUniqueDonors}
          numOfDonations={numOfDonations}
          height={300}
        />
        <div></div>
      </div>
    );
  }
}

export default DonationsChartHome;
