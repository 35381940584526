import React, { Component, useEffect } from 'react';

import Createable from "react-select/creatable";
import { components } from 'react-select';
import { SortableContainer, SortableElement } from "react-sortable-hoc";

const customStyles = {
  control: (provided, state) => ({
     ...provided,
     border: state.isFocused ? "1px solid #324234" : "1px solid #cccccc",
     boxShadow: "none",
     "&:hover": {
       border: "1px solid #324234",
       boxShadow: "none"
     },
     cursor: 'default'
   })
 };

 function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const SortableMultiValue = SortableElement((props) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableSelect = SortableContainer(Createable);

export default function MultiSelectDropdown(props) {

  const handleChange = (newValue, actionMeta) => {
    // console.group('Value Changed');
    // console.log(newValue);

    // this if statement is used for lists of multi-select dropdowns, where we need to identify which dropdown was changed
    // an example of this is used in the custom inputs for forms
    if (props.dataIndex !== undefined) {
      props.setItems(newValue.map((item) => item.value.substring(0, 50)), props.dataIndex);
    }
    else {
      props.setItems(newValue.map((item) => item.value.substring(0, 50)));
    }
    // console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(props.items, oldIndex, newIndex);

    // this if statement is used for lists of multi-select dropdowns, where we need to identify which dropdown was changed
    // an example of this is used in the custom inputs for forms
    if (props.dataIndex !== undefined) {
      props.setItems(newValue, props.dataIndex);
    }
    else {
      props.setItems(newValue);
    }
    // console.log(
    //   "Values sorted:",
    //   newValue.map((value) => value)
    // );
  };

  return (
    <SortableSelect
      helperClass="z-40 visible"
      // react-sortable-hoc props:
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      // react-select props:
      isMulti
      placeholder={props.placeholder}
      onChange={handleChange}
      options={props.options}
      styles={customStyles}
      value={props.items.map((item) => ({value: item, label: item}))}
      components={{
        MultiValue: SortableMultiValue
      }}
      closeMenuOnSelect={false}
    />
  );
}
// 