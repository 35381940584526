/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import CreateFormSlideover from "../slideovers/CreateFormSlideover";
import CreateDonationSlideover from "../slideovers/CreateDonationSlideover";
import SearchGPT from "../slideovers/SearchGPT";
import CreateDonationFlagSlideover from "../slideovers/CreateDonationFlagSlideover";
import CreateQuickDonation from "../slideovers/CreateQuickDonation";
import CreateDonorList from "../slideovers/CreateDonorList";
import CreateEventSlideover from "../slideovers/CreateEventSlideover";
import CreateTicketSlideover from "../slideovers/CreateTicketSlideover";

import { useSelector } from "react-redux";

import FundraisingBar from "../slideovers/CreateFundraisingBar";
import QRCode from "../slideovers/CreateQRCode";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const eventOrgs = [
  "Dev",
  "Thabaat",
  "Muslim Coogs Alumni Network",
  "Nona Muslim Community Association Inc",
  "Muslim Longhorns Alumni Association",
  "Guided Hearts Institute",
  "Nueces Mosque Operations",
  ".NGO Charities",
  "Amaly",
  "Hamd Institute",
  "Texas Muslimahs",
  "Detroit Muslim Running Club",
  "Ihya Foundation",
  "Alpha Lambda Mu Foundation",
  "An Nisa Hope Center",
  "Khalil Islamic Community",
  "Pillars by Hamd",
  "Salaam Foundation",
  "Aghosh AlKhidmat USA",
  "Al Noor Muslim Community Center",
  "Bayan",
  "Guardians Foundation International",
  "Middle Eastern Immigrant and Refugee Alliance (MIRA)",
  "Mihraab Foundation",
  "Rahma Worldwide Aid & Development",
  "The Giving Games Foundation",
  "The Preservers",
  "The SunBundle",
  "Two Point Five Fund",
  "Najam Institute Foundation Inc",
  "Yameen ATX",
  "Iswarah",
  "Kidneys Beyond Borders Inc",
  "Voices of Virtue",
];

export default function CreateDropdown(props) {
  const [openForm, setOpenForm] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);
  const [openTicket, setOpenTicket] = useState(false);

  const [openDonation, setOpenDonation] = useState(false);
  const [openSearchGPT, setOpenSearchGPT] = useState(false);
  const [openDonationFlag, setOpenDonationFlag] = useState(false);
  const [openQuickDonate, setOpenQuickDonate] = useState(false);
  const [openFundraisingBar, setOpenFundraisingBar] = useState(false);
  const [openQRCode, setOpenQRCode] = useState(false);
  const [openDonorList, setOpenDonorList] = useState(false);

  const { readOnly } = useSelector((state) => state.readOnly); // this contains the variable which youll use to check
  {
    /*
            If the readOnly variable is truthy (evaluates to true), it will return null. In other words, nothing will be rendered in this case.
            If the readOnly variable is falsy (evaluates to false), it will execute the code block enclosed in the parentheses ( and ). This code block can contain JSX elements or other JavaScript code that will be rendered or executed when readOnly is falsy.
            */
  }

  console.log("dropdown, props.selectableFoms", props.selectableForms);
  return readOnly ? null : (
    <div>
      <CreateFormSlideover
        grabSelectableFunds={props.grabSelectableFunds}
        open={openForm}
        setOpen={setOpenForm}
        selectableFunds={props.selectableFunds}
        merchant={props.merchant}
        selectableForms={props.selectableForms}
      />
      <CreateDonationSlideover
        grabSelectableFunds={props.grabSelectableFunds}
        open={openDonation}
        setOpen={setOpenDonation}
        selectableFunds={props.selectableFunds}
        merchant={props.merchant}
        selectableForms={props.selectableForms}
      />
      <SearchGPT
        grabSelectableFunds={props.grabSelectableFunds}
        open={openSearchGPT}
        setOpen={setOpenSearchGPT}
        selectableFunds={props.selectableFunds}
        merchant={props.merchant}
        selectableForms={props.selectableForms}
      />
      <CreateDonationFlagSlideover
        grabSelectableFunds={props.grabSelectableFunds}
        open={openDonationFlag}
        setOpen={setOpenDonationFlag}
        selectableFunds={props.selectableFunds}
        merchant={props.merchant}
        selectableForms={props.selectableForms}
      />
      <CreateQuickDonation
        grabSelectableFunds={props.grabSelectableFunds}
        open={openQuickDonate}
        setOpen={setOpenQuickDonate}
        selectableFunds={props.selectableFunds}
        merchant={props.merchant}
        selectableForms={props.selectableForms}
      />
      <FundraisingBar
        grabSelectableFunds={props.grabSelectableFunds}
        open={openFundraisingBar}
        setOpen={setOpenFundraisingBar}
        selectableFunds={props.selectableFunds}
        merchant={props.merchant}
        selectableForms={props.selectableForms}
      />
      <QRCode
        grabSelectableFunds={props.grabSelectableFunds}
        open={openQRCode}
        setOpen={setOpenQRCode}
        selectableFunds={props.selectableFunds}
        merchant={props.merchant}
        selectableForms={props.selectableForms}
      />
      <CreateDonorList
        open={openDonorList}
        setOpen={setOpenDonorList}
        merchant={props.merchant}
        selectableForms={props.selectableForms}
      />
      <CreateEventSlideover
        grabSelectableFunds={props.grabSelectableFunds}
        open={openEvent}
        setOpen={setOpenEvent}
        selectableFunds={props.selectableFunds}
        merchant={props.merchant}
        selectableForms={props.selectableForms}
      />
      <CreateTicketSlideover
        grabSelectableFunds={props.grabSelectableFunds}
        open={openTicket}
        setOpen={setOpenTicket}
        selectableFunds={props.selectableFunds}
        merchant={props.merchant}
        selectableForms={props.selectableForms}
      />

      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-gray-400 focus:ring-offset-2">
            &nbsp; Create
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="focus:outline-none absolute right-0 z-10 mt-2 w-44 origin-top-right rounded-lg bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="py-0">
              <span className="px-2 text-xs font-bold">Raise</span>
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => setOpenForm(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-2 py-1 text-sm"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                      />
                    </svg>
                    <span className="text-sm">&nbsp; Fundraiser </span>
                  </a>
                )}
              </Menu.Item>

              {props.merchant !== null &&
                props.merchant !== undefined &&
                props.merchant.merchantName !== "ICNA Relief USA" &&
                props.merchant.merchantName !== "ICNA Relief USA Dev" && (
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => setOpenDonation(true)}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-2 py-1 text-sm"
                        )}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <span className="text-sm">&nbsp; Donation </span>
                      </a>
                    )}
                  </Menu.Item>
                )}

              {/* <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => setOpenQuickDonate(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-2 py-2 text-sm'
                    )}
                  >

                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3" />
                    </svg>
                    <span className="text-sm">&nbsp;                    Quick Donate </span>

                  </a>
                )}
              </Menu.Item>
              <div className="border-b border-gray-300 mx-2 my-1 h-full"></div> */}
              {/* 
              <span className="text-xs px-2 font-bold">Elements</span>
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => setOpenFundraisingBar(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-2 py-2 text-sm'
                    )}
                  >

                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                    </svg>
                    <span className="text-sm">&nbsp;                    Fundraising Bar </span>

                  </a>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => setOpenDonorList(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-2 py-2 text-sm'
                    )}
                  >

                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                    </svg>
                    <span className="text-sm">&nbsp;                    Donor List </span>

                  </a>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => setOpenDonationFlag(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-2 py-2 text-sm'
                    )}
                  >


                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9" />
                    </svg>
                    <span className="text-sm">&nbsp;                    Donation Flag </span>

                  </a>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => setOpenQRCode(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-2 py-2 text-sm'
                    )}
                  >


                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
                    </svg>
                    <span className="text-sm">&nbsp;                    QR Code </span>

                  </a>
                )}
              </Menu.Item> 
              <div className="border-b border-gray-300 mx-2 my-1 h-full"></div>

              {/* Disabled until create donation is made 
              <span className="text-xs px-2 font-bold">Donations</span>
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => setOpenDonation(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-2 py-2 text-sm'
                    )}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span className="text-sm">&nbsp;                    Internal Donation </span>
                  </a>
                )}
              </Menu.Item>

              <div className="border-b border-gray-300 mx-2 my-1 h-full"></div>
            */}
              {/* Disabled until create donation is made */}
              {props.groups.some((group) => eventOrgs.includes(group)) && (
                <>
                  <div className="mx-2 my-1 h-full border-b border-gray-300"></div>

                  <span className="px-2 text-xs font-bold">Connect</span>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => setOpenEvent(true)}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-2 py-1 text-sm"
                        )}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2Z"
                          />
                        </svg>
                        <span className="text-sm">&nbsp; Event</span>
                      </a>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => setOpenTicket(true)}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "group flex items-center px-2 py-1 text-sm"
                        )}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 0 0-2 2v3a2 2 0 1 1 0 4v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3a2 2 0 1 1 0-4V7a2 2 0 0 0-2-2H5Z"
                          />
                        </svg>
                        <span className="text-sm">&nbsp; Ticket</span>
                      </a>
                    )}
                  </Menu.Item>
                </>
              )}

              {/* <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => setOpenDonation(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-2 py-2 text-sm'
                    )}
                  >
                    
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
  </svg>
                    <span className="text-sm">&nbsp;                    Offline Donation </span>
                  </a>
                )}
              </Menu.Item> */}
              {/* <div className="border-b border-gray-300 mx-2 my-1 h-full"></div> */}

              {/* Disabled until create donation is made */}
              {/* <span className="text-xs px-2 font-bold">Scheduling</span>
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => setOpenDonation(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-2 py-2 text-sm'
                    )}
                  >

                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
                    </svg>
                    <span className="text-sm">&nbsp;                    Events </span>
                  </a>
                )}
              </Menu.Item>


              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => setOpenDonation(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-2 py-2 text-sm'
                    )}
                  >

                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                    </svg>
                    <span className="text-sm">&nbsp;                    Classes </span>
                  </a>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => setOpenDonation(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-2 py-2 text-sm'
                    )}
                  >

                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                    </svg>
                    <span className="text-sm">&nbsp;                    Appointments </span>
                  </a>
                )}
              </Menu.Item> */}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      {/*       <Menu as="div" className="relative inline-block text-left">

      <Menu.Item>
  {({ active }) => (
    <button
      onClick={() => setOpenSearchGPT(true)}
      className={classNames(
        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
        'group flex items-center px-4 py-2 text-sm ml-2'
      )}
    >
      <div className="inline-flex w-full justify-center ">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="1 0 26 20" stroke-width="1.5" stroke="currentColor" className="w-6 h-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
        </svg>
      </div>
      &nbsp;
    </button>
  )}
</Menu.Item>
</Menu> */}
    </div>
  );
}
