import React, { useState, useEffect } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import FilterButton from "../partials/actions/FilterButton";
import DonationDatePicker from "../partials/actions/DonationDatePicker";
import DashboardCard01 from "../partials/dashboard/DashboardCard01";
import DashboardCard02 from "../partials/dashboard/DashboardCard02";
import DashboardCard03 from "../partials/dashboard/DashboardCard03";
import DashboardCard04 from "../partials/dashboard/DashboardCard04";
import DashboardCard05 from "../partials/dashboard/DashboardCard05";
import DashboardCard06 from "../partials/dashboard/DashboardCard06";
import DashboardCard07 from "../partials/dashboard/DashboardCard07";
import DashboardCard09 from "../partials/dashboard/DashboardCard09";
import DashboardCard10 from "../partials/dashboard/DashboardCard10";
import DashboardCard11 from "../partials/dashboard/DashboardCard11";
import DonationsTable from "../partials/dashboard/DonationsTable";
import DonorsTable from "../partials/dashboard/DonorsTable";
import DonationsChart from "../partials/dashboard/DonationsChart";
import Linechart from "../charts/LineChart03";
import mainLogo from "../partials/header/logo.png";

function ComingSoon(props) {
  //logic for switching tables. Used in onClick below
  function switchTables() {
    setTableIndex((tableIndex + 1) % 2);
  }

  //constants
  const tables = [DonationsTable, DonorsTable];
  const tableNames = ["Donors Table", "Donations Table"];

  //Define default timerange
  const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
  let today = new Date();
  // increment today forward by 1 day
  today.setDate(today.getDate() + 1);
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() - 6);
  const startTimerange =
    tomorrow.toLocaleDateString("en-US", DATE_OPTIONS) +
    " - " +
    today.toLocaleDateString("en-US", DATE_OPTIONS);

  //react hooks
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [timerange, setTimerange] = useState(startTimerange);
  const [tableIndex, setTableIndex] = useState(0);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        groups={props.groups}
      />

      {/* Content area */}
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          groups={props.groups}
        />

        <main>
          <div className="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
            {/* Welcome banner */}

            <div className="bg-white py-12">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="lg:text-center">
                  <h2 className="text-secondaryfont-semibold text-base uppercase tracking-wide">
                    COMING SOON
                  </h2>
                  <p className="text-primary mt-2 text-3xl font-extrabold leading-8 tracking-tight sm:text-4xl">
                    A better way to make impact, today.
                  </p>
                  <img class="mx-auto h-60" src={mainLogo} alt=".NGO" />

                  <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                    We are currently working on this feature, and will launch
                    soon!
                  </p>
                </div>

                <div className="mt-10"></div>
              </div>
            </div>

            {/* Dashboard actions */}

            {/* Cards */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default ComingSoon;
