import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { LinkIcon } from "@heroicons/react/20/solid";
import Toggle from "../Toggle";
import Dropdown from "../dropdowns/Dropdown";

import SingleSelectNoCreateDropdown from "../dropdowns/SingleSelectNoCreateDropdown";
import { Auth, input } from "aws-amplify";
import ReactTooltip from "react-tooltip";
import SuccessAlert from "../alerts/SuccessAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import Checkbox from "../Checkbox";
import CreateTicket from "../CreateTicket";
import uuid from "react-uuid";
import ConfirmationTicket from "../payment-methods/ConfirmationTicket";
import tailwindConfig from "../../css/tailwind.config.js";
import CustomTicketCreationContent from "./tabs/CustomTicketCreationContent";
// import '../../css/donation-form-styles.css'
import dayjs from "dayjs";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const lightColor = tailwindConfig.theme.extend.colors.primary;
const darkColor = tailwindConfig.theme.extend.colors.secondary;

export default function CreateTicketSlideover(props) {
  const [loadedEvents, setLoadedEvents] = useState(false);

  const [showUpdatePaymentCreatePayment, setShowUpdatePaymentCreatePayment] =
    useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [inputtedTickets, setInputtedTickets] = useState([]);
  const [chosenEvent, setChosenEvent] = useState();
  const [chosenTickets, setChosenTickets] = useState([]);
  const [index, setIndex] = useState();

  const [jwtToken, setJWTToken] = useState("");
  const [donationAmount, setDonationAmount] = useState("0.0");
  const [donorFirstName, setDonorFirstName] = useState("");
  const [donorLastName, setDonorLastName] = useState("");
  const [donorEmail, setDonorEmail] = useState("");
  const [donorPhoneNumber, setDonorPhoneNumber] = useState("");

  const [donorCountry, setDonorCountry] = useState("");
  const [donorAddress1, setDonorAddress1] = useState("");
  const [donorAddress2, setDonorAddress2] = useState("");
  const [donorCity, setDonorCity] = useState("");
  const [donorState, setDonorState] = useState("");
  const [donorZip, setDonorZip] = useState("");
  const [transactionID, setTransactionID] = useState("");

  const [defaultDonationType, setDefaultDonationType] = useState("Monthly");
  const [oneTimeAmount1, setOneTimeAmount1] = useState("");
  const [oneTimeAmount2, setOneTimeAmount2] = useState("");
  const [oneTimeAmount3, setOneTimeAmount3] = useState("");
  const [oneTimeAmount4, setOneTimeAmount4] = useState("");
  const [monthlyAmount1, setMonthlyAmount1] = useState("");
  const [monthlyAmount2, setMonthlyAmount2] = useState("");
  const [monthlyAmount3, setMonthlyAmount3] = useState("");
  const [monthlyAmount4, setMonthlyAmount4] = useState("");
  const [showOneTimeDefault, setShowOneTimeDefault] = useState(false);
  const [showMonthlyDefault, setShowMonthlyDefault] = useState(false);
  const [oneTimeDefault, setOneTimeDefault] = useState("");
  const [monthlyDefault, setMonthlyDefault] = useState("");
  const [separateAmounts, setSeparateAmounts] = useState(false);
  const [requirePhoneNumber, setRequirePhoneNumber] = useState(false);
  const [requireBillingAddress, setRequireBillingAddress] = useState(false);
  const [requireCountryDropdown, setRequireCountryDropdown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [formData, setFormData] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [typeChange, setTypeChange] = useState();
  const [mapping, setMapping] = useState();
  const [ticketPassed, setTicketsPassed] = useState();
  const [elementID, setElementID] = useState();

  const [funds, setFunds] = useState([]);
  const [color, setColor] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [copiedState, setCopiedState] = useState("Copied!");
  const [monthlyChecked, setMonthlyChecked] = useState(true);
  const [frequencyChecked, setFrequencyChecked] = useState("");
  const [frequencyCheckedValue, setFrequencyCheckedValue] =
    useState("ONE_TIME");
  const [feeCovered, setFeeCovered] = useState(false);
  const [fees, setFees] = useState(0);
  const [oneTimeChecked, setOneTimeChecked] = useState(true);
  const [perTimeIndicator, setPerTimeIndicator] = useState("");
  const [ticketArrays, setTicketArray] = useState([]);

  const [selectedRecurringFrequency, setSelectedRecurringFrequency] = useState(
    "Please select an option"
  );
  const [showScheduleDonation, setShowScheduleDonation] = useState(false);
  const [showDatePickerInput, setShowDatePickerInput] = useState(false);
  const [startValue, setStartValue] = useState(dayjs(new Date()));
  const [endValue, setEndValue] = useState(undefined);

  function handleCoverFeesChange(e) {
    if (e === true) {
      setFeeCovered(true);
    }
    if (e === false) {
      setFeeCovered(false);
    }
  }

  const grabBaseMerchant = async (jwtToken) => {
    const url = process.env.REACT_APP_MERCHANT_URL;
    const requestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
    };

    let baseMerchantResponse = await fetch(url, requestOptions);
    let baseMerchantData = await baseMerchantResponse.json();
    props.setMerchant(baseMerchantData);
  };

  // internal functions
  const checkFormErrors = () => {
    if (
      chosenEvent == undefined ||
      chosenEvent == null ||
      chosenEvent.length == 0
    ) {
      return "An event is required";
    }
    if (inputtedTickets.length == 0) {
      return "Select a ticket";
    }
    for (let i = 0; i < inputtedTickets.length; i++) {
      if (inputtedTickets[0].firstName == "") {
        return "A fist name is required";
      }
      if (inputtedTickets[0].lastName == "") {
        return "A last name is required";
      }
      if (inputtedTickets[0].email == "") {
        return "An email is required";
      }
      if (inputtedTickets[0].type == "") {
        return "Select a ticket";
      }
    }
    return getEmptyFieldsMessage(inputtedTickets);

    return "";
  };

  function getEmptyFieldsMessage(inputtedData) {
    // Initialize counters for missing fields
    let missingFirstNames = 0;
    let missingLastNames = 0;
    let missingEmails = 0;

    // Check each entry in inputtedData
    inputtedData.forEach((entry) => {
      entry.attendees.forEach((attendee) => {
        if (!attendee.firstName) missingFirstNames++;
        if (!attendee.lastName) missingLastNames++;
        if (!attendee.email) missingEmails++;
      });
    });
    let missingFirst = "names";
    let missingLast = "names";
    let missingEmail = "emails";
    if (
      missingFirstNames === 0 &&
      missingLastNames === 0 &&
      missingEmails === 0
    ) {
      return "";
    } else {
      if (missingEmails == 1) {
        missingEmail = "email";
      }
      if (missingFirstNames == 1) {
        missingFirst = "name";
      }
      if (missingLastNames == 1) {
        missingLast = "name";
      }
      return `Missing ${missingFirstNames} first ${missingFirst}, ${missingLastNames} last ${missingLast}, and ${missingEmails} ${missingEmail}.`;
    }
  }

  const getData = async (token) => {
    // grab forms data
    const formsRequestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: token,
      },
    };

    const formsURL = process.env.REACT_APP_FORMS_URL;

    let formsResponse = await fetch(formsURL, formsRequestOptions);
    let formsData = await formsResponse.json();
    const eventTicketMap = [];

    formsData = formsData.filter((formData) => formData.formType === "EVENT");
    // create a map of formname string to states from formsdata
    const formsActive = new Map();
    // create a map of formname string to formType from formsdata
    const formsType = new Map();

    const formsTickets = new Map();

    const formsTicketMappings = new Map();

    // create map of formName string to set of active funds
    formsData.forEach((form) => {
      const key = form.nonprofitName + " | " + form.formName;
      formsActive.set(key, form.active);
      formsType.set(key, form.formType);
      formsTickets.set(key, form.tickets);

      const elementID = form.elementID;
      const eventName = form.formName;

      const tickets = form.tickets || [];

      // Insert the elementID into each ticket
      tickets.forEach((ticket) => {
        ticket.elementID = elementID;
        ticket.eventName = eventName;
      });

      eventTicketMap.push({
        event: form.formName,
        tickets,
      });
    });

    setTicketArray(eventTicketMap);
    setFormData(formsData);
    getEvents(eventTicketMap);
    setLoadedEvents(true);
  };

  const sendToPaymentScreen = async () => {
    let returnedError = checkFormErrors();
    if (returnedError != "") {
      setErrorMessage(returnedError);
      setShowErrorAlert(true);

      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    }
    setTransactionID(uuid());
    parseTickets();

    setShowUpdatePaymentCreatePayment(true);
  };

  function extractPrice(type) {
    const regex = /\$([\d.]+)/; // Match the dollar sign and one or more digits with an optional decimal point
    const match = type.match(regex);
    if (match && match[1]) {
      return parseFloat(match[1]); // Convert the matched string to a floating-point number
    }
  }

  function parseTickets() {
    let updatedTickets = inputtedTickets.map((ticket) => {
      const type = ticket.type;
      const priceMatch = extractPrice(type);
      // use type to get ticket from chosenTickets by matching on
      const chosenTicket = chosenTickets.find((ticket) => ticket.name == type);
      // const ticketNameMatch = type.match(/(.*?) -/);

      // if (priceMatch >= 0 && priceMatch != undefined) {
      //   ticket.price = priceMatch;
      // }
      ticket.price = parseFloat(chosenTicket.price);
      ticket.ticketName = chosenTicket.ticketName;

      // if (ticketNameMatch && ticketNameMatch[1]) {
      //   ticket.ticketName = ticketNameMatch[1];
      // }

      return ticket;
    });

    let newtickets = [...updatedTickets];
    chosenTickets.map((ticket) => {
      for (let i = 0; i < newtickets.length; i++) {
        if (newtickets[i].type == ticket.name) {
          console.log(newtickets[i].type, ticket.name);
          let newCustomTicket = newtickets[i];
          newCustomTicket["groupType"] = ticket.type;
          newCustomTicket["seats"] = ticket.seats;
          newCustomTicket["ticketID"] = ticket.id;
          setElementID(ticket.elementID);
        }
      }
    });

    const tickets = [];
    const ticketMapping = {};

    newtickets.forEach((ticketData) => {
      const { ticketID, type, attendees, price, ticketName, groupType, seats } =
        ticketData;

      // Create the ticketMapping entry or update an existing one

      if (!ticketMapping[ticketName]) {
        ticketMapping[ticketName] = {
          numSold: 0,
          id: ticketID,
          soldTotalPrice: 0.0,
        };
      }

      // Increment numSold and update soldTotalPrice
      ticketMapping[ticketName].numSold++;
      if (price != undefined && price != null) {
        ticketMapping[ticketName].soldTotalPrice += price;
      }
      // Create individual tickets for each attendee
      attendees.forEach((attendee) => {
        const { firstName, lastName, email } = attendee;

        // Generate a random confirmation ID for each attendee
        const confirmationID = Math.random().toString(36).substring(2, 9);

        // Create a ticket entry
        const ticket = {
          checkInStatus: false,
          ticketID: ticketID,
          type: groupType,
          price,
          ticketName: ticketName,
          confirmationID,
          seats: parseInt(seats, 10),
          firstName,
          lastName,
          email,
        };

        // Add the ticket to the tickets array
        tickets.push(ticket);
      });
    });

    // Convert ticketMapping into an array format
    const ticketMappingArray = Object.keys(ticketMapping).map((ticketName) => ({
      name: ticketName,
      ...ticketMapping[ticketName],
    }));

    const totalAmount = calculateTotalSum(ticketMappingArray);
    setDonationAmount(totalAmount);

    setInputtedTickets(newtickets);
    setMapping(ticketMappingArray);
    setTicketsPassed(tickets);
  }

  // Clear states when modal is closed
  const clearStates = () => {
    // close slideover
    // return to default states
    setTransactionID("");
    //    setShowConfirmation(false)
    setShowUpdatePaymentCreatePayment(false);
    setDonationAmount("");
    setDonorFirstName("");
    setDonorLastName("");
    setDonorEmail("");
    setFunds([]);
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
    setIsDisabled(false);
    setElementID();

    setTickets([]);
    setInputtedTickets([]);
    setChosenEvent();
    setIndex();
    setFormData();
    setSelectedEvents([]);
    setTypeChange();
    setMapping();
    setTicketsPassed();
    setTicketArray([]);

    props.setOpen(false);
  };

  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      const formattedMerchants = data["idToken"]["payload"][
        "cognito:groups"
      ].map((merchant) => merchant.replace(/-/g, " "));

      setJWTToken(data["idToken"]["jwtToken"]);
      getData(data["idToken"]["jwtToken"]);

      // setMerchants(formattedMerchants)
    };
    myFunction();
  }, []);
  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      const formattedMerchants = data["idToken"]["payload"][
        "cognito:groups"
      ].map((merchant) => merchant.replace(/-/g, " "));

      setJWTToken(data["idToken"]["jwtToken"]);
      getData(data["idToken"]["jwtToken"]);

      // setMerchants(formattedMerchants)
    };
    myFunction();
  }, [props.open]);

  useEffect(() => {
    if (props.merchant != null) {
    }
  }, [props.merchant]);

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
  }, [props.open]);

  let submitButtonClass =
    "justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2";
  if (isDisabled) {
    submitButtonClass =
      "justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm cursor-not-allowed focus:outline-none";
  }

  function extractTicketCount(type) {
    const regex = /\((\d+)\)/; // Regular expression to match (number)

    const match = regex.exec(type);
    let attendees = [];
    if (match) {
      const count = parseInt(match[1], 10);
      if (isNaN(count)) {
        attendees.push({
          firstName: "",
          lastName: "",
          email: "",
        });
      } else {
        for (let i = 0; i < count; i++) {
          attendees.push({
            firstName: "",
            lastName: "",
            email: "",
          });
        }
      }
    } else {
      attendees.push({
        firstName: "",
        lastName: "",
        email: "",
      });
    }
    return attendees;
  }

  const handleTypeChange = (value, index) => {
    if (
      value != null &&
      value != undefined &&
      value != "" &&
      value.length != 0
    ) {
      setTypeChange(value);
      let newtickets = [...inputtedTickets];
      let newCustomTicket = newtickets[index];
      newCustomTicket["type"] = value[0];

      let attendeesTemp = extractTicketCount(value[0]);
      newCustomTicket["attendees"] = attendeesTemp;
      setInputtedTickets(newtickets);
    }
  };

  // first name
  const handleFirstNameChange = (event, attendeeIndex, ticketIndex) => {
    let value = event.target.value;

    let newtickets = [...inputtedTickets];
    let attendee = newtickets[ticketIndex].attendees[attendeeIndex];
    attendee["firstName"] = value;
    setInputtedTickets(newtickets);
  };

  // last name
  const handleLastNameChange = (event, attendeeIndex, ticketIndex) => {
    let value = event.target.value;

    let newtickets = [...inputtedTickets];
    let attendee = newtickets[ticketIndex].attendees[attendeeIndex];
    attendee["lastName"] = value;
    setInputtedTickets(newtickets);
  };

  // email
  const handleEmailChange = (event, attendeeIndex, ticketIndex) => {
    let i = parseInt(event.target.dataset.index);
    let value = event.target.value;

    let newtickets = [...inputtedTickets];
    let attendee = newtickets[ticketIndex].attendees[attendeeIndex];
    attendee["email"] = value;
    setInputtedTickets(newtickets);
  };

  // Add New Ticket
  const createNewCustomTicket = () => {
    let newtickets = [...inputtedTickets];
    newtickets.push({
      id: Math.random().toString(36).substring(2, 9), // generates a random id
      type: "",
      attendees: [],
    });
    setInputtedTickets(newtickets);
  };

  function getEvents(ticketArrays) {
    let selectedTicketsList = [];
    ticketArrays.forEach((event) => {
      selectedTicketsList.push({
        id: event.tickets[0].elementID,
        name: event.event,
        tickets: event.tickets,
      });
    });
    setSelectedEvents(selectedTicketsList);
  }

  // Function to format tickets
  function formatTicket(ticket) {
    const ticketType = ticket.type === "SINGLE" ? "Single" : "Group";
    if (ticketType === "Single") {
      return {
        elementID: ticket.elementID,
        eventName: ticket.eventName,
        price: parseFloat(ticket.price).toFixed(2),
        type: ticket.type,
        seats: 1,
        id: ticket.id,
        ticketName: ticket.name,
        name: `${ticket.name} - $${parseFloat(ticket.price).toFixed(2)}`,
      };
    } else {
      return {
        elementID: ticket.elementID,
        eventName: ticket.eventName,
        price: parseFloat(ticket.price).toFixed(2),
        type: ticket.type,
        ticketName: ticket.name,
        seats: ticket.seats,
        id: ticket.id,
        name: `${ticket.name} (${ticket.seats}) - $${parseFloat(
          ticket.price
        ).toFixed(2)}`,
      };
    }
  }

  useEffect(() => {
    getTicketsPerEvent(chosenEvent);
  }, [chosenEvent]);

  function getTicketsPerEvent(selectedEvent) {
    if (selectedEvent != null && selectedEvent != undefined) {
      const selectedEventTickets = ticketArrays.find(
        (event) => event.tickets[0].eventName === selectedEvent[0]
      ).tickets;
      const formattedTickets = selectedEventTickets.map(formatTicket);
      setChosenTickets(formattedTickets);
      setFunds(selectedEvent);
    }
  }

  // Remove Ticket
  const removeCustomTicket = (index) => {
    let newtickets = [...inputtedTickets];
    newtickets.splice(index, 1);
    setInputtedTickets(newtickets);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let newtickets = [...inputtedTickets];
    let newCustomTicket = newtickets[source.index];
    newtickets.splice(source.index, 1);
    newtickets.splice(destination.index, 0, newCustomTicket);
    setInputtedTickets(newtickets);
  };

  function calculateTotalSum(ticketMapping) {
    let totalSummedAmount = 0.0;
    for (const ticket of ticketMapping) {
      totalSummedAmount += parseFloat(ticket.soldTotalPrice);
    }
    return totalSummedAmount.toString(); // Convert the final result to a string with 2 decimal places
  }

  const CreatePaymentCard = async (tokenID, stripePaymentMethod) => {
    let returnedError = checkFormErrors();

    if (returnedError != "" && transactionID === "") {
      setErrorMessage(returnedError);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    }

    if (returnedError === "") {
      //     setShowUpdatePaymentCreatePayment(true)
      const requestOptions = {
        method: "POST",
        headers: {
          accept: "*/*",
          "content-type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify({
          stripePaymentMethod: stripePaymentMethod,
          paymentMethod: "CARD",
          name:
            inputtedTickets[0].attendees[0].firstName +
            " " +
            inputtedTickets[0].attendees[0].lastName,
          fund: chosenEvent[0],
          address: donorAddress1,
          address2: donorAddress2,
          city: donorCity,
          amount: donationAmount,
          region: donorState,
          uuid: transactionID,
          tip: 0,
          zip: donorZip,
          donationFrequency: frequencyCheckedValue,
          dateEndCharge: endValue,
          coverFees: feeCovered,
          email: inputtedTickets[0].attendees[0].email,
          formName: chosenEvent[0],
          merchantName: props.merchant.merchantName,
          country: selectedCountry,
          token: tokenID,
          phone: donorPhoneNumber,
          elementID: elementID,
          tickets: ticketPassed,
          ticketMapping: mapping,
        }),
      };

      // submitting transaction to donors-data lambda
      try {
        let response = await fetch(
          `${process.env.REACT_APP_DASHBOARD_TRANSACTIONS_URL}/checkout/create-donation`,
          requestOptions
        );
        let responseBody = await response.json();
        if (!response.ok) {
          setShowErrorAlert(true);
          setErrorMessage(responseBody);
        } else {
          props.grabSelectableFunds(jwtToken);
          setShowErrorAlert(false);
          setErrorMessage("");
          props.setOpen(false);
          setShowConfirmation(true);
          clearStates();
        }
      } catch (error) {
        setShowErrorAlert(true);
        setErrorMessage(error.message);
      }
    }
  };

  const CreatePaymentBank = async (
    accountNumber,
    routingNumber,
    accountType
  ) => {
    let returnedError = checkFormErrors();
    if (returnedError != "" && transactionID === "") {
      setErrorMessage(returnedError);
      setShowErrorAlert(true);
      setShowSuccessAlert(false);
      return;
    }

    if (returnedError === "") {
      const requestOptions = {
        method: "POST",
        headers: {
          accept: "*/*",
          "content-type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify({
          accountNumber: accountNumber,
          routingNumber: routingNumber,
          accountType: accountType,
          paymentMethod: "PAY_BY_BANK",
          name:
            inputtedTickets[0].attendees[0].firstName +
            " " +
            inputtedTickets[0].attendees[0].lastName,
          fund: chosenEvent[0],
          address: donorAddress1,
          address2: donorAddress2,
          city: donorCity,
          elementID: elementID,
          amount: donationAmount,
          region: donorState,
          uuid: transactionID,
          tip: 0,
          zip: donorZip,
          donationFrequency: frequencyCheckedValue,
          dateEndCharge: endValue,
          coverFees: feeCovered,
          email: inputtedTickets[0].attendees[0].email,
          formName: chosenEvent[0],
          merchantName: props.merchant.merchantName,
          country: selectedCountry,
          phone: donorPhoneNumber,
          tickets: ticketPassed,
          ticketMapping: mapping,
        }),
      };

      // submitting transaction to donors-data lambda
      try {
        let response = await fetch(
          `${process.env.REACT_APP_DASHBOARD_TRANSACTIONS_URL}/checkout/create-donation`,
          requestOptions
        );
        let responseBody = await response.json();
        if (!response.ok) {
          setTransactionID(uuid());
          setShowErrorAlert(true);
          setErrorMessage(responseBody);
        } else {
          props.grabSelectableFunds(jwtToken);
          setShowErrorAlert(false);
          setErrorMessage("");
          props.setOpen(false);
          setShowConfirmation(true);
          clearStates();
        }
      } catch (error) {
        setTransactionID(uuid());
        setShowErrorAlert(true);
        setErrorMessage(error.message);
      }
    }
  };

  const handleFreeTicketPurchase = async (tokenID) => {
    let returnedError = checkFormErrors();
    if (returnedError != "" && transactionID === "") {
      setErrorMessage(returnedError);
      setShowErrorAlert(true);
      setShowSuccessAlert(false);
      return;
    }

    if (returnedError === "") {
      const requestOptions = {
        method: "POST",
        headers: {
          accept: "*/*",
          "content-type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify({
          customInputs: [],
          crowdfundingOption: undefined,
          amount: 0.0,
          email: inputtedTickets[0].attendees[0].email,
          uuid: transactionID,
          donationFrequency: "ONE_TIME",
          coverFees: feeCovered,
          merchantName: props.merchant.merchantName,
          merchantID: props.merchant.merchantID,
          name:
            inputtedTickets[0].attendees[0].firstName +
            " " +
            inputtedTickets[0].attendees[0].lastName,
          phone: donorPhoneNumber,
          address: donorAddress1,
          address2: donorAddress2,
          city: donorCity,
          tip: 0,
          region: donorState,
          zip: donorZip,
          country: selectedCountry,
          finixSessionKey: process.env.REACT_APP_FINIX_APPLICATION_ID,
          fund: chosenEvent[0],
          subFund: "",
          formName: chosenEvent[0],
          dateEndCharge: endValue,
          elementID: elementID,
          anonymous: false,
          tickets: ticketPassed,
          ticketMapping: mapping,
          color: lightColor,
        }),
      };
      // submitting transaction to donors-data lambda
      try {
        let response = await fetch(
          process.env.REACT_APP_FREE_TRANSACTION_URL,
          requestOptions
        );
        let responseBody = await response.json();
        if (!response.ok) {
          setShowErrorAlert(true);
          setErrorMessage(responseBody);
        } else {
          props.grabSelectableFunds(jwtToken);
          setShowErrorAlert(false);
          setErrorMessage("");
          props.setOpen(false);
          setShowConfirmation(true);
          clearStates();
        }
      } catch (error) {
        setShowErrorAlert(true);
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <>
      {showConfirmation === true && (
        <ConfirmationTicket
          slideoverOpen={props.openConfirmation}
          openConfirmation={showConfirmation}
          setOpenConfirmation={setShowConfirmation}
        />
      )}
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
          <CreateTicket
            slideoverOpen={props.open}
            open={showUpdatePaymentCreatePayment}
            setOpen={setShowUpdatePaymentCreatePayment}
            CreatePaymentBank={CreatePaymentBank}
            CreatePaymentCard={CreatePaymentCard}
            donationAmount={donationAmount}
            inputtedTickets={inputtedTickets}
            tickets={tickets}
            ticketMapping={mapping}
            setInputtedTickets={setInputtedTickets}
            selectedSubscription={funds}
            event={chosenEvent}
            merchant={props.merchant}
            frequency={frequencyChecked}
            errorMessageFromParent={errorMessage}
            perTimeIndicator={perTimeIndicator}
            fees={fees}
            feeCovered={feeCovered}
            setFeeCovered={setFeeCovered}
            handleFreeTicketPurchase={handleFreeTicketPurchase}
            //     address={donorAddress1} address2={donorAddress2} city={donorCity} region={donorState} country={selectedCountry} phone={donorPhoneNumber} email={donorEmail}
            //      coverFees={feeCovered}
            //        //check with Abe and MC if this is needed.
            //      formName="Dashboard Manual Donation"
          />

          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                Create Ticket
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Get started by filling in the information below
                                to create a new ticket.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={clearStates}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Divider container */}
                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:py-0">
                          {/* Tickets */}

                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6">
                            <label
                              htmlFor="form-name"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Event
                            </label>
                            <div className="flex w-full space-x-10 pt-2 text-sm sm:col-span-2">
                              <div className="w-full pb-5">
                                <SingleSelectNoCreateDropdown
                                  options={selectedEvents.map((ticket) => {
                                    return {
                                      value: ticket.name,
                                      label: ticket.name,
                                      color: "#69995E",
                                      seats: ticket.seats,
                                    };
                                  })}
                                  setItems={setChosenEvent}
                                  items={chosenEvent}
                                  placeholder={
                                    loadedEvents
                                      ? "Select Event"
                                      : "Loading Events..."
                                  }
                                  disabled={!loadedEvents}
                                />
                              </div>
                            </div>
                          </div>

                          {chosenTickets != null &&
                          chosenTickets.length > 0 &&
                          chosenTickets != undefined ? (
                            <div className="mb-16 space-y-6 py-6 sm:space-y-0 sm:py-0">
                              <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                  {(provided) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {inputtedTickets.map((ticket, index) => (
                                        <CustomTicketCreationContent
                                          key={ticket.id}
                                          id={ticket.id}
                                          CustomTicket={ticket}
                                          index={index}
                                          handleFirstNameChange={
                                            handleFirstNameChange
                                          }
                                          handleLastNameChange={
                                            handleLastNameChange
                                          }
                                          handleEmailChange={handleEmailChange}
                                          handleTypeChange={handleTypeChange}
                                          setTypeChange={setTypeChange}
                                          typeChange={typeChange}
                                          tickets={chosenTickets}
                                          inputtedTickets={inputtedTickets}
                                          setInputtedTickets={
                                            setInputtedTickets
                                          }
                                          setIndex={setIndex}
                                          removeCustomTicket={
                                            removeCustomTicket
                                          }
                                        />
                                      ))}
                                      {provided.placeholder}
                                      <div className="mr-4 mt-2 flex justify-end">
                                        <div
                                          className="flex cursor-pointer"
                                          onClick={createNewCustomTicket}
                                        >
                                          <PlusIcon
                                            className="mr-2 mt-0.5 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                          <div className="text-gray-400">
                                            {" "}
                                            add Ticket{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex h-12 items-center justify-between space-x-3">
                          {showSuccessAlert && (
                            <SuccessAlert
                              message="Form Created Successfully"
                              setShowSuccessAlert={setShowSuccessAlert}
                            />
                          )}
                          {showErrorAlert && (
                            <ErrorAlert
                              message={errorMessage}
                              setShowErrorAlert={setShowErrorAlert}
                            />
                          )}
                          {!showSuccessAlert && !showErrorAlert && <div />}
                          <button
                            type="button"
                            className={submitButtonClass}
                            onClick={sendToPaymentScreen}
                          >
                            {!isDisabled && "Next"}
                            {isDisabled && "Completed"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
