import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

export default function PDFViewer(props) {

    // added this to fix some bug i was getting. See https://github.com/wojtekmaj/react-pdf/issues/991
    useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; });
    const { pdf } = props;

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [inputValue, setInputValue] = useState('');

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    function goToPage() {
        const pageNumber = parseInt(inputValue);
        if (pageNumber && pageNumber >= 1 && pageNumber <= numPages) {
            setPageNumber(pageNumber);
            setInputValue('');
        }
    }

    return (
        <div className="flex flex-col h-full w-full">
            <div className="flex flex-grow">
                <div className="w-full h-full">
                    <Document
                        file={pdf}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                </div>
            </div>
            <div className="flex  items-start py-2 px-4 border-t">
                <button
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                    className="rounded-md border border-primary text-primary py-2 px-4 font-medium hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                >
                    Previous
                </button><br/>
                <div className="flex items-center space-x-2 py-2">
                    <span>
                        &nbsp;&nbsp; Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'} &nbsp;&nbsp;
                    </span>
                </div><br/>
                <button
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                    className="rounded-md border border-primary text-primary py-2 px-4 font-medium hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                >
                    Next
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="flex items-center space-x-2">
                    <span>Go to page:</span>
                    <input
                        type="number"
                        min="1"
                        max={numPages}
                        value={inputValue}
                        onChange={(event) => setInputValue(event.target.value)}
                        className="form-input rounded-md shadow-sm"
                    />
                    <button
                        type="button"
                        onClick={goToPage}
                        className="rounded-md border border-primary text-primary py-2 px-4 font-medium hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                    >
                        Go
                    </button>
                </div>
            </div>
        </div>
    );
    
    
}
