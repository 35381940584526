import { useRef, useState } from "react";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import ErrorAlert from "../alerts/ErrorAlert";
import ClipLoader from "react-spinners/ClipLoader";

export default function PaypalDisconnect(props) {
  const cancelButtonRef = useRef(null);
  const [sendingData, setSendingData] = useState(false);
  console.log("props value in paypal disconnect", props)

  const handleClose = () => {
    if (props.setOpen && typeof props.setOpen === 'function') {
      props.setOpen(false);
    }
  };

  return (
    <div className={`fixed inset-0 z-10 overflow-y-auto ${props.open ? '' : 'hidden'}`}>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          {props.showErrorAlert && (
            <div className="mb-2">
              <ErrorAlert
                message={props.errorMessage}
                setShowErrorAlert={props.setShowErrorAlert}
              />
            </div>
          )}
          <div className="sm:flex sm:items-start">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.0" stroke="currentColor" className="w-8 h-8">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            {/* 
       keeping the code for making the button clickable
          <button onClick={handleClose} className="group -ml-1 flex h-12 w-12 items-center justify-center bg-gray-100 rounded-full flex-shrink-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-300 ease-in-out transform hover:scale-110 hover:bg-gray-200 hover:shadow-lg">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.0" stroke="currentColor" className="w-8 h-8">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
 */}
            <div className="group -ml-6 flex h-12 w-12 items-center justify-center bg-gray-100 rounded-full flex-shrink-0 ">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.0" stroke="currentColor" className="w-8 h-8">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>

            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Confirm PayPal Disconnection
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Disconnecting your PayPal account will prevent you from offering PayPal services and products on your website.
                  <br></br>
                  <br></br>

                  Recurring subscriptions through PayPal will also no longer be processed. There will be additional steps to follow after disconnecting.
                  <br></br>
                  <br></br>
                  Do you wish to continue?
                </p>
              </div>
            </div>
          </div>
          {/* Horizontal Line */}
          <div className="my-4">
            <hr className="border-t border-gray-300" />
          </div>

          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            {sendingData ? (
              <button
                type="button"
                className="inline-flex w-1/5 justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm bg-secondary hover:bg-secondary focus:outline-none focus:ring-secondary pointer-events-none sm:ml-3 sm:text-sm"
              >
                <ClipLoader color={"#FFFFFF"} loading={sendingData} size={20} />
              </button>
            ) : (
              <button
                type="button"
                className="inline-flex w-1/5 justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-red-600 sm:ml-3 sm:text-sm"
                onClick={props.disconnectFromPaypal}
              >
                Disconnect
              </button>
            )}
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => props.setOpen(false)}
              ref={cancelButtonRef}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div >
  );
}
