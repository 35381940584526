import React, { useEffect } from "react";
import CustomInputsTab from "../../slideovers/tabs/CustomInputsTab";
import SuccessAlert from "../../alerts/SuccessAlert";
import ErrorAlert from "../../alerts/ErrorAlert";

function SettingsCustomInputs({ merchant, jwtToken }) {
  const [customInputs, setCustomInputs] = React.useState([]);
  const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);
  const [showErrorAlert, setShowErrorAlert] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isDisabled, setIsDisabled] = React.useState(false);

  let submitButtonClass =
    "justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2";
  if (isDisabled) {
    submitButtonClass =
      "justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm cursor-not-allowed focus:outline-none";
  }

  useEffect(() => {
    if (merchant) {
      setCustomInputs(merchant.globalCustomInputs ?? []);
    }
  }, [merchant]);

  const updateGlobalCustomInputs = async () => {
    //iterate through customInputs and check for errors
    for (let i = 0; i < customInputs.length; i++) {
      if (customInputs[i].title == "") {
        setErrorMessage("Custom Input Label is required");
        setShowErrorAlert(true);
        setShowSuccessAlert(false);
        return;
      }
      if (customInputs[i].type == "Select an Input Type") {
        setErrorMessage("Custom Input Type is required");
        setShowErrorAlert(true);
        setShowSuccessAlert(false);
        return;
      }
      if (
        (customInputs[i].type == "SINGLE_SELECTION_DROPDOWN" ||
          customInputs[i].type == "SINGLE_SELECTION_DROPDOWN") &&
        customInputs[i].options.length < 2
      ) {
        setErrorMessage(
          "2 or more Custom Input Options are required for Dropdowns"
        );
        setShowErrorAlert(true);
        setShowSuccessAlert(false);
        return;
      }
    }
    const payload = {
      customInputs: customInputs,
    };
    setIsDisabled(true);

    const requestOptions = {
      method: "PATCH",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(payload),
    };
    //needs to be updated
    const url =
      process.env.REACT_APP_ACCOUNTS_BACKEND + "/global-custom-inputs";

    let response = await fetch(url, requestOptions);
    let data = await response.json();
    console.log({ data });
    if (data.error) {
      console.log(data.error);
      return;
    } else {
      setIsDisabled(false);

      setShowErrorAlert(false);
      setShowSuccessAlert(true);

      // grabSelectableFunds(jwtToken)
    }
  };

  return (
    <div>
      <CustomInputsTab
        customInputs={customInputs}
        setCustomInputs={setCustomInputs}
        originalCustomInputs={[]}
      />
      <div className="flex-shrink-0 border-t border-gray-200 py-2">
        <div className="flex h-12 items-center justify-between space-x-3">
          {showSuccessAlert && (
            <SuccessAlert
              message="Changes Saved Successfully"
              setShowSuccessAlert={setShowSuccessAlert}
            />
          )}
          {showErrorAlert && (
            <ErrorAlert
              message={errorMessage}
              setShowErrorAlert={setShowErrorAlert}
            />
          )}
          {!showSuccessAlert && !showErrorAlert && <div />}
          <button
            type="button"
            className={submitButtonClass}
            onClick={updateGlobalCustomInputs}
          >
            {!isDisabled && "Save"}
            {isDisabled && "Saving"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SettingsCustomInputs;
