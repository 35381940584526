import React, { useState, useEffect, useCallback } from "react";
import ReactTooltip from "react-tooltip";
import CustomPromoTabContent from "./CustomPromoTabContent";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { PlusIcon } from "@heroicons/react/20/solid";

function CustomPromoTab(props) {
  const {
    promosList,
    setPromosList,
    availableTickets,
    setShowErrorAlert,
    showErrorAlert,
    setErrorMessage,
    errorMessage,
  } = props;

  useEffect(() => {
    let newPromos = promosList.map((promo) => ({
      ...promo,
      tickets: promo.tickets.map((ticket) => {
        let newTicket = availableTickets.find((t) => t.id === ticket.id);
        console.log("newTicket", newTicket, ticket.id, ticket.name);
        return {
          id: ticket.id,
          name: newTicket.name,
        };
      }),
      tickets2: [
        ...promo.tickets.map((ticket) => {
          let newTicket = availableTickets.find((t) => t.id === ticket.id);
          console.log("newTicket2", newTicket, ticket.id, ticket.name);
          return {
            id: ticket.id,
            name: newTicket.name,
          };
        }),
      ],
    }));
    console.log("useEffect", newPromos);
    setPromosList(newPromos);
  }, [availableTickets]);

  // Name
  const handleNameChange = (promo) => {
    let index = parseInt(promo.target.dataset.index);
    let value = promo.target.value;

    let newPromos = [...promosList];
    let newPromo = newPromos[index];
    newPromo["codeName"] = value;
    setPromosList(newPromos);
    console.log("handleNameChange", newPromos);
  };

  // Type
  const handleTypeChange = (promo) => {
    let index = parseInt(promo.target.dataset.index);
    let value = promo.target.defaultValue;

    let newPromos = [...promosList];
    let newPromo = newPromos[index];
    newPromo["codeType"] = value;
    setPromosList(newPromos);
    console.log("handleTypeChange", newPromos);
  };

  // Amount
  const handleAmountChange = (promo) => {
    let index = parseInt(promo.target.dataset.index);
    let value = promo.target.value;

    // checking if value include a dec point
    if (value.match(/\./g)) {
      const [, decimal] = value.split(".");

      // restrict value to only 2 decimal places
      if (decimal?.length > 2) {
        // do nothing
        return;
      }
    }

    let newPromos = [...promosList];
    let newPromo = newPromos[index];
    newPromo["codeAmount"] = parseFloat(value);
    setPromosList(newPromos);
    console.log("handleAmountChange", newPromos);
  };

  // Tickets
  const handleTicketsChange = (promos, index) => {
    console.log("handleTicketsChange promos", promos);
    let value = getSelectedTickets(promos);

    let newPromos = [...promosList];
    let newPromo = newPromos[index];
    newPromo["tickets"] = value;
    newPromo["tickets2"] = value;

    setPromosList(newPromos);
    console.log("handleTicketsChange", newPromos);
  };

  // Given a list of selected ticket name, return a list of map of the selected tickets with ticket id and ticket name as keys
  function getSelectedTickets(selectedTickets) {
    let selectedTicketsList = [];
    selectedTickets.forEach((ticket) => {
      let ticketId = availableTickets.find((t) => t.name === ticket).id;
      selectedTicketsList.push({
        id: ticketId,
        name: ticket,
      });
    });
    return selectedTicketsList;
  }

  // Add New Promo
  const createNewCustomPromo = () => {
    if (availableTickets.length === 0) {
      setErrorMessage("A ticket needs to created");
      setShowErrorAlert(true);
      return;
    } else {
      let newPromos = [...promosList];
      newPromos.push({
        codeId: Math.random().toString(36).substring(2, 9), // generates a random id
        codeName: "",
        codeType: "PERCENTAGE",
        codeAmount: 0,
        tickets: [],
      });
      setPromosList(newPromos);
      console.log("createNewCustomPromo", newPromos);
    }
  };

  // Remove Promo
  const removeCustomPromo = (index) => {
    let newPromos = [...promosList];
    newPromos.splice(index, 1);
    setPromosList(newPromos);
    console.log("removeCustomPromo", newPromos);
  };

  const onDragEnd = (result) => {
    console.log(result);
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let newPromos = [...promosList];
    let newPromo = newPromos[source.index];
    newPromos.splice(source.index, 1);
    newPromos.splice(destination.index, 0, newPromo);
    console.log(newPromos);
    setPromosList(newPromos);
    console.log("on drag end", newPromos);
  };

  return (
    <>
      <div className="mb-16 space-y-6 py-6 sm:space-y-0 sm:py-0">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {promosList.map((promo, index) => (
                  <CustomPromoTabContent
                    key={promo.codeId}
                    id={promo.codeId}
                    CustomPromo={promo}
                    index={index}
                    handleNameChange={handleNameChange}
                    handleTypeChange={handleTypeChange}
                    handleTicketsChange={handleTicketsChange}
                    handleAmountChange={handleAmountChange}
                    removeCustomPromo={removeCustomPromo}
                    setShowErrorAlert={setShowErrorAlert}
                    showErrorAlert={showErrorAlert}
                    setErrorMessage={setErrorMessage}
                    errorMessage={errorMessage}
                    tickets={availableTickets.map((ticket) => ticket.name)}
                  />
                ))}
                {provided.placeholder}
                <div className="mr-4 mt-2 flex justify-end">
                  <div
                    className="flex cursor-pointer"
                    onClick={createNewCustomPromo}
                  >
                    <PlusIcon
                      className="mr-2 mt-0.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <div className="text-gray-400"> add Promo Code </div>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
}

export default CustomPromoTab;
