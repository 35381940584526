/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Dialog, Transition } from "@headlessui/react";
import { CreditCardIcon } from "@heroicons/react/24/outline";
import { BanknotesIcon, BuildingLibraryIcon } from "@heroicons/react/20/solid";
import "./donationform.css";
import "./toggle.css";

function wait(ms) {
  var start = new Date().getTime();
  var end = start;
  while (end < start + ms) {
    end = new Date().getTime();
  }
}

export default function ConfirmationPaypalDisconnect(props) {
  const {
    errorMessageFromParent,
  } = props;

  const cancelButtonRef = useRef(null);

  const [jwtToken, setJWTToken] = useState(null);
  const [sendingData, setSendingData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  // Card Related
  const [cardSelected, setCardSelected] = useState(false);
  const [iframeStates, setIframeStates] = useState(null);
  const [form, setForm] = useState(null);

  // Bank Transfer Related
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountType, setAccountType] = useState("CHECKING");

  useEffect(() => {
    const myFunction = async () => {
      if (errorMessageFromParent !== "") {
        setShowErrorAlert(true);
        setErrorMessage(errorMessageFromParent);
        setSendingData(false);
      }
    };
    myFunction();
  }, [errorMessageFromParent]);

  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      setJWTToken(data["idToken"]["jwtToken"]);

      setForm(
        window.PaymentForm.card((state, binInformation) => {
          if (state === undefined) {
          } else {
            setIframeStates(state);
          }
        })
      );
      console.log("line 56");
    };
    myFunction();
  }, []);

  useEffect(() => {
    const myFunction = async () => {
      if (props.openConfirmation == false) {
        setForm(
          window.PaymentForm.card((state, binInformation) => {
            if (state === undefined) {
            } else {
              setIframeStates(state);
            }
          })
        );
        setErrorMessage("");
        setShowErrorAlert(false);
        setCardSelected(false);
        setAccountNumber("");
        setRoutingNumber("");
        setAccountType("CHECKING");
      }
    };
    myFunction();
  }, [props.openConfirmation]);

  let cardVisibility = {};
  let bankTransferVisibility = { display: "none" };

  let extraCardButtonClass =
    "bg-primary text-white hover:bg-secondary focus:ring-secondary border-transparent";
  let extraBankTransferButtonClass =
    "bg-white text-secondary hover:bg-gray059 focus:ring-gray-400 border-gray-300";

  return (
    <Transition.Root
      show={props.openConfirmation}
      as={Fragment}
      unmount={props.slideoveropenConfirmation}
    >
      <Dialog
        as="div"
        className="relative z-20"
        initialFocus={cancelButtonRef}
        onClose={props.setOpenConfirmation}
        unmount={props.slideoveropenConfirmation}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          unmount={props.slideoveropenConfirmation}
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              unmount={props.slideoveropenConfirmation}
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  {/* {
                                        showErrorAlert
                                        &&
                                        <ErrorAlert message={errorMessage} setShowErrorAlert={setShowErrorAlert} />
                                    } */}

                  <div className="mt-3 text-center sm:text-left">
                    <div className="">
                      <div className="grid grid-cols-2">
                        <p className="col-span-1 mt-2 flex items-center text-sm text-gray-500">
                          {/* <BanknotesIcon className='ml-1 mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' /> */}
                        </p>
                      </div>
                    </div>
                    <div className=" flex justify-between"></div>
                    <div className="">
                      <p className="text-sm text-gray-500">
                        <div className="FormBoxConfirm" id="FormBoxConfirm">
                          <form>
                            <div
                              style={{
                                top: "5%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                animationDelay: ".5s",
                              }}
                            >
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 130.2 130.2"
                                style={{ width: "40px", display: "block" }}
                              >
                                <circle
                                  class="path circle"
                                  fill="none"
                                  stroke="#73AF55"
                                  stroke-width="6"
                                  stroke-miterlimit="10"
                                  cx="65.1"
                                  cy="65.1"
                                  r="62.1"
                                />
                                <polyline
                                  class="path check"
                                  fill="none"
                                  stroke="#73AF55"
                                  stroke-width="6"
                                  stroke-linecap="round"
                                  stroke-miterlimit="10"
                                  points="100.2,40.2 51.5,88.8 29.8,67.5 "
                                />
                              </svg>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                                textAlign: "center",
                                justifyContent: "center",
                                fontSize: "2.0vh",
                                fontWeight: "bold",
                                marginTop: "5px",
                              }}
                            >
                              {" "}
                              PayPal has been removed{" "}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                                justifyContent: "center",
                              }}
                            ></div>
                       

                              <br></br>
                              <div className="content-start"  style={{
                                display: "flex",
                                textAlign: "left",
                                justifyContent: "left",
                                fontSize: "12.4px",
                                color: "darkgray",
                                fontWeight: "600",
                                paddingBottom: "3px",
                              }}>
                                Finalize PayPal disconnection with the following steps: 
                                <br></br>
                                </div>
                                <div className="content-start"  style={{
                                display: "flex",
                                textAlign: "left",
                                justifyContent: "left",
                                fontSize: "12.4px",
                                color: "darkgray",
                                fontWeight: "400",
                                paddingBottom: "1px",
                              }}>
                              1. Login to your PayPal account and click on your icon in the top-right corner
                              <br></br>
                              2. Under &quot;Account Settings&quot; select &quot;API Access&quot;
                              <br></br>
                              3. Under REST API Integration, select &quot;Manage REST API apps and credentials&quot;
                              <br></br>
                              4. Select the app you would like to revoke and remove permissions from the platform
                            </div>

                            <br></br>
                          </form>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
