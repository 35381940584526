import React, { useState, useEffect } from 'react';
import { DatePicker, Space } from 'antd';
// import '../Styling/styles.css'
import dayjs from 'dayjs';
import { CloseOutlined, CalendarOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
const RecurringDatePicker = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [suffixIcon, setSuffixIcon] = useState(<CalendarOutlined style={{ fontSize: '150%' }}/>)
  const dateFormat = 'MM/DD/YYYY';

  useEffect(() => {
    // console.log('props.showComponent', props.showComponent)
    if (props.showComponent) {
      // setIsOpen(true)
      // const element = document.querySelector('[placeholder="End month"]');
      // console.log('element',element)
      // element.click();
    }
  }, [props.showComponent])

  // useEffect(() => {
  //   if (isOpen) {
  //     const element = document.querySelector('[placeholder="End month"]');
  //     console.log('element',element)
  //     element.click();
  //   }
  // }, [isOpen])

  useEffect(() => {
    console.log("in use effect")
    if (props.endValue === null) {
      props.setShowComponent(false)
    }
    else {
      // const icon = document.getElementsByClassName('ant-picker-suffix');
      // icon.innerHTML = 'X';
    }
  }, [props.endValue])

  const disabledDate = (current) => {
    // Allow only dates one month into the future and after
    if (props.timePicker === "week") {
      return current < dayjs().add(1, 'week');
    }
    else if (props.timePicker === "day") {
      return current < dayjs().add(1, 'day');
    }
    else if (props.timePicker === "year") {
      return current < dayjs().add(1, 'year');
    }

    // default to monthly
    return current < dayjs().add(1, 'month');
  };

  const handleCalendarChange = (dates) => {
    if (dates && dates.length > 1) {
      props.setEndValue(dates[1]);
    }
  };

  console.log("props.picker: ", props.timePicker)
  
  return (
    <RangePicker
      format={dateFormat}
      disabledDate={disabledDate}
      picker={props.timePicker !== "day" ? props.timePicker : undefined}
      onOpenChange={(status) => {
        if (status) {
          props.setEndValue(undefined);
        }
      }}
      onChange={(datesArr) => {
        if (datesArr && datesArr.length === 2) {
          props.setEndValue(datesArr[1]);
        } else {
          props.setEndValue(undefined);
        }
      }}
      onCalendarChange={handleCalendarChange}
      clearIcon={<CloseOutlined style={{ fontSize: '20px' }} />}
      suffixIcon={suffixIcon}
      allowClear={true}
      value={[props.startValue, props.endValue]} // Always set the start date to today
      defaultPickerValue={[props.startValue, props.endValue]} // Ensure the left side shows the start date
      disabled={[true, false]} // Disable the start date picker
      style={{ width: "100%", height: "42px", borderWidth: '1px', borderColor: props.lightColor ?? '#000000', borderStyle: 'solid', borderRadius: "6px" }}
    />
  );
};

export default RecurringDatePicker;