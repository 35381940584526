import React, { Component } from "react";
import { StrictMode } from "react/cjs/react.production.min";
import PaginationClassic from "../PaginationClassic";
import moment from "moment";
import { Auth } from "aws-amplify";
import DonationDatePicker from "./DonationDatePicker";
import { CSVLink } from "react-csv";
import EditFormSlideover from "../slideovers/EditFormSlideover";
import EditQuickDonationSlideover from "../slideovers/EditQuickDonationSlideover";
import decompressData from "../../utils/decompressData";
import pako from "pako";

const fundraiserTypeMap = {
  CROWDFUNDING_FORM: "Donation Form",
  EMBED_FORM: "Donation Form",
  DONATION_FORM: "Donation Form",
  QUICK_DONATE: "Quick Donate",
  DONATION_FLAG: "Donation Flag",
  EVENT: "Event",
  PRODUCT: "Product",
};

export class FormsTable extends Component {
  constructor(props) {
    super(props);

    const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
    let today = new Date();
    // increment today forward by 1 day
    today.setDate(today.getDate() + 1);
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() - 6000);
    const startTimerange =
      tomorrow.toLocaleDateString("en-US", DATE_OPTIONS) +
      " - " +
      today.toLocaleDateString("en-US", DATE_OPTIONS);
    this.state = {
      jwtToken: "",
      donorsList: [],
      start: 0,
      end: 0,
      total: 0,
      totalActiveForms: 0,
      totalDonors: 0,
      grossAmountVariable: 0,
      totalDonationSum: 0,
      totalDonations: 0,
      totalDonorValue: 0,
      timerange: startTimerange,
      showFilter: false,
      open: false,
      openQuickDonation: false,
      editFormData: null,
      formsData: null,
    };
  }

  setOpen = (toggle) => {
    this.setState({ open: toggle });
    // console.log('toggle', toggle)
    // if (toggle == false) {
    //   console.log("close")
    //   this.updateData()
    // }
  };

  setOpenQuickDonation = (toggle) => {
    this.setState({ openQuickDonation: toggle });
  };

  onFilterClickHandler = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  async componentDidMount() {
    const data = await Auth.currentSession();
    let user = await Auth.currentAuthenticatedUser();
    this.setState(
      {
        jwtToken: data["idToken"]["jwtToken"],
        whoIsLoggedIn: user.username,
      },
      () => this.updateData()
    );
  }

  componentDidUpdate() {
    const { totalActiveForms, totalDonationSum, totalDonations, totalDonors } =
      this.state;
    let totalDonationSumTwoDecimals = totalDonationSum.toFixed(2);
    this.props.getMetrics(
      totalActiveForms,
      totalDonationSumTwoDecimals,
      totalDonations,
      totalDonors
    );
  }

  convertTimestamp(timestamp) {
    const sections = timestamp.split("-");
    return sections[1] + "-" + sections[2] + "-" + sections[0];
  }

  setDateTimeRange = (timerange) => {
    this.setState({ timerange: timerange }, () => this.updateData());
  };

  onEditHandler = (key) => {
    const { formsData } = this.state;
    // const nonprofitName = e.target.id.split(" | ")[0];
    const formName = key.split(" | ")[1];
    const formData = formsData.find((form) => form.formName === formName);
    console.log("formData", formData);
    if (
      formData.fundraiserType === "DONATION_FLAG" ||
      formData.fundraiserType === "DONATION_FORM" ||
      formData.fundraiserType === "PRODUCT"
    ) {
      this.setState({
        open: true,
        editFormData: formData,
      });
    } else if (formData.fundraiserType === "QUICK_DONATE") {
      this.setState({
        open: true,
        editFormData: formData,
      });
    }
  };

  updateData = async () => {
    // grab forms data
    const formsRequestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: this.state.jwtToken,
      },
    };

    const formsURL = process.env.REACT_APP_FORMS_URL;

    let formsResponse = await fetch(formsURL, formsRequestOptions);
    let formsData = await formsResponse.json();
    console.log(formsData);

    // Filter the formsData to exclude objects with fundraiserType === 'EVENT'
    // formsData = formsData.filter((formData) => formData.fundraiserType !== "");
    // formsData = formsData.filter(
    //   (formData) => formData.fundraiserType !== undefined
    // );
    // formsData = formsData.filter(
    //   (formData) => formDataraiserType !== "EVENT"
    // );

    // create a map of formname string to states from formsdata
    const formsActive = new Map();
    // create a map of formname string to fundraiserType from formsdata
    const formsType = new Map();
    // create map of formName string to set of active funds
    const activeFunds = new Map();

    const fundraiserToDonationsMap = {}; // we will use this to calculate the total number of donations for each fundraiser

    // filter out events
    formsData = formsData.filter((form) => form.elementType !== "EVENT");
    console.log(
      "updatedFormsData",
      formsData.filter((form) => form.formName.includes("Mubarrat"))
    );

    formsData.forEach((form) => {
      const key = form.formName;
      console.log("key", key);
      formsActive.set(key, form.active);
      formsType.set(key, form.fundraiserType);
      if (!activeFunds.has(key)) {
        activeFunds.set(key, new Set());
      }
      form["funds"].forEach((fund) => {
        activeFunds.get(key).add(fund);
      });
    });

    // console.log(activeFunds)

    // grab transactions data
    const requestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: this.state.jwtToken,
      },

      body: JSON.stringify({
        timerange: this.state.timerange,
      }),
    };

    const url = process.env.REACT_APP_DONATIONS_URL;
    let res = [];
    let lastEvaluatedKeys = {};
    while (true) {
      // fetch data
      let response = await fetch(url, requestOptions);
      // get response in json
      let response_json = await response.json();
      // decompress data
      let decompressed_data = decompressData(response_json);
      console.log("DECOMPRESSED DATA", decompressed_data);
      // extend res to include decompressed_data['transactions']
      res = res.concat(decompressed_data["transactions"]);
      lastEvaluatedKeys = decompressed_data["lastEvaluatedKeys"];

      // break if lastEvaluatedKeys is an object of size 0
      if (
        lastEvaluatedKeys === undefined ||
        lastEvaluatedKeys === null ||
        Object.keys(lastEvaluatedKeys).length === 0
      ) {
        console.log("no more last evaluated key");
        break;
      }

      console.log("has lastEvaluatedKeys", lastEvaluatedKeys);
      // update body to include lastEvaluatedKey
      requestOptions.body = JSON.stringify({
        timerange: this.state.timerange,
        lastEvaluatedKeys: lastEvaluatedKeys,
      });
    }
    let totalDonors = new Set();
    let grossAmountVariable = 0;
    // let totalDonationSum = 0 // = 0.0;
    // let totalDonations = 0
    let totalDonorValue = 0;

    for (let index = 0; index < res.length; index++) {
      res[index].grossAmountVar = res[index].grossAmount;
    }

    let quantity = 0;

    //Table rows with calculated gross donations from each unique email
    let rows = [];
    const fundMap = new Map();
    res.map((donation) => {
      if (
        donation.formName === "Internal Donation" ||
        formsType.get(donation.formName) === "EVENT" ||
        (donation.tickets !== undefined &&
          donation.tickets !== null &&
          donation.tickets.length > 0)
      ) {
        // skip
        return;
      }

      // ---- Logic for calculating total number of donations for each fundraiser START----
      let funds = donation.funds ?? [];
      if (
        donation.state === "SUCCEEDED" ||
        donation.state === "PARTIAL_REFUND"
      ) {
        if (funds.length == 0) {
          // handle single fund use case
          const fundraiser = donation.formName;
          if (fundraiser in fundraiserToDonationsMap) {
            fundraiserToDonationsMap[fundraiser] += 1;
          }
        } else {
          // Create a set of unique fundraisers based on fund.fundraiser in funds
          // for each item in the set, increment fundraiserToDonationsMap by 1 for that item
          // fundraiserToDonationsMap = {fundraiser1: 1, fundraiser2: 2, fundraiser3: fundraiser3}
          let uniqueFundraisers = new Set();
          funds.forEach((fund) => {
            uniqueFundraisers.add(fund.fundraiser);
          });
          uniqueFundraisers.forEach((fundraiser) => {
            if (fundraiser in fundraiserToDonationsMap) {
              fundraiserToDonationsMap[fundraiser] += 1;
            } else {
              fundraiserToDonationsMap[fundraiser] = 1;
            }
          });
        }
      }

      // ---- Logic for calculating total number of donations for each fundraiser END ----

      // if funds is a list greater than 1, iterate through the list
      if (
        donation.funds !== undefined &&
        donation.funds !== null &&
        donation.funds.length > 0
      ) {
        // do a for each loop on the funds list
        donation.funds.forEach((fund) => {
          quantity += parseInt(fund.quantity);
          let grossAmountVariable =
            parseFloat(fund.amount) * parseInt(fund.quantity);
          const name = donation.name.toLowerCase();
          const email = donation.email.toLowerCase();

          let key = fund.fundraiser;

          if (donation.state == "SUCCEEDED") {
            totalDonors.add(name + " | " + email);
            if (fundMap.has(key)) {
              totalDonorValue += 1;
              //     console.log("checking value of totalDonationNumberCounter", fundMap.get(key)["totalDonationNumberCounter"])
              fundMap.get(key)["totalDonationNumberCounter"] += parseInt(
                fund.quantity
              );
              fundMap.get(key)["grossAmount"] += grossAmountVariable;
            } else {
              //    console.log('new key', donation)
              if (activeFunds.get(key) === undefined) {
                console.log("key undefined", key);
              }
              fundMap.set(key, {
                formName: fund.fundraiser,
                grossAmount: grossAmountVariable,
                totalDonationNumberCounter: parseInt(fund.quantity),
                funds: activeFunds.get(key) ?? [],
                nonprofitName: donation.merchantName,
                active: formsActive.get(key),
                fundraiserType: formsType.get(key),
              });
            }
          }
          if (donation.state == "FULL_REFUND") {
            console.log("in full refund donation funds");
            //     quantity -= 1;
            totalDonors.add(name + " | " + email);
            let totalRefunded = donation.refunded.reduce((sum, item) => {
              if (item.name !== "Fee") {
                sum += parseFloat(item.refundAmount) || 0;
              }
              return sum;
            }, 0);
            console.log("new totalRefunded", totalRefunded);

            grossAmountVariable = grossAmountVariable - totalRefunded;
            console.log(
              "FULL REFUND new grossAmountVariable",
              grossAmountVariable
            );

            if (fundMap.has(key)) {
              console.log(
                "fundmap has key",
                donation.refunded,
                grossAmountVariable
              );
              totalDonorValue += 1;
              fundMap.get(key)["totalDonationNumberCounter"] -= parseInt(
                fund.quantity
              );
            } else {
              if (activeFunds.get(key) === undefined) {
                console.log("key undefined", key);
              }
              console.log("in full refund donation funds else ");

              fundMap.set(key, {
                formName: donation.formName,
                grossAmount: 0,
                totalDonationNumberCounter: 1,
                funds: activeFunds.get(key),
                nonprofitName: donation.merchantName,
                active: formsActive.get(key),
                fundraiserType: formsType.get(key),
              });
            }
          }

          if (donation.state == "PARTIAL_REFUND") {
            console.log("in partial refund donation funds");
            quantity += 1;
            totalDonors.add(name + " | " + email);

            let totalRefunded = donation.refunded.reduce((sum, item) => {
              if (
                item.name === fund.fundName &&
                item.refundAmount === fund.amount &&
                item.fundraiser === fund.fundraiser &&
                item.frequency == fund.frequency
              ) {
                sum += parseFloat(item.refundAmount) || 0;
              }
              return sum;
            }, 0);
            console.log(
              "old grossAmountVariable",
              grossAmountVariable,
              totalRefunded,
              grossAmountVariable - totalRefunded
            );
            grossAmountVariable = grossAmountVariable - totalRefunded;
            console.log("new grossAmountVariable", grossAmountVariable);

            if (fundMap.has(key)) {
              totalDonorValue += 1;
              console.log(
                "fundmap has key",
                donation.refunded,
                grossAmountVariable
              );
              console.log("donation in partial refund if statement", fund);
              fundMap.get(key)["totalDonationNumberCounter"] += 1;
              fundMap.get(key)["grossAmount"] += grossAmountVariable;
            } else {
              //    console.log('new key', donation)
              if (activeFunds.get(key) === undefined) {
                console.log("key undefined", key);
              }
              console.log("donation in partial refund else statement", fund);
              fundMap.set(key, {
                formName: donation.formName,
                grossAmount: grossAmountVariable,
                totalDonationNumberCounter: 1,
                funds: activeFunds.get(key),
                nonprofitName: donation.merchantName,
                active: formsActive.get(key),
                fundraiserType: formsType.get(key),
              });
            }
          }
        });
      }
      // single fund only
      else {
        let grossAmountVariable = parseFloat(donation.grossAmountVar);
        const amount = parseFloat(donation.amount).toFixed(2);
        const name = donation.name.toLowerCase();
        const email = donation.email.toLowerCase();
        let key = donation.formName;
        const monthlyDonation = donation.donationFrequency;
        if (donation.state == "SUCCEEDED") {
          quantity += 1;
          totalDonors.add(name + " | " + email);

          if (fundMap.has(key)) {
            totalDonorValue += 1;
            fundMap.get(key)["totalDonationNumberCounter"] += 1;
            fundMap.get(key)["grossAmount"] += grossAmountVariable;
          } else {
            //    console.log('new key', donation)
            if (activeFunds.get(key) === undefined) {
              console.log("key undefined", key);
            }
            fundMap.set(key, {
              formName: donation.formName,
              grossAmount: grossAmountVariable,
              totalDonationNumberCounter: 1,
              funds: activeFunds.get(key),
              nonprofitName: donation.merchantName,
              active: formsActive.get(key),
              fundraiserType: formsType.get(key),
            });
          }
        }
        if (donation.state == "FULL_REFUND") {
          console.log("in full refund donation single fund");
          quantity -= 1;
          totalDonors.add(name + " | " + email);
          console.log("FULL REFUND TRANSACTION", donation);

          let totalRefunded = donation.refunded.reduce((sum, item) => {
            if (item.name !== "Fee") {
              sum += parseFloat(item.refundAmount) || 0;
            }
            return sum;
          }, 0);
          console.log("total refunded ", totalRefunded);
          grossAmountVariable = grossAmountVariable - totalRefunded;
          console.log("new grossAmountVariable", grossAmountVariable);

          if (fundMap.has(key)) {
            console.log(
              "fundmap has key",
              donation.refunded,
              grossAmountVariable
            );
            totalDonorValue += 1;
          } else {
            if (activeFunds.get(key) === undefined) {
              console.log("key undefined", key);
            }
            console.log("in full refund donation single fund else");

            fundMap.set(key, {
              formName: donation.formName,
              grossAmount: grossAmountVariable,
              totalDonationNumberCounter: 0,
              funds: activeFunds.get(key),
              nonprofitName: donation.merchantName,
              active: formsActive.get(key),
              fundraiserType: formsType.get(key),
            });
          }
        }

        if (donation.state == "PARTIAL_REFUND") {
          console.log("in partial refund donation single fund");
          quantity += 1;
          totalDonors.add(name + " | " + email);

          let totalRefunded = donation.refunded.reduce((sum, item) => {
            if (item.name !== "Fee") {
              sum += parseFloat(item.refundAmount) || 0;
            }
            return sum;
          }, 0);
          console.log(
            "old grossAmountVariable",
            grossAmountVariable,
            totalRefunded,
            grossAmountVariable - totalRefunded
          );
          grossAmountVariable = grossAmountVariable - totalRefunded;
          console.log("new grossAmountVariable", grossAmountVariable);

          if (fundMap.has(key)) {
            totalDonorValue += 1;
            console.log(
              "fundmap has key",
              donation.refunded,
              grossAmountVariable
            );

            fundMap.get(key)["totalDonationNumberCounter"] += 1;
            fundMap.get(key)["grossAmount"] += grossAmountVariable;
          } else {
            //    console.log('new key', donation)
            if (activeFunds.get(key) === undefined) {
              console.log("key undefined", key);
            }
            fundMap.set(key, {
              formName: donation.formName,
              grossAmount: grossAmountVariable,
              totalDonationNumberCounter: 1,
              funds: activeFunds.get(key),
              nonprofitName: donation.merchantName,
              active: formsActive.get(key),
              fundraiserType: formsType.get(key),
            });
          }
        }
      }
    });
    console.log("quantity", quantity);
    let totalDonations = 0;
    let totalDonationSum = 0;
    for (const [key, value] of fundMap.entries()) {
      console.log(key, value);
      rows.push({
        formName: value["formName"],
        grossAmountVar: value["grossAmount"],
        totalDonationNumberCounter: value["totalDonationNumberCounter"],
        fund: Array.from(value["funds"]).join(", "),
        nonprofitName: value["nonprofitName"],
        active: value["active"],
        fundraiserType: value["fundraiserType"],
      });
      totalDonations += value["totalDonationNumberCounter"];
      totalDonationSum += value["grossAmount"];
    }

    // Create empty entries for forms without donations
    for (let i = 0; i < formsData.length; i++) {
      if (
        formsData[i].formName === "Internal Donation" ||
        formsData[i].formType === "EVENT"
      ) {
        // skip
        continue;
      }
      let key = formsData[i].formName;
      if (key == "Nueces Mosque - Donate Page") {
        console.log("NUECES", {
          formName: formsData[i].formName,
          grossAmountVar: 0,
          totalDonationNumberCounter: 0,
          fund: formsData[i].funds.join(", "),
          nonprofitName: formsData[i].nonprofitName,
          active: formsData[i].active,
          fundraiserType: formsData[i].fundraiserType,
        });
      }
      if (!fundMap.has(key)) {
        rows.push({
          formName: formsData[i].formName,
          grossAmountVar: 0,
          totalDonationNumberCounter: 0,
          fund: formsData[i].funds.join(", "),
          nonprofitName: formsData[i].nonprofitName,
          active: formsData[i].active,
          fundraiserType: formsData[i].fundraiserType,
        });
      }
    }

    rows.sort((a, b) => {
      if (a.active == b.active) {
        return a.formName.toLowerCase() < b.formName.toLowerCase() ? 1 : -1;
      }
    });
    rows.sort((a, b) => (a.active < b.active ? 1 : -1));

    let end = 20;
    if (formsData.length < 20) {
      end = formsData.length;
    }

    // ---- Logic for calculating total number of donations for each fundraiser START----
    // update rows.totalDonationNumberCounter for each row using what we found in fundraiserToDonationsMap
    rows.forEach((row) => {
      const fundraiser = row.formName;
      if (fundraiser in fundraiserToDonationsMap) {
        row.totalDonationNumberCounter = fundraiserToDonationsMap[fundraiser];
      }
    });
    // ---- Logic for calculating total number of donations for each fundraiser END ----

    // calculate scorecard for Total Donations
    let totalDonationCount = res.filter(
      (donation) =>
        (donation.state === "SUCCEEDED" ||
          donation.state === "PARTIAL_REFUND") &&
        donation.formName !== "Internal Donation" &&
        (donation.tickets === undefined ||
          donation.tickets === null ||
          donation.tickets.length === 0)
    ).length;

    // number of active forms
    const totalActiveForms = rows.filter((row) => row.active).length;

    this.setState({
      donorsList: rows,
      start: 0,
      end: end,
      totalActiveForms: totalActiveForms,
      total: rows.length,
      totalDonors: totalDonors.size,
      grossAmountVar: grossAmountVariable,
      totalDonationSum: totalDonationSum,
      totalDonationNumberCounter: 1,
      totalDonations: totalDonationCount,
      formsData: formsData,
    });
  };

  goNext = (e) => {
    e.preventDefault();
    const { start, end, donorsList } = this.state;
    if (end !== donorsList.length) {
      this.setState({
        start: start + 20,
        end: Math.min(end + 20, donorsList.length),
      });
    }
  };

  goPrev = (e) => {
    e.preventDefault();
    const { start, end, total } = this.state;
    if (start !== 0) {
      this.setState({
        start: Math.max(start - 20, 0),
        end: start,
      });
    }
  };

  headers1 = [
    { label: "Form Name", key: "formName" },
    { label: "Form Type", key: "fundraiserType" },
    { label: "Fund", key: "fund" },
    { label: "Total Raised", key: "grossAmountVar" },
    { label: "Total Donations", key: "totalDonationNumberCounter" },
  ];

  render() {
    const {
      open,
      donorsList,
      start,
      end,
      total,
      showFilter,
      editFormData,
      openQuickDonation,
    } = this.state;
    const headers1 = this.headers1;
    let donationList1 = donorsList;

    let next = "btn bg-white border-gray-200 hover:border-gray-300 text-light";
    let prev = "btn bg-white border-gray-200 hover:border-gray-300 text-light";

    if (end === total) {
      next = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed";
    }
    if (start === 0) {
      prev = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed";
    }

    return (
      <div className="col-span-full xl:col-span-12">
        <EditFormSlideover
          grabSelectableFunds={this.props.grabSelectableFunds}
          open={open}
          setOpen={this.setOpen}
          selectableFunds={this.props.selectableFunds}
          selectableForms={this.props.selectableForms}
          editFormData={editFormData}
          updateData={this.updateData}
          merchant={this.props.merchant}
        />
        <div className="flex flex-row justify-end py-3 ">
          <div className="flex ">
            <button
              type="button "
              className="focus:outline-none inline-block flex focus:ring-2 focus:ring-offset-2 "
            >
              <CSVLink
                filename="CharityStack Forms Export.csv"
                data={donationList1}
                headers={headers1}
              >
                <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 17"
                    stroke="#a1afc2"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                  &nbsp;
                  <div className="text-gray-500 hover:text-gray-900 ">
                    {" "}
                    Export &nbsp;{" "}
                  </div>
                </div>
              </CSVLink>
            </button>
          </div>
        </div>

        {showFilter && (
          <div className="flex flex-row p-3">
            <DonationDatePicker setTimerange={this.setDateTimeRange} />
          </div>
        )}

        <div className="rounded-lg border border-[#D1D9E0] bg-white p-3">
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="w-full table-auto ">
              {/* Table header */}
              <thead className="rounded-sm bg-gray-50 text-xs uppercase text-gray-400">
                <tr>
                  <th className="p-2">
                    <div className="text-left font-semibold">Fundraisers</div>
                  </th>
                  <th className="p-2">
                    <div className="text-left font-semibold">Funds</div>
                  </th>
                  <th className="p-2 ">
                    <div className="text-center font-semibold">Type</div>
                  </th>
                  <th className="p-2">
                    <div className="text-center font-semibold">Donations</div>
                  </th>
                  <th className="p-2">
                    <div className="text-center font-semibold">Amount</div>
                  </th>
                  <th className="p-2">
                    <div className="text-center font-semibold">Status </div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="divide-y divide-gray-100 text-sm font-medium">
                {donorsList.slice(start, end).map((donor, index) => {
                  // donor.grossAmount = (donor.grossAmount).toLocaleString(undefined, { maximumFractionDigits: 2 })
                  const id = donor.nonprofitName + " | " + donor.formName;

                  let elementTypeColumn =
                    fundraiserTypeMap[donor.fundraiserType];

                  if (
                    elementTypeColumn !== undefined &&
                    elementTypeColumn !== ""
                  ) {
                    //   console.log(elementTypeColumn);
                    elementTypeColumn = elementTypeColumn
                      .replace(/_/g, " ") // Replace underscores with spaces
                      .toLowerCase() // Convert to lowercase
                      .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word
                  }

                  let status = donor.active == true ? "Active" : "Deactivated";
                  let statusColor =
                    donor.active == true
                      ? "px-3 text-center text-green-800 bg-green-100 rounded-full"
                      : "px-3 text-center text-red-800 bg-red-200 rounded-full";

                  return (
                    <tr
                      className="cursor-pointer hover:bg-gray-50"
                      key={id}
                      onClick={() => this.onEditHandler(id)}
                    >
                      <td
                        className="p-2"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <div className="items-center">
                          <div className="text-left text-sm">
                            <a className="font-medium text-gray-600">
                              {donor.formName}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td className="p-2">
                        <div
                          className="items-center"
                          style={{ textAlign: "left" }}
                        >
                          <div className="text-s text-left">{donor.fund}</div>
                        </div>
                      </td>
                      <td
                        className="p-2"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <div className="items-center">
                          <div className="text-s text-left">
                            {elementTypeColumn}
                          </div>
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="items-center text-center">
                          <button className="text-center">
                            {donor.totalDonationNumberCounter
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </button>
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="items-center text-center">
                          <div className="text-center">
                            $
                            {(
                              Math.round(
                                donor.grossAmountVar * Math.pow(10, 2)
                              ) / Math.pow(10, 2)
                            )
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </div>
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="items-center text-center">
                          <button className={statusColor}>{status}</button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
                {}
              </tbody>
            </table>
            <div className="mt-8">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <nav
                  className="mb-4 sm:order-1"
                  role="navigation"
                  aria-label="Navigation"
                >
                  <ul className="flex justify-center">
                    <li className="ml-3 first:ml-0">
                      <a className={prev} href="#0" onClick={this.goPrev}>
                        &lt;- Previous
                      </a>
                    </li>
                    <li className="ml-3 first:ml-0">
                      <a className={next} href="#0" onClick={this.goNext}>
                        Next -&gt;
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="text-center text-sm text-gray-500 sm:text-left">
                  Showing{" "}
                  <span className="font-medium text-gray-600">{start}</span> to{" "}
                  <span className="font-medium text-gray-600">{end}</span> of{" "}
                  <span className="font-medium text-gray-600">{total}</span>{" "}
                  results
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormsTable;
