/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Dialog, Transition } from "@headlessui/react";
import {
  CreditCardIcon,
  BuildingLibraryIcon,
} from "@heroicons/react/24/outline";
import Card from "../partials/payment-methods/Card";
import BankTransfer from "../partials/payment-methods/BankTransfer";
import ErrorAlert from "../partials/alerts/ErrorAlert";
import ClipLoader from "react-spinners/ClipLoader";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import StripeCheckout from "./StripeCheckout";

export default function UpdatePayment(props) {
  const stripeCardRef = useRef(null);
  const {
    selectedSubscription,
    setOpen,
    updateSubscriptionData,
    setShowSuccessAlert,
    setSuccessMessage,
    merchant,
  } = props;
  const cancelButtonRef = useRef(null);

  const [jwtToken, setJWTToken] = useState(null);
  const [sendingData, setSendingData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  // Card Related
  const [cardSelected, setCardSelected] = useState(true);
  const [iframeStates, setIframeStates] = useState(null);
  const [form, setForm] = useState(null);

  // Bank Transfer Related
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountType, setAccountType] = useState("CHECKING");

  const [resetKey, setResetKey] = useState(0);
  const [zip, setZip] = useState("");

  const [stripePromise, setStripePromise] = useState(null);

  const callStripeCardPaymentFunction = () => {
    stripeCardRef.current.handleSubmit();
  };

  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      setJWTToken(data["idToken"]["jwtToken"]);

      setForm(
        window.PaymentForm.card((state, binInformation) => {
          if (state === undefined) {
            setIframeStates(state);
          } else {
            setIframeStates(state);
          }
        })
      );
    };
    myFunction();
  }, []);

  useEffect(() => {
    console.log("stripeAccountID", props.merchant);
    if (props.merchant && props.merchant.stripeAccountID) {
      console.log("stripePromise");
      setStripePromise(
        loadStripe(process.env.REACT_APP_STRIPE_PK, {
          stripeAccount: props.merchant.stripeAccountID,
        })
      );
    }
  }, [props.merchant]);

  useEffect(() => {
    const myFunction = async () => {
      setForm(
        window.PaymentForm.card((state, binInformation) => {
          if (state === undefined) {
            setIframeStates(state);
          } else {
            setIframeStates(state);
          }
        })
      );
      if (props.merchant.defaultPaymentProcessor === "STRIPE") {
        setCardSelected(true);
      }
      setErrorMessage("");
      setShowErrorAlert(false);
      setAccountNumber("");
      setRoutingNumber("");
      setAccountType("CHECKING");
    };
    myFunction();
  }, [props.open]);

  useEffect(() => {
    console.log("merchant is", merchant);
    if (merchant?.defaultPaymentProcessor === "STRIPE") {
      setCardSelected(true);
      // setStripeAccountID(merchant.stripeAccountID);
      console.log("stripe account id", merchant.stripeAccountID);
    }
  }, [merchant]);

  let netAmount = 0;
  let displayedFund = "";
  if (selectedSubscription != null) {
    netAmount = (
      (Math.round(selectedSubscription.amount - selectedSubscription.fees) *
        Math.pow(10, 2)) /
      Math.pow(10, 2)
    )
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    displayedFund = selectedSubscription.fund;
    if (
      selectedSubscription.funds !== null &&
      selectedSubscription.funds !== undefined &&
      selectedSubscription.funds.length > 0
    ) {
      displayedFund = selectedSubscription.funds
        .map((item) => item.fundName)
        .join(", ");
    }
  }

  const checkIframeErrors = () => {
    return (
      iframeStates.number.errorMessages.length == 0 &&
      iframeStates.expiration_date.errorMessages.length == 0 &&
      iframeStates.security_code.errorMessages.length == 0 &&
      iframeStates["address.postal_code"].errorMessages.length == 0
    );
  };

  const updateWithBankTransfer = async () => {
    setSendingData(true);
    const requestOptions = {
      method: "PATCH",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        accountNumber: accountNumber,
        routingNumber: routingNumber,
        accountType: accountType,
        paymentMethod: "PAY_BY_BANK",
        subscriptionID: selectedSubscription.id,
      }),
    };

    try {
      let response = await fetch(
        process.env.REACT_APP_DONORS_URL,
        requestOptions
      );
      let responseBody = await response.json();
      if (!response.ok) {
        setShowErrorAlert(true);
        setErrorMessage(responseBody);
        setSendingData(false);
      } else {
        updateSubscriptionData();
        setShowSuccessAlert(true);
        setSuccessMessage("Your payment method has been updated");
        setShowErrorAlert(false);
        setErrorMessage("");
        setOpen(false);
        setSendingData(false);
      }
    } catch (error) {
      setShowErrorAlert(true);
      setErrorMessage(error.message);
      setSendingData(false);
    }
  };

  const handleStripeSubmit = async (paymentMethod) => {
    setShowErrorAlert(false);
    setErrorMessage("");
    setSendingData(true);

    console.log("[paymentMethod]", paymentMethod);
    const tokenID = paymentMethod.id;
    const requestOptions = {
      method: "PATCH",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        token: tokenID,
        paymentMethod: "CARD",
        stripePaymentMethod: paymentMethod,
        subscriptionID: selectedSubscription.id,
      }),
    };

    try {
      let response = await fetch(
        process.env.REACT_APP_DONORS_URL,
        requestOptions
      );
      let responseBody = await response.json();
      if (!response.ok) {
        setShowErrorAlert(true);
        setErrorMessage(responseBody);
        setSendingData(false);
      } else {
        updateSubscriptionData();
        setShowSuccessAlert(true);
        setSuccessMessage("Your payment method has been updated");
        setShowErrorAlert(false);
        setErrorMessage("");
        setOpen(false);
        setSendingData(false);
      }
    } catch (error) {
      setShowErrorAlert(true);
      setErrorMessage("Something went wrong when submitting your request");
      setSendingData(false);
    }
  };
  const updateWithCard = () => {
    setSendingData(true);
    const iframesOkay = checkIframeErrors();
    if (iframesOkay === false) {
      setShowErrorAlert(true);
      setErrorMessage("Your card's information is incomplete");
      setSendingData(false);
    } else {
      setShowErrorAlert(false);
      setErrorMessage("");
      try {
        form.submit(
          process.env.REACT_APP_FINIX_ENVIRONMENT,
          process.env.REACT_APP_FINIX_APPLICATION_ID,
          async function (err, res) {
            if (err) {
              setShowErrorAlert(true);
              setErrorMessage(err);
              setSendingData(false);
              return;
            }

            const tokenID = res.data.id;

            const requestOptions = {
              method: "PATCH",
              headers: {
                accept: "*/*",
                "content-type": "application/json",
                Authorization: jwtToken,
              },
              body: JSON.stringify({
                token: tokenID,
                paymentMethod: "CARD",
                subscriptionID: selectedSubscription.id,
              }),
            };

            let response = await fetch(
              process.env.REACT_APP_DONORS_URL,
              requestOptions
            );
            let responseBody = await response.json();
            if (!response.ok) {
              setShowErrorAlert(true);
              setErrorMessage(responseBody);
              setSendingData(false);
            } else {
              updateSubscriptionData();
              setShowSuccessAlert(true);
              setSuccessMessage("Your payment method has been updated");
              setShowErrorAlert(false);
              setErrorMessage("");
              setOpen(false);
              setSendingData(false);
            }
          }
        );
      } catch (err) {
        setShowErrorAlert(true);
        setErrorMessage("Something went wrong when submitting your request");
        setSendingData(false);
      }
    }
  };

  const message = cardSelected
    ? "Enter the credit card details, and then click the update button below to update the payment method"
    : "Enter the bank transfer details, and then click the update button below to update the payment method";

  const cardVisibility = cardSelected ? {} : { display: "none" };
  const bankTransferVisibility = cardSelected ? { display: "none" } : {};

  return (
    <Transition.Root
      show={props.open}
      as={Fragment}
      unmount={props.slideoverOpen}
    >
      <Dialog
        as="div"
        className="relative z-20"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
        unmount={props.slideoverOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          unmount={props.slideoverOpen}
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              unmount={props.slideoverOpen}
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {showErrorAlert && (
                  <div className="mb-2">
                    <ErrorAlert
                      message={errorMessage}
                      setShowErrorAlert={setShowErrorAlert}
                    />
                  </div>
                )}
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <CreditCardIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <div className="">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Update Subscription
                      </Dialog.Title>
                      <div className="grid grid-cols-2">
                        <p className="text-primary col-span-1 mt-2 truncate text-sm font-medium">
                          ${netAmount} per month
                        </p>
                        <p className="col-span-1 mt-2 flex items-center text-sm text-gray-500">
                          <span className="truncate">
                            {displayedFund && displayedFund.includes(", ")
                              ? "Funds:"
                              : "Fund:"}{" "}
                            {displayedFund}
                          </span>
                        </p>
                      </div>
                    </div>
                    {/* {merchant?.defaultPaymentProcessor !== "STRIPE" && (
                      <div className="mt-2 flex justify-between">
                        <button
                          type="button"
                          name="Cancel"
                          className={`focus:outline-none ml-1 w-1/2 rounded-md border px-2 py-1 text-xs font-medium shadow-sm focus:ring-2 focus:ring-offset-2 ${
                            !cardSelected
                              ? "bg-primary hover:bg-secondary focus:ring-secondary border-transparent text-white"
                              : "text-secondary border-gray-300 bg-white hover:bg-gray-50 focus:ring-gray-400"
                          }`}
                          onClick={() => setCardSelected(false)}
                        >
                          Bank Transfer
                        </button>
                        <button
                          type="button"
                          name="Update"
                          className={`focus:outline-none ml-1 w-1/2 rounded-md border px-2 py-1 text-xs font-medium shadow-sm focus:ring-2 focus:ring-offset-2 ${
                            cardSelected
                              ? "bg-primary hover:bg-secondary focus:ring-secondary border-transparent text-white"
                              : "text-secondary border-gray-300 bg-white hover:bg-gray-50 focus:ring-gray-400"
                          }`}
                          onClick={() => setCardSelected(true)}
                        >
                          Card
                        </button>
                      </div>
                    )} */}
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{message}</p>
                    </div>
                    {merchant?.defaultPaymentProcessor !== "STRIPE" && (
                      <div className="mt-2" style={bankTransferVisibility}>
                        <BankTransfer
                          accountNumber={accountNumber}
                          setAccountNumber={setAccountNumber}
                          routingNumber={routingNumber}
                          setRoutingNumber={setRoutingNumber}
                          accountType={accountType}
                          setAccountType={setAccountType}
                        />
                      </div>
                    )}
                    <div className="mt-2" style={cardVisibility}>
                      {merchant?.defaultPaymentProcessor === "STRIPE" &&
                      stripePromise ? (
                        <Elements stripe={stripePromise}>
                          <StripeCheckout
                            ref={stripeCardRef}
                            setErrorMessage={setErrorMessage}
                            setShowErrorAlert={setShowErrorAlert}
                            setSendingData={setSendingData}
                            defaultPaymentProcessor={"STRIPE"}
                            // processStripeCard={}
                            processStripeCard={handleStripeSubmit}
                            stripeAccountID={merchant.stripeAccountID}
                          />
                        </Elements>
                      ) : (
                        form !== null && (
                          <Card updateWithCard={updateWithCard} form={form} />
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {sendingData && (
                    <button
                      type="button"
                      name="deactivate"
                      className="bg-secondary hover:bg-secondary focus:outline-none focus:ring-secondary pointer-events-none inline-flex w-1/5 justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:text-sm"
                    >
                      <ClipLoader
                        color={"#FFFFFF"}
                        loading={sendingData}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </button>
                  )}
                  {!sendingData && (
                    <button
                      type="button"
                      name="deactivate"
                      className="bg-primary hover:bg-secondary focus:outline-none focus:ring-secondary inline-flex w-1/5 justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:text-sm"
                      onClick={(e) => {
                        if (merchant?.defaultPaymentProcessor === "STRIPE") {
                          callStripeCardPaymentFunction();
                        } else if (cardSelected) {
                          updateWithCard();
                        } else {
                          updateWithBankTransfer();
                        }
                      }}
                    >
                      Update
                    </button>
                  )}

                  <button
                    type="button"
                    className="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
