import React, { useRef, useState, useEffect, useCallback } from 'react'
import Dropdown from '../../dropdowns/Dropdown';
import MultiSelectDropdown from '../../dropdowns/MultiSelectDropdown';
import Toggle from '../../Toggle'
import ReactTooltip from "react-tooltip";
import { Draggable } from 'react-beautiful-dnd';
import { Bars3Icon } from '@heroicons/react/20/solid'
import { TrashIcon } from '@heroicons/react/24/outline'

function CustomFAQsTabContent(props) {
    const { customFAQs, id, index, handleQuestionChange, handleAnswerChange, removeCustomFAQs } = props;

    return (
        <Draggable draggableId={id} index={index}>
            {(provided) => (
                <div
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    className="border-b border-gray-300"
                >
                    <div {...provided.dragHandleProps}>
                        <Bars3Icon className="h-6 w-6 ml-auto mr-4 mt-2" aria-hidden="true" />
                    </div>
                    <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <label
                            htmlFor="form-name"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                            Question*
                            <svg data-tip data-for='faqquestionTooltip' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="inline-block w-5 h-5 ml-1 text-primary">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                            </svg>
                            <ReactTooltip id='faqquestionTooltip' effect="solid" place='right'>
                                <p className="text-sm text-white">This is where you can customize the details of the </p>
                                <p className="text-sm text-white">question of the FAQ.</p>
                            </ReactTooltip>
                        </label>
                        <div className="flex space-x-10 sm:col-span-2 pt-2 text-sm">
                            <input
                                data-type="question"
                                data-index={index}
                                value={customFAQs.question}
                                placeholder={'e.g. How did you find our website?'}
                                type="text"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                onChange={(e) => handleQuestionChange(e)}
                                maxLength={50}
                            />
                        </div>
                    </div>

                    {/* New Field: Answer */}
                    <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <label
                            htmlFor="form-answer"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                            Answer*
                            <svg data-tip data-for='faqanswerTooltip' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="inline-block w-5 h-5 ml-1 text-primary">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                            </svg>
                            <ReactTooltip id='faqanswerTooltip' effect="solid" place='right'>
                                <p className="text-sm text-white">This is where you can customize the details of the </p>
                                <p className="text-sm text-white">answer to the FAQ.</p>
                            </ReactTooltip>
                        </label>
                        <div className="flex space-x-10 sm:col-span-2 pt-2 text-sm">
                            <textarea
                                data-type="answer"
                                data-index={index}
                                value={customFAQs.answer}
                                placeholder={'e.g. I found it through a search engine.'}
                                type="text"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                                onChange={(e) => handleAnswerChange(e.target.value, index)}
                                maxLength={200}
                            />

                        </div>
                    </div>
                    {/* Right-justified removal */}
                    <div className='flex justify-end mr-4 mb-2'>
                        <div className='flex cursor-pointer' data-index={index} onClick={(e) => removeCustomFAQs(index)}>
                            <TrashIcon data-index={index} className="h-5 w-5 mr-2 mt-0.5 text-red-500" aria-hidden="true" />
                            <div data-index={index} className='text-red-500'> remove custom FAQ </div>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
}

export default CustomFAQsTabContent;
