import React, { useEffect, useState } from 'react';


function ApiKeys(props) {
    const { publicKey, setPublicKey,
        privateKey, setPrivateKey, allowEdit } = props

    return (
        <div className="space-y-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:py-5 items-center">
            <div>
                <label
                    htmlFor="public-key"
                    className="flex text-sm font-medium text-gray-900 col-span-1"
                >
                    Public Key
                </label>
            </div>
            <div className="sm:col-span-2">
                <input
                    readOnly={!allowEdit}
                    value={publicKey}
                    onChange={(e) => setPublicKey(e.target.value)}
                    type="text"
                    name="public-key"
                    id="public-key"
                    disabled={!allowEdit}
                    autocomplete="do-not-autofill" // Picked this value b/c of https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
                    className={"block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"  + (allowEdit ? "" : " opacity-50 bg-gray-200")}
                />
            </div>
            <div>
                <label
                    htmlFor="private-key"
                    className="flex text-sm font-medium text-gray-900 col-span-1"
                >
                    Private Key
                </label>
            </div>
            <div className="sm:col-span-2">
                <input
                    readOnly={!allowEdit}
                    value={privateKey}
                    onChange={(e) => setPrivateKey(e.target.value)}
                    type="text"
                    name="private-key"
                    id="private-key"
                    disabled={!allowEdit}
                    autocomplete="do-not-autofill"
                    className={"block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm" + (allowEdit ? "" : " opacity-50 bg-gray-200")}
                />
            </div>
        </div>
    )
}

export default ApiKeys