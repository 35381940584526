import React from 'react';
import Flatpickr from 'react-flatpickr';

function DonationDatePicker(props) {

  const options = {
    inline: true,
    mode: 'range',
    static: false,
    monthSelectorType: 'static',
    dateFormat: 'M j, Y',
    defaultDate: [new Date().setDate(new Date().getDate() - 0), new Date()],
    prevArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    onReady: (selectedDates, dateStr, instance) => {
      instance.element.value = dateStr.replace('-', 'to');
    },
    onClose: (selectedDates, dateStr, instance) => {
      instance.element.value = dateStr.replace('to', '-');
      if (instance.element.value !== '') {
        props.setTimerange(instance.element.value)
      }
    },
  }

  return (
    <div className="text-center">
      <Flatpickr className="form-input flex justify-center text-center text-sm border p-1 rounded-lg shadow-sm text-gray-500 hover:text-gray-600 font-medium focus:border-gray-300 w-full" options={options} />
    </div>
  );
}

export default DonationDatePicker;
