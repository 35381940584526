import React, { useState, useEffect, useCallback } from "react";
import ReactTooltip from "react-tooltip";
import CustomTicketsTabContent from "./CustomTicketsTabContent";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { PlusIcon } from "@heroicons/react/20/solid";

const TICKET_TYPE = {
  Single: "SINGLE",
  Group: "GROUP",
};
function CustomTicketsTab(props) {
  const { customTickets, setCustomTickets } = props;

  // Name
  const handleNameChange = (event) => {
    let index = parseInt(event.target.dataset.index);
    let value = event.target.value;

    let newCustomTickets = [...customTickets];
    let newCustomTicket = newCustomTickets[index];
    newCustomTicket["name"] = value;
    setCustomTickets(newCustomTickets);
  };

  // Type
  const handleTypeChange = (event) => {
    let index = parseInt(event.target.dataset.index);
    let value = event.target.defaultValue;
    let newCustomTickets = [...customTickets];
    let newCustomTicket = newCustomTickets[index];
    newCustomTicket["type"] = value;
    // clear options if type is not a dropdown
    if (value !== "SINGLE") {
      newCustomTicket["seats"] = 0;
    } else {
      newCustomTicket["seats"] = 1;
    }

    setCustomTickets(newCustomTickets);
  };

  //price
  const handlePriceChange = (event) => {
    let index = parseInt(event.target.dataset.index);
    let value = event.target.value;

    // checking if value include a dec point
    if (value.match(/\./g)) {
      const [, decimal] = value.split(".");

      // restrict value to only 2 decimal places
      if (decimal?.length > 2) {
        // do nothing
        return;
      }
    }

    let newCustomTickets = [...customTickets];
    let newCustomTicket = newCustomTickets[index];
    newCustomTicket["price"] = parseFloat(value);
    setCustomTickets(newCustomTickets);
  };

  // Quantity
  const handleQuantityChange = (event) => {
    let index = parseInt(event.target.dataset.index);
    let value = event.target.value;

    let newCustomTickets = [...customTickets];
    let newCustomTicket = newCustomTickets[index];
    newCustomTicket["quantity"] = parseInt(value);
    setCustomTickets(newCustomTickets);
  };

  // Seats
  const handleSeatsChange = (seats) => {
    let index = parseInt(seats.target.dataset.index);
    let value = seats.target.value;

    let newCustomTickets = [...customTickets];
    let newCustomTicket = newCustomTickets[index];
    newCustomTicket["seats"] = parseInt(value);
    setCustomTickets(newCustomTickets);
  };

  // Add New Ticket
  const createNewCustomTicket = () => {
    let newCustomTickets = [...customTickets];
    newCustomTickets.push({
      id: Math.random().toString(36).substring(2, 9), // generates a random id
      name: "",
      price: 0,
      quantity: 0,
      type: "SINGLE",
      seats: 1,
      sold: 0,
      attendees: [],
    });
    setCustomTickets(newCustomTickets);
  };

  // Remove Ticket
  const removeCustomTicket = (index) => {
    let newCustomTickets = [...customTickets];
    newCustomTickets.splice(index, 1);
    setCustomTickets(newCustomTickets);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let newCustomTickets = [...customTickets];
    let newCustomTicket = newCustomTickets[source.index];
    newCustomTickets.splice(source.index, 1);
    newCustomTickets.splice(destination.index, 0, newCustomTicket);
    setCustomTickets(newCustomTickets);
    console.log(customTickets);
  };

  return (
    <>
      <div className="mb-16 space-y-6 py-6 sm:space-y-0 sm:py-0">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {customTickets.map((ticket, index) => (
                  <CustomTicketsTabContent
                    key={ticket.id}
                    id={ticket.id}
                    CustomTicket={ticket}
                    index={index}
                    handleNameChange={handleNameChange}
                    handleTypeChange={handleTypeChange}
                    handleSeatsChange={handleSeatsChange}
                    handlePriceChange={handlePriceChange}
                    handleQuantityChange={handleQuantityChange}
                    removeCustomTicket={removeCustomTicket}
                  />
                ))}
                {provided.placeholder}
                <div className="mr-4 mt-2 flex justify-end">
                  <div
                    className="flex cursor-pointer"
                    onClick={createNewCustomTicket}
                  >
                    <PlusIcon
                      className="mr-2 mt-0.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <div className="text-gray-400"> add ticket </div>
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
}

export default CustomTicketsTab;
