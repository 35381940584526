import pako from "pako";
export default function decompressData(encodedData) {
  // Decode the Base64 encoded data
  const compressedData = atob(encodedData);

  // Convert the string to a Uint8Array
  const compressedByteArray = new Uint8Array(compressedData.length);
  for (let i = 0; i < compressedData.length; i++) {
    compressedByteArray[i] = compressedData.charCodeAt(i);
  }

  // Decompress the data using pako library (for gzip)
  const decompressedData = pako.inflate(compressedByteArray, {
    to: "string",
  });
  console.log("decompressedData", decompressedData);
  // Parse the JSON string to get the original data
  const originalData = JSON.parse(decompressedData);
  return originalData;
}
