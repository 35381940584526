import { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Auth } from "aws-amplify";
import ReactTooltip from "react-tooltip";
import { XMarkIcon } from '@heroicons/react/24/outline'
import '../../css/searchgpt.css'
import Cursor from '../../utils/Cursor'

export default function SearchGPT(props) {
  const [jwtToken, setJWTToken] = useState('')
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const [awaitingResponse, setAwaitingResponse] = useState(false)
  const [needsToBeTyped, setNeedsToBeTyped] = useState(false)
  const [merchants, setMerchants] = useState([''])
  const [merchantName, setMerchantName] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [firstLetterOfName, setFirstLetterOfName] = useState('?')


  const clearStates = () => {
    // close slideover
    props.setOpen(false)
  }
  const sendMessage = async (e) => {
    e.preventDefault();
    const messagesCopy = [...messages]
    if (awaitingResponse) {
      return;
    }

    setAwaitingResponse(true)
    if (!inputValue) return;

    const newMessage = { text: inputValue, fromUser: true };
    setMessages([...messagesCopy, newMessage]);
    setInputValue('');

    // Call an async function that returns the bot message
    const botMessage = await getBotMessage(inputValue);
    console.log("botmessage: ", botMessage)
    // Update the state with the bot message
    const newBotMessage = { text: botMessage, fromUser: false };
    setMessages([...messagesCopy, newMessage, newBotMessage]);
    setAwaitingResponse(false)
    setNeedsToBeTyped(true)
  };

  async function getBotMessage(userInput) {
    const payload = {
      "query": userInput,
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'accept': '*/*', 'content-type': 'application/json', 'Authorization': jwtToken },
    };
    const response = await fetch(`${process.env.REACT_APP_SEARCH_GPT_URL}/dashboard-search-gpt`, requestOptions);
    // check response status code
    if (!response.ok) {
      return "Sorry, I'm having trouble understanding you. Please try wording your question differently."
    }
    const data = await response.json();
    return data
  }

  const clearChatHistory = async () => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'accept': '*/*', 'content-type': 'application/json', 'Authorization': jwtToken },
    };
    const response = await fetch(`${process.env.REACT_APP_SEARCH_GPT_URL}/dashboard-search-gpt`, requestOptions);
    setMessages([])
  }



  /* async function getBotMessage(userInput) {
   const response = await fetch('/chatbot', {
     method: 'POST',
     headers: { 'Content-Type': 'application/json' },
     body: JSON.stringify({ input: userInput })
   });
   const data = await response.json();
   return data.message;
 } */





  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession()
      const username = data.idToken.payload['cognito:username'];
      const firstLetter = username.charAt(0).toString().toUpperCase();

      setFirstLetterOfName(firstLetter)

      const formattedMerchants = data['idToken']['payload']['cognito:groups'].map(merchant => merchant.replace(/-/g, ' '))
      setJWTToken(data['idToken']['jwtToken'])
      setMerchants(formattedMerchants)
      setMerchantName(formattedMerchants[0])

      // grab chat history and store in state
      const requestOptions = {
        method: 'GET',
        headers: { 'accept': '*/*', 'content-type': 'application/json', 'Authorization': data['idToken']['jwtToken'] },
      };
      const response = await fetch(`${process.env.REACT_APP_SEARCH_GPT_URL}/dashboard-search-gpt`, requestOptions);
      const chatHistory = await response.json();
      let chatLog = []
      // for every item in responseData, create a new message object and push it to chatLog
      chatHistory.forEach(message => {
        const newMessage = { text: message['data']['content'], fromUser: message['type'] == 'human' ? true : false }
        chatLog.push(newMessage)
      })
      setMessages(chatLog)
    }
    myFunction()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100)
  }, [props.open])


  useEffect(() => {
    const myFunction = async () => {
      let auth = jwtToken
      if (props.merchant !== null) {
        if (auth === '') {
          const data = await Auth.currentSession()
          setJWTToken(data['idToken']['jwtToken'])
          auth = data['idToken']['jwtToken']
        }

        // grab double the donation api key if it exists
        const requestOptions = {
          method: 'GET',
          headers: { 'accept': '*/*', 'content-type': 'application/json', 'Authorization': auth },
        };

        const params = new URLSearchParams();
        params.append("nonprofit", props.merchant['merchantName']);
        const url = process.env.REACT_APP_CS_DTD_SERVER_URL + '/api-keys'

        const response = await fetch(url + `?${params.toString()}`, requestOptions);

        let responseData = await response.json()
        // console.log('responseData is', responseData['publicApiKey'])
      }
    }
    myFunction()
  }, [props.merchant])

  const calculateTextareaHeight = (rows) => {
    // Calculate the height of each row based on your styling
    const rowHeight = 20; // Adjust this value according to your styling
    // Set the maximum number of rows before implementing a scrollbar
    const maxRows = 8;

    // Calculate the maximum height
    const maxHeight = maxRows * rowHeight;

    // Calculate the current height
    const currentHeight = rows * rowHeight;

    // Return the smaller value between current height and maximum height
    return Math.min(currentHeight, maxHeight);
  };


  let submitButtonClass = 'justify-center py-1 px-1 text-sm font-medium text-gray-600 hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2'
  if (isDisabled) {
    submitButtonClass = 'justify-center py-1 px-1 text-sm font-medium text-gray-300 hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2'
  }
  const inputRef = useRef(null);
  const handleInput = () => {
    const textarea = inputRef.current;
    textarea.style.height = '40px'; // Reset the height to auto
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height based on the scrollHeight
  };
  return (
    <>
      <Transition.Root show={props.open} as="div">
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden z-50"
          open={props.open}
          onClose={props.setOpen}
        >

          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">

              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-lg font-medium text-gray-900">Magic Search (Alpha)</Dialog.Title>
                            <p className="text-sm text-gray-500">
                              Start a conversation with our AI-powered assistant. Ask it anything about your donation information or donors.
                            </p>

                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="text-gray-400 hover:text-gray-500"
                              onClick={clearStates}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>


                      {/* Messages */}
                      <form onSubmit={sendMessage}>

                        <div className="messages p-4">
                          {messages.map((message, index) => {
                            // check if it's the last message in the array and if it's from the bot
                            const typeOutMessage = index === messages.length - 1 && !message.fromUser && needsToBeTyped
                            if (typeOutMessage) {
                              return (
                                <div className='flex'>
                                  <div
                                    key={index}
                                    className={`message ${'fromBot'}`}
                                    data-initial={firstLetterOfName}
                                  >
                                    <Cursor setNeedsToBeTyped={setNeedsToBeTyped} text={message.text} />
                                  </div>
                                </div>
                              )
                            }
                            else {
                              return (
                                <div
                                  key={index}
                                  className={`message ${message.fromUser ? 'fromUser' : 'fromBot'}`}
                                  data-initial={firstLetterOfName}

                                >
                                  {message.text}
                                </div>
                              )
                            }
                          }
                          )}
                          {
                            awaitingResponse && (
                              // use tailwind to make the children elements of this div flex
                              <div className='flex'>
                                <div
                                  key={9999999}
                                  className={`message ${'fromBot'}`}
                                  data-initial={firstLetterOfName}
                                >
                                  <Cursor setNeedsToBeTyped={null} text={""} />
                                </div>
                              </div>
                            )
                          }

                        </div>
                      </form>

                    </div>

                    {
                      messages.length > 0 && !awaitingResponse && !needsToBeTyped && (
                        <button
                          type="button"
                          onClick={clearChatHistory}
                          className={`justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-primary shadow-sm  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 self-center w-full mt-2`}
                        >
                          Clear Chat History
                        </button>
                      )
                    }

                    <div className="border-t border-gray-200 p-4">

                      <div className="flex">
                        <div className="relative flex-grow">
                          <div className="flex shadow-xl shadow-inner">
                            <textarea
                              value={inputValue}
                              style={{
                                paddingRight: '25px',
                                height: '40px',
                                resize: 'none',
                                overflow: 'hidden', // Hide the scrollbar
                                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)'

                              }}

                              name="message"
                              onInput={handleInput}
                              onChange={(e) => setInputValue(e.target.value)}
                              className="block w-full rounded-md border-gray-300 shadow-sm px-4 py-2 focus:ring-secondary focus:border-secondary sm:text-sm"
                              placeholder="Type a message..."
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  sendMessage(e);
                                }
                              }}
                              ref={inputRef}
                            />
                            <button
                              style={{
                                position: 'absolute', top: 5, right: 0,
                                color: 'gray'
                              }}
                              type="button"
                              disabled={awaitingResponse}
                              className={`${submitButtonClass} ml-2`}
                              onClick={sendMessage}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                              </svg>

                            </button>

                          </div>
                          <div className='color-gray-100 shadow-sm text-xxs py-3 text-center'> Free Preview. CharityStack's Magic Search may produce inaccurate results about donors or donations. &nbsp;
                            <a href="https://support.charitystack.com/en/articles/7934319-magic-search" target="_blank" rel="noopener noreferrer" className="color-gray-200 underline shadow-sm text-xxs py-3 text-center">Learn more here.</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                </Dialog.Panel>

              </Transition.Child>

            </div>
          </div>
        </Dialog>
      </Transition.Root >
    </>
  );
}