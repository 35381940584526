import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { resizeFile } from "./utils"; // Create a separate utility file for image resizing
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

const ImageUploader = React.forwardRef((props, ref) => {
  const [sizeWrong, setSizeWrong] = useState(false);
  const [imageUrl, setImageUrl] = useState(props.image);
  const reader = new FileReader();

  useEffect(() => {
    if (
      props.image_type !== null &&
      props.image_type !== "" &&
      props.merchantName !== null &&
      props.merchantName !== "" &&
      props.elementID !== null &&
      props.elementID !== ""
    ) {
      setImageUrl(
        `${process.env.REACT_APP_IMAGE_URL}/${props.image_type}/${
          props.merchantName
        }_${props.elementID}.png?timestamp=${Date.now().toString()}`
      );
    }
  }, [props.image_type, props.merchantName, props.elementID]);

  const handleUpload = async (e) => {
    if (e.target.files !== undefined && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      const imageLoadedPromise = new Promise((resolve, reject) => {
        let img = document.createElement("img");
        img.src = URL.createObjectURL(file);

        img.onload = function () {
          console.log({ width: img.width, height: img.height });
          // Check if the file size is greater than 6MB
          if (file.size > 6 * 1048576) {
            props.setErrorMessage("Upload Image with size less than 6MB");
            props.setShowErrorAlert(true);
            setSizeWrong(true);
            console.log("rejecting due to file size");
            reject("File size is too large");
          } else if (
            props.desiredHeight > img.height ||
            props.desiredWidth > img.width
          ) {
            props.setErrorMessage(
              `Upload Image with dimensions of ${props.desiredWidth} x ${props.desiredHeight}`
            );
            props.setShowErrorAlert(true);
            setSizeWrong(true);
            console.log("rejecting");
            reject();
          } else {
            setSizeWrong(false);
            props.setErrorMessage(``);
            props.setShowErrorAlert(false);
            console.log("resolving");
            resolve();
          }
        };
      });

      try {
        const message = await imageLoadedPromise;
        console.log("helloooooo", message);

        const resizeImage = file;

        let imgs = URL.createObjectURL(resizeImage);
        props.setImage(imgs);

        if (resizeImage) {
          reader.readAsDataURL(resizeImage);
        }

        reader.onloadend = () => {
          // `reader.result` contains the Base64 representation of the image
          const base64String = reader.result;
          props.setResizedImage(base64String);
        };

        setImageUrl(imgs);
      } catch (error) {
        // dimensions are wrong, so skip the rest of the function
        console.log(error);
      }
    }
  };

  return (
    <div className="sm:col-span-2">
      <Box sx={{}} className="mt-1 rounded-md border border-gray-300 shadow-sm">
        <Stack
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          height={90}
        >
          {imageUrl == null ? (
            <div className="ml-10 flex h-10 w-10 items-center justify-center rounded-md border border-dashed border-gray-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <img
                src={`${imageUrl}`}
                alt="HostedImg"
                onError={() => {
                  setImageUrl(null);
                }}
                style={{ width: 150, height: 70 }}
                className="rounded-md"
              />
            </div>
          )}

          {imageUrl == null ? (
            <div className="">
              <Button variant="outlined" component="label">
                Upload File
                <input
                  type="file"
                  hidden
                  onChange={handleUpload}
                  accept=".png, .jpg, .jpeg"
                />
              </Button>
            </div>
          ) : (
            <div className="">
              <Button
                variant="outlined"
                component="label"
                onClick={handleUpload}
              >
                Replace File
                <input
                  type="file"
                  hidden
                  onChange={handleUpload}
                  accept=".png, .jpg, .jpeg"
                />
              </Button>
            </div>
          )}
        </Stack>
      </Box>
    </div>
  );
});

export default ImageUploader;
