import { Auth } from "aws-amplify";
import decompressData from "./decompressData";
import pako from "pako";

async function fetchTransactionData(timerange) {
  const data = await Auth.currentSession();
  const jwtToken = data["idToken"]["jwtToken"];
  const requestOptions = {
    method: "POST",
    headers: {
      accept: "*/*",
      "content-type": "application/json",
      Authorization: jwtToken,
    },

    body: JSON.stringify({
      timerange: timerange,
    }),
  };

  const url = process.env.REACT_APP_DONATIONS_URL;
  return fetch(url, requestOptions);
  // .then(async (response) => {
  // response.json().then((res) => {console.log('response is' + res); return res})
  // const result = await response.json()
  // return result
  // })
}

export default fetchTransactionData;
