/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { LinkIcon } from "@heroicons/react/20/solid";
import { SketchPicker, TwitterPicker } from "react-color";
import Toggle from "../Toggle";
import Dropdown from "../dropdowns/Dropdown";
import MultiSelectDropdown from "../dropdowns/MultiSelectDropdown";
import { Auth } from "aws-amplify";
import ReactTooltip from "react-tooltip";
import SuccessAlert from "../alerts/SuccessAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import Checkbox from "../Checkbox";
import { useHistory } from "react-router-dom";
import CustomInputsTab_Events from "./tabs/CustomInputsTab_Events";
import CustomFAQsTab from "../slideovers/tabs/CustomFAQsTab";
import CustomTicketsTab from "../slideovers/tabs/CustomTicketsTab";

import CustomPromoTab from "./tabs/CustomPromoTab";
import ImageUploader from "./../uploads/ImageUploader";

import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const HOSTED_PAGE_URL =
  process.env.REACT_APP_ENV === "production"
    ? `https://event.charitystack.com`
    : `https://dev.d3g3mvdffm1bm1.amplifyapp.com`;

const tabs = [
  { name: "Options", href: "#Options", current: true },
  { name: "Tickets", href: "#tickets", current: false },
  { name: "Promo Codes", href: "#promoCodes", current: false },
  { name: "Custom Inputs", href: "#customInputs", current: false },
  { name: "FAQs", href: "#faqs", current: false },
  { name: "Tax Receipts", href: "#tax", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`.toUpperCase();
  } else {
    return hexColor.toUpperCase();
  }
};

const countries = [
  "United States",
  "Canada",
  "United Kingdom",
  "Australia",
  "France",
  "Lebanon",
  "Kuwait",
];

export default function CreateEventSlideover(props) {
  const [jwtToken, setJWTToken] = useState("");
  const [formName, setFormName] = useState("");
  const [elementID, setElementID] = useState("");
  const [merchantName, setMerchantName] = useState("");
  const fileUploaderRef = useRef(null);
  const [resizedImage, setResizedImage] = useState(null);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    let markup = draftToHtml(rawContentState);
    console.log("markup", markup);
    // if markup is empty, set it to an empty string
    if (markup.trim() === "<p></p>") {
      markup = "";
    }
    // replace all <p></p> with <br>
    markup = markup.replace(/<p><\/p>/g, "<br>");
    console.log("markup2", markup);
    setConvertedContent(markup);
  }, [editorState]);

  const [activeTab, setActiveTab] = useState({
    name: "Options",
    current: true,
  });

  // Required Fields
  const [requirePhoneNumber, setRequirePhoneNumber] = useState(false);
  const [requireScheduleDonations, setRequireScheduleDonations] =
    useState(false);
  const [image, setImage] = useState(null);
  const [requireBillingAddress, setRequireBillingAddress] = useState(false);

  const [requireCountryDropdown, setRequireCountryDropdown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    "Choose Default Country"
  );

  // Color
  const [color, setColor] = useState("");
  const [sketchPickerColor, setSketchPickerColor] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const { r, g, b, a } = sketchPickerColor;
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  // Alerts
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [createdForm, setCreatedForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [copiedState, setCopiedState] = useState("Copied!");

  // Custom Inputs
  const [customInputs, setCustomInputs] = useState([]);
  const [originalCustomInputs, setOriginalCustomInputs] = useState([]);

  // Custom Tickets
  const [customTickets, setCustomTickets] = useState([]);

  // Custom Promos
  const [customPromos, setCustomPromos] = useState([]);

  // Custom FAQs
  const [customFAQs, setCustomFAQs] = useState([]);
  const [originalCustomFAQs, setOriginalCustomFAQs] = useState([]);
  const [imageUploadError, setImageUploadError] = useState(false);
  // Tax Receipts
  const [emailCustomization, setEmailCustomization] = useState(false);
  const [replyToEmailAddress, replyToEmailAddressChange] = useState("");
  const [subject, subjectChange] = useState("");
  const [emailMessage, emailMessageChange] = useState("");

  const variableClick = (e) => {
    let currentValueOfCustomMessage = emailMessage;
    //this adds a space charecter when the variable button list is clicked
    let newValueOfCustomMessage = currentValueOfCustomMessage + " " + e;

    emailMessageChange(newValueOfCustomMessage);
  };
  function convertFieldsToStrings(data) {
    return data.map((item) => {
      const newItem = { ...item };
      for (const key in newItem) {
        if (
          ["price", "sold", "quantity", "seats"].includes(key) &&
          typeof newItem[key] !== "string"
        ) {
          newItem[key] = newItem[key].toString();
        }
      }
      return newItem;
    });
  }

  const copyToClipboard = () => {
    const url = `${HOSTED_PAGE_URL}/${elementID}`;
    navigator.clipboard.writeText(url);
  };

  // internal functions
  const checkFormErrors = () => {
    if (imageUploadError === true) {
      return "Image Upload Error";
    }

    if (formName === "") {
      return "Event Name is required";
    }
    if (color.length != 7) {
      return "Color is required";
    }

    //iterate through customInputs and check for errors
    for (let i = 0; i < customInputs.length; i++) {
      if (customInputs[i].title == "") {
        return "Custom Input Label is required";
      }
      if (customInputs[i].type == "Select an Input Type") {
        return "Custom Input Type is required";
      }
      if (
        (customInputs[i].type == "SINGLE_SELECTION_DROPDOWN" ||
          customInputs[i].type == "SINGLE_SELECTION_DROPDOWN") &&
        customInputs[i].options.length < 2
      ) {
        return "2 or more Custom Input Options are required for Dropdowns";
      }
    }

    //iterate through customTickets and check for errors
    if (customTickets.length == 0) {
      return "At least one ticket must be made before creating an event.";
    }

    const seen_ticket_names = new Set();
    for (let i = 0; i < customTickets.length; i++) {
      var ticket = customTickets[i];
      if (seen_ticket_names.has(ticket.name)) {
        return "Ticket names must be unique";
      } else {
        seen_ticket_names.add(ticket.name);
      }
      if (ticket.name == "") {
        return "Ticket Name is required";
      }
      if (ticket.type == "Select an ticket Type") {
        return "Ticket Type is required";
      }
      if (ticket.type.toLowerCase() == "group") {
        if (ticket.seats == 1) {
          return "Invalid seat selection for ticket type";
        }
        if (ticket.seats == 0) {
          return "Seats cannot be 0";
        }
      }

      if (ticket.price == null) {
        return "Ticket price is required";
      }
      if (ticket.quantity == 0 || ticket.quantity == null) {
        return "Quantity is required";
      }
    }

    for (let i = 0; i < customPromos.length; i++) {
      var promo = customPromos[i];
      if (promo.codeName == "") {
        return "Promo Code Name is required";
      }
      if (promo.codeType == "Select an promo code Type") {
        return "Promo Code Type is required";
      }

      if (promo.codeAmount == null) {
        return "Promo Code Amount is required";
      }
      if (promo.tickets.length == 0) {
        return "Tickets are required for each promo";
      }

      if (promo.codeAmount > 100 && promo.codeType === "PERCENTAGE") {
        return "Promo Code Amount cannot be more than 100%";
      }
    }

    for (let i = 0; i < customFAQs.length; i++) {
      if (customFAQs[i].question == "" || customFAQs[i].answer === "") {
        return "FAQ details are required";
      }
    }

    const errorMessages = validatePromoCodes(customPromos, customTickets);

    if (errorMessages.length > 0) {
      console.log("more than 0 errors");
      return errorMessages[0];
    }

    for (let i = 0; i < customFAQs.length; i++) {
      if (customFAQs[i].question == "" || customFAQs[i].answer === "") {
        return "FAQ details are required";
      }
    }
    return "";
  };

  function validatePromoCodes(CustomPromos, CustomTickets) {
    const errorMessages = [];

    CustomPromos.forEach((promo) => {
      const associatedTickets = promo.tickets
        .map((ticket2) => {
          return CustomTickets.find((ticket) => ticket.id === ticket2.id);
        })
        .filter(Boolean);

      if (associatedTickets.length === 0) {
        errorMessages.push(
          `Promo code: "${promo.codeName}" references invalid tickets.`
        );
      } else {
        const smallestTicketPrice = Math.min(
          ...associatedTickets.map((ticket) => parseFloat(ticket.price))
        );
        const promoAmount = parseFloat(promo.codeAmount);

        if (smallestTicketPrice == 0) {
          errorMessages.push(`Free Tickets cannot be used with Promo Codes`);
        }
        if (promo.codeType === "AMOUNT" && promoAmount > smallestTicketPrice) {
          errorMessages.push(
            `Promo value for code "${promo.codeName}" cannot be more than $${smallestTicketPrice}.`
          );
        }
      }
    });

    return errorMessages;
  }

  const ticketPromosMap = {};
  for (const promo of customPromos) {
    for (const ticket of promo.tickets) {
      if (!ticketPromosMap[ticket.id]) {
        ticketPromosMap[ticket.id] = [];
      }
      ticketPromosMap[ticket.id].push({
        codeName: promo.codeName,
        codeType: promo.codeType,
        codeAmount: `${promo.codeAmount}`,
      });
    }
  }

  const updatedTickets = customTickets.map((ticket) => {
    // Check if the type of the ticket is 'Single' or 'SINGLE'
    if (ticket.type.toLowerCase() === "single") {
      return { ...ticket, seats: 1 };
    }
    return ticket;
  });

  // Merge promos into the tickets
  const mergedTickets = updatedTickets.map((ticket) => {
    return {
      ...ticket,
      promos: ticketPromosMap[ticket.id] || [],
    };
  });

  // Tax receipt stuff
  const changeEmailSettings = async () => {
    if (
      emailCustomization === true &&
      (emailMessage === "" || subject === "" || replyToEmailAddress === "")
    ) {
      setErrorMessage("Tax Receipts is missing required fields");
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      setIsDisabled(false);
      return;
    }

    const payload = {
      replyToEmailAddress: replyToEmailAddress,
      subject: subject,
      emailMessage: emailMessage,
      requestType: "FORMS_PAGE",
      formName: formName,
      merchantName: props.merchant.merchantName,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(payload),
    };

    const deletePayload = {
      replyToEmailAddress: replyToEmailAddress,
      subject: subject,
      emailMessage: emailMessage,
      requestType: "FORMS_PAGE",
      formName: formName,
      merchantName: props.merchant.merchantName,
    };
    const queryParams = Object.entries(deletePayload)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    const requestOptionsDelete = {
      method: "DELETE",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(deletePayload),
    };

    const url = process.env.REACT_APP_EMAIL_CONFIG_URL;

    if (emailCustomization === false && imageUploadError === false) {
      console.log("reverting to default template");
      let response = await fetch(url + "?" + queryParams, requestOptionsDelete);

      let data = await response.json();
      if (data.error) {
        setIsDisabled(false);
        setErrorMessage(data.error);
        setShowErrorAlert(true);
        // unshowing success alert if it was never closed out
        setShowSuccessAlert(false);
        return;
      } else {
        props.grabSelectableFunds(jwtToken);
        setIsDisabled(false);
        setSuccessMessage(
          createdForm
            ? "Event Saved Successfully"
            : "Event Created Successfully"
        );
        setCreatedForm(true);
        // unshowing error alert if it was never closed out
        setShowErrorAlert(false);
        setShowSuccessAlert(true);
      }
    }

    if (emailCustomization === true) {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      if (data.error) {
        setIsDisabled(false);
        setErrorMessage(data.error);
        setShowErrorAlert(true);
        // unshowing success alert if it was never closed out
        setShowSuccessAlert(false);
        return;
      } else {
        props.grabSelectableFunds(jwtToken);
        setIsDisabled(false);
        setSuccessMessage(
          createdForm
            ? "Event Saved Successfully"
            : "Event Created Successfully"
        );
        setCreatedForm(true);
        // unshowing error alert if it was never closed out
        setShowErrorAlert(false);
        setShowSuccessAlert(true);
      }
    }
    if (imageUploadError === true) {
      setIsDisabled(false);
      setErrorMessage("Image Upload Error");
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
    }
  };

  // POST method
  // const sendToServer = async () => {
  //   let returnedError = checkFormErrors();
  //   console.log(returnedError);
  //   if (returnedError != "") {
  //     setErrorMessage(returnedError);
  //     setShowErrorAlert(true);
  //     // unshowing success alert if it was never closed out
  //     setShowSuccessAlert(false);
  //     return;
  //   }

  //   let response = await fetch(url, requestOptions);
  //   let data = await response.json();
  //   console.log({ data });
  //   if (data.error) {

  //   } else {
  //     changeEmailSettings();
  //     if (elementID === "") {
  //       setElementID(data.elementID);
  //     }
  //   }
  //   if (elementID != "" && elementID != null) {
  //     console.log(elementID);
  //     handleSubmit();
  //   }
  // };
  function convertToFloatIfPossible(data) {
    return data.map((item) => {
      const newItem = { ...item };
      for (const key in newItem) {
        // Exclude 'name' and 'attendees' fields from conversion
        if (key !== "name" && key !== "attendees" && !isNaN(newItem[key])) {
          newItem[key] = parseFloat(newItem[key]);
        }
      }
      return newItem;
    });
  }

  const sendToServer = async () => {
    let returnedError = checkFormErrors();
    console.log(returnedError);
    if (returnedError != "") {
      setErrorMessage(returnedError);
      setShowErrorAlert(true);
      setShowSuccessAlert(false);
      return;
    }

    const darkColor = newShade(color, -50);

    const convertedData = convertFieldsToStrings(mergedTickets);

    setIsDisabled(true);
    const payload = {
      formName: formName,
      merchantName: props.merchant.merchantName,
      formType: "EVENT",
      elementType: "EVENT",
      requirePhoneNumber: requirePhoneNumber,
      requireBillingAddress: requireBillingAddress,
      requireCountryDropdown: requireCountryDropdown,
      selectedCountry: selectedCountry,
      lightColor: color,
      darkColor: darkColor,
      tickets: convertedData,
      customInputs: customInputs,
      elementID: elementID,
      faqs: customFAQs,
      eventDetails: convertedContent,
    };

    console.log("payload is as follows: ", payload);
    const requestOptions = {
      method: createdForm ? "PATCH" : "POST", // choose the method based on if it is an updated or a new form
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(payload),
    };

    const url = process.env.REACT_APP_FORMS_URL;

    let response = await fetch(url, requestOptions);
    let data = await response.json();

    if (data.error) {
      setIsDisabled(false);
      setErrorMessage(data.error);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    } else {
      // Handle success
      changeEmailSettings();
      if (elementID != "" && elementID != null) {
        await handleSubmit(elementID);
      } else {
        await handleSubmit(data.elementID);
      }
      // Update elementID from response if available
      if (data.elementID) {
        setElementID(data.elementID);
      } else if (elementID) {
        // If elementID was already set, call handleSubmit
        if (elementID === "") {
          setElementID(data.elementID);
        }
      }
    }

    // Re-enable UI elements if needed
    setIsDisabled(false);
  };

  const handleSubmit = async (image_id) => {
    const requestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        image_data: `${resizedImage}`,
        image_name: `${merchantName}_${image_id}`,
        image_type: `${"event"}`,
        nonprofitName: `${merchantName}`,
      }),
    };

    // submitting transaction to donors-data lambda
    try {
      let response = await fetch(
        process.env.REACT_APP_IMAGE_UPLOAD_URL,
        requestOptions
      );
      let responseBody = await response.json();
      if (!response.ok) {
        setShowErrorAlert(true);
        setErrorMessage("Image Failed to upload");
      } else {
        setShowErrorAlert(false);
        setErrorMessage("");
        setImage(
          `${process.env.REACT_APP_IMAGE_URL}/${
            props.image_type
          }/${merchantName}_${image_id}.png?timestamp=${Date.now.toString()}`
        );
      }
    } catch (error) {
      setShowErrorAlert(true);
      setErrorMessage(error.message);
    }
  };

  // Clear states when modal is closed
  const clearStates = () => {
    // close slideover
    props.setOpen(false);

    // return to default states
    setFormName("");
    setRequirePhoneNumber(false);
    setRequireScheduleDonations(false);
    setRequireBillingAddress(false);
    setRequireCountryDropdown(false);
    setSelectedCountry("Choose Default Country");
    setColor("");
    setSketchPickerColor({
      r: "241",
      g: "112",
      b: "19",
      a: "1",
    });
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
    setIsDisabled(false);
    setCustomTickets([]);
    setCustomPromos([]);

    setCustomInputs([]);
    setActiveTab({ name: "Options", current: true });
    setCreatedForm(false);
    setElementID("");
    setCustomFAQs([]);
    setOriginalCustomFAQs([]);
    setEditorState(() => EditorState.createEmpty());
    setConvertedContent(null);
  };

  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      const formattedMerchants = data["idToken"]["payload"][
        "cognito:groups"
      ].map((merchant) => merchant.replace(/-/g, " "));

      setJWTToken(data["idToken"]["jwtToken"]);
    };
    myFunction();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
    if (props.open === false) {
      clearStates();
    }
  }, [props.open]);

  useEffect(() => {
    const myFunction = async () => {
      let auth = jwtToken;
      if (props.merchant !== null) {
        if (auth === "") {
          const data = await Auth.currentSession();
          setJWTToken(data["idToken"]["jwtToken"]);
          auth = data["idToken"]["jwtToken"];
        }

        // grab double the donation api key if it exists
        const requestOptions = {
          method: "GET",
          headers: {
            accept: "*/*",
            "content-type": "application/json",
            Authorization: auth,
          },
        };

        const params = new URLSearchParams();
        params.append("nonprofit", props.merchant["merchantName"]);
        setMerchantName(props.merchant["merchantName"]);
        const url = process.env.REACT_APP_CS_DTD_SERVER_URL + "/api-keys";

        const response = await fetch(
          url + `?${params.toString()}`,
          requestOptions
        );

        let responseData = await response.json();
        // console.log('responseData is', responseData['publicApiKey'])
      }
    };
    myFunction();
  }, [props.merchant]);

  let submitButtonClass =
    "justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2";
  if (isDisabled) {
    submitButtonClass =
      "justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm cursor-not-allowed focus:outline-none";
  }

  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                Create Event
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Get started by filling in the information below
                                to create your new event.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={clearStates}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                              Select a tab
                            </label>
                            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                            <select
                              id="tabs"
                              name="tabs"
                              className="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              defaultValue={
                                tabs.find((tab) => tab.current).name
                              }
                            >
                              {tabs.map((tab) => (
                                <option key={tab.name}>{tab.name}</option>
                              ))}
                            </select>
                          </div>
                          <div className="hidden sm:block">
                            <div className="border-b border-gray-200">
                              <nav
                                className="-mb-px flex space-x-8 px-5"
                                aria-label="Tabs"
                              >
                                {tabs.map((tab) => (
                                  <a
                                    key={tab.name}
                                    href={tab.href}
                                    onClick={() =>
                                      setActiveTab({
                                        name: tab.name,
                                        current: true,
                                      })
                                    }
                                    className={classNames(
                                      tab.name === activeTab.name
                                        ? "border-green-500 text-green-600"
                                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                      "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                                    )}
                                    aria-current={
                                      tab.name === activeTab.name
                                        ? "page"
                                        : undefined
                                    }
                                  >
                                    {tab.name}
                                  </a>
                                ))}
                              </nav>
                            </div>
                          </div>
                        </div>
                        {/* Divider container */}

                        <div
                          style={
                            activeTab.name == "Custom Inputs"
                              ? {}
                              : { display: "none" }
                          }
                        >
                          <CustomInputsTab_Events
                            customInputs={customInputs}
                            setCustomInputs={setCustomInputs}
                            originalCustomInputs={originalCustomInputs}
                          />
                        </div>

                        <div
                          style={
                            activeTab.name == "FAQs" ? {} : { display: "none" }
                          }
                        >
                          <CustomFAQsTab
                            customFAQs={customFAQs}
                            setCustomFAQs={setCustomFAQs}
                            originalCustomFAQs={originalCustomFAQs}
                          />
                        </div>

                        <div
                          style={
                            activeTab.name == "Tax Receipts"
                              ? {}
                              : { display: "none" }
                          }
                        >
                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:py-0">
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <label
                                htmlFor="form-name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Customization*
                                <svg
                                  data-tip
                                  data-for="emailCustomizationTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="emailCustomizationTooltip"
                                  effect="solid"
                                  place="right"
                                >
                                  <p className="text-sm text-white">
                                    This is where you can customize the details
                                    of the{" "}
                                  </p>
                                  <p className="text-sm text-white">
                                    email sent out to donors.
                                  </p>
                                </ReactTooltip>
                              </label>
                              <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
                                <input
                                  type="radio"
                                  value="false"
                                  name="emailCustomization"
                                  onClick={() => setEmailCustomization(false)}
                                  style={{
                                    backgroundColor:
                                      emailCustomization === false
                                        ? "green"
                                        : "white",
                                    marginTop: "4px",
                                    marginRight: "5px",
                                  }}
                                />
                                Default&nbsp;&nbsp;
                                <input
                                  type="radio"
                                  value="false"
                                  name="emailCustomization"
                                  onClick={() => setEmailCustomization(true)}
                                  style={{
                                    backgroundColor:
                                      emailCustomization === true
                                        ? "green"
                                        : "white",
                                    marginTop: "4px",
                                    marginRight: "5px",
                                  }}
                                />
                                Custom Message
                              </div>
                            </div>
                            {emailCustomization === true && (
                              <>
                                {/* Form name */}
                                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <label
                                    htmlFor="form-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Reply-To Address*
                                    <svg
                                      data-tip
                                      data-for="replytoTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="replytoTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        This is the name of the email address
                                        that
                                      </p>
                                      <p className="text-sm text-white">
                                        the donors will see on their donor
                                        receipts.
                                      </p>
                                      <p className="text-sm text-white">
                                        It must be a full email address.
                                      </p>
                                    </ReactTooltip>
                                  </label>
                                  <div className="sm:col-span-2">
                                    <input
                                      value={replyToEmailAddress}
                                      type="text"
                                      className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                      onChange={(e) =>
                                        replyToEmailAddressChange(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <div>
                                    <label
                                      htmlFor="form-name"
                                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                    >
                                      Subject*
                                      <svg
                                        data-tip
                                        data-for="subjectTooltip"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="text-primary ml-1 inline-block h-5 w-5"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                          clip-rule="evenodd"
                                        />
                                      </svg>
                                      <ReactTooltip
                                        id="subjectTooltip"
                                        effect="solid"
                                        place="right"
                                      >
                                        <p className="text-sm text-white">
                                          This is the subject of the email that
                                        </p>
                                        <p className="text-sm text-white">
                                          the donors will see on their donor
                                          receipts.
                                        </p>
                                      </ReactTooltip>
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <input
                                      value={subject}
                                      type="text"
                                      className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                      onChange={(e) =>
                                        subjectChange(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <label
                                    htmlFor="form-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Custom Message*
                                    <svg
                                      data-tip
                                      data-for="customMessageTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="customMessageTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        This is the message that will be shown
                                        in the{" "}
                                      </p>
                                      <p className="text-sm text-white">
                                        body of the email that donors will see
                                        on their donor receipts.
                                      </p>
                                    </ReactTooltip>
                                  </label>

                                  <div className="sm:col-span-2">
                                    <p class="py-2">
                                      <select
                                        id="variables"
                                        class="float-right mb-2 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        onChange={(e) =>
                                          variableClick(e.target.value)
                                        }
                                      >
                                        <option
                                          selected="true"
                                          disabled="disabled"
                                        >
                                          Variables
                                        </option>
                                        <option value="{{name}}">
                                          Donor Name
                                        </option>
                                        <option value="{{total}}">
                                          Donated Amount
                                        </option>
                                        <option value="{{fund}}">
                                          Donated Fund
                                        </option>
                                        <option value="{{nonprofit}}">
                                          Nonprofit Name
                                        </option>
                                        <option value="{{phone}}">
                                          Donor Phone Number
                                        </option>
                                        <option value="{{address}}">
                                          Donor Address
                                        </option>
                                        <option value="{{email}}">
                                          Donor Email
                                        </option>
                                        <option value="{{paymentMethod}}">
                                          Donor Payment Method
                                        </option>
                                        <option value="{{feeCovered}}">
                                          Covered Fee*
                                        </option>
                                        <option value="{{ein}}">EIN</option>
                                      </select>
                                      <textarea
                                        value={emailMessage}
                                        type="text"
                                        className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                        onChange={(e) =>
                                          emailMessageChange(e.target.value)
                                        }
                                        rows="4"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        <div
                          style={
                            activeTab.name === "Options"
                              ? {}
                              : { display: "none" }
                          }
                          className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:py-0"
                        >
                          {/* Form name */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            {!createdForm && (
                              <>
                                <div>
                                  <label
                                    htmlFor="form-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Event Name*
                                    <svg
                                      data-tip
                                      data-for="formNameTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="formNameTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        This is the name of your event.
                                      </p>
                                    </ReactTooltip>
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    value={formName}
                                    onChange={(e) =>
                                      setFormName(
                                        e.target.value.replace("|", "")
                                      )
                                    }
                                    type="text"
                                    name="form-name"
                                    id="form-name"
                                    className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                  />
                                </div>
                              </>
                            )}
                            {createdForm && (
                              <>
                                <div>
                                  <label
                                    htmlFor="form-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Event Name*
                                    <svg
                                      data-tip
                                      data-for="formNameTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="formNameTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        This is the name of the event you
                                        created.
                                      </p>
                                    </ReactTooltip>
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <div className="py-2 text-sm text-gray-700">
                                    {formName}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          {/* Additonal Info to Collect */}
                          <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-2 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <h3 className="inline-block text-sm font-medium text-gray-900">
                                Required Fields
                              </h3>
                              <svg
                                data-tip
                                data-for="additionalInfoTooltip"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="text-primary ml-1 inline-block h-5 w-5"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                              <ReactTooltip
                                id="additionalInfoTooltip"
                                effect="solid"
                                place="right"
                              >
                                <p className="text-sm text-white">
                                  Optionally, include any additional required
                                  fields:
                                </p>
                                <p className="text-sm text-white">
                                  Phone Number - The donor's phone number.
                                </p>
                                <p className="text-sm text-white">
                                  Billing Address - The donor's full billing
                                  address.
                                </p>
                                <p className="text-sm text-white">
                                  Country Dropdown - Allow the donor to select
                                  from countries
                                </p>
                                <p className="text-sm text-white">
                                  outside of the US. If unselected, US is
                                  assumed.
                                </p>
                              </ReactTooltip>
                            </div>
                            <div className="sm:col-span-2 sm:col-start-2">
                              <div className="">
                                <div className="block">
                                  <Toggle
                                    name={"Require Phone Number"}
                                    enabled={requirePhoneNumber}
                                    setEnabled={setRequirePhoneNumber}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="sm:col-span-2 sm:col-start-2">
                              <div className="">
                                <div className="block">
                                  <Toggle
                                    name={"Require Billing Address"}
                                    enabled={requireBillingAddress}
                                    setEnabled={setRequireBillingAddress}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="sm:col-span-2 sm:col-start-2">
                              <div className="">
                                <div className="block">
                                  <Toggle
                                    name={"Require Country Dropdown"}
                                    enabled={requireCountryDropdown}
                                    setEnabled={setRequireCountryDropdown}
                                  />
                                </div>
                              </div>
                            </div>
                            {requireCountryDropdown && (
                              <div className="sm:col-span-2 sm:col-start-2">
                                <div className="">
                                  <div className="mt-2 block grow">
                                    <Dropdown
                                      selectedValue={selectedCountry}
                                      setSelectedValue={setSelectedCountry}
                                      values={countries}
                                      width={"w-56"}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          {/* Color */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="color"
                                className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                {/* make spacing and height same f*/}
                                Color*
                                <svg
                                  data-tip
                                  data-for="colorTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="colorTooltip"
                                  effect="solid"
                                  place="right"
                                  delayHide={500}
                                  className="react-tooltip-clickable-link"
                                >
                                  <p className="text-sm text-white">
                                    Provide a six character Hex color preceded
                                    by a '#'.
                                  </p>
                                  <p className="text-sm text-white">
                                    For help getting this value, visit the link
                                    below.
                                  </p>
                                  <a
                                    target="_blank"
                                    className="focus:outline-none text-sm text-white underline"
                                    href="https://htmlcolorcodes.com/color-picker/"
                                  >
                                    https://htmlcolorcodes.com/color-picker/
                                  </a>
                                </ReactTooltip>
                              </label>
                            </div>
                            <div className="relative">
                              <div className="inline-flex">
                                <button
                                  type="button"
                                  className="relative mr-2 inline-block h-10 cursor-pointer rounded-md border  border-gray-300	bg-white shadow-sm"
                                  onClick={handleClick}
                                >
                                  <div
                                    className="h-10 w-20 rounded"
                                    style={{ backgroundColor: color }}
                                  />
                                </button>
                                {displayColorPicker ? (
                                  <div className="absolute z-20 pt-10">
                                    <div
                                      className="fixed bottom-0 left-0 right-0 top-0"
                                      onClick={handleClose}
                                    />
                                    <SketchPicker
                                      color={sketchPickerColor}
                                      onChange={(color) => {
                                        setSketchPickerColor(color.rgb);
                                        setColor(color.hex);
                                      }}
                                    />
                                  </div>
                                ) : null}

                                <input
                                  value={color}
                                  placeholder="#000000"
                                  onChange={(e) => {
                                    let color = e.target.value;
                                    color = color.toUpperCase();
                                    color = color.replace(/^([^#])/g, "#$1");
                                    color = color.replace(/[^0-9a-fA-F#]/g, "");
                                    if (color.length > 7) {
                                      color = color.substring(0, 7);
                                    }
                                    setColor(color);
                                  }}
                                  type="text"
                                  name="color"
                                  id="color"
                                  className="focus:border-secondary focus:ring-secondary flex h-10 w-36 rounded-md border-gray-300 shadow-sm sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="project-description"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Image
                                <svg
                                  data-tip
                                  data-for="imgTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="imgTooltip"
                                  effect="solid"
                                  place="right"
                                >
                                  <p className="text-sm text-white">
                                    This is the logo thats displayed for this
                                    event
                                  </p>
                                </ReactTooltip>
                              </label>

                              <br />
                            </div>
                            <ImageUploader
                              merchantName={merchantName}
                              elementID={elementID}
                              image_type={"event"}
                              desiredHeight={0} // originally 500
                              image={image}
                              setImage={setImage}
                              resizedImage={resizedImage}
                              setResizedImage={setResizedImage}
                              desiredWidth={0} // originally 500
                              setErrorMessage={setErrorMessage}
                              setShowErrorAlert={setShowErrorAlert}
                              showErrorAlert={showErrorAlert}
                              jwtToken={jwtToken}
                            />
                          </div>

                          {/* Embed Link */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="project-description"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Event Details
                                <svg
                                  data-tip
                                  data-for="rteTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="rteTooltip"
                                  effect="solid"
                                  place="right"
                                >
                                  <p className="text-sm text-white">
                                    Provide things date and time of the event
                                    and a general description.
                                  </p>
                                </ReactTooltip>
                              </label>
                            </div>
                            <div className="sm:col-span-3">
                              <Editor
                                editorStyle={{
                                  border: 1,
                                  borderRadius: 5,
                                  paddingHorizontal: 4,
                                  minHeight: 300,
                                  paddingVertical: 2,
                                }}
                                toolbarStyle={{
                                  borderRadius: 5,
                                  backgroundColor: "#f1f5f9",
                                }}
                                wrapperStyle={{ borderRadius: 5 }}
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                wrapperClassName="wrapper-class border h-100 border-gray-300"
                                editorClassName="editor-class h-100 px-4 py-2"
                                toolbarClassName="toolbar-class"
                                toolbar={{
                                  options: [
                                    "link",
                                    "inline",
                                    "blockType",
                                    "list",
                                    "textAlign",
                                  ],

                                  list: {
                                    options: ["unordered", "ordered"],
                                  },
                                  inline: {
                                    inDropdown: false,
                                    options: ["bold", "italic", "underline"],
                                  },
                                  blockType: {
                                    inDropdown: true,
                                    options: ["Normal", "H1", "H2", "H3"],
                                  },
                                }}
                              />
                            </div>
                          </div>

                          {/* URL */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="project-description"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                URL
                                <svg
                                  data-tip
                                  data-for="urlTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="urlTooltip"
                                  effect="solid"
                                  place="right"
                                >
                                  <p className="text-sm text-white">
                                    This is the link for your hosted event page.
                                  </p>
                                </ReactTooltip>
                              </label>

                              <br />
                            </div>
                            <div className="relative sm:col-span-2">
                              <textarea
                                className="block w-full cursor-not-allowed rounded-md border-gray-300 bg-gray-100 shadow-sm sm:text-sm"
                                type="text"
                                value={
                                  elementID !== ""
                                    ? `${HOSTED_PAGE_URL}/${elementID}`
                                    : ""
                                }
                                readOnly
                                onClick={(e) => e.target.select()} // Select text on click
                              />
                              <button
                                type="button" // Add this line to prevent form submission
                                className="absolute right-2 top-1/2 -translate-y-1/2 transform rounded-md pb-4 text-black"
                                onClick={copyToClipboard}
                              ></button>
                            </div>
                          </div>

                          {/* Embed Link */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="project-description"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Embed link
                                <svg
                                  data-tip
                                  data-for="embedTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="embedTooltip"
                                  effect="solid"
                                  place="right"
                                >
                                  <p className="text-sm text-white">
                                    Copy and paste the code on the right into
                                    your
                                  </p>
                                  <p className="text-sm text-white">
                                    website after clicking "Create". If nothing
                                    appears,
                                  </p>
                                  <p className="text-sm text-white">
                                    you may have forgotten to embed the
                                    CharityStack script
                                  </p>
                                  <p className="text-sm text-white">
                                    file. You can find this on the Settings
                                    Page.
                                  </p>
                                </ReactTooltip>
                              </label>

                              <p className="text-sm text-gray-500">
                                Embed this code on your website to collect
                                donations.
                              </p>
                              <br />

                              <a
                                className="text-primary hover:text-secondary group flex cursor-pointer items-center space-x-2.5 text-sm font-medium"
                                data-tip
                                data-for="copyIframe"
                                data-event="click"
                              >
                                {/* <LinkIcon
                                  className="h-5 w-5 text-primary group-hover:text-secondary"
                                  aria-hidden="true"
                                />
                                <span>Copy code</span> */}
                              </a>

                              <ReactTooltip
                                id="copyIframe"
                                clickable={true}
                                effect="solid"
                                place="bottom"
                                afterShow={async () => {
                                  try {
                                    await navigator.clipboard.writeText(
                                      elementID !== ""
                                        ? `<div data-entity="EVENT" data-source="CharityStack" id="${elementID}"></div>`
                                        : ""
                                    );
                                    setCopiedState("Copied!");
                                  } catch (err) {
                                    console.error("Failed to copy: ", err);
                                    setCopiedState("Failed to copy");
                                  } finally {
                                    setTimeout(ReactTooltip.hide, 1000);
                                  }
                                }}
                                delayHide={500}
                                delayUpdate={500}
                              >
                                <span className="text-sm text-white">
                                  {copiedState}
                                </span>
                              </ReactTooltip>
                            </div>
                            <div className="sm:col-span-2">
                              <textarea
                                value={
                                  elementID !== ""
                                    ? `<div data-entity="EVENT" data-source="CharityStack" id="${elementID}"></div>`
                                    : ""
                                }
                                id="project-description"
                                name="project-description"
                                rows={5}
                                className={
                                  "focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm " +
                                  (elementID !== ""
                                    ? "bg-white"
                                    : "bg-gray-100")
                                }
                                defaultValue={""}
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          style={
                            activeTab.name == "Tickets"
                              ? {}
                              : { display: "none" }
                          }
                        >
                          <CustomTicketsTab
                            customTickets={customTickets}
                            setCustomTickets={setCustomTickets}
                          />
                        </div>

                        <div
                          style={
                            activeTab.name == "Promo Codes"
                              ? {}
                              : { display: "none" }
                          }
                        >
                          <CustomPromoTab
                            promosList={customPromos}
                            setPromosList={setCustomPromos}
                            availableTickets={customTickets}
                            setShowErrorAlert={setShowErrorAlert}
                            errorMessage={errorMessage}
                            setErrorMessage={setErrorMessage}
                            showErrorAlert={showErrorAlert}
                          />
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex h-12 items-center justify-between space-x-3">
                          {showSuccessAlert && (
                            <SuccessAlert
                              message={successMessage}
                              setShowSuccessAlert={setShowSuccessAlert}
                            />
                          )}
                          {showErrorAlert && (
                            <ErrorAlert
                              message={errorMessage}
                              setShowErrorAlert={setShowErrorAlert}
                            />
                          )}
                          {!showSuccessAlert && !showErrorAlert && <div />}

                          <button
                            type="button"
                            className={submitButtonClass}
                            onClick={sendToServer}
                          >
                            {createdForm
                              ? isDisabled
                                ? "Saving"
                                : "Save"
                              : isDisabled
                              ? "Creating"
                              : "Create"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
