import React, { Component } from 'react'
import { StrictMode } from 'react/cjs/react.production.min';
import PaginationClassic from '../PaginationClassic';
import moment from 'moment';
import {Auth} from "aws-amplify";


export class BankingAccountTable extends Component {
  constructor(props) {
    super(props)
    let end = 6
    
    if (this.props.chapters.length < 6) {
        end = this.props.chapters.length
    }
    console.log('hello im inside banking')
    this.state = {
      start: 0,
      end: end,
      total: this.props.chapters.length
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.chapters[0] !== this.props.chapters[0]) {
        let end = 6
        if (this.props.chapters.length < 6) {
            end = this.props.chapters.length
        }
        console.log('hello im inside banking')
        this.setState({
        start: 0,
        end: end,
        total: this.props.chapters.length
        })
    }
  }

  goNext = (e) => {
    e.preventDefault()
    const { start, end, total } = this.state
    if (end !== total) {
      this.setState({
        start: start + 6,
        end: Math.min(end + 6, total)
      })
    }
  }

  goPrev = (e) => {
    e.preventDefault()
    const { start, end, total } = this.state
    if (start !== 0) {
      this.setState({
        start: Math.max(start - 6, 0),
        end: start
      })
    }
  }

  render() {
    const { transactionList, start, end, total } = this.state;
    let next = "btn bg-white border-gray-200 hover:border-gray-300 text-light"
    let prev = "btn bg-white border-gray-200 hover:border-gray-300 text-light"
    if (end === total) {
      next = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed"
    }
    if (start === 0) {
      prev = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed"
    }

    let emptyRows = [];
    {/*}
    for (var i = 0; i < 3 - (end - start); i++) {
      emptyRows.push(
        <tr>
          <td className="p-2">
            <div className="items-center">
              <div className="text-gray-800" style={{ visibility: "hidden" }}>filler</div>
            </div>
          </td>
          <td className="p-2">
            <div className="items-center">
              <div className="text-gray-800" style={{ visibility: "hidden" }}>filler</div>
            </div>
          </td>
          <td className="p-2">
            <div className="items-center">
              <div className="text-gray-800" style={{ visibility: "hidden" }}>filler</div>
            </div>
          </td>
          <td className="p-2">
            <div className="items-center">
              <div className="text-gray-800" style={{ visibility: "hidden" }}>filler</div>
            </div>
          </td>
        </tr>
      )
    } */}

    return (
      <div className="col-span-full xl:col-span-12 bg-white shadow-lg rounded-lg border border-gray-200">
        {/* <header className="px-5 py-4 border-b border-gray-100">
         <h2 className="font-semibold text-gray-800">Transactions</h2> 
        </header> */}
        <div className="p-3">
      
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="table-auto w-full">
              {/* Table header */}
              <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                <tr>
                  <th className="p-2">
                    <div className="font-semibold text-left">Chapter</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-center">Account Number </div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-center">Routing Number</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">Balance</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="text-xs font-medium divide-y divide-gray-100">
                {this.props.chapters.slice(start, end).map((chapter, index) => {
                    return (
                        <tr>
                          <td className="p-2">
                            <div className="flex items-center">
                              <div className="text-gray-800">{chapter}</div>
                            </div>
                          </td>
                          <td className="p-2">
                          <div id="realnumbers" className="text-center font-bold">{this.props.accountNumbers[index]}</div>
                          </td>
                          <td className="p-2">
                            <div className="text-center font-bold ">084106768</div>
                          </td>
                          <td className="p-2">
                          <div id="realnumbers" className="text-left">${(Math.round(this.props.currentBalances[index] * Math.pow(10, 2)) /Math.pow(10,2)).toFixed(2)}</div>
                          </td>
                        </tr>
                      )
                    })}
                    {
                  emptyRows.map(emptyRow => emptyRow)
                }
              </tbody>
            </table>
            <div className="mt-8">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <nav className="mb-4 sm:order-1" role="navigation" aria-label="Navigation">
                  <ul className="flex justify-center">
                    <li className="ml-3 first:ml-0">
                      <a className={prev} href="#0" onClick={this.goPrev}>&lt;- Previous</a>
                    </li>
                    <li className="ml-3 first:ml-0">
                      <a className={next} href="#0" onClick={this.goNext}>Next -&gt;</a>
                    </li>
                  </ul>
                </nav>
                <div className="text-sm text-gray-500 text-center sm:text-left">
                  Showing <span className="font-medium text-gray-600">{start}</span> to <span className="font-medium text-gray-600">{end}</span> of <span className="font-medium text-gray-600">{total}</span> results
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BankingAccountTable