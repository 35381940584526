import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./phone.css";
import parsePhoneNumber from "libphonenumber-js";
import S3Upload from "../../uploads/S3Upload";
import { Storage, Amplify } from "aws-amplify";
import awsconfig from "../../../aws-exports";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import LogoUploader from "../../uploads/LogoUploader";
import ErrorAlert from "../../alerts/ErrorAlert";

function Organization(props) {
  const { merchant, grabBaseMerchant } = props;

  const [uploadingImage, setUploadingImage] = useState(false);
  const [merchantName, setMerchantName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [ein, setEin] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [jwtToken, setJwtToken] = useState("");
  const [fullLogo, setFullLogo] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const [resizedImage, setResizedImage] = useState(null);
  const [image, setImage] = useState(
    `${
      process.env.REACT_APP_IMAGE_URL
    }/logo/${merchantName}_Logo.png?timestamp=${Date.now().toString()}`
  );

  useEffect(() => {
    if (props.merchant && props.merchant["merchantName"]) {
      let merchantName = props.merchant["merchantName"];
      if (merchant.merchantName) {
        setMerchantName(merchant.merchantName);
        setImage(
          `${
            process.env.REACT_APP_IMAGE_URL
          }/logo/${merchantName}_Logo.png?timestamp=${Date.now().toString()}`
        );
      }
    }
  }, [merchantName]);

  useEffect(() => {
    Amplify.configure(awsconfig);

    if (props.merchant && props.merchant["merchantName"]) {
      let merchantName = props.merchant["merchantName"];
    }
    if (merchant) {
      // check if each state is in merchant dictionary. if so, set it to that value
      if (merchant.merchantName) {
        setMerchantName(merchant.merchantName);
        setImage(
          `${
            process.env.REACT_APP_IMAGE_URL
          }/logo/${merchantName}_Logo.png?timestamp=${Date.now().toString()}`
        );
      }
      if (merchant.address1) {
        setAddressLine1(merchant.address1);
      }
      if (merchant.address2) {
        setAddressLine2(merchant.address2);
      }
      if (merchant.city) {
        setCity(merchant.city);
      }
      if (merchant.state) {
        setState(merchant.state);
      }
      if (merchant.zip) {
        setZipCode(merchant.zip);
      }
      if (merchant.EIN) {
        setEin(merchant.EIN);
      }
    }
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
  }, [merchant]);

  const handleSave = async () => {
    setDisabled(true);
    const payload = {
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      state: state,
      zip: zipCode,
      ein: ein,
    };
    const requestOptions = {
      method: "PATCH",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(payload),
    };
    //needs to be updated
    const url = `${process.env.REACT_APP_ACCOUNTS_BACKEND}/nonprofit-settings`;

    let response = await fetch(url, requestOptions);
    let data = await response.json();
    setSubmit(true);
    await grabBaseMerchant(jwtToken);
    if (resizedImage !== null) {
      console.log("rezied is not null its ", resizedImage);
      handleSubmit();
    }
    setDisabled(false);
    setEditMode(false);
  };

  let submitButtonClass =
    "justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2";
  if (disabled || uploadingImage) {
    submitButtonClass =
      "justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm cursor-not-allowed focus:outline-none";
  }

  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      setJwtToken(data.idToken.jwtToken);
    };
    myFunction();
  }, []);

  const handleEditClick = () => {
    setEditMode(!editMode); // Toggle the editMode state
  };

  const handleSubmit = async () => {
    console.log(errorMessage);
    if (errorMessage === "") {
      console.log("resizedImage", resizedImage);
      setUploadingImage(true);
      const requestOptions = {
        method: "POST",
        headers: {
          accept: "*/*",
          "content-type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify({
          image_data: `${resizedImage}`,
          image_name: `${merchantName}_Logo`,
          image_type: `${"logo"}`,
          nonprofitName: `${merchantName}`,
        }),
      };
      console.log(requestOptions.body);

      // submitting transaction to donors-data lambda
      try {
        let response = await fetch(
          process.env.REACT_APP_IMAGE_UPLOAD_URL,
          requestOptions
        );
        let responseBody = await response.json();
        if (response.ok) {
          setUploadingImage(false);
        }
        console.log("responseBody", responseBody);
        setImage(
          `${
            process.env.REACT_APP_IMAGE_URL
          }/logo/${merchantName}_Logo.png?timestamp=${Date.now().toString()}`
        );
      } catch (error) {
        console.error(error);
      }
    }
  };
  console.log("image", image);

  return (
    <div>
      <div className="pb-6 text-2xl text-black">Organization</div>
      <div className="grid grid-cols-5">
        <div className="col-span-3 row-start-1 flex grid grid-cols-3 border-b-2 border-gray-200 py-2">
          <div className="col-start-1 text-base text-black">Name</div>
          <div className={`col-span-2 col-start-2 text-sm text-gray-400`}>
            <div>{merchantName}</div>
          </div>
        </div>
        <div className="col-span-3 row-start-2 flex grid grid-cols-3 border-b-2 border-gray-200 py-2">
          <div className="col-start-1 text-base text-black">Address</div>
          <div
            className={`col-span-2 col-start-2 text-sm ${
              editMode ? "text-black" : "text-gray-400"
            }`}
          >
            {!editMode && (
              <>
                <div className="-mt-1">{addressLine1}</div>
                <div className="-mt-1">{addressLine2}</div>
                <div className="-mt-1">
                  {city}, {state} {zipCode}
                </div>
              </>
            )}

            {editMode && (
              <>
                <input
                  value={addressLine1}
                  placeholder={"Address Line 1"}
                  type="text"
                  className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  onChange={(e) => setAddressLine1(e.target.value)}
                  maxLength={50}
                />
                <input
                  value={addressLine2}
                  placeholder={"Address Line 2"}
                  type="text"
                  className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  onChange={(e) => setAddressLine2(e.target.value)}
                  maxLength={50}
                />
                <input
                  value={city}
                  placeholder={"City"}
                  type="text"
                  className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  onChange={(e) => setCity(e.target.value)}
                  maxLength={50}
                />
                <input
                  value={state}
                  placeholder={"State"}
                  type="text"
                  className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  onChange={(e) => setState(e.target.value)}
                  maxLength={50}
                />
                <input
                  value={zipCode}
                  placeholder={"Zip Code"}
                  type="text"
                  className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  onChange={(e) => setZipCode(e.target.value)}
                  maxLength={50}
                />
              </>
            )}
          </div>
        </div>
        <div className="col-span-3 row-start-3 flex grid grid-cols-3 border-b-2 border-gray-200 py-2">
          <div className="col-start-1 text-base text-black">EIN</div>
          <div
            className={`col-span-2 col-start-2 text-sm ${
              editMode ? "text-black" : "text-gray-400"
            }`}
          >
            {!editMode && <div>{ein}</div>}

            {editMode && (
              <input
                value={ein}
                placeholder={"EIN"}
                type="text"
                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                onChange={(e) => setEin(e.target.value)}
                maxLength={50}
              />
            )}
          </div>
        </div>
        <div className="col-span-3 row-start-4 flex grid grid-cols-3 py-2">
          <div className="col-start-1 text-base text-black">
            Logo
            <svg
              data-tip
              data-for="donationAmountTooltip"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="text-primary ml-1 inline-block h-5 w-5"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd"
              />
            </svg>
            <ReactTooltip
              id="donationAmountTooltip"
              effect="solid"
              place="right"
            >
              <div className="text-sm text-white">
                <p>
                  Upload or replace your organization's logo to be used across
                  the CharityStack platform.
                </p>
                <p>Required: under 2MB, between 300x300 pixels.</p>
              </div>
            </ReactTooltip>
          </div>

          <div className="col-span-2 col-start-2 text-sm text-gray-400">
            {editMode && (
              <LogoUploader
                merchantName={merchantName}
                editMode={editMode}
                desiredHeight={300}
                image={image}
                setImage={setImage}
                resizedImage={resizedImage}
                setResizedImage={setResizedImage}
                setErrorMessage={setErrorMessage}
                showErrorAlert={showErrorAlert}
                setShowErrorAlert={setShowErrorAlert}
                setFullLogo={setFullLogo}
                desiredWidth={300}
                jwtToken={jwtToken}
              />
            )}

            {showErrorAlert && (
              <ErrorAlert
                message={errorMessage}
                setShowErrorAlert={setShowErrorAlert}
              />
            )}
            {!showErrorAlert && <div />}
            {!editMode && (
              <div>
                {image !== null && merchantName !== "" ? (
                  <img
                    src={`${image}`}
                    onError={() => {
                      setImage(null);
                    }}
                    alt="Logo"
                    className="max-h-40 w-full rounded-lg object-contain opacity-100"
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <p> No Image Added</p>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="absolute bottom-0 right-0 col-start-5 row-start-6 flex-shrink-0 place-self-end px-4 py-3">
          <div className="flex h-12 items-center justify-between space-x-3">
            {/* {!showSuccessAlert && !showErrorAlert && <div />} */}
            {!editMode && (
              <button
                type="button"
                className="bg-primary hover:bg-secondary focus:outline-none focus:ring-secondary justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:ring-2 focus:ring-offset-2"
                onClick={() => setEditMode(true)}
              >
                Edit
              </button>
            )}
            {editMode && (
              <button
                type="button"
                className={submitButtonClass}
                onClick={() => handleSave()}
              >
                {uploadingImage && "Uploading Image"}
                {!uploadingImage && !disabled && "Save"}
                {!uploadingImage && disabled && "Saving"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Organization;
