import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import Toggle from "../../Toggle";


function ThemeSelection() {
    const [dark, setDark] = useState(false);
    const [theme, setTheme] = useState("light");

    function updateTheme() {
        setDark(!dark);
        if (dark) {
            setTheme("dark");
        } else {
            setTheme("light");
        }
    }



    return (
        <div>
            <div className="pb-6 text-2xl text-black">Theme</div>
            <div className="grid grid-cols-5">
                <div className=" row-start-1 flex  border-b-2 border-gray-200 py-2">

                    <div className="sm:col-span-2 sm:col-start-2">
                        <div className="">
                            <div className="block">
                                <Toggle
                                    name={"Dark Mode"}
                                    enabled={dark}
                                    setEnabled={updateTheme}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ThemeSelection;
