import React, { Component } from 'react'
import { StrictMode } from 'react/cjs/react.production.min';
import PaginationClassic from '../PaginationClassic';
import moment from 'moment';
import {Auth} from "aws-amplify";

export class BankingTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      jwtToken: '',
      transactionList: [],
      start: 0,
      end: 0,
      total: 0
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.timerange !== this.props.timerange) {
      this.updateData()
    }
  }

  async componentDidMount() {
    const data = await Auth.currentSession()
    this.setState({
      jwtToken: data['idToken']['jwtToken']
    }, () => this.updateData())
  }

  updateData() {
    console.log('inside')
    const requestOptions = {
      method: 'POST',
      headers: { 'accept': '*/*', 'content-type': 'application/json', 'Authorization': this.state.jwtToken },
      body: JSON.stringify({
        timerange: this.props.timerange,
        accountID: global.accountID
      }),
    };
    const url = process.env.REACT_APP_BANKING_URL
    let paymentInstrument = fetch(url, requestOptions)
      .then(async (response) => {
        const res = await response.json()
        let end = 20
        if (res.transactions.length < 20) {
          end = res.transactions.length
        }

        this.props.setCurrentBalances(res.currentBalance);
        this.props.setAccountNames(res.accountName);
        this.props.setAccountNumbers(res.accountNumber);
        let aggregateTotal = 0

        for (let index = 0; index < res.currentBalance.length; index++) {
          aggregateTotal = res.currentBalance[index] + aggregateTotal          
        }
        this.props.setTotalBalance(aggregateTotal);

        this.setState({
          transactionList: res.transactions,
          start: 0,
          end: end,
          total: res.transactions.length,
        })
        console.log({})
      });
  }

  goNext = (e) => {
    e.preventDefault()
    const { start, end, total } = this.state
    if (end !== total) {
      this.setState({
        start: start + 20,
        end: Math.min(end + 20, total)
      })
    }
  }

  goPrev = (e) => {
    e.preventDefault()
    const { start, end, total } = this.state
    if (start !== 0) {
      this.setState({
        start: Math.max(start - 20, 0),
        end: start
      })
    }
  }

  render() {
    const { transactionList, start, end, total } = this.state;
    let next = "btn bg-white border-gray-200 hover:border-gray-300 text-light"
    let prev = "btn bg-white border-gray-200 hover:border-gray-300 text-light"
    if (end === total) {
      next = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed"
    }
    if (start === 0) {
      prev = "btn bg-white border-gray-200 text-gray-300 cursor-not-allowed"
    }

    let emptyRows = [];
    for (var i = 0; i < 20 - (end - start); i++) {
      emptyRows.push(
        <tr>
          <td className="p-2">
            <div className="items-center">
              <div className="text-gray-800" style={{ visibility: "hidden" }}>filler</div>
            </div>
          </td>
          <td className="p-2">
            <div className="items-center">
              <div className="text-gray-800" style={{ visibility: "hidden" }}>filler</div>
            </div>
          </td>
          <td className="p-2">
            <div className="items-center">
              <div className="text-gray-800" style={{ visibility: "hidden" }}>filler</div>
            </div>
          </td>
          <td className="p-2">
            <div className="items-center">
              <div className="text-gray-800" style={{ visibility: "hidden" }}>filler</div>
            </div>
          </td>
          <td className="p-2">
            <div className="items-center">
              <div className="text-gray-800" style={{ visibility: "hidden" }}>filler</div>
            </div>
          </td>
        </tr>
      )
    }

    return (
      <div className="col-span-full xl:col-span-12 bg-white shadow-lg rounded-lg border border-gray-200">
        {/* <header className="px-5 py-4 border-b border-gray-100">
         <h2 className="font-semibold text-gray-800">Transactions</h2> 
        </header> */}
        <div className="p-3">
      
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="table-auto w-full">
              {/* Table header */}
              <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
                <tr>
                  <th className="p-2">
                    <div className="font-semibold text-left">Date</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-center">Name</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-center">Chapter</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-center">Transaction Type</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">Amount</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="text-sm font-medium divide-y divide-gray-100">
                {transactionList.slice(start, end).map(transaction => {
  

                  //set color of debit/credit
                
                  let stateColor2 = "text-center"
                  let stateColorCredit = "text-center text-green-900 bg-green-300 rounded-full px-3"
                  let stateColorDebit = "text-center text-red-900 bg-red-200 rounded-full px-3"

                  if (transaction.transactionType === 'internalTransfer' && +transaction.amount > 0) {
                    transaction.transactionType = 'Credit'
                    stateColor2 = stateColorCredit
                  }
                  if (transaction.nameOfTransaction.includes('Mercury Checking ••6273') && (transaction.transactionType === 'internalTransfer' || transaction.transactionType === 'Credit') && +transaction.amount > 0) {
                    transaction.nameOfTransaction = '.NGO Payout'
                    stateColor2 = stateColorCredit
                  }
                  if (transaction.nameOfTransaction.includes('Mercury Checking ••6273') && (transaction.transactionType === 'internalTransfer' || transaction.transactionType === 'Credit') && +transaction.amount < 0) {
                    transaction.nameOfTransaction = '.NGO Fee'
                    stateColor2 = stateColorDebit
                  }
                  if (transaction.accountNumber = '9800506273' && transaction.transactionType === 'internalTransfer' && +transaction.amount < 0) {
                    transaction.transactionType = 'Debit'
                    stateColor2 = stateColorDebit
                  }
                  if (transaction.transactionType === 'externalTransfer' && +transaction.amount > 0) {
                    transaction.transactionType = 'Credit'
                    stateColor2 = stateColorCredit
                  }
                  if (transaction.transactionType === 'externalTransfer' && +transaction.amount < 0) {
                    transaction.transactionType = 'Debit'
                    stateColor2 = stateColorCredit
                  }
                  if (transaction.transactionType === 'outgoingPayment' || transaction.transactionType === 'debitCardTransaction' || transaction.transactionType === 'fee') {
                    transaction.transactionType = 'Debit'
                    stateColor2 = stateColorCredit
                  }
                  if (transaction.transactionType === 'incomingDomesticWire' || transaction.transactionType === 'checkDeposit' || transaction.transactionType === 'incomingInternationalWire' ) {
                    transaction.transactionType = 'Credit'
                    stateColor2 = stateColorCredit
                  }
                  if (transaction.transactionType === 'other' && +transaction.amount > 0) {
                    transaction.transactionType = 'Credit'
                    stateColor2 = stateColorCredit
                  }
                  if (transaction.transactionType === 'other' && +transaction.amount < 0) {
                    transaction.transactionType = 'Debit'
                    stateColor2 = stateColorDebit
                  }
                  if (transaction.nameOfTransaction.includes('Stax SV9T') && transaction.transactionType === 'Credit' && +transaction.amount > 0) {
                    transaction.nameOfTransaction = "Stax"
                  }
                  
            
           {/* 
          IF (Account Number = "9800506273" OR Bank Account Name = "Fiscal Sponsor) AND Transaction Type = "internalTransfer" AND Amount > 0 THEN New Transaction Type = "Credit"
IF (Account Number = "9800506273" OR Bank Account Name = "Fiscal Sponsor) AND Transaction Type = "internalTransfer" AND Amount < 0 THEN New Transaction Type = "Debit"
IF (Account Number = "9800506273" OR Bank Account Name = "Fiscal Sponsor) AND Transaction Type = "internalTransfer" AND Amount > 0 THEN New Transaction Name = ".NGO Payout"
IF (Account Number = "9800506273" OR Bank Account Name = "Fiscal Sponsor) AND Transaction Type = "internalTransfer" AND Amount < 0 THEN New Transaction Name = ".NGO Fee"
IF Transaction Type = "External Transfer" and Amount > 0 THEN New Transaction Type = "Credit"
IF Transaction Type = "External Transfer" and Amount < 0 THEN New Transaction Type = "Debit" 
IF Transaction Type = "outgoingPayment" THEN New Transaction Type = "Debit"
IF Transaction Type = "debitCard Transaction" THEN New Transaction Type = "Debit"
IF Transaction Type = "incomingDomesticWire" THEN New Transaction Type = "Credit"
IF Transaction Type = "checkDeposit" THEN New Transaction Type = "Credit"
IF Transaction Type = "incomingInternationalWire" THEN New Transaction Type = "Credit"
IF Transaction Type = "fee" THEN New Transaction Type = "Debit"
IF Transaction Type = "other" AND Amount > 0 THEN New Transaction Type = "Credit" 
IF Transaction Type = "other" AND Amount < 0 THEN New Transaction Type = "Credit" 
          */}
                  return (
                    <tr>
                      <td className="p-2">
                          <div className="text-gray-800">{transaction.dateDeposited}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-center font-semibold">{transaction.nameOfTransaction}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-center">{transaction.accountName}</div>
                      </td>
                      <td className="p-2">
                      <div className="flex justify-center">
                        <div className={stateColor2}>{transaction.transactionType}</div>
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="text-left">${Math.abs(Math.round(transaction.amount * Math.pow(10, 2)) /Math.pow(10,2)).toFixed(2)}</div>
                      </td>
                      
                    </tr>
                  )
                }
                )}
                {
                  emptyRows.map(emptyRow => emptyRow)
                }
              </tbody>
            </table>
            <div className="mt-8">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                <nav className="mb-4 sm:order-1" role="navigation" aria-label="Navigation">
                  <ul className="flex justify-center">
                    <li className="ml-3 first:ml-0">
                      <a className={prev} href="#0" onClick={this.goPrev}>&lt;- Previous</a>
                    </li>
                    <li className="ml-3 first:ml-0">
                      <a className={next} href="#0" onClick={this.goNext}>Next -&gt;</a>
                    </li>
                  </ul>
                </nav>
                <div className="text-sm text-gray-500 text-center sm:text-left">
                  Showing <span className="font-medium text-gray-600">{start}</span> to <span className="font-medium text-gray-600">{end}</span> of <span className="font-medium text-gray-600">{total}</span> results
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BankingTable