import React, { useState, useEffect } from "react";
import decompressData from "../utils/decompressData";
import pako from "pako";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import FilterButton from "../partials/actions/FilterButton";
import DonationDatePicker from "../partials/actions/DonationDatePicker";
import DashboardCard01 from "../partials/dashboard/DashboardCard01";
import DashboardCard02 from "../partials/dashboard/DashboardCard02";
import DashboardCard03 from "../partials/dashboard/DashboardCard03";
import DashboardCard04 from "../partials/dashboard/DashboardCard04";
import DashboardCard05 from "../partials/dashboard/DashboardCard05";
import DashboardCard06 from "../partials/dashboard/DashboardCard06";
import DashboardCard07 from "../partials/dashboard/DashboardCard07";
import DashboardCard09 from "../partials/dashboard/DashboardCard09";
import DashboardCard10 from "../partials/dashboard/DashboardCard10";
import DashboardCard11 from "../partials/dashboard/DashboardCard11";
import DonationsTable from "../partials/dashboard/DonationsTable";
import TicketsTable from "../partials/dashboard/TicketsTable";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Auth } from "aws-amplify";
import { queryTransactionsTopFunds } from "../utils/RocksetQueries/TransactionQueries";

function Tickets(props) {
  //constants
  const tables = [TicketsTable];

  //Define default timerange
  const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
  let today = new Date();
  // increment today forward by 1 day
  today.setDate(today.getDate() + 1);
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() - 6);
  const startTimerange =
    tomorrow.toLocaleDateString("en-US", DATE_OPTIONS) +
    " - " +
    today.toLocaleDateString("en-US", DATE_OPTIONS);

  //react hooks
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [timerange, setTimerange] = useState(startTimerange);
  const [tableIndex, setTableIndex] = useState(0);

  const [totalAttendees, setTotalAttendees] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);
  const [totalCheckedIn, setTotalCheckedIn] = useState(0);
  const [totalNotCheckedIn, setTotalNotCheckedIn] = useState(0);

  const [currentTab, setCurrentTab] = useState("Tickets");

  let getMetrics = (
    totalActiveEvents,
    totalAmount,
    totalAttendees,
    totalAttendeeValue,
    totalCheckedIn
  ) => {
    setTotalAttendees(
      totalAttendeeValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
    setTotalEvents(
      totalActiveEvents.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
    setTotalCheckedIn(
      totalCheckedIn.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
    setTotalNotCheckedIn(
      parseInt(totalAttendeeValue) - parseInt(totalCheckedIn)
    );
  };

  const handleTabChange = (value) => {
    setCurrentTab(value);
  };

  const grabBaseMerchant = async (jwtToken) => {
    const url = process.env.REACT_APP_MERCHANT_URL;
    const requestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
    };

    let baseMerchantResponse = await fetch(url, requestOptions);
    let baseMerchantData = await baseMerchantResponse.json();
    props.setMerchant(baseMerchantData);
  };

  const grabSelectableFunds = async (jwtToken) => {
    //call transactions table and get funds when jwtToken is set
    const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
    let today = new Date();
    // increment today forward by 1 day
    today.setDate(today.getDate() + 1);
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() - 6000);
    const allTimeTimerange =
      tomorrow.toLocaleDateString("en-US", DATE_OPTIONS) +
      " - " +
      today.toLocaleDateString("en-US", DATE_OPTIONS);

    // grab forms data
    const formsRequestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
    };

    const formsURL = process.env.REACT_APP_FORMS_URL;

    // make api calls simultaneously
    let formsAPICall = fetch(formsURL, formsRequestOptions);

    let formsResponse = await formsAPICall;
    let formsData = await formsResponse.json();

    let runningFundsList = [];

    // get merchants
    const data = await Auth.currentSession();
    // grab groups from cognito
    const groups = data["idToken"]["payload"]["cognito:groups"];
    // replace the - with a space for every value in the array
    const groupsWithSpaces = groups.map((group) => group.replace(/-/g, " "));

    let donationFundsData = await queryTransactionsTopFunds(
      groupsWithSpaces,
      allTimeTimerange,
      -1,
      -1,
      -1,
      [],
      [],
      "",
      999999999
    );

    // grab all historical funds from donations table
    donationFundsData.map((fund) => {
      if (!runningFundsList.includes(fund)) {
        runningFundsList.push(fund);
      }
    });

    // grab all funds from forms
    formsData.map((form) => {
      // events got no funds, check for that
      if (form.funds != undefined) {
        form.funds.map((fund) => {
          if (!runningFundsList.includes(fund)) {
            runningFundsList.push(fund);
          }
        });
      }
    });

    // format funds for react-select
    runningFundsList = runningFundsList.map((fund) => {
      return {
        value: fund,
        label: fund,
        color: "#69995E",
      };
    });
    props.setSelectableForms(formsData);
    props.setSelectableFunds(runningFundsList);
  };

  useEffect(() => {
    const myFunction = async () => {
      if (props.selectableFunds.length === 0) {
        if (!props.jwtToken) {
          const data = await Auth.currentSession();
          props.setJWTToken(data["idToken"]["jwtToken"]);
          grabSelectableFunds(data["idToken"]["jwtToken"]);
          grabBaseMerchant(data["idToken"]["jwtToken"]);
        } else {
          grabSelectableFunds(props.jwtToken);
        }
      }
    };
    myFunction();
  }, []);

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        groups={props.groups}
      />

      {/* Content area */}
      <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <div className="max-w-9xl mx-auto w-full px-4 sm:px-6 lg:px-8">
          <Header
            grabSelectableFunds={grabSelectableFunds}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            selectableFunds={props.selectableFunds}
            selectableForms={props.selectableForms}
            merchant={props.merchant}
            groups={props.groups}
          />
        </div>

        <main>
          <div className="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
            {/* Welcome banner */}
            {/* Dashboard actions */}

            {/* Left: Avatars */}
            {/* <DashboardAvatars /> */}

            {/*charts of cards */}
            {currentTab === "Tickets" && (
              <div className="flex space-x-5">
                <div class="block max-w-sm rounded-lg border border-gray-200 bg-white p-6 shadow-md hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <div class="center text-m mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Attendees &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <p class="font-normal text-gray-700 dark:text-gray-400">
                    {totalAttendees}
                  </p>
                </div>

                <div class="block max-w-sm rounded-lg border border-gray-200 bg-white p-6 shadow-md hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <div class="text-m center mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Events{" "}
                  </div>
                  <p class="font-normal text-gray-700 dark:text-gray-400">
                    {totalEvents}
                  </p>
                </div>
                <div class="block max-w-sm rounded-lg border border-gray-200 bg-white p-6 shadow-md hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <div class="text-m mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Checked-In{" "}
                  </div>
                  {/* shine box */}
                  <div className="z-5 group-hover:animate-shine absolute -inset-full top-0 block h-full w-1/2 -skew-x-12 transform bg-gradient-to-r from-transparent to-white opacity-40" />
                  <p class="font-normal text-gray-700 dark:text-gray-400">
                    {totalCheckedIn}
                  </p>
                </div>
                <div class="block max-w-sm rounded-lg border border-gray-200 bg-white p-6 shadow-md hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <div class="text-m mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    Not Checked-In{" "}
                  </div>
                  <p class="font-normal text-gray-700 dark:text-gray-400">
                    {totalNotCheckedIn}
                  </p>
                </div>
              </div>
            )}

            {/* Right: Actions */}
            <div className="grid grid-flow-col gap-2 sm:auto-cols-max sm:justify-end"></div>
            <div className="py-1"></div>

            <div className=" mb-4 grid grow grid-cols-12 gap-6"></div>

            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              <TicketsTable
                grabSelectableFunds={grabSelectableFunds}
                getMetrics={getMetrics}
                timerange={props.timerange}
                setTimerange={props.setTimerange}
                setTransactionData={props.setTransactionData}
                transactionData={props.transactionData}
                selectableFunds={props.selectableFunds}
                merchant={props.merchant}
              ></TicketsTable>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Tickets;
