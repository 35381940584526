/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, React, useEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import DonorSubscriptions from "../lists/DonorSubscriptions";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import UpdatePayment from "../UpdatePayment";
import SuccessAlert from "../alerts/SuccessAlert";
import CancelDonorSubscriptionAlert from "../alerts/CancelDonorSubscriptionAlert";
import { useSelector } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function capitalizeFirstLetter(string) {
  const words = string.split(" ");

  for (let i = 0; i < words.length; i++) {
    if (words[i].length > 1) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    } else if (words[i].length == 1) {
      words[i] = words[i].charAt(0).toUpperCase();
    }
  }

  return words.join(" ");
}

export default function DonorSlideover(props) {
  const [showUpdatePayment, setShowUpdatePayment] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { readOnly } = useSelector((state) => state.readOnly); // this contains the variable which youll use to check
  {
    /*
            If the readOnly variable is truthy (evaluates to true), it will return null. In other words, nothing will be rendered in this case.
            If the readOnly variable is falsy (evaluates to false), it will execute the code block enclosed in the parentheses ( and ). This code block can contain JSX elements or other JavaScript code that will be rendered or executed when readOnly is falsy.
            */
  }
  let name = "";
  let email = "";
  let phone = "N/A";
  let address1 = "N/A";
  let address2 = "N/A";
  let address3 = "N/A";
  let country = "";
  let showAddress2 = { display: "none" };
  let showAddress3 = { display: "none" };
  let showCountry = { display: "none" };
  let stateColor =
    "px-3 text-center text-green-800 bg-green-100 rounded-full ml-3";
  let donorStatus = "Recurring";

  let monthlyDonation = 0;
  let grossAmountVar = "";
  let netAmount = 0;
  console.log(props);

  if (props.selectedDonor) {
    netAmount = props.selectedDonor.amount - props.selectedDonor.fees;
    netAmount = (Math.round(netAmount * Math.pow(10, 2)) / Math.pow(10, 2))
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    name = capitalizeFirstLetter(props.selectedDonor.name);
    email = props.selectedDonor.email;
    console.log("props: ", props.selectedDonor);
    donorStatus = props.selectedDonor.donorType;
    // donationFrequency = props.selectedDonor.donationFrequency
    grossAmountVar = props.selectedDonor.grossAmountVar.toFixed(2);

    if (
      props.selectedDonor.address != "" &&
      props.selectedDonor.address != null
    ) {
      address1 = props.selectedDonor.address;
      address2 = props.selectedDonor.address2;
      address3 =
        props.selectedDonor.city +
        ", " +
        props.selectedDonor.region +
        " " +
        props.selectedDonor.zip;
      country = props.selectedDonor.country;
      showCountry = {};
      showAddress3 = {};
      if (
        props.selectedDonor.address2 != "" &&
        props.selectedDonor.address2 != null
      ) {
        showAddress2 = {};
      }
    }
    if (
      (props.selectedDonor.phonenumber != "") &
      (props.selectedDonor.phonenumber != null)
    ) {
      phone = props.selectedDonor.phonenumber;
    }
    //set color for monthly donor (green), repeat? (blue), one-time (red)
    if (props.selectedDonor.donorType == "One-Time") {
      stateColor = "px-3 text-center text-red-800 bg-red-200 rounded-full ml-3";
    }
    if (props.selectedDonor.donorType == "Repeat") {
      stateColor =
        "px-3 text-center text-blue-800 bg-blue-100 rounded-full ml-3";
    }
    if (props.selectedDonor.donorType == "Canceled") {
      stateColor =
        "px-3 text-center text-yellow-800 bg-yellow-100 rounded-full ml-3";
    }

    // if (props.selectedDonor.donorType == 'Monthly') {
    //     monthlyDonation = props.selectedDonor.monthlyDonation.toFixed(2)
    //     donorStatus = `$${monthlyDonation} ${donorStatus}`
    // }
  }

  useEffect(() => {
    console.log("changed open", props.open);
    setShowSuccessAlert(false);
  }, [props.open]);

  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
          <UpdatePayment
            updateSubscriptionData={props.updateData}
            setShowSuccessAlert={setShowSuccessAlert}
            setSuccessMessage={setSuccessMessage}
            slideoverOpen={props.open}
            open={showUpdatePayment}
            setOpen={setShowUpdatePayment}
            selectedSubscription={selectedSubscription}
            merchant={props.merchant}
          />
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    {showSuccessAlert && (
                      <div className="fixed z-20">
                        <SuccessAlert
                          message={successMessage}
                          setShowSuccessAlert={setShowSuccessAlert}
                        />
                      </div>
                    )}
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <div className="space-y-1">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              Donor Profile
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              Below is information pertaining to the donor you
                              selected.
                            </p>
                          </div>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => props.setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div>
                        <div className="pb-1 sm:pb-6">
                          <div>
                            {/* <div className="relative h-40 sm:h-56">
                                                        <img
                                                            className="absolute h-full w-full object-cover"
                                                            src="https://images.unsplash.com/photo-1501031170107-cfd33f0cbdcc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&h=600&q=80"
                                                            alt=""
                                                        />
                                                    </div> */}
                            <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                              <div className="sm:flex-1">
                                <div className="flex grid grid-cols-1 sm:grid-cols-2">
                                  <div className="sm:col-span-1">
                                    <div className="flex items-center">
                                      <h3 className="text-xl font-bold text-gray-900">
                                        {name}
                                      </h3>

                                      <button className={stateColor}>
                                        {donorStatus}
                                      </button>
                                    </div>
                                    <div className="flex">
                                      <EnvelopeIcon
                                        className="mr-1.5 mt-1 h-4 w-4 flex-shrink-0 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      <p className="text-sm text-gray-500">
                                        {email}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="ml-10 sm:col-span-1">
                                    <div className="flex items-center">
                                      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                                        Total Donated
                                      </dt>
                                    </div>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-1">
                                      ${grossAmountVar}
                                    </dd>
                                  </div>
                                </div>
                                <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                                  {/* <button
                                                                    type="button"
                                                                    className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:flex-1"
                                                                >
                                                                    Message
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="inline-flex w-full flex-1 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                >
                                                                    Call
                                                                </button>
                                                                <div className="ml-3 inline-flex sm:ml-0">
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <Menu.Button className="inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                                            <span className="sr-only">Open options menu</span>
                                                                            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </Menu.Button>
                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                <div className="py-1">
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <a
                                                                                                href="#"
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'block px-4 py-2 text-sm'
                                                                                                )}
                                                                                            >
                                                                                                View profile
                                                                                            </a>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <a
                                                                                                href="#"
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'block px-4 py-2 text-sm'
                                                                                                )}
                                                                                            >
                                                                                                Copy profile link
                                                                                            </a>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 pb-5 pt-5 sm:px-0 sm:pt-0">
                          <dl className="grid grid-cols-1 px-4 sm:grid-cols-2 sm:px-6">
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                                Address
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-1">
                                <p>{address1}</p>
                                <p style={showAddress2}>{address2}</p>
                                <p style={showAddress3}>{address3}</p>
                                <p style={showCountry}>{country}</p>
                              </dd>
                              <br />
                              <br />
                            </div>
                            <div className="ml-10 sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                                Phone
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-1">
                                {phone}
                              </dd>
                            </div>

                            {/* <div className='sm:col-span-1 '>
                                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Donated Fund</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-1">
                                                            {fund}
                                                        </dd>
                                                    </div>
                                                    <div className='sm:col-span-1'>
                                                        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Total Donated</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-1">
                                                            ${grossAmountVar}
                                                        </dd>
                                                    </div> */}

                            {donorStatus === "Recurring" && (
                              <>
                                <div className="col-span-2">
                                  <h3 className="mb-4 font-semibold text-gray-900">
                                    Recurring Donation
                                  </h3>
                                </div>
                                <div className="col-span-2">
                                  <DonorSubscriptions
                                    setShowSuccessAlert={setShowSuccessAlert}
                                    setSuccessMessage={setSuccessMessage}
                                    selectedDonorSubscriptions={
                                      props.selectedDonorSubscriptions
                                    }
                                    setSelectedSubscription={
                                      setSelectedSubscription
                                    }
                                    setShowUpdatePayment={setShowUpdatePayment}
                                    updateData={props.updateData}
                                    jwtToken={props.jwtToken}
                                  />
                                </div>
                              </>
                            )}
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
