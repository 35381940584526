import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  Suspense,
  useRef,
} from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { border, fontFamily, width } from "@mui/system";
import StripeCardShimmer from "./StripeCardShimmer";

import "../css/stripe.css";

// import "./stripe.css";

const CheckoutForm = forwardRef(
  (
    {
      setErrorMessage,
      setShowErrorAlert,
      setSendingData,
      setStripeCardPaymentFunction,
      defaultPaymentProcessor,
      processStripeCard,
      stripeAccountID,
    },
    ref
  ) => {
    const stripe = useStripe();
    const elements = useElements();

    const [resetKey, setResetKey] = useState(0);
    const [zip, setZip] = useState("");

    const [cardNumberReady, setCardNumberReady] = useState(false);
    const [cardExpiryReady, setCardExpiryReady] = useState(false);
    const [cardCvcReady, setCardCvcReady] = useState(false);

    // Add a function to reset the form
    const resetForm = () => {
      setZip("");
      setResetKey((prevKey) => prevKey + 1);
    };

    useImperativeHandle(ref, () => ({
      resetForm,
      handleSubmit: async () => {
        setSendingData(true);
        // event.preventDefault();
        // const zipValue = document.getElementById("cs-zip").value;
        // let billingDetails = {};
        // if (zipValue) {
        if (!zip) {
          setErrorMessage("Please enter a card zip/postal code");
          return;
        }
        const billingDetails = {
          address: {
            postal_code: zip,
          },
        };

        // }

        console.log("inside handle submitttt");
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          console.log("stripe or elements not loaded");
          return;
        } else {
          console.log("stripe and elements loaded");
        }

        const cardElement = elements.getElement(CardNumberElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod(
          {
            type: "card",
            card: cardElement,
            billing_details: billingDetails,
          },
          {
            stripeAccount: stripeAccountID, // Include the connected account ID here
          }
        );

        if (error) {
          console.error("[error]", error);
          setErrorMessage(error.message);
          setShowErrorAlert(true);
          setSendingData(false);
          return;
        }

        console.log("[PaymentMethod]", paymentMethod);
        setErrorMessage("");
        setShowErrorAlert(false);
        // setSendingData(false);
        processStripeCard(paymentMethod);
      },
    }));

    // if (!stripeInstance) {
    //   return <div>Loading...</div>; // Or any loading indicator
    // }

    const showShimmer = !cardNumberReady || !cardExpiryReady || !cardCvcReady;

    return (
      <>
        <div class="w-full" style={showShimmer ? {} : { display: "none" }}>
          <StripeCardShimmer />
        </div>
        <div
          class="w-full"
          key={resetKey}
          style={showShimmer ? { display: "none" } : {}}
        >
          <div
            className="w-full"
            style={{
              alignItems: "center",
              backgroundColor: "#fff",
              borderRadius: "6px",
              border: "1px solid #b1b1b1",
              boxShadow: "0 1px 3px 0 #e6ebf1",
              display: "grid",
              height: "42px",
              marginBottom: "2px",
              width: "100%",
              color: "#b1b1b1",
              fontSize: "13px",
              fontWeight: 500,
              paddingRight: "5%",
              paddingLeft: "10px",
              MozAppearance: "none",
              WebkitAppearance: "none",
              appearance: "none",
              backgroundRepeat: "no-repeat, repeat",
              backgroundPosition: "right 0.7em top 50%, 0 0",
              backgroundSize: "0.65em auto, 100%",
            }}
          >
            <CardNumberElement
              onReady={() => setCardNumberReady(true)}
              options={{
                showIcon: true,
                style: {
                  base: {
                    height: "42px",
                    color: "#000000",
                    fontSize: "13px",
                    fontWeight: 500,
                    fontFamily: "system-ui",
                    MozAppearance: "none",
                    WebkitAppearance: "none",
                    appearance: "none",
                    backgroundRepeat: "no-repeat, repeat",
                    "::placeholder": {
                      color: "#B1B1B1",
                    },
                    fontSmoothing: "antialiased",
                  },
                },
              }}
            />
          </div>
          <div
            style={{
              alignItems: "center",
              backgroundColor: "#fff",
              borderRadius: "6px",
              border: "1px solid #b1b1b1",
              boxShadow: "0 1px 3px 0 #e6ebf1",
              display: "flex",
              height: "42px",
              marginBottom: "2px",
              width: "100%",
              fontSize: "13px",
              fontWeight: 500,
              paddingRight: "10px",
              paddingLeft: "10px",
              MozAppearance: "none",
              WebkitAppearance: "none",
              appearance: "none",
              backgroundRepeat: "no-repeat, repeat",
              backgroundPosition: "right 0.7em top 50%, 0 0",
              backgroundSize: "0.65em auto, 100%",
            }}
          >
            <div style={{ width: "33.33%" }}>
              <CardExpiryElement
                onReady={() => setCardExpiryReady(true)}
                options={{
                  style: {
                    base: {
                      height: "42px",
                      color: "#000000",
                      fontSize: "13px",
                      fontWeight: 500,
                      fontFamily: "system-ui",
                      MozAppearance: "none",
                      WebkitAppearance: "none",
                      appearance: "none",
                      backgroundRepeat: "no-repeat, repeat",
                      paddingLeft: "10px",
                      "::placeholder": {
                        color: "#B1B1B1",
                      },
                      fontSmoothing: "antialiased",
                    },
                  },
                }}
              />
            </div>
            <div style={{ width: "33.33%" }}>
              <CardCvcElement
                onReady={() => setCardCvcReady(true)}
                options={{
                  style: {
                    base: {
                      height: "42px",
                      color: "#000000",
                      fontSize: "13px",
                      fontWeight: 500,
                      fontFamily: "system-ui",
                      MozAppearance: "none",
                      WebkitAppearance: "none",
                      appearance: "none",
                      backgroundRepeat: "no-repeat, repeat",
                      paddingLeft: "10px",
                      "::placeholder": {
                        color: "#B1B1B1",
                      },
                      fontSmoothing: "antialiased",
                    },
                  },
                }}
              />
            </div>
            <div style={{ width: "33.33%" }}>
              <input
                style={{
                  color: "#000000",
                  fontSize: "13px",
                  fontWeight: 500,
                  fontFamily: "system-ui",
                  MozAppearance: "none",
                  WebkitAppearance: "none",
                  appearance: "none",
                  backgroundRepeat: "no-repeat, repeat",
                  outline: "none",
                  border: "none",
                  width: "100%",
                }}
                id="cs-zip"
                type="tel"
                maxLength="6"
                className="focus:outline-none w-full border-none text-black focus:border-transparent focus:ring-0"
                value={zip}
                onChange={(event) => {
                  let value = event.target.value;
                  value = value.replace(/[^A-Za-z0-9]+/g, "");
                  setZip(value);
                }}
                placeholder="Zip/Postal"
                autoComplete="postal-code"
              />
            </div>
          </div>
          {/* <button type="button" onClick={handleSubmit}>
          Pay
        </button> */}
        </div>
      </>
    );
  }
);

const StripeCheckout = forwardRef(
  (
    {
      setErrorMessage,
      setShowErrorAlert,
      setSendingData,
      defaultPaymentProcessor,
      processStripeCard,
      stripeAccountID,
    },
    ref
  ) => {
    // const [stripePromise, setStripePromise] = useState(null);

    // useEffect(() => {
    //   if (stripeAccountID) {
    //     const stripeInstance = loadStripe(
    //       "pk_test_51Lb9EUFFzBBowpWlLZ1J6uBUdmrBwnKnfmyjgD9E4DlInlVLNaVzXeexqlExKJLkIX3jz9slzXJa4N5ZwRcxffDr00ngy13lmA",
    //       {
    //         stripeAccount: stripeAccountID,
    //       }
    //     );
    //     setStripePromise(stripeInstance);
    //   }
    // }, [stripeAccountID]);

    // if (!stripePromise) {
    //   return <div>Loading...</div>; // Or any loading indicator
    // }
    return (
      // <Elements stripe={stripePromise} width="100%">
      <CheckoutForm
        ref={ref}
        width="100%"
        setErrorMessage={setErrorMessage}
        setShowErrorAlert={setShowErrorAlert}
        setSendingData={setSendingData}
        defaultPaymentProcessor={defaultPaymentProcessor}
        processStripeCard={processStripeCard}
        stripeAccountID={stripeAccountID}
      />
      // </Elements>
    );
  }
);

export default StripeCheckout;
