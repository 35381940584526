export function fundsToCSVString(array) {
  // Convert array elements to strings
  let stringArray = array.map((element) => {
    // If the element is an object or array, stringify it
    if (typeof element === "object") {
      return stringifyObject(element);
    }
    // Otherwise, just convert to string and handle special characters
    return handleSpecialCharacters(String(element));
  });

  // Join the elements with commas and encapsulate each element within double quotes
  let csvString = '"' + stringArray.join('","') + '"';

  return csvString;
}

// Function to stringify objects
function stringifyObject(obj) {
  // rename fundraiser to form as a deep copy
  obj = renameKeys(obj, { fundraiser: "form", fundName: "fund" });

  delete obj.subscriptionID;
  delete obj.cartItemKey;
  delete obj.salesforceCampaignID;

  // Convert object to string and handle special characters
  let objString = JSON.stringify(obj);
  return handleSpecialCharacters(objString);
}

function renameKeys(obj, keyMap) {
  const newObj = {};
  for (let key in obj) {
    if (keyMap.hasOwnProperty(key)) {
      newObj[keyMap[key]] = obj[key];
    } else {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

// Function to handle special characters
function handleSpecialCharacters(str) {
  // If the string contains double quotes or commas, enclose it within double quotes and escape any double quotes inside
  if (str.includes('"') || str.includes(",")) {
    return '"' + str.replace(/"/g, '""') + '"';
  }
  // Otherwise, return the string as is
  return str;
}

// --------------------------------------------
export function ticketsToCSVString(array) {
  // Convert array elements to strings
  let stringArray = array.map((element) => {
    // If the element is an object or array, stringify it
    if (typeof element === "object") {
      return stringifyTicketObject(element);
    }
    // Otherwise, just convert to string and handle special characters
    return handleSpecialCharacters(String(element));
  });

  // Join the elements with commas and encapsulate each element within double quotes
  let csvString = '"' + stringArray.join('","') + '"';

  return csvString;
}

// Function to stringify objects
function stringifyTicketObject(obj) {
  delete obj.checkInStatus;
  delete obj.customInputAttendee;
  delete obj.ticketID;
  delete obj.confirmationID;

  // Convert object to string and handle special characters
  let objString = JSON.stringify(obj);
  return handleSpecialCharacters(objString);
}
// --------------------------------------------

export function customInputsToCSVString(array) {
  // Convert array elements to strings
  let stringArray = array.map((element) => {
    // If the element is an object or array, stringify it
    if (typeof element === "object") {
      return stringifyCustomInputsObject(element);
    }
    // Otherwise, just convert to string and handle special characters
    return handleSpecialCharacters(String(element));
  });

  // Join the elements with commas
  let csvString = stringArray.join(", ");

  // Encapsulate the entire string in double quotes to keep it in one cell

  console.log(`${csvString.replace(/"/g, "")}`);
  return `${csvString.replace(/"/g, "")}`;
}

// Function to stringify objects
function stringifyCustomInputsObject(obj) {
  delete obj.options;
  delete obj.questionType;
  delete obj.type;
  delete obj.required;

  // Ensure title appears before answers and format as requested
  let title = obj.title || "";
  let answers = Array.isArray(obj.answers)
    ? obj.answers.join(" ")
    : obj.answers || "";

  let objString = `${title}: ${answers}`;

  return objString;
}
