import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./phone.css";
import parsePhoneNumber from "libphonenumber-js";

function Profile() {
  const [username, setUsername] = useState("");
  const [orignalEmail, setOriginalEmail] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [disabled, setDisabled] = useState(false);
  const [editMode, setEditMode] = useState(false);

  let submitButtonClass =
    "justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2";
  if (disabled) {
    submitButtonClass =
      "justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm cursor-not-allowed focus:outline-none";
  }

  const updateUserAttributes = async () => {
    setDisabled(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const formattedAddress = `${addressLine1}\n${addressLine2}\n${city}, ${state} ${zipCode}`;
      const updatedAttributes = {
        given_name: firstName,
        family_name: lastName,
        phone_number: phone,
        address: formattedAddress,
        updated_at: String(Math.floor(Date.now() / 1000)),
      };

      const result = await Auth.updateUserAttributes(user, updatedAttributes);
      console.log("Attributes updated:", result);
      setOriginalEmail(email);
      setDisabled(false);
      setEditMode(false);
    } catch (error) {
      console.error("Error updating attributes:", error);
      setDisabled(false);
    }
  };

  const getUserAttributes = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const attributes = await Auth.userAttributes(user);
      console.log("Attributes:", attributes);
      attributes.forEach((attribute) => {
        console.log("attribute", attribute);
        console.log("attribute.Name", attribute.Value.split(","));
        if (attribute.Name === "email") {
          setEmail(attribute.Value);
          setOriginalEmail(attribute.Value);
        } else if (attribute.Name === "given_name") {
          setFirstName(attribute.Value);
        } else if (attribute.Name === "family_name") {
          setLastName(attribute.Value);
        } else if (attribute.Name === "phone_number") {
          setPhone(attribute.Value);
        } else if (attribute.Name === "address") {
          const address = attribute.Value.split("\n");
          setAddressLine1(address[0]);
          setAddressLine2(address[1] == undefined ? "" : address[1]);
          const cityStateZip = address[2].split(",");
          setCity(cityStateZip[0]);
          setState(cityStateZip[1].split(" ")[1]);
          setZipCode(cityStateZip[1].split(" ")[2]);
        }
      });
      // grab username from cognito
      setUsername(user.username);
    } catch (error) {
      console.error("Error fetching attributes:", error);
    }
  };

  useEffect(() => {
    getUserAttributes();
  }, []);

  return (
    <div>
      <div className="pb-6 text-2xl text-black">Profile</div>
      <div className="grid grid-cols-5">
        <div className="col-span-3 row-start-1 flex grid grid-cols-3 border-b-2 border-gray-200 py-2">
          <div className="col-start-1 text-base text-black">Email</div>
          <div className={`col-span-2 col-start-2 text-sm text-gray-400`}>
            <div>{email}</div>
          </div>
        </div>
        <div className="col-span-3 row-start-2 flex grid grid-cols-3 border-b-2 border-gray-200 py-2">
          <div className="col-start-1 text-base text-black">First Name</div>
          <div
            className={`col-span-2 col-start-2 text-sm ${
              editMode ? "text-black" : "text-gray-400"
            }`}
          >
            {!editMode && <div>{firstName}</div>}

            {editMode && (
              <input
                value={firstName}
                placeholder={"First Name"}
                type="text"
                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                onChange={(e) => setFirstName(e.target.value)}
                maxLength={50}
              />
            )}
          </div>
        </div>
        <div className="col-span-3 row-start-3 flex grid grid-cols-3 border-b-2 border-gray-200 py-2">
          <div className="col-start-1 text-base text-black">Last Name</div>
          <div
            className={`col-span-2 col-start-2 text-sm ${
              editMode ? "text-black" : "text-gray-400"
            }`}
          >
            {!editMode && <div>{lastName}</div>}
            {editMode && (
              <input
                value={lastName}
                placeholder={"Last Name"}
                type="text"
                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                onChange={(e) => setLastName(e.target.value)}
                maxLength={50}
              />
            )}
          </div>
        </div>
        <div className="col-span-3 row-start-4 flex grid grid-cols-3 border-b-2 border-gray-200 py-2">
          <div className="col-start-1 text-base text-black">Phone</div>
          <div
            className={`col-span-2 col-start-2 text-sm ${
              editMode ? "text-black" : "text-gray-400"
            }`}
          >
            {!editMode && (
              <div>
                {parsePhoneNumber(phone) != undefined
                  ? parsePhoneNumber(phone).formatNational()
                  : ""}
              </div>
            )}
            {/* when reading in other apps, install and use this library
            https://www.npmjs.com/package/libphonenumber-js */}
            {editMode && (
              <>
                <PhoneInput
                  placeholder="Phone Number"
                  value={phone}
                  onChange={setPhone}
                  defaultCountry="US"
                  // className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                />
                {/* <input
                  value={phone}
                  placeholder={"Phone Number"}
                  type="text"
                  className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  onChange={(e) => setPhone(e.target.value)}
                  maxLength={50}
                /> */}
              </>
            )}
          </div>
        </div>
        <div className="col-span-3 row-start-5 flex grid grid-cols-3 py-2">
          <div className="col-start-1 text-base text-black">Address</div>
          <div
            className={`col-span-2 col-start-2 text-sm ${
              editMode ? "text-black" : "text-gray-400"
            }`}
          >
            {!editMode && (
              <>
                <div className="-mt-1">{addressLine1}</div>
                <div className="-mt-1">{addressLine2}</div>
                <div className="-mt-1">
                  {city}, {state} {zipCode}
                </div>
              </>
            )}

            {editMode && (
              <>
                <input
                  value={addressLine1}
                  placeholder={"Address Line 1"}
                  type="text"
                  className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  onChange={(e) => setAddressLine1(e.target.value)}
                  maxLength={50}
                />
                <input
                  value={addressLine2}
                  placeholder={"Address Line 2"}
                  type="text"
                  className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  onChange={(e) => setAddressLine2(e.target.value)}
                  maxLength={50}
                />
                <input
                  value={city}
                  placeholder={"City"}
                  type="text"
                  className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  onChange={(e) => setCity(e.target.value)}
                  maxLength={50}
                />
                <input
                  value={state}
                  placeholder={"State"}
                  type="text"
                  className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  onChange={(e) => setState(e.target.value)}
                  maxLength={50}
                />
                <input
                  value={zipCode}
                  placeholder={"Zip Code"}
                  type="text"
                  className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                  onChange={(e) => setZipCode(e.target.value)}
                  maxLength={50}
                />
              </>
            )}
          </div>
        </div>
        <div className="absolute bottom-0 right-0 col-start-5 row-start-6 flex-shrink-0 place-self-end px-4 py-3">
          <div className="flex h-12 items-center justify-between space-x-3">
            {/* {!showSuccessAlert && !showErrorAlert && <div />} */}
            {!editMode && (
              <button
                type="button"
                className="bg-primary hover:bg-secondary focus:outline-none focus:ring-secondary justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:ring-2 focus:ring-offset-2"
                onClick={() => setEditMode(true)}
              >
                Edit
              </button>
            )}
            {editMode && (
              <button
                type="button"
                className={submitButtonClass}
                onClick={() => updateUserAttributes()}
              >
                {!disabled && "Save"}
                {disabled && "Saving"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
