/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  LinkIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import Toggle from "../Toggle";
import Dropdown from "../dropdowns/Dropdown";
import MultiSelectDropdown from "../dropdowns/MultiSelectDropdown";
import { Auth } from "aws-amplify";
import ReactTooltip from "react-tooltip";
import SuccessAlert from "../alerts/SuccessAlert";
import DeactivateAlert from "../alerts/DeactivateAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import Checkbox from "../Checkbox";
import { useHistory } from "react-router-dom";
import CustomInputsTab from "./tabs/CustomInputsTab";
import CustomFAQsTab from "../slideovers/tabs/CustomFAQsTab";
import ElementsTab from "../slideovers/tabs/ElementsTab";
import { SketchPicker, TwitterPicker } from "react-color";
import ImageUploader from "./../uploads/ImageUploader";
import { Storage, Amplify } from "aws-amplify";
import awsconfig from "../../aws-exports";
import axios from "axios";
import SingleSelectDropdown from "../dropdowns/SingleSelectDropdown";
import ProductGroups from "./product/ProductGroups";

import { async } from "q";

// Fundraiser Details Library
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { set } from "../../redux/slices/readOnly";

// ----------------------------------------------

const PRODUCT_NONPROFITS = [
  "ICNA Relief USA",
  "ICNA Relief USA Dev",
  "OBAT Helpers",
  "Rahma Worldwide Aid & Development",
  "Dev",
  ".NGO Charities",
  "Aghosh AlKhidmat USA",
  "Human Development Fund",
];

const HOSTED_PAGE_URL =
  process.env.REACT_APP_ENV === "production"
    ? `https://donate.charitystack.com`
    : `https://dev.d3qaovrr6ze9o9.amplifyapp.com`;

const RECURRING_FREQUENCY_ORDER_MAP = {
  Annually: 0,
  Monthly: 1,
  Weekly: 2,
  Daily: 3,
  "One-Time": 4,
};

const tabs = [
  { name: "Form Options", href: "#formOptions", current: true },
  { name: "Elements", href: "#elements", current: false },
  { name: "Custom Fields", href: "#tax", current: false },
  { name: "FAQs", href: "#faqs", current: false },
  { name: "Tax Receipts", href: "#tax", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`.toUpperCase();
  } else {
    return hexColor.toUpperCase();
  }
};

const defaultAmounts = [10, 25, 50, 100];
const countries = [
  "United States",
  "Canada",
  "United Kingdom",
  "Australia",
  "France",
  "Lebanon",
  "Kuwait",
];

export default function EditFormSlideover(props) {
  const editorRef = useRef(null);
  const [hasHoveredEditor, setHasHoveredEditor] = useState(false);
  // above two states are used to handle the focus for the editor
  // editform is weird because of how we update the state from the db
  // so to prevent the focus fight between it and the slideover
  // we disable the focus in the useEffect below until the users mouse gestures over it

  const [jwtToken, setJWTToken] = useState("");
  const [formName, setFormName] = useState("");
  const [elementID, setElementID] = useState("");

  // Fundraiser Details states and useEffect
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    let markup = draftToHtml(rawContentState);
    console.log("markup", markup);
    // if markup is empty, set it to an empty string
    if (markup.trim() === "<p></p>") {
      markup = "";
    }
    // replace all <p></p> with <br>
    markup = markup.replace(/<p><\/p>/g, "<br>");
    console.log("markup2", markup);
    setConvertedContent(markup);
  }, [editorState]);

  // this is used to handle the focus for the editor
  useEffect(() => {
    // The code inside this block will run after the component is mounted

    // Access and check the current value of the ref
    if (editorRef.current !== null && hasHoveredEditor === false) {
      editorRef.current.editor.blur();
      // The ref has been set by the component that is mounted
      console.log("Ref is now set:", editorRef.current);
    }
  }, [editorRef.current, editorState]); // Make sure to include the ref in the dependency array

  // ------------------------------

  const [defaultDonationType, setDefaultDonationType] = useState("Monthly");
  const [merchants, setMerchants] = useState([""]);
  const [merchantName, setMerchantName] = useState("");
  const [oneTimeAmount1, setOneTimeAmount1] = useState("");
  const [oneTimeAmount2, setOneTimeAmount2] = useState("");
  const [oneTimeAmount3, setOneTimeAmount3] = useState("");
  const [oneTimeAmount4, setOneTimeAmount4] = useState("");
  const [monthlyAmount1, setMonthlyAmount1] = useState("");
  const [monthlyAmount2, setMonthlyAmount2] = useState("");
  const [monthlyAmount3, setMonthlyAmount3] = useState("");
  const [monthlyAmount4, setMonthlyAmount4] = useState("");
  const [dailyAmount1, setDailyAmount1] = useState("");
  const [dailyAmount2, setDailyAmount2] = useState("");
  const [dailyAmount3, setDailyAmount3] = useState("");
  const [dailyAmount4, setDailyAmount4] = useState("");
  const [weeklyAmount1, setWeeklyAmount1] = useState("");
  const [weeklyAmount2, setWeeklyAmount2] = useState("");
  const [weeklyAmount3, setWeeklyAmount3] = useState("");
  const [weeklyAmount4, setWeeklyAmount4] = useState("");
  const [annuallyAmount1, setAnuallyAmount1] = useState("");
  const [annuallyAmount2, setAnuallyAmount2] = useState("");
  const [annuallyAmount3, setAnuallyAmount3] = useState("");
  const [annuallyAmount4, setAnuallyAmount4] = useState("");
  const [resizedImage, setResizedImage] = useState(null);
  const [image, setImage] = useState(null);

  const [showOneTimeDefault, setShowOneTimeDefault] = useState(false);
  const [showMonthlyDefault, setShowMonthlyDefault] = useState(false);
  const [showDailyDefault, setShowDailyDefault] = useState(false);
  const [showWeeklyDefault, setShowWeeklyDefault] = useState(false);
  const [showAnnuallyDefault, setshowAnnuallyDefault] = useState(false);
  const [oneTimeDefault, setOneTimeDefault] = useState("");
  const [monthlyDefault, setMonthlyDefault] = useState("");
  const [dailyDefault, setDailyDefault] = useState("");
  const [weeklyDefault, setWeeklyDefault] = useState("");
  const [annuallyDefault, setAnnuallyDefault] = useState("");

  const [separateAmounts, setSeparateAmounts] = useState(true);
  const [requirePhoneNumber, setRequirePhoneNumber] = useState(false);
  const [requireScheduleDonations, setRequireScheduleDonations] =
    useState(false);
  const [requireBillingAddress, setRequireBillingAddress] = useState(false);
  const [requireCountryDropdown, setRequireCountryDropdown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    "Choose Default Country"
  );
  const [funds, setFunds] = useState([]);
  const [fundsSingle, setFundsSingle] = useState("");

  const [showDeactivateAlert, setShowDeactivateAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successAlertMessage, setSuccessAlertMessage] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [active, setActive] = useState(false);
  const [copiedState, setCopiedState] = useState("Copied!");

  const [monthlyChecked, setMonthlyChecked] = useState(false);
  const [oneTimeChecked, setOneTimeChecked] = useState(false);
  const [weeklyChecked, setWeeklyChecked] = useState(false);
  const [dailyChecked, setDailyChecked] = useState(false);
  const [annuallyChecked, setAnnuallyChecked] = useState(false);

  const [checkedValues, setCheckedValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  const [activeTab, setActiveTab] = useState({
    name: "Form Options",
    current: true,
  });
  const [emailCustomization, setEmailCustomization] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [replyToEmailAddress, replyToEmailAddressChange] = useState("");
  const [subject, subjectChange] = useState("");
  const [emailMessage, emailMessageChange] = useState("");

  const [dtdPublicApiKey, setDtdPublicApiKey] = useState(null);
  const [requireDoubleTheDonation, setRequireDoubleTheDonation] =
    useState(false);

  const [customInputs, setCustomInputs] = useState([]);
  const [originalCustomInputs, setOriginalCustomInputs] = useState([]);

  const [showAnonymousDonations, setShowAnonymousDonations] = useState(false);
  const [showOrganizationNameField, setShowOrganizationNameField] =
    useState(false);

  //ADD TO CART && ICNA SPECIFIC FIELDS
  const [showAddToCart, setShowAddToCart] = useState(false);
  const [sourceWebsite, setSourceWebsite] = useState([]);
  const [sourceWebsiteValues, setSourceWebsiteValues] = useState([]);
  const [salesforceCampaignIDICNA, setSalesforceCampaignIDICNA] = useState([]);
  const [salesforceCampaignIDICNAValues, setSalesforceCampaignIDICNAValues] =
    useState("");

  const [crowdFundingFormType, setCrowdFundingFormType] = useState(false);
  const [option1Title, setOption1Title] = useState("");
  const [option1Amount, setOption1Amount] = useState("");
  const [option1Description, setOption1Description] = useState("");
  const [option2Title, setOption2Title] = useState("");
  const [option2Amount, setOption2Amount] = useState("");
  const [option2Description, setOption2Description] = useState("");
  const [option3Title, setOption3Title] = useState("");
  const [option3Amount, setOption3Amount] = useState("");
  const [option3Description, setOption3Description] = useState("");
  const [option4Title, setOption4Title] = useState("");
  const [option4Amount, setOption4Amount] = useState("");
  const [option4Description, setOption4Description] = useState("");

  const [customFAQs, setCustomFAQs] = useState([]);
  const [originalCustomFAQs, setOriginalCustomFAQs] = useState([]);
  const [fundraiserType, setFundraiserType] = useState("");
  const [elements, setElements] = useState([]);
  const [originalElements, setOriginalElements] = useState([]);

  const [createdForm, setCreatedForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [elementsSaved, setElementsSaved] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);

  // Color
  const [color, setColor] = useState("");
  const [sketchPickerColor, setSketchPickerColor] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const { r, g, b, a } = sketchPickerColor;
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  // flag
  const [flagPosition, setFlagPosition] = useState("");
  const [flagPageUrl, setFlagPageUrl] = useState("");

  const [flagDestination, setFlagDestination] = useState("");

  // Image
  const [fundraiserImage, setFundraiserImage] = useState("");
  const [file, setFile] = useState();
  const [baseLogo, setBaseLogo] = useState("");
  const [fullLogo, setFullLogo] = useState("");
  const [submit, setSubmit] = useState(false);
  const [imageUploadError, setImageUploadError] = useState(false);

  // Products
  const [productGroups, setProductGroups] = useState([]);
  const [originalProductGroups, setOriginalProductGroups] = useState([]);

  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
    setFundraiserImage(e.target.files[0]);
  }
  function handleRemove() {
    setFile(null);
    setFundraiserImage("");
  }

  // resetting show add to cart for OBAT and Rahma
  useEffect(() => {
    if (fundraiserType !== "PRODUCT") {
      if (
        merchants.includes("OBAT Helpers") ||
        merchants.includes("Rahma Worldwide Aid & Development") ||
        merchants.includes("Dev") ||
        merchants.includes(".NGO Charities") ||
        merchants.includes("Aghosh AlKhidmat USA") ||
        merchants.includes("Human Development Fund")
      ) {
        setShowAddToCart(false);
      }
    }
  }, [fundraiserType]);

  useEffect(() => {
    // Check if merchant is "ICNA Relief" and props.selectableForms exists
    console.log("in useeffect on edit");
    if (props.merchant && props.merchant["merchantName"]) {
      console.log("IF in useeffect on edit");

      const merchantName = props.merchant["merchantName"];
      console.log("Merchant Name:", merchantName); // Log the merchantName for debugging
      if (
        (merchantName === "ICNA Relief USA" ||
          merchantName === "ICNA Relief USA Dev") &&
        props.selectableForms
      ) {
        // Print the value of selectableForms for debugging
        console.log("Selectable Forms:", props.selectableForms);

        let salesforceCampaignID = [];
        let sourceWebsiteHolder = [];

        if (Array.isArray(props.selectableForms)) {
          console.log("props.selectableForms is an array");
          props.selectableForms.forEach((form, index) => {
            console.log(`Processing form ${index + 1}:`, form);
            // Check if the form has salesforceCampaignID and it's not empty
            if (
              typeof form.salesforceCampaignID === "string" &&
              form.salesforceCampaignID.trim() !== ""
            ) {
              if (
                !salesforceCampaignID.includes(form.salesforceCampaignID.trim())
              ) {
                console.log(
                  "Adding salesforceCampaignIDItem:",
                  form.salesforceCampaignID.trim()
                );
                salesforceCampaignID.push(form.salesforceCampaignID.trim());
              }
            }
            if (
              typeof form.sourceWebsite === "string" &&
              form.sourceWebsite.trim() !== ""
            ) {
              if (!sourceWebsiteHolder.includes(form.sourceWebsite.trim())) {
                console.log(
                  "Adding sourceWebsiteItem:",
                  form.sourceWebsite.trim()
                );
                sourceWebsiteHolder.push(form.sourceWebsite.trim());
              }
            } else {
              console.log(
                "form.salesforceCampaignID or form.sourceWebsite is not a valid format:",
                form.salesforceCampaignID,
                form.sourceWebsite
              );
            }
          });
        } else {
          console.log("props.selectableForms is not an array");
        }

        console.log("Campaign IDs:", salesforceCampaignID);
        console.log("Source Websites:", sourceWebsiteHolder);
        // Set the salesforceCampaignIDOptions in your state (replace setSalesforceCampaignIDICNA with the appropriate setter)
        console.log("setting sourceWebsite", sourceWebsiteHolder);
        setSalesforceCampaignIDICNA(salesforceCampaignID);
        setSourceWebsite(sourceWebsiteHolder);
        console.log(" sourceWebsite set", sourceWebsite);
      } else {
        console.log("Not ICNA, Dev, or Charities", merchantName);
      }
    }
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
  }, [props.merchant, props.selectableForms]);

  useEffect(() => {
    console.log("New state for sourceWebsiteValues:", sourceWebsiteValues);
  }, [sourceWebsiteValues]);

  useEffect(() => {
    Amplify.configure(awsconfig);
    // Check if elementID is not an empty string
    if (elementID && props.merchant && props.merchant["merchantName"]) {
      let merchantName = props.merchant["merchantName"];
      let Logo = elementID;
      let customFilename = `${merchantName}_${Logo}.png`;
      let fullLogoLink = `${process.env.REACT_APP_S3_LOGO_URL}${customFilename}`;
      setFullLogo(fullLogoLink);
      setBaseLogo(customFilename);
    }
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
  }, [elementID, props.merchant]);

  const variableClick = (e) => {
    let currentValueOfCustomMessage = emailMessage;
    //this adds a space charecter when the variable button list is clicked
    let newValueOfCustomMessage = currentValueOfCustomMessage + " " + e;

    emailMessageChange(newValueOfCustomMessage);
  };
  const recurringOptions = ["Annually", "Monthly", "Weekly", "Daily"];

  // const handleCheckboxChange = (value, isChecked) => {
  //   let newCheckedValues;

  //   if (isChecked) {
  //     newCheckedValues = [...checkedValues, value];
  //   } else {
  //     newCheckedValues = checkedValues.filter((v) => v !== value);

  //     // Uncheck recurring value
  //     const recurringOptions = ["Annually", "Monthly", "Weekly", "Daily"];
  //     if (recurringOptions.includes(value)) {
  //       newCheckedValues = newCheckedValues.filter((v) => !recurringOptions.includes(v));
  //     }
  //   }

  //   // Show all options if One-Time is selected
  //   if (newCheckedValues.includes("One-Time")) {
  //     setCheckedValues(newCheckedValues);
  //     return;
  //   }

  //   // Hide recurring options if a recurring value is selected and One-Time is not selected
  //   const hasRecurringValue = recurringOptions.some((v) => newCheckedValues.includes(v));
  //   if (hasRecurringValue) {
  //     newCheckedValues = newCheckedValues.filter((v) => recurringOptions.includes(v));

  //     // Only allow one recurring value if One-Time is not selected
  //     if (!newCheckedValues.includes("One-Time") && newCheckedValues.length > 1) {
  //       newCheckedValues = [value];
  //     }
  //   }

  //   setCheckedValues(newCheckedValues);
  // };

  const handleFrequencyRadioChange = (value) => {
    const newCheckedValues = [value]; // Create an array with the selected value

    // Update the selected state variables based on the selected value
    setOneTimeChecked(value === "One-Time");
    setMonthlyChecked(value === "Monthly");
    setWeeklyChecked(value === "Weekly");
    setDailyChecked(value === "Daily");
    setAnnuallyChecked(value === "Annually");

    // Sort the selected values if needed (similar to your existing logic)
    newCheckedValues.sort((a, b) => {
      const weightA = RECURRING_FREQUENCY_ORDER_MAP[a];
      const weightB = RECURRING_FREQUENCY_ORDER_MAP[b];
      if (weightA === weightB) {
        return a.localeCompare(b);
      } else {
        return weightB - weightA;
      }
    });

    // Update the state with the new selected value
    setCheckedValues(newCheckedValues);
  };

  const handleFrequencyCheckboxChange = (value, isChecked) => {
    let newCheckedValues;
    if (value == "One-Time") {
      setOneTimeChecked(isChecked);
    }
    if (value == "Monthly") {
      setMonthlyChecked(isChecked);
    }
    if (value == "Weekly") {
      setWeeklyChecked(isChecked);
    }
    if (value == "Daily") {
      setDailyChecked(isChecked);
    }
    if (value == "Annually") {
      setAnnuallyChecked(isChecked);
    }
    console.log("value is: ", value, "isChecked is: ", isChecked);
    if (isChecked) {
      newCheckedValues = [...checkedValues, value];
    } else {
      newCheckedValues = checkedValues.filter((v) => v !== value);
      // newCheckedValues = [value];
    }

    newCheckedValues.sort((a, b) => {
      const weightA = RECURRING_FREQUENCY_ORDER_MAP[a];
      const weightB = RECURRING_FREQUENCY_ORDER_MAP[b];
      console.log("weighta", weightA);
      console.log("weightb", weightB);
      if (weightA === weightB) {
        return a.localeCompare(b);
      } else {
        return weightB - weightA;
      }
    });
    console.log("newCheckedValues: ", newCheckedValues);
    setCheckedValues(newCheckedValues);
  };

  // internal functions
  const checkFormErrors = () => {
    //salesforce error checking
    if (
      merchantName === "ICNA Relief USA" ||
      merchantName === "ICNA Relief USA Dev" // &&
      //showAddToCart === true
    ) {
      // Check if salesforceCampaignIDICNAValues is not empty
      if (
        !salesforceCampaignIDICNAValues ||
        salesforceCampaignIDICNAValues.length === 0
      ) {
        return "Please select or input a Campaign ID";
      }

      if (salesforceCampaignIDICNAValues[0].length !== 18) {
        return "Campaign IDs must be 18 characters long";
      }
    }

    if (
      showAddToCart === true &&
      (!sourceWebsiteValues || sourceWebsiteValues.length === 0)
    ) {
      return "Please select or input a Source Website";
    }

    if (fundraiserType === "PRODUCT") {
      if (productGroups.length === 0) {
        return "At least one product group is required";
      }
      for (let i = 0; i < productGroups.length; i++) {
        if (productGroups[i].productGroupName === "") {
          return "Product Group Name is required";
        }
        if (productGroups[i].products.length === 0) {
          return "At least one product is required for each product group";
        }
        for (let j = 0; j < productGroups[i].products.length; j++) {
          if (productGroups[i].products[j].productName === "") {
            return "One or more product names are missing";
          }
          if (productGroups[i].products[j].productPrice === "") {
            return "One or more product prices are missing";
          }
        }
      }
    }

    if (imageUploadError === true) {
      return "Image Upload Error";
    }
    if (fundraiserType === "DONATION_FLAG") {
      //  console.log("hello");
    }
    if (fundraiserType === "DONATION_FLAG" && flagPosition === "") {
      return "A Flag position is required";
    }
    if (fundraiserType === "DONATION_FLAG" && flagDestination === "") {
      return "A Flag destination is required";
    }
    if (fundraiserType === "DONATION_FLAG" && flagPageUrl === "") {
      //crowdfunding is the selected form type and a required field is blank
      return "A Flag URL is required";
    }

    // Initialize an object to keep track of element types
    const elementTypesCount = {};

    for (let i = 0; i < elements.length; i++) {
      console.log(elements);

      // Add the uniqueness check here
      if (elementTypesCount[elements[i].type]) {
        return "Only one type of element allowed per fundraiser";
      }

      // Increment the count for the current element type
      elementTypesCount[elements[i].type] =
        (elementTypesCount[elements[i].type] || 0) + 1;

      // Your existing validation conditions...
      if (elements[i].type === "Select an Element") {
        return "Element Type is required";
      }

      if (
        (elements[i].type === "FUNDRAISING_BAR" &&
          (elements[i].goal === "" || elements[i].embedSize === "")) ||
        (elements[i].type === "QR_CODE" && elements[i].qrCodeType === "") ||
        (elements[i].type === "QR_CODE" &&
          elements[i].url === "" &&
          elements[i].qrCodeType === "CUSTOM_PAGE") ||
        (elements[i].type === "DONOR_LIST" && elements[i].listType === "")
      ) {
        return "Element details are required";
      }
    }

    if (
      (fundraiserType === "DONATION_FORM" ||
        fundraiserType === "DONATION_FLAG") &&
      (crowdFundingFormType === "" || crowdFundingFormType === "QUICK_DONATE")
    ) {
      return "An Amount Button Type is required";
    }

    // console.log("checkedValues: ", checkedValues, "selectedValue: ", selectedValue)
    if (
      crowdFundingFormType === "CROWDFUNDING_FORM" &&
      (option1Amount === "" ||
        option1Description === "" ||
        option1Title === "" ||
        option2Amount === "" ||
        option2Description === "" ||
        option2Title === "" ||
        option3Amount === "" ||
        option3Description === "" ||
        option3Title === "" ||
        option4Amount === "" ||
        option4Description === "" ||
        option4Title === "")
    ) {
      //crowdfunding is the selected form type and a required field is blank
      return "All Titles, Amounts, and Descriptions must be filled out for the Buttons selection";
    }
    if (checkedValues.length < 1) {
      return "At least one donation frequency must be selected";
    }
    if (
      checkedValues.length > 1 &&
      (selectedValue === "" ||
        selectedValue === null ||
        selectedValue === "Select an option")
    ) {
      return "A default frequency is required";
    }
    if (formName === "") {
      return "Form Name is required";
    }
    if (
      !oneTimeChecked &&
      !dailyChecked &&
      !weeklyChecked &&
      !monthlyChecked &&
      !annuallyChecked
    ) {
      return "At least one donation frequency must be selected";
    }
    if (
      fundraiserType !== "QUICK_DONATE" &&
      crowdFundingFormType === "EMBED_FORM"
    ) {
      if (
        (oneTimeAmount1 == "" ||
          oneTimeAmount2 == "" ||
          oneTimeAmount3 == "" ||
          oneTimeAmount4 == "") &&
        oneTimeChecked
      ) {
        if (separateAmounts) {
          return "One-Time amounts are required";
        }
      }
      if (
        (weeklyAmount1 == "" ||
          weeklyAmount2 == "" ||
          weeklyAmount3 == "" ||
          weeklyAmount4 == "") &&
        weeklyChecked
      ) {
        if (separateAmounts) {
          return "Weekly amounts are required";
        }
      }
      if (
        (dailyAmount1 == "" ||
          dailyAmount2 == "" ||
          dailyAmount3 == "" ||
          dailyAmount4 == "") &&
        dailyChecked
      ) {
        if (separateAmounts) {
          return "Daily amounts are required";
        }
      }
      if (
        (annuallyAmount1 == "" ||
          annuallyAmount2 == "" ||
          annuallyAmount3 == "" ||
          annuallyAmount4 == "") &&
        annuallyChecked
      ) {
        if (separateAmounts) {
          return "Annual amounts are required";
        }
      }
      if (
        (monthlyAmount1 == "" ||
          monthlyAmount2 == "" ||
          monthlyAmount3 == "" ||
          monthlyAmount4 == "") &&
        monthlyChecked
      ) {
        if (separateAmounts) {
          return "Monthly amounts are required";
        }
      }
    }
    if (oneTimeDefault == "" && showOneTimeDefault) {
      return 'Please provide a default One-Time amount, or deselect the "Set One-Time Default" toggle';
    }
    if (monthlyDefault == "" && showMonthlyDefault) {
      return 'Please provide a default Monthly amount, or deselect the "Set Monthly Default" toggle';
    }
    if (dailyDefault == "" && showDailyDefault) {
      return 'Please provide a default Daily amount, or deselect the "Set Daily Default" toggle';
    }
    if (weeklyDefault == "" && showWeeklyDefault) {
      return 'Please provide a default Weekly amount, or deselect the "Set Weekly Default" toggle';
    }
    if (annuallyDefault == "" && showAnnuallyDefault) {
      return 'Please provide a default Annually amount, or deselect the "Set Annually Default" toggle';
    }
    if (color.length != 7) {
      return "Color is required";
    }
    if (funds.length == 0) {
      return "At least one fund is required";
    }
    if (fundraiserType === "") {
      return "A fundraiser type is required";
    }
    //    if (fundraiserType != "" && crowdFundingFormType === "CROWDFUNDING_FORM") {
    //    return "An amount button type is required";
    //}
    //iterate through customInputs and check for errors
    for (let i = 0; i < customInputs.length; i++) {
      if (customInputs[i].title == "") {
        return "Custom Input Label is required";
      }
      if (customInputs[i].type == "Select an Input Type") {
        return "Custom Input Type is required";
      }
      if (
        (customInputs[i].type == "SINGLE_SELECTION_DROPDOWN" ||
          customInputs[i].type == "SINGLE_SELECTION_DROPDOWN") &&
        customInputs[i].options.length < 2
      ) {
        return "2 or more Custom Input Options are required for Dropdowns";
      }
    }
    for (let i = 0; i < customFAQs.length; i++) {
      if (customFAQs[i].question == "" || customFAQs[i].answer === "") {
        return "FAQ details are required";
      }
    }

    return "";
  };

  const copyToClipboard = () => {
    const url = `${HOSTED_PAGE_URL}/${elementID}`;
    navigator.clipboard.writeText(url);
  };

  const changeEmailSettings = async () => {
    if (
      emailCustomization === true &&
      (emailMessage === "" || subject === "" || replyToEmailAddress === "")
    ) {
      setErrorMessage("Tax Receipts is missing required fields");
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      setIsDisabled(false);
      return;
    }

    const payload = {
      replyToEmailAddress: replyToEmailAddress,
      subject: subject,
      emailMessage: emailMessage,
      requestType: "FORMS_PAGE",
      formName: formName,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(payload),
    };

    const deletePayload = {
      replyToEmailAddress: replyToEmailAddress,
      subject: subject,
      emailMessage: emailMessage,
      requestType: "FORMS_PAGE",
      formName: formName,
      merchantName: merchantName,
    };
    const queryParams = Object.entries(deletePayload)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    const requestOptionsDelete = {
      method: "DELETE",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(deletePayload),
    };

    const url = process.env.REACT_APP_EMAIL_CONFIG_URL;
    if (emailCustomization === false && imageUploadError === false) {
      console.log("reverting to default template");
      console.log("no image errors");
      let response = await fetch(url + "?" + queryParams, requestOptionsDelete);
      console.log(response);

      let data = await response.json();
      console.log({ data });
      if (data.error) {
        setIsDisabled(false);
        setErrorMessage(data.error);
        setShowErrorAlert(true);
        // unshowing success alert if it was never closed out
        setShowSuccessAlert(false);
        return;
      } else {
        props.grabSelectableFunds(jwtToken);
        props.updateData();
        setIsDisabled(false);
        setSuccessAlertMessage("Fundraiser Saved Successfully");
        // unshowing error alert if it was never closed out
        setShowErrorAlert(false);
        setShowSuccessAlert(true);
      }
    }

    if (emailCustomization === true) {
      let response = await fetch(url, requestOptions);
      let data = await response.json();
      console.log({ data });
      if (data.error) {
        setIsDisabled(false);
        setErrorMessage(data.error);
        setShowErrorAlert(true);
        // unshowing success alert if it was never closed out
        setShowSuccessAlert(false);
        return;
      } else {
        props.grabSelectableFunds(jwtToken);
        props.updateData();
        setIsDisabled(false);
        setSuccessAlertMessage("Fundraiser Saved Successfully");
        // unshowing error alert if it was never closed out
        setShowErrorAlert(false);
        setShowSuccessAlert(true);
      }
    }
    if (imageUploadError === true) {
      setIsDisabled(false);
      setErrorMessage("Image Upload Error");
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
    }
  };

  const updateForm = async () => {
    let returnedError = checkFormErrors();
    console.log(returnedError);
    if (returnedError != "") {
      setErrorMessage(returnedError);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    }

    const darkColor = newShade(color, -50);

    let oneTimeAmounts = [
      oneTimeAmount1,
      oneTimeAmount2,
      oneTimeAmount3,
      oneTimeAmount4,
    ];
    let weeklyAmounts = [
      weeklyAmount1,
      weeklyAmount2,
      weeklyAmount3,
      weeklyAmount4,
    ];
    let dailyAmounts = [dailyAmount1, dailyAmount2, dailyAmount3, dailyAmount4];
    let annuallyAmounts = [
      annuallyAmount1,
      annuallyAmount2,
      annuallyAmount3,
      annuallyAmount4,
    ];
    let monthlyAmounts = [
      monthlyAmount1,
      monthlyAmount2,
      monthlyAmount3,
      monthlyAmount4,
    ];

    oneTimeAmounts = oneTimeAmounts.map((amount, index) => {
      if (amount === "") {
        amount = defaultAmounts[index];
      }
      return parseFloat(amount);
    });

    weeklyAmounts = weeklyAmounts.map((amount, index) => {
      if (amount === "") {
        amount = defaultAmounts[index];
      }
      return parseFloat(amount);
    });

    dailyAmounts = dailyAmounts.map((amount, index) => {
      if (amount === "") {
        amount = defaultAmounts[index];
      }
      return parseFloat(amount);
    });

    annuallyAmounts = annuallyAmounts.map((amount, index) => {
      if (amount === "") {
        amount = defaultAmounts[index];
      }
      return parseFloat(amount);
    });
    monthlyAmounts = monthlyAmounts.map((amount, index) => {
      if (amount === "") {
        amount = defaultAmounts[index];
      }
      return parseFloat(amount);
    });

    const mapping = {
      "One-Time": "ONE_TIME",
      Daily: "DAILY",
      Weekly: "WEEKLY",
      Monthly: "MONTHLY",
      Annually: "ANNUALLY",
      "": "",
    };

    let defaultFrequency = mapping[selectedValue];
    let frequencies1 = ["MONTHLY", "ONE_TIME", "ANNUALLY", "WEEKLY", "DAILY"];
    let frequencies = checkedValues.map((value) => {
      switch (value) {
        case "One-Time":
          return "ONE_TIME";
        case "Weekly":
          return "WEEKLY";
        case "Daily":
          return "DAILY";
        case "Monthly":
          return "MONTHLY";
        case "Annually":
          return "ANNUALLY";
        default:
          return null; // or throw an error if you want to handle invalid values
      }
    });
    let showScheduleDonations = requireScheduleDonations;
    if (
      !(
        checkedValues.includes("Daily") ||
        checkedValues.includes("Weekly") ||
        checkedValues.includes("Monthly") ||
        checkedValues.includes("Annually")
      )
    ) {
      showScheduleDonations = false;
    }

    // set showScheduleDonations to false if no frequencies are selected

    let formType = "";
    let allOptionsDescriptionsAndAmounts = [];

    if (crowdFundingFormType === "CROWDFUNDING_FORM") {
      allOptionsDescriptionsAndAmounts = [
        {
          amount: option1Amount,
          title: option1Title,
          description: option1Description,
        },
        {
          amount: option2Amount,
          title: option2Title,
          description: option2Description,
        },
        {
          amount: option3Amount,
          title: option3Title,
          description: option3Description,
        },
        {
          amount: option4Amount,
          title: option4Title,
          description: option4Description,
        },
      ];
    } else if (crowdFundingFormType === "CROWDFUNDING_FORM") {
    }

    console.log("OneTimeDefault :", oneTimeDefault);
    console.log("weekly default :", weeklyDefault, parseFloat(weeklyDefault));
    console.log("daily default :", dailyDefault);
    console.log("annual default :", annuallyDefault);

    setIsDisabled(true);
    let newElements = elements.map((element) => ({
      ...element,
      isSaved: true,
    }));

    let newfund;
    console.log("fundraiserType", fundraiserType);
    if (funds.length > 0) {
      newfund = funds;
    } else {
      // Handle the case when neither condition is met
      newfund = [];
    }

    const payload = {
      formName: formName,
      //create new value for type of fundraiser independent of formType
      fundraiserType: fundraiserType,
      //holds string value as expected
      formType: crowdFundingFormType,
      crowdfundingOptions: allOptionsDescriptionsAndAmounts,
      //   "defaultMonthly": defaultMonthly,
      defaultFrequency: defaultFrequency,
      frequencies: frequencies,
      merchantName: merchantName,
      oneTimeAmounts: oneTimeAmounts,
      monthlyAmounts: monthlyAmounts,
      weeklyAmounts: weeklyAmounts,
      dailyAmounts: dailyAmounts,
      annuallyAmounts: annuallyAmounts,
      oneTimeDefaultAmount:
        showOneTimeDefault && oneTimeChecked ? parseFloat(oneTimeDefault) : 0,
      weeklyDefaultAmount:
        showWeeklyDefault && weeklyChecked ? parseFloat(weeklyDefault) : 0,
      dailyDefaultAmount:
        showDailyDefault && dailyChecked ? parseFloat(dailyDefault) : 0,
      annuallyDefaultAmount:
        showAnnuallyDefault && annuallyChecked
          ? parseFloat(annuallyDefault)
          : 0,
      monthlyDefaultAmount:
        showMonthlyDefault && monthlyChecked ? parseFloat(monthlyDefault) : 0,
      // do something similar for option1title, option1amount, option1description
      // maybe convert option1amount to int
      requirePhoneNumber: requirePhoneNumber,
      requireScheduleDonations: showScheduleDonations,
      requireBillingAddress: requireBillingAddress,
      requireCountryDropdown: requireCountryDropdown,
      selectedCountry: selectedCountry,
      funds: newfund,
      lightColor: color,
      darkColor: darkColor,
      requireDoubleTheDonation: requireDoubleTheDonation,
      dtdPublicApiKey: dtdPublicApiKey,
      customInputs: customInputs,
      elementID: elementID,
      faqs: customFAQs,
      elements: newElements,
      showAnonymousDonations: showAnonymousDonations,
      showOrganizationNameField: showOrganizationNameField,
      showAddToCart: showAddToCart,
      flagDestination: flagDestination,
      flagPageUrl: flagPageUrl,
      flagPosition: flagPosition,
      details: convertedContent,
      productGroups: productGroups,
    };

    if (sourceWebsiteValues !== "") {
      payload.sourceWebsite = sourceWebsiteValues.toString();
    }

    if (salesforceCampaignIDICNAValues !== "") {
      payload.salesforceCampaignID = salesforceCampaignIDICNAValues.toString();
    }

    console.log("payload is as follows: ", payload);

    const requestOptions = {
      method: "PATCH",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(payload),
    };

    const url = process.env.REACT_APP_FORMS_URL;
    console.log("im right here url: ", url);
    let response = await fetch(url, requestOptions);
    let data = await response.json();
    console.log(data.error);

    if (data.error) {
      setIsDisabled(false);
      setErrorMessage(data.error);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    } else {
      // now update tax receipt settings
      changeEmailSettings();
      if (resizedImage !== null) {
        console.log("rezied is not null its ", resizedImage);
        handleSubmit();
      }

      // props.grabSelectableFunds(jwtToken)
      // props.updateData()
      // setSuccessAlertMessage('Form Saved Successfully')
      // // unshowing error alert if it was never closed out
      // setShowErrorAlert(false)
      // setShowSuccessAlert(true)
    }
    // success. retrieve element_type_to_id mapping, which maps each element type to its element id
    // update the elements state by iterating through and updating any matching "type"'s id
    const elementsCopy = [...elements];
    const element_type_to_id = data["element_type_to_id"];
    for (let i = 0; i < elementsCopy.length; i++) {
      if (element_type_to_id[elementsCopy[i]["type"]]) {
        elementsCopy[i]["id"] = element_type_to_id[elementsCopy[i]["type"]];
      }
    }
    setElements(elementsCopy);

    setSubmit(true);
    setElementsSaved(true);
    setElementsSaved(false);
  };

  const handleSubmit = async () => {
    console.log("resizedImage", resizedImage);

    const requestOptions = {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        image_data: `${resizedImage}`,
        image_name: `${merchantName}_${elementID}`,
        image_type: `${"fundraiser"}`,
        nonprofitName: `${merchantName}`,
      }),
    };
    console.log(requestOptions.body);

    // submitting transaction to donors-data lambda
    try {
      let response = await fetch(
        process.env.REACT_APP_IMAGE_UPLOAD_URL,
        requestOptions
      );
      let responseBody = await response.json();
      if (!response.ok) {
        setShowErrorAlert(true);
        setErrorMessage(responseBody);
      } else {
        setShowErrorAlert(false);
        setErrorMessage("");
      }
      console.log("responseBody", responseBody);
    } catch (error) {
      setShowErrorAlert(true);
      setErrorMessage(error.message);
    }
  };

  const activateForm = async (e) => {
    e.preventDefault();
    const state = e.target.name == "activate" ? true : false;
    const successAlertMessage = state
      ? "Fundraiser Activated Successfully"
      : "Fundraiser Deactivated Successfully";
    const payload = {
      formName: formName,
      merchantName: merchantName,
      activate: state,
      elementID: elementID,
    };
    const requestOptions = {
      method: "PATCH",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(payload),
    };

    const url = process.env.REACT_APP_FORMS_URL;

    let response = await fetch(url, requestOptions);
    let data = await response.json();
    console.log(data.error);

    if (data.error) {
      setErrorMessage(data.error);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    } else {
      props.updateData();
      setActive(state);
      setSuccessAlertMessage(successAlertMessage);
      setShowSuccessAlert(true);
      setShowErrorAlert(false);
    }
  };

  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      const formattedMerchants = data["idToken"]["payload"][
        "cognito:groups"
      ].map((merchant) => merchant.replace(/-/g, " "));

      setJWTToken(data["idToken"]["jwtToken"]);
      setMerchants(formattedMerchants);
      setMerchantName(formattedMerchants[0]);
    };
    myFunction();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
  }, [props.open]);

  useEffect(() => {
    if (checkedValues.length > 1 && selectedValue === "") {
      setSelectedValue("Select an option");
    } else if (
      checkedValues.length > 1 &&
      checkedValues.includes(selectedValue) === false
    ) {
      setSelectedValue("Select an option");
    } else if (checkedValues.length === 0) {
      setSelectedValue("Select an option");
    } else if (checkedValues.length === 1) {
      console.log("seeting checked value", checkedValues[0]);
      setSelectedValue(checkedValues[0]);
    }
  }, [checkedValues]);

  useEffect(() => {
    const myFunction = async () => {
      let auth = jwtToken;
      if (props.merchant !== null) {
        if (auth === "") {
          const data = await Auth.currentSession();
          setJWTToken(data["idToken"]["jwtToken"]);
          auth = data["idToken"]["jwtToken"];
        }

        // grab double the donation api key if it exists
        const requestOptions = {
          method: "GET",
          headers: {
            accept: "*/*",
            "content-type": "application/json",
            Authorization: auth,
          },
        };

        const params = new URLSearchParams();
        params.append("nonprofit", props.merchant["merchantName"]);
        const url = process.env.REACT_APP_CS_DTD_SERVER_URL + "/api-keys";

        const response = await fetch(
          url + `?${params.toString()}`,
          requestOptions
        );

        let responseData = await response.json();
        // console.log('responseData is', responseData['publicApiKey'])
        setDtdPublicApiKey(responseData["publicApiKey"]);
      }
    };
    myFunction();
  }, [props.merchant]);

  // Clear states when modal is closed
  const clearStates = () => {
    // close slideover
    props.setOpen(false);

    // return to default states
    setFormName("");
    setElementID("");
    setDefaultDonationType("Monthly");
    setSeparateAmounts(true);
    setOneTimeAmount1("");
    setOneTimeAmount2("");
    setOneTimeAmount3("");
    setOneTimeAmount4("");
    setMonthlyAmount1("");
    setMonthlyAmount2("");
    setMonthlyAmount3("");
    setMonthlyAmount4("");
    setDailyAmount1("");
    setDailyAmount2("");
    setDailyAmount3("");
    setDailyAmount4("");
    setWeeklyAmount1("");
    setWeeklyAmount2("");
    setWeeklyAmount3("");
    setWeeklyAmount4("");
    setAnuallyAmount1("");
    setAnuallyAmount2("");
    setAnuallyAmount3("");
    setAnuallyAmount4("");

    setShowOneTimeDefault(false);
    setShowMonthlyDefault(false);
    setShowDailyDefault(false);
    setShowWeeklyDefault(false);
    setshowAnnuallyDefault(false);
    setOneTimeDefault("");
    setMonthlyDefault("");
    setDailyDefault("");
    setWeeklyDefault("");
    setAnnuallyDefault("");

    setRequirePhoneNumber(false);
    setRequireScheduleDonations(false);
    setRequireBillingAddress(false);
    setRequireCountryDropdown(false);
    setSelectedCountry("Choose Default Country");
    setFunds([]);
    setFundsSingle("");
    setColor("");
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
    setMonthlyChecked(false);
    setOneTimeChecked(false);
    setWeeklyChecked(false);
    setAnnuallyChecked(false);
    setDailyChecked(false);
    setCheckedValues([]);
    setRequireDoubleTheDonation(false);
    setCustomInputs([]);
    setActiveTab({ name: "Form Options", current: true });
    setCrowdFundingFormType(false);
    setOption1Amount("");
    setOption1Description("");
    setOption1Title("");
    setOption2Amount("");
    setOption2Description("");
    setOption2Title("");
    setOption3Amount("");
    setOption3Description("");
    setOption3Title("");
    setOption4Amount("");
    setOption4Description("");
    setOption4Title("");
    setCustomFAQs([]);
    setOriginalCustomFAQs([]);
    setShowAnonymousDonations(false);
    setShowOrganizationNameField(false);
    setElements([]);
    setOriginalElements([]);
    setShowAnonymousDonations(false);
    setShowAddToCart(false);

    setColor("");
    setFlagDestination("");
    setFlagPageUrl("");
    setFlagPosition("");
    setSketchPickerColor({
      r: "241",
      g: "112",
      b: "19",
      a: "1",
    });
    setEditorState(() => EditorState.createEmpty());
    setConvertedContent(null);
    setHasHoveredEditor(false);
    setProductGroups([]);
    setOriginalProductGroups([]);
  };

  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      const formattedMerchants = data["idToken"]["payload"][
        "cognito:groups"
      ].map((merchant) => merchant.replace(/-/g, " "));

      setJWTToken(data["idToken"]["jwtToken"]);
      setMerchants(formattedMerchants);
      setMerchantName(formattedMerchants[0]);
    };
    myFunction();
  }, []);

  useEffect(() => {
    const myFunction = async () => {
      if (props.open == false) {
        // return to default states
        setFormName("");
        setElementID("");

        setDefaultDonationType("Monthly");
        setSeparateAmounts(true);
        setOneTimeAmount1("");
        setOneTimeAmount2("");
        setOneTimeAmount3("");
        setOneTimeAmount4("");
        setMonthlyAmount1("");
        setMonthlyAmount2("");
        setMonthlyAmount3("");
        setMonthlyAmount4("");
        setDailyAmount1("");
        setDailyAmount2("");
        setDailyAmount3("");
        setDailyAmount4("");
        setWeeklyAmount1("");
        setWeeklyAmount2("");
        setWeeklyAmount3("");
        setWeeklyAmount4("");
        setAnuallyAmount1("");
        setAnuallyAmount2("");
        setAnuallyAmount3("");
        setAnuallyAmount4("");

        setShowOneTimeDefault(false);
        setShowMonthlyDefault(false);
        setShowDailyDefault(false);
        setShowWeeklyDefault(false);
        setshowAnnuallyDefault(false);
        setOneTimeDefault("");
        setMonthlyDefault("");
        setDailyDefault("");
        setWeeklyDefault("");
        setAnnuallyDefault("");

        setRequirePhoneNumber(false);
        setRequireScheduleDonations(false);
        setRequireBillingAddress(false);
        setRequireCountryDropdown(false);
        setSelectedCountry("Choose Default Country");
        setFunds([]);
        setFundsSingle("");
        setColor("");
        setShowSuccessAlert(false);
        setShowErrorAlert(false);
        setMonthlyChecked(false);
        setOneTimeChecked(false);
        setWeeklyChecked(false);
        setAnnuallyChecked(false);
        setDailyChecked(false);
        setCheckedValues([]);
        setRequireDoubleTheDonation(false);
        setCustomInputs([]);
        setActiveTab({ name: "Form Options", current: true });

        setCustomFAQs([]);
        setOriginalCustomFAQs([]);
        setShowAnonymousDonations(false);
        setShowOrganizationNameField(false);
        setElements([]);
        setOriginalElements([]);
        setShowAnonymousDonations(false);
        setColor("");
        setFlagDestination("");
        setFlagPageUrl("");
        setFlagPosition("");
        setSketchPickerColor({
          r: "241",
          g: "112",
          b: "19",
          a: "1",
        });
        setShowAddToCart(false);
        setSalesforceCampaignIDICNAValues("");
        setSourceWebsiteValues("");
        setEditorState(() => EditorState.createEmpty());
        setConvertedContent(null);
        setHasHoveredEditor(false);
        setProductGroups([]);
        setOriginalProductGroups([]);
      } else {
        if (props.editFormData != null) {
          console.log("editFormData", props.editFormData);
          const selectedCountry =
            props.editFormData["defaultCountry"] === ""
              ? "Choose Default Country"
              : props.editFormData["defaultCountry"];
          setFormName(props.editFormData["formName"]);

          // fundraiser details load
          setConvertedContent(props.editFormData["details"]);
          var initialHtml = "";

          if (
            props.editFormData["details"] != "" &&
            props.editFormData["details"] != null &&
            props.editFormData["details"] != undefined
          ) {
            initialHtml = props.editFormData["details"];
          }

          const contentBlock = htmlToDraft(initialHtml);
          const initialEditorState = contentBlock
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(contentBlock.contentBlocks)
              )
            : EditorState.createEmpty();

          setEditorState(initialEditorState);
          // fundraiser details load end ------------------------------

          setElementID(props.editFormData["elementID"]);
          setDefaultDonationType(props.editFormData["frequencies"]); /// ? 'Monthly' : 'One-Time')

          //salesforceData
          console.log(
            "Setting salesforce details",
            props.editFormData["showAddToCart"],
            props.editFormData["salesforceCampaignID"],
            props.editFormData["sourceWebsite"]
          );
          setShowAddToCart(props.editFormData["showAddToCart"] ?? false);
          console.log("showaddtocart", showAddToCart);
          setSalesforceCampaignIDICNAValues(
            [props.editFormData["salesforceCampaignID"]] ?? []
          );
          console.log(
            "salesforceCampaignIDValues",
            salesforceCampaignIDICNAValues
          );
          setSourceWebsiteValues([props.editFormData["sourceWebsite"]] ?? []);
          console.log("sourceWebsiteValues", sourceWebsiteValues);

          console.log("salesforce details set");

          //faqs
          // Using nullish coalescing operator
          setCustomFAQs(props.editFormData["faqs"] ?? []);
          setOriginalCustomFAQs(props.editFormData["faqs"] ?? []);
          setElements(props.editFormData["elements"] ?? []);
          setOriginalElements(props.editFormData["elements"] ?? []);
          console.log("hi there", props.editFormData);
          setShowOneTimeDefault(
            props.editFormData["defaultOneTimeAmount"] != 0 ? true : false
          );
          setShowMonthlyDefault(
            props.editFormData["defaultMonthlyAmount"] != 0 ? true : false
          );
          setShowDailyDefault(
            props.editFormData["defaultDailyAmount"] != 0 ? true : false
          );
          setShowWeeklyDefault(
            props.editFormData["defaultWeeklyAmount"] != 0 ? true : false
          );
          setshowAnnuallyDefault(
            props.editFormData["defaultAnnuallyAmount"] != 0 ? true : false
          );

          setOneTimeDefault(
            props.editFormData["defaultOneTimeAmount"] != 0
              ? props.editFormData["defaultOneTimeAmount"]
              : ""
          );
          setMonthlyDefault(
            props.editFormData["defaultMonthlyAmount"] != 0
              ? props.editFormData["defaultMonthlyAmount"]
              : ""
          );
          setDailyDefault(
            props.editFormData["defaultDailyAmount"] != 0
              ? props.editFormData["defaultDailyAmount"]
              : ""
          );
          setWeeklyDefault(
            props.editFormData["defaultWeeklyAmount"] != 0
              ? props.editFormData["defaultWeeklyAmount"]
              : ""
          );
          setAnnuallyDefault(
            props.editFormData["defaultAnnuallyAmount"] != 0
              ? props.editFormData["defaultAnnuallyAmount"]
              : ""
          );

          setRequirePhoneNumber(props.editFormData["phoneNumber"]);
          setRequireScheduleDonations(
            props.editFormData["showScheduleDonation"]
          );
          setRequireBillingAddress(props.editFormData["showAddress"]);
          setRequireCountryDropdown(props.editFormData["showCountries"]);
          setSelectedCountry(selectedCountry);

          if (Array.isArray(props.editFormData["funds"])) {
            //should always be an array moving forward
            //  console.log("is an array");

            //    if (props.editFormData["fundraiserType"] === "QUICK_DONATE") {

            // If "funds" is an array and fundraiserType is "QUICK_DONATE,"
            // set "fundsSingle" to the first element of the array
            //      setFundsSingle(props.editFormData["funds"]);
            //  } else {
            // If "funds" is an array but fundraiserType is not "QUICK_DONATE,"
            // set "funds" to the entire array
            setFunds(props.editFormData["funds"]);
            // }
          } else if (typeof props.editFormData["funds"] === "string") {
            console.log("is a string?");
            // If "funds" is a string, set the "fundsSingle" state
            setFunds([props.editFormData["funds"]]);
          }

          setColor(props.editFormData["lightColor"]);
          setMerchantName(props.editFormData["nonprofitName"]);
          setActive(props.editFormData["active"]);
          setMonthlyChecked(
            props.editFormData["frequencies"].includes("MONTHLY")
          );
          setOneTimeChecked(
            props.editFormData["frequencies"].includes("ONE_TIME")
          );
          setDailyChecked(props.editFormData["frequencies"].includes("DAILY"));
          setWeeklyChecked(
            props.editFormData["frequencies"].includes("WEEKLY")
          );
          setAnnuallyChecked(
            props.editFormData["frequencies"].includes("ANNUALLY")
          );

          const mapping = {
            ONE_TIME: "One-Time",
            DAILY: "Daily",
            WEEKLY: "Weekly",
            MONTHLY: "Monthly",
            ANNUALLY: "Annually",
            "": "",
          };

          let defaultFrequencyHolder =
            mapping[props.editFormData["defaultFrequency"]];

          let frequencies = props.editFormData["frequencies"].map((value) => {
            switch (value) {
              case "ONE_TIME":
                return "One-Time";
              case "WEEKLY":
                return "Weekly";
              case "DAILY":
                return "Daily";
              case "MONTHLY":
                return "Monthly";
              case "ANNUALLY":
                return "Annually";
              default:
                return null; // or throw an error if you want to handle invalid values
            }
          });

          frequencies.sort((a, b) => {
            const weightA = RECURRING_FREQUENCY_ORDER_MAP[a];
            const weightB = RECURRING_FREQUENCY_ORDER_MAP[b];
            if (weightA === weightB) {
              return a.localeCompare(b);
            } else {
              return weightB - weightA;
            }
          });

          setSelectedValue(defaultFrequencyHolder);

          setCheckedValues(frequencies);
          console.log(
            "frequencies: ",
            frequencies,
            "selectedValue: ",
            defaultFrequencyHolder
          ); //[props.editFormData['defaultFrequency']])

          if (
            props.editFormData["oneTimeAmounts"] !== null &&
            props.editFormData["oneTimeAmounts"] !== undefined
          ) {
            setOneTimeAmount1(props.editFormData["oneTimeAmounts"][0]);
            setOneTimeAmount2(props.editFormData["oneTimeAmounts"][1]);
            setOneTimeAmount3(props.editFormData["oneTimeAmounts"][2]);
            setOneTimeAmount4(props.editFormData["oneTimeAmounts"][3]);
          }

          if (
            props.editFormData["monthlyAmounts"] !== null &&
            props.editFormData["monthlyAmounts"] !== undefined
          ) {
            setMonthlyAmount1(props.editFormData["monthlyAmounts"][0]);
            setMonthlyAmount2(props.editFormData["monthlyAmounts"][1]);
            setMonthlyAmount3(props.editFormData["monthlyAmounts"][2]);
            setMonthlyAmount4(props.editFormData["monthlyAmounts"][3]);
          }

          if (
            props.editFormData["dailyAmounts"] !== null &&
            props.editFormData["dailyAmounts"] !== undefined
          ) {
            setDailyAmount1(props.editFormData["dailyAmounts"][0]);
            setDailyAmount2(props.editFormData["dailyAmounts"][1]);
            setDailyAmount3(props.editFormData["dailyAmounts"][2]);
            setDailyAmount4(props.editFormData["dailyAmounts"][3]);
          }
          if (
            props.editFormData["weeklyAmounts"] !== null &&
            props.editFormData["weeklyAmounts"] !== undefined
          ) {
            setWeeklyAmount1(props.editFormData["weeklyAmounts"][0]);
            setWeeklyAmount2(props.editFormData["weeklyAmounts"][1]);
            setWeeklyAmount3(props.editFormData["weeklyAmounts"][2]);
            setWeeklyAmount4(props.editFormData["weeklyAmounts"][3]);
          }
          if (
            props.editFormData["annuallyAmounts"] !== null &&
            props.editFormData["annuallyAmounts"] !== undefined
          ) {
            setAnuallyAmount1(props.editFormData["annuallyAmounts"][0]);
            setAnuallyAmount2(props.editFormData["annuallyAmounts"][1]);
            setAnuallyAmount3(props.editFormData["annuallyAmounts"][2]);
            setAnuallyAmount4(props.editFormData["annuallyAmounts"][3]);
          }

          // check if crowdfunding options is not null
          if (
            props.editFormData["crowdfundingOptions"] != null &&
            props.editFormData["crowdfundingOptions"].length > 0
          ) {
            setOption1Amount(
              props.editFormData["crowdfundingOptions"][0].amount
            );
            setOption2Amount(
              props.editFormData["crowdfundingOptions"][1].amount
            );
            setOption3Amount(
              props.editFormData["crowdfundingOptions"][2].amount
            );
            setOption4Amount(
              props.editFormData["crowdfundingOptions"][3].amount
            );

            setOption1Description(
              props.editFormData["crowdfundingOptions"][0].description
            );
            setOption2Description(
              props.editFormData["crowdfundingOptions"][1].description
            );
            setOption3Description(
              props.editFormData["crowdfundingOptions"][2].description
            );
            setOption4Description(
              props.editFormData["crowdfundingOptions"][3].description
            );

            setOption1Title(props.editFormData["crowdfundingOptions"][0].title);
            setOption2Title(props.editFormData["crowdfundingOptions"][1].title);
            setOption3Title(props.editFormData["crowdfundingOptions"][2].title);
            setOption4Title(props.editFormData["crowdfundingOptions"][3].title);
          }

          setFundraiserType(props.editFormData["fundraiserType"]);

          setCrowdFundingFormType(props.editFormData["formType"]);

          setFlagDestination(
            props.editFormData["flagDestination"] === undefined ||
              props.editFormData["flagDestination"] === null
              ? ""
              : props.editFormData["flagDestination"]
          );
          setFlagPageUrl(
            props.editFormData["flagPageUrl"] === undefined ||
              props.editFormData["flagPageUrl"] === null
              ? ""
              : props.editFormData["flagPageUrl"]
          );
          setFlagPosition(
            props.editFormData["flagPosition"] === undefined ||
              props.editFormData["flagPosition"] === null
              ? ""
              : props.editFormData["flagPosition"]
          );

          let importedProductGroups = props.editFormData["productGroups"] ?? [];
          importedProductGroups = importedProductGroups.map((productGroup) => {
            return {
              ...productGroup,
              id: Math.random().toString(36).substring(2, 9),
            };
          });
          setProductGroups(importedProductGroups);
          setOriginalProductGroups(importedProductGroups);

          //In this code, the comparison props.editFormData['formType'] === 'CROWDFUNDING' checks if props.editFormData['formType'] is equal to 'CROWDFUNDING'. If it is, the expression evaluates to true, and setCrowdFundingFormType is set to true. If props.editFormData['formType'] is any other value, including 'STANDARD', the comparison evaluates to false, and setCrowdFundingFormType is set to false.
          /*           setCrowdFundingFormType(
            props.editFormData["formType"] === "CROWDFUNDING_FORM"
          );
 */
          // if props.editFormData["formType"] === null or it's === "",

          // When we enable separate amoutns later, we need to revisit this legacy code
          // setSeparateAmounts(
          //   !(
          //     (props.editFormData['oneTimeAmounts'].length == props.editFormData['monthlyAmounts'].length)
          //     &&
          //     props.editFormData['oneTimeAmounts'].every(function (element, index) {
          //       return element === props.editFormData['monthlyAmounts'][index];
          //     })
          //   )
          // )
          setRequireDoubleTheDonation(
            props.editFormData["showDoubleTheDonation"]
          );

          // add id to custom inputs
          let importedCustomInputs = props.editFormData["customInputs"] ?? [];
          importedCustomInputs = importedCustomInputs.map((customInput) => {
            return {
              ...customInput,
              id: Math.random().toString(36).substring(2, 9),
            };
          });
          setCustomInputs(importedCustomInputs);

          setShowAnonymousDonations(
            props.editFormData["showAnonymousDonations"]
          );

          setShowOrganizationNameField(
            props.editFormData["showOrganizationNameField"]
          );

          setShowAddToCart(props.editFormData["showAddToCart"]);

          const requestOptions = {
            method: "GET",
            headers: {
              accept: "*/*",
              "content-type": "application/json",
              Authorization: jwtToken,
            },
          };

          const params = new URLSearchParams();
          params.append("requestType", "FORMS_PAGE");
          params.append("formName", props.editFormData["formName"]);
          const url = process.env.REACT_APP_EMAIL_CONFIG_URL;

          const response = await fetch(
            url + `?${params.toString()}`,
            requestOptions
          );

          let data = await response.json();
          console.log(data);
          if (data === null || data.message === "Unauthorized") {
            setEmailCustomization(false);
            subjectChange("");
            replyToEmailAddressChange("");
            emailMessageChange("");
          }

          if (data !== null) {
            setEmailCustomization(true);
            subjectChange(data.emailSubject);
            replyToEmailAddressChange(data.replyToEmail);
            emailMessageChange(data.emailMessage);
          }
        }
      }
      setTimeout(() => {
        ReactTooltip.rebuild();
      }, 100);
    };
    myFunction();
  }, [props.open]);

  useEffect(() => {
    if (checkedValues.length > 1 && selectedValue === "") {
      setSelectedValue("Select an option");
    } else if (
      checkedValues.length > 1 &&
      checkedValues.includes(selectedValue) === false
    ) {
      setSelectedValue("Select an option");
    } else if (checkedValues.length === 0) {
      setSelectedValue("Select an option");
    } else if (checkedValues.length === 1) {
      console.log("seeting checked value", checkedValues[0]);
      setSelectedValue(checkedValues[0]);
    }
  }, [checkedValues]);

  const iframe = `<iframe src="${
    process.env.REACT_APP_DONATION_FORM_BASE_URL
  }/?nonprofitName=${encodeURIComponent(
    merchantName
  )}&formName=${encodeURIComponent(
    formName
  )}" height="800" width="100%" allowtransparency="true" frameborder="0" allow="payment"></iframe>`;
  let amountsLabel = "One-Time & Monthly";
  if (separateAmounts) {
    amountsLabel = "One-Time";
  }
  let submitButtonClass =
    "justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2";
  if (isDisabled) {
    submitButtonClass =
      "justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm cursor-not-allowed focus:outline-none";
  }

  console.log("before return", sourceWebsite);
  // Check if merchants contain "ICNA Relief USA Dev" or "ICNA Relief USA",
  // Check if merchants contain "OBAT Helpers" or "Rahma Worldwide Aid & Development" and fundraiserType is "PRODUCT"
  const displayAddToCartToggle =
    ((merchants.includes("ICNA Relief USA Dev") ||
      merchants.includes("ICNA Relief USA")) &&
      fundraiserType !== "DONATION_FLAG" &&
      fundraiserType !== "") ||
    ((merchants.includes("OBAT Helpers") ||
      merchants.includes("Rahma Worldwide Aid & Development") ||
      merchants.includes("Dev") ||
      merchants.includes(".NGO Charities") ||
      merchants.includes("Aghosh AlKhidmat USA") ||
      merchants.includes("Human Development Fund")) &&
      fundraiserType == "PRODUCT");
  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
          <DeactivateAlert
            objectType={"Form"}
            open={showDeactivateAlert}
            setOpen={setShowDeactivateAlert}
            formName={formName}
            activateForm={activateForm}
          />
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            Í
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          Í
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                Update Fundraiser
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Modify any of the fields below (except
                                Fundraiser name), and then click "Save" to
                                update your fundraiser.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={clearStates}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                              Select a tab
                            </label>
                            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                            <select
                              id="tabs"
                              name="tabs"
                              className="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              defaultValue={
                                tabs.find((tab) => tab.current).name
                              }
                              onChange={(event) => {
                                const selectedTab = tabs.find(
                                  (tab) => tab.name === event.target.value
                                );
                                window.location.href = selectedTab.href;
                              }}
                            >
                              {tabs.map((tab) => (
                                <option key={tab.name} value={tab.name}>
                                  {tab.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="hidden sm:block">
                            <div className="border-b border-gray-200">
                              <nav
                                className="-mb-px flex space-x-8 px-5"
                                aria-label="Tabs"
                              >
                                {tabs.map((tab) => (
                                  <a
                                    key={tab.name}
                                    href={tab.href}
                                    onClick={() =>
                                      setActiveTab({
                                        name: tab.name,
                                        current: true,
                                      })
                                    }
                                    className={classNames(
                                      tab.name === activeTab.name
                                        ? "border-green-500 text-green-600"
                                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                      "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                                    )}
                                    aria-current={
                                      tab.name === activeTab.name
                                        ? "page"
                                        : undefined
                                    }
                                  >
                                    {tab.name}
                                  </a>
                                ))}
                              </nav>
                            </div>
                          </div>
                        </div>
                        {/* Divider container */}
                        {activeTab.name === "Custom Fields" && (
                          <CustomInputsTab
                            customInputs={customInputs}
                            setCustomInputs={setCustomInputs}
                            originalCustomInputs={originalCustomInputs}
                          />
                        )}
                        {activeTab.name === "FAQs" && (
                          <CustomFAQsTab
                            customFAQs={customFAQs}
                            setCustomFAQs={setCustomFAQs}
                            originalCustomFAQs={originalCustomFAQs}
                          />
                        )}
                        {activeTab.name === "Elements" && (
                          <ElementsTab
                            elements={elements}
                            setElements={setElements}
                            originalElements={originalElements}
                            elementID={elementID}
                            elementsSaved={elementsSaved}
                            merchantName={merchantName}
                            formName={formName}
                            submit={submit}
                            color={color}
                          />
                        )}
                        {activeTab.name === "Tax Receipts" && (
                          <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:py-0">
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <label
                                htmlFor="form-name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Customization*
                                <svg
                                  data-tip
                                  data-for="emailCustomizationTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="emailCustomizationTooltip"
                                  effect="solid"
                                  place="right"
                                >
                                  <p className="text-sm text-white">
                                    This is where you can customize the details
                                    of the{" "}
                                  </p>
                                  <p className="text-sm text-white">
                                    email sent out to donors.
                                  </p>
                                </ReactTooltip>
                              </label>
                              <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
                                <input
                                  type="radio"
                                  value="false"
                                  name="emailCustomization"
                                  onClick={() => setEmailCustomization(false)}
                                  style={{
                                    backgroundColor:
                                      emailCustomization === false
                                        ? "green"
                                        : "white",
                                    marginTop: "4px",
                                    marginRight: "5px",
                                  }}
                                />
                                Default&nbsp;&nbsp;
                                <input
                                  type="radio"
                                  value="false"
                                  name="emailCustomization"
                                  onClick={() => setEmailCustomization(true)}
                                  style={{
                                    backgroundColor:
                                      emailCustomization === true
                                        ? "green"
                                        : "white",
                                    marginTop: "4px",
                                    marginRight: "5px",
                                  }}
                                />
                                Custom Message
                              </div>
                            </div>
                            {emailCustomization === true && (
                              <>
                                {/* Form name */}
                                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <label
                                    htmlFor="form-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Reply-To Address*
                                    <svg
                                      data-tip
                                      data-for="replytoTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="replytoTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        This is the name of the email address
                                        that
                                      </p>
                                      <p className="text-sm text-white">
                                        the donors will see on their donor
                                        receipts.
                                      </p>
                                      <p className="text-sm text-white">
                                        It must be a full email address.
                                      </p>
                                    </ReactTooltip>
                                  </label>
                                  <div className="sm:col-span-2">
                                    <input
                                      value={replyToEmailAddress}
                                      type="text"
                                      className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                      onChange={(e) =>
                                        replyToEmailAddressChange(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <div>
                                    <label
                                      htmlFor="form-name"
                                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                    >
                                      Subject*
                                      <svg
                                        data-tip
                                        data-for="subjectTooltip"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="text-primary ml-1 inline-block h-5 w-5"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                          clip-rule="evenodd"
                                        />
                                      </svg>
                                      <ReactTooltip
                                        id="subjectTooltip"
                                        effect="solid"
                                        place="right"
                                      >
                                        <p className="text-sm text-white">
                                          This is the subject of the email that
                                        </p>
                                        <p className="text-sm text-white">
                                          the donors will see on their donor
                                          receipts.
                                        </p>
                                      </ReactTooltip>
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <input
                                      value={subject}
                                      type="text"
                                      className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                      onChange={(e) =>
                                        subjectChange(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <label
                                    htmlFor="form-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Custom Message*
                                    <svg
                                      data-tip
                                      data-for="customMessageTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="customMessageTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        This is the message that will be shown
                                        in the{" "}
                                      </p>
                                      <p className="text-sm text-white">
                                        body of the email that donors will see
                                        on their donor receipts.
                                      </p>
                                    </ReactTooltip>
                                  </label>

                                  <div className="sm:col-span-2">
                                    <p class="py-2">
                                      <select
                                        id="variables"
                                        class="float-right mb-2 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                        onChange={(e) =>
                                          variableClick(e.target.value)
                                        }
                                      >
                                        <option
                                          selected="true"
                                          disabled="disabled"
                                        >
                                          Variables
                                        </option>
                                        <option value="{{name}}">
                                          Donor Name
                                        </option>
                                        <option value="{{total}}">
                                          Donated Amount
                                        </option>
                                        <option value="{{fund}}">
                                          Donated Fund
                                        </option>
                                        <option value="{{nonprofit}}">
                                          Nonprofit Name
                                        </option>
                                        <option value="{{phone}}">
                                          Donor Phone Number
                                        </option>
                                        <option value="{{address}}">
                                          Donor Address
                                        </option>
                                        <option value="{{email}}">
                                          Donor Email
                                        </option>
                                        <option value="{{paymentMethod}}">
                                          Donor Payment Method
                                        </option>
                                        <option value="{{feeCovered}}">
                                          Covered Fee*
                                        </option>
                                        <option value="{{ein}}">EIN</option>
                                      </select>
                                      <textarea
                                        value={emailMessage}
                                        type="text"
                                        className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                        onChange={(e) =>
                                          emailMessageChange(e.target.value)
                                        }
                                        rows="4"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        {
                          <div
                            style={
                              activeTab.name === "Form Options"
                                ? {}
                                : { display: "none" }
                            }
                            className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:py-0"
                          >
                            {/* Form name */}
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="form-name"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Fundraiser name*
                                  <svg
                                    data-tip
                                    data-for="formNameTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="formNameTooltip"
                                    effect="solid"
                                    place="right"
                                  >
                                    <p className="text-sm text-white">
                                      This is the name of the fundraiser you
                                      created.
                                    </p>
                                  </ReactTooltip>
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                {/* <input
                                disabled
                                value={formName}
                                onChange={(e) => setFormName(e.target.value.replace('|', ''))}
                                type="text"
                                name="form-name"
                                id="form-name"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
                              /> */}
                                <div className="py-2 text-sm text-gray-700">
                                  {formName}
                                </div>
                              </div>
                            </div>

                            {/* Form Type */}
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="form-description"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Fundraiser Type*
                                  <svg
                                    data-tip
                                    data-for="formTypeTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="formTypeTooltip"
                                    effect="solid"
                                    place="right"
                                  >
                                    <p className="text-sm text-white">
                                      Donation Form: Embedded Fundraiser for
                                      website fundraising.{" "}
                                    </p>
                                    <p className="text-sm text-white">
                                      Quick Donate: Standard Fundraiser with
                                      custom donation levels.{" "}
                                    </p>
                                    <p className="text-sm text-white">
                                      Donation Flag: Standard Fundraiser with
                                      custom donation levels.{" "}
                                    </p>
                                  </ReactTooltip>
                                </label>
                              </div>

                              <div className="flex flex-col text-sm">
                                <div className="flex flex-row">
                                  <input
                                    type="radio"
                                    value="false"
                                    name="fundraisingFormType"
                                    onClick={() =>
                                      setFundraiserType("DONATION_FORM")
                                    }
                                    style={{
                                      backgroundColor:
                                        fundraiserType === "DONATION_FORM"
                                          ? "green"
                                          : "white",
                                      marginTop: "4px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  <div>Donation Form</div>
                                </div>
                                <div className="flex flex-row">
                                  <input
                                    type="radio"
                                    value="false"
                                    name="fundraisingFormType"
                                    onClick={() => {
                                      setFundraiserType("QUICK_DONATE");
                                      setCheckedValues("");
                                      setCrowdFundingFormType("");
                                    }}
                                    style={{
                                      backgroundColor:
                                        fundraiserType === "QUICK_DONATE"
                                          ? "green"
                                          : "white",
                                      marginTop: "4px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  <div>Quick Donate</div>
                                </div>
                                <div className="flex flex-row">
                                  <input
                                    type="radio"
                                    value="false"
                                    name="fundraisingFormType"
                                    onClick={() => {
                                      setFundraiserType("DONATION_FLAG");
                                      setFundsSingle("");
                                      setFunds([]);
                                      setShowAddToCart(false);
                                    }}
                                    style={{
                                      backgroundColor:
                                        fundraiserType === "DONATION_FLAG"
                                          ? "green"
                                          : "white",
                                      marginTop: "4px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  <div>Donation Flag</div>
                                </div>
                                {
                                  // render only if merchants has one or more match in PRODUCT_NONPROFITS
                                  merchants.some((merchant) =>
                                    PRODUCT_NONPROFITS.includes(merchant)
                                  ) && (
                                    <div className="flex flex-row">
                                      <input
                                        type="radio"
                                        value="false"
                                        name="fundraisingFormType"
                                        onClick={() => {
                                          setFundraiserType("PRODUCT");
                                          setCheckedValues(["One-Time"]);
                                          setFundsSingle("");
                                          setFunds([]);
                                          setOneTimeChecked(true);
                                          setCrowdFundingFormType("");
                                        }}
                                        style={{
                                          backgroundColor:
                                            fundraiserType === "PRODUCT"
                                              ? "green"
                                              : "white",
                                          marginTop: "4px",
                                          marginRight: "5px",
                                        }}
                                      />
                                      <div>Product</div>
                                    </div>
                                  )
                                }
                              </div>
                            </div>

                            {fundraiserType === "PRODUCT" && (
                              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="form-description"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px"
                                  >
                                    Products*
                                    <svg
                                      data-tip
                                      data-for="amountButtonTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="amountButtonTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        Please fill out the products that you
                                        would like to sell.
                                      </p>
                                    </ReactTooltip>
                                  </label>
                                </div>

                                <div className="my-auto flex text-sm sm:col-span-2">
                                  <ProductGroups
                                    productGroups={productGroups}
                                    setProductGroups={setProductGroups}
                                    originalProductGroups={
                                      originalProductGroups
                                    }
                                  />
                                </div>
                              </div>
                            )}

                            {/* Amount Button Type */}
                            {(fundraiserType === "DONATION_FORM" ||
                              fundraiserType === "DONATION_FLAG") && (
                              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="form-description"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Amount Button Type*
                                    <svg
                                      data-tip
                                      data-for="formTypeTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="formTypeTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        Standard: Embedded Fundraiser for
                                        website fundraising.{" "}
                                      </p>
                                      <p className="text-sm text-white">
                                        Crowdfunding: Standard Fundraiser with
                                        custom donation levels.{" "}
                                      </p>
                                    </ReactTooltip>
                                  </label>
                                </div>

                                <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
                                  <input
                                    type="radio"
                                    value="false"
                                    name="crowdFundingFormType"
                                    onClick={() =>
                                      setCrowdFundingFormType("EMBED_FORM")
                                    }
                                    style={{
                                      backgroundColor:
                                        crowdFundingFormType === "EMBED_FORM"
                                          ? "green"
                                          : "white",
                                      marginTop: "4px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  Standard&nbsp;&nbsp;
                                  <input
                                    type="radio"
                                    value="false"
                                    name="crowdFundingFormType"
                                    onClick={() =>
                                      setCrowdFundingFormType(
                                        "CROWDFUNDING_FORM"
                                      )
                                    }
                                    style={{
                                      backgroundColor:
                                        crowdFundingFormType ===
                                        "CROWDFUNDING_FORM"
                                          ? "green"
                                          : "white",
                                      marginTop: "4px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  Crowdfunding
                                </div>
                              </div>
                            )}

                            {/* Donation Frequencies if Quick Donate */}
                            {fundraiserType === "QUICK_DONATE" && (
                              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="form-description"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Donation frequencies*
                                    <svg
                                      data-tip
                                      data-for="donationTypeTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="donationTypeTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        Choose the donation frequencies
                                      </p>
                                      <p className="text-sm text-white">
                                        for your form. If you select more than
                                        one,
                                      </p>
                                      <p className="text-sm text-white">
                                        you will be asked to set a default
                                        option.
                                      </p>
                                    </ReactTooltip>
                                  </label>
                                </div>
                                <div className="flex flex-wrap items-center sm:col-span-2">
                                  <div className="p grid grid-cols-3 text-sm">
                                    <div className="pb-1">
                                      <input
                                        type="radio"
                                        id="oneTime"
                                        name="frequency"
                                        checked={checkedValues.includes(
                                          "One-Time"
                                        )}
                                        onChange={() =>
                                          handleFrequencyRadioChange("One-Time")
                                        }
                                        style={{
                                          backgroundColor:
                                            checkedValues.includes("One-Time")
                                              ? "green"
                                              : "white",
                                        }}
                                      />
                                      &nbsp;&nbsp;One-Time&nbsp;&nbsp;
                                    </div>
                                    <div className="pb-1">
                                      <input
                                        type="radio"
                                        id="daily"
                                        name="frequency"
                                        checked={checkedValues.includes(
                                          "Daily"
                                        )}
                                        onChange={() =>
                                          handleFrequencyRadioChange("Daily")
                                        }
                                        style={{
                                          backgroundColor:
                                            checkedValues.includes("Daily")
                                              ? "green"
                                              : "white",
                                        }}
                                      />
                                      &nbsp;&nbsp;Daily&nbsp;&nbsp;
                                    </div>
                                    <div className="pb-1">
                                      <input
                                        type="radio"
                                        id="weekly"
                                        name="frequency"
                                        checked={checkedValues.includes(
                                          "Weekly"
                                        )}
                                        onChange={() =>
                                          handleFrequencyRadioChange("Weekly")
                                        }
                                        style={{
                                          backgroundColor:
                                            checkedValues.includes("Weekly")
                                              ? "green"
                                              : "white",
                                        }}
                                      />
                                      &nbsp;&nbsp;Weekly&nbsp;&nbsp;
                                    </div>
                                    <div>
                                      <input
                                        type="radio"
                                        id="monthly"
                                        name="frequency"
                                        checked={checkedValues.includes(
                                          "Monthly"
                                        )}
                                        onChange={() =>
                                          handleFrequencyRadioChange("Monthly")
                                        }
                                        style={{
                                          backgroundColor:
                                            checkedValues.includes("Monthly")
                                              ? "green"
                                              : "white",
                                        }}
                                      />
                                      &nbsp;&nbsp;Monthly&nbsp;&nbsp;
                                    </div>
                                    <div>
                                      <input
                                        type="radio"
                                        id="annually"
                                        name="frequency"
                                        checked={checkedValues.includes(
                                          "Annually"
                                        )}
                                        onChange={() =>
                                          handleFrequencyRadioChange("Annually")
                                        }
                                        style={{
                                          backgroundColor:
                                            checkedValues.includes("Annually")
                                              ? "green"
                                              : "white",
                                        }}
                                      />
                                      &nbsp;&nbsp;Annually&nbsp;&nbsp;
                                    </div>
                                  </div>
                                </div>
                                {checkedValues.length > 1 && (
                                  <>
                                    <p className="py-2 text-sm text-gray-500">
                                      Default frequency
                                    </p>
                                    <div style={{ marginLeft: "auto" }}>
                                      <Dropdown
                                        values={checkedValues}
                                        selectedValue={selectedValue}
                                        setSelectedValue={setSelectedValue}
                                        width={"w-44"}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                            {/* Donation Frequencies not equal to Quick Donate */}
                            {fundraiserType !== "QUICK_DONATE" &&
                              fundraiserType != "PRODUCT" && (
                                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <div>
                                    <label
                                      htmlFor="form-description"
                                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                    >
                                      Donation frequencies*
                                      <svg
                                        data-tip
                                        data-for="donationTypeTooltip"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="text-primary ml-1 inline-block h-5 w-5"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                      <ReactTooltip
                                        id="donationTypeTooltip"
                                        effect="solid"
                                        place="right"
                                      >
                                        <p className="text-sm text-white">
                                          Choose the donation frequencies
                                        </p>
                                        <p className="text-sm text-white">
                                          for your fundraiser. If you select
                                          more than one,
                                        </p>
                                        <p className="text-sm text-white">
                                          you will be asked to set a default
                                          option.
                                        </p>
                                      </ReactTooltip>
                                    </label>
                                  </div>
                                  <div className="flex flex-wrap items-center sm:col-span-2">
                                    <div className="mb-4 grid grid-cols-3">
                                      <Checkbox
                                        label="One-Time &nbsp;&nbsp;"
                                        checked={checkedValues.includes(
                                          "One-Time"
                                        )}
                                        onChange={(isChecked) =>
                                          handleFrequencyCheckboxChange(
                                            "One-Time",
                                            isChecked
                                          )
                                        }
                                      />
                                      <Checkbox
                                        label="Daily"
                                        checked={checkedValues.includes(
                                          "Daily"
                                        )}
                                        onChange={(isChecked) =>
                                          handleFrequencyCheckboxChange(
                                            "Daily",
                                            isChecked
                                          )
                                        }
                                      />
                                      <Checkbox
                                        label="Weekly"
                                        checked={checkedValues.includes(
                                          "Weekly"
                                        )}
                                        onChange={(isChecked) =>
                                          handleFrequencyCheckboxChange(
                                            "Weekly",
                                            isChecked
                                          )
                                        }
                                      />
                                      <Checkbox
                                        label="Monthly"
                                        checked={checkedValues.includes(
                                          "Monthly"
                                        )}
                                        onChange={(isChecked) =>
                                          handleFrequencyCheckboxChange(
                                            "Monthly",
                                            isChecked
                                          )
                                        }
                                      />
                                      <Checkbox
                                        label="Annually"
                                        checked={checkedValues.includes(
                                          "Annually"
                                        )}
                                        onChange={(isChecked) =>
                                          handleFrequencyCheckboxChange(
                                            "Annually",
                                            isChecked
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  {checkedValues.length > 1 && (
                                    <>
                                      <p className="py-2 text-sm text-gray-500">
                                        Default frequency
                                      </p>
                                      <div style={{ marginLeft: "auto" }}>
                                        <Dropdown
                                          values={checkedValues}
                                          selectedValue={selectedValue}
                                          setSelectedValue={setSelectedValue}
                                          width={"w-44"}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                            {/* Crowdfunding specific section*/}
                            {crowdFundingFormType === "CROWDFUNDING_FORM" &&
                              fundraiserType !== "QUICK_DONATE" &&
                              fundraiserType != "PRODUCT" && (
                                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-2">
                                  <div>
                                    <label
                                      htmlFor="buttons-crowdfunding"
                                      className="block text-sm font-medium text-gray-900 "
                                    >
                                      Buttons*
                                      <svg
                                        data-tip
                                        data-for="buttonCrowdfundingTooltip"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="text-primary ml-1 inline-block h-5 w-5"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                      <ReactTooltip
                                        id="buttonCrowdfundingTooltip"
                                        effect="solid"
                                        place="right"
                                      >
                                        <p className="text-sm text-white">
                                          Title: This is the name of the
                                          donation level.
                                        </p>
                                        <p className="text-sm text-white">
                                          Amount: This is the encouraged
                                          donation amount for this level.
                                        </p>
                                        <p className="text-sm text-white">
                                          Description: Explain the purpose or
                                          benefits of this donation level.
                                        </p>
                                      </ReactTooltip>
                                    </label>
                                    <br></br>
                                    <br></br>

                                    <div className="font-small pb-12 pt-6 text-sm">
                                      <label
                                        htmlFor="option1"
                                        className="text-gray-900"
                                      >
                                        Option 1
                                      </label>
                                    </div>
                                    <br></br>

                                    <div className="font-small py-11 text-sm">
                                      <label
                                        htmlFor="option2"
                                        className="text-gray-900"
                                      >
                                        Option 2
                                      </label>
                                    </div>
                                    <br></br>
                                    <div className="font-small py-10 text-sm">
                                      <label
                                        htmlFor="option3"
                                        className="text-gray-900"
                                      >
                                        Option 3
                                      </label>
                                    </div>
                                    <br></br>

                                    <div className="font-small py-10 text-sm">
                                      <label
                                        htmlFor="option4"
                                        className="text-gray-900"
                                      >
                                        Option 4
                                      </label>
                                    </div>
                                  </div>
                                  <div className="flex flex-wrap items-center sm:col-span-2 ">
                                    <br></br>
                                    <br></br>
                                    <div className="py-3">
                                      <div className="grid grid-cols-2 gap-4">
                                        <div className="mb-4">
                                          <input
                                            type="text"
                                            id="option1Title"
                                            placeholder="Enter title"
                                            value={option1Title}
                                            onChange={(e) =>
                                              setOption1Title(e.target.value)
                                            }
                                            className="focus:ring-primary focus:border-primary mt-1 block w-full rounded-md border-gray-300 sm:text-sm"
                                          />
                                        </div>
                                        <div className="mb-4">
                                          <input
                                            type="text"
                                            id="option1Amount"
                                            placeholder="Enter amount"
                                            value={option1Amount}
                                            onChange={(e) =>
                                              setOption1Amount(
                                                e.target.value.replace(
                                                  /[^0-9]/g,
                                                  ""
                                                )
                                              )
                                            }
                                            className="focus:ring-primary focus:border-primary mt-1 block w-full rounded-md border-gray-300 sm:text-sm"
                                          />
                                        </div>
                                      </div>
                                      <div className="mb-1 w-full">
                                        <input
                                          type="text"
                                          id="option1Description"
                                          placeholder="Enter description"
                                          value={option1Description}
                                          onChange={(e) =>
                                            setOption1Description(
                                              e.target.value
                                            )
                                          }
                                          className="focus:ring-primary focus:border-primary mt-1 block w-full rounded-md border-gray-300 sm:text-sm"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="py-3">
                                      <div className="grid grid-cols-2 gap-4">
                                        <div className="mb-4">
                                          <input
                                            type="text"
                                            id="option2Title"
                                            placeholder="Enter title"
                                            value={option2Title}
                                            onChange={(e) =>
                                              setOption2Title(e.target.value)
                                            }
                                            className="focus:ring-primary focus:border-primary mt-1 block w-full rounded-md border-gray-300 sm:text-sm"
                                          />
                                        </div>
                                        <div className="mb-4">
                                          <input
                                            type="text"
                                            id="option2Amount"
                                            placeholder="Enter amount"
                                            value={option2Amount}
                                            onChange={(e) =>
                                              setOption2Amount(
                                                e.target.value.replace(
                                                  /[^0-9]/g,
                                                  ""
                                                )
                                              )
                                            }
                                            className="focus:ring-primary focus:border-primary mt-1 block w-full rounded-md border-gray-300 sm:text-sm"
                                          />
                                        </div>
                                      </div>
                                      <div className="mb-1 w-full">
                                        <input
                                          type="text"
                                          id="option2Description"
                                          placeholder="Enter description"
                                          value={option2Description}
                                          onChange={(e) =>
                                            setOption2Description(
                                              e.target.value
                                            )
                                          }
                                          className="focus:ring-primary focus:border-primary mt-1 block w-full rounded-md border-gray-300 sm:text-sm"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="py-3">
                                      <div className="grid grid-cols-2 gap-4">
                                        <div className="mb-4">
                                          <input
                                            type="text"
                                            id="option3Title"
                                            placeholder="Enter title"
                                            value={option3Title}
                                            onChange={(e) =>
                                              setOption3Title(e.target.value)
                                            }
                                            className="focus:ring-primary focus:border-primary mt-1 block w-full rounded-md border-gray-300 sm:text-sm"
                                          />
                                        </div>
                                        <div className="mb-4">
                                          <input
                                            type="text"
                                            id="option3Amount"
                                            placeholder="Enter amount"
                                            value={option3Amount}
                                            onChange={(e) =>
                                              setOption3Amount(
                                                e.target.value.replace(
                                                  /[^0-9]/g,
                                                  ""
                                                )
                                              )
                                            }
                                            className="focus:ring-primary focus:border-primary mt-1 block w-full rounded-md border-gray-300 sm:text-sm"
                                          />
                                        </div>
                                      </div>
                                      <div className="mb-1 w-full">
                                        <input
                                          type="text"
                                          id="option3Description"
                                          placeholder="Enter description"
                                          value={option3Description}
                                          onChange={(e) =>
                                            setOption3Description(
                                              e.target.value
                                            )
                                          }
                                          className="focus:ring-primary focus:border-primary mt-1 block w-full rounded-md border-gray-300 sm:text-sm"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="py-3">
                                      <div className="grid grid-cols-2 gap-4">
                                        <div className="mb-4">
                                          <input
                                            type="text"
                                            id="option4Title"
                                            placeholder="Enter title"
                                            value={option4Title}
                                            onChange={(e) =>
                                              setOption4Title(e.target.value)
                                            }
                                            className="focus:ring-primary focus:border-primary mt-1 block w-full rounded-md border-gray-300 sm:text-sm"
                                          />
                                        </div>
                                        <div className="mb-4">
                                          <input
                                            type="text"
                                            id="option4Amount"
                                            placeholder="Enter amount"
                                            value={option4Amount}
                                            onChange={(e) =>
                                              setOption4Amount(
                                                e.target.value.replace(
                                                  /[^0-9]/g,
                                                  ""
                                                )
                                              )
                                            }
                                            className="focus:ring-primary focus:border-primary mt-1 block w-full rounded-md border-gray-300 sm:text-sm"
                                          />
                                        </div>
                                      </div>
                                      <div className="mb-1 w-full">
                                        <input
                                          type="text"
                                          id="option4Description"
                                          placeholder="Enter description"
                                          value={option4Description}
                                          onChange={(e) =>
                                            setOption4Description(
                                              e.target.value
                                            )
                                          }
                                          className="focus:ring-primary focus:border-primary mt-1 block w-full rounded-md border-gray-300 sm:text-sm"
                                        ></input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                            {/* Default amount */}
                            {
                              //crowdFundingFormType === false &&
                              fundraiserType != "PRODUCT" &&
                                (checkedValues.includes("Daily") ||
                                  checkedValues.includes("Weekly") ||
                                  checkedValues.includes("Monthly") ||
                                  checkedValues.includes("Annually") ||
                                  checkedValues.includes("One-Time")) && (
                                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                    <div>
                                      <label
                                        htmlFor="form-description"
                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                      >
                                        Default amount
                                        <svg
                                          data-tip
                                          data-for="defaultAmountTooltip"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          className="text-primary ml-1 inline-block h-5 w-5"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                            clip-rule="evenodd"
                                          />
                                        </svg>
                                        <ReactTooltip
                                          id="defaultAmountTooltip"
                                          effect="solid"
                                          place="right"
                                        >
                                          <p className="text-sm text-white">
                                            You can choose to set a default
                                            selected amount
                                          </p>
                                          <p className="text-sm text-white">
                                            for your fundraiser. This will load
                                            your fundraiser
                                          </p>
                                          <p className="text-sm text-white">
                                            with the amount pre-selected.
                                          </p>
                                        </ReactTooltip>
                                      </label>
                                    </div>
                                    <div className="sm:col-span-2">
                                      {checkedValues.includes("One-Time") && (
                                        <div className="mt-4">
                                          <Toggle
                                            name={"Set One-Time Default"}
                                            enabled={showOneTimeDefault}
                                            setEnabled={setShowOneTimeDefault}
                                          />
                                        </div>
                                      )}
                                      {checkedValues.includes("Daily") && (
                                        <div className="mt-4">
                                          <Toggle
                                            name={"Set Daily Default"}
                                            enabled={showDailyDefault}
                                            setEnabled={setShowDailyDefault}
                                          />
                                        </div>
                                      )}
                                      {checkedValues.includes("Weekly") && (
                                        <div className="mt-4">
                                          <Toggle
                                            name={"Set Weekly Default"}
                                            enabled={showWeeklyDefault}
                                            setEnabled={setShowWeeklyDefault}
                                          />
                                        </div>
                                      )}
                                      {checkedValues.includes("Monthly") && (
                                        <div className="mt-4">
                                          <Toggle
                                            name={"Set Monthly Default"}
                                            enabled={showMonthlyDefault}
                                            setEnabled={setShowMonthlyDefault}
                                          />
                                        </div>
                                      )}
                                      {checkedValues.includes("Annually") && (
                                        <div className="mt-4">
                                          <Toggle
                                            name={"Set Annual Default"}
                                            enabled={showAnnuallyDefault}
                                            setEnabled={setshowAnnuallyDefault}
                                          />
                                        </div>
                                      )}
                                    </div>

                                    {showOneTimeDefault &&
                                      checkedValues.includes("One-Time") && (
                                        <>
                                          <p className="py-2 text-sm text-gray-500">
                                            One-Time
                                          </p>
                                          <div className="flex grid grid-cols-4 flex-wrap space-x-2 sm:col-span-2">
                                            <input
                                              type="number"
                                              name="oneTimeDefault"
                                              id="oneTimeDefault"
                                              className="focus:border-secondary focus:ring-secondary col-start-1 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                              placeholder="$10"
                                              value={oneTimeDefault}
                                              onChange={(e) =>
                                                setOneTimeDefault(
                                                  e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                  )
                                                )
                                              }
                                            />
                                          </div>
                                        </>
                                      )}
                                    {showDailyDefault &&
                                      checkedValues.includes("Daily") && (
                                        <>
                                          <p className="py-2 text-sm text-gray-500">
                                            Daily
                                          </p>
                                          <div className="flex grid grid-cols-4 flex-wrap space-x-2 sm:col-span-2">
                                            <input
                                              type="number"
                                              name="dailyDefault"
                                              id="dailyDefault"
                                              className="focus:border-secondary focus:ring-secondary col-start-1 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                              placeholder="$10"
                                              value={dailyDefault}
                                              onChange={(e) =>
                                                setDailyDefault(
                                                  e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                  )
                                                )
                                              }
                                            />
                                          </div>
                                        </>
                                      )}
                                    {showWeeklyDefault &&
                                      checkedValues.includes("Weekly") && (
                                        <>
                                          <p className="py-2 text-sm text-gray-500">
                                            Weekly
                                          </p>
                                          <div className="flex grid grid-cols-4 flex-wrap space-x-2 sm:col-span-2">
                                            <input
                                              type="number"
                                              name="weeklyDefault"
                                              id="weeklyDefault"
                                              className="focus:border-secondary focus:ring-secondary col-start-1 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                              placeholder="$10"
                                              value={weeklyDefault}
                                              onChange={(e) =>
                                                setWeeklyDefault(
                                                  e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                  )
                                                )
                                              }
                                            />
                                          </div>
                                        </>
                                      )}
                                    {showMonthlyDefault &&
                                      checkedValues.includes("Monthly") && (
                                        <>
                                          <p className="py-2 text-sm text-gray-500">
                                            Monthly
                                          </p>
                                          <div className="flex grid grid-cols-4 flex-wrap space-x-2 sm:col-span-2">
                                            <input
                                              type="number"
                                              name="monthlyDefault"
                                              id="monthlyDefault"
                                              className="focus:border-secondary focus:ring-secondary col-start-1 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                              placeholder="$10"
                                              value={monthlyDefault}
                                              onChange={(e) =>
                                                setMonthlyDefault(
                                                  e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                  )
                                                )
                                              }
                                            />
                                          </div>
                                        </>
                                      )}
                                    {showAnnuallyDefault &&
                                      checkedValues.includes("Annually") && (
                                        <>
                                          <p className="py-2 text-sm text-gray-500">
                                            Annually
                                          </p>
                                          <div className="flex grid grid-cols-4 flex-wrap space-x-2 sm:col-span-2">
                                            <input
                                              type="number"
                                              name="annuallyDefault"
                                              id="annuallyDefault"
                                              className="focus:border-secondary focus:ring-secondary col-start-1 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                              placeholder="$10"
                                              value={annuallyDefault}
                                              onChange={(e) =>
                                                setAnnuallyDefault(
                                                  e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                  )
                                                )
                                              }
                                            />
                                          </div>
                                        </>
                                      )}
                                  </div>
                                )
                            }

                            {crowdFundingFormType === "EMBED_FORM" && (
                              <>
                                {/* amount Buttons */}
                                {/* only one-time checked */}
                                {checkedValues.includes("One-Time") && (
                                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                    <div>
                                      <label
                                        htmlFor="form-description"
                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                      >
                                        One-Time buttons*
                                        <svg
                                          data-tip
                                          data-for="amountButtonsTooltipOneTime"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          className="text-primary ml-1 inline-block h-5 w-5"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                            clip-rule="evenodd"
                                          />
                                        </svg>
                                        <ReactTooltip
                                          id="amountButtonsTooltipOneTime"
                                          effect="solid"
                                          place="right"
                                        >
                                          <p className="text-sm text-white">
                                            These are the values for the
                                          </p>
                                          <p className="text-sm text-white">
                                            one-time amount buttons.{" "}
                                          </p>
                                        </ReactTooltip>
                                      </label>
                                    </div>
                                    <div className="flex grid grid-cols-4 flex-wrap space-x-2 sm:col-span-2">
                                      <input
                                        type="number"
                                        name="amount-1"
                                        id="amount-1"
                                        className="focus:border-secondary focus:ring-secondary col-start-1 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$10"
                                        value={oneTimeAmount1}
                                        onChange={(e) =>
                                          setOneTimeAmount1(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        name="amount-2"
                                        id="amount-2"
                                        className="focus:border-secondary focus:ring-secondary col-start-2 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$25"
                                        value={oneTimeAmount2}
                                        onChange={(e) =>
                                          setOneTimeAmount2(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        name="amount-3"
                                        id="amount-3"
                                        className="focus:border-secondary focus:ring-secondary col-start-3 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$50"
                                        value={oneTimeAmount3}
                                        onChange={(e) =>
                                          setOneTimeAmount3(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        name="amount-4"
                                        id="amount-4"
                                        className="focus:border-secondary focus:ring-secondary col-start-4 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$100"
                                        value={oneTimeAmount4}
                                        onChange={(e) =>
                                          setOneTimeAmount4(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                )}

                                {/* amount Buttons */}
                                {/* only daily checked */}
                                {checkedValues.includes("Daily") && (
                                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                    <div>
                                      <label
                                        htmlFor="form-description"
                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                      >
                                        Daily buttons*
                                        <svg
                                          data-tip
                                          data-for="amountButtonsTooltipDaily"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          className="text-primary ml-1 inline-block h-5 w-5"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                            clip-rule="evenodd"
                                          />
                                        </svg>
                                        <ReactTooltip
                                          id="amountButtonsTooltipDaily"
                                          effect="solid"
                                          place="right"
                                        >
                                          <p className="text-sm text-white">
                                            These are the values for the
                                          </p>
                                          <p className="text-sm text-white">
                                            daily amount buttons.{" "}
                                          </p>
                                        </ReactTooltip>
                                      </label>
                                    </div>
                                    {/* <div className="py-2 sm:col-span-2"></div>
                              <p className="text-sm text-gray-500 py-2">
                                Monthly
                              </p> */}
                                    <div className="flex grid grid-cols-4 flex-wrap space-x-2 sm:col-span-2">
                                      <input
                                        type="number"
                                        name="amount-1"
                                        id="amount-1"
                                        className="focus:border-secondary focus:ring-secondary col-start-1 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$10"
                                        value={dailyAmount1}
                                        onChange={(e) =>
                                          setDailyAmount1(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        name="amount-2"
                                        id="amount-2"
                                        className="focus:border-secondary focus:ring-secondary col-start-2 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$25"
                                        value={dailyAmount2}
                                        onChange={(e) =>
                                          setDailyAmount2(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        name="amount-3"
                                        id="amount-3"
                                        className="focus:border-secondary focus:ring-secondary col-start-3 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$50"
                                        value={dailyAmount3}
                                        onChange={(e) =>
                                          setDailyAmount3(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        name="amount-4"
                                        id="amount-4"
                                        className="focus:border-secondary focus:ring-secondary col-start-4 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$100"
                                        value={dailyAmount4}
                                        onChange={(e) =>
                                          setDailyAmount4(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                                {/* weekly checked */}
                                {checkedValues.includes("Weekly") && (
                                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                    <div>
                                      <label
                                        htmlFor="form-description"
                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                      >
                                        Weekly buttons*
                                        <svg
                                          data-tip
                                          data-for="amountButtonsTooltipWeekly"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          className="text-primary ml-1 inline-block h-5 w-5"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                            clip-rule="evenodd"
                                          />
                                        </svg>
                                        <ReactTooltip
                                          id="amountButtonsTooltipWeekly"
                                          effect="solid"
                                          place="right"
                                        >
                                          <p className="text-sm text-white">
                                            These are the values for the
                                          </p>
                                          <p className="text-sm text-white">
                                            weekly amount buttons.{" "}
                                          </p>
                                        </ReactTooltip>
                                      </label>
                                    </div>
                                    <div className="flex grid grid-cols-4 flex-wrap space-x-2 sm:col-span-2">
                                      <input
                                        type="number"
                                        name="amount-1"
                                        id="amount-1"
                                        className="focus:border-secondary focus:ring-secondary col-start-1 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$10"
                                        value={weeklyAmount1}
                                        onChange={(e) =>
                                          setWeeklyAmount1(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        name="amount-2"
                                        id="amount-2"
                                        className="focus:border-secondary focus:ring-secondary col-start-2 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$25"
                                        value={weeklyAmount2}
                                        onChange={(e) =>
                                          setWeeklyAmount2(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        name="amount-3"
                                        id="amount-3"
                                        className="focus:border-secondary focus:ring-secondary col-start-3 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$50"
                                        value={weeklyAmount3}
                                        onChange={(e) =>
                                          setWeeklyAmount3(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        name="amount-4"
                                        id="amount-4"
                                        className="focus:border-secondary focus:ring-secondary col-start-4 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$100"
                                        value={weeklyAmount4}
                                        onChange={(e) =>
                                          setWeeklyAmount4(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                )}

                                {/* only monthly checked */}
                                {checkedValues.includes("Monthly") && (
                                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                    <div>
                                      <label
                                        htmlFor="form-description"
                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                      >
                                        Monthly buttons*
                                        <svg
                                          data-tip
                                          data-for="amountButtonsTooltipMonthly"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          className="text-primary ml-1 inline-block h-5 w-5"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                            clip-rule="evenodd"
                                          />
                                        </svg>
                                        <ReactTooltip
                                          id="amountButtonsTooltipMonthly"
                                          effect="solid"
                                          place="right"
                                        >
                                          <p className="text-sm text-white">
                                            These are the values for the
                                          </p>
                                          <p className="text-sm text-white">
                                            monthly amount buttons.{" "}
                                          </p>
                                        </ReactTooltip>
                                      </label>
                                    </div>
                                    {/* <div className="py-2 sm:col-span-2"></div>
                              <p className="text-sm text-gray-500 py-2">
                                Monthly
                              </p> */}
                                    <div className="flex grid grid-cols-4 flex-wrap space-x-2 sm:col-span-2">
                                      <input
                                        type="number"
                                        name="amount-1"
                                        id="amount-1"
                                        className="focus:border-secondary focus:ring-secondary col-start-1 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$10"
                                        value={monthlyAmount1}
                                        onChange={(e) =>
                                          setMonthlyAmount1(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        name="amount-2"
                                        id="amount-2"
                                        className="focus:border-secondary focus:ring-secondary col-start-2 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$25"
                                        value={monthlyAmount2}
                                        onChange={(e) =>
                                          setMonthlyAmount2(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        name="amount-3"
                                        id="amount-3"
                                        className="focus:border-secondary focus:ring-secondary col-start-3 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$50"
                                        value={monthlyAmount3}
                                        onChange={(e) =>
                                          setMonthlyAmount3(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        name="amount-4"
                                        id="amount-4"
                                        className="focus:border-secondary focus:ring-secondary col-start-4 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$100"
                                        value={monthlyAmount4}
                                        onChange={(e) =>
                                          setMonthlyAmount4(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                )}

                                {/* only annually checked */}
                                {checkedValues.includes("Annually") && (
                                  <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                    <div>
                                      <label
                                        htmlFor="form-description"
                                        className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                      >
                                        Annual buttons*
                                        <svg
                                          data-tip
                                          data-for="amountButtonsTooltipAnnually"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          className="text-primary ml-1 inline-block h-5 w-5"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                            clip-rule="evenodd"
                                          />
                                        </svg>
                                        <ReactTooltip
                                          id="amountButtonsTooltipAnnually"
                                          effect="solid"
                                          place="right"
                                        >
                                          <p className="text-sm text-white">
                                            These are the values for the
                                          </p>
                                          <p className="text-sm text-white">
                                            yearly amount buttons.{" "}
                                          </p>
                                        </ReactTooltip>
                                      </label>
                                    </div>
                                    {/* <div className="py-2 sm:col-span-2"></div>
                              <p className="text-sm text-gray-500 py-2">
                                Monthly
                              </p> */}
                                    <div className="flex grid grid-cols-4 flex-wrap space-x-2 sm:col-span-2">
                                      <input
                                        type="number"
                                        name="amount-1"
                                        id="amount-1"
                                        className="focus:border-secondary focus:ring-secondary col-start-1 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$10"
                                        value={annuallyAmount1}
                                        onChange={(e) =>
                                          setAnuallyAmount1(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        name="amount-2"
                                        id="amount-2"
                                        className="focus:border-secondary focus:ring-secondary col-start-2 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$25"
                                        value={annuallyAmount2}
                                        onChange={(e) =>
                                          setAnuallyAmount2(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        name="amount-3"
                                        id="amount-3"
                                        className="focus:border-secondary focus:ring-secondary col-start-3 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$50"
                                        value={annuallyAmount3}
                                        onChange={(e) =>
                                          setAnuallyAmount3(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        name="amount-4"
                                        id="amount-4"
                                        className="focus:border-secondary focus:ring-secondary col-start-4 block rounded-md border-gray-300 placeholder-gray-400 shadow-sm sm:text-sm"
                                        placeholder="$100"
                                        value={annuallyAmount4}
                                        onChange={(e) =>
                                          setAnuallyAmount4(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}

                            {/* Additonal Info to Collect */}
                            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-2 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <h3 className="inline-block text-sm font-medium text-gray-900">
                                  Required Fields
                                </h3>
                                <svg
                                  data-tip
                                  data-for="additionalInfoTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="additionalInfoTooltip"
                                  effect="solid"
                                  place="right"
                                >
                                  <p className="text-sm text-white">
                                    Optionally, include any additional required
                                    fields:
                                  </p>
                                  <p className="text-sm text-white">
                                    Phone Number - The donor's phone number.
                                  </p>
                                  <p className="text-sm text-white">
                                    Billing Address - The donor's full billing
                                    address.
                                  </p>
                      
                                </ReactTooltip>
                              </div>
                              <div className="sm:col-span-2 sm:col-start-2">
                                <div className="">
                                  <div className="block">
                                    <Toggle
                                      name={"Require Phone Number"}
                                      enabled={requirePhoneNumber}
                                      setEnabled={setRequirePhoneNumber}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="sm:col-span-2 sm:col-start-2">
                                <div className="">
                                  <div className="block">
                                    <Toggle
                                      name={"Require Billing Address"}
                                      enabled={requireBillingAddress}
                                      setEnabled={setRequireBillingAddress}
                                    />
                                  </div>
                                </div>
                              </div>

                             {/*  <div className="sm:col-span-2 sm:col-start-2">
                                <div className="">
                                  <div className="block">
                                    <Toggle
                                      name={"Require Country Dropdown"}
                                      enabled={requireCountryDropdown}
                                      setEnabled={setRequireCountryDropdown}
                                    />
                                  </div>
                                </div>
                              </div>
                              {requireCountryDropdown && (
                                <div className="sm:col-span-2 sm:col-start-2">
                                  <div className="">
                                    <div className="mt-2 block grow">
                                      <Dropdown
                                        selectedValue={selectedCountry}
                                        setSelectedValue={setSelectedCountry}
                                        values={countries}
                                        width={"w-56"}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )} */}
                            </div>

                            {/* Show show Anonymous Donations */}
                            {
                              <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-2 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <h3 className="inline-block text-sm font-medium text-gray-900">
                                    Addtional Details
                                  </h3>
                                  <svg
                                    data-tip
                                    data-for="doubleTheDonationTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="doubleTheDonationTooltip"
                                    effect="solid"
                                    place="right"
                                  >
                                    <p className="text-sm text-white">
                                      When enabling the following options, they
                                      will appear on the
                                    </p>
                                    <p className="text-sm text-white">
                                      "Additional Details" section of the
                                      checkout page.
                                    </p>
                                  </ReactTooltip>
                                </div>
                                {dtdPublicApiKey != null && (
                                  <div className="sm:col-span-2 sm:col-start-2">
                                    <div className="">
                                      <div className="block">
                                        <Toggle
                                          name={"Enable Double the Donation"}
                                          enabled={requireDoubleTheDonation}
                                          setEnabled={
                                            setRequireDoubleTheDonation
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {(checkedValues.includes("Daily") ||
                                  checkedValues.includes("Weekly") ||
                                  checkedValues.includes("Monthly") ||
                                  checkedValues.includes("Annually")) && (
                                  <div className="sm:col-span-2 sm:col-start-2">
                                    <div className="">
                                      <div className="block">
                                        <Toggle
                                          name={"Enable Scheduled Donations"}
                                          enabled={requireScheduleDonations}
                                          setEnabled={
                                            setRequireScheduleDonations
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="sm:col-span-2 sm:col-start-2">
                                  <div className="">
                                    <div className="block">
                                      <Toggle
                                        name={"Enable Anonymous Donations"}
                                        enabled={showAnonymousDonations}
                                        setEnabled={setShowAnonymousDonations}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="sm:col-span-2 sm:col-start-2">
                                  <div className="">
                                    <div className="block">
                                      <Toggle
                                        name={"Enable Organization Name"}
                                        enabled={showOrganizationNameField}
                                        setEnabled={
                                          setShowOrganizationNameField
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {
                                  // Check if merchants contain "ICNA Relief USA Dev", or "ICNA Relief USA",
                                  displayAddToCartToggle && (
                                    <div className="sm:col-span-2 sm:col-start-2">
                                      <div className="">
                                        <div className="block">
                                          <Toggle
                                            name={"Enable Add-to-Cart"}
                                            enabled={showAddToCart}
                                            setEnabled={setShowAddToCart}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }
                              </div>
                            }

                            {/* Always Show Campaign ID */}
                            {(merchants.includes("ICNA Relief USA") ||
                              merchants.includes("ICNA Relief USA Dev")) &&
                              fundraiserType !== "" && (
                                <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <div>
                                    <h3 className="inline-block text-sm font-medium text-gray-900">
                                      Salesforce Campaign ID*
                                    </h3>
                                    <svg
                                      data-tip
                                      data-for="addToCartOptionsTooltip1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="addToCartOptionsTooltip1"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        Please fill out the required fields for
                                        the Salesforce Integration.
                                      </p>
                                      <p className="text-whiteˇ text-sm">
                                        Salesforce Campaign ID - the Campaign ID
                                        that all Donations/Oppertunites should
                                        write to
                                      </p>
                                    </ReactTooltip>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <div className="p grid grid-cols-1">
                                      <SingleSelectDropdown
                                        options={salesforceCampaignIDICNA.map(
                                          (id) => {
                                            return {
                                              value: id,
                                              label: id,
                                              color: "#69995E",
                                            };
                                          }
                                        )}
                                        caseSensitiveFilter={true}
                                        setItems={
                                          setSalesforceCampaignIDICNAValues
                                        }
                                        items={salesforceCampaignIDICNAValues}
                                        placeholder="Select/Type a Campaign ID"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                            {/* Add to Cart particular fields */}
                            {fundraiserType !== "DONATION_FLAG" &&
                              fundraiserType !== "" &&
                              showAddToCart === true && (
                                <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <div>
                                    <h3 className="inline-block text-sm font-medium text-gray-900">
                                      Add-to-Cart options*
                                    </h3>
                                    <svg
                                      data-tip
                                      data-for="addToCartOptionsTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="addToCartOptionsTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        Please fill out the required fields for
                                        the Add-to-Cart.
                                      </p>
                                      <p className="text-sm text-white">
                                        Source Website - the URL where the
                                        Add-to-Cart item will be embedded
                                      </p>
                                    </ReactTooltip>
                                  </div>
                                  <div className="sm:col-span-2">
                                    <div className="p grid grid-cols-1">
                                      <SingleSelectDropdown
                                        options={sourceWebsite.map((id) => {
                                          return {
                                            value: id,
                                            label: id,
                                            color: "#69995E",
                                          };
                                        })}
                                        setItems={setSourceWebsiteValues}
                                        items={sourceWebsiteValues}
                                        placeholder="Select/Type a Source Website"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                            {/* Funds */}
                            {fundraiserType != "PRODUCT" && (
                              <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <h3 className="inline-block text-sm font-medium text-gray-900">
                                    Add fund options*
                                  </h3>
                                  <svg
                                    data-tip
                                    data-for="fundOptionsTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="fundOptionsTooltip"
                                    effect="solid"
                                    place="right"
                                  >
                                    <p className="text-sm text-white">
                                      Please select at least 1 fund. You may
                                      select
                                    </p>
                                    <p className="text-sm text-white">
                                      from the dropdown, or type in a new fund
                                      to add.
                                    </p>
                                    <p className="text-sm text-white">
                                      You can reorder funds by dragging the
                                      cards.
                                    </p>
                                  </ReactTooltip>
                                </div>
                                <div className="sm:col-span-2">
                                  <MultiSelectDropdown
                                    options={props.selectableFunds}
                                    setItems={setFunds}
                                    items={funds}
                                    placeholder="Select/Type a fund"
                                  />
                                  {/*  {fundraiserType === "QUICK_DONATE" && (
                                  <SingleSelectDropdown
                                    options={props.selectableFunds}
                                    setItems={setFundsSingle}
                                    items={fundsSingle}
                                    placeholder="Select/Type a fund"
                                  />
                                )} */}
                                </div>
                              </div>
                            )}

                            {fundraiserType === "PRODUCT" && (
                              // add single select dropdown for funds
                              <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <h3 className="inline-block text-sm font-medium text-gray-900">
                                    Add fund option*
                                  </h3>
                                  <svg
                                    data-tip
                                    data-for="fundOptionsTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="fundOptionsTooltip"
                                    effect="solid"
                                    place="right"
                                  >
                                    <p className="text-sm text-white">
                                      Please select a fund. You may select
                                    </p>
                                    <p className="text-sm text-white">
                                      from the dropdown, or type in a new fund
                                      to add.
                                    </p>
                                  </ReactTooltip>
                                </div>
                                <div className="sm:col-span-2">
                                  {/* <MultiSelectDropdown
                                    options={props.selectableFunds}
                                    setItems={setFunds}
                                    items={funds}
                                    placeholder="Select/Type a fund"
                                  /> */}
                                  <SingleSelectDropdown
                                    options={props.selectableFunds}
                                    setItems={setFunds}
                                    items={funds}
                                    placeholder="Select/Type a fund"
                                  />
                                </div>
                              </div>
                            )}

                            {/* Color */}
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="color"
                                  className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  {/* make spacing and height same f*/}
                                  Color*
                                  <svg
                                    data-tip
                                    data-for="colorTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="colorTooltip"
                                    effect="solid"
                                    place="right"
                                    delayHide={500}
                                    className="react-tooltip-clickable-link"
                                  >
                                    <p className="text-sm text-white">
                                      Provide a six character Hex color preceded
                                      by a '#'.
                                    </p>
                                    <p className="text-sm text-white">
                                      For help getting this value, visit the
                                      link below.
                                    </p>
                                    <a
                                      target="_blank"
                                      className="focus:outline-none text-sm text-white underline"
                                      href="https://htmlcolorcodes.com/color-picker/"
                                    >
                                      https://htmlcolorcodes.com/color-picker/
                                    </a>
                                  </ReactTooltip>
                                </label>
                              </div>
                              <div className="relative">
                                <div className="inline-flex">
                                  <button
                                    type="button"
                                    className="relative mr-2 inline-block h-10 cursor-pointer rounded-md border  border-gray-300	bg-white shadow-sm"
                                    onClick={handleClick}
                                  >
                                    <div
                                      className="h-10 w-20 rounded"
                                      style={{ backgroundColor: color }}
                                    />
                                  </button>
                                  {displayColorPicker ? (
                                    <div className="absolute z-20 pt-10">
                                      <div
                                        className="fixed bottom-0 left-0 right-0 top-0"
                                        onClick={handleClose}
                                      />
                                      <SketchPicker
                                        color={sketchPickerColor}
                                        onChange={(color) => {
                                          setSketchPickerColor(color.rgb);
                                          setColor(color.hex);
                                        }}
                                      />
                                    </div>
                                  ) : null}

                                  <input
                                    value={color}
                                    placeholder="#000000"
                                    onChange={(e) => {
                                      let color = e.target.value;
                                      color = color.toUpperCase();
                                      color = color.replace(/^([^#])/g, "#$1");
                                      color = color.replace(
                                        /[^0-9a-fA-F#]/g,
                                        ""
                                      );
                                      if (color.length > 7) {
                                        color = color.substring(0, 7);
                                      }
                                      setColor(color);
                                    }}
                                    type="text"
                                    name="color"
                                    id="color"
                                    className="focus:border-secondary focus:ring-secondary flex h-10 w-36 rounded-md border-gray-300 shadow-sm sm:text-sm"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="project-description"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Image
                                  <svg
                                    data-tip
                                    data-for="imgTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="imgTooltip"
                                    effect="solid"
                                    place="right"
                                  >
                                    <p className="text-sm text-white">
                                      This is the logo thats displayed for this
                                      event
                                    </p>
                                  </ReactTooltip>
                                </label>

                                <br />
                              </div>
                              <ImageUploader
                                merchantName={merchantName}
                                elementID={elementID}
                                image_type={"fundraiser"}
                                desiredHeight={0} // originally 500
                                image={image}
                                setImage={setImage}
                                resizedImage={resizedImage}
                                setResizedImage={setResizedImage}
                                desiredWidth={0} // originally 500
                                setErrorMessage={setErrorMessage}
                                setShowErrorAlert={setShowErrorAlert}
                                showErrorAlert={showErrorAlert}
                                jwtToken={jwtToken}
                              />
                            </div>

                            {/* Multi-Merchant Select */}
                            {merchants.length > 1 && (
                              <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <h3 className="inline-block text-sm font-medium text-gray-900">
                                    Selected Nonprofit
                                  </h3>
                                  <svg
                                    data-tip
                                    data-for="merchantsTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="merchantsTooltip"
                                    effect="solid"
                                    place="right"
                                  >
                                    <p className="text-sm text-white">
                                      This is the nonprofit this fundraiser is
                                      associated with.
                                    </p>
                                  </ReactTooltip>
                                </div>
                                <div className="text-sm text-gray-700">
                                  {merchantName}
                                  {/* <div name="merchant" id="merchant">
                                  <Dropdown selectedValue={merchantName} setSelectedValue={setMerchantName} values={merchants} />
                                </div> */}
                                </div>
                              </div>
                            )}

                            {/* Flag Position */}
                            {fundraiserType === "DONATION_FLAG" && (
                              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="form-description"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Flag Position*
                                    <svg
                                      data-tip
                                      data-for="flagPositionTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="flagPositionTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        The Donation Flag is a sticky button
                                        that exists as a static button.{" "}
                                      </p>
                                      <p className="text-sm text-white">
                                        It can be in any of the following
                                        positions:
                                      </p>
                                      <p className="text-sm text-white">
                                        Top: Top Left, Bottom: Bottom Left,
                                        Right: Middle Right, Left: Middle Left{" "}
                                      </p>
                                    </ReactTooltip>
                                  </label>
                                </div>

                                <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
                                  <input
                                    type="radio"
                                    value="false"
                                    name="flagPosition"
                                    onClick={() => setFlagPosition("TOP")}
                                    style={{
                                      backgroundColor:
                                        flagPosition === "TOP"
                                          ? "green"
                                          : "white",
                                      marginTop: "4px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  Top&nbsp;&nbsp;
                                  <input
                                    type="radio"
                                    value="false"
                                    name="flagPosition"
                                    onClick={() => setFlagPosition("BOTTOM")}
                                    style={{
                                      backgroundColor:
                                        flagPosition === "BOTTOM"
                                          ? "green"
                                          : "white",
                                      marginTop: "4px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  Bottom
                                  <input
                                    type="radio"
                                    value="false"
                                    name="flagPosition"
                                    onClick={() => setFlagPosition("LEFT")}
                                    style={{
                                      backgroundColor:
                                        flagPosition === "LEFT"
                                          ? "green"
                                          : "white",
                                      marginTop: "4px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  Left&nbsp;&nbsp;
                                  <input
                                    type="radio"
                                    value="false"
                                    name="flagPosition"
                                    onClick={() => setFlagPosition("RIGHT")}
                                    style={{
                                      backgroundColor:
                                        flagPosition === "RIGHT"
                                          ? "green"
                                          : "white",
                                      marginTop: "4px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  Right
                                </div>
                              </div>
                            )}
                            {fundraiserType === "DONATION_FLAG" && (
                              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="form-description"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Flag Destination*
                                    <svg
                                      data-tip
                                      data-for="flagPositionTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="flagPositionTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        The Donation Flag is a sticky button
                                        that exists as a static button.{" "}
                                      </p>
                                      <p className="text-sm text-white">
                                        It can be used as a Pop-Up, or Hosted.
                                      </p>
                                    </ReactTooltip>
                                  </label>
                                </div>

                                <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
                                  <input
                                    type="radio"
                                    value="false"
                                    name="flagDestination"
                                    onClick={() => setFlagDestination("HOSTED")}
                                    style={{
                                      backgroundColor:
                                        flagDestination === "HOSTED"
                                          ? "green"
                                          : "white",
                                      marginTop: "4px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  Hosted&nbsp;&nbsp;
                                  <input
                                    type="radio"
                                    value="false"
                                    name="flagDestination"
                                    onClick={() => setFlagDestination("POPUP")}
                                    style={{
                                      backgroundColor:
                                        flagDestination === "POPUP"
                                          ? "green"
                                          : "white",
                                      marginTop: "4px",
                                      marginRight: "5px",
                                    }}
                                  />
                                  Pop-Up
                                </div>
                              </div>
                            )}

                            {/* Flag URL */}
                            {fundraiserType === "DONATION_FLAG" && (
                              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="color"
                                    className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Source Website*
                                    <svg
                                      data-tip
                                      data-for="urlTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="urlTooltip"
                                      effect="solid"
                                      place="right"
                                      delayHide={500}
                                      className="react-tooltip-clickable-link"
                                    >
                                      <p className="text-sm text-white">
                                        When a donor clicks on the Donation
                                        Flag, they will be
                                      </p>
                                      <p className="text-sm text-white">
                                        redirected to the following link.
                                      </p>
                                    </ReactTooltip>
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    value={flagPageUrl}
                                    onChange={(e) =>
                                      setFlagPageUrl(
                                        e.target.value.replace("|", "")
                                      )
                                    }
                                    type="text"
                                    name="flag-page-url"
                                    id="flag-page-url"
                                    className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                  />
                                </div>
                              </div>
                            )}

                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <div>
                                <label
                                  htmlFor="project-description"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Fundraiser Details
                                  <svg
                                    data-tip
                                    data-for="rteTooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="text-primary ml-1 inline-block h-5 w-5"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                  <ReactTooltip
                                    id="rteTooltip"
                                    effect="solid"
                                    place="right"
                                  >
                                    <p className="text-sm text-white">
                                      Provide things date and time of the
                                      fundraiser and a general description.
                                    </p>
                                  </ReactTooltip>
                                </label>
                              </div>
                              <div
                                className="sm:col-span-3"
                                onMouseEnter={() => setHasHoveredEditor(true)}
                              >
                                <Editor
                                  ref={editorRef}
                                  editorStyle={{
                                    border: 1,
                                    borderRadius: 5,
                                    paddingHorizontal: 4,
                                    minHeight: 300,
                                    paddingVertical: 2,
                                  }}
                                  toolbarStyle={{
                                    borderRadius: 5,
                                    backgroundColor: "#f1f5f9",
                                  }}
                                  wrapperStyle={{ borderRadius: 5 }}
                                  editorState={editorState}
                                  onEditorStateChange={setEditorState}
                                  wrapperClassName="wrapper-class border h-100 border-gray-300"
                                  editorClassName="editor-class h-100 px-4 py-2"
                                  toolbarClassName="toolbar-class"
                                  toolbar={{
                                    options: [
                                      "link",
                                      "inline",
                                      "blockType",
                                      "list",
                                      "textAlign",
                                    ],

                                    list: {
                                      options: ["unordered", "ordered"],
                                    },
                                    inline: {
                                      inDropdown: false,
                                      options: ["bold", "italic", "underline"],
                                    },
                                    blockType: {
                                      inDropdown: true,
                                      options: ["Normal", "H1", "H2", "H3"],
                                    },
                                  }}
                                />
                              </div>
                            </div>

                            {/* URL */}
                            {!showAddToCart &&
                              !(
                                fundraiserType === "DONATION_FLAG" &&
                                flagDestination === "POPUP"
                              ) && (
                                <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <div>
                                    <label
                                      htmlFor="project-description"
                                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                    >
                                      URL
                                      <svg
                                        data-tip
                                        data-for="urlTooltip"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="text-primary ml-1 inline-block h-5 w-5"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                          clip-rule="evenodd"
                                        />
                                      </svg>
                                      <ReactTooltip
                                        id="urlTooltip"
                                        effect="solid"
                                        place="right"
                                      >
                                        <p className="text-sm text-white">
                                          This is the link for your hosted
                                          donation form.
                                        </p>
                                      </ReactTooltip>
                                    </label>

                                    <br />
                                  </div>
                                  <div className="relative sm:col-span-2">
                                    <textarea
                                      className="block w-full cursor-not-allowed rounded-md border-gray-300 bg-gray-100 shadow-sm sm:text-sm"
                                      type="text"
                                      value={
                                        elementID !== ""
                                          ? `${HOSTED_PAGE_URL}/${elementID}`
                                          : ""
                                      }
                                      readOnly
                                      onClick={(e) => e.target.select()} // Select text on click
                                    />
                                    <button
                                      type="button" // Add this line to prevent form submission
                                      className="absolute right-2 top-1/2 -translate-y-1/2 transform rounded-md pb-4 text-black"
                                      onClick={copyToClipboard}
                                    ></button>
                                  </div>
                                </div>
                              )}

                            {/* Embed Link */}
                            {fundraiserType !== "DONATION_FLAG" && (
                              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <label
                                    htmlFor="project-description"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Embed link
                                    <svg
                                      data-tip
                                      data-for="embedTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="embedTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        Copy and paste the code on the right
                                        into your
                                      </p>
                                      <p className="text-sm text-white">
                                        website after clicking "Create". If
                                        nothing appears,
                                      </p>
                                      <p className="text-sm text-white">
                                        you may have forgotten to embed the
                                        CharityStack script
                                      </p>
                                      <p className="text-sm text-white">
                                        file. You can find this on the Settings
                                        Page.
                                      </p>
                                    </ReactTooltip>
                                  </label>
                                  <p className="text-sm text-gray-500">
                                    Embed this code on your website to collect
                                    donations.
                                  </p>
                                  <br />

                                  <a
                                    className="text-primary hover:text-secondary group flex cursor-pointer items-center space-x-2.5 text-sm font-medium"
                                    data-tip
                                    data-for="copyIframe"
                                    data-event="click"
                                  >
                                    {/* <LinkIcon
                                  className="h-5 w-5 text-primary group-hover:text-secondary"
                                  aria-hidden="true"
                                />
                                <span>Copy code</span> */}
                                  </a>

                                  <ReactTooltip
                                    id="copyIframe"
                                    clickable={true}
                                    effect="solid"
                                    place="bottom"
                                    afterShow={async () => {
                                      try {
                                        await navigator.clipboard.writeText(
                                          '<div data-entity="EMBED_FORM" data-source="CharityStack" id="${elementID}"></div>'
                                        );
                                        setCopiedState("Copied!");
                                      } catch (err) {
                                        console.error("Failed to copy: ", err);
                                        setCopiedState("Failed to copy");
                                      } finally {
                                        setTimeout(ReactTooltip.hide, 1000);
                                      }
                                    }}
                                    delayHide={500}
                                    delayUpdate={500}
                                  >
                                    <span className="text-sm text-white">
                                      {copiedState}
                                    </span>
                                  </ReactTooltip>
                                </div>
                                <div className="sm:col-span-2">
                                  <textarea
                                    value={
                                      elementID !== ""
                                        ? `<div data-entity="EMBED_FORM" data-source="CharityStack" id="${elementID}"></div>`
                                        : ""
                                    }
                                    id="project-description"
                                    name="project-description"
                                    rows={5}
                                    className={
                                      "focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm " +
                                      (elementID !== ""
                                        ? "bg-white"
                                        : "bg-gray-100")
                                    }
                                    defaultValue={""}
                                    disabled
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        }
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex h-12 items-center justify-between space-x-3">
                          {showSuccessAlert && (
                            <SuccessAlert
                              message={successAlertMessage}
                              setShowSuccessAlert={setShowSuccessAlert}
                            />
                          )}
                          {showErrorAlert && (
                            <ErrorAlert
                              message={errorMessage}
                              setShowErrorAlert={setShowErrorAlert}
                            />
                          )}
                          {!showSuccessAlert && !showErrorAlert && <div />}
                          <div className="flex justify-end space-x-3">
                            {!active && (
                              <button
                                type="button"
                                name="activate"
                                className="focus:outline-none rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-800 focus:ring-2 focus:ring-red-700 focus:ring-offset-2"
                                onClick={activateForm}
                              >
                                Activate
                              </button>
                            )}
                            {active && (
                              <>
                                <button
                                  type="button"
                                  name="deactivate"
                                  className="focus:outline-none rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-800 focus:ring-2 focus:ring-red-700 focus:ring-offset-2"
                                  onClick={(e) => setShowDeactivateAlert(true)}
                                >
                                  Deactivate
                                </button>
                                <button
                                  type="button"
                                  className={submitButtonClass}
                                  onClick={updateForm}
                                >
                                  {!isDisabled && "Save"}
                                  {isDisabled && "Saving"}
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
