/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from 'react'
import { Auth } from "aws-amplify";
import { Dialog, Transition } from '@headlessui/react'
import { CreditCardIcon } from '@heroicons/react/24/outline'
import { BanknotesIcon, BuildingLibraryIcon } from '@heroicons/react/20/solid'
import BankTransfer from '../../payment-methods/BankTransfer'
import ErrorAlert from '../../alerts/ErrorAlert';
import ClipLoader from "react-spinners/ClipLoader";
import icon from '../../../assets/double-the-donation-icon.png';
import banner from '../../../assets/360MatchPro-logo.png';
import ApiKeys from './ApiKeys';

export default function DoubleTheDonationApiKeyModal(props) {
    const { selectedSubscription } = props

    const [jwtToken, setJWTToken] = useState(null)
    const [sendingData, setSendingData] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showErrorAlert, setShowErrorAlert] = useState(false)

    // Bank Transfer Related
    const [accountNumber, setAccountNumber] = useState('')
    const [routingNumber, setRoutingNumber] = useState('')
    const [accountType, setAccountType] = useState('CHECKING')

    const [publicKey, setPublicKey] = useState('')
    const [privateKey, setPrivateKey] = useState('')
    const [allowEdit, setAllowEdit] = useState(true)
    let apiKeysRef = useRef(null)

    useEffect(() => {
        const myFunction = async () => {
            const data = await Auth.currentSession()
            setJWTToken(data['idToken']['jwtToken'])
        }
        myFunction()
    }, []);

    useEffect(() => {
        const myFunction = async () => {
            if (props.merchant !== null) {
                const data = await Auth.currentSession()
                const token = data['idToken']['jwtToken']
                setJWTToken(token)
    
                // grab double the donation api key if it exists
                const requestOptions = {
                    method: 'GET',
                    headers: { 'accept': '*/*', 'content-type': 'application/json', 'Authorization': token },
                };
    
                const params = new URLSearchParams();
                params.append("nonprofit", props.merchant['merchantName']);
                const url = process.env.REACT_APP_CS_DTD_SERVER_URL + '/api-keys'
    
                const response = await fetch(url + `?${params.toString()}`, requestOptions);
    
                let responseData = await response.json()
                // console.log('responseData is', responseData['publicApiKey'])
                setPublicKey(responseData['publicApiKey'] == null ? '' : responseData['publicApiKey'])
                setPrivateKey(responseData['privateApiKey'] == null ? '' : responseData['privateApiKey'])
                setAllowEdit(responseData['publicApiKey'] == null ? true : false)
            }
        }
        myFunction()
    }, [props.merchant])

    let netAmount = 0
    let fund = ""
    if (selectedSubscription != null) {
        netAmount = ((Math.round((selectedSubscription.amount - selectedSubscription.fees)) * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        fund = selectedSubscription.fund
    }

    let bankTransferVisibility = {}
    let extraBankTransferButtonClass = "bg-primary text-white hover:bg-secondary focus:ring-secondary border-transparent"


    const message = "Enter the bank transfer details, and then click the update button below to update the payment method"


    const updateWithBankTransfer = async () => {
        setSendingData(true)
        const requestOptions = {
            method: 'POST',
            headers: { 'accept': '*/*', 'content-type': 'application/json', 'Authorization': jwtToken },
            body: JSON.stringify({
                privateApiKey: privateKey,
                publicApiKey: publicKey,
                nonprofit: props.merchant['merchantName']
            }),
        }

        // submitting transaction to donors-data lambda
        try {
            let response = await fetch(`${process.env.REACT_APP_CS_DTD_SERVER_URL}/api-keys`, requestOptions)
            let responseBody = await response.json()
            if (!response.ok) {
                setShowErrorAlert(true)
                setErrorMessage(responseBody)
                setSendingData(false)
            }

            else {
                // props.updateSubscriptionData()
                props.setShowSuccessAlert(true)
                props.setSuccessMessage("Double the Donation API Keys Updated Successfully")
                setShowErrorAlert(false)
                setErrorMessage("")
                props.setOpen(false)
                setSendingData(false)
                setAllowEdit(false)
            }
        }
        catch (error) {
            setShowErrorAlert(true)
            setErrorMessage(error.message)
            setSendingData(false)
        }

    }



    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-20" initialFocus={apiKeysRef} onClose={props.setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                {
                                    showErrorAlert
                                    &&
                                    <div className="mb-2">
                                        <ErrorAlert message={errorMessage} setShowErrorAlert={setShowErrorAlert} />
                                    </div>
                                }
                                <div className="sm:flex sm:items-start">
                                    {/* {
                                        showErrorAlert
                                        &&
                                        <ErrorAlert message={errorMessage} setShowErrorAlert={setShowErrorAlert} />
                                    } */}
                                    <div className="mx-auto flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full">
                                        {/* <BuildingLibraryIcon className="h-6 w-6 text-green-600" aria-hidden="true" /> */}
                                        <img src={icon} className="h-7 w-7" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <div className="">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                Integrate with Double the Donation

                                            </Dialog.Title>

                                        </div>
                                        {/* <div className="mt-2 flex justify-between">
                                            <button
                                                type="button"
                                                name="Cancel"
                                                className={"w-1/2 rounded-md border py-1 px-2 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 " + extraBankTransferButtonClass}
                                            >
                                                Bank Transfer
                                            </button>
                                        </div> */}
                                        <div className="mt-2">
                                            <img src={banner} className="w-full" aria-hidden="true" />
                                        </div>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Enter your 360MatchPro public and private keys below to allow CharityStack to integrate with Double the Donation.
                                            </p>
                                        </div>
                                        <div className='mt-2' style={bankTransferVisibility}>
                                            <ApiKeys publicKey={publicKey}
                                                setPublicKey={setPublicKey}
                                                privateKey={privateKey}
                                                setPrivateKey={setPrivateKey}
                                                allowEdit={allowEdit}
                                                ref={apiKeysRef}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    {
                                        <>
                                            {!allowEdit ?
                                                <button
                                                    type="button"
                                                    name="Edit"
                                                    className="inline-flex w-1/5 justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 sm:ml-3 sm:text-sm"
                                                    onClick={(e) => {
                                                        setAllowEdit(true)
                                                    }}
                                                >
                                                    Edit
                                                </button>
                                                :
                                                <>
                                                    {
                                                        sendingData &&
                                                        <button
                                                            type="button"
                                                            name="deactivate"
                                                            className="inline-flex w-1/5 justify-center rounded-md border border-transparent bg-secondary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 sm:ml-3 sm:text-sm pointer-events-none"
                                                        >
                                                            {/* <div className="invisible">
                                                            Update
                                                        </div> */}
                                                            <ClipLoader
                                                                color={"#FFFFFF"}
                                                                loading={sendingData}
                                                                // cssOverride={override}
                                                                size={20}
                                                                aria-label="Loading Spinner"
                                                                data-testid="loader"
                                                            />
                                                        </button>
                                                    }
                                                    {
                                                        !sendingData &&
                                                        <button
                                                            type="button"
                                                            name="deactivate"
                                                            className="inline-flex w-1/5 justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 sm:ml-3 sm:text-sm"
                                                            onClick={(e) => {
                                                                updateWithBankTransfer()
                                                            }}
                                                        >
                                                            Integrate
                                                        </button>
                                                    }
                                                </>
                                            }
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                                onClick={() => props.setOpen(false)}
                                            >
                                                Cancel
                                            </button>
                                        </>

                                    }

                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
