/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { LinkIcon } from "@heroicons/react/20/solid";
import Toggle from "../Toggle";
import Dropdown from "../dropdowns/Dropdown";
import MultiSelectDropdown from "../dropdowns/MultiSelectDropdown";
import { Auth } from "aws-amplify";
import ReactTooltip from "react-tooltip";
import SuccessAlert from "../alerts/SuccessAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import Checkbox from "../Checkbox";
import { useHistory } from "react-router-dom";
import CustomInputsTab from "./tabs/CustomInputsTab";

const RECURRING_FREQUENCY_ORDER_MAP = {
  Annually: 0,
  Monthly: 1,
  Weekly: 2,
  Daily: 3,
  "One-Time": 4,
};

const frequencyOptions = ["One-Time", "Daily", "Weekly", "Monthly", "Annually"];

const tabs = [
  { name: "Form Options", href: "#formOptions", current: true },
  { name: "Custom Fields", href: "#tax", current: false },
  { name: "Tax Receipts", href: "#tax", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`.toUpperCase();
  } else {
    return hexColor.toUpperCase();
  }
};

const defaultAmounts = [10, 25, 50, 100];
const countries = [
  "United States",
  "Canada",
  "United Kingdom",
  "Australia",
  "France",
  "Lebanon",
  "Kuwait",
];

export default function CreateFormSlideover(props) {
  const [jwtToken, setJWTToken] = useState("");
  const [flagName, setflagName] = useState("");
  const [elementID, setElementID] = useState("");

  const [defaultDonationType, setDefaultDonationType] = useState("Monthly");
  const [oneTimeAmount1, setOneTimeAmount1] = useState("");
  const [oneTimeAmount2, setOneTimeAmount2] = useState("");
  const [oneTimeAmount3, setOneTimeAmount3] = useState("");
  const [oneTimeAmount4, setOneTimeAmount4] = useState("");
  const [monthlyAmount1, setMonthlyAmount1] = useState("");
  const [monthlyAmount2, setMonthlyAmount2] = useState("");
  const [monthlyAmount3, setMonthlyAmount3] = useState("");
  const [monthlyAmount4, setMonthlyAmount4] = useState("");

  const [dailyAmount1, setDailyAmount1] = useState("");
  const [dailyAmount2, setDailyAmount2] = useState("");
  const [dailyAmount3, setDailyAmount3] = useState("");
  const [dailyAmount4, setDailyAmount4] = useState("");
  const [weeklyAmount1, setWeeklyAmount1] = useState("");
  const [weeklyAmount2, setWeeklyAmount2] = useState("");
  const [weeklyAmount3, setWeeklyAmount3] = useState("");
  const [weeklyAmount4, setWeeklyAmount4] = useState("");
  const [annuallyAmount1, setAnuallyAmount1] = useState("");
  const [annuallyAmount2, setAnuallyAmount2] = useState("");
  const [annuallyAmount3, setAnuallyAmount3] = useState("");
  const [annuallyAmount4, setAnuallyAmount4] = useState("");

  const [frequency, setFrequency] = useState("One-Time");
  const [showFrequencyOptions, setShowFrequencyOptions] = useState(true);

  const [showOneTimeDefault, setShowOneTimeDefault] = useState(false);
  const [showMonthlyDefault, setShowMonthlyDefault] = useState(false);
  const [showDailyDefault, setShowDailyDefault] = useState(false);
  const [showWeeklyDefault, setShowWeeklyDefault] = useState(false);
  const [showAnnuallyDefault, setshowAnnuallyDefault] = useState(false);
  const [oneTimeDefault, setOneTimeDefault] = useState("");
  const [monthlyDefault, setMonthlyDefault] = useState("");
  const [dailyDefault, setDailyDefault] = useState("");
  const [weeklyDefault, setWeeklyDefault] = useState("");
  const [annuallyDefault, setAnnuallyDefault] = useState("");
  const [separateAmounts, setSeparateAmounts] = useState(true);
  const [requirePhoneNumber, setRequirePhoneNumber] = useState(false);
  const [requireScheduleDonations, setRequireScheduleDonations] =
    useState(false);
  const [requireBillingAddress, setRequireBillingAddress] = useState(false);
  const [requireCountryDropdown, setRequireCountryDropdown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    "Choose Default Country"
  );
  const [funds, setFunds] = useState([]);
  const [color, setColor] = useState("");

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [copiedState, setCopiedState] = useState("Copied!");

  const [monthlyChecked, setMonthlyChecked] = useState(false);
  const [oneTimeChecked, setOneTimeChecked] = useState(false);
  const [weeklyChecked, setWeeklyChecked] = useState(false);
  const [dailyChecked, setDailyChecked] = useState(false);
  const [annuallyChecked, setAnnuallyChecked] = useState(false);

  const [checkedValues, setCheckedValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  const [flagPosition, setFlagPosition] = useState("");
  const [flagPageUrl, setFlagPageUrl] = useState("");

  const [emailMessage, emailMessageChange] = useState("");

  const [dtdPublicApiKey, setDtdPublicApiKey] = useState(null);
  const [requireDoubleTheDonation, setRequireDoubleTheDonation] =
    useState(false);

  const [customInputs, setCustomInputs] = useState([]);
  const [originalCustomInputs, setOriginalCustomInputs] = useState([]);

  const [createdForm, setCreatedForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const variableClick = (e) => {
    let currentValueOfCustomMessage = emailMessage;
    //this adds a space charecter when the variable button list is clicked
    let newValueOfCustomMessage = currentValueOfCustomMessage + " " + e;

    emailMessageChange(newValueOfCustomMessage);
  };
  const recurringOptions = ["Annually", "Monthly", "Weekly", "Daily"];

  // const handleCheckboxChange = (value, isChecked) => {
  //   let newCheckedValues;

  //   if (isChecked) {
  //     newCheckedValues = [...checkedValues, value];
  //   } else {
  //     newCheckedValues = checkedValues.filter((v) => v !== value);

  //     // Uncheck recurring value
  //     const recurringOptions = ["Annually", "Monthly", "Weekly", "Daily"];
  //     if (recurringOptions.includes(value)) {
  //       newCheckedValues = newCheckedValues.filter((v) => !recurringOptions.includes(v));
  //     }
  //   }

  //   // Show all options if One-Time is selected
  //   if (newCheckedValues.includes("One-Time")) {
  //     setCheckedValues(newCheckedValues);
  //     return;
  //   }

  //   // Hide recurring options if a recurring value is selected and One-Time is not selected
  //   const hasRecurringValue = recurringOptions.some((v) => newCheckedValues.includes(v));
  //   if (hasRecurringValue) {
  //     newCheckedValues = newCheckedValues.filter((v) => recurringOptions.includes(v));

  //     // Only allow one recurring value if One-Time is not selected
  //     if (!newCheckedValues.includes("One-Time") && newCheckedValues.length > 1) {
  //       newCheckedValues = [value];
  //     }
  //   }

  //   setCheckedValues(newCheckedValues);
  // };

  const handleFrequencyCheckboxChange = (value, isChecked) => {
    let newCheckedValues;
    if (value == "One-Time") {
      setOneTimeChecked(isChecked);
    }
    if (value == "Monthly") {
      setMonthlyChecked(isChecked);
    }
    if (value == "Weekly") {
      setWeeklyChecked(isChecked);
    }
    if (value == "Daily") {
      setDailyChecked(isChecked);
    }
    if (value == "Annually") {
      setAnnuallyChecked(isChecked);
    }
    console.log("value is: ", value, "isChecked is: ", isChecked);
    if (isChecked) {
      newCheckedValues = [...checkedValues, value];
    } else {
      newCheckedValues = checkedValues.filter((v) => v !== value);
      // newCheckedValues = [value];
    }

    newCheckedValues.sort((a, b) => {
      const weightA = RECURRING_FREQUENCY_ORDER_MAP[a];
      const weightB = RECURRING_FREQUENCY_ORDER_MAP[b];
      console.log("weighta", weightA);
      console.log("weightb", weightB);
      if (weightA === weightB) {
        return a.localeCompare(b);
      } else {
        return weightB - weightA;
      }
    });
    console.log("newCheckedValues: ", newCheckedValues);
    setCheckedValues(newCheckedValues);
  };

  // internal functions
  const checkFormErrors = () => {
    // console.log("checkedValues: ", checkedValues, "selectedValue: ", selectedValue)
    if (flagPosition === "") {
      return "A Flag position is required";
    }
    if (flagPageUrl === "") {
      //crowdfunding is the selected form type and a required field is blank
      return "A Page URL is required";
    }
    if (flagName === "") {
      return "Flag Name is required";
    }
    if (color.length != 7) {
      return "Color is required";
    }
    return "";
  };

  const sendToServer = async () => {
    let returnedError = checkFormErrors();
    console.log(returnedError);
    if (returnedError != "") {
      setErrorMessage(returnedError);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    }
    const darkColor = newShade(color, -50);

    let oneTimeAmounts = [
      oneTimeAmount1,
      oneTimeAmount2,
      oneTimeAmount3,
      oneTimeAmount4,
    ];
    let weeklyAmounts = [
      weeklyAmount1,
      weeklyAmount2,
      weeklyAmount3,
      weeklyAmount4,
    ];
    let dailyAmounts = [dailyAmount1, dailyAmount2, dailyAmount3, dailyAmount4];
    let annuallyAmounts = [
      annuallyAmount1,
      annuallyAmount2,
      annuallyAmount3,
      annuallyAmount4,
    ];
    let monthlyAmounts = [
      monthlyAmount1,
      monthlyAmount2,
      monthlyAmount3,
      monthlyAmount4,
    ];

    oneTimeAmounts = oneTimeAmounts.map((amount, index) => {
      if (amount === "") {
        amount = defaultAmounts[index];
      }
      return parseFloat(amount);
    });

    weeklyAmounts = weeklyAmounts.map((amount, index) => {
      if (amount === "") {
        amount = defaultAmounts[index];
      }
      return parseFloat(amount);
    });

    dailyAmounts = dailyAmounts.map((amount, index) => {
      if (amount === "") {
        amount = defaultAmounts[index];
      }
      return parseFloat(amount);
    });

    annuallyAmounts = annuallyAmounts.map((amount, index) => {
      if (amount === "") {
        amount = defaultAmounts[index];
      }
      return parseFloat(amount);
    });
    monthlyAmounts = monthlyAmounts.map((amount, index) => {
      if (amount === "") {
        amount = defaultAmounts[index];
      }
      return parseFloat(amount);
    });

    const mapping = {
      "One-Time": "ONE_TIME",
      Daily: "DAILY",
      Weekly: "WEEKLY",
      Monthly: "MONTHLY",
      Annually: "ANNUALLY",
      "": "",
    };

    let defaultFrequency = mapping[selectedValue];
    let frequencies1 = ["MONTHLY", "ONE_TIME", "ANNUALLY", "WEEKLY", "DAILY"];
    let frequencies = checkedValues.map((value) => {
      switch (value) {
        case "One-Time":
          return "ONE_TIME";
        case "Weekly":
          return "WEEKLY";
        case "Daily":
          return "DAILY";
        case "Monthly":
          return "MONTHLY";
        case "Annually":
          return "ANNUALLY";
        default:
          return null; // or throw an error if you want to handle invalid values
      }
    });
    let showScheduleDonations = requireScheduleDonations;
    if (
      !(
        checkedValues.includes("Daily") ||
        checkedValues.includes("Weekly") ||
        checkedValues.includes("Monthly") ||
        checkedValues.includes("Annually")
      )
    ) {
      showScheduleDonations = false;
    }
    let allOptionsDescriptionsAndAmounts = [];

    console.log("OneTimeDefault :", oneTimeDefault);
    console.log("weekly default :", weeklyDefault, parseFloat(weeklyDefault));
    console.log("daily default :", dailyDefault);
    console.log("annual default :", annuallyDefault);

    setIsDisabled(true);
    const payload = {
      elementName: flagName,
      orientation: flagPosition,
      destinationURL: flagPageUrl,
      nonprofitName: props.merchant.merchantName,
      color: color,
      elementType: "DONATION_FLAG",
      elementID: elementID,
      //   "defaultMonthly": defaultMonthly,
      /* 
            "defaultFrequency": defaultFrequency,
            "frequencies": frequencies,
            "oneTimeAmounts": oneTimeAmounts,
            "monthlyAmounts": monthlyAmounts,
            "weeklyAmounts": weeklyAmounts,
            "dailyAmounts": dailyAmounts,
            "annuallyAmounts": annuallyAmounts,
            "oneTimeDefaultAmount": showOneTimeDefault && oneTimeChecked ? parseFloat(oneTimeDefault) : 0,
            "weeklyDefaultAmount": showWeeklyDefault && weeklyChecked ? parseFloat(weeklyDefault) : 0,
            "dailyDefaultAmount": showDailyDefault && dailyChecked ? parseFloat(dailyDefault) : 0,
            "annuallyDefaultAmount": showAnnuallyDefault && annuallyChecked ? parseFloat(annuallyDefault) : 0,
            "monthlyDefaultAmount": showMonthlyDefault && monthlyChecked ? parseFloat(monthlyDefault) : 0,
            // do something similar for option1title, option1amount, option1description
            // maybe convert option1amount to int
            "requirePhoneNumber": requirePhoneNumber,
            "requireScheduleDonations": showScheduleDonations,
            "requireBillingAddress": requireBillingAddress,
            "requireCountryDropdown": requireCountryDropdown,
            "selectedCountry": selectedCountry,
            "funds": funds, */
      /*  "requireDoubleTheDonation": requireDoubleTheDonation,
             "dtdPublicApiKey": dtdPublicApiKey,
             "customInputs": customInputs, */
    };

    console.log("payload is as follows: ", payload);
    const requestOptions = {
      method: createdForm ? "PATCH" : "POST", // choose the method based on if it is an updated or a new form
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(payload),
    };

    const url = `${process.env.REACT_APP_ELEMENTS_URL}/elements`;

    let response = await fetch(url, requestOptions);
    let data = await response.json();
    console.log({ data });
    if (data.error) {
      setIsDisabled(false);
      setErrorMessage(data.error);
      setShowErrorAlert(true);
      // unshowing success alert if it was never closed out
      setShowSuccessAlert(false);
      return;
    } else {
      // changeEmailSettings()
      setIsDisabled(false);
      setSuccessMessage(
        createdForm
          ? "Element Saved Successfully"
          : "Element Created Successfully"
      );
      setCreatedForm(true);
      // unshowing error alert if it was never closed out
      setShowErrorAlert(false);
      setShowSuccessAlert(true);

      if (elementID === "") {
        setElementID(data.elementID);
      }
      // props.grabSelectableFunds(jwtToken)
      // setIsDisabled(true)
      // // if previosly created form, then set success message to 'Form Saved Successfully'
      // setSuccessMessage(createdForm ? 'Form Saved Successfully' : 'Form Created Successfully')
      // // set createdForm to true so that next time it will be a PATCH request
      // setCreatedForm(true)
      // // unshowing error alert if it was never closed out
      // setShowErrorAlert(false)
      // setShowSuccessAlert(true)
    }
  };

  // Clear states when modal is closed
  const clearStates = () => {
    // close slideover
    props.setOpen(false);

    // return to default states
    setflagName("");
    setDefaultDonationType("Monthly");
    setSeparateAmounts(true);
    setOneTimeAmount1("");
    setOneTimeAmount2("");
    setOneTimeAmount3("");
    setOneTimeAmount4("");
    setMonthlyAmount1("");
    setMonthlyAmount2("");
    setMonthlyAmount3("");
    setMonthlyAmount4("");
    setShowOneTimeDefault(false);
    setShowMonthlyDefault(false);
    setShowDailyDefault(false);
    setShowWeeklyDefault(false);
    setshowAnnuallyDefault(false);
    setOneTimeDefault("");
    setMonthlyDefault("");
    setDailyDefault("");
    setWeeklyDefault("");
    setAnnuallyDefault("");
    setRequirePhoneNumber(false);
    setRequireScheduleDonations(false);
    setRequireBillingAddress(false);
    setRequireCountryDropdown(false);
    setSelectedCountry("Choose Default Country");
    setFunds([]);
    setColor("");
    setShowSuccessAlert(false);
    setShowErrorAlert(false);
    setIsDisabled(false);
    setMonthlyChecked(false);
    setOneTimeChecked(false);
    setWeeklyChecked(false);
    setAnnuallyChecked(false);
    setDailyChecked(false);
    setCheckedValues([]);
    setRequireDoubleTheDonation(false);
    setCustomInputs([]);
    setCreatedForm(false);
    setElementID("");
    setFlagPosition("");
    setFlagPageUrl("");
  };

  useEffect(() => {
    const myFunction = async () => {
      const data = await Auth.currentSession();
      const formattedMerchants = data["idToken"]["payload"][
        "cognito:groups"
      ].map((merchant) => merchant.replace(/-/g, " "));

      setJWTToken(data["idToken"]["jwtToken"]);
    };
    myFunction();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
  }, [props.open]);

  useEffect(() => {
    if (checkedValues.length > 1 && selectedValue === "") {
      setSelectedValue("Select an option");
    } else if (
      checkedValues.length > 1 &&
      checkedValues.includes(selectedValue) === false
    ) {
      setSelectedValue("Select an option");
    } else if (checkedValues.length === 0) {
      setSelectedValue("Select an option");
    } else if (checkedValues.length === 1) {
      console.log("seeting checked value", checkedValues[0]);
      setSelectedValue(checkedValues[0]);
    }
  }, [checkedValues]);

  useEffect(() => {
    const myFunction = async () => {
      let auth = jwtToken;
      if (props.merchant !== null) {
        if (auth === "") {
          const data = await Auth.currentSession();
          setJWTToken(data["idToken"]["jwtToken"]);
          auth = data["idToken"]["jwtToken"];
        }

        // grab double the donation api key if it exists
        const requestOptions = {
          method: "GET",
          headers: {
            accept: "*/*",
            "content-type": "application/json",
            Authorization: auth,
          },
        };

        const params = new URLSearchParams();
        params.append("nonprofit", props.merchant["merchantName"]);
        const url = process.env.REACT_APP_CS_DTD_SERVER_URL + "/api-keys";

        const response = await fetch(
          url + `?${params.toString()}`,
          requestOptions
        );

        let responseData = await response.json();
        // console.log('responseData is', responseData['publicApiKey'])
        setDtdPublicApiKey(responseData["publicApiKey"]);
      }
    };
    myFunction();
  }, [props.merchant]);

  let amountsLabel = "One-Time & Monthly";
  if (separateAmounts) {
    amountsLabel = "One-Time";
  }

  let submitButtonClass =
    "justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2";
  if (isDisabled) {
    submitButtonClass =
      "justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm cursor-not-allowed focus:outline-none";
  }
  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                New Donation Flag
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Get started by filling in the information below
                                to create your new donation flag.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={clearStates}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                              Select a tab
                            </label>
                            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                            <select
                              id="tabs"
                              name="tabs"
                              className="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              defaultValue={
                                tabs.find((tab) => tab.current).name
                              }
                            >
                              {tabs.map((tab) => (
                                <option key={tab.name}>{tab.name}</option>
                              ))}
                            </select>
                          </div>
                          <div className="hidden sm:block">
                            <div className="border-b border-gray-200">
                              <nav
                                className="-mb-px flex space-x-8 px-5"
                                aria-label="Tabs"
                              ></nav>
                            </div>
                          </div>
                        </div>
                        {/* Divider container */}

                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-300 sm:py-0">
                          {/* Form name */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            {!createdForm && (
                              <>
                                <div>
                                  <label
                                    htmlFor="form-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Flag Name*
                                    <svg
                                      data-tip
                                      data-for="flagNameTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="flagNameTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        This is the name of your donation flag.
                                      </p>
                                      <p className="text-sm text-white">
                                        Each Flag name must have a unique name.
                                      </p>
                                    </ReactTooltip>
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <input
                                    value={flagName}
                                    onChange={(e) =>
                                      setflagName(
                                        e.target.value.replace("|", "")
                                      )
                                    }
                                    type="text"
                                    name="flag-name"
                                    id="flag-name"
                                    className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                                  />
                                </div>
                              </>
                            )}
                            {createdForm && (
                              <>
                                <div>
                                  <label
                                    htmlFor="form-name"
                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                  >
                                    Flag Name*
                                    <svg
                                      data-tip
                                      data-for="flagNameTooltip"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      className="text-primary ml-1 inline-block h-5 w-5"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    <ReactTooltip
                                      id="flagNameTooltip"
                                      effect="solid"
                                      place="right"
                                    >
                                      <p className="text-sm text-white">
                                        This is the name of the donation form
                                        you created.
                                      </p>
                                    </ReactTooltip>
                                  </label>
                                </div>
                                <div className="sm:col-span-2">
                                  <div className="py-2 text-sm text-gray-700">
                                    {flagName}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          {/* Form Type */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="form-description"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Flag Position*
                                <svg
                                  data-tip
                                  data-for="flagPositionTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="flagPositionTooltip"
                                  effect="solid"
                                  place="right"
                                >
                                  <p className="text-sm text-white">
                                    The Donation Flag is a sticky button that
                                    exists as a static button.{" "}
                                  </p>
                                  <p className="text-sm text-white">
                                    It can be in any of the following positions:
                                  </p>
                                  <p className="text-sm text-white">
                                    Top: Top Left, Bottom: Bottom Left, Right:
                                    Middle Right, Left: Middle Left{" "}
                                  </p>
                                </ReactTooltip>
                              </label>
                            </div>

                            <div className="flex space-x-10 pt-2 text-sm sm:col-span-2 ">
                              <input
                                type="radio"
                                value="false"
                                name="flagPosition"
                                onClick={() => setFlagPosition("TOP")}
                                style={{
                                  backgroundColor:
                                    flagPosition === "TOP" ? "green" : "white",
                                  marginTop: "4px",
                                  marginRight: "5px",
                                }}
                              />
                              Top&nbsp;&nbsp;
                              <input
                                type="radio"
                                value="false"
                                name="flagPosition"
                                onClick={() => setFlagPosition("BOTTOM")}
                                style={{
                                  backgroundColor:
                                    flagPosition === "BOTTOM"
                                      ? "green"
                                      : "white",
                                  marginTop: "4px",
                                  marginRight: "5px",
                                }}
                              />
                              Bottom
                              <input
                                type="radio"
                                value="false"
                                name="flagPosition"
                                onClick={() => setFlagPosition("LEFT")}
                                style={{
                                  backgroundColor:
                                    flagPosition === "LEFT" ? "green" : "white",
                                  marginTop: "4px",
                                  marginRight: "5px",
                                }}
                              />
                              Left&nbsp;&nbsp;
                              <input
                                type="radio"
                                value="false"
                                name="flagPosition"
                                onClick={() => setFlagPosition("RIGHT")}
                                style={{
                                  backgroundColor:
                                    flagPosition === "RIGHT"
                                      ? "green"
                                      : "white",
                                  marginTop: "4px",
                                  marginRight: "5px",
                                }}
                              />
                              Right
                            </div>
                          </div>

                          {/* Color */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="color"
                                className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Color*
                                <svg
                                  data-tip
                                  data-for="colorTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="colorTooltip"
                                  effect="solid"
                                  place="right"
                                  delayHide={500}
                                  className="react-tooltip-clickable-link"
                                >
                                  <p className="text-sm text-white">
                                    Provide a six character Hex color preceded
                                    by a '#'.
                                  </p>
                                  <p className="text-sm text-white">
                                    For help getting this value, visit the link
                                    below.
                                  </p>
                                  <a
                                    target="_blank"
                                    className="focus:outline-none text-sm text-white underline"
                                    href="https://htmlcolorcodes.com/color-picker/"
                                  >
                                    https://htmlcolorcodes.com/color-picker/
                                  </a>
                                </ReactTooltip>
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                value={color}
                                placeholder="#000000"
                                onChange={(e) => {
                                  let color = e.target.value;
                                  color = color.toUpperCase();
                                  color = color.replace(/^([^#])/g, "#$1");
                                  color = color.replace(/[^0-9a-fA-F#]/g, "");
                                  if (color.length > 7) {
                                    color = color.substring(0, 7);
                                  }
                                  setColor(color);
                                }}
                                type="text"
                                name="color"
                                id="color"
                                className="focus:border-secondary focus:ring-secondary flex w-32 rounded-md border-gray-300 shadow-sm sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* Page URL */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="color"
                                className="text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Page URL*
                                <svg
                                  data-tip
                                  data-for="urlTooltip"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="text-primary ml-1 inline-block h-5 w-5"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <ReactTooltip
                                  id="urlTooltip"
                                  effect="solid"
                                  place="right"
                                  delayHide={500}
                                  className="react-tooltip-clickable-link"
                                >
                                  <p className="text-sm text-white">
                                    When a donor clicks on the Donation Flag,
                                    they should be
                                  </p>
                                  <p className="text-sm text-white">
                                    redirected to the following link where the
                                    donation form is hosted.
                                  </p>
                                </ReactTooltip>
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                value={flagPageUrl}
                                onChange={(e) =>
                                  setFlagPageUrl(
                                    e.target.value.replace("|", "")
                                  )
                                }
                                type="text"
                                name="flag-page-url"
                                id="flag-page-url"
                                className="focus:border-secondary focus:ring-secondary block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* Multi-Merchant Select 
                            {
                              merchants.length > 1 &&
                              <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <div>
                                  <h3 className="inline-block text-sm font-medium text-gray-900">Selected Nonprofit</h3>
                                  <svg data-tip data-for='merchantsTooltip' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="inline-block w-5 h-5 ml-1 text-primary">
                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                  </svg>
                                  <ReactTooltip id='merchantsTooltip' effect="solid" place='right'>
                                    <p className="text-sm text-white">Select the nonprofit you want this form to be</p>
                                    <p className="text-sm text-white">associated with.</p>
                                  </ReactTooltip>
                                </div>
                                <div>
                                  <div name="merchant" id="merchant">
                                    <Dropdown selectedValue={merchantName} setSelectedValue={setMerchantName} values={merchants} width={'w-64'} />
                                  </div>
                                </div>
                              </div>
                            }
*/}

                          {/* Embed Link */}
                          {/* <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                                        <div>
                                                            <label
                                                                htmlFor="project-description"
                                                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                                            >
                                                                Embed link
                                                                <svg data-tip data-for='embedTooltip' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="inline-block w-5 h-5 ml-1 text-primary">
                                                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                                                </svg>
                                                                <ReactTooltip id='embedTooltip' effect="solid" place='right'>
                                                                    <p className="text-sm text-white">Copy and paste the code on the right into your</p>
                                                                    <p className="text-sm text-white">website after clicking "Create". If nothing appears,</p>
                                                                    <p className="text-sm text-white">you may have forgotten to embed the CharityStack script</p>
                                                                    <p className="text-sm text-white">file. You can find this on the</p>
                                                                </ReactTooltip>
                                                            </label>

                                                            <p className="text-sm text-gray-500">
                                                                Embed this code on your website to collect donations.
                                                            </p>
                                                            <br />

                                                            <a
                                                                className="group flex items-center space-x-2.5 text-sm font-medium text-primary hover:text-secondary cursor-pointer"
                                                                data-tip
                                                                data-for="copyIframe"
                                                                data-event="click"
                                                            >

                                                                
                                                            </a>

                                                            <ReactTooltip id='copyIframe' clickable={true} effect="solid" place="bottom"
                                                                afterShow={
                                                                    async () => {
                                                                        try {
                                                                            await navigator.clipboard.writeText(iframe);
                                                                            setCopiedState('Copied!')
                                                                        }
                                                                        catch (err) {
                                                                            console.error('Failed to copy: ', err);
                                                                            setCopiedState('Failed to copy')
                                                                        }
                                                                        finally {
                                                                            setTimeout(ReactTooltip.hide, 1000)
                                                                        }
                                                                    }
                                                                }
                                                                delayHide={500}
                                                                delayUpdate={500}
                                                            >
                                                                <span className='text-sm text-white'>{copiedState}</span>
                                                            </ReactTooltip >


                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <textarea
                                                                value={elementID !== '' ? `<div data-entity="EMBED_FORM" data-source="CharityStack" id="${elementID}"></div>` : ''}
                                                                id="project-description"
                                                                name="project-description"
                                                                rows={5}
                                                                className={"block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm " + (elementID !== '' ? 'bg-white' : 'bg-gray-100')}
                                                                defaultValue={''}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div> */}
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex h-12 items-center justify-between space-x-3">
                          {showSuccessAlert && (
                            <SuccessAlert
                              message={successMessage}
                              setShowSuccessAlert={setShowSuccessAlert}
                            />
                          )}
                          {showErrorAlert && (
                            <ErrorAlert
                              message={errorMessage}
                              setShowErrorAlert={setShowErrorAlert}
                            />
                          )}
                          {!showSuccessAlert && !showErrorAlert && <div />}

                          <button
                            type="button"
                            className={submitButtonClass}
                            onClick={sendToServer}
                          >
                            {createdForm
                              ? isDisabled
                                ? "Saving"
                                : "Save"
                              : isDisabled
                              ? "Creating"
                              : "Create"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
